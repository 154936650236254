import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Bugsnag from "@bugsnag/js";

import InfiniteScrollWrapper from "../../../base/components/InfiniteScrollWrapper";
import { useLoading } from "../../../base/hooks/useLoading";
import { useLocationQuery } from "../../../base/hooks/useQueryString";
import { useService } from "../../../base/hooks/useService";
import ShippingService from "../../../services/ShippingService";
import PickingHeader from "../components/pickingHeader";
import PickingCard from "../components/pickingCard";
import { SHIPPING_TASK_STATUSES } from "../constants";
import { useShippingList } from "../helpers/useShippingList";
import styles from "./index.module.scss";
import {
  changeSidebarPickingCounter,
  changeSidebarShippingCounter,
} from "../../../store/actions";
import PickingFilters from "../components/PickingFilters";
import Placeholder from "../../inventory/components/Placeholder";
import emptyPickingIcon from "../images/shipping-empty.png";
import WhiteBox from "../../drafts/components/WhiteBox";
import SortDefaultIcon from "../../../assets/images/sort-default.svg";
import SortAscIcon from "../../../assets/images/sort-asc.svg";
import SortDescIcon from "../../../assets/images/sort-desc.svg";

const Picking = () => {
  const counters = useSelector((state) => state.Counters);
  const dispatch = useDispatch();
  const [storedData, setStoredData] = useState([]);
  const [toggleSort, setToggleSort] = useState(-1);
  const [sortIconImage, setSortIconImage] = useState(SortDefaultIcon);

  const [, { registerPromise }] = useLoading();
  /**
   * @type {ShippingService}
   */
  const shippingService = useService(ShippingService);

  const {
    params: { marketplaceType },
  } = useLocationQuery();

  const { data, hasMore, handleFetch, handleRefresh, totalCount, isLoading } =
    useShippingList({
      status: SHIPPING_TASK_STATUSES.PICKING,
    });

  const handleChangeStatus = useCallback(
    (event, id, shippingCost) => {
      event.preventDefault();
      registerPromise(
        shippingService.changeShippingStatusById(id, {
          status: SHIPPING_TASK_STATUSES.SHIPPING,
          shippingCost,
        })
      )
        .then(() => handleRefresh())
        .catch((e) => Bugsnag.notify(e));
    },
    [registerPromise, shippingService, handleRefresh]
  );

  useEffect(() => {
    const sumPickingAndShipping =
      +counters?.shippingCount?.picking + +counters?.shippingCount?.shipping;
    const newShippingCounter = sumPickingAndShipping - totalCount;

    dispatch(changeSidebarPickingCounter(totalCount));
    dispatch(changeSidebarShippingCounter(newShippingCounter));
  }, [
    counters?.shippingCount?.picking,
    counters?.shippingCount?.shipping,
    dispatch,
    totalCount,
  ]);

  useEffect(() => {
    handleRefresh();
  }, [marketplaceType]);

  useEffect(() => {
    setStoredData(data);

    if (toggleSort !== null) {
      sortData();
    }
  }, [data]);

  useEffect(() => {
    if (storedData.length !== 0) {
      sortData();
    }
  }, [storedData.length, toggleSort]);

  const sortData = () => {
    const itemsWithoutSku = data.filter(
      (item) => Object.keys(item).indexOf("sku") === -1 || !item.sku
    );

    const itemsWithSku = data.filter(
      (item) =>
        Object.keys(item).indexOf("sku") !== -1 &&
        item.sku !== null &&
        item.sku !== undefined
    );

    if (toggleSort === null) return;

    const sortIcon = toggleSort === 1 ? SortDescIcon : SortAscIcon;

    if (itemsWithSku.length > 0) {
      itemsWithSku.sort((a, b) => {
        if (a.sku.toLowerCase() < b.sku.toLowerCase()) return toggleSort;

        if (a.sku.toLowerCase() > b.sku.toLowerCase()) return -1 * toggleSort;

        return 0;
      });
    }

    const sortedData = itemsWithSku.concat(itemsWithoutSku);

    setStoredData(sortedData);
    setSortIconImage(sortIcon);
  };

  const onSortData = () => {
    const sortType = toggleSort || 1;
    setToggleSort(-1 * sortType);
  };

  return (
    <div>
      <PickingFilters sort={toggleSort} setSort={setToggleSort} />
      {!!data?.length && (
        <PickingHeader sortData={onSortData} sortIconImage={sortIconImage} />
      )}
      <InfiniteScrollWrapper
        hasMore={hasMore}
        fetchData={handleFetch}
        hasInfiniteLoader={!!data?.length}
      >
        <div className={styles.shippingList}>
          {isLoading ? (
            <WhiteBox>
              <p>Loading...</p>
            </WhiteBox>
          ) : data?.length ? (
            storedData.map((shipping) => (
              <PickingCard
                onCheckboxClick={handleChangeStatus}
                {...shipping}
                key={shipping.id}
              />
            ))
          ) : (
            <Placeholder
              text={"Currently no picking tasks."}
              imgSrc={emptyPickingIcon}
            />
          )}
        </div>
      </InfiniteScrollWrapper>
    </div>
  );
};

export default Picking;
