import yup from "./yup";
import { ACCESS_CODE_LENGTH, MIN_PHONE_NUMBER_LENGTH } from "./lengthConstants";
import { ACCESS_CODE_PATTERN, PHONE_NUMBER_PATTERN, DEV_PHONE_NUMBER_PATTERN } from "../base/constants/patterns";
import { ACCESS_CODE_ERROR, MIN_PHONE_NUMBER_ERROR, PHONE_NUMBER_ERROR } from "../base/constants/messages";

export const PHONE_NUMBER = yup
  .string()
  .min(MIN_PHONE_NUMBER_LENGTH - 1, MIN_PHONE_NUMBER_ERROR)
  .matches(PHONE_NUMBER_PATTERN, PHONE_NUMBER_ERROR)
  .trim();

export const DEV_PHONE_NUMBER = yup
  .string()
  .min(MIN_PHONE_NUMBER_LENGTH - 1, MIN_PHONE_NUMBER_ERROR)
  .matches(DEV_PHONE_NUMBER_PATTERN, PHONE_NUMBER_ERROR)
  .trim();

export const ACCESS_CODE = yup
  .string()
  .min(ACCESS_CODE_LENGTH, ACCESS_CODE_ERROR)
  .matches(ACCESS_CODE_PATTERN, ACCESS_CODE_ERROR)
  .trim()