import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import FormikGroupAddon from "../../GroupAddon";
import { getCutPrice } from "../../../../../base/helpers/getCutPrice";
import styles from "./SmartPricing.module.scss";
import joinClassNames from "../../../../../base/helpers/joinClassNames";

export const SmartPricing = ({ setFieldValue, floorPrice }) => {
  const [on, toggle] = useState(!!floorPrice);

  useEffect(() => {
    if (!on) setFieldValue("floorPrice", null);
  }, [on]);

  return (
    <div className={joinClassNames(styles.block, "mb-4")}>
      <Row>
        <Col md="8">
          <h6>Smart pricing</h6>
          <p className={styles.p}>
            Set it and forget it. Increase your chances of a sale.
          </p>
        </Col>
        <Col md="4" className={"d-flex"}>
          <button
            type={"button"}
            className={styles.btn}
            onClick={() => toggle(!on)}
          >
            {on ? "On" : "Off"}
          </button>
        </Col>
      </Row>
      {on && (
        <Row className={"mt-4"}>
          <Col md="6">
            <FormikGroupAddon
              label="Set a floor price"
              name="floorPrice"
              groupText="$"
              onChange={({ target: { value } }) => {
                setFieldValue("floorPrice", getCutPrice(value));
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};
