import { useCallback, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useLoading } from "../../../base/hooks/useLoading";
import { useService } from "../../../base/hooks/useService";
import ShippingService from "../../../services/ShippingService";
import { toNumber } from "../../sold/helpers";

import { SHIPPING_TASK_STATUSES } from "../constants";

export const useChangeShippingCost = (initialShippingCost, onRefresh) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [newShippingCost, setNewShippingCost] = useState(
    initialShippingCost?.toString()
  );

  /**
   * @type {ShippingService}
   */
  const shippingService = useService(ShippingService);
  const [, { registerPromise }] = useLoading();

  const onOpenEditMode = useCallback(() => {
    setIsEditMode(true);
  }, [setIsEditMode]);

  const onCloseEditMode = useCallback(() => {
    setIsEditMode(false);
  }, [setIsEditMode]);

  const onChangeShippingCost = useCallback(
    (id) => {
      const data = {
        status: SHIPPING_TASK_STATUSES.SHIPPING,
        shippingCost: toNumber(newShippingCost),
      };

      return registerPromise(shippingService.changeShippingStatusById(id, data))
        .then(() => onCloseEditMode())
        .then(() => onRefresh())
        .catch((e) =>
          Bugsnag.notify(e, (event) => {
            event.addMetadata("onChangeShippingCostError", {
              id,
              data,
            });
          })
        );
    },
    [
      registerPromise,
      shippingService,
      newShippingCost,
      onCloseEditMode,
      onRefresh,
    ]
  );

  const onChangeValue = useCallback(
    (value) => {
      setNewShippingCost(value.replace(/,/g, "."));
    },
    [setNewShippingCost]
  );

  return [
    isEditMode,
    onOpenEditMode,
    onChangeShippingCost,
    newShippingCost,
    onChangeValue,
  ];
};
