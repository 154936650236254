import {
  MARKETPLACE_EBAY_TYPE,
  MARKETPLACE_ETSY_TYPE,
  MARKETPLACE_MERCARI_TYPE,
  MARKETPLACE_OTHER_TYPE,
  MARKETPLACE_POSHMARK_TYPE,
} from "../../inventory/constants/importInventory";
import { EDIT_INVENTORY_LINKS } from "../../inventory/config";

export const MARKETPLACE_TYPES = {
  [MARKETPLACE_EBAY_TYPE]: "Ebay",
  [MARKETPLACE_ETSY_TYPE]: "Etsy",
  [MARKETPLACE_POSHMARK_TYPE]: "Poshmark",
  [MARKETPLACE_MERCARI_TYPE]: "Mercari",
  [MARKETPLACE_OTHER_TYPE]: "Other",
};

export const MARKETPLACE_SPECIFIC_FIELDS = {
  [MARKETPLACE_EBAY_TYPE]: "productEbaySpecificFields",
  [MARKETPLACE_ETSY_TYPE]: "productEtsySpecificFields",
  [MARKETPLACE_POSHMARK_TYPE]: "productPoshmarkSpecificFields",
  [MARKETPLACE_MERCARI_TYPE]: "productMercariSpecificFields",
};

export const MARKETPLACE_BASE = {
  key: "",
  label: "Basic",
  href: EDIT_INVENTORY_LINKS.BASIC,
};

export const MARKETPLACES_LIST = [
  {
    key: "productEtsySpecificFields",
    label: "Etsy",
    href: EDIT_INVENTORY_LINKS.ETSY,
  },
  {
    key: "productEbaySpecificFields",
    label: "Ebay",
    href: EDIT_INVENTORY_LINKS.EBAY,
  },
  {
    key: "productPoshmarkSpecificFields",
    label: "Poshmark",
    href: EDIT_INVENTORY_LINKS.POSHMARK,
  },
  {
    key: "productMercariSpecificFields",
    label: "Mercari",
    href: EDIT_INVENTORY_LINKS.MERCARI,
  },
];

export const UPDATE_SOLD_MAX_DAY = 31;
export const UPDATE_SOLD_HOUR_12 = 12;
export const UPDATE_SOLD_DAY_1 = 1;
export const UPDATE_SOLD_DAY_2 = 2;
