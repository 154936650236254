/* eslint-disable react/prop-types */
import { Col, Row } from "reactstrap";
import React from "react";
import DotsLoader from "../../../../base/components/DotLoader";

const WrapperElementBlock = ({
  label,
  description,
  children,
  classWrapper = "",
  loading,
}) => {
  return (
    <Row className={`section-wrapper ${classWrapper}`}>
      <Col md={4}>
        <h3 className="bold-black mb-1">
          {label} {loading && <DotsLoader />}
        </h3>
        {description && <p className="text-gray-gomi mb-0">{description}</p>}
      </Col>
      <Col md={8}>{children}</Col>
    </Row>
  );
};

export default WrapperElementBlock;
