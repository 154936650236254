import { useCallback, useRef, useState } from "react";
import moment from "moment";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../base/hooks/useService";
import EbayExtension from "../../../services/EbayExtension";
import { MARKETPLACES } from "../../../base/constants/extension";
import { useEbayIsConnect } from "../../drafts/hooks/useEbayIsConnect";
import filterObjectFalsy from "../../../base/helpers/filterObjectFalsy";
import SoldService from "../../../services/SoldService";
import EbayProduct from "../../inventory/classes/EbayProduct";

import EbayService from "../../../services/EbayService";
import TabConnection from "../../../services/importServices/TabConnection";
import { compareTwoDate, getLastSyncedDateForMarketplace } from "../helpers";

import { useDelistByMarketplace } from "./useDelistByMarketplace";
import QueuePromises from "../../../base/classes/QueuePromises";

export const useGetSoldItemsEbay = () => {
  const itemsRef = useRef([]);
  const [isProcessing, setFetchProcessing] = useState(false);
  const { isConnected } = useEbayIsConnect();
  const newSyncDate = useRef();
  const dateForFilter = useRef();

  /**
   * @type {EbayExtension}
   */
  const ebay = useService(EbayExtension);

  /**
   * @type {EtsyService}
   */
  const ebayService = useService(EbayService);

  /**
   * @type {TabConnection}
   */
  const tabConnection = useRef(new TabConnection(MARKETPLACES.EBAY));

  const marketplace = MARKETPLACES.EBAY;
  /**
   * @type {SoldService}
   */
  const sold = useService(SoldService);

  const [delistProduct] = useDelistByMarketplace();

  const onComplete = (activeProductsMap) => {
    const newSoldItems = itemsRef.current.filter(({ listingId }) => {
      return activeProductsMap[listingId];
    });

    const markAsSoldQueue = new QueuePromises();

    const promises = newSoldItems.map((soldItem) => {
      const soldDetails = new EbayProduct(soldItem);
      const formattedSoldDetails = filterObjectFalsy(soldDetails.data);

      return markAsSoldQueue.add(() =>
        sold
          .importSold(formattedSoldDetails)
          .finally(() =>
            delistProduct(
              MARKETPLACES.EBAY,
              soldDetails.data.listingId,
              activeProductsMap
            )
          )
      );
    });

    markAsSoldQueue.finally(() => setFetchProcessing(false));

    return Promise.all(promises).finally(() => {
      ebayService.updateLastSyncedDate(newSyncDate.current);
    });
  };

  const getFilteredItemsByDate = (data, dateForFilter) =>
    data.filter(({ order, listingId }) => {
      const { lineItems = [] } = order;
      const dateTime = lineItems.find(
        (lineItem) => lineItem.lineItem.indexOf("Order date") !== -1
      );

      if (dateTime) {
        const soldDate = new Date(dateTime.lineItem.replace("Order date", ""));

        return compareTwoDate(soldDate, dateForFilter);
      } else {
        // this is not supposed to happen
        Bugsnag.notify(
          new Error(`Unable to find sold timestamp for ebay item:${listingId}`)
        );
      }
    });

  const sync = useCallback(
    (mappedProducts, marketplaceAccountInfo) => {
      dateForFilter.current = getLastSyncedDateForMarketplace(
        marketplaceAccountInfo
      );

      newSyncDate.current = moment().toISOString();

      setFetchProcessing(true);
      itemsRef.current = [];

      const { unSubscribe } = ebay.importSoldProducts((response) => {
        const {
          data: { result, err, marketplaceTabId },
        } = response;

        if (err) {
          unSubscribe();
          setFetchProcessing(false);
          onComplete(mappedProducts);
          return;
        }

        const { data, processing } = result;

        const filteredItems = getFilteredItemsByDate(
          data,
          dateForFilter.current
        );

        if (filteredItems.length !== data.length) {
          itemsRef.current = [...itemsRef.current, ...filteredItems];

          unSubscribe();
          onComplete(mappedProducts);
          tabConnection.current.disconnectById(marketplaceTabId);

          return unSubscribe;
        }

        itemsRef.current = [...itemsRef.current, ...data];

        if (!processing) {
          unSubscribe();
          onComplete(mappedProducts);
        }
      });

      return unSubscribe;
    },

    [ebay, itemsRef, ebayService]
  );

  return {
    isProcessing,
    sync,
    marketplace,
    isConnected,
  };
};
