import React from "react";
import { useField } from "formik";
import { Col, Row } from "reactstrap";

import FormikGroupAddon from "../../GroupAddon";
import { usePriceValidation } from "../../../../../base/hooks/usePriceValidation";
import { STEP_BY_HUNDREDTH } from "../../../../../base/constants/forms";

const FormPackageDetailsEbay = ({ isRequired }) => {

  const [onChangeWithValidation, onKeyPress] = usePriceValidation({});

  return (
    <>
      <Row className="mb-4 d-flex align-items-end">
        <Col md="3">
          <FormikGroupAddon
            label={isRequired ? "Package weight*" : "Package weight"}
            name="weightLb"
            groupText="lb"
            placeholder={"0"}
            onChange={(event, setValue) =>
              onChangeWithValidation(event, setValue)
            }
            onKeyPress={onKeyPress}
            isFieldOnChange={false}
            step={STEP_BY_HUNDREDTH}
          />
        </Col>
        <Col md="3">
          <FormikGroupAddon
            label={""}
            name="weightOz"
            groupText="oz"
            placeholder={"0"}
            onChange={(event, setValue) =>
              onChangeWithValidation(event, setValue)
            }
            onKeyPress={onKeyPress}
            isFieldOnChange={false}
            step={STEP_BY_HUNDREDTH}
          />
        </Col>
      </Row>
      <Row className="mb-4 d-flex align-items-end">
        <Col md="2">
          <FormikGroupAddon
            label={isRequired ? "Package dimensions*" : "Package dimensions"}
            name="depth"
            groupText="in"
            placeholder={"0"}
            onChange={(event, setValue) =>
              onChangeWithValidation(event, setValue)
            }
            onKeyPress={onKeyPress}
            isFieldOnChange={false}
            step={STEP_BY_HUNDREDTH}
          />
        </Col>
        <Col md="2">
          <FormikGroupAddon
            label=""
            name="width"
            groupText="in"
            placeholder={"0"}
            onChange={(event, setValue) =>
              onChangeWithValidation(event, setValue)
            }
            onKeyPress={onKeyPress}
            isFieldOnChange={false}
            step={STEP_BY_HUNDREDTH}
          />
        </Col>
        <Col md="2">
          <FormikGroupAddon
            label=""
            name="height"
            groupText="in"
            placeholder={"0"}
            onChange={(event, setValue) =>
              onChangeWithValidation(event, setValue)
            }
            onKeyPress={onKeyPress}
            isFieldOnChange={false}
            step={STEP_BY_HUNDREDTH}
          />
        </Col>
      </Row>
    </>
  );
};

export default FormPackageDetailsEbay;
