/* eslint-disable react/prop-types */
import React from "react";
import classnames from "classnames";

import "./index.scss";

const CountEntries = ({
  id,
  children,
  count = 0,
  color = "green",
  className,
}) => {
  return (
    <div id={id} className={classnames("count-entries", className)}>
      <div className={`count-entries-badge count-entries-badge-${color}`}>
        {count}
      </div>
      <span>{children}</span>
    </div>
  );
};

export default CountEntries;
