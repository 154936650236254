import React from "react";
import PropTypes from "prop-types";

const DevideLine = ({ className, text }) => {
  return (
    <div className='devide-line'>
      <p>{text}</p>
      <hr className={className} />
    </div>    
  );
};

DevideLine.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
};

export default DevideLine;