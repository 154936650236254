import React, { useState, useCallback } from "react";
import Bugsnag from "@bugsnag/js";

import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../base/components/CustomModal";
import CustomButton from "../../../base/components/CustomButton";

import { useLoading } from "../../../base/hooks/useLoading";
import { useService } from "../../../base/hooks/useService";
import ShippingService from "../../../services/ShippingService";

import { SHIPPING_TASK_STATUSES } from "../constants";

export const useChangeShippingStatus = (handleRefresh) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [selectedShippingCost, setSelectedShippingCost] = useState();

  /**
   * @type {ShippingService}
   */
  const shippingService = useService(ShippingService);
  const [loading, { registerPromise }] = useLoading();

  const onOpenModal = useCallback(
    (id, shippingCost) => {
      setIsOpenModal(true);
      setSelectedId(id);
      setSelectedShippingCost(shippingCost);
    },
    [setIsOpenModal, setSelectedShippingCost]
  );

  const onCloseModal = useCallback(() => {
    setIsOpenModal(false);
  }, [setIsOpenModal]);

  const onChangeStatus = useCallback(() => {
    registerPromise(
      shippingService.changeShippingStatusById(selectedId, {
        status: SHIPPING_TASK_STATUSES.SHIPPED,
        shippingCost: selectedShippingCost,
      })
    )
      .then(() => setIsOpenModal(false))
      .then(() => handleRefresh())
      .catch((e) => Bugsnag.notify(e));
  }, [
    registerPromise,
    shippingService,
    selectedId,
    selectedShippingCost,
    handleRefresh,
  ]);

  const confirmationModal = (
    <CustomModal isOpen={isOpenModal} onClose={onCloseModal}>
      <ModalHeader onClose={onCloseModal}>Finish shipping</ModalHeader>
      <ModalBody>
        <p className="ui-regular">
          Are you sure you want to mark this item as shipped? Check shipping
          cost before confirming.
        </p>
      </ModalBody>
      <ModalFooter>
        <CustomButton onClick={onCloseModal} className="outline-primary ">
          No
        </CustomButton>
        <CustomButton
          className="filled-primary"
          onClick={onChangeStatus}
          disabled={loading}
        >
          Yes
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );

  return [onOpenModal, confirmationModal];
};
