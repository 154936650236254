import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";

import CustomBadge from "../../../../base/components/CustomBadge";
import { useExtensionInstall } from "../../hooks/Poshmark/useExtensionInstall";
import { usePoshmarkIsConnect } from "../../hooks/Poshmark/usePoshmarkIsConnect";
import { useMercariIsConnect } from "../../hooks/useMercariIsConnect";
import { useEtsyIsConnect } from "../../hooks/useEtsyIsConnect";
import { MARKETPLACES_NAMES } from "../../constants";
import { useEbayIsConnect } from "../../hooks/useEbayIsConnect";

import styles from "./index.module.scss";

const TradingFloor = ({
  name,
  Logo,
  CardContent,
  GoogleLogo,
  MarketplaceButtons,
  data,
  handleUpdateMarketplace,
  isExtension,
}) => {
  const { isInstalled } = useExtensionInstall();
  const { isConnected: isConnectedPoshmark } = usePoshmarkIsConnect();
  const { isConnected: isConnectedMercari } = useMercariIsConnect();
  const { isConnected: isConnectedEtsy } = useEtsyIsConnect();
  const { isConnected: isConnectedEbay } = useEbayIsConnect();

  const activeMarketPlace = useMemo(() => {
    if (name === MARKETPLACES_NAMES.poshmark) return isConnectedPoshmark;
    if (name === MARKETPLACES_NAMES.mercari) return isConnectedMercari;
    if (name === MARKETPLACES_NAMES.etsy) return isConnectedEtsy && data?.id;
    if (name === MARKETPLACES_NAMES.ebay) return isConnectedEbay && data?.id;

    return data;
  }, [
    name,
    isConnectedPoshmark,
    isConnectedMercari,
    isConnectedEtsy,
    isConnectedEbay,
    data,
  ]);

  return (
    <Card className="custom-marketplace-card">
      <CardBody>
        <div className="d-flex justify-content-between flex-wrap">
          <div className="mb-3">
            <Logo className="mr-3" />
            <span className="lg-bold-black">{name}</span>
          </div>
          <div className="mb-3 d-flex flex-column justify-content-between align-items-end">
            {isExtension && !isInstalled ? (
              <CustomBadge color="grey">Not installed</CustomBadge>
            ) : (
              <CustomBadge
                className={styles.activeStatusBadge}
                color={activeMarketPlace ? "green" : "grey"}
              >
                {activeMarketPlace ? "Logged in" : "Not logged in"}
              </CustomBadge>
            )}
            {GoogleLogo && activeMarketPlace && <GoogleLogo />}
          </div>
        </div>
        <CardContent data={data} />
      </CardBody>
      <hr />
      <CardBody className="pb-2 pt-2">
        {MarketplaceButtons && (
          <MarketplaceButtons
            data={data}
            handleUpdateMarketplace={handleUpdateMarketplace}
            isExtension={isExtension}
          />
        )}
      </CardBody>
    </Card>
  );
};

TradingFloor.propTypes = {
  name: PropTypes.string,
  status: PropTypes.string,
  Logo: PropTypes.any,
  CardContent: PropTypes.any,
  GoogleLogo: PropTypes.any,
  MarketplaceButtons: PropTypes.any,
  data: PropTypes.any,
  handleUpdateMarketplace: PropTypes.func,
  isExtension: PropTypes.bool,
};

export default TradingFloor;
