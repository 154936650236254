/* eslint-disable react/prop-types */
import { useBeforeunload } from "../../../../base/hooks/useBeforeunload";
import { useEffect } from "react";
import React from "react";
import { Prompt } from "react-router-dom";

const PreventClosePage = ({
  isBlocked,
  message = `Are you sure you want to leave this page?`,
  allowPath,
}) => {
  const checkPreventPageUnload = useBeforeunload();

  useEffect(() => {
    return checkPreventPageUnload(() => (isBlocked ? message : undefined));
  }, [checkPreventPageUnload, isBlocked, message]);

  return isBlocked ? (
    <Prompt
      when
      message={({ pathname }) => {
        if (pathname.includes(allowPath)) return;
        return message;
      }}
    />
  ) : null;
};

export default PreventClosePage;
