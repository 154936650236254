import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';

import CustomButton from '../../../../base/components/CustomButton';
import { CustomModal, ModalBody, ModalFooter, ModalHeader } from '../../../../base/components/CustomModal';

import ProfileService from '../../../../services/ProfileService';
import AuthCardContainer from '../../components/AuthCardContainer';
import { useLoading } from '../../../../base/hooks/useLoading';
import { useLocationQuery } from '../../../../base/hooks/useQueryString';
import { useService } from '../../../../base/hooks/useService';
import { AUTH_GROUP_LINKS } from '../../config';

const ChangeEmail = () => {
  const { push } = useHistory();
  /**
   * @type {ProfileService}
   */
  const profileService = useService(ProfileService);

  const {
    params: { token },
  } = useLocationQuery();

  const [,{ registerPromise }] = useLoading();
  const [ isOpenModal ] = useState(true);

  const handleCloseModal = useCallback(() => {
    push(AUTH_GROUP_LINKS.LINK_LOGIN);
  }, [push])

  const verifyNewEmail = useCallback(() => {
    registerPromise(profileService.verifyEmail({ token })); // CHECK
  }, [registerPromise, profileService, token]);

  useEffect(() => {
    verifyNewEmail();
  }, [verifyNewEmail]);

  return (
    <AuthCardContainer metaText="Login">
      <CustomModal isOpen={isOpenModal} onClose={handleCloseModal}>
        <ModalHeader onClose={handleCloseModal}>
          Verify email address
        </ModalHeader>
        <ModalBody>
          <p className='ui-regular'>
            Your email was successfully verified.
          </p>
        </ModalBody>
        <ModalFooter>
          <CustomButton
						className='filled-primary'
						onClick={handleCloseModal}
          >
            Ok
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </AuthCardContainer>
  )
}

export default ChangeEmail;
