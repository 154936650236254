import Bugsnag from "@bugsnag/js";

import Http from "./Http";
import MercariExtension from "./MercariExtension";
import { parseMercariImages } from "../pages/drafts/helpers/Mercari/parseMercariImages";

class MercariService extends Http {
  static $displayName = "MercariService";

  postProduct(id, listingId) {
    return this.patch(`/products/${id}/mercari`, listingId);
  }

  updatePostedStatusProduct(id, listingId) {
    return this.put(`/products/${id}/mercari`, listingId);
  }

  relistMercariInventory(id, marketplace, listingId, fetchedData, formValues) {
    const mercariExtension = new MercariExtension();

    Bugsnag.leaveBreadcrumb("Mercari relist - deleting item", listingId);

    return mercariExtension
      .deleteActiveInventory(listingId)
      .then(() => {
        Bugsnag.leaveBreadcrumb("Mercari relist - preparing image data");
        const picturesBase64 = parseMercariImages(fetchedData.files);

        return Promise.all(picturesBase64).then((pictures) => {
          Bugsnag.leaveBreadcrumb("Mercari relist - preparing to post");
          return mercariExtension
            .postDraft(pictures, {
              ...fetchedData?.productMercariSpecificFields,
              ...formValues,
            })
            .then((res) => {
              return {
                listingId: res.result.data.createListing.id,
              };
            })
            .catch((err) => {
              Bugsnag.notify(err);

              return Promise.reject({ error: err });
            });
        });
      })
      .catch((e) => Bugsnag.notify(e));
  }

  updateLastSyncedDate(newDate) {
    return this.put("/mercari-accounts/lastSyncedAt", {
      lastSyncedAt: newDate,
    });
  }

  saveMarketplaceAccount({ accountId }) {
    return this.post("/mercari-accounts", {
      accountId,
    });
  }
}

export default MercariService;
