import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container, Spinner } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import VerticalTabs from "../../../../base/components/VerticalTabs";

import { CREATE_DRAFT_LINKS } from "./config";
import CreateDraftEtsy from "./pages/Etsy";
import { DRAFTS_GROUP_LINKS } from "../../config";
import PageTitleBreadcrumb from "../../../../base/components/PageTitleBreadcrumb";
import { useSaveDraft } from "../../hooks/useSaveSubmit";
import { useService } from "../../../../base/hooks/useService";
import DraftsService from "../../../../services/DraftsService";
import PreventClosePage from "../../components/PreventClosePage";
import { useDetectFormChanges } from "../../hooks/useDetectDirtyForm";
import CreateDraftPoshmark from "./pages/Poshmark";
import CreateDraftMercari from "./pages/Mercari";
import { PRODUCT_TYPE } from "../../../../base/constants/product";
import { useTotalProducts } from "../../hooks/useProduct";
import Permissions from "../../../../base/helpers/Permission";
import { useProfile } from "../../../profile/hooks/useProfile";
import EditDraftBasic from "../EditDraft/pages/Basic";
import EditDraftEbay from "../EditDraft/pages/Ebay";
import FormResources from "../../../../services/FormResources";
import { useLoading } from "../../../../base/hooks/useLoading";
import { getInitialEmptyForm } from "../Settings/utils/getInitialEmptyForm";

const CREATE_DRAFT_TABS = [
  {
    title: "Basic",
    link: CREATE_DRAFT_LINKS.BASIC,
  },
  {
    title: "eBay",
    link: CREATE_DRAFT_LINKS.EBAY,
  },
  {
    title: "Etsy",
    link: CREATE_DRAFT_LINKS.ETSY,
  },
  {
    title: "Poshmark",
    link: CREATE_DRAFT_LINKS.POSHMARK,
  },
  {
    title: "Mercari",
    link: CREATE_DRAFT_LINKS.MERCARI,
  },
];

const DRAFT_BREADCRUMBS = [
  { title: "Draft", link: DRAFTS_GROUP_LINKS.BASE },
  { title: "Draft details", link: "#" },
];

const CreateDraft = () => {
  const initialFormValues = getInitialEmptyForm();
  const [fullFormData, updateFullForm] = useState(initialFormValues);
  const draftService = useService(DraftsService);
  const [loadingSession, { registerPromise }] = useLoading();
  const { dirty, containerRef, cancel } = useDetectFormChanges();
  const [{ activeSubscription: { type } = {} }] = useProfile();
  const [{ total }] = useTotalProducts(PRODUCT_TYPE.DRAFT);

  const onAfterCreate = (model) => {
    return draftService
      .createDraft(model)
      .then((data) => {
        cancel();
        return data;
      })
      .catch((e) => Bugsnag.notify * e);
  };

  useEffect(() => {
    registerPromise(
      FormResources.waitResources(FormResources.prefetchFormResources())
    );
  }, [registerPromise]);

  const onSave = useSaveDraft(onAfterCreate);

  const onSaveAndCreateNew = useSaveDraft(onAfterCreate, null);

  const isDisableCreateDraft = Permissions.disableCreateDraft(type, total + 1);

  const commonProps = {
    onSaveAndCreateNew,
    onSave,
    updateFullForm,
    fullFormData,
    isDisableCreateDraft,
    isDirty: dirty,
  };

  useEffect(() => {
    return () => {
      updateFullForm(initialFormValues);
    };
  }, []);

  return (
    <Container fluid>
      <div ref={containerRef}>
        <PageTitleBreadcrumb
          title="DRAFT DETAILS"
          breadcrumbItems={DRAFT_BREADCRUMBS}
          onBackPath={DRAFTS_GROUP_LINKS.BASE}
        />

        <PreventClosePage
          isBlocked={dirty}
          allowPath={DRAFTS_GROUP_LINKS.ADD_DRAFT}
        />

        <VerticalTabs tabs={CREATE_DRAFT_TABS} />
        {loadingSession ? (
          <div className="d-flex justify-content-center mt-3">
            <Spinner
              style={{
                width: "48px",
                height: "48px",
                color: "rgba(47, 102, 92, 1)",
              }}
            />
          </div>
        ) : (
          <Switch>
            <Route path={CREATE_DRAFT_LINKS.BASIC} exact>
              <EditDraftBasic {...commonProps} />
            </Route>
            <Route path={CREATE_DRAFT_LINKS.EBAY} exact>
              <EditDraftEbay {...commonProps} />
            </Route>
            <Route path={CREATE_DRAFT_LINKS.ETSY} exact>
              <CreateDraftEtsy {...commonProps} />
            </Route>
            <Route path={CREATE_DRAFT_LINKS.POSHMARK} exact>
              <CreateDraftPoshmark {...commonProps} />
            </Route>
            <Route path={CREATE_DRAFT_LINKS.MERCARI} exact>
              <CreateDraftMercari {...commonProps} />
            </Route>
            <Redirect to={CREATE_DRAFT_LINKS.BASIC} />
          </Switch>
        )}
      </div>
    </Container>
  );
};

export default CreateDraft;
