import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import StaticText from "../../components/StaticText";

import { STRINGS } from "./constants";
import styles from "./index.module.scss";

const TermsAndConditions = () => {
  return (
    <Row className="mt-3">
      <Col lg="12">
        <Card>
          <CardBody>
            <div className={styles.wrapper}>
              <StaticText
                title={STRINGS.title}
                text={[STRINGS.part_1, STRINGS.part_2, STRINGS.part_3]}
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default TermsAndConditions;
