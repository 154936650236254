import React, { createContext, useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  useLocationQuery,
  stringifyParams,
} from "../../../base/hooks/useQueryString";
import { MARKETPLACE_TYPES } from "../hooks/useProductStatus/components/SoldModal/constants";

export type FilterOption = "All" | "eBay" | "Etsy" | "Poshmark" | "Mercari";
export type DropdownFilterOptions = FilterOption[];
interface QueryStringParams {
  offset?: number;
  mergeDisplayed?: boolean;
  marketplaceType: number[];
}

interface DropdownFilterContextType {
  selectedValues: DropdownFilterOptions;
  isMergeDisplayed: boolean;
  handleClick: (selection: FilterOption) => void;
  setIsMergeDisplayed: (disabled: boolean) => void;
  options: DropdownFilterOptions;
}

const DropdownFilterContext = createContext<
  DropdownFilterContextType | undefined
>(undefined);

const options: DropdownFilterOptions = [
  "All",
  "eBay",
  "Etsy",
  "Poshmark",
  "Mercari",
];

export const DropdownFilterProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [selectedValues, setSelectedValues] = useState<DropdownFilterOptions>([
    "All",
  ]);
  const [isMergeDisplayed, setIsMergeDisplayed] = useState<boolean>(true);
  const { params } = useLocationQuery();
  const history = useHistory();

  const handleClick = (selection: FilterOption) => {
    if (selection === "All") {
      setSelectedValues(["All"]);
      return;
    }

    const updatedValues = selectedValues.includes(selection)
      ? selectedValues.filter((value) => value !== selection)
      : [...selectedValues.filter((value) => value !== "All"), selection];

    setSelectedValues(updatedValues.length ? updatedValues : ["All"]);
  };

  useEffect(() => {
    const newParams: QueryStringParams = {
      ...params,
      marketplaceType: selectedValues
        .filter((selected) => {
          const marketplace = MARKETPLACE_TYPES[selected.toLowerCase()];

          if (marketplace) {
            return true;
          }
        })
        .map((value) => MARKETPLACE_TYPES[value.toLowerCase()]),
      mergeDisplayed: isMergeDisplayed,
      offset: 0,
    };

    history.push({
      search: stringifyParams(newParams),
    });
  }, [JSON.stringify(selectedValues), isMergeDisplayed]);

  return (
    <DropdownFilterContext.Provider
      value={{
        selectedValues,
        isMergeDisplayed,
        handleClick,
        setIsMergeDisplayed,
        options,
      }}
    >
      {children}
    </DropdownFilterContext.Provider>
  );
};

export const useDropdownFilter = () => {
  const context = useContext(DropdownFilterContext);

  if (context === undefined) {
    throw new Error(
      "useDropdownFilter must be used within a DropdownFilterProvider"
    );
  }

  return context;
};
