import { useEffect } from "react";
import { useStatefullProvider } from "../../hooks/useProvider";
import { createStatefullProvider } from '../../helpers/createContext';

export const CustomErrorContext = createStatefullProvider({});
export const WaitSubscribtionContext = createStatefullProvider({wait: false});

export const useCleanCustomErrors = () => {
  const [,update] = useStatefullProvider(CustomErrorContext);

  useEffect(() => {
   
    return () => {
      update({});
    }
  }, [update])

}

export const useCustomError = () => {
  const [state, update] = useStatefullProvider(CustomErrorContext);

  const getError = (name) => {
    return state[name] || state[name]?.message;
  }

  const setError = (name, message) => {
    update((errors) => {
      return {
        ...errors,
        [name]: message
      }
    })
  }

  return {setError, getError}
}
