import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import Bugsnag from "@bugsnag/js";

import VideoContainer from "../components/VideoContainer";
import CustomButton from "../../../base/components/CustomButton";
import ProfileService from "../../../services/ProfileService";
import SettingsService from "../../../services/Settings";
import { useService } from "../../../base/hooks/useService";
import { useLoading } from "../../../base/hooks/useLoading";
import { useLocationQuery } from "../../../base/hooks/useQueryString";

const Instruction = ({ steps, TopNavigation, onClickNext, step }) => {
  /**
   * @type {ProfileService}
   */
  const profileService = useService(ProfileService);

  /**
   * @type {SettingsService}
   */
  const settingsService = useService(SettingsService);

  const {
    params: { step: currentStep },
  } = useLocationQuery();

  const [, { registerPromise }] = useLoading();
  const [data, updateData] = useState({});

  const handleSubmit = useCallback(() => {
    registerPromise(profileService.completeRegistration())
      .then(onClickNext)
      .catch((e) => Bugsnag.notify(e));
  }, [onClickNext, profileService, registerPromise]);

  useEffect(() => {
    if (currentStep === step) {
      registerPromise(settingsService.getYouTubeLinks())
        .then(({ data }) => updateData(data))
        .catch((e) => Bugsnag.notify(e));
    }
  }, [registerPromise, settingsService, step, currentStep]);

  return (
    <div className="step-form-wrapper">
      <div className="step-form-container">
        <div className="steps-navigation">
          <TopNavigation steps={steps} />
        </div>
        <div className="vertical-divider"></div>
        <div className="step-form-content">
          <div className="onboarding-instruction">
            <VideoContainer src={data.videoLink} />
            <h2 className="ui-bold-black mb-3">{data.title}</h2>
            <p className="ui-regular-black">{data.description}</p>
          </div>
        </div>
      </div>
      <div className="step-worm-buttons-wrapper">
        <CustomButton className="filled-primary" onClick={handleSubmit}>
          Done
        </CustomButton>
      </div>
    </div>
  );
};

Instruction.propTypes = {
  steps: PropTypes.array,
  TopNavigation: PropTypes.func,
  onClickNext: PropTypes.func,
  step: PropTypes.number,
};

export default Instruction;
