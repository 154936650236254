import React from 'react'
import { Link } from 'react-router-dom';

import { AUTH_GROUP_LINKS } from '../config';

const SignInFooter = () => {
  return (
  <div className='sign-up-footer text-center'>
    <p>
      Don&apos;t have an account ?{" "}
      <Link
        to={AUTH_GROUP_LINKS.LINK_REGISTER}
        className="font-weight-medium"
      >
        Sign up now
      </Link>
    </p>
    </div>
  )
};

export default SignInFooter;