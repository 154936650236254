import StorageService from "../../../../../services/StorageService";
import { KEY_USER } from "../../../../../base/constants/storage";
import { useService } from "../../../../../base/hooks/useService";

export const useGetActiveSubscription = () => {
  const storage = useService(StorageService);

  const { activeSubscription } = storage.get(KEY_USER, {});

  return activeSubscription;
};
