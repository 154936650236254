import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody } from "reactstrap";
import moment from "moment";
import Bugsnag from "@bugsnag/js";

import TitleWrapper from "../TitleWrapper";
import { ReactComponent as IconItemsListed } from "../../assets/images/ic-list.svg";
import { ReactComponent as IconSales } from "../../assets/images/ic-sales.svg";
import { ReactComponent as IconSold } from "../../assets/images/ic-sold.svg";
import { ReactComponent as IconProfit } from "../../assets/images/ic-profit.svg";
import { ReactComponent as IconRate } from "../../assets/images/ic-rate.svg";
import { ReactComponent as IconDays } from "../../assets/images/ic-days.svg";
import { ReactComponent as IconShipping } from "../../assets/images/ic-shipping.svg";
import { ReactComponent as IconFees } from "../../assets/images/ic-platform-fees.svg";
import { useService } from "../../../../base/hooks/useService";
import AnalyticsService from "../../../../services/AnalyticsService";
import { separateThousandth } from "../../helpers/separateThousandth";
import { marketplaceTypeList } from "../../constants/analisticsSalesPerPlatform";

import AnalyticItem from "../AnalyticItem";
import InventoryDropdown from "../InventoryDropdown";

import style from "../../pages/SalesPerPlatform/index.module.scss";
import AnalyticRangeDateFilter from "../AnalyticRangeDateFilter";
import AnalyticRangeDateInput from "../AnalyticRangeDateInput";
import { useAnalyticRangeDateFilter } from "../../hooks/useAnalyticRangeDateFilter";

import { MOMENT_MONTH } from "../../../bookkeeping/constants";
import { changeDataToPercent } from "../../helpers/changeDataToPercent";

import "./index.scss";

const Overview = ({
  isPerPlatform = false,
  onSelectPlatform = () => {},
  marketplaceType,
}) => {
  const [overview, updateOverview] = useState({});

  const [dateFilter, { onChangeDateFilter, formatDate }, dateFilterRef] =
    useAnalyticRangeDateFilter({
      startDate: moment().startOf(MOMENT_MONTH).toDate(),
      endDate: moment().endOf(MOMENT_MONTH).toDate(),
    });

  /**
   * @type {AnalyticsService}
   */
  const analytics = useService(AnalyticsService);

  useEffect(() => {
    if (!dateFilter.dateRange.endDate) {
      analytics
        .getTotalSales({
          marketplaceType,
        })
        .then(({ data }) => {
          updateOverview(data);
        })
        .catch((e) => Bugsnag.notify(e));
    } else {
      analytics
        .getTotalSales({
          startDate: formatDate(dateFilter.dateRange.startDate),
          endDate: formatDate(dateFilter.dateRange.endDate),
          marketplaceType,
        })
        .then(({ data }) => {
          updateOverview(data);
        })
        .catch((e) => Bugsnag.notify(e));
    }
  }, [marketplaceType, dateFilter.dateRange]);

  useEffect(() => {}, [dateFilter.dateRange.endDate]);

  return (
    <Card className={"h-100"}>
      <CardBody className={"overview-container"}>
        <TitleWrapper title={"Overview"}>
          <AnalyticRangeDateFilter
            dateRange={useMemo(
              () => [
                dateFilter.dateRange.startDate,
                dateFilter.dateRange.endDate,
              ],
              [dateFilter]
            )}
            dateRangeType={dateFilter.dateRangeType}
            onChange={onChangeDateFilter}
            CustomInput={AnalyticRangeDateInput}
            dateFilterRef={dateFilterRef}
          />
        </TitleWrapper>
        {isPerPlatform && (
          <InventoryDropdown
            list={marketplaceTypeList}
            defaultSelected={marketplaceTypeList[0]}
            classes={style.dropdownButton}
            right={false}
            onChange={onSelectPlatform}
          />
        )}
        <div className={"items-container"}>
          <AnalyticItem
            icon={<IconItemsListed />}
            label={"Total items listed"}
            value={overview?.itemsListed}
          />
          <AnalyticItem
            icon={<IconSales />}
            label={"Total sales"}
            value={separateThousandth(overview?.totalSales)}
          />
          <AnalyticItem
            icon={<IconSold />}
            label={"Total items sold"}
            value={overview?.itemsSold}
          />
          <AnalyticItem
            icon={<IconProfit />}
            label={"Total Gross profit"}
            value={separateThousandth(overview?.grossProfit)}
          />
          <AnalyticItem
            icon={<IconDays />}
            label={"Median days til sold"}
            value={overview?.medianDaysTillSold}
          />
          <AnalyticItem
            icon={<IconRate />}
            label={"Sell through rate"}
            value={
              overview?.sellThroughRate &&
              changeDataToPercent(overview?.sellThroughRate)
            }
          />
          {isPerPlatform && (
            <div>
              <AnalyticItem
                icon={<IconShipping />}
                label={"Total  Shipping"}
                value={separateThousandth(overview?.totalShippingExpenses)}
              />
              <AnalyticItem
                icon={<IconFees />}
                label={"Total  Platform fees"}
                value={separateThousandth(overview?.totalMarketplaceFees)}
              />
            </div>
          )}
          {/*TODO After implementing marketplace filter*/}
          <div className={"d-none"}>
            <AnalyticItem
              icon={<IconShipping />}
              label={"Total  Shipping"}
              value={separateThousandth(overview?.totalShippingExpenses)}
            />
            <AnalyticItem
              icon={<IconFees />}
              label={"Total  Platform fees"}
              value={separateThousandth(overview?.totalMarketplaceFees)}
            />
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default Overview;
