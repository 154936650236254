import React, { useCallback, useState } from "react";
import Bugsnag from "@bugsnag/js";

import CustomButton from "../../../base/components/CustomButton";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../base/components/CustomModal";

import { useLoading } from "../../../base/hooks/useLoading";
import { useService } from "../../../base/hooks/useService";
import MileagesService from "../../../services/MileageService";

export const useDeleteMileage = ({ refresh }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [idForDeleting, setIdForDeleting] = useState();
  const [loading, { registerPromise }] = useLoading();

  const onOpenModal = useCallback(
    (id) => {
      setIdForDeleting(id);
      setIsOpenModal(true);
    },
    [setIsOpenModal, setIdForDeleting]
  );

  const onCloseModal = useCallback(() => {
    setIsOpenModal(false);
  }, [setIsOpenModal]);

  /**
   * @type {MileagesService}
   */
  const mileagesService = useService(MileagesService);

  const onDeleteMileage = useCallback(() => {
    registerPromise(mileagesService.deleteMileage(idForDeleting))
      .then(() => {
        onCloseModal();
        refresh();
      })
      .catch((e) => Bugsnag.notify(e));
  }, [registerPromise, mileagesService, idForDeleting, onCloseModal, refresh]);

  const modal = (
    <CustomModal isOpen={isOpenModal} onClose={onCloseModal}>
      <ModalHeader onClose={onCloseModal}>Mileage deleting</ModalHeader>
      <ModalBody>
        <p className="ui-regular">
          Are you sure you want to delete the chosen mileage expense?
        </p>
      </ModalBody>
      <ModalFooter>
        <CustomButton onClick={onCloseModal} className="outline-primary ">
          No
        </CustomButton>
        <CustomButton
          className="filled-primary"
          onClick={onDeleteMileage}
          disabled={loading}
        >
          Yes
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );

  return [onOpenModal, modal, onDeleteMileage, loading];
};
