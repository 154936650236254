import React from "react";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import Logo from "./Logo";

export default function AuthHeader({ headerText, description }) {
  return (
    <div className="auth-header bg-soft">
      <Row>
        <Col xs={7}>
          <div className="p-4">
            <h5>{headerText}</h5>
            <p className='regular-white'>{description}</p>
          </div>
        </Col>
      </Row>
      <Logo />
    </div>
  );
}

AuthHeader.propTypes = {
  headerText: PropTypes.string,
  description: PropTypes.string,
};
