import React from "react";
import { Card, CardBody, Container } from "reactstrap";
import { useHistory } from "react-router-dom";

import "./profile-info.scss";

import CustomButton from "../../../../base/components/CustomButton";

import { DEFAULT_AVATAR_IMAGE } from "../../constants";
import { PROFILE_GROUP_LINKS } from "../../config";
import {useProfile} from "../../hooks/useProfile";
import PageMeta from "../../../../base/components/MetaTags";

const ProfileInfo = () => {
  const [profileInfo] = useProfile();
  const {email, firstName, lastName, image} = profileInfo;
  const history = useHistory();

  return (
    <div className="page-content">
      <PageMeta title='Profile' />
      <Container fluid>
        <h4 className="mb-4 font-weight-semibold font-size-16">PROFILE</h4>
        <Card>
          <CardBody>
            <div className="info-wrapper">
              <div className="main-info">
                <img
                  className="avatar-image rounded-circle img-thumbnail"
                  src={image?.link || DEFAULT_AVATAR_IMAGE}
                  alt={firstName}
                />
                <div className="text-wrapper">
                  <h5 className="name">{firstName} {lastName}</h5>
                  <p className="email">{email}</p>
                </div>
              </div>
              <div className="button-wrapper">
                <CustomButton
                  className="filled-primary button-content"
                  onClick={() =>
                    history.push(PROFILE_GROUP_LINKS.LINK_TO_VIEW_PROFILE_PAGE)
                  }
                >
                  <i className="bx bxs-edit-alt font-size-16 mr-2" />
                  Edit Profile
                </CustomButton>
              </div>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default ProfileInfo;
