import React from 'react'
import { ReactComponent as SuccessLogo } from '../../../../../../assets/images/ic-success (1).svg';
import { ReactComponent as ErrorLogo } from '../../../../../../assets/images/ic-success.svg';
import { ACTIVE, INACTIVE } from '../../../constant/subscription';

export const subscriberModals = {
  [ACTIVE]: {
    isOpen: true,
    title: 'Success!',
    text: <p>Subscription is successfully purchased!</p>,
    Logo: SuccessLogo,
    isError: false,
  },
  [INACTIVE]: {
    isError: true,
    isOpen: true,
    title: 'Oops!',
    text: <>
      <p className='mb-0'>Something went wrong. Payment did not process.</p>
      <p className='mb-0'>Please try again.</p>      
    </>,
    Logo: ErrorLogo,
  }
};

