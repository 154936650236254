import React, {useState } from "react";
import { Row, Col } from "reactstrap";
import Overview from "../../components/Overview";
import Averages from "../../components/Averages";
import TotalOverviewPerPlatform from "../../components/TotalOverviewPerPlatform";
import Utils from "../../utils/utils"
import {
  MARKETPLACE_POSHMARK_TYPE,
} from "../../../inventory/constants/importInventory";


const SalesPerPlatform = () => {
  const [marketplaceTypeForAverages, setMarketplaceTypeForAverages] = useState(MARKETPLACE_POSHMARK_TYPE)
  const [marketplaceTypeForOverview, setMarketplaceTypeForOverview] = useState(MARKETPLACE_POSHMARK_TYPE)


  const startDate = Utils.getStartDate()
  const endDate = Utils.getEndDate()

  const onSelectPlatformForAverages = (value) => {
    setMarketplaceTypeForAverages(value)
  }
  const onSelectPlatformForOverview = (value) => {
    setMarketplaceTypeForOverview(value)
  }

  return (
    <div className="mt-3">
      <TotalOverviewPerPlatform/>
      <Row>
        <Col>
          <Overview
            isPerPlatform={true}
            onSelectPlatform={onSelectPlatformForOverview}
            startDate={startDate}
            endDate={endDate}
            marketplaceType={marketplaceTypeForOverview}
          />
        </Col>
        <Col>
          <Averages
            isPerPlatform={true}
            onSelectPlatform={onSelectPlatformForAverages}
            startDate={startDate}
            endDate={endDate}
            marketplaceType={marketplaceTypeForAverages}
          />
        </Col>
      </Row>
    </div>
  )
}

export default SalesPerPlatform;