import React from "react";
import ebayLogo from "../../../assets/images/ebayLogo.png";
import "./index.scss"

const EbayLogo = () => {
  return (
    <>
      <img src={ebayLogo} className={"ebay-logo-img"}/>
    </>
  );
};

export default EbayLogo;