import Http from "./Http";

class ContactService extends Http {
  static $displayName = "ContactService";

  async uploadFile(file) {
    return await this.post("/upload/file", file);
  }

  async sendContactForm(values) {
    return await this.post("/contact", values);
  }
}

export default ContactService;
