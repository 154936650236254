import { DRAFT_FIELDS, DRAFT_PRIORITIES, MARKETPLACES_ACTIVE } from "../constants";
import { get } from 'lodash';
import {PRODUCT_TYPE} from "../../../base/constants/product";

export const getTableFieldValue = (model, fieldSchema, defaultValue) => {
    for (let i = 0; i < DRAFT_PRIORITIES.length; i++) {
        const marketplace = DRAFT_PRIORITIES[i]
        const value = get(model, fieldSchema[marketplace]);

        if (value) return  value
    }

    return defaultValue
}

export const getTableFieldValuePostedStatus = (model, fieldSchema, defaultValue) => {
    for (let i = 0; i < DRAFT_PRIORITIES.length; i++) {
        const marketplace = DRAFT_PRIORITIES[i]
        const status = get(model, DRAFT_FIELDS.STATUS[marketplace])
        const isPosted = status === PRODUCT_TYPE.ACTIVE
        const value = get(model, fieldSchema[marketplace]);

        if (value && isPosted) return  value
    }

    return defaultValue
}

export const getCountOfActiveItems = (model) => {
    let count = 0;

    for (let i = 0; i < MARKETPLACES_ACTIVE.length; i++) {
        const marketplace = MARKETPLACES_ACTIVE[i]
        const status = get(model, DRAFT_FIELDS.STATUS[marketplace])
        const isActive = status === PRODUCT_TYPE.ACTIVE

        if (isActive) {
            count++
        }
    }

    return count
}