import { DRAFTS_GROUP_LINKS } from "../../config";
import { FORM_TYPES } from "../../const/form";

export const CREATE_DRAFT_LINKS = {
  BASIC: `${DRAFTS_GROUP_LINKS.ADD_DRAFT}/${FORM_TYPES.BASE}`,
  EBAY: `${DRAFTS_GROUP_LINKS.ADD_DRAFT}/${FORM_TYPES.EBAY}`,
  ETSY: `${DRAFTS_GROUP_LINKS.ADD_DRAFT}/${FORM_TYPES.ETSY}`,
  POSHMARK: `${DRAFTS_GROUP_LINKS.ADD_DRAFT}/${FORM_TYPES.POSHMARK}`,
  MERCARI: `${DRAFTS_GROUP_LINKS.ADD_DRAFT}/${FORM_TYPES.MERCARI}`,
};
