import React from "react";
import PropTypes from "prop-types";

import { Card, CardBody } from "reactstrap";

import ReturnCardContent from "../ReturnCardContent";
import ReturnCardTitle from "../ReturnCardTitle";

const ReturnCard = ({
  title,
  onRefresh,
  updateTabs,
  onRelist,
  isDisableRelist,
  ...otherProps
}) => {
  return (
    <Card>
      <CardBody>
        <ReturnCardTitle
          title={title}
          props={otherProps}
          onRefresh={onRefresh}
          updateTabs={updateTabs}
        />
        <ReturnCardContent
          onRelist={onRelist}
          isDisableRelist={isDisableRelist}
          title={title}
          updateTabs={updateTabs}
          {...otherProps}
        />
      </CardBody>
    </Card>
  );
};

ReturnCard.propTypes = {
  title: PropTypes.string,
  onRefresh: PropTypes.func,
  onRelist: PropTypes.func,
  isDisableRelist: PropTypes.bool,
};

export default ReturnCard;
