import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Bugsnag from "@bugsnag/js";

import Button from "../../../base/components/Button";
import AuthService from "../../../services/AuthService";
import ToasterService from "../../../services/ToastService";
import { useService } from "../../../base/hooks/useService";
import { useLoading } from "../../../base/hooks/useLoading";
import { SUCCESS_RESEND } from "../constants/messages";

const ResendButton = ({ values }) => {
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);

  const toastr = useService(ToasterService);

  const [, { registerPromise }] = useLoading();

  const handleResend = useCallback(
    (email) => {
      registerPromise(authService.resendVerificationLink({ email }))
        .then(() => toastr.success(SUCCESS_RESEND))
        .catch((e) => Bugsnag.notify(e));
    },
    [authService, registerPromise, toastr]
  );

  return (
    <Button
      className="filled-primary"
      onClick={() => handleResend(values?.email)}
    >
      Resend link
    </Button>
  );
};

ResendButton.propTypes = {
  values: PropTypes.object,
};

export default ResendButton;
