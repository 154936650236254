import React from "react";
import PropTypes from "prop-types";

import {ReactComponent as PostedMark} from "./images/posted-mark.svg";
import styles from "./index.module.scss";

const InventoryTabItem = ({posted, title}) => (
	<>
		{posted && <PostedMark className={styles.image}/>}
		<span>{title}</span>
	</>
);

InventoryTabItem.propTypes = {
	posted: PropTypes.bool,
	title: PropTypes.string,
};

export default InventoryTabItem;
