import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";

import ChangePassword from "./pages/ChangePassword";
import ChangeAvatar from "./pages/ChangeAvatar";
import EditProfile from "./pages/EditProfile";
import ProfileInfo from "./pages/ProfileInfo";
import PhoneNumber from "./pages/PhoneNumber";
import ChangeEmail from "./pages/ChangeEmail";

import { PROFILE_GROUP_LINKS } from "./config";

import VerticalTabs from "../../base/components/VerticalTabs";
import PageTitleBreadcrumb from "../../base/components/PageTitleBreadcrumb";

const PROFILE_BREADCRUMBS = [
  { title: "Profile", link: PROFILE_GROUP_LINKS.BASE },
  {
    title: "Edit",
    link: PROFILE_GROUP_LINKS.LINK_TO_VIEW_EDIT_PROFILE_PAGE,
  },
];

const EDIT_PROFILE_TABS = [
  {
    title: "Profile info",
    link: PROFILE_GROUP_LINKS.LINK_TO_VIEW_EDIT_PROFILE_PAGE,
  },
  {
    title: "Password",
    link: PROFILE_GROUP_LINKS.LINK_TO_CHANGE_PASSWORD_PAGE,
  },
  {
    title: "Email",
    link: PROFILE_GROUP_LINKS.LINK_TO_VIEW_EMAIL_PAGE,
  },
  {
    title: "Phone number",
    link: PROFILE_GROUP_LINKS.LINK_TO_VIEW_PHONE_NUMBER_PAGE,
  },
];

function ProfileRoutes() {
  return (
    <div className="page-content">
      <Container fluid>
        <PageTitleBreadcrumb
          title="PROFILE"
          breadcrumbItems={PROFILE_BREADCRUMBS}
        />
        <VerticalTabs tabs={EDIT_PROFILE_TABS} />
        <Switch>
          <Route
            path={PROFILE_GROUP_LINKS.LINK_TO_VIEW_EDIT_PROFILE_PAGE}
            component={EditProfile}
            exact
          />
          <Route
            path={PROFILE_GROUP_LINKS.LINK_TO_CHANGE_PASSWORD_PAGE}
            component={ChangePassword}
            exact
          />
          <Route
            path={PROFILE_GROUP_LINKS.LINK_TO_CHANGE_AVATAR_PAGE}
            component={ChangeAvatar}
            exact
          />
          <Route
            path={PROFILE_GROUP_LINKS.LINK_TO_VIEW_EMAIL_PAGE}
            component={ChangeEmail}
            exact
          />
          <Route
            path={PROFILE_GROUP_LINKS.LINK_TO_VIEW_PHONE_NUMBER_PAGE}
            component={PhoneNumber}
            exact
          />
          <Redirect to={PROFILE_GROUP_LINKS.LINK_TO_VIEW_EDIT_PROFILE_PAGE} />
        </Switch>
      </Container>
    </div>
  );
}

export default () => {
  return (
    <Switch>
      <Route path={PROFILE_GROUP_LINKS.BASE} component={ProfileInfo} exact />
      <Route path={PROFILE_GROUP_LINKS.BASE} component={ProfileRoutes} />
      <Redirect to={PROFILE_GROUP_LINKS.BASE} />
    </Switch>
  );
};
