export const getMultiInitialOption = (initialOption) => {
  if (Array.isArray(initialOption?.id)) {
    const parsedInitialOption = [];
    for (let i = 0; i < initialOption?.id.length; i++) {
      const optionObj = {
        id: initialOption?.id[i],
        label: initialOption?.label[i],
        variant: undefined,
      };
      parsedInitialOption.push(optionObj);
    }

    return parsedInitialOption;
  } else {
    return initialOption?.id ? initialOption : null;
  }
};
