import {
  MARKETPLACE_EBAY_TYPE,
  MARKETPLACE_ETSY_TYPE,
  MARKETPLACE_MERCARI_TYPE,
  MARKETPLACE_POSHMARK_TYPE,
  MARKETPLACE_OTHER_TYPE
} from "../../inventory/constants/importInventory";

export const marketplaceTypeList  = [{label: "Poshmark", value: MARKETPLACE_POSHMARK_TYPE}, {label: "Ebay", value: MARKETPLACE_EBAY_TYPE}, {label: "Etsy", value: MARKETPLACE_ETSY_TYPE}, {label: "Mercari", value: MARKETPLACE_MERCARI_TYPE}, {label: "Other", value: MARKETPLACE_OTHER_TYPE}]

export const LABELS = [
  "",
  "Ebay",
  "Etsy",
  "Poshmark",
  "Mercari",
  "Other",
];
export const templateOfSalesData = [
  {
    priceSoldSum: 0,
    marketplaceType: MARKETPLACE_POSHMARK_TYPE,
  },
  {
    priceSoldSum: 0,
    marketplaceType: MARKETPLACE_MERCARI_TYPE,
  },
  {
    priceSoldSum: 0,
    marketplaceType: MARKETPLACE_ETSY_TYPE,
  },
  {
    priceSoldSum: 0,
    marketplaceType: MARKETPLACE_EBAY_TYPE,
  },
  {
    priceSoldSum: 0,
    marketplaceType: MARKETPLACE_OTHER_TYPE,
  },
]

export const DATE_FORMAT_ISO_8601 =  "YYYY-MM-DD"
export const FRACTIONS_ARRAY_LENGTH = 5;
export const FRACTIONS_ARRAY_DEFAULT_VALUE = 0;
