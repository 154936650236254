import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

const StaticText = ({ title, text = [] }) => {
  return (
    <div className={styles.container}>
      {title && <h2 className={styles.title}>{title}</h2>}
      {text.map((textPart, index) => (
        <span key={index}>{textPart}</span>
      ))}
    </div>
  );
};

StaticText.propTypes = {
  title: PropTypes.string,
  text: PropTypes.array,
};

export default StaticText;
