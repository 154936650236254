import { TYPES } from "../../../../base/components/Subscription/constant/subscription";
import { ROLES } from "../../../auth/constants/roles";
import { SETTINGS_LINKS } from "./config";

export const SETTING_TABS = [
  {
    title: 'Marketplace connections',
    link: SETTINGS_LINKS.MARKETPLACE,
    roles: [ROLES.owner, ROLES.employee]
  },
  {
    title: 'Employee management',
    link: SETTINGS_LINKS.EMPLOYEE,
    roles: [ROLES.owner]
  },
  {
    title: 'Subscription',
    link: SETTINGS_LINKS.SUBSCRIPTION,
    roles: [ROLES.owner]
  },
  {
    title: 'Payment',
    link: SETTINGS_LINKS.PAYMENT,
    roles: [ROLES.owner],
    isNotTrivial: true,
  }
];

export const getSettingTabs = (type, role) => {
  return SETTING_TABS.filter((tab) => {
    if (tab.roles.includes(role)) {
      tab.isDisabled = tab.isNotTrivial && type === TYPES.TRIAL;
      return tab;
    }
  })
};