/* eslint-disable react/prop-types */
import React from "react";
import { CardBody, Row, Col, Collapse } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import FormikTextarea from "../../../../base/components/FormikTextarea";
import ProductPreview from "../../../drafts/components/ProductPreview";
import WithHighlight from "../../../drafts/components/WithHighlight";
import { getFormattedDate } from "../../../drafts/helpers/format";
import ExpenseActionsButton from "../ExpenseActionsButton";
import { Formik } from "formik";

import "./index.scss";

import { MAX_MESSAGE_LENGTH } from "../../../../validation/lengthConstants";
import { validationSchema } from "./form";
import CustomButton from "../../../../base/components/CustomButton";
import { COUNT_OF_ROWS_STR } from "../../../drafts/const/poshmark";
import { useService } from "../../../../base/hooks/useService";
import ExpensesService from "../../../../services/ExpensesService";
import { formFields } from "../CreateExpenseModal/form";
import { toNumber } from "../../../sold/helpers";

const ExpenseCard = ({
  id,
  title,
  image,
  price,
  expenseMadeAt,
  expenseCategory,
  description,
  memo,
  onEditExpense,
  onDeleteExpense,
  handleToggleCollapse,
  isOpenCollapse,
}) => {
  /**
   * @type {ExpensesService}
   */
  const expensesService = useService(ExpensesService);

  const initialValues = {
    title: title,
    description: description,
    price: price,
    category: expenseCategory.name,
    image: image,
    memo: memo,
  };

  const onEditMemo = (id, values, resetForm) => {
    const formattedValues = {
      [formFields.title]: values[formFields.title],
      [formFields.description]: values[formFields.description],
      [formFields.category]: values[formFields.category],
      [formFields.price]: toNumber(values[formFields.price]),
      [formFields.imageId]: toNumber(values?.image?.id),
      ...(values[formFields.memo] && {
        [formFields.memo]: values[formFields.memo],
      }),
    };

    expensesService
      .updateExpense(id, formattedValues)
      .then(({ data }) => {
        const newData = {
          title: data.title,
          description: data.description,
          price: data.price,
          category: data.expenseCategory?.name,
          image: data.image,
          memo: data.memo,
        };
        resetForm({ values: newData });
        handleToggleCollapse(null);
      })
      .catch((e) => Bugsnag.notify(e));
  };

  return (
    <CardBody className="border-bottom">
      <Row className="py-1">
        <Col xs="12" sm="5" md="4" className="d-flex align-items-center">
          <div className="expense-title">
            <ProductPreview
              src={image?.link}
              className={"drafts-product-preview cursor-pointer"}
              hasFullScreenMode
            />
            <WithHighlight text={title || "-"} className="fw-bold" />
          </div>
        </Col>
        <Col xs="2" md="2" className="d-flex align-items-center">
          <p className="fw-bold mb-0">${price}</p>
        </Col>
        <Col xs="2" md="2" className="d-flex align-items-center">
          <p className="mb-0">{getFormattedDate(expenseMadeAt)}</p>
        </Col>
        <Col xs="2" md="2" className="d-flex align-items-center">
          <p className="mb-0 text-truncate">{expenseCategory?.name}</p>
        </Col>
        <Col
          xs="9"
          sm="5"
          md="2"
          className="d-flex align-items-center justify-content-end justify-content-sm-center"
          onClick={handleToggleCollapse}
        >
          <ExpenseActionsButton
            isOpen={isOpenCollapse}
            onDeleteExpense={(event) => onDeleteExpense(id, event)}
            onEditExpense={(event) => onEditExpense(id, event)}
          />
        </Col>
      </Row>
      <Collapse isOpen={isOpenCollapse}>
        <div className="mt-3 pt-3 border-top">
          <p className="text-muted">{description}</p>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={() => {}}
        >
          {({ dirty, isValid, values, resetForm }) => {
            return (
              <Row>
                <Col xs="12" sm="5" md="4">
                  <FormikTextarea
                    id="memo"
                    name="memo"
                    label={"Memo"}
                    placeholder="Enter memo"
                    rows={COUNT_OF_ROWS_STR}
                    maxLength={MAX_MESSAGE_LENGTH}
                  />
                </Col>
                <Col className="d-flex justify-content-end align-items-end">
                  <CustomButton
                    type="submit"
                    className="filled-primary comment__button task-form__button"
                    disabled={!isValid || !dirty}
                    onClick={() => {
                      onEditMemo(id, values, resetForm);
                    }}
                  >
                    Done
                  </CustomButton>
                </Col>
              </Row>
            );
          }}
        </Formik>
      </Collapse>
    </CardBody>
  );
};

export default ExpenseCard;
