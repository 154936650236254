export const EBAY_RETURNS_TYPES = {
  domestic: "domestic",
  international: "international",
};

export const IS_ACCEPTED_RETURN_OPTIONS = {
  available: 1,
  unavailable: 2,
};

export const EBAY_DAY_RETURNS_OPTIONS = {
  days14: 14,
  days30: 30,
  days60: 60,
};

export const DAY_DROPDOWN_OPTIONS = [
  { id: EBAY_DAY_RETURNS_OPTIONS.days14, label: "14 Days" },
  { id: EBAY_DAY_RETURNS_OPTIONS.days30, label: "30 Days " },
  { id: EBAY_DAY_RETURNS_OPTIONS.days60, label: "60 Days " },
];

export const PAID_BY_OPTIONS = {
  seller: 1,
  buyer: 2,
};

export const PAID_BY_DROPDOWN_OPTIONS = [
  {
    id: 1,
    label: "Seller",
  },
  {
    id: 2,
    label: "Buyer",
  },
];

export const REPLACEMENT_CHECKBOX_OPTIONS = {
  replacement: 1,
};

export const FORMIK_NAMES = {
  [EBAY_RETURNS_TYPES.domestic]: {
    mainCheckboxName: "returnPolicyType",
    daysDropdownName: "returnDays",
    paidByDropdownName: "returnExpenses",
    replacementCheckboxName: "returnMethod",
  },
  [EBAY_RETURNS_TYPES.international]: {
    mainCheckboxName: "returnPolicyTypeInternational",
    daysDropdownName: "returnDaysInternational",
    paidByDropdownName: "returnExpensesInternational",
    replacementCheckboxName: "returnMethodInternational",
  },
};
