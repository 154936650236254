import * as yup from "yup";
import { ACCESS_CODE, DEV_PHONE_NUMBER, PHONE_NUMBER } from "../../../../validation/phoneNumber";

export const validationSchema = yup.object().shape({
  phoneNumber: PHONE_NUMBER.required(),
  code: ACCESS_CODE.required()
});

export const getValidationSchema = (isDev = false) => {
  return yup.object().shape({
    phoneNumber: isDev ? DEV_PHONE_NUMBER.required() : PHONE_NUMBER.required(),
    code: ACCESS_CODE.required()
  });
}