import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

const UploaderButton = ({ onClick, className, children = "Upload image", description }) => {
  return (
    <div className={className || styles.buttonWrapper} onClick={onClick}>
      <div className={styles.contentWrapper}>
        <i className={`bx bxs-cloud-upload cloud`} />
        <p className={`${styles.text} ${description ? "mb-2" : ""}`}>{children}</p>
          {description &&
              <p className="text-gray-gomi mb-0">{description}</p>
          }
      </div>
    </div>
  );
};

UploaderButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default UploaderButton;
