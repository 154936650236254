/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createFilter } from "react-select";
import Bugsnag from "@bugsnag/js";

import { getProperties } from "../../../../helpers/fetchCategories";
import { useField } from "formik";
import FormikSingleSelect from "../../../../../../base/components/FormikSingleSelect";
import { productColors } from "../../../../../../base/constants/draft";
import FormikAsyncSelect from "../../../../../../base/components/FormikAsyncSelect";
import { getConfigBrandInitialValue } from "../../../../helpers/getConfigInitialValue";
import { useService } from "../../../../../../base/hooks/useService";
import BrandsService from "../../../../../../services/BrandsService";
import { listToOptions } from "../../../../helpers/listToOptions";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import VirtualizeMenuList from "../../../../../../base/components/VirtualizeMenuList";
import VirtualizeSelectOption from "../../../../../../base/components/VirtualizeSelectOption";
import { getFieldType } from "../../../../helpers/getFieldType";
import { getEbayFieldsByTypes } from "../../../../helpers/getEbayFieldsByTypes";
import AdditionalFields from "./components/AdditionalFields";

import {
  EBAY_ADDITIONAL_FIELDS_LIMIT,
  EBAY_FIELDS_TYPES,
  EBAY_FIELDS_TYPES_FROM_RESPONSE,
} from "../../../../const/ebay";
import CustomBrandMessage from "../../../CustomBrandMessage";
import { matchStrings } from "../../../../../../base/helpers/matchStrings";
import { useFindAlternativeBrands } from "../../../../hooks/useFindAlternativeBrands";

export const BRAND_LABEL = "Brand";

const excludeProperties = {
  [BRAND_LABEL]: ({ validate, options, suggestedFields }) => {
    const [search, setSearch] = useState("");
    const [alternativeBrandsList, setAlternativeBrandsList] = useState([]);

    const [isLoading, { registerPromise }] = useLoading();
    const [findAlternativeBrands] = useFindAlternativeBrands();

    const { brand: suggested } = suggestedFields;

    const [{ value }, , { setValue }] = useField({ name: "brand", validate });
    const brandId = value;

    const isCustom = useMemo(() => {
      const notFoundInList = !options.find(({ label }) =>
        matchStrings(label, value)
      );

      return notFoundInList && suggested && matchStrings(brandId, suggested);
    }, [options, value, suggested, brandId]);
    /**
     * @type {BrandsService}
     */
    const brands = useService(BrandsService);
    const getBrandOptions = useCallback(
      (query) => {
        return brands
          .search(query)
          .then((data) => {
            const basicBrandsOptions = listToOptions(data);

            const response = [...basicBrandsOptions, ...options];

            if (value) {
              response.push({
                label: value,
                id: value,
                variants: undefined,
              });
            }

            return response;
          })
          .catch((e) => Bugsnag.notify(e));
      },
      [brands, options, value]
    );

    const onSelectAlternativeBrand = (alternativeBrand) => {
      setValue(alternativeBrand);
    };

    const formatAllBrands = (allBrands) => allBrands.map(({ label }) => label);

    useEffect(() => {
      const loadedBrands = options.length;

      if (!loadedBrands || brandId || !suggested) return;

      setValue(suggested);
    }, [options, brandId, suggested]);

    useEffect(() => {
      if (!isCustom) return;

      registerPromise(
        // eslint-disable-next-line no-unused-vars
        getBrandOptions()
          // eslint-disable-next-line no-unused-vars
          .then((allBrands) => {
            const alternativeBrand = findAlternativeBrands(
              options,
              brandId,
              formatAllBrands
            );

            setAlternativeBrandsList(alternativeBrand);
          })
          .catch((e) => Bugsnag.notify(e))
      );
    }, [isCustom]);

    return (
      <>
        <FormikAsyncSelect
          initialValue={getConfigBrandInitialValue(value)}
          name="brand"
          label={"Brand"}
          placeholder="Select brand"
          onChange={(value) => value.label}
          onRequestAPI={getBrandOptions}
          updateSearch={(value) => {
            setSearch(value);
          }}
          components={{
            MenuList: VirtualizeMenuList,
            Option: VirtualizeSelectOption,
          }}
          filterOption={createFilter({ ignoreAccents: false })}
          search={search}
          isCreatable
          validate={validate}
          getValue={(options) => {
            const opts = options.find(({ label }) => {
              return matchStrings(label, value);
            });

            return opts;
          }}
        />
        {isCustom && !isLoading && (
          <CustomBrandMessage
            brandList={alternativeBrandsList}
            onSelectBrand={onSelectAlternativeBrand}
          />
        )}
      </>
    );
  },
  Color: ({ validate }) => {
    const [, , { setTouched }] = useField({ name: "color", validate });
    return (
      <FormikSingleSelect
        name="color"
        validate={validate}
        onBlur={() => {
          setTouched(true);
        }}
        label={"Color"}
        options={productColors}
        placeholder="Select color"
        allowCreate
      />
    );
  },
};

export const mapEbayProperties = (option) => {
  const {
    localizedAspectName: label,
    aspectValues,
    aspectConstraint: {
      itemToAspectCardinality,
      aspectRequired: isRequired,
      aspectMode,
      aspectUsage,
      aspectMaxLength: maxLength,
    } = {},
  } = option;
  const fieldName = "productAdditionalFields.propertyId_" + label;

  let options;

  if (aspectValues?.length) {
    options = aspectValues.map(({ localizedValue: label }) => {
      return {
        label,
        id: label,
        variants: undefined,
      };
    });
  }

  const type = getFieldType({
    [EBAY_FIELDS_TYPES.required]: isRequired,
    [EBAY_FIELDS_TYPES.recommended]:
      aspectUsage === EBAY_FIELDS_TYPES_FROM_RESPONSE.recommended &&
      !isRequired,
    [EBAY_FIELDS_TYPES.additional]:
      aspectUsage === EBAY_FIELDS_TYPES_FROM_RESPONSE.optional && !isRequired,
  });

  return {
    label,
    options,
    isRequired,
    type,
    maxLength,
    name: fieldName,
    key: label,
    mode: aspectMode,
    allowCreate: true,
    isMulti: itemToAspectCardinality === "MULTI",
  };
};

const OptionalFields = ({ categoryId, suggestedFields }) => {
  const [fields, updateFields] = useState([]);

  const [, , { setValue }] = useField({ name: "productAdditionalFields" });
  const [, , { setValue: setBrandValue }] = useField({ name: "brand" });
  const refMounted = useRef(false);
  const [loading, { registerPromise: awaitProperties }] = useLoading();

  useEffect(() => {
    if (!categoryId) return;
    if (refMounted.current) setValue({});

    refMounted.current = true;

    awaitProperties(getProperties(categoryId))
      .then((response) => {
        const { data = [] } = response;
        const fields = data.map(mapEbayProperties);
        const hasBrand = fields.find(({ label }) => label === BRAND_LABEL);

        if (!hasBrand) {
          setBrandValue("");
        }

        updateFields(fields);
      })
      .catch((e) =>
        Bugsnag.notify(e, (event) => {
          event.addMetadata("getpropertiesError", {
            categoryId,
          });
        })
      );
  }, [categoryId, awaitProperties]);

  const { required, recommended, additional } = useMemo(
    () => getEbayFieldsByTypes(fields),
    [fields]
  );

  return (
    <>
      {!fields.length ? (
        <div className="mb-4">No specific fields</div>
      ) : (
        <>
          <AdditionalFields
            groupTitle="Required"
            groupSubTitle="Buyers need these item specifics about your item."
            fields={required}
            isLoading={loading}
            excludeProperties={excludeProperties}
            suggestedFields={suggestedFields}
          />
          <AdditionalFields
            groupTitle="Recommended"
            groupSubTitle="Buyers frequently search for these item specifics."
            fields={recommended}
            isLoading={loading}
            excludeProperties={excludeProperties}
            suggestedFields={suggestedFields}
          />
          <AdditionalFields
            groupTitle="Additional"
            groupSubTitle="Buyers may also be interested in these item specifics."
            fields={additional}
            isLoading={loading}
            excludeProperties={excludeProperties}
            limit={EBAY_ADDITIONAL_FIELDS_LIMIT}
            isShowMoreButton={true}
            suggestedFields={suggestedFields}
          />
        </>
      )}
    </>
  );
};

export default OptionalFields;
