import Http from "./Http";

class ProductStatusService extends Http {
  static $displayName = "ProductStatusService";

  relist(id, marketplace, param) {
    return this.put(`/products/${id}/${marketplace}`, param ? param : {});
  }

  sold(values) {
    return this.post("/products/orders", values);
  }

  delist(id, marketplace) {
    return this.delete(`/products/${id}/${marketplace}`);
  }

  unmerge(values) {
    return this.post("/products/separation", values);
  }
}

export default ProductStatusService;
