import React from "react";
import Utils from "../../../../utils/utils"
import {styleDiagramText} from "../../utils/utils"
import {DEFAULT_ORDER_BY} from "../../../../constants/analyticsAvailableInventory"
import styles from "./index.module.scss"

const List = ({data, orderBy}) => {
  return (
    <ul className={styles.container}>
      {data.map((item, idx) => {
       return <li className={styles.listItem} key={idx}>
          <div className={styles.inventoryListIndex}>{idx + 1}</div>
          <div className={styles.inventoryListName}>{item.name}</div>
          <div className={styles.listContainerText}>
            {orderBy === DEFAULT_ORDER_BY ?
              <div style={styleDiagramText(item.count, data[0].count)} className={styles.listText}>{item.count || 0}</div> :
              <div style={styleDiagramText(item.priceSum, data[0].priceSum)} className={styles.listText}>{Utils.formatPrice(item.priceSum || 0)}</div>
            }
          </div>
        </li>
      })
      }
    </ul>
  )
}

export default List