import React from "react";
import { Redirect, Route, Switch } from "react-router";
import { useSelector } from "react-redux";

import VerticalPageWrapper from "../../base/components/VerticalPageWrapper";
import { SHIPPING_GROUP_LINKS } from "./config";
import Picking from "./picking";
import Shipping from "./shipping";

import styles from "./common.module.scss";
import FilterDropdown from "../../base/components/FilterDropdown";
import { MarketplacesFilterOptions } from "../drafts/constants";

const ShippingBase = () => {
  const counters = useSelector((state) => state.Counters);

  return (
    <VerticalPageWrapper
      className={styles.page}
      title="Shipping"
      titleMeta="Shipping"
      hasButton={false}
      tabs={[
        {
          link: SHIPPING_GROUP_LINKS.picking,
          title: `Picking ${
            counters.shippingCount.picking
              ? `(${counters.shippingCount.picking})`
              : ""
          }`,
        },
        {
          link: SHIPPING_GROUP_LINKS.shipping,
          title: `Shipping ${
            counters.shippingCount.shipping
              ? `(${counters.shippingCount.shipping})`
              : ""
          }`,
        },
      ]}
      tabChildren={
        <div className={styles.tabChildrenWrapper}>
          <span className={styles.filterTitle}>Marketplace:</span>
          <FilterDropdown
            className="ml-3"
            withAllOption
            filterName="marketplaceType"
            filterOptions={MarketplacesFilterOptions}
          />
        </div>
      }
    >
      <Switch>
        <Route path={SHIPPING_GROUP_LINKS.picking} exact>
          <Picking />
        </Route>
        <Route path={SHIPPING_GROUP_LINKS.shipping} exact>
          <Shipping />
        </Route>
        <Redirect to={`${SHIPPING_GROUP_LINKS.picking}`} />
      </Switch>
    </VerticalPageWrapper>
  );
};

export default ShippingBase;
