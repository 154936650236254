import { DEFAULT_START_PHONE_NUMBER, DEV_DEFAULT_START_PHONE_NUMBER, DEV_FIRST_PREFIX_LENGTH, FIRST_PREFIX_LENGTH } from "../constants/forms"

export const getPrefixDevPhpne = (phoneValue) => {
  return phoneValue.length <= DEV_FIRST_PREFIX_LENGTH
    ? DEV_DEFAULT_START_PHONE_NUMBER
    : DEV_DEFAULT_START_PHONE_NUMBER + phoneValue.replace(DEV_DEFAULT_START_PHONE_NUMBER, '')
}

export const getPrefixPhone = (phoneValue) => {
  return phoneValue.length <= FIRST_PREFIX_LENGTH 
    ? DEFAULT_START_PHONE_NUMBER
    : DEFAULT_START_PHONE_NUMBER + phoneValue.replace(DEFAULT_START_PHONE_NUMBER, '')
}