/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import CustomButton from "../../../../base/components/CustomButton";
import MarketPlaceService from "../../../../services/MarketplaceService";
import StorageService from "../../../../services/StorageService";
import { useService } from "../../../../base/hooks/useService";
import { useOpenWindow } from "../../hooks/useOpenWindow";
import {
  KEY_EBAY_CONNECT,
  KEY_ETSY_CONNECT,
} from "../../../../base/constants/storage";
import { usePoshmarkIsConnect } from "../../hooks/Poshmark/usePoshmarkIsConnect";
import { useEtsyIsConnect } from "../../hooks/useEtsyIsConnect";
import { useEbayIsConnect } from "../../hooks/useEbayIsConnect";
import { EBAY_PARAMS } from "../../pages/Settings/page/MarketplaceConnection/MarketplacePlatforms";

const ConnectButton = ({ marketplaceData, handleUpdateMarketplace }) => {
  const { isConnected } = useEbayIsConnect();
  const marketplaceService = useService(MarketPlaceService);

  const storage = useService(StorageService);
  const [connectionInfo, handleOpenWindow] = useOpenWindow(KEY_EBAY_CONNECT);

  useEffect(() => {
    if (connectionInfo?.state !== EBAY_PARAMS.STATE) return;
    if (connectionInfo?.code) {
      marketplaceService
        .connectEbayAccount(connectionInfo.code)
        .then(() => handleUpdateMarketplace())
        .catch((e) => Bugsnag.notify(e))
        .finally(() => storage.set(KEY_EBAY_CONNECT, {}));
    }
  }, [connectionInfo, storage, marketplaceService, handleUpdateMarketplace]);

  const handleSignIn = useCallback(() => {
    marketplaceService
      .getEbayAccount()
      .then(({ data: { authUrl } }) => authUrl)
      .then(handleOpenWindow)
      .catch((e) => Bugsnag.notify(e));
  }, [marketplaceService, handleOpenWindow]);

  return (
    <CustomButton className="filled-sm" onClick={handleSignIn}>
      {marketplaceData?.data.ebayAccount && isConnected
        ? "Reconnect"
        : "Connect"}
    </CustomButton>
  );
};

export const ConnectButtonEtsy = ({
  marketplaceData,
  handleUpdateMarketplace,
}) => {
  const { isConnected } = useEtsyIsConnect();
  /**
   * @type {MarketPlaceService}
   */
  const marketplaceService = useService(MarketPlaceService);

  const storage = useService(StorageService);
  const [connectionInfo, handleOpenWindow] = useOpenWindow(KEY_ETSY_CONNECT);

  useEffect(() => {
    if (connectionInfo?.state === EBAY_PARAMS.STATE) return;
    if (connectionInfo?.code) {
      marketplaceService
        .connectEtsyAccount(`${connectionInfo.code}`)
        .then(() => handleUpdateMarketplace())
        .catch((e) => Bugsnag.notify(e))
        .finally(() => storage.set(KEY_ETSY_CONNECT, {}));
    }
  }, [connectionInfo, handleUpdateMarketplace, marketplaceService, storage]);

  const handleSignIn = useCallback(() => {
    marketplaceService
      .getEtsyAccount()
      .then(({ data: { authUrl } }) => authUrl)
      .then(handleOpenWindow)
      .catch((e) => Bugsnag.notify(e));
  }, [marketplaceService, handleOpenWindow]);

  return (
    <CustomButton className="filled-sm" onClick={handleSignIn}>
      {marketplaceData?.data.etsyAccount && isConnected
        ? "Reconnect"
        : "Connect"}
    </CustomButton>
  );
};

export const ConnectButtonPoshmark = () => {
  const { isConnected, handleSignIn } = usePoshmarkIsConnect();
  const marketplaceService = useService(MarketPlaceService);
  const [isPoshmarkConnectedToFearn, setIsPoshmarkConnectedToFearn] =
    useState(false);

  useEffect(() => {
    marketplaceService
      .getMarketplace()
      .then((response) => {
        if (response?.data?.poshmarkAccountDto) {
          setIsPoshmarkConnectedToFearn(true);
        } else {
          setIsPoshmarkConnectedToFearn(false);
        }
      })
      .catch((e) => Bugsnag.notify(e));
  }, [isConnected]);

  return (
    <CustomButton className="filled-sm" onClick={handleSignIn}>
      {isConnected && isPoshmarkConnectedToFearn ? "Reconnect" : "Connect"}
    </CustomButton>
  );
};

export default ConnectButton;
