import {
  MAX_DRAFT_BUSINESS,
  MAX_DRAFT_STARTER,
  TYPES,
} from "../../../base/components/Subscription/constant/subscription";

const getDisabledFromMarketplace = {
  draft: {
    [TYPES.TRIAL]: () => false,
    [TYPES.STARTER]: (used) => used >= MAX_DRAFT_STARTER,
    [TYPES.BUSINESS]: (used) => used >= MAX_DRAFT_BUSINESS,
    [TYPES.UNLIMITED]: () => false,
    [TYPES.EXPIRED]: () => true,
  },
  inventory: {
    [TYPES.TRIAL]: () => false,
    [TYPES.STARTER]: (used) => used >= MAX_DRAFT_STARTER,
    [TYPES.BUSINESS]: (used) => used >= MAX_DRAFT_BUSINESS,
    [TYPES.UNLIMITED]: () => false,
    [TYPES.EXPIRED]: () => true,
  },
};

export const isDisabledDraft = (type, used, marketplace) => {
  return getDisabledFromMarketplace?.[marketplace][type]?.(used);
};
