import Http from "./Http";

class ProductReturnsService extends Http {
  static $displayName = "ProductReturnsService";

  createReturnProduct(values) {
    return this.post("/products/returns", values);
  }

  getListReturnProducts(params) {
    return this.get("/products/returns", { params });
  }

  updateReturnProduct(id, values) {
    return this.put(`/products/returns/${id}`, values);
  }

  deleteReturnProduct(idReturn) {
    return this.delete(`/products/returns/${idReturn}`);
  }

  relistReturnProduct(id) {
    return this.patch(`/products/returns/${id}`);
  }
}

export default ProductReturnsService;
