import React from 'react'
import PropTypes from 'prop-types';

const SelectContainer = ({children}) => {
  return (
    <>
      {children}
    </>
  );
};

SelectContainer.propTypes = {
  children: PropTypes.any
}

export default SelectContainer