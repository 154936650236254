/* eslint-disable react/prop-types */
import React from "react";
import { components as ReactSelectComponents } from "react-select";

import styles from "./index.module.scss";

const VirtualizeSelectOption = (props) => {
  // delete props.innerProps.onMouseMove;
  // delete props.innerProps.onMouseOver;

  return (
    <div className={styles.wrapper}>
      <ReactSelectComponents.Option {...props}>
        {props.children}
      </ReactSelectComponents.Option>
    </div>
  );
};

export default VirtualizeSelectOption;
