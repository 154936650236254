import React from "react";
import PropTypes from "prop-types";

const image =
  "https://www.nomadfoods.com/wp-content/uploads/2018/08/placeholder-1-e1533569576673.png";

export default function Placeholder({ text = "No matches are found" }) {
  return (
    <section className="table-placeholder">
      <span className="table-placeholder__title">{text}</span>
    </section>
  );
}

Placeholder.propTypes = {
  text: PropTypes.string,
};
