import React from 'react'
import { Spinner } from 'reactstrap';

const Loader = () => {
  return (
    // <div className='d-flex justify-content-center'>
    //   <p className='md-green'>
    //     <i className='bx bx-loader bx-spin font-size-18 align-middle me-2'></i>
    //     Load more
    //   </p>
    // </div>

    <div className='d-flex justify-content-center mt-3'>
      <Spinner style={{
        width: "48px",
        height: "48px",
        color: "rgba(47, 102, 92, 1)"
      }} />
    </div>
  )
}

export default Loader;