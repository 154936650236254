import React from 'react'
import PropTypes from "prop-types";

const ProfileImage = ({ src, name }) => {
  return (
    <div className='d-flex align-items-center'>
      <div className='image-wrapper'>
        <img 
          src={src}
          className="profile-logo header-profile-user" 
          alt="placeholder" 
        />
      </div>
      <span className="d-none d-sm-inline-block ml-2 mr-1">
        {name}
      </span>
      <i className="mdi mdi-chevron-down"></i>
    </div>    
  )
};

ProfileImage.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string
};

export default ProfileImage;