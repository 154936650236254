import React from "react";
import PerformanceMonthlyGoal from "../../components/PerformanceMonthlyGoal";
import { Row, Col } from "reactstrap";
import Overview from "../../components/Overview";
import Averages from "../../components/Averages";


const MonthlyOverview = () => {
  return (
    <div className="mt-3">
      <PerformanceMonthlyGoal />
      <Row>
        <Col>
          <Overview />
        </Col>
        <Col>
          <Averages />
        </Col>
      </Row>
    </div>
  )
}

export default MonthlyOverview;