import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FormGroup } from "reactstrap";
import { useField } from "formik";

import ErrorMessageDefault from "./ErrorMessageDefault";
import { useTranslate } from "../hooks/useTranslate";
import FieldDescription from "./FieldDescription";
import { useTriggerFormChanges } from "../../pages/drafts/hooks/useDetectDirtyForm";

const FormikTextarea = ({
  label,
  bottomText = "",
  ErrorMessage = ErrorMessageDefault,
  min,
  max,
  isShowLengthDescription = false,
  isHideLabel = false,
  maxLength,
  description = "",
  className = "mb-3",
  maxLengthDescription,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [translate] = useTranslate();
  const { markChanges, containerRef } = useTriggerFormChanges();

  const isError = meta.error && meta.touched;

  const allProps = {
    ...field,
    ...props,
  };

  return (
    <FormGroup className={className}>
      {label && !isHideLabel && (
        <label
          htmlFor={props.name}
          className={classnames({ "text-danger": isError })}
        >
          {label}
        </label>
      )}
      <textarea
        className={classnames("form-control", {
          "is-invalid-input is-invalid": isError,
        })}
        {...allProps}
        onChange={(event) => {
          allProps.onChange(event);
          markChanges();
        }}
        maxLength={maxLength}
      />
      <div className="d-flex align-items-center" ref={containerRef}>
        {bottomText && (
          <span className="font-size-10 text-gray-gomi mb-1 mr-3">
            {bottomText}
          </span>
        )}
        {isShowLengthDescription && (
          <FieldDescription
            isWithError={isError}
            valueLength={field?.value?.length}
            description={description}
            maxLength={maxLengthDescription || maxLength}
          />
        )}
      </div>

      <ErrorMessage name={props.name}>
        {translate(meta.error, { label, min, max })}
      </ErrorMessage>
    </FormGroup>
  );
};

FormikTextarea.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  rows: PropTypes.string,
  label: PropTypes.string,
  bottomText: PropTypes.string,
  ErrorMessage: PropTypes.node,
  min: PropTypes.number,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  isShowLengthDescription: PropTypes.bool,
  isHideLabel: PropTypes.bool,
  description: PropTypes.string,
  className: PropTypes.string,
  maxLengthDescription: PropTypes.number,
};

export default FormikTextarea;
