import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import "./index.scss";
import { PRODUCT_STATUS_STYLES } from "./constants";

const ProductStatus = ({
  statusStyle = PRODUCT_STATUS_STYLES.notPosted,
  children,
}) => {
  const classNamesMap = {
    [PRODUCT_STATUS_STYLES.isPosted]: "is-posted",
    [PRODUCT_STATUS_STYLES.notPosted]: "not-posted",
  };

  return (
    <div className={classnames("product-status", classNamesMap[statusStyle])}>
      {children}
    </div>
  );
};

ProductStatus.propTypes = {
  statusStyle: PropTypes.string,
  children: PropTypes.any,
};

export default ProductStatus;
