/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import Bugsnag from "@bugsnag/js";

import ImportModal from "../../../../../base/components/ImportModal";
import ErrorMarketplaceConnection from "./ErrorEbayConnection";
import EbayLogo from "../../../../../base/components/EbayLogo";

import DraftsService from "../../../../../services/DraftsService";

import { SOLD_INVENTORY_LABEL } from "../../../constants";
import {
  ImportPopupEbayService,
  ImportPopupEbaySoldService,
} from "../../../services/index";
import { useService } from "../../../../../base/hooks/useService";
import ImportEbay from "../../../../../services/importServices/ImportEbay";
import { MARKETPLACES } from "../../../../../base/constants/extension";
import { EBAY_TIME_PERIOD_OPTIONS } from "../../../../../base/constants/importEbay";

export const useImportService = (
  Service,
  loadData,
  marketplaceName,
  timePeriod = null
) => {
  const [, rerender] = useState(Date.now());
  const serviceRef = useRef(
    new Service({ loadData, marketplaceName, timePeriod })
  );

  useEffect(() => {
    serviceRef.current.onUpdate(() => rerender(Date.now()));
  }, []);

  return [serviceRef.current];
};

const EbayImportModal = (props) => {
  const { data, status } = props;
  const [timePeriod, setTimePeriod] = useState(null);

  /**
   * @type {DraftsService}
   */
  const draftsService = useService(DraftsService);
  /**
   * @type {ImportEbay}
   */
  const importEbay = useService(ImportEbay);

  const loadDataSold = useCallback(
    (tabId, pagination, timePeriodValue = null) => {
      return importEbay
        .importSoldProducts(
          tabId,
          pagination || 0,
          timePeriodValue || EBAY_TIME_PERIOD_OPTIONS[6].value
        )
        .then((response) => {
          const {
            result: {
              data: { items, pagination },
            },
          } = response;
          return {
            data: items.map((data) => {
              const {
                order: { lineItems = [] },
              } = data;

              const dateItem = lineItems.find(
                (lineItem) => lineItem.lineItem.indexOf("Order date") !== -1
              );

              let soldDate = null;

              if (dateItem) {
                soldDate = new Date(
                  dateItem.lineItem.replace("Order date", "")
                );
              }

              return {
                ...data,
                dateSold: soldDate,
                imageSrc: data.imageUrls?.[0] || null,
              };
            }),
            pagination,
            hasNextPage: !!pagination,
          };
        })
        .catch((e) => {
          Bugsnag.notify(e);
        });
    },
    [importEbay]
  );

  const isSold = status === SOLD_INVENTORY_LABEL;

  const loadData = useCallback(
    (pagination) => {
      return draftsService.getProductsEbay(pagination);
    },
    [draftsService]
  );

  const [service] = useImportService(
    isSold ? ImportPopupEbaySoldService : ImportPopupEbayService,
    isSold ? loadDataSold : loadData,
    MARKETPLACES.EBAY,
    timePeriod
  );

  const filterSoldItemWithoutOrder = useCallback(({ orderId }) => {
    return !!orderId;
  }, []);

  const filters = useMemo(() => {
    if (!isSold) return [];
    return [filterSoldItemWithoutOrder];
  }, [isSold, filterSoldItemWithoutOrder]);

  if (!data) {
    return <ErrorMarketplaceConnection {...props} />;
  }

  return (
    <ImportModal
      title="Import from eBay"
      products={service.getVisibleItems()}
      service={service}
      productFilters={filters}
      setTimePeriod={setTimePeriod}
      timePeriod={timePeriod}
      {...props}
    >
      <EbayLogo />
    </ImportModal>
  );
};

EbayImportModal.propTypes = {
  data: PropTypes.any,
};

export default EbayImportModal;
