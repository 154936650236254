import styled from "styled-components";

import Icon from "../../../../base/components/Icon";
import { DropdownItem } from "reactstrap";

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;

interface LinkIconStyleProps {
  disabled?: boolean;
}

export const LinkIcon = styled(Icon)<LinkIconStyleProps>`
  width: 16px;
  height: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  opacity: ${({ disabled = false }) => (disabled ? "0.4" : "1")};
`;

export const CheckIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  transition: all 0.2s ease;
`;

export const DropdownItemContainer = styled(DropdownItem)`
  display; flex;
  flex-diection: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &:hover > div {
    opacity: 1.0;
  }
`;

export const AddIcon = styled.div`
  font-size: 22px;
  color: #2f665c;
  transition: all 0.2s ease;
  opacity: 0.2;
`;
