/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import Bugsnag from "@bugsnag/js";

import SocialLogin from "../../../base/components/SocialLogin";
import { useService } from "../../../base/hooks/useService";
import AuthService from "../../../services/AuthService";
import { useLoading } from "../../../base/hooks/useLoading";
import { useAfterLogin } from "../hooks/useAfterLogin";

const GoogleAuth = ({ children }) => {
  const authService = useService(AuthService);
  const [, { registerPromise }] = useLoading();
  const { redirect } = useAfterLogin();
  const onSuccess = useCallback(
    (token) => {
      registerPromise(authService.registerLoginWithGoogle(token))
        .then(redirect)
        .catch((e) => Bugsnag.notify(e));
    },
    [registerPromise, authService, redirect]
  );

  const onFail = (error) => console.log("Error", error);

  return (
    <SocialLogin onSuccess={onSuccess} onFail={onFail}>
      {children}
    </SocialLogin>
  );
};

export default GoogleAuth;
