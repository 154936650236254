import get from "lodash.get";

const queueMap = {};

export const WAIT_GROUPS = {
  CREATE_ORDERS: "create_orders",
};

function addToQueueAdapter(adapter, name) {
  return config => {
    // Do request
    const request = adapter(config);
    const queue = get(queueMap, name, []);

    queueMap[name] = [
      ...queue,
      request.catch(console.error)
    ];

    request.finally(() => {
      queueMap[name] = queueMap[name].filter(promise => promise !== request);
    });

    return request;
  };
}

function waitQueueAdapter(adapter, name) {
  return config => {
    // Do request
    const queue = get(queueMap, name, []);

    const request = () => adapter(config);

    // wait dependencies requests
    // and make general request

    return Promise.all(queue)
      .catch(request)
      .then(request)
  };
}

export function awaitQueueAdapter(adapter, name) {
  return addToQueueAdapter(waitQueueAdapter(adapter, name), name)
}
