import {Route, Switch} from "react-router-dom";
import React from "react";
import {OAUTH_GROUP_LINKS} from "./config";
import EtsyOAuth from "./pages/Etsy";
import EbayOAuth from "./pages/Ebay";

const OAuth = () => {
	return (
		<div className="page-content">
			<Switch>
				<Route path={OAUTH_GROUP_LINKS.ETSY} component={EtsyOAuth} />
				<Route path={OAUTH_GROUP_LINKS.EBAY} component={EbayOAuth} />
			</Switch>
		</div>
	)
};

export default OAuth
