import React, { useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import PageTitle from '../../../profile/components/PageName/index'
import VerticalTabs from "../../../../base/components/VerticalTabs";
import MarketPlaceConnection from "./page/MarketplaceConnection";
import EmployeeManagement from "./page/EmployeeManagement";
import PageMeta from "../../../../base/components/MetaTags";

import { SETTINGS_LINKS } from "./config";
import SubscriptionSetting from "./page/SubscriptionSetting";
import PaymentSetting from "./page/PaymentSetting";
import { useGetActiveSubscription } from "./hooks/useGetActiveSubscription";
import { getSettingTabs } from "./tabs";
import { useProfile } from "../../../profile/hooks/useProfile";

const Settings = () => {
  const activeSubscription = useGetActiveSubscription();
  const [{ role }] = useProfile();

  const settingTabs = useMemo(() => getSettingTabs(activeSubscription?.type, role), [activeSubscription, role]);

  return (
    <div className="page-content">
      <Container fluid>
        <PageMeta title='Settings' />
        <PageTitle title='SETTINGS' />
        <VerticalTabs tabs={settingTabs} />
        <Switch>
          <Route path={SETTINGS_LINKS.MARKETPLACE} component={MarketPlaceConnection} />
          <Route path={SETTINGS_LINKS.EMPLOYEE} component={EmployeeManagement} />
          <Route path={SETTINGS_LINKS.SUBSCRIPTION} component={SubscriptionSetting} />
          <Route path={SETTINGS_LINKS.PAYMENT} component={PaymentSetting} />
        </Switch>
      </Container>
    </div>
  );
};

export default Settings;
