import React from "react";
import { CardBody, Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import classNames from "classnames";

import IconButton from "../../../drafts/components/IconButton";
import WithHighlight from "../../../drafts/components/WithHighlight";
import { getFormattedDate } from "../../../drafts/helpers/format";
import Icon from "../../../../base/components/Icon";

import styles from "./index.module.scss";

const MileageCard = ({
  id,
  title,
  mileageMadeAt,
  mileageQuantity,
  onEdit,
  onDelete,
}) => {
  return (
    <CardBody className="border-bottom">
      <Row className="py-1 cursor-pointer">
        <Col xs="12" sm="5" md="5" className="d-flex align-items-center">
          <WithHighlight
            text={title || "-"}
            className={classNames("fw-bold", styles.title)}
          />
        </Col>
        <Col xs="2" md="3" className="d-flex align-items-center">
          <p className="mb-0">{getFormattedDate(mileageMadeAt)}</p>
        </Col>
        <Col xs="2" md="2" className="d-flex align-items-center">
          <p className="mb-0 text-nowrap">{`${mileageQuantity} ml`}</p>
        </Col>
        <Col
          xs="9"
          sm="5"
          md="2"
          className="d-flex align-items-center justify-content-end justify-content-sm-center"
        >
          <div className="flex-grow-1" />
          <IconButton
            onClick={(event) => onEdit(id, event)}
            className="ms-2 action-icon-button d-flex align-items-center"
          >
            <Icon icon="editIcon" />
          </IconButton>
          <IconButton
            onClick={(event) => onDelete(id, event)}
            className="ms-2 action-icon-button d-flex align-items-center"
          >
            <Icon icon="deleteIcon" />
          </IconButton>
        </Col>
      </Row>
    </CardBody>
  );
};

MileageCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  createdAt: PropTypes.string,
  mileageQuantity: PropTypes.number,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default MileageCard;
