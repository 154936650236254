import React from "react";
import { components as comp } from "react-select";

import styles from "./index.module.scss";

export const CategoryOption = (props) => {
  const {
    data: { __isNew__ },
    innerProps,
    isSelected,
  } = props;

  const className = ["Select-Option", isSelected ? "selected-option" : ""].join(
    " "
  );

  if (__isNew__)
    return (
      <div
        {...innerProps}
        className={styles.createNewOptionWrapper || className}
      >
        <span>
          <i className="bx bx-plus" />
          Create new category
        </span>
      </div>
    );

  return <comp.Option {...props}>{props.children}</comp.Option>;
};
