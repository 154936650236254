import * as yup from "yup";

import {
  USER_EMAIL,
  USER_FIRSTNAME,
  USER_LASTNAME,
} from "../../../../../../../../validation/user";

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
};

export const validationSchema = yup.object().shape({
  firstName: USER_FIRSTNAME.required(),
  lastName: USER_LASTNAME.required(),
  email: USER_EMAIL.required(),
});
