import Bugsnag from "@bugsnag/js";

import MercariExtension from "../../../services/MercariExtension";
import ProductStatusService from "../../../services/ProductStatusService";
import { useService } from "../../../base/hooks/useService";
import { FORM_TYPES } from "../../drafts/const/form";
import PoshmarkExtension from "../../../services/PoshmarkExtension";
import { MARKETPLACES } from "../../../base/constants/extension";
import PoshmarkService from "../../../services/PoshmarkService";
import { createPoshmarkModelForRequest } from "../../drafts/helpers/Poshmark/createPoshmarkModelForRequest";

export const useDelistByMarketplace = () => {
  /**
   * @type {ProductStatusService}
   */
  const productStatusService = useService(ProductStatusService);
  const poshmarkService = useService(PoshmarkService);

  const delistProductByMarketplaceMethodsMap = {
    // eslint-disable-next-line no-unused-vars
    [MARKETPLACES.EBAY]: async (listingId, id) => {
      // On the backend, these requests are called for eBay and Etsy
      // return await productStatusService.delist(listingId, FORM_TYPES.EBAY);
      return Promise.resolve();
    },
    // eslint-disable-next-line no-unused-vars
    [MARKETPLACES.ETSY]: async (listingId, id) => {
      // On the backend, these requests are called for eBay and Etsy
      // return await productStatusService.delist(listingId, FORM_TYPES.ETSY);
      return Promise.resolve();
    },
    [MARKETPLACES.POSHMARK]: async (listingId, id) => {
      const poshmarkExtension = new PoshmarkExtension();

      try {
        const invItem = await poshmarkExtension.getDraft(listingId);

        if (invItem) {
          const { convertedData } = createPoshmarkModelForRequest(
            {
              productPoshmarkSpecificFields: invItem,
            },
            invItem
          );

          convertedData.inventory = {
            ...convertedData.inventory,
            size_quantities: [
              {
                size_id: "fearn",
                size_obj: {
                  display: "fearn",
                  display_with_size_set: "fearn",
                  id: "fearn",
                  size_systems: "us",
                },
              },
            ],
          };

          convertedData.brand = invItem.brand_id;
          convertedData.price_amount = invItem.prince_amount;
          convertedData.original_price_amount = invItem.original_price_amount;
          convertedData.colors = invItem.colors?.map((color) => color.name);

          await poshmarkExtension.editInventory(listingId, [], convertedData);
          await poshmarkService.delayPromise(5000);
        }

        await poshmarkExtension.deleteInventory(listingId);
      } catch (e) {
        Bugsnag.notify(e, (event) => {
          event.addMetadata("poshmarkDelistError", { id: listingId });
        });

        await poshmarkExtension.deleteInventory(listingId);
      }

      return productStatusService.delist(id, FORM_TYPES.POSHMARK);
    },
    [MARKETPLACES.MERKARI]: async (listingId, id) => {
      const mercariExtension = new MercariExtension();

      await mercariExtension.deleteActiveInventory(listingId);
      return productStatusService.delist(id, FORM_TYPES.MERCARI);
    },
  };

  const delistProduct = (
    marketplaceType,
    productListingId,
    activeInventories
  ) => {
    const {
      data: { id },
    } = activeInventories[productListingId];

    delistProductByMarketplaceMethodsMap[marketplaceType](productListingId, id);

    const marketplacesForDelist =
      activeInventories[productListingId]?.marketplacesForDelist || [];

    const promisesForDelist = marketplacesForDelist.map(
      ({ marketplaceType, listingId, id = null }) => {
        return (
          delistProductByMarketplaceMethodsMap[marketplaceType](
            listingId,
            id
          ) || Promise.resolve()
        );
      }
    );

    return Promise.all(promisesForDelist);
  };

  return [delistProduct];
};
