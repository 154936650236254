import React from "react";
import CustomButton from "../../../../base/components/CustomButton";

const CreateButton = ({buttonText = "Create return", onClick}) => {
  return (
    <>
      <CustomButton className="filled-primary" onClick={onClick}>
        {buttonText}
      </CustomButton>
    </>
  );
};

export default CreateButton;