/* eslint-disable react/prop-types */
import React, { memo, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Bugsnag from "@bugsnag/js";
import styled from "styled-components";

import CustomButton from "../../../../CustomButton";
import CostAlert from "../../CostAlert";

import SubscriptionService from "../../../../../../services/SubscriptionService";
import CreditCardService from "../../../../../../services/CardService";
import { CREDIT_STYLES } from "./style";
import { useDisabledSubmit } from "../../../../../hooks/useDisabledSubmit";
import {
  CARD_EXPIRY,
  REQUIRED_CARD_FIELDS,
  VALIDATION_FIELDS,
} from "../../../constant/cardFields";
import { useService } from "../../../../../hooks/useService";
import { WaitSubscribtionContext } from "../../../../../contexts/CustomErrorMessage";
import { useStatefullProvider } from "../../../../../hooks/useProvider";
import { useCreditCardData } from "../../../hooks/useCreditCardData";
import style from "../../../../../../pages/drafts/pages/Settings/index.module.scss";
import StripeInputs from "./components/StripeInputs";
import PrefiledCardInputs from "./components/PrefiledCardInputs";
import { NavLink } from "react-router-dom";
import { PAYMENT_LINKS } from "../../../../../../pages/drafts/pages/Settings/page/PaymentSetting/config";
import ToasterService from "../../../../../../services/ToastService";

const ErrorMessage = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
  margin-top: -16px;
  color: #990000;
`;

const StripeCreditCard = ({
  handleIsOpen = () => {},
  chosenSubscription,
  onChangeStep,
  isChangingSubscription = false,
  onChangeSubscriptionPlan,
  pricesDifference = null,
  isPostSubscription,
  onClose,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const toastr = useService(ToasterService);
  const [ccError, setCCError] = useState(null);

  const { creditCard } = useCreditCardData();

  const lastFourDigits = useMemo(() => {
    return creditCard?.lastFourDigits;
  }, [creditCard]);

  const creditCardService = useService(CreditCardService);
  const subscriptionService = useService(SubscriptionService);

  const { isDisabled, onChange, errors } = useDisabledSubmit(
    REQUIRED_CARD_FIELDS,
    VALIDATION_FIELDS
  );

  const [{ wait }, updateWaitSubscrb] = useStatefullProvider(
    WaitSubscribtionContext
  );
  const [isCurrentCard, setIsCurrentCard] = useState(!!creditCard?.id);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const cardNumber = elements.getElement(CardNumberElement);
    const { token, error } = await stripe.createToken(cardNumber, { name });

    if (error) {
      Bugsnag.notify(error);
      // TODO ERROR
      return;
    }

    const { id: stripeCardId } = token;

    try {
      updateWaitSubscrb({ wait: true });
      await creditCardService.addCard({ stripeCardId });
      if (isChangingSubscription) {
        await onChangeSubscriptionPlan({
          subscriptionType: chosenSubscription.type,
        });
      } else {
        await subscriptionService.subscribe({
          subscriptionType: chosenSubscription.type,
        });
      }
    } catch (baseError) {
      const { error } = baseError;
      Bugsnag.notify(baseError);
      toastr.error(error.message);
      updateWaitSubscrb({ wait: false });
    }
  };

  const handleChangeButtonClick = async () => {
    try {
      setCCError(null);
      updateWaitSubscrb({ wait: true });

      if (isChangingSubscription && !isPostSubscription) {
        await onChangeSubscriptionPlan({
          subscriptionType: chosenSubscription.type,
        });
      } else {
        await subscriptionService.subscribe({
          subscriptionType: chosenSubscription.type,
        });
        onClose();
      }
    } catch (e) {
      Bugsnag.notify(e.error);
      setCCError(e.error.message);
      updateWaitSubscrb({ wait: false });
    }
  };

  useEffect(() => {
    setIsCurrentCard(!!creditCard?.id);
  }, [creditCard]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="ml-3 mr-3 position-relative">
        <>
          {isCurrentCard && (
            <NavLink
              onClick={() => handleIsOpen(false)}
              to={PAYMENT_LINKS.EDIT}
              className={style.changeCardLabel}
            >
              Change payment card
            </NavLink>
          )}
          {isCurrentCard ? (
            <PrefiledCardInputs lastFourDigits={lastFourDigits} />
          ) : (
            <StripeInputs
              onChange={onChange}
              styleObject={CREDIT_STYLES}
              error={errors[CARD_EXPIRY]}
            />
          )}
          <p className={style.secondaryText}>
            To apply a higher plan, the difference will be charged
          </p>
        </>
        <CostAlert
          title={chosenSubscription?.title}
          price={chosenSubscription?.price}
          pricesDifference={pricesDifference}
          isChangingSubscription={isChangingSubscription}
        />
      </div>
      {ccError && !wait && <ErrorMessage>{ccError}</ErrorMessage>}
      {wait && (
        <div className="d-flex justify-content-center mb-3">
          <Spinner color="success" />
        </div>
      )}
      <div className="d-flex justify-content-end">
        <CustomButton
          className="outline-primary mr-2 w-130"
          onClick={onChangeStep}
          disabled={wait}
        >
          Back
        </CustomButton>
        {isCurrentCard ? (
          <CustomButton
            type={"button"}
            onClick={handleChangeButtonClick}
            className="filled-primary"
          >
            Subscribe
          </CustomButton>
        ) : (
          <CustomButton
            type={"submit"}
            className="filled-primary"
            disabled={isDisabled || wait}
          >
            Subscribe
          </CustomButton>
        )}
      </div>
    </form>
  );
};

StripeCreditCard.propTypes = {
  onChangeStep: PropTypes.func,
  chosenSubscription: PropTypes.any,
  onToogleModal: PropTypes.func,
  onChangeSubscriptionPlan: PropTypes.func,
  isChangingSubscription: PropTypes.bool,
};

export default memo(StripeCreditCard);
