import React, { useRef, useState } from "react";
import { Card, CardBody, Col, Collapse, Input, Row, Tooltip } from "reactstrap";

import ActionsButtons from "../ActionsButtons";
import TaskComments from "../TaskComments";
import { useTooltipProps } from "../../../sold/pages/SoldList/table";
import ModalConfirm from "../ModalConfirm";

import "./styles.scss";
import EditTaskModal from "../EditTaskModal";

const TaskCard = ({
  id,
  title,
  assignee: { id: assigneeId },
  creator: { firstName, lastName },
  description,
  commentsCount,
  profileId,
  isAssignee,
  onChangeMessage,
  onDeleteTask,
  onDeleteMessage,
  onCreateMessage,
  onEditTask,
}) => {
  const [isOpenCollapse, setIsOpenCollapse] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [isOpenModalMarkDone, setIsOpenModalMarkDone] = useState(false);
  const [isOpenModalDeleteTask, setIsOpenModalDeleteTask] = useState(false);
  const [isOpenModalEditTask, setIsOpenModalEditTask] = useState(false);

  const checkboxRef = useRef(null);

  const handleToggleCollapse = () =>
    setIsOpenCollapse((prevState) => !prevState);

  const handleToggleModalMarkDone = () =>
    setIsOpenModalMarkDone((prevState) => !prevState);
  const handleToggleModalDeleteTask = () =>
    setIsOpenModalDeleteTask((prevState) => !prevState);
  const handleToggleModalEditTask = () =>
    setIsOpenModalEditTask((prevState) => !prevState);

  const handleClickMarkDone = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (isChecked) return setIsChecked(false);

    handleToggleModalMarkDone();
  };

  const handleApproveModalMarkDone = async () => {
    await onDeleteTask(id);
    setIsChecked(true);
    handleToggleModalMarkDone();
  };

  const handleApproveModalDeleteTask = async () => {
    await onDeleteTask(id);
    handleToggleModalDeleteTask();
  };

  const handleApproveEditModalTask = (...props) =>
    onEditTask(id, handleToggleModalEditTask, ...props);

  const computedInitialState = { title, description, assigneeId };
  const computedInitialSelect = {
    label: `${firstName} ${lastName}`,
    value: assigneeId,
  };

  const tooltipOptions = useTooltipProps(checkboxRef);

  return (
    <>
      <Card className="mb-2">
        <CardBody>
          <Row className="py-1 cursor-pointer" onClick={handleToggleCollapse}>
            <Col
              xs="12"
              sm="5"
              md="8"
              className="d-flex align-items-center mb-2 mb-sm-0"
            >
              <p className="mb-0 fw-bold font-size-13 text-truncate">{title}</p>
            </Col>
            <Col xs="2" md="1" className="d-flex align-items-center">
              {isAssignee && (
                <>
                  <Input
                    innerRef={checkboxRef}
                    type="checkbox"
                    className="task-checkbox cursor-pointer"
                    onClick={handleClickMarkDone}
                    onChange={() => {}}
                    checked={isChecked}
                  />
                  <Tooltip placement="top" {...tooltipOptions}>
                    Mark is done
                  </Tooltip>
                </>
              )}
            </Col>
            <Col
              xs="9"
              sm="5"
              md="3"
              className="d-flex align-items-center justify-content-end justify-content-sm-center"
            >
              <ActionsButtons
                commentsCount={commentsCount}
                onDeleteTask={handleToggleModalDeleteTask}
                onEditTask={handleToggleModalEditTask}
                isAssignee={isAssignee}
                isOpen={isOpenCollapse}
              />
            </Col>
          </Row>
          <Collapse isOpen={isOpenCollapse}>
            <div className="mt-2">
              <p className="mb-0">
                From:
                <span className="ms-2 task-from fw-bold">{`${firstName} ${lastName}`}</span>
              </p>
              <p className="mt-2 pb-3 task-description">{description}</p>
              <TaskComments
                taskId={id}
                profileId={profileId}
                isOpen={isOpenCollapse}
                onChangeMessage={onChangeMessage}
                onDeleteMessage={onDeleteMessage}
                onCreateMessage={onCreateMessage}
              />
            </div>
          </Collapse>
        </CardBody>
      </Card>

      <ModalConfirm
        isOpen={isOpenModalMarkDone}
        title="Finish task"
        text="Are you sure you want to mark the task as Done?"
        onClose={handleToggleModalMarkDone}
        onClick={handleApproveModalMarkDone}
      />

      <ModalConfirm
        isOpen={isOpenModalDeleteTask}
        title="Delete task"
        text="Are you sure you want to delete the task?"
        onClose={handleToggleModalDeleteTask}
        onClick={handleApproveModalDeleteTask}
      />

      <EditTaskModal
        isOpen={isOpenModalEditTask}
        onClose={handleToggleModalEditTask}
        onClick={handleApproveEditModalTask}
        initialState={computedInitialState}
        initialStateSelect={computedInitialSelect}
      />
    </>
  );
};

export default TaskCard;
