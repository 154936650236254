import { useCallback } from "react";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../base/hooks/useService";
import S3Service from "../../../services/S3Service";
import ProfileService from "../../../services/ProfileService";
import { fileTypes } from "../../../base/constants/fileTyles";
import ImageService from "../../../services/ImageService";

export const useUploadAvatar = () => {
  /**
   * @type {S3Service}
   */
  const s3 = useService(S3Service);
  /**
   * @type {ProfileService}
   */
  const profile = useService(ProfileService);
  /**
   * @type {ImageService}
   */
  const imagesService = useService(ImageService);

  const uploadAvatar = useCallback(
    (file) => {
      return file
        ? s3
            .uploadImageFiles([file], fileTypes.userAvatar)
            .then(([{ file }]) => {
              imagesService.resizeLoadedImages([file.id]);

              return profile.createAvatar(file.id).then(() => file);
            })
            .catch((e) => Bugsnag.notify(e))
        : profile.createAvatar();
    },
    [s3, profile, imagesService]
  );

  return {
    uploadAvatar,
  };
};
