import { getCategoryById } from "../helpers/fetchBasicCategories";

class DraftUtils {
  static formatPrice(price) {
    return Number(price).toLocaleString("en-US", {
      style: "currency",
      currency: "USD"
    });
  }

  static extractSuggestedFields(basic) {
    const { brand, categoryId } = basic;
    const category = getCategoryById(categoryId) ;

    return {
      brand,
      categoryName: category?.name || ""
    };
  }
}


export default DraftUtils;
