import { convertSpecPoshmarkFieldsForSaving } from "./convertSpecPoshmarkFieldsForSaving";
import ImageService from "../../../../services/ImageService";


export const createPoshmarkModelForRequest = (fetchedData, formValues) => {
  const convertedData = convertSpecPoshmarkFieldsForSaving(fetchedData?.productPoshmarkSpecificFields, formValues);
  const picturesBase64 = convertedData.pictures.map((picture) => {
    return ImageService.fileToBase64(picture.file);
  });
   return {
     convertedData,
     picturesBase64
   }
}