import React from "react";
import { useField } from "formik";
import { Col, Row } from "reactstrap";

import Divider from "../../Divider";
import FormikGroupAddon from "../../GroupAddon";
import { usePriceValidation } from "../../../../../base/hooks/usePriceValidation";
import { STEP_BY_HUNDREDTH } from "../../../../../base/constants/forms";

const FormPackageDetails = ({ weightLbTitle = "Package weight" }) => {

  const [onChangeWithValidation, onKeyPress] = usePriceValidation({});

  return (
    <>
      <h3 className="bold-black mb-4">Package details</h3>
      <Divider />
      <Row className="mb-4">
        <Col md="6">
          <FormikGroupAddon
            label={weightLbTitle}
            name="weightLb"
            groupText="lb"
            placeholder={"0"}
            onChange={(event, setValue) =>
              onChangeWithValidation(event, setValue)
            }
            onKeyPress={onKeyPress}
            isFieldOnChange={false}
            step={STEP_BY_HUNDREDTH}
          />
        </Col>
        <Col md="6">
          <FormikGroupAddon
            label={weightLbTitle}
            name="weightOz"
            groupText="oz"
            placeholder={"0"}
            onChange={(event, setValue) =>
              onChangeWithValidation(event, setValue)
            }
            onKeyPress={onKeyPress}
            isFieldOnChange={false}
            step={STEP_BY_HUNDREDTH}
          />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md="4">
          <FormikGroupAddon
            label="Length"
            name="depth"
            groupText="in"
            placeholder={"0"}
            onChange={(event, setValue) =>
              onChangeWithValidation(event, setValue)
            }
            onKeyPress={onKeyPress}
            isFieldOnChange={false}
            step={STEP_BY_HUNDREDTH}
          />
        </Col>
        <Col md="4">
          <FormikGroupAddon
            label="Width"
            name="width"
            groupText="in"
            placeholder={"0"}
            onChange={(event, setValue) =>
              onChangeWithValidation(event, setValue)
            }
            onKeyPress={onKeyPress}
            isFieldOnChange={false}
            step={STEP_BY_HUNDREDTH}
          />
        </Col>
        <Col md="4">
          <FormikGroupAddon
            label="Height"
            name="height"
            groupText="in"
            placeholder={"0"}
            onChange={(event, setValue) =>
              onChangeWithValidation(event, setValue)
            }
            onKeyPress={onKeyPress}
            isFieldOnChange={false}
            step={STEP_BY_HUNDREDTH}
          />
        </Col>
      </Row>
    </>
  );
};

export default FormPackageDetails;
