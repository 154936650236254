import * as yup from "yup";
import { LOGIN_PASSWORD, USER_EMAIL } from "../../../../validation/user";

export const validationSchema = yup.object().shape({
  email: USER_EMAIL.required(),
  password: LOGIN_PASSWORD.required(),
});

export const initialValues = {
  email: "",
  password: "",
};
