import React, {useCallback} from "react";
import "./index.scss";
import classnames from 'classnames';
import SizeUtils from "../../../../../utils/Poshmark/SizeUtils";
import { useField } from "formik";
import { useTriggerFormChanges } from "../../../../../hooks/useDetectDirtyForm";

const SizeItem = ({size, setFieldValue, values, currentSystem, currentTab}) => {

    const [,, {setValue}] = useField({name: "sizeName"});
  const { markChanges, containerRef } = useTriggerFormChanges();

    const setSize = (element) => {
        SizeUtils.setSize({
            setFieldValue: setFieldValue,
            tab: currentTab,
            system: currentSystem.id,
            id: element.id
        });
        markChanges();
        setValue(element?.display_with_size_system)
    }

    const getSizeId = useCallback((value) => {
        const [,,sizeId] = SizeUtils.getSize(value)
        return sizeId
    }, [SizeUtils])

    return(
        <div
            className={classnames("mr-3 mb-3  cursor-pointer d-flex align-items-center justify-content-center size-item", {
                active: getSizeId(values.sizeId) === size.id
            })}
            onClick={() => setSize(size)}
            ref={containerRef}
        >
            <p className="font-size-13 fw-medium mb-0 size-name">
                {size.display_with_size_system}
            </p>
        </div>
    )
}

export default SizeItem;
