import Http from "./Http";
import poshmarkCategoriesMap from "../assets/categories/poshmark.config";
import mercariCategoriesMap from "../assets/categories/mercari.config";
import ebayCategoriesMap from "../assets/categories/ebay.config";
import etsyCategoriesMap from "../assets/categories/etsy.config";
import migration from "../assets/categories/migration";

const migrateCategories = (categoriesMap) => {
  const map = {};

  migration.forEach(({ old, new: _new }) => {
    if (!categoriesMap[old]) return;

    map[_new] = categoriesMap[old];
  });

  return map;
};

class CategoriesService extends Http {
  static $displayName = "CategoriesService";

  static POSHMARK_CATEGORIES = migrateCategories(poshmarkCategoriesMap);
  static MERCARI_CATEGORIES = migrateCategories(mercariCategoriesMap);
  static EBAY_CATEGORIES = migrateCategories(ebayCategoriesMap);
  static ETSY_CATEGORIES = migrateCategories(etsyCategoriesMap);

  search(q, parentId) {
    return this.get("/categories", { params: { q, parentId } });
  }
}

export default CategoriesService;
