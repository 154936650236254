import React, { useCallback } from "react";
import { CardBody, Card } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import Bugsnag from "@bugsnag/js";

import FormikInput from "../../../../base/components/FormikInput";
import AuthCardContainer from "../../components/AuthCardContainer";
import AuthHeader from "../../components/Header";
import DevideLine from "../../components/DevideLine";
import FormikPassword from "../../../../base/components/FormikPassword";
import AuthPrivacy from "../../components/AuthPrivacy";
import CustomButton from "../../../../base/components/CustomButton";
import SignUpFooter from "../../components/SignUpFooter";
import FormikEmail from "../../../../base/components/FormikEmail";
import CustomErrorMessage from "../../../../base/components/ErrorMessage";

import AuthService from "../../../../services/AuthService";
import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useRequestErrorMessage } from "../../../../base/hooks/useRequestErrorMessage";
import { initialValues, validationSchema } from "./form";
import { AUTH_GROUP_LINKS } from "../../config";
import { ERROR_EMAIL_MESSAGE } from "../../../../base/constants/forms";
import {
  MAX_FIRSTNAME_LENGTH,
  MAX_PASSWORD_LENGTH,
  MIN_FIRSTNAME_LENGTH,
  MIN_PASSWORD_LENGTH,
} from "../../../../validation/lengthConstants";
import {
  useCleanCustomErrors,
  useCustomError,
} from "../../../../base/contexts/CustomErrorMessage";
import GoogleAuth from "../../components/GoogleAuth";

const Register = () => {
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);

  const { setError } = useCustomError();
  useCleanCustomErrors();

  const history = useHistory();

  const onRedirectToVerifyEmail = useCallback(
    (email) => {
      history.push(AUTH_GROUP_LINKS.VERIFY_EMAIL, {
        email,
      });
    },
    [history]
  );

  const [errorEmail, { update }] = useRequestErrorMessage();
  const [isLoading, { registerPromise }] = useLoading();

  const registerUser = (values) => {
    registerPromise(authService.register(values))
      .then(() => onRedirectToVerifyEmail(values.email))
      .catch((e) => {
        Bugsnag.notify(e);
        setError("email", ERROR_EMAIL_MESSAGE);
      });
  };

  return (
    <AuthCardContainer metaText="Register">
      <Card className="overflow-hidden">
        <AuthHeader
          headerText="Welcome to Fearn!"
          description="Sign up to continue to Fearn"
        />
        <CardBody className="pt-0">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={registerUser}
          >
            {({ errors, handleSubmit, setFieldValue, values, dirty }) => (
              <form className="form-horizontal p-2" onSubmit={handleSubmit}>
                <GoogleAuth>Sign up with Google</GoogleAuth>
                <DevideLine text="Or" />
                <FormikInput
                  id="firstName"
                  name="firstName"
                  label="First name"
                  containerClassName="mb-3"
                  placeholder="Enter your First name"
                  type="text"
                  min={MIN_FIRSTNAME_LENGTH}
                  max={MAX_FIRSTNAME_LENGTH}
                />
                <FormikInput
                  id="lastName"
                  name="lastName"
                  label="Last name"
                  containerClassName="mb-3"
                  placeholder="Enter your Last name"
                  type="text"
                  min={MIN_FIRSTNAME_LENGTH}
                  max={MAX_FIRSTNAME_LENGTH}
                />
                <FormikEmail
                  type="email"
                  update={update}
                  errorEmail={errorEmail}
                  id="email"
                  name="email"
                  label="Email"
                  containerClassName="mb-3"
                  placeholder="Enter your email"
                  ErrorMessage={CustomErrorMessage}
                  onChange={() => {
                    setError("email", "");
                  }}
                />
                <FormikPassword
                  type="password"
                  id="password"
                  label="Password"
                  name="password"
                  containerClassName="mb-3"
                  placeholder="Enter password"
                  min={MIN_PASSWORD_LENGTH}
                  max={MAX_PASSWORD_LENGTH}
                />
                <FormikPassword
                  type="password"
                  id="confirmPassword"
                  label="Confirm password"
                  name="confirmPassword"
                  containerClassName="mb-3"
                  placeholder="Confirm password"
                  min={MIN_PASSWORD_LENGTH}
                  max={MAX_PASSWORD_LENGTH}
                />
                <AuthPrivacy
                  id="checked"
                  checked={values.checked}
                  onChange={setFieldValue}
                />
                <CustomButton
                  type="submit"
                  className="filled-primary w-100"
                  disabled={
                    isLoading ||
                    !!Object.keys(errors).length ||
                    !dirty ||
                    !values.checked
                  }
                >
                  Sign up
                </CustomButton>
              </form>
            )}
          </Formik>
        </CardBody>
      </Card>
      <SignUpFooter />
    </AuthCardContainer>
  );
};

export default Register;
