import React from "react";
import PropTypes from "prop-types";

import ReportPeriod from "./components/ReportPeriod";
import ColumnsSettings from "./components/ColumnsSettings";

import "./index.scss";

const BookkeepingSettings = ({ settings, setSettings }) => {
  return (
    <div className="mt-4">
      <ReportPeriod settings={settings} setSettings={setSettings} />
      <ColumnsSettings settings={settings} setSettings={setSettings} />
    </div>
  );
};

BookkeepingSettings.propTypes = {
  settings: PropTypes.object,
  setSettings: PropTypes.func,
};

export default BookkeepingSettings;
