import React, { useMemo } from "react";
import moment from "moment";
import PropTypes from "prop-types";

import Checkbox from "../../../../../../base/components/Checkbox";

import { INIT_DATE_FILTER } from "../../../../hooks/useAnalyticRangeDateFilter";

import { MOMENT_DAY } from "../../../../../expenses/constants";
import {
  ANALYTICS_CUSTOM_DATE_FILTER_TYPES,
  MOMENT_ONE_MONTH,
  MOMENT_ONE_YEAR,
  MOMENT_SEVEN_DAYS,
} from "../../../../constants";

import styles from "./index.module.scss";
import {
  MOMENT_MONTH,
  MOMENT_YEAR,
} from "../../../../../bookkeeping/constants";

const CalendarContainerRightPanel = ({ dateRangeType, onChangeDate }) => {
  const checkboxTypesAndTitles = useMemo(
    () => [
      { type: ANALYTICS_CUSTOM_DATE_FILTER_TYPES.day, title: "Day" },
      { type: ANALYTICS_CUSTOM_DATE_FILTER_TYPES.week, title: "Week" },
      { type: ANALYTICS_CUSTOM_DATE_FILTER_TYPES.month, title: "Month" },
      { type: ANALYTICS_CUSTOM_DATE_FILTER_TYPES.year, title: "Year" },
    ],
    []
  );

  const checkboxDatePeriods = useMemo(
    () => ({
      [ANALYTICS_CUSTOM_DATE_FILTER_TYPES.day]: {
        startDate: moment().startOf(MOMENT_DAY).toDate(),
        endDate: moment().toDate(),
      },
      [ANALYTICS_CUSTOM_DATE_FILTER_TYPES.week]: {
        startDate: moment()
          .subtract(MOMENT_SEVEN_DAYS, MOMENT_DAY)
          .startOf(MOMENT_DAY)
          .toDate(),
        endDate: moment().toDate(),
      },
      [ANALYTICS_CUSTOM_DATE_FILTER_TYPES.month]: {
        startDate: moment()
          .subtract(MOMENT_ONE_MONTH, MOMENT_MONTH)
          .startOf(MOMENT_DAY)
          .toDate(),
        endDate: moment().toDate(),
      },
      [ANALYTICS_CUSTOM_DATE_FILTER_TYPES.year]: {
        startDate: moment()
          .subtract(MOMENT_ONE_YEAR, MOMENT_YEAR)
          .startOf(MOMENT_DAY)
          .toDate(),
        endDate: moment().toDate(),
      },
    }),
    []
  );

  const checkboxMap = useMemo(
    () =>
      checkboxTypesAndTitles.map(({ type, title }) => ({
        name: type,
        title: title,
        checked: dateRangeType === type,
        onChange: () => {
          const newDateRangeType = dateRangeType === type ? null : type;
          const { startDate, endDate } = newDateRangeType
            ? checkboxDatePeriods[type]
            : INIT_DATE_FILTER;

          onChangeDate([startDate, endDate], event, newDateRangeType);
        },
      })),
    [checkboxTypesAndTitles, dateRangeType, onChangeDate, checkboxDatePeriods]
  );

  return (
    <div className={styles.wrapper}>
      {checkboxMap.map((checkbox, index) => (
        <Checkbox
          name={checkbox.name}
          className="custom-green-checkbox"
          text={checkbox.title}
          checked={checkbox.checked}
          onChange={checkbox.onChange}
          key={index}
        />
      ))}
    </div>
  );
};

CalendarContainerRightPanel.propTypes = {
  dateRangeType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  onChangeDate: PropTypes.func,
};

export default CalendarContainerRightPanel;
