/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../../../../base/hooks/useService";
import PoshmarkExtension from "../../../../../../services/PoshmarkExtension";
import { getConfigBrandInitialValue } from "../../../../helpers/getConfigInitialValue";
import FormikAsyncSelect from "../../../../../../base/components/FormikAsyncSelect";
import { poshmarkBrandlistToOptions } from "../../../../helpers/listToOptions";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import DotsLoader from "../../../../../../base/components/DotLoader";
import { validateCustomBrandOptions } from "../../../DraftBasicFormInner";
import CustomBrandMessage from "../../../CustomBrandMessage";
import { matchStrings } from "../../../../../../base/helpers/matchStrings";
import { useFindAlternativeBrands } from "../../../../hooks/useFindAlternativeBrands";

const FormPoshmarkBrand = ({ values, setFieldValue, suggested }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isCustom, changeIsCustom] = useState(false);
  const [alternativeBrandsList, setAlternativeBrandsList] = useState([]);

  const [loading, { registerPromise }] = useLoading();
  const [isLoadingBrands, { registerPromise: registerPromiseBrands }] =
    useLoading();

  const [findAlternativeBrands] = useFindAlternativeBrands();

  /**
   * @type {PoshmarkExtension}
   */
  const poshmark = useService(PoshmarkExtension);

  const getBrandOptions = useCallback(
    (query) => {
      return registerPromise(
        poshmark
          .searchBrands(query || values.brandId)
          .then(poshmarkBrandlistToOptions)
      )
        .then((list) => {
          const options = validateCustomBrandOptions(
            list,
            values.brandId,
            query
          );
          changeIsCustom(!!options?.[0]?.isCustom);

          return options;
        })
        .catch((e) =>
          Bugsnag.notify(e, (event) => {
            event.addMetadata("getBrandOptionsError", {
              query,
              values,
            });
          })
        );
    },

    [poshmark, values, searchQuery, registerPromise]
  );

  const onSelectAlternativeBrand = (alternativeBrand) => {
    setFieldValue("brandId", alternativeBrand);
  };

  const formatAllBrands = (allBrands) => allBrands;

  useEffect(() => {
    setFieldValue("brandName", values.brandId);
  }, [values.brandId]);

  useEffect(() => {
    if (values.brandId || !suggested) return;

    getBrandOptions(suggested)
      .then((list) => {
        const brand = list.find(({ label }) => matchStrings(label, suggested));
        if (brand) setFieldValue("brandId", brand.label);
      })
      .catch((e) => Bugsnag.notify(e));
  }, [values.brandId, suggested]);

  useEffect(() => {
    getBrandOptions(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    if (!isCustom) return;

    registerPromiseBrands(
      poshmark
        .getAllBrands()
        .then((allBrands) => {
          const alternativeBrand = findAlternativeBrands(
            allBrands,
            values.brandId,
            formatAllBrands
          );

          setAlternativeBrandsList(alternativeBrand);
        })
        .catch((e) => Bugsnag.notify(e))
    );
  }, [isCustom]);

  return (
    <Row className="section-wrapper">
      <Col md={4}>
        <h3 className="bold-black mb-1">Brand* {loading && <DotsLoader />}</h3>
        <p className="text-gray-gomi">Select Brand or write your own.</p>
      </Col>
      <Col md={8}>
        <Row>
          <Col md={6}>
            <FormikAsyncSelect
              initialValue={getConfigBrandInitialValue(values.brandId)}
              name="brandId"
              placeholder="Select or write brand/designer"
              noOptionsMessage="Select or write brand/designer"
              onChange={(value) => value.name}
              onRequestAPI={getBrandOptions}
              isCreatable
              getValue={(options) => {
                return options.find(({ label }) =>
                  matchStrings(label, values.brandId)
                );
              }}
              label={"Brand"}
              isHideLabel
              updateSearch={(searchValue) => setSearchQuery(searchValue)}
              search={searchQuery}
            />
            {isCustom && !isLoadingBrands && (
              <CustomBrandMessage
                brandList={alternativeBrandsList}
                onSelectBrand={onSelectAlternativeBrand}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default FormPoshmarkBrand;
