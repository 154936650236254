import Bugsnag from "@bugsnag/js";

import Http from "./Http";

class EbayService extends Http {
  static $displayName = "EbayService";
  retryCount = 0;

  session() {
    return this.get("/ebay-accounts/application-session");
  }

  postProduct(id) {
    return this.post(`/products/${id}/ebay`);
  }

  postProductMigrate(id) {
    return this.post(`/products/${id}/ebay/migration`);
  }

  updateLastSyncedDate(newDate) {
    return this.put("/ebay-accounts/lastSyncedAt", {
      lastSyncedAt: newDate,
    });
  }

  getShippingData() {
    return this.get("/products/ebay/shipping-info");
  }

  async getEbayCategories(node) {
    if (!localStorage.getItem("category-tree")) {
      const data = await this.get(
        `/ebay-accounts/category-tree?node=${node}`
      ).catch((e) => {
        Bugsnag.notify(e);
        return null;
      });

      if (data) {
        localStorage.setItem(
          "category-tree",
          JSON.stringify({
            data,
            expiry: new Date().getTime() + 604800 * 1000,
          })
        );
      }

      return data;
    }

    const categoryTreeData = JSON.parse(localStorage.getItem("category-tree"));
    const { data, expiry } = categoryTreeData;

    if (expiry <= new Date().getTime()) {
      localStorage.removeItem("category-tree");

      return this.getEbayCategories(node);
    }

    return data;
  }

  async getEbayCategoryItems(id, categoryId) {
    const data = await this.get(
      `/ebay-accounts/category-items?id=${id}&category_id=${categoryId}`
    ).catch((e) => {
      Bugsnag.notify(e);

      if (this.retryCount < 3) {
        this.retryCount += 1;

        return this.getEbayCategoryItems(id, categoryId);
      }

      this.retryCount = 0;

      return null;
    });

    return data;
  }

  getEbayCategorySubTree(id, categoryId) {
    return this.get(
      `/ebay-accounts/category-subtree?id=${id}&category_id=${categoryId}`
    );
  }

  getEbayConditions(categoryId) {
    return this.get(`/ebay-accounts/conditions?categoryId=${categoryId}`);
  }
}

export default EbayService;
