import {Col, Row} from "reactstrap";
import React, { useState } from "react";
import SizesSelect from "./SizesSelect";
import ErrorMessageDefault from "../../../../../../base/components/ErrorMessageDefault";
import {useField} from "formik";
import {useTranslate} from "../../../../../../base/hooks/useTranslate";
import DotsLoader from "../../../../../../base/components/DotLoader";

const FormPoshmarkSizes = ({sizeSystems, sizeSets, values, setFieldValue, label = "Size", name="sizeId", onSetSizeClicked=()=>{}, isSizeClicked, loading}) => {

    const [, { error, touched }] = useField({ name });
    const [translate] = useTranslate();



    return(
        <Row className="section-wrapper">
            <Col md={4} >
                <h3 className='bold-black mb-1'>
                    Size* {loading && <DotsLoader/>}
                </h3>
            </Col>
            <Col md={8} >
                <Row>
                    <Col md={6} onClick={onSetSizeClicked}>
                        {sizeSets && <SizesSelect
                            values={values}
                            setFieldValue={setFieldValue}
                            sizeSets={sizeSets}
                            sizeSystems={sizeSystems}
                            isDisabled={!sizeSets.length}
                        />}
                    </Col>
                    <div className="is-invalid-input is-invalid"/>
                    {touched && <ErrorMessageDefault>
                        {translate(error, { label })}
                    </ErrorMessageDefault>}
                  {!sizeSets.length && isSizeClicked && <span className="invalid-feedback">Please select the category first</span>}
                </Row>
            </Col>
        </Row>
    )
}

export default FormPoshmarkSizes;
