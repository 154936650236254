import React, { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { Container } from "../components/Container";
import { FORM_TYPES } from "../../drafts/const/form";

const Ebay = () => {
  const [base, updateBase] = useState([]);
  const [categories, updateCategories] = useState([]);

  useEffect(() => {
    import("../data/basic")
      .then(({ basic }) => {
        updateBase(basic);
      })
      .catch((e) => Bugsnag.notify(e));
    import("../data/ebay")
      .then(({ ebay }) => {
        updateCategories(ebay);
      })
      .catch((e) => Bugsnag.notify(e));
  }, []);

  return (
    <Container form={FORM_TYPES.EBAY} categories={categories} basic={base} />
  );
};

export default Ebay;
