import Bugsnag from "@bugsnag/js";

import { getFilteredProducts } from "../utils/getFilteredProducts";
import { MARKETPLACE_EBAY_TYPE } from "../constants/importInventory";
import ImportPopupService from "./ImportPopupService";
import TabConnection from "../../../services/importServices/TabConnection";

class ImportPopupExtension extends ImportPopupService {
  constructor({ loadData, marketplaceName, timePeriod = null }) {
    super();
    this.loadData = loadData;
    this.connection = new TabConnection(marketplaceName);
    this.pagination = {};
    this.timePeriod = timePeriod;
  }

  loadMore(timePeriod = null) {
    this.timePeriod = timePeriod;
    super.loadMore();
    return this._loadChunks();
  }

  _loadChunks() {
    this.markChanges();

    if (this.checkIsAllDataLoaded()) {
      return Promise.reject("No more items, invalid request");
    }

    if (this.checkIsLoadedNextPage()) {
      return Promise.resolve();
    }

    if (!this.hasMore()) {
      return Promise.reject("No more items, invalid request");
    }

    this.processing = true;

    return this.connection
      .getLoadedTab()
      .then((tabId) => {
        if (!this.processing) {
          this.invalidate();
          this.connection?.disconnect();
          return;
        }

        return this.loadData(tabId, this.pagination, this.timePeriod).then(
          (resp) => {
            const { data, pagination, hasNextPage } = resp;

            if (!this.processing) {
              this.invalidate();
              this.connection?.disconnect();
              return;
            }

            this.items = this.items.concat(data);
            this.pagination = pagination;
            this.totalCount = hasNextPage ? Infinity : this.items.length;

            this.markChanges();

            if (this.checkIsNeededAdditionalLoad()) {
              return this._loadChunks();
            }
          }
        );
      })
      .catch((err) => {
        Bugsnag.notify(err, (event) => {
          event.addMetadata("loadChunksError", {
            pagination: this.pagination,
          });
        });
        this.invalidate();
        this.reset();
        this.connection?.disconnect();
        return Promise.reject(err);
      })
      .finally(() => {
        this.processing = false;
      });
  }

  loadAll() {
    super.loadAll();
    return this._loadChunks();
  }

  reset() {
    this.connection?.disconnect();
    return super.reset();
  }
}

class ImportPopupAPI extends ImportPopupService {
  constructor({ loadData }) {
    super();
    this.loadData = loadData;
    this.pagination = { limit: 100, offset: 0 };
  }

  loadMore() {
    super.loadMore();
    return this._loadChunks();
  }

  _loadChunks() {
    this.markChanges();

    if (this.checkIsLoadedNextPage()) {
      return Promise.resolve();
    }

    if (this.checkIsAllDataLoaded()) {
      return Promise.reject("No more items, invalid request");
    }

    if (!this.hasMore()) {
      return Promise.reject("No more items, invalid request");
    }

    this.processing = true;

    return this.loadData({ ...this.pagination, limit: 100 })
      .then(({ data, pagination }) => {
        const { nextOffset, totalCount } = pagination;

        if (!this.processing) return;

        this.items = this.items.concat(
          getFilteredProducts(data, MARKETPLACE_EBAY_TYPE)
        );

        this.pagination = {
          ...this.pagination,
          offset: nextOffset,
        };

        this.totalCount = totalCount;

        this.markChanges();

        if (this.checkIsNeededAdditionalLoad()) {
          return this._loadChunks();
        }
      })
      .catch((err) => {
        Bugsnag.notify(err);
        this.invalidate();

        return Promise.reject(err);
      })
      .finally(() => {
        this.processing = false;
      });
  }

  loadAll() {
    super.loadAll();
    return this._loadChunks();
  }
}

export class ImportPopupEbayService extends ImportPopupAPI {}

export class ImportPopupEbaySoldService extends ImportPopupExtension {}

export class ImportPopupEtsyService extends ImportPopupAPI {}

export class ImportPopupMercariService extends ImportPopupExtension {}

export class ImportPopupPoshmarkService extends ImportPopupExtension {}
