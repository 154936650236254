import { isObject } from 'lodash';

export const getConfigInitialValue = (values) => {
  if (!isObject(values)) return values;

  return {
    ...values,
    label: values['name'] || ''
  }
}

export const getConfigBrandInitialValue = (values) => {
  if (!isObject(values)) return values;

  return values.name;
};