/* eslint-disable react/prop-types */
import React, { memo } from "react";
import PropTypes from "prop-types";
import InfiniteScroll from "react-infinite-scroller";

import Loader from "./components/Loader";

import { THRESHOLD_DEFAULT } from "./constants/infiniteScroll";

const InfiniteScrollWrapper = ({
  hasMore = false,
  fetchData = () => {},
  loaderComponent = <Loader key="loader" />,
  threshold = THRESHOLD_DEFAULT,
  children,
  hasInfiniteLoader = true,
}) => {
  return (
    <InfiniteScroll
      initialLoad={true}
      loadMore={fetchData}
      hasMore={hasMore}
      threshold={threshold}
      loader={hasInfiniteLoader ? loaderComponent : null}
    >
      {children}
    </InfiniteScroll>
  );
};

InfiniteScrollWrapper.propTypes = {
  hasMore: PropTypes.bool,
  fetchData: PropTypes.func,
  loaderComponent: PropTypes.any,
  threshold: PropTypes.number,
  children: PropTypes.any,
};

export default memo(InfiniteScrollWrapper);
