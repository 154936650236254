import React, { useCallback } from "react";
import PropTypes from "prop-types";

import EbayReturns from "../EbayReturns";

import {
  EBAY_DAY_RETURNS_OPTIONS,
  EBAY_RETURNS_TYPES,
  IS_ACCEPTED_RETURN_OPTIONS,
  PAID_BY_OPTIONS,
  REPLACEMENT_CHECKBOX_OPTIONS,
} from "../../constants";

const DomesticReturns = ({ values, setFieldValue }) => {
  const onToggleAcceptDomesticReturns = useCallback(() => {
    if (values.returnPolicyType === IS_ACCEPTED_RETURN_OPTIONS.available) {
      setFieldValue("returnPolicyType", IS_ACCEPTED_RETURN_OPTIONS.unavailable);
      setFieldValue("returnDays", null);
      setFieldValue("returnExpenses", null);
      setFieldValue("returnMethod", null);
    } else {
      setFieldValue("returnPolicyType", IS_ACCEPTED_RETURN_OPTIONS.available);
      setFieldValue("returnDays", EBAY_DAY_RETURNS_OPTIONS.days30);
      setFieldValue("returnExpenses", PAID_BY_OPTIONS.buyer);
    }
  }, [values.returnPolicyType, setFieldValue]);

  const onToggleReplacement = useCallback(() => {
    const newValue = values.returnMethod
      ? null
      : REPLACEMENT_CHECKBOX_OPTIONS.replacement;
    setFieldValue("returnMethod", newValue);
  }, [values.returnMethod, setFieldValue]);

  return (
    <EbayReturns
      isAccepted={
        values.returnPolicyType === IS_ACCEPTED_RETURN_OPTIONS.available
      }
      isReplacement={values.returnMethod}
      onToggleAccept={onToggleAcceptDomesticReturns}
      onToggleReplacement={onToggleReplacement}
      type={EBAY_RETURNS_TYPES.domestic}
    />
  );
};

DomesticReturns.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
};

export default DomesticReturns;
