import React from "react";
import PageMeta from "../../base/components/MetaTags";
import DashboardPanel from "./pages/DashboardPanel";

const Dashboard = () => {
  return (
    <div className="page-content">
      <PageMeta title='Dashboard' />
      <DashboardPanel />
    </div>
  )
};


export default Dashboard;