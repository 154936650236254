import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import Icon from "../../../../../../../../base/components/Icon";
import { BOOKKEEPING_DATE_FORMAT } from "../../../../../../constants";

import styles from "./index.module.scss";

const ReportDateInput = forwardRef(({ value, onClick }, ref) => {
  const date = moment(value).format(BOOKKEEPING_DATE_FORMAT);

  return (
    <button className={styles.button} onClick={onClick} ref={ref}>
      {date}
      <Icon icon="bottomArrow" className={styles.icon} />
    </button>
  );
});

ReportDateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

export default ReportDateInput;
