import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { Card, CardBody, Container, Spinner } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import VerticalTabs from "../../../../base/components/VerticalTabs";
import PageTitleBreadcrumb from "../../../../base/components/PageTitleBreadcrumb";
import EditDraftBasic from "./pages/Basic";
import EditDraftEbay from "./pages/Ebay";
import FormPartials from "../../classes/FormPartials";

import DraftsService from "../../../../services/DraftsService";
import { EDIT_DRAFT_LINKS, getEditTabs } from "./config";
import { DRAFTS_GROUP_LINKS } from "../../config";
import { useService } from "../../../../base/hooks/useService";
import CreateDraftEtsy from "../CreateDraft/pages/Etsy";
import { useSaveDraft } from "../../hooks/useSaveSubmit";
import { DRAFT_UPDATE_SUCCESS } from "../../../../base/constants/messages";
import PreventClosePage from "../../components/PreventClosePage";
import { CREATE_DRAFT_LINKS } from "../CreateDraft/config";
import { useDetectFormChanges } from "../../hooks/useDetectDirtyForm";
import { useLoading } from "../../../../base/hooks/useLoading";
import CreateDraftPoshmark from "../CreateDraft/pages/Poshmark";
import CreateDraftMercari from "../CreateDraft/pages/Mercari";
import { DRAFT_PREV_PATH, MARKETPLACES_NAMES_REQUEST } from "../../constants";
import { usePrevLocation } from "../../../../base/hooks/usePrevLocation";
import { useProfile } from "../../../profile/hooks/useProfile";
import { PRODUCT_TYPE } from "../../../../base/constants/product";
import { useTotalProducts } from "../../hooks/useProduct";
import Permissions from "../../../../base/helpers/Permission";
import { useLoadProduct } from "../../hooks/useLoadProduct";
import FormResources from "../../../../services/FormResources";
import { getInitialEmptyForm } from "../Settings/utils/getInitialEmptyForm";

const DRAFT_BREADCRUMBS = [
  { title: "Draft", link: DRAFTS_GROUP_LINKS.BASE },
  { title: "Draft details", link: "#" },
];

export const Loading = () => (
  <Card className="mt-3 ">
    <CardBody className={""}>
      <div className="d-flex justify-content-center align-content-center min-height-spinner-section">
        <Spinner color="primary" className={"m-auto"} />
      </div>
    </CardBody>
  </Card>
);

const EditDraft = () => {
  const {
    params: { draftId },
  } = useRouteMatch();
  const initialFormValues = getInitialEmptyForm();

  const [fullFormData, updateFullForm] = useState(initialFormValues);
  const [fetchedData, setFetchedData] = useState({});
  const { dirty, containerRef, cancel } = useDetectFormChanges();
  const [isLoading, { registerPromise }] = useLoading();
  const { url } = useRouteMatch();
  const [{ activeSubscription: { type } = {} }] = useProfile();
  const [{ total }] = useTotalProducts(PRODUCT_TYPE.DRAFT);

  const onAfterUpdate = (model) => {
    return draftService
      .updateDraft(draftId, model)
      .then((data) => {
        cancel();
        return data;
      })
      .catch((e) => Bugsnag.notify(e));
  };

  const isDisableCreateDraft = Permissions.disableCreateDraft(type, total + 1);

  const onSave = useSaveDraft(
    onAfterUpdate,
    DRAFTS_GROUP_LINKS.BASE,
    DRAFT_UPDATE_SUCCESS
  );

  const onSaveAndCreateNew = useSaveDraft(
    onAfterUpdate,
    CREATE_DRAFT_LINKS.BASIC,
    DRAFT_UPDATE_SUCCESS
  );

  const onHandleDelete = useCallback(() => {
    cancel();
  }, [cancel]);

  const toggleStatus = useCallback((marketplace, newStatus) => {
    const specificField = {
      [MARKETPLACES_NAMES_REQUEST.ebay]: FormPartials.SpecificFields.Ebay,
      [MARKETPLACES_NAMES_REQUEST.etsy]: FormPartials.SpecificFields.Etsy,
      [MARKETPLACES_NAMES_REQUEST.poshmark]:
        FormPartials.SpecificFields.Poshmark,
      [MARKETPLACES_NAMES_REQUEST.mercari]: FormPartials.SpecificFields.Mercari,
    }[marketplace];

    if (!specificField) return;

    setFetchedData((form) => {
      form[specificField].status = newStatus;

      return { ...form };
    });
  }, []);

  const loadLatest = useCallback(() => {
    registerPromise(
      FormResources.waitResources([
        ...FormResources.prefetchFormResources(),
        loadProduct({
          setFetchedData,
          updateFullForm,
        })
          .then(cancel)
          .catch((e) => Bugsnag.notify(e))
          .finally(cancel),
      ])
    );
  }, []);

  const commonProps = {
    onSaveAndCreateNew,
    onSave,
    onHandleDelete,
    updateFullForm,
    fullFormData,
    fetchedData,
    isDisableCreateDraft,
    isDirty: dirty,
    toggleStatus,
    loadLatest,
  };

  const loadProduct = useLoadProduct(draftId);
  /**
   * @type {DraftsService}
   */
  const draftService = useService(DraftsService);

  useEffect(() => {
    loadLatest();
  }, [cancel, registerPromise, loadProduct, setFetchedData, updateFullForm]);

  const editTabs = useMemo(() => getEditTabs(url), [url]);

  const { prevPath } = usePrevLocation(DRAFT_PREV_PATH);

  useEffect(() => {
    () => {
      updateFullForm(initialFormValues);
    };
  }, []);

  return (
    <Container fluid>
      <div ref={containerRef}>
        <PageTitleBreadcrumb
          title="DRAFT DETAILS"
          breadcrumbItems={DRAFT_BREADCRUMBS}
          onBackPath={prevPath.current || DRAFTS_GROUP_LINKS.BASE}
        />
        <VerticalTabs tabs={editTabs} />

        {isLoading ? (
          <Loading />
        ) : (
          <>
            <PreventClosePage
              isBlocked={dirty}
              allowPath={DRAFTS_GROUP_LINKS.VIEW_DRAFT.replace(
                ":draftId",
                draftId
              )}
            />
            <Switch>
              <Route path={EDIT_DRAFT_LINKS.BASIC} exact>
                <EditDraftBasic {...commonProps} />
              </Route>
              <Route path={EDIT_DRAFT_LINKS.EBAY} exact>
                <EditDraftEbay {...commonProps} />
              </Route>
              <Route path={EDIT_DRAFT_LINKS.ETSY} exact>
                <CreateDraftEtsy {...commonProps} />
              </Route>
              <Route path={EDIT_DRAFT_LINKS.POSHMARK} exact>
                <CreateDraftPoshmark {...commonProps} />
              </Route>
              <Route path={EDIT_DRAFT_LINKS.MERCARI} exact>
                <CreateDraftMercari {...commonProps} />
              </Route>
              <Redirect to={EDIT_DRAFT_LINKS.BASIC} />
            </Switch>
          </>
        )}
      </div>
    </Container>
  );
};

export default EditDraft;
