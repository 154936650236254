import { TYPES } from "../../../base/components/Subscription/constant/subscription";
import {
  STARTER_SUBSCRIPTION_PRICE,
  BUSINESS_SUBSCRIPTION_PRICE,
  UNLIMITED_SUBSCRIPTION_PRICE,
  TRIAL_SUBSCRIPTION_PRICE,
  EXPIRED_SUBSCRIPTION_PRICE,
  BETA_SUBSCRIPTION_PRICE,
} from "../../../base/components/Subscription/subscriptionList";

export const UPGRADE_SUBSCRIPTION_MESSAGE =
  "Do you really want to upgrade the subscription plan? You will transafered to the new one after paying the difference between plans.";
export const DOWNGRADE_SUBSCRIPTION_MESSAGE =
  "Do you really want to downgrade the subscription plan?";

export const subscriptionPrices = {
  [TYPES.EXPIRED]: EXPIRED_SUBSCRIPTION_PRICE,
  [TYPES.TRIAL]: TRIAL_SUBSCRIPTION_PRICE,
  [TYPES.STARTER]: STARTER_SUBSCRIPTION_PRICE,
  [TYPES.BUSINESS]: BUSINESS_SUBSCRIPTION_PRICE,
  [TYPES.UNLIMITED]: UNLIMITED_SUBSCRIPTION_PRICE,
  [TYPES.BETA]: BETA_SUBSCRIPTION_PRICE,
};
