import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import CreateDraft from "./pages/CreateDraft";
import DraftsList from "./pages/ListDrafts";
import { DRAFTS_GROUP_LINKS } from "./config";
import EditDraft from "./pages/EditDraft";

const Drafts = () => {
	return (
		<div className="page-content">			
			<Switch>
				<Route path={DRAFTS_GROUP_LINKS.ADD_DRAFT} component={CreateDraft} />
				<Route path={DRAFTS_GROUP_LINKS.BASE} exact>
					<DraftsList/>
				</Route>
				<Route path={DRAFTS_GROUP_LINKS.VIEW_DRAFT} component={EditDraft} />
				<Route path={DRAFTS_GROUP_LINKS.EDIT_DRAFT} component={EditDraft} exact/>
				<Redirect to={DRAFTS_GROUP_LINKS.BASE}/>
			</Switch>
		</div>
	)
};

export default Drafts;
