import {
  MAX_DRAFT_BUSINESS,
  MAX_DRAFT_STARTER,
  TYPES,
} from "../components/Subscription/constant/subscription";

class Permissions {
  static disableCreateDraft(typeSubscription, countDrafts) {
    return (
      (typeSubscription === TYPES.STARTER &&
        countDrafts >= MAX_DRAFT_STARTER) ||
      (typeSubscription === TYPES.BUSINESS && countDrafts >= MAX_DRAFT_BUSINESS)
    );
  }

  static allowCreateInventory() {}
}

export default Permissions;
