import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as SuccessIcon } from "../assets/images/toastr/bx-check.svg";
import React from "react";
import styled from "styled-components";

import "../assets/scss/custom/components/_toastr.scss";
import { Spinner } from "../base/components/GlobalLoader/GlobalLoader";

const ToastContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ToastText = styled.div`
  margin-left: 16px;
`;

toast.configure();

const options = {
  autoClose: 3000,
  hideProgressBar: true,
  position: toast.POSITION.TOP_RIGHT,
  pauseOnHover: false,
};

class ToasterService {
  success(message) {
    toast.success(
      <div>
        <SuccessIcon className={"me-2"} />
        {message}
      </div>,
      options
    );
  }

  error(message) {
    toast.error(message);
  }

  progress(message, config = {}) {
    toast.info(
      <ToastContainer>
        <div>
          <Spinner />
        </div>
        <ToastText>{message}</ToastText>
      </ToastContainer>,
      { ...options, ...config }
    );
  }

  static $displayName = "ToasterService";
}

export default ToasterService;
