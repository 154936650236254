import {
  BRAND_ERROR,
  DESCRIPTION_ERROR,
  LB_ERROR,
  OZ_ERROR,
  PRISE_ERROR,
  TITLE_ERROR
} from "../../../base/constants/forms";

export const checkIsEmptyForm = values => !Object.keys(values).length;

export const getIsEmptyForm = (values, setErrors) => {
  if (!Object.keys(values).length) {
    setErrors({
      title: TITLE_ERROR,
      description: DESCRIPTION_ERROR,
      brand: BRAND_ERROR,
      weightLb: LB_ERROR,
      weightOz: OZ_ERROR,
      listingPrice: PRISE_ERROR
    });
    return true;
  }
  return false;
};
