import React from "react";
import PropTypes from "prop-types";

import PageTitle from "../../../pages/profile/components/PageName";
import Breadcrumb from "../Common/Breadcrumb2";

const PageTitleBreadcrumb = ({ title, breadcrumbItems, onBackPath = "" }) => {
  return (
    <div className="d-flex justify-content-between">
      <PageTitle title={title} onBackPath={onBackPath} />
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
    </div>
  );
};

PageTitleBreadcrumb.propTypes = {
  title: PropTypes.string,
  breadcrumbItems: PropTypes.array,
  onBackPath: PropTypes.string,
};

export default PageTitleBreadcrumb;
