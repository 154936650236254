/* eslint-disable react/prop-types */
import { useEffect, useMemo, useRef, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../../../base/hooks/useService";
import EtsyService from "../../../../../services/EtsyService";
import { groupFields } from "../../../helpers/customProperties";
import CustomButton from "../../../../../base/components/CustomButton";
import { Alert, Col, Row } from "reactstrap";
import RenderCustomField from "./RenderCustomField";
import React from "react";
import "./index.scss";
import { useField } from "formik";

export const splitByRequired = (properties) => {
  const optional = [];
  const required = [];

  properties.forEach((property) => {
    if (property.isRequired) {
      required.push(property);
      return;
    }

    optional.push(property);
  });

  return {
    optional,
    required,
  };
};

const RenderCustomFields = ({ categoryId, mapFieldData }) => {
  const [fields, updateFields] = useState([]);
  const [showFields, toggle] = useState(false);
  const [, , { setValue }] = useField({
    name: "productAdditionalFields",
  });

  const refMounted = useRef(false);
  /**
   *
   * @type {EtsyService}
   */
  const etsy = useService(EtsyService);

  useEffect(() => {
    if (!categoryId) return;
    if (refMounted.current) setValue({});

    refMounted.current = true;

    etsy
      .getProperties(categoryId)
      .then(({ data }) => updateFields(data))
      .catch((e) => Bugsnag.notify(e));
  }, [categoryId, etsy]);

  const [, , { setValue: setSizeName }] = useField({ name: "sizeName" });
  const [{ value: valueSizeStart }] = useField({ name: "sizeNameStart" });
  const [{ value: valueSizeEnd }] = useField({ name: "sizeNameEnd" });

  useEffect(() => {
    if (valueSizeStart || (valueSizeStart && valueSizeEnd)) {
      setSizeName([valueSizeStart, valueSizeEnd].filter(Boolean).join(" "));
    }
  }, [valueSizeEnd, valueSizeStart]);

  const { required, optional } = useMemo(
    () => splitByRequired(fields),
    [fields]
  );

  return (
    <>
      {fields.length ? (
        groupFields(required).map(([first, second]) => {
          const props = mapFieldData(first);
          const propSeconds = second && mapFieldData(second);
          return (
            <Row
              className="mb-4 optional-fields"
              key={`${props.key}_${propSeconds?.key}`}
            >
              <Col md="6">
                <RenderCustomField {...props} isRequired />
              </Col>
              <Col md="6">
                {!!second && <RenderCustomField {...propSeconds} isRequired />}
              </Col>
            </Row>
          );
        })
      ) : (
        <Alert color="warning" role="alert" className="mb-0">
          <div className="d-flex align-items-center">
            <div>
              <i className="mdi mdi-alert-outline me-2 font-size-20" />
            </div>
            <div>{"Please select a category to load the Item specifics"}</div>
          </div>
        </Alert>
      )}
      {!!optional.length && (
        <CustomButton
          className="outline-primary mb-4"
          onClick={() => toggle(!showFields)}
        >
          {showFields ? "Hide" : "Show"} Optional Fields
        </CustomButton>
      )}

      {showFields &&
        groupFields(optional).map(([first, second]) => {
          const props = mapFieldData(first);
          const propSeconds = second && mapFieldData(second);
          return (
            <Row
              className="mb-4 optional-fields"
              key={`${props.key}_${propSeconds?.key}`}
            >
              <Col md="6">
                <RenderCustomField {...props} />
              </Col>
              <Col md="6">
                {!!second && <RenderCustomField {...propSeconds} />}
              </Col>
            </Row>
          );
        })}
    </>
  );
};

export default RenderCustomFields;
