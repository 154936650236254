import React from "react";
import { Form, Formik } from "formik";

import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../base/components/CustomModal";
import CustomButton from "../../../../base/components/CustomButton";
import TaskForm from "../TaskForm";
import { taskSchema } from "../../forms/task";

const initialValues = { assigneeId: null, title: "", description: "" };

const CreateTaskModal = ({ isOpen, onClose, onClick }) => {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <ModalHeader onClose={onClose}>Task creation</ModalHeader>
      <Formik
        initialValues={initialValues}
        onSubmit={onClick}
        validationSchema={taskSchema}
      >
        {({ isValid, dirty }) => (
          <Form>
            <ModalBody>
              <TaskForm isCreateMode={true} />
            </ModalBody>
            <ModalFooter>
              <CustomButton
                type="submit"
                onClick={onClick}
                className="filled-primary"
                disabled={!isValid || !dirty}
              >
                Create
              </CustomButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default CreateTaskModal;
