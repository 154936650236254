import React from "react";
import AvailableInventoryDropdown from "../Dropdown/AvailableInventoryDropdown";
import styles from "../../index.module.scss"


const TableHeader = ({  headerProps }) => {

  return (
    <div className={"d-flex drafts-list-toolbar justify-content-between"}>
      <div className={styles.headerContainer}>
        <h3 className={styles.headerTitle}>10 Most Available</h3>
       <AvailableInventoryDropdown
         list={headerProps.groupByList}
         defaultSelected={headerProps.groupByList[0]}
         onChange={headerProps.onSelectGroup}
         classes={styles.headerBrandButton}
       />
      </div>

          <AvailableInventoryDropdown
            list={headerProps.orderByList}
            defaultSelected={headerProps.orderByList[0]}
            onChange={headerProps.onSelectOrder}
            classes={styles.headerCountButton}
          />
    </div>
  );
};

export default TableHeader;