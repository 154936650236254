/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";

export default function CustomInput({
  value,
  handleBlur,
  handleChange,
  className,
  name,
  placeholder,
  mask,
  isDiscountShippingClicked = false,
  ...other
}) {
  return (
    <InputMask
      mask={mask}
      className={
        "form-control " +
        className +
        (!value && isDiscountShippingClicked
          ? " is-invalid-input is-invalid"
          : "")
      }
      value={value}
      name={name}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
      maskChar={null}
      {...other}
    />
  );
}

CustomInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  mask: PropTypes.string,
};
