import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../../base/hooks/useService";
import AnalyticsService from "../../../../services/AnalyticsService";
import { separateThousandth } from "../../../analytics/helpers/separateThousandth";
import { changeDataToPercentDashboard } from "../../../analytics/helpers/changeDataToPercent";

const COUNT_ROUNDING = 2;

const ProgressPerformance = () => {
  const [overview, setOverview] = useState({});

  /**
   * @type {AnalyticsService}
   */
  const analytics = useService(AnalyticsService);

  const fetchAnalytics = useCallback(() => {
    analytics
      .getTotalSales()
      .then(({ data }) => {
        setOverview((prevState) => ({
          ...prevState,
          totalSales: separateThousandth(data.totalSales),
          grossProfit: separateThousandth(data.grossProfit),
          itemsListed: data.itemsListed,
          itemsSold: data.itemsSold,
        }));
      })
      .catch((e) => Bugsnag.notify(e));
    analytics
      .getAverages()
      .then(({ data }) => {
        setOverview((prevState) => ({
          ...prevState,
          avgProfitMargin: changeDataToPercentDashboard(
            data?.avgProfitMargin,
            COUNT_ROUNDING
          ),
        }));
      })
      .catch((e) => Bugsnag.notify(e));
  }, [analytics]);

  useEffect(() => {
    fetchAnalytics();
  }, [fetchAnalytics]);

  const items = useMemo(() => [
    { label: "Sales", value: overview?.totalSales },
    { label: "Gross Profit", value: overview?.grossProfit },
    { label: "Items listed", value: overview?.itemsListed },
    { label: "Sold Items", value: overview?.itemsSold },
    { label: "Average Margin", value: overview?.avgProfitMargin },
  ]);

  return (
    <>
      {items.map((item) => {
        return (
          <Row key={item.label} className="align-items-center mb-3">
            <Col md={5}>
              <div className="font-size-16 mb-0">{item.label}:</div>
            </Col>
            <Col md={4} className="d-flex justify-content-end">
              <div className="font-size-19 fw-bold title-text-color mb-0">
                {item.value}
              </div>
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default ProgressPerformance;
