import React from "react";
import { Col, Row } from "reactstrap";

import Divider from "../../Divider";
import FormikGroupAddon from "../../GroupAddon";

import { usePriceValidation } from "../../../../../base/hooks/usePriceValidation";

const FormPriceDetails = ({ setFieldValue }) => {
  const [onChangeWithValidation, onKeyPress] = usePriceValidation({});

  return (
    <>
      <h3 className="bold-black mb-4">Price details</h3>
      <Divider />

      <Row className="mb-4">
        <Col md="6">
          <FormikGroupAddon
            label="Listing Price"
            name="listingPrice"
            groupText="$"
            placeholder={"00.00"}
            inputClassName="priceInputWrapper"
            onChange={(event, setValue) =>
              onChangeWithValidation(event, setValue)
            }
            onKeyPress={onKeyPress}
            isFieldOnChange={false}
          />
        </Col>
        <Col md="6">
          <FormikGroupAddon
            label="Cost of item"
            name="itemCost"
            groupText="$"
            placeholder={"00.00"}
            inputClassName="priceInputWrapper"
            onChange={(event, setValue) =>
              onChangeWithValidation(event, setValue)
            }
            onKeyPress={onKeyPress}
            isFieldOnChange={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default FormPriceDetails;
