import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { SearchInput } from "../../../../../pages/drafts/components/Search";

const SelectInput = ({ onChange, onFocus, onBlur, value, selectProps }) => {
  const { handleChangeSearch } = selectProps;

  const handleChange = useCallback(
    (event) => {
      handleChangeSearch(event);
      onChange(event);
    },
    [onChange, handleChangeSearch]
  );

  return (
    <SearchInput
      className="dropdown-search-input"
      autoComplete="off"
      placeholder="Search on all categories"
      onChange={handleChange}
      onBlur={onBlur}
      onFocus={onFocus}
      value={value}
      isDeleteButton={true}
      autoFocus
    />
  );
};

SelectInput.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
};

export default SelectInput;
