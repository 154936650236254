import { useMemo } from "react";

import { useInfiniteScroll } from "../../../base/components/InfiniteScrollWrapper/hooks/useInfiniteScroll";
import { useLocationQuery } from "../../../base/hooks/useQueryString";
import { useService } from "../../../base/hooks/useService";
import ShippingService from "../../../services/ShippingService";

export const useShippingList = (baseParams = {}) => {
  const {
    params: { marketplaceType },
  } = useLocationQuery();

  const addedParams = useMemo(
    () => ({
      marketplaceType,
      ...baseParams,
    }),
    [marketplaceType, baseParams]
  );

  /**
   * @type {ShippingService}
   */
  const shippingService = useService(ShippingService);

  const { data, handleFetch, hasMore, handleRefresh, totalCount, isLoading } =
    useInfiniteScroll({
      fetchData: (params) => shippingService.getList(params),
      addedParams,
      limit: 30,
    });

  return { data, handleFetch, hasMore, handleRefresh, totalCount, isLoading };
};
