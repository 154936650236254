import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Select from "react-select";
import { useField } from "formik";
import { FormGroup } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import ErrorMessageDefault from "./ErrorMessageDefault";
import Arrow from "./Select/DropdownSelect/components/Arrow";
import SelectWithCreate from "react-select/creatable";
import { customStyles } from "./Select/shared/utils/defaultSelectStyle";
import classnames from "classnames";
import { useTranslate } from "../hooks/useTranslate";
import { useTriggerFormChanges } from "../../pages/drafts/hooks/useDetectDirtyForm";
import { getMultiInitialOption } from "../helpers/getMultiInitialOption";

const FormikMultiSelect = ({
  name,
  options,
  placeholder,
  initialOption,
  label,
  allowCreate,
  description,
  isHideLabel = false,
  onRequestAPI = () => {},
  ...props
}) => {
  const [field, { error, touched }, helpers] = useField({ name });
  const [translate] = useTranslate();
  const [created, updateCreated] = useState([]);
  const { markChanges, containerRef } = useTriggerFormChanges();

  const allOptions = useMemo(() => {
    return [...options, ...created];
  }, [options, created]);

  const getCurrentValue = useMemo(() => {
    const map = {};

    if (Array.isArray(field.value)) {
      (field.value || [])?.forEach((name) => {
        map[name] = true;
      });
    }

    return allOptions?.filter(({ id }) => !!map[id]);
  }, [allOptions, field.value]);

  const handleChange = useCallback(
    (options) => {
      const getValueOptions = options?.map(({ id }) => id);
      helpers.setValue(getValueOptions);
      helpers.setTouched(true);
      markChanges();
    },
    [helpers, markChanges]
  );

  const Component = allowCreate ? SelectWithCreate : Select;

  useEffect(() => {
    // Created custom field
    // Need add to options
    const map = {};

    (options || []).forEach(({ id }) => {
      map[id] = true;
    });

    const customOptions = (field.value || "").toString().split(",");

    const listToAdd = customOptions?.filter((id) => !map[id]);

    updateCreated(
      listToAdd
        .filter((x) => x.replace(/ /g, "") !== "")
        .map((id) => ({
          id,
          label: id,
        }))
    );

    return () => {
      updateCreated([]);
    };
  }, [options, field.value]);

  const [search, updateSearch] = useState("");

  const handleRequestAPI = useCallback(
    (q) => {
      return onRequestAPI(q)
        ?.then((data) => {
          updateCreated([...data]);
        })
        .catch((e) =>
          Bugsnag.notify(e, (event) => {
            event.addMetadata("handleRequestAPIError", {
              q,
            });
          })
        );
    },
    [updateCreated, onRequestAPI]
  );

  useEffect(() => {
    handleRequestAPI(search);
  }, [handleRequestAPI, search]);

  return (
    <FormGroup className="mb-3">
      {label && !isHideLabel && (
        <label
          htmlFor={props.name}
          className={classNames({ "text-danger": error && touched })}
        >
          {label}
        </label>
      )}
      <Component
        isMulti={true}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        options={allOptions}
        value={
          getCurrentValue.length
            ? getCurrentValue
            : getMultiInitialOption(initialOption)
        }
        onChange={handleChange}
        placeholder={placeholder}
        getOptionValue={({ id }) => id}
        styles={customStyles}
        onInputChange={updateSearch}
        components={{
          ClearIndicator: null,
          IndicatorSeparator: null,
          DropdownIndicator: Arrow,
        }}
        isValidNewOption={(value) => !!value}
        onCreateOption={(id) => {
          const newOption = { id, label: id };
          updateCreated((options) => [...options, newOption]);
          handleChange([...getCurrentValue, newOption]);
        }}
      />
      {description && (
        <p
          className={classnames("font-size-10 text-gray-gomi mb-1", {
            "text-danger": error,
          })}
        >
          {description}
        </p>
      )}
      <div className="is-invalid-input is-invalid" ref={containerRef} />
      <ErrorMessageDefault>{translate(error, { label })}</ErrorMessageDefault>
    </FormGroup>
  );
};

FormikMultiSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  isHideLabel: PropTypes.bool,
  onRequestAPI: PropTypes.func,
  allowCreate: PropTypes.bool,
  initialOption: PropTypes.any,
};

export default FormikMultiSelect;
