import React from "react";
import PropTypes from "prop-types";

import ErrorMessageDefault from "../ErrorMessageDefault";
import { useCustomError } from "../../contexts/CustomErrorMessage";

export const CustomErrorMessage = ({ children, name }) => {
  const { getError } = useCustomError();
  return (
    <ErrorMessageDefault>{children || getError(name)}</ErrorMessageDefault>
  );
};

CustomErrorMessage.propTypes = {
  name: PropTypes.string,
  children: PropTypes.any,
};

export default CustomErrorMessage;
