/* eslint-disable react/prop-types */
import { Col, Row } from "reactstrap";

import React, { useEffect } from "react";
import FormikInput from "../../../../../base/components/FormikInput";
import { useField } from "formik";

const excludeSharp = (value) => {
  if (!value) return value;
  return value.replace(/#/g, "");
};

const transformValuePipe = (value) => {
  if (!value) return value;
  return "#" + excludeSharp(value.toString());
};

const Tags = ({ values }) => {
  const { tags_1, tags_2, tags_3 } = values;
  const [, , { setValue: setTags }] = useField({ name: "tags" });

  useEffect(() => {
    const tags = [
      excludeSharp(tags_1),
      excludeSharp(tags_2),
      excludeSharp(tags_3),
    ].filter(Boolean);
    setTags(tags.length ? tags : null);
  }, [tags_1, tags_2, tags_3]);

  return (
    <Row className="mb-4">
      <Col md="4">
        <FormikInput
          name="tags_1"
          label={"#tag"}
          containerClassName="mb-3"
          pipeValue={transformValuePipe}
          placeholder="Add a hashtag"
        />
      </Col>
      <Col md="4">
        <FormikInput
          name="tags_2"
          label={" "}
          pipeValue={transformValuePipe}
          containerClassName="mb-3"
          placeholder="Add a hashtag"
        />
      </Col>
      <Col md="4">
        <FormikInput
          name="tags_3"
          label={" "}
          pipeValue={transformValuePipe}
          containerClassName="mb-3"
          placeholder="Add a hashtag"
        />
      </Col>
    </Row>
  );
};

export default Tags;
