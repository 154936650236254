import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

import FormikGroupAddon from "../../GroupAddon";

import { usePriceValidation } from "../../../../../base/hooks/usePriceValidation";
import { useField } from "formik";
import { UPC_MAX } from "../../../../../base/constants/forms";

const FormUpc = ({ values }) => {
  const { doesNotApplyUpc } = values;
  const [, , helpers] = useField({ name: "upc" });

  const [onChangeWithValidation, onKeyPress] = usePriceValidation({
    maxValue: UPC_MAX,
    maxDecimalPartLength: 0,
  });

  useEffect(() => {
    if (doesNotApplyUpc) helpers.setValue(null);
  }, [doesNotApplyUpc]);

  return (
    <Row>
      <Col md="7">
        <FormikGroupAddon
          label={"UPC"}
          name="upc"
          placeholder="Enter UPC, EAN or ISBN code"
          inputClassName="priceInputWrapper"
          onChange={(event, setValue) =>
            onChangeWithValidation(event, setValue)
          }
          onKeyPress={onKeyPress}
          isFieldOnChange={false}
          max={UPC_MAX}
        />
      </Col>
    </Row>
  );
};

FormUpc.propTypes = {
  values: PropTypes.object,
};

export default FormUpc;
