export const HTTPS_PATTERN = /^((http|https):\/\/s3)/;
export const PASSWORD_PATTERN =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()?|]?)(?=.*\d)[\S]+$/;
export const PHONE_NUMBER_PATTERN = /^\+?1(\d+){10}$/;
export const DEV_PHONE_NUMBER_PATTERN = /^\+?(\d+){10,16}$/;
export const ACCESS_CODE_PATTERN = /^(\d+){6}$/;
export const SKU_CODE_PATTERN = /^(\d+){0,5}$/;
export const TAG_CODE_PATTERN = /^[a-zA-Z0-9,\s]*$/
export const DOMAIN_PATTERN = /([a-z0-9]+\.)*[a-z0-9]+\.[a-z]+/;
export const ONLY_LETTERS_PATTERN = /^[a-zA-Z]+$/;
// eslint-disable-next-line no-useless-escape
export const NO_ESCAPE = /[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g;
export const ONLY_NUMBERS_AND_DECIMAL_SEPARATORS = /[0-9]|[.,]/;
