import Bugsnag from "@bugsnag/js";

import ExtensionService from "./Extension";
import { EXTENSION_EVENTS, MARKETPLACES } from "../base/constants/extension";
import {
  LABEL_DISCOUNT_7_45,
  LABEL_NO_DISCOUNT,
} from "../pages/drafts/constants";
import { labelDiscount } from "../pages/drafts/helpers/Poshmark/generateLabelDiscount";

class PoshmarkExtension extends ExtensionService {
  static $displayName = "PoshmarkExtension";

  constructor() {
    super(MARKETPLACES.POSHMARK);
  }

  /**
   *
   * @param query {string}
   * @returns {Promise<any>}
   */
  searchBrands(query = "") {
    return this.request(EXTENSION_EVENTS.SEARCH_BRANDS, { params: { query } });
  }

  getAllBrands() {
    return this.request(EXTENSION_EVENTS.GET_ALL_BRANDS);
  }

  /**
   *
   * @param query {string}
   * @returns {Promise<any>}
   */
  getTags(query = "") {
    return this.request(EXTENSION_EVENTS.GET_TAGS, { params: { query } });
  }

  /**
   *
   * @param callback {(function(any): any )}
   * @returns {{toPromise: (function(): Promise<any>), unSubscribe: unSubscribe}}
   * @todo - call unSubscribe after checking completed auth
   * @desc - provide callback and listen products chunks
   */
  onCompletedAuth(callback) {
    const result = this.subscribe(
      EXTENSION_EVENTS.COMPLETED_AUTH,
      ({ err }) => {
        if (!err) callback();
      }
    );

    return result;
  }

  logOut() {
    return this.request(EXTENSION_EVENTS.LOG_OUT);
  }

  onCompletedLogout(callback) {
    return this.subscribe(EXTENSION_EVENTS.COMPLETED_LOG_OUT, ({ err }) => {
      if (!err) callback();
    });
  }
  /**
   *
   * @returns {Promise<any>}
   */
  isConnected() {
    return this.request(EXTENSION_EVENTS.IS_CONNECTED);
  }
  /**
   *
   * @returns {Promise<any>}
   */
  createDraft() {
    return this.request(EXTENSION_EVENTS.CREATE_DRAFT);
  }

  /**
   *
   * @param draftId {string|number}
   * @returns {Promise<any>}
   */
  getDraft(draftId) {
    return this.request(EXTENSION_EVENTS.GET_DRAFT, { draftId });
  }

  /**
   *
   * @param draftId {string|number}
   * @param model {object}
   * @param images {array}
   * @returns {Promise<any>}
   */
  postDraft(draftId, images, model) {
    return this.request(EXTENSION_EVENTS.POST_DRAFT, {
      draftId,
      images,
      model,
    });
  }

  /**
   *
   * @param draftId {string|number}
   * @param model {object}
   * @param images {array}
   * @returns {Promise<any>}
   */
  editInventory(draftId, images, model) {
    return this.request(EXTENSION_EVENTS.EDIT_INVENTORY, {
      draftId,
      images,
      model,
    });
  }

  /**
   *
   * @param draftId {string|number}
   * @returns {Promise<any>}
   */
  deleteInventory(draftId) {
    return this.request(EXTENSION_EVENTS.DELETE_DRAFT, { draftId });
  }

  /**
   *
   * @param callback {(function(any): any )}
   * @returns {{toPromise: (function(): Promise<any>), unSubscribe: unSubscribe}}
   * @todo - call unSubscribe after import
   * @desc - provide callback and listen products chunks
   */
  importProducts(callback) {
    return this.subscribe(EXTENSION_EVENTS.IMPORT_PRODUCTS, callback);
  }

  /**
   *
   * @returns {Promise<any>}
   */
  async getCategories() {
    if (!this.categoryData) {
      this.categoryData = await this.request(EXTENSION_EVENTS.GET_CATEGORIES);
    }

    return this.categoryData;
  }

  /**
   *
   * @param draftId {string|number}
   * @param base64 {string}
   * @returns {Promise<any>}
   */
  attachMedia(draftId, base64) {
    return this.request(EXTENSION_EVENTS.ADD_MEDIA, { draftId, base64 });
  }

  /**
   *
   * @param price {string|number}
   * @returns {Promise<any>}
   */
  getSellerShippingDiscounts(price) {
    return this.request(EXTENSION_EVENTS.GET_SELLER_SHIPPING_DISCOUNTS, {
      params: { price },
    })
      .then((response) => {
        const { data = [] } = response;

        return data.map((discount) => {
          return {
            ...discount,
            label: labelDiscount({
              label:
                discount.label === LABEL_DISCOUNT_7_45
                  ? LABEL_NO_DISCOUNT
                  : discount.label,
              footerMessage: discount.footer_message,
              isDisabled: !discount.enabled,
            }),
            isDisabled: !discount.enabled,
          };
        });
      })
      .catch((e) => Bugsnag.notify(e));
  }

  /**
   *
   * @param price {string|number}
   * @param discount_amount {string|number}
   * @returns {Promise<any>}
   */
  getSellerEarnings(price, discount_amount) {
    return this.request(EXTENSION_EVENTS.GET_SELLER_EARNINGS, {
      params: discount_amount ? { price, discount_amount } : { price },
    });
  }

  getSaleDetails(sale_id) {
    return this.request(EXTENSION_EVENTS.SALE_DETAILS, {
      params: { sale_id },
    });
  }

  /**
   *
   * @param callback {(function(any): any )}
   * @returns {{toPromise: (function(): Promise<any>), unSubscribe: unSubscribe}}
   */
  importSoldProducts(callback) {
    return this.subscribe(EXTENSION_EVENTS.GET_SOLD_ITEMS, callback);
  }
  /**
   *
   * @returns {*}
   */
  getProfile() {
    return this.request(EXTENSION_EVENTS.GET_PROFILE);
  }
}

export default PoshmarkExtension;
