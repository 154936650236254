import React, {useEffect, useState} from "react";
import FormikInput from "../../../../../../../base/components/FormikInput";
import {MAX_TITLE_POSHMARK_LENGTH} from "../../../../../const/poshmark";
import {Col,Row} from "reactstrap";
import CustomButton from "../../../../../../../base/components/CustomButton";
import { ReactComponent as Delete } from "../../../../../../drafts/components/Icons/Delete.svg";
import { ReactComponent as Edit } from "../../../../../../../assets/images/icon-16-edit-green.svg";

import "./index.scss";
import classnames from "classnames";
import SizeUtils from "../../../../../utils/Poshmark/SizeUtils";
import {useParams} from "react-router-dom";
import { useField } from "formik";
import { useTriggerFormChanges } from "../../../../../hooks/useDetectDirtyForm";

const SizeCustom = ({values, setFieldValue, currentSystem, currentTab}) => {
    const {draftId} = useParams()
    const [isDisabled, updateIsDisabled] = useState(false)
    const [isActiveDone, updateIsActiveDone] = useState(false)
  const { markChanges, containerRef } = useTriggerFormChanges();

    const [,, {setValue}] = useField({name: "sizeName"});

    const onSave = () => {
        if (!values.customSize) return;
        updateIsDisabled(true)
        updateIsActiveDone(true)
    }

    const onEdit = () => {
        updateIsDisabled(false)
        updateIsActiveDone(false)
    }

    const onDelete = () => {
        updateIsDisabled(false)
        updateIsActiveDone(false)
        setFieldValue("customSize", "")
    }

    useEffect(() => {
        const [tabId,,sizeId] = SizeUtils.getSize(values.sizeId)

        setFieldValue("customSize", (Number(tabId) === Number(currentTab) && draftId)? sizeId : "")
        updateIsDisabled(!!sizeId)
    }, [])

    const onDone = () => {
        SizeUtils.setSize({
            setFieldValue: setFieldValue,
            tab: currentTab,
            system: currentSystem.id,
            id: values.customSize
        });
        markChanges();
        setValue(values.customSize)
    }

    return(
        <>
            <Row className="d-flex align-items-center">
                <div className="input-block" ref={containerRef}>
                    <FormikInput
                        id="customSize"
                        name="customSize"
                        placeholder="Write custom size"
                        type="text"
                        maxLength={MAX_TITLE_POSHMARK_LENGTH}
                        disabled={isDisabled}
                    />
                </div>
                <div
                    className={`d-flex save-block ${isDisabled ? "d-none" : ""}`}
                    onClick={onSave}
                >
                    <p
                        className={classnames("mb-0 fw-bold cursor-pointer custom-size-save",
                            {disabled: !values.customSize}
                        )}
                    >
                        Save
                    </p>
                </div>
                <div className={`d-flex action-block ${!isDisabled ? "d-none" : ""}`}>
                    <Edit className="cursor-pointer mr-1" onClick={onEdit}/>
                    <Delete className="cursor-pointer" onClick={onDelete}/>
                </div>
            </Row>
            <Row className="button-wrapper">
                <Col className="d-flex justify-content-end">
                    <CustomButton
                        className='filled-sm custom-size-btn'
                        disabled={!isActiveDone}
                        onClick={onDone}
                    >
                        Done
                    </CustomButton>
                </Col>
            </Row>
        </>

    )
};

export default SizeCustom;
