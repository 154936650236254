import Http from "./Http";

class MileagesService extends Http {
  static $displayName = "MileagesService";

  getMileageList(params) {
    return this.get("/mileages", { params });
  }

  createMileage(values) {
    return this.post("/mileages", values);
  }

  editMileage(id, values) {
    return this.put(`/mileages/${id}`, values);
  }

  deleteMileage(id) {
    return this.delete(`/mileages/${id}`);
  }
}

export default MileagesService;
