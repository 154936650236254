import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";

import { useGoogleLogin } from "react-google-login";
import { IS_GOOGLE } from "../../constants/index";
import Button from "../../../Button";
import { ENV } from "../../../../constants/env";

export const GoogleLoginButton = ({
  isDisabled,
  onPending,
  onSuccess,
  onFail,
  children,
}) => {
  const onSuccessLogin = useCallback(
    (res) => {
      onSuccess(res.accessToken, IS_GOOGLE);
      onPending(true);
    },
    [onPending, onSuccess]
  );

  const onFailure = useCallback(
    (error) => {
      onFail(error);
      onPending(true);
    },
    [onPending, onFail]
  );

  const { signIn } = useGoogleLogin({
    onSuccess: onSuccessLogin,
    onFailure,
    clientId: ENV.GOOGLE_CLIENT_ID,
    isSignedIn: false,
    accessType: "offline",
  });

  const onClick = useCallback(
    (event) => {
      event.preventDefault();
      onPending(false);
      return signIn(event);
    },
    [onPending, signIn]
  );

  useEffect(() => {
    onPending(true);
  }, [signIn]);

  return (
    <Button
      onClick={onClick}
      disabled={isDisabled}
      className="google-button w-100"
    >
      {children}
    </Button>
  );
};

GoogleLoginButton.propTypes = {
  isDisabled: PropTypes.bool,
  children: PropTypes.any,
  onPending: PropTypes.func,
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
};
