import React, { useCallback, useState, useEffect } from "react";
import { Card, CardBody } from "reactstrap";

import CustomButton from "../../../../base/components/CustomButton";
import InfiniteScrollWrapper from "../../../../base/components/InfiniteScrollWrapper";
import Placeholder from "../../../../base/components/Table/placeholder";

import ExpenseCategoryFilter from "../ExpenseCategoryFilter";
import ExpenseDateFilter from "../ExpenseDateFilter";
import ExpensesHeader from "../ExpensesHeader";
import ExpensesPlaceholder from "../ExpensesPlaceholder";
import ExpenseCard from "../ExpenseCard";
import ConfirmCloseModal from "../ConfirmCloseModal";
import ExpenseSearch from "../ExpenseSearch";
import EmptyDateFilterPlaceholder from "../EmptyDateFilterPlaceholder";
import CreateExpenseModal from "../CreateExpenseModal";

import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useGetExpenseList } from "../../hooks/useGetExpensesList";
import { useExpenseDateFilter } from "../../hooks/useExpenseDateFilter";
import { useDeleteExpense } from "../../hooks/useDeleteExpense";
import { useEditExpense } from "../../hooks/useEditExpense";

import "./index.scss";

import { EXPENSES_TAB } from "../../constants/expensesTabs";
import { useExpensesCategories } from "../../hooks/useExpensesCategories";
import WhiteBox from "../../../drafts/components/WhiteBox";

const ExpensesList = ({
                        getCount = () => {
                        },
                      }) => {
  const [isOpenModalCreateExpense, setIsOpenModalCreateExpense] =
    useState(false);
  const [isConfirmCloseModal, setIsConfirmCloseModal] = useState(false);

  const {
    params: { query: q, categoryId, startDate, endDate, orderBy, orderType },
  } = useLocationQuery();

  const { data, hasMore, handleFetch, isLoading, handleRefresh, totalCount } =
    useGetExpenseList();

  const [categories] = useExpensesCategories();

  const [onEdit, editModal] = useEditExpense(handleRefresh, categories);
  const [onDelete, deleteModal] = useDeleteExpense(handleRefresh);

  const onRefresh = useCallback(() => {
    handleRefresh();
  }, [q, categoryId, startDate, endDate, orderBy, orderType]);

  useEffect(() => {
    onRefresh();
  }, [onRefresh]);

  useEffect(() => {
    getCount({ [EXPENSES_TAB]: totalCount });
  }, [totalCount]);

  const onCloseConfirmModal = useCallback(() => {
    setIsConfirmCloseModal(false);
  }, [setIsConfirmCloseModal]);

  const onConfirmCloseCreateModal = useCallback(() => {
    setIsConfirmCloseModal(false);
    setIsOpenModalCreateExpense(false);
  }, [setIsConfirmCloseModal, setIsOpenModalCreateExpense]);

  const handleToggleModalCreateExpense = (_, isDirty) => {
    if (isDirty) {
      setIsConfirmCloseModal(true);
      return;
    }

    setIsOpenModalCreateExpense((prevState) => !prevState);
  };

  const [dateRange, onChangeDateRange] = useExpenseDateFilter();

  const placeholdersMap = useCallback(() => {
    if (!data?.length && !isLoading && !q && !endDate) {
      return <ExpensesPlaceholder onClick={handleToggleModalCreateExpense}/>;
    }

    if (!data?.length && !!q) {
      return <Placeholder text={"No found expenses"}/>;
    }

    if (!data?.length && endDate?.length) {
      return (
        <EmptyDateFilterPlaceholder text="No expenses for chosen period"/>
      );
    }
  }, [data, isLoading, q, endDate]);

  const [openedCard, setOpenedCard] = useState(null);

  const handleToggleCollapse = (id) => {
    setOpenedCard((prevState) => (prevState === id ? null : id));
  };
  return (
    <>
      <Card>
        <CardBody>
          {isLoading ? (
            <WhiteBox>
              <p>Loading...</p>
            </WhiteBox>
          ) : !data?.length && !isLoading && !q && !endDate && !categoryId ? (
            <ExpensesPlaceholder onClick={handleToggleModalCreateExpense}/>
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <ExpenseSearch placeholder="Search by Title"/>
                <div className="d-flex align-items-center">
                  <div className="expense-filters-wrapper">
                    <div className="d-flex align-items-center mr-4">
                      <span className="date-filter-label">
                        Filter by category:
                      </span>
                      <ExpenseCategoryFilter categories={categories}/>
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="date-filter-label">Filter by date:</span>
                      <ExpenseDateFilter
                        dateRange={dateRange}
                        onChange={onChangeDateRange}
                        containerClass={"dateFilterContainer"}
                      />
                    </div>
                  </div>

                  <CustomButton
                    className="filled-primary create-expense-btn"
                    onClick={handleToggleModalCreateExpense}
                  >
                    + Create expense
                  </CustomButton>
                </div>
              </div>
              <ExpensesHeader/>
              <InfiniteScrollWrapper hasMore={hasMore} fetchData={handleFetch}>
                {placeholdersMap()}
                {data &&
                data.map((expense) => {
                  return (
                    <ExpenseCard
                      onEditExpense={onEdit}
                      onDeleteExpense={onDelete}
                      key={expense?.id}
                      handleToggleCollapse={() =>
                        handleToggleCollapse(expense?.id)
                      }
                      isOpenCollapse={openedCard === expense?.id}
                      {...expense}
                    />
                  );
                })}
              </InfiniteScrollWrapper>
            </>
          )}
        </CardBody>
      </Card>
      <CreateExpenseModal
        isOpen={isOpenModalCreateExpense}
        onClose={handleToggleModalCreateExpense}
        afterSubmit={handleRefresh}
        categories={categories}
      />
      <ConfirmCloseModal
        isOpen={isConfirmCloseModal}
        onClose={onCloseConfirmModal}
        onConfirm={onConfirmCloseCreateModal}
      />
      {editModal}
      {deleteModal}
    </>
  );
};

export default ExpensesList;
