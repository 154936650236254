import {
  MARKETPLACE_EBAY_TYPE,
  MARKETPLACE_ETSY_TYPE,
  MARKETPLACE_MERCARI_TYPE,
  MARKETPLACE_POSHMARK_TYPE,
} from "../../constants/importInventory.js";

import EbayImportModal from "./EbayImportModal";
import EtsyImportModal from "./EtsyImportModal";
import PoshmarkImportModal from "./PoshmarkImportModal";
import MercariImportModal from "./MercariImportModal";

export const BACKEND_KEYS = {
  [MARKETPLACE_EBAY_TYPE]: "ebayAccount",
  [MARKETPLACE_ETSY_TYPE]: "etsyAccount",
  [MARKETPLACE_POSHMARK_TYPE]: "poshmarkAccountDto",
  [MARKETPLACE_MERCARI_TYPE]: "mercariAccountDto",
};

export const activeImportList = [
  {
    title: "eBay",
    backendKey: BACKEND_KEYS[MARKETPLACE_EBAY_TYPE],
    ModalComponent: EbayImportModal,
    marketplaceType: MARKETPLACE_EBAY_TYPE,
  },
  {
    title: "Etsy",
    backendKey: BACKEND_KEYS[MARKETPLACE_ETSY_TYPE],
    ModalComponent: EtsyImportModal,
    marketplaceType: MARKETPLACE_ETSY_TYPE,
  },
  {
    title: "Poshmark",
    backendKey: BACKEND_KEYS[MARKETPLACE_POSHMARK_TYPE],
    ModalComponent: PoshmarkImportModal,
    marketplaceType: MARKETPLACE_POSHMARK_TYPE,
  },
  {
    title: "Mercari",
    backendKey: BACKEND_KEYS[MARKETPLACE_MERCARI_TYPE],
    ModalComponent: MercariImportModal,
    marketplaceType: MARKETPLACE_MERCARI_TYPE,
  },
];

export const soldImportList = [
  {
    title: "eBay",
    backendKey: BACKEND_KEYS[MARKETPLACE_EBAY_TYPE],
    ModalComponent: EbayImportModal,
    marketplaceType: MARKETPLACE_EBAY_TYPE,
  },
  {
    title: "Poshmark",
    backendKey: BACKEND_KEYS[MARKETPLACE_POSHMARK_TYPE],
    ModalComponent: PoshmarkImportModal,
    marketplaceType: MARKETPLACE_POSHMARK_TYPE,
  },
  {
    title: "Mercari",
    backendKey: BACKEND_KEYS[MARKETPLACE_MERCARI_TYPE],
    ModalComponent: MercariImportModal,
    marketplaceType: MARKETPLACE_MERCARI_TYPE,
  },
];
