import { COUNT_DIGITS_0, COUNT_DIGITS_2 } from "../constants";

const formatData = (value, count) => {
  return Math.abs(value)?.toFixed(count).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const separateThousandth = (value) => {
  if(!value)return "-"
    const prefix = Number(value).toFixed(COUNT_DIGITS_2) >= 0 ? "$" : "-$";
    return prefix +  formatData(value, COUNT_DIGITS_2)
}

export const separateThousandthFloored = (value) => {
  if(!value)return "-"
    const prefix = Number(value).toFixed(COUNT_DIGITS_0) >= 0 ? "$" : "-$";
    return prefix +  formatData(value, COUNT_DIGITS_0)
}