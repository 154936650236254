import Http from "./Http";
import axios from "axios";
import { LINK_TO_REFRESH_TOKEN_API } from "../base/links";
import { initService } from "../base/hooks/useService";
import SessionStorage from "./SessionStorage";

let promise = null;

/**
 *
 * @param {string} refreshToken
 * @returns {Promise<AxiosResponse<T>>}
 */
export const sendRefreshToken = async (refreshToken) => {
  /**
   * @type {SessionStorage}
   */
  const storage = initService(SessionStorage);
  const API = `${Http.api.common}/${Http.versions.v1}${LINK_TO_REFRESH_TOKEN_API}`;

  if (!promise) {
    promise = axios.put(API, refreshToken, { withCredentials: true });
  }

  const response = await promise;

  storage.setSession(response.data.data);

  promise = null;

  return response;
};
