import React, { useEffect, useState } from "react";

import { useService } from "../../../../base/hooks/useService";
import TaskManagement from "../../../../services/TaskManagement";
import { ReactComponent as Message } from "../Icons/Message.svg";
import CommentItem from "../CommentItem";
import CommentForm from "../CommentForm";
import { MAX_COMMENTS } from "../../constants/default";
import '../TaskCard/styles.scss'

const TaskComments = ({
  taskId,
  profileId,
  isOpen,
  onChangeMessage,
  onDeleteMessage,
  onCreateMessage,
}) => {
  const [comments, setComments] = useState([]);
  /**
   * @type {TaskManagement}
   */
  const TaskManagementService = useService(TaskManagement);

  const totalCount = comments.length;
  const isAddNewComment = totalCount < MAX_COMMENTS;

  const getComments = async () => {
    const { data } = await TaskManagementService.getComments(taskId);
    setComments(data);
  };

  const handleChangeMessage = async ({ ...props }) => {
    await onChangeMessage({ ...props });
    await getComments();
  };

  const handleDeleteMessage = async ({ ...props }) => {
    await onDeleteMessage({ ...props });
    await getComments();
  };

  const handleCreateMessage = async ({ ...props }) => {
    await onCreateMessage({ taskId, ...props });
    await getComments();
  };

  useEffect(() => {
    if (!isOpen) return;

    getComments();
  }, [isOpen]);

  return (
    <>
      <div className="d-flex align-items-center mb-3 comments-container">
        <Message />
        <p className="mb-0 ms-2 fw-bold font-size-13">
          Comments ({totalCount})
        </p>
      </div>
      {comments.map((comment) => (
        <CommentItem
          key={comment.id}
          isEdited={comment.updatedAt !== comment.createdAt}
          isCreator={profileId === comment.from.id}
          onChangeMessage={handleChangeMessage}
          onDeleteMessage={handleDeleteMessage}
          {...comment}
        />
      ))}

      {isAddNewComment && <CommentForm onSubmit={handleCreateMessage} />}
    </>
  );
};

export default TaskComments;
