/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState, useEffect } from "react";

const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(null);

  const setLoadingState = (state, message = null) => {
    setLoading(state);
    setLoadingMessage(message);
  };

  useEffect(() => {
    if (!loading) {
      setLoadingMessage(null);
    }
  }, [loading]);

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading: setLoadingState,
        loadingMessage,
        setLoadingMessage,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export const useGlobalLoading = () => useContext(LoadingContext);
