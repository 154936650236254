import React, { useCallback, useRef, useState } from "react";
import { Tooltip } from "reactstrap";
import classnames from "classnames";

import IconButton from "../../../../components/IconButton";
import ProductPreview from "../../../../components/ProductPreview";

import { ReactComponent as Delete } from "../../../../components/Icons/Delete.svg";

import { useStatelessProvider } from "../../../../../../base/hooks/useProvider";

import { TableContext } from "../../../../contexts/table";
import WithHighlight from "../../../../components/WithHighlight";

import styles from "./index.module.scss";
import { EMPLOYEE_STATUSES } from "./constants";
import defaultEmployeeImageUrl from "./images/icon-profile-photo-52-placeholder.svg";

export const LIMIT = 10;

const useTooltipProps = (ref) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return {
    isOpen: tooltipOpen,
    target: ref,
    toggle,
  };
};

export const StatusLabel = ({ statusCode }) => {
  const status = Object.values(EMPLOYEE_STATUSES).find(
    (employeeStatus) => employeeStatus?.code === statusCode
  );

  const statusStyles = {
    [EMPLOYEE_STATUSES.inProgress.code]: styles.pendingStatusLabel,
    [EMPLOYEE_STATUSES.active.code]: styles.activeStatusLabel,
    [EMPLOYEE_STATUSES.failed.code]: styles.failedStatusLabel,
  };

  return (
    <span className={classnames(styles.statusLabel, statusStyles[statusCode])}>
      {status?.label || "-"}
    </span>
  );
};

export const ActionBlock = ({ id, isBlocked }) => {
  const [{ onBlock }] = useStatelessProvider(TableContext);

  const handleBlock = useCallback(async () => {
    const newStatus = isBlocked
      ? EMPLOYEE_STATUSES.active.code
      : EMPLOYEE_STATUSES.blocked.code;

    onBlock(id, newStatus);
  }, [id, onBlock, isBlocked]);

  return (
    <button
      className={classnames(styles.actionButton, {
        [styles.actionUnblockButton]: isBlocked,
        [styles.actionBlockButton]: !isBlocked,
      })}
      onClick={handleBlock}
    >
      {isBlocked ? "Unblock" : "Block"}
    </button>
  );
};

export const ActionDelete = ({ id }) => {
  const ref = useRef();
  const [{ onDelete }] = useStatelessProvider(TableContext);

  const handleDelete = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);

  return (
    <>
      <IconButton
        className={styles.actionIconButton}
        ref={ref}
        onClick={handleDelete}
      >
        <Delete />
      </IconButton>
      <Tooltip placement="top" {...useTooltipProps(ref)}>
        Delete
      </Tooltip>
    </>
  );
};

export const TableStructure = () => {
  return [
    {
      text: "Name",
      style: {
        width: "30%",
      },
      formatter: (_, { firstName, lastName }) => {
        return (
          <div className={styles.nameWrapper}>
            <ProductPreview
              className={styles.image}
              src={"" || defaultEmployeeImageUrl}
            />
            <WithHighlight
              text={firstName ? `${firstName} ${lastName}` : "-"}
              className={styles.title}
            />
          </div>
        );
      },
      sort: false,
      dataField: "firstName",
    },
    {
      text: "Email",
      style: {
        width: "25%",
      },

      formatter: (_, { email }) => {
        return (
          <div className={styles.emailWrapper}>
            <WithHighlight text={email || "-"} exact />
          </div>
        );
      },
      dataField: "email",
      sort: false,
    },
    {
      text: "Status",
      headerAlign: "center",
      style: {
        width: "30%",
      },
      formatter: (_, { employeeAdditionalFields }) => {
        return <StatusLabel statusCode={employeeAdditionalFields.status} />;
      },
      dataField: "status",
      align: "center",
      sort: false,
    },
    {
      text: "Actions",
      headerAlign: "center",
      align: "right",
      style: { width: "15%" },
      formatter: (_, { id, employeeAdditionalFields, isBlocked }) => {
        return (
          <div
            className={"table-actions"}
            onClick={(event) => event.stopPropagation()}
          >
            {[
              EMPLOYEE_STATUSES.active.code,
              EMPLOYEE_STATUSES.blocked.code,
            ].includes(employeeAdditionalFields.status) && (
              <ActionBlock id={id} isBlocked={isBlocked} />
            )}
            <ActionDelete id={id} />
          </div>
        );
      },
      dataField: "id",
    },
  ];
};
