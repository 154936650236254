import React, { useCallback, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { Formik } from "formik";
import Bugsnag from "@bugsnag/js";

import FormikInput from "../../../../base/components/FormikInput";
import Button from "../../../../base/components/Button";
import CustomButton from "../../../../base/components/CustomButton";
import ForgotPasswordFooter from "../../components/ForgotPasswordFooter";
import CustomAlert from "../../components/CustomAlert";
import AuthCardContainer from "../../components/AuthCardContainer";
import AuthHeader from "../../components/Header";
import AuthService from "../../../../services/AuthService";

import { useService } from "../../../../base/hooks/useService";
import { initialValues, validationSchema } from "./form";
import { useLoading } from "../../../../base/hooks/useLoading";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../base/components/CustomModal";

const ForgetPasswordPage = () => {
  const [, { registerPromise }] = useLoading();
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);

  const [isOpenModal, updateIsOpenModal] = useState(false);

  const handleToogleModal = useCallback(() => {
    updateIsOpenModal((isOpen) => !isOpen);
  }, []);

  const sendEmailLink = useCallback(({ email }) => {
    registerPromise(authService.sendRestorePasswordEmail(email))
      .then(() => {
        handleToogleModal();
      })
      .catch((e) => Bugsnag.notify(e));
  }, []);

  return (
    <AuthCardContainer metaText="Restore password">
      <Card className="overflow-hidden">
        <AuthHeader headerText="Reset Password" description="&nbsp;" />
        <CardBody className="pt-0">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={sendEmailLink}
          >
            {({ handleSubmit }) => (
              <form
                className="form-horizontal d-flex flex-column p-2"
                onSubmit={handleSubmit}
              >
                <CustomAlert className="mb-4">
                  Enter your email and instructions will be sent to you!
                </CustomAlert>
                <FormikInput
                  id="email"
                  name="email"
                  label="Email"
                  containerClassName="mb-3"
                  placeholder="Enter email"
                  type="email"
                />
                <CustomButton
                  type="submit"
                  className="filled-primary align-self-end mt-2"
                >
                  Send restore link
                </CustomButton>
              </form>
            )}
          </Formik>
        </CardBody>
      </Card>
      <ForgotPasswordFooter />
      <CustomModal isOpen={isOpenModal} onClose={handleToogleModal}>
        <ModalHeader onClose={handleToogleModal}>Reset Password</ModalHeader>
        <ModalBody>
          <p className="ui-regular">
            The link to restore your password was sent to your email.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button className="filled-primary" onClick={handleToogleModal}>
            Ok
          </Button>
        </ModalFooter>
      </CustomModal>
    </AuthCardContainer>
  );
};

export default ForgetPasswordPage;
