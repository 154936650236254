/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef } from "react";
import { Formik } from "formik";
import { Row, Col } from "reactstrap";

import CustomButton from "../../../../../../base/components/CustomButton";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../../../base/components/CustomModal";
import FormikSingleSelect from "../../../../../../base/components/FormikSingleSelect";
import Divider from "../../../../components/Divider";
import { getFormattedDate } from "../../../../helpers/format";

import InfoItem from "./components/InfoItem";
import InfoPriceItem from "./components/InfoPriceItem";
import styles from "./index.module.scss";
import { MAX_BUYER_LENGTH, validationSchema } from "./form";
import {
  ADDITIONAL_SELLING_FEES_TOOLTIP_TEXT,
  MARKETPLACES_OPTIONS,
  MARKETPLACE_TYPES,
  PAYMENTS_METHODS,
  PAYMENT_METHOD_OPTIONS,
  SHIPPING_CHARGED_TO_BUYER_TOOLTIP_TEXT,
} from "./constants";
import { calcEtsyFees } from "../../../../helpers/Etsy/fees";
import { calcPoshmarkFees } from "../../../../helpers/Poshmark/calcPoshmarkFees";
import { calcMercariFees } from "../../../../helpers/Mercari/calcMercariFees";
import { calcTransactionFeesByFormulaForMercari } from "../../../../helpers/Mercari/calcMercariTransactionFees";
import { useHistory } from "react-router-dom";
import { SOLD_GROUP_LINKS } from "../../../../../sold/config";
import { calcEtsyMarketplaceFees } from "../../../../helpers/Etsy/calcEtsyFees";
import ToolTip from "../../../../../../base/components/ToolTip/ToolTip";

export const UseEffectInsideFormik = ({ values, setFieldValue, dirty }) => {
  const prevPriceSoldValue = useRef(values.priceSold);
  const prevMarketplaceType = useRef(values.marketplaceType);
  const prevPaymentMethod = useRef(values.paymentMethod);
  const prevShippingCost = useRef(values.shippingCost);

  const history = useHistory();
  const isSoldPage = useMemo(() => {
    return history.location.pathname.includes(SOLD_GROUP_LINKS.BASE);
  }, [history]);

  useEffect(() => {
    const isNeedClearTransactionFees =
      values.marketplaceType === MARKETPLACE_TYPES.ebay ||
      values.marketplaceType === MARKETPLACE_TYPES.poshmark ||
      values.marketplaceType === MARKETPLACE_TYPES.other;

    const isNeedClearMarketplaceFees =
      values.marketplaceType === MARKETPLACE_TYPES.etsy ||
      values.marketplaceType === MARKETPLACE_TYPES.other;

    if (isNeedClearTransactionFees) {
      setFieldValue("transactionFees", "");
    }

    if (isNeedClearMarketplaceFees) {
      setFieldValue("marketplaceFees", "");
    }
  }, [values.marketplaceType]);

  useEffect(() => {
    const mode = isSoldPage ? !dirty : dirty;

    const getTransactionFees = () => {
      if (values.marketplaceType === MARKETPLACE_TYPES.etsy) {
        //this condition needed to edit transactionFees field
        if (
          prevPriceSoldValue.current === values.priceSold &&
          prevMarketplaceType.current === values.marketplaceType &&
          prevPaymentMethod.current === values.paymentMethod &&
          mode
        )
          return;

        return setFieldValue(
          "transactionFees",
          calcEtsyFees(values.priceSold, values.paymentMethod)
        );
      }

      if (values.marketplaceType === MARKETPLACE_TYPES.mercari) {
        //this condition needed to edit transactionFees field
        if (
          prevPriceSoldValue.current === values.priceSold &&
          prevMarketplaceType.current === values.marketplaceType &&
          mode
        )
          return;

        return setFieldValue(
          "transactionFees",
          calcTransactionFeesByFormulaForMercari(
            values.priceSold,
            isNaN(parseFloat(values.shippingCost))
              ? 0
              : parseFloat(values.shippingCost)
          )
        );
      }
    };

    const getMarketplaceFees = () => {
      if (values.marketplaceType === MARKETPLACE_TYPES.etsy) {
        //this condition needed to edit marketplaceFees field
        if (
          prevPriceSoldValue.current === values.priceSold &&
          prevMarketplaceType.current === values.marketplaceType &&
          prevShippingCost.current === values.shippingCost &&
          mode
        )
          return;

        return setFieldValue(
          "marketplaceFees",
          calcEtsyMarketplaceFees(values.priceSold, values.shippingCost)
        );
      }

      if (values.marketplaceType === MARKETPLACE_TYPES.mercari) {
        //this condition needed to edit marketplaceFees field
        if (
          prevPriceSoldValue.current === values.priceSold &&
          prevMarketplaceType.current === values.marketplaceType &&
          mode
        )
          return;

        return setFieldValue(
          "marketplaceFees",
          calcMercariFees(values.priceSold)
        );
      }

      if (values.marketplaceType === MARKETPLACE_TYPES.poshmark) {
        //this condition needed to edit marketplaceFees field
        if (
          prevPriceSoldValue.current === values.priceSold &&
          prevMarketplaceType.current === values.marketplaceType &&
          mode
        )
          return;

        return setFieldValue(
          "marketplaceFees",
          calcPoshmarkFees(values.priceSold)
        );
      }
    };

    getTransactionFees();
    getMarketplaceFees();
    prevPriceSoldValue.current = values.priceSold;
    prevMarketplaceType.current = values.marketplaceType;
    prevPaymentMethod.current = values.paymentMethod;
  }, [values, dirty, isSoldPage]);

  return null;
};

const SoldModal = ({
  isOpen,
  onClose,
  title,
  onConfirm,
  soldValues = {},
  processing,
}) => {
  const selectedMarketplaceType = useMemo(
    () =>
      MARKETPLACES_OPTIONS.find(
        (marketplace) => marketplace.label === soldValues.marketplace
      ),
    [soldValues]
  );

  return (
    <CustomModal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalHeader onClose={onClose}>{title}</ModalHeader>
      <Formik
        initialValues={{
          priceSold: soldValues.priceSold || "",
          marketplaceFees: "",
          costOfGood: soldValues.costOfGood || "",
          shippingCost: soldValues.shippingCost || "",
          marketplaceType: selectedMarketplaceType?.id,
          paymentMethod: PAYMENTS_METHODS.etsy,
          transactionFees: "",
          additionalSellingFees: "",
          shippingChargedToBuyer: "",
          productId: soldValues.productId || "",
          title: soldValues.title || "",
          sku: soldValues.sku || "",
          color: soldValues.color || "",
          buyer: soldValues.buyer || "",
        }}
        validateOnChange={true}
        onSubmit={onConfirm}
        validationSchema={validationSchema}
      >
        {({ values, handleSubmit, setFieldValue, dirty }) => {
          const isShowEmptyPriceSoldWarning =
            !values.priceSold &&
            (values.marketplaceType === MARKETPLACE_TYPES.etsy ||
              values.marketplaceType === MARKETPLACE_TYPES.mercari);

          const isShowToolTip =
            values.marketplaceType === MARKETPLACE_TYPES.ebay;

          return (
            <form onSubmit={handleSubmit}>
              <UseEffectInsideFormik
                values={values}
                setFieldValue={setFieldValue}
                dirty={dirty}
              />
              <ModalBody>
                <div className={styles.wrapper}>
                  <div className={styles.mainInfo}>
                    <InfoItem
                      title="Date listed"
                      value={getFormattedDate(soldValues?.dateListed)}
                    />
                    <InfoItem title="Brand" value={soldValues?.brand} />
                  </div>
                  <div className={styles.mainInfo}>
                    <InfoItem
                      title="Sold date"
                      value={getFormattedDate(soldValues?.soldDate)}
                    />
                  </div>
                  <div>
                    <Row>
                      <Col md={2}>
                        <p className="mb-0 fw-medium">Category</p>
                      </Col>
                      <Col md={10}>
                        <p className="mb-0 text-gray-gomi">
                          {soldValues?.categoryName}
                        </p>
                      </Col>
                    </Row>
                  </div>
                  <Divider />
                  {isShowEmptyPriceSoldWarning && (
                    <span className={styles.emptyPriceSoldWarning}>
                      Enter sold price first to calculate transaction fees
                    </span>
                  )}
                  <div className={styles.mainPriceInfo}>
                    <InfoPriceItem
                      title="Price sold"
                      value={values.priceSold}
                      name="priceSold"
                      onChange={(value) => {
                        setFieldValue("priceSold", value);
                      }}
                      placeholder="0"
                    />
                    <InfoPriceItem
                      title="Additional selling fees"
                      value={values.additionalSellingFees}
                      name="additionalSellingFees"
                      onChange={(value) => {
                        setFieldValue("additionalSellingFees", value);
                      }}
                      placeholder="0"
                      toolTip={
                        isShowToolTip && (
                          <ToolTip
                            classNames="mr-2"
                            text={ADDITIONAL_SELLING_FEES_TOOLTIP_TEXT}
                          />
                        )
                      }
                    />
                    <InfoPriceItem
                      title="Cost of good"
                      value={values.costOfGood}
                      name="costOfGood"
                      onChange={(value) => {
                        setFieldValue("costOfGood", value);
                      }}
                      placeholder="0"
                    />
                    <InfoPriceItem
                      title="Transaction fees"
                      value={values.transactionFees}
                      name="transactionFees"
                      onChange={(value) => {
                        setFieldValue("transactionFees", value);
                      }}
                      placeholder="0"
                    />
                    <FormikSingleSelect
                      name="marketplaceType"
                      label="Marketplace"
                      options={MARKETPLACES_OPTIONS}
                      containerClassName={styles.dropdownWrapper}
                      placeholder="Choose marketplace"
                      isClearBtn={false}
                    />
                    <InfoPriceItem
                      title="Shipping cost"
                      value={values.shippingCost}
                      name="shippingCost"
                      onChange={(value) => {
                        setFieldValue("shippingCost", value);
                      }}
                      placeholder="0"
                    />
                    <InfoPriceItem
                      title="Marketplace fees"
                      value={values.marketplaceFees}
                      name="marketplaceFees"
                      onChange={(value) => {
                        setFieldValue("marketplaceFees", value);
                      }}
                      placeholder="0"
                    />
                    <InfoPriceItem
                      title="Shipping charged to buyer"
                      value={values.shippingChargedToBuyer}
                      name="shippingChargedToBuyer"
                      onChange={(value) => {
                        setFieldValue("shippingChargedToBuyer", value);
                      }}
                      placeholder="0"
                      toolTip={
                        isShowToolTip && (
                          <ToolTip
                            classNames="mr-2"
                            text={SHIPPING_CHARGED_TO_BUYER_TOOLTIP_TEXT}
                          />
                        )
                      }
                    />
                    <InfoPriceItem
                      title="Buyer"
                      value={values.buyer}
                      name="buyer"
                      onChange={(value) => {
                        setFieldValue("buyer", value);
                      }}
                      placeholder="Enter buyer"
                      isOnlyInput
                      isPrice={false}
                      maxLength={MAX_BUYER_LENGTH}
                    />
                    {values.marketplaceType === MARKETPLACE_TYPES.etsy && (
                      <FormikSingleSelect
                        name="paymentMethod"
                        label="Payment method"
                        options={PAYMENT_METHOD_OPTIONS}
                        containerClassName={styles.dropdownWrapper}
                        placeholder="Choose payment method"
                        isClearBtn={false}
                      />
                    )}
                  </div>
                  <div className={styles.warning}>
                    <span>
                      All fields are optional and editable. All inputs and
                      changes may affect data within Bookkeeping and Analytics.
                    </span>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className={styles.footerWrapper}>
                  <span className={styles.bottomWarning}>
                    {`When you mark this item as sold, we will delist it from the remaining marketplaces." which should be changed to "When you mark this item as sold, it will be delisted from the remaining marketplaces.`}
                  </span>
                  <CustomButton
                    type="submit"
                    className="filled-primary"
                    disabled={processing}
                  >
                    Sold
                  </CustomButton>
                </div>
              </ModalFooter>
            </form>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

export default SoldModal;
