export const MAX_TITLE_LENGTH_EBAY = 80;

export const CONDITIONS_STATUSES = {
  new: 1000,
  newOther: 1500,
  newWithDefects: 1750,
  certifiedRefurbished: 2000,
  excellentRefurbished: 2010,
  veryGoodRefurbished: 2020,
  goodRefurbished: 2030,
  sellerRefurbished: 2500,
  likeNew: 2750,
  usedExcellent: 3000,
  usedVeryGood: 4000,
  usedGood: 5000,
  usedAcceptable: 6000,
  forPartsOrNotWorking: 7000,
};

export const CONDITIONS_MAP = {
  [CONDITIONS_STATUSES.new]: "NEW",
  [CONDITIONS_STATUSES.newOther]: "NEW_OTHER",
  [CONDITIONS_STATUSES.newWithDefects]: "NEW_WITH_DEFECTS",
  [CONDITIONS_STATUSES.certifiedRefurbished]: "CERTIFIED_REFURBISHED",
  [CONDITIONS_STATUSES.excellentRefurbished]: "EXCELLENT_REFURBISHED",
  [CONDITIONS_STATUSES.veryGoodRefurbished]: "VERY_GOOD_REFURBISHED",
  [CONDITIONS_STATUSES.goodRefurbished]: "GOOD_REFURBISHED",
  [CONDITIONS_STATUSES.sellerRefurbished]: "SELLER_REFURBISHED",
  [CONDITIONS_STATUSES.likeNew]: "LIKE_NEW",
  [CONDITIONS_STATUSES.usedExcellent]: "USED_EXCELLENT",
  [CONDITIONS_STATUSES.usedVeryGood]: "USED_VERY_GOOD",
  [CONDITIONS_STATUSES.usedGood]: "USED_GOOD",
  [CONDITIONS_STATUSES.usedAcceptable]: "USED_ACCEPTABLE",
  [CONDITIONS_STATUSES.forPartsOrNotWorking]: "FOR_PARTS_OR_NOT_WORKING",
};

export const EBAY_CONDITIONS = {
  newWithTagsBox: "NEW",
  newWithoutTagsBox: "NEW_OTHER",
  newWithDefects: "NEW_WITH_DEFECTS",
  preOwned: "USED_EXCELLENT",
};

export const EBAY_FIELDS_TYPES_FROM_RESPONSE = {
  recommended: "RECOMMENDED",
  optional: "OPTIONAL",
};

export const EBAY_FIELDS_TYPES = {
  required: "required",
  recommended: "recommended",
  additional: "additional",
};

export const EBAY_ADDITIONAL_FIELDS_LIMIT = 1;
