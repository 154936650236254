import { Route, Switch, Redirect } from "react-router-dom";
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { LINKS } from "./config";
import Poshmark from "./pages/Poshmark";
import Mercari from "./pages/Mercari";
import Ebay from "./pages/Ebay";
import Etsy from "./pages/Etsy";

const CategoriesPage = () => {
  return (
    <div className={"hidden-page"}>
      <DndProvider backend={HTML5Backend}>
        <Switch>
          <Route path={LINKS.MERCARI} component={Mercari} exact />
          <Route path={LINKS.POSHMARK} component={Poshmark} exact />
          <Route path={LINKS.ETSY} component={Etsy} exact />
          <Route path={LINKS.EBAY} component={Ebay} exact />
          <Redirect to={LINKS.MERCARI} />
        </Switch>
      </DndProvider>
    </div>
  );
};

export default CategoriesPage;
