import { EMPTY_VALUE } from "../../bookkeeping/components/BookkeepingTables/constants";
import { COUNT_DIGITS_2, PERCENTAGE_NUMBER } from "../constants";

export const changeDataToPercent = (value) => {
  return (value * PERCENTAGE_NUMBER)?.toFixed(COUNT_DIGITS_2) + "%";
};

export const changeDataToPercentDashboard = (value, count) => {
  if (!value) return EMPTY_VALUE;
  return ((value * PERCENTAGE_NUMBER)?.toFixed(count) || "0") + "%";
};
