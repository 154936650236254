import React from "react";
import { Col } from "reactstrap";
import "../Overview/index.scss"


const AnalyticItem = ({ icon, label, value }) => {
  return (
    <div className="d-flex align-items-center item-container pb-3 pt-3">
      <Col className={"item-label"}>
        <div className="d-flex align-items-center">
          {icon}
          <p className="font-size-15 ml-3 mb-0">
            {label}
          </p>
        </div>
      </Col>
      <Col className="d-flex justify-content-end">
        <p className="font-size-19 fw-bold mb-0 item-value">
          {value || "-"}
        </p>
      </Col>
    </div>
  );
};

export default AnalyticItem;