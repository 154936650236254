const LINK_PRIVACY_GROUP = "/privacy";
const LINK_PRIVACY_POLICY = `${LINK_PRIVACY_GROUP}/privacy-policy`;
const LINK_COOKIES = `${LINK_PRIVACY_GROUP}/cookies-policy`;
const LINK_TERMS = `${LINK_PRIVACY_GROUP}/terms-and-conditions`;

export const PRIVACY_GROUP_LINKS = {
  BASE: LINK_PRIVACY_GROUP,
  LINK_PRIVACY_POLICY,
  LINK_COOKIES,
  LINK_TERMS,
};
