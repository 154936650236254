import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import SoldList from "./pages/SoldList";
import { SOLD_GROUP_LINKS } from "./config";

import SoldDetails from "./pages/SoldDetails";

const Sold = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <Switch>
          <Route path={SOLD_GROUP_LINKS.BASE} exact>
            <SoldList />
          </Route>
          <Route path={SOLD_GROUP_LINKS.DETAILS} exact>
            <SoldDetails />
          </Route>
          <Redirect to={SOLD_GROUP_LINKS.BASE} />
        </Switch>
      </Container>
    </div>
  );
};

export default Sold;
