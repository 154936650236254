import { get, set, isEqual } from "lodash";

class Mapper {
  constructor(basic) {
    this.basic = basic;

    this.dependencies = {};
  }

  getDependencyHandlers(key) {
    if (!this.dependencies[key]) {
      this.dependencies[key] = [];
    }

    return this.dependencies[key];
  }

  onBeforeApply(key, handler) {
    this.getDependencyHandlers(key).push(handler);
    return this;
  }

  // have an unused param here because it might be passed on depending on what instance of the class is called
  // eslint-disable-next-line no-unused-vars
  compareChanges(form, basicOldValue, basicNewValue, basicFieldName = null) {
    throw new Error("Not implemented");
  }
}

export class TransformField extends Mapper {
  constructor(basic, custom) {
    super(basic);

    this.formField = custom;
    this.converters = {
      when: () => true,
      fromBasic: { mapper: (val) => val },
      fromMarketplace: { mapper: (val) => val },
    };
  }

  fromBasic(mapper) {
    this.converters.fromBasic = { mapper };
    return this;
  }

  fromMarketplace(mapper) {
    this.converters.fromMarketplace = { mapper };
    return this;
  }

  when(callback) {
    this.converters.when = callback;
    return this;
  }

  // have an unused param here because it might be passed on depending on what instance of the class is called
  // eslint-disable-next-line no-unused-vars
  compareChanges(
    form,
    fromValue,
    toValue,
    basicFieldName = null,
    isBasicForm = false
  ) {
    const changes = [];

    const oldValue = get(JSON.parse(JSON.stringify(form)), this.formField);

    if (!isBasicForm) {
      const basicValue = get(form, basicFieldName);

      if (toValue && toValue !== basicValue) {
        const mergedValue = this.converters.fromMarketplace.mapper(toValue);

        if (basicFieldName) {
          changes.push({
            key: basicFieldName,
            value: mergedValue,
          });
        }
      }
    }

    const convertedOldValue = this.converters.fromMarketplace.mapper(oldValue);
    const convertedBasicValue = this.converters.fromBasic.mapper(fromValue);
    const convertedNewValue = this.converters.fromMarketplace.mapper(toValue);

    const checkAllowMerge = () => {
      let isListed = false;

      if (this.formField.indexOf(".") !== -1) {
        const formName = this.formField.split(".")[0].trim();

        if (!form[formName]?.link || form[formName]?.link === "") {
          return true;
        }

        isListed = true;
      }

      if (!isEqual(convertedOldValue, convertedBasicValue) && !isListed) {
        return true;
      }

      if (oldValue) {
        if (Array.isArray(oldValue) && oldValue.length === 0) {
          return true;
        }

        if (!isListed) {
          return true;
        }
      }

      return false;
    };

    const getMergedValue = () => {
      if (isBasicForm) return convertedNewValue;

      let isListed = false;

      if (this.formField.indexOf(".") !== -1) {
        const formName = this.formField.split(".")[0].trim();

        if (form[formName]?.link && form[formName]?.link !== "") {
          isListed = true;
        }
      }

      if (isListed) return convertedOldValue;

      return convertedBasicValue;
    };

    const allowMerge = checkAllowMerge();

    if (!allowMerge) return [];

    // const mergedValue = isBasicForm ? convertedNewValue : convertedBasicValue;
    const mergedValue = getMergedValue();

    if (!this.converters.when(form, fromValue, toValue, mergedValue)) return [];

    if (mergedValue === undefined) return;

    changes.push({ key: this.formField, value: mergedValue });

    this.getDependencyHandlers(this.formField).forEach((getUpdates) => {
      const change = getUpdates(form, fromValue, toValue, mergedValue);

      changes.push(change);
    });

    return changes;
  }
}

class Associations extends Mapper {
  constructor(basic) {
    super(basic);
    this.map = {};
    this.keys = [];

    this.converters = {
      byDefault: undefined,
    };
  }

  static applyChanges(form, changes) {
    changes.forEach((change) => {
      if (change) {
        const { key, value } = change;
        set(form, key, value);
      }
    });
  }

  addPropertyValues(field, values) {
    this.map[field] = values;
    this.keys.push(field);
    return this;
  }

  setDefaultValue(value) {
    this.converters.byDefault = value;
    return this;
  }

  compareChanges(
    form,
    basicOldValue,
    basicNewValue,
    basicFieldName = null,
    isBasicForm = false
  ) {
    const changes = [];

    this.keys.forEach((key) => {
      const oldValue = get(form, key) || null;
      const basicFieldValue = get(form, basicFieldName);

      const map = this.map[key];

      let basicFieldId = Object.keys(map)?.find((val) => {
        if (isBasicForm && basicFieldValue) {
          return (
            parseInt(val, 10) === basicFieldValue || val === basicFieldValue
          );
        }

        const value = map[parseInt(val, 10)];

        if (typeof value === "object") {
          return value.some((data) => data === basicNewValue);
        }

        return value === basicNewValue;
      });

      if (isBasicForm) {
        basicFieldId = map[parseInt(basicFieldId, 10)];
      }

      if (basicFieldId && typeof basicFieldId === "object") {
        basicFieldId = basicFieldId[0];
      }

      const allowMerge = !oldValue || oldValue !== basicFieldId;

      if (
        !allowMerge ||
        (!basicFieldId &&
          key !== "productPoshmarkSpecificFields.isTagsAttached")
      )
        return;

      if (!basicFieldId) return;

      changes.push({
        key: isBasicForm ? key : basicFieldName,
        value: basicFieldId,
      });

      this.getDependencyHandlers(key).forEach((getUpdates) => {
        const change = getUpdates(
          form,
          basicOldValue,
          basicNewValue,
          basicFieldId
        );

        changes.push(change);
      });
    });

    return changes;
  }
}

export default Associations;
