import React, { useCallback } from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import Bugsnag from "@bugsnag/js";

import FormikInput from "../../../../../../../../base/components/FormikInput";
import CustomButton from "../../../../../../../../base/components/CustomButton";

import { useService } from "../../../../../../../../base/hooks/useService";
import { useLoading } from "../../../../../../../../base/hooks/useLoading";
import EmployeesService from "../../../../../../../../services/EmployeesService";

import { initialValues, validationSchema } from "./form";
import styles from "./index.module.scss";
import {
  MAX_FIRSTNAME_LENGTH,
  MIN_FIRSTNAME_LENGTH,
} from "../../../../../../../../validation/lengthConstants";

const CreateEmployee = ({
  onCloseModal,
  onOpenErrorModal,
  onOpenConfirmModal,
  onConfirmInvite,
}) => {
  const [loading, { registerPromise }] = useLoading();

  /**
   * @type {EmployeesService}
   */
  const employeesService = useService(EmployeesService);

  const handleSendInvite = useCallback(
    async (values) => {
      try {
        registerPromise(employeesService.sendInvite(values))
          .then(() => {
            onConfirmInvite();
            onOpenConfirmModal();
          })
          .catch((e) => {
            Bugsnag.notify(e);
            onOpenErrorModal();
          });
      } catch (e) {
        Bugsnag.notify(e);
      } finally {
        onCloseModal();
      }
    },
    [
      onConfirmInvite,
      registerPromise,
      employeesService,
      onOpenErrorModal,
      onOpenConfirmModal,
      onCloseModal,
    ]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSendInvite}
    >
      {({ handleSubmit, errors, values }) => (
        <form className="form-horizontal p-2" onSubmit={handleSubmit}>
          <FormikInput
            id="firstName"
            name="firstName"
            label="First name*"
            containerClassName="mb-3"
            placeholder="Enter first name"
            type="text"
            min={MIN_FIRSTNAME_LENGTH}
            max={MAX_FIRSTNAME_LENGTH}
          />
          <FormikInput
            id="lastName"
            name="lastName"
            label="Last name*"
            containerClassName="mb-3"
            placeholder="Enter last name"
            type="text"
            min={MIN_FIRSTNAME_LENGTH}
            max={MAX_FIRSTNAME_LENGTH}
          />
          <FormikInput
            id="email"
            name="email"
            label="Email*"
            containerClassName="mb-3"
            placeholder="Enter email"
            type="email"
          />
          <div className={styles.buttonsWrapper}>
            <CustomButton className="outline-primary" onClick={onCloseModal}>
              Close
            </CustomButton>
            <CustomButton
              type="submit"
              className="filled-primary"
              disabled={
                loading ||
                !!Object.keys(errors).length ||
                !values?.firstName ||
                !values?.lastName ||
                !values?.email
              }
            >
              Send invite
            </CustomButton>
          </div>
        </form>
      )}
    </Formik>
  );
};

CreateEmployee.propTypes = {
  onCloseModal: PropTypes.func,
  onOpenErrorModal: PropTypes.func,
  onOpenConfirmModal: PropTypes.func,
  onConfirmInvite: PropTypes.func,
};

export default CreateEmployee;
