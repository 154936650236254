import React from 'react'
import { useHistory } from 'react-router-dom';
import { ReactComponent as SettingLogo } from '../../../assets/images/navigation/icon-setting.svg';
import { SETTINGS_LINKS } from '../../../pages/drafts/pages/Settings/config';

const ProfileSetting = () => {
  const { push } = useHistory();

  return (
    <SettingLogo onClick={() => push(SETTINGS_LINKS.MARKETPLACE)}/>
  )
};

export default ProfileSetting;