export const CARD_NUMBER = "cardNumber";
export const CARD_EXPIRY = "cardExpiry";
export const CARD_CVC = "cardCvc";

export const REQUIRED_CARD_FIELDS = [CARD_NUMBER, CARD_EXPIRY, CARD_CVC];

export const VALIDATION_FIELDS = {
  [CARD_NUMBER]: {
    invalid_number: "Card number is invalid",
  },
  [CARD_EXPIRY]: {
    invalid_expiry_year_past: "Expiry date is invalid",
  },
};
