import React from "react";
import { FormatPrice, FormatAvgDaysTillSold, FormatTitle } from "./helpers/format";
import styles from "./index.module.scss"

export function TableStructureCount() {
  return [
    {
      text: "Title",
      sort: false,
      dataField: "name",
      formatter: FormatTitle,
      style: {
        width: "30%",
      }
    },
    {
      sort: false,
      text: "Sold",
      style: {
        width: "10%",
      },
      dataField: "count",
      formatter: (count) => {
        return <div className={styles.tableText}>{count}</div>
      }
    },
    {
      text: "Available",
      dataField: "available",
      style: {
        width: "20%",
      },
      sort: false,
      formatter: (available) => {
        return <div className={styles.tableText}>{available}</div>
      }
    },
    {
      dataField: "avgProfit",
      sort: false,
      text: "Avg.Profit",
      style: {
        width: "20%",
      },
      formatter: FormatPrice,
    },
    {
      dataField: "avgDaysTillSold",
      text: "Avg. Days till sold",
      style: {
        width: "20%",
      },
      sort: false,
      formatter: FormatAvgDaysTillSold
    },
  ];
}

export function TableStructurePrice() {
  return [
    {
      text: "Title",
      sort: false,
      dataField: "name",
      style: {
        width: "30%",
      },
      formatter: FormatTitle
    },
    {
      sort: false,
      text: "Sold",
      dataField: "priceSoldSum",
      style: {
        width: "10%",
      },
      formatter: FormatPrice,
    },
    {
      text: "Available",
      dataField: "available",
      style: {
        width: "20%",
      },
      sort: false,
      formatter: (available) => {
       return <div className={styles.tableText}>{available}</div>
      }
    },
    {
      dataField: "avgProfit",
      sort: false,
      style: {
        width: "20%",
      },
      text: "Avg.Profit",
      formatter: FormatPrice,
    },
    {
      dataField: "avgDaysTillSold",
      text: "Avg. Days till sold",
      style: {
        width: "20%",
      },
      sort: false,
      formatter: FormatAvgDaysTillSold
    },
  ];
}