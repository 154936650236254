import React, {useRef, useState} from "react";
import "./index.scss";
import DropdownIcon from "../DropdownIcon/DropdownIcon";
import {Collapse} from "reactstrap";
import {useClickOutside} from "../../../../../hooks/Poshmark/useClickOutside";

const SizeSystem = ({sizeSystems, currentSystem, updateCurrentSystem}) => {
    const [isOpen, updateIsOpen] = useState(false)

    const outsideSystemRef = useRef();

    const handleToggleSystem = () =>
        updateIsOpen((prevState) => !prevState);

    const changeSystem = (index) => {
        updateCurrentSystem(sizeSystems[index]);
        updateIsOpen(false)
    }

    useClickOutside({refName: outsideSystemRef, isOpen: isOpen, updateIsOpen: updateIsOpen})

    return (
        <div className="system-block" ref={outsideSystemRef}>
            <div className="d-flex align-items-center justify-content-around mt-1 ml-2" onClick={handleToggleSystem}>
                <div className="cursor-pointer" >
                    <img src={currentSystem?.image_url_small} alt={"system"}/>
                </div>
                <DropdownIcon />
            </div>
            <Collapse isOpen={isOpen}>
                <div className="menu-system">
                    {sizeSystems.map((system, index) => {
                        return (
                            <div key={system.id} className="d-flex align-items-center mb-3 cursor-pointer" onClick={() => changeSystem(index)}>
                                <img src={system.image_url_large} alt={"system-flag"} className="system-flag"/>
                                <p className="mb-0 font-size-13 fw-medium">{system.display_name}{" "}Sizing</p>
                            </div>
                        )
                    })
                    }
                </div>
            </Collapse>
        </div>
    )
}

export default SizeSystem;