import React from "react";
import { Alert } from "reactstrap";
import PropTypes from "prop-types";

import { ReactComponent as GoogleLogoWhite } from "../../../../assets/images/settings/icon-google(1).svg";
import { phrases } from "../../../../store/phrases";

const DEFAULT_PLACEHOLDER = phrases.poshmarkNotConnectedDrafts;

const ConnectPlaceholder = ({ children = DEFAULT_PLACEHOLDER }) => {
  return (
    <>
      <div className="d-flex align-items-center marketplace-body-text mb-4">
        <GoogleLogoWhite className="mr-2" />
        <p className="text-gray-gomi mb-0">
          Connect your account to get started.
        </p>
      </div>
      <div className="mb-4">
        <Alert color="warning" role="alert">
          <div className="d-flex">
            <div>
              <i className="mdi mdi-alert-outline me-2 font-size-16" />
            </div>
            <div>{children}</div>
          </div>
        </Alert>
      </div>
    </>
  );
};

ConnectPlaceholder.propTypes = {
  children: PropTypes.string,
};

export default ConnectPlaceholder;
