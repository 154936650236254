import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { useDebounce } from "../../../../base/hooks/useDebounce";
import "../../../drafts/components/Search/index.scss";
import {
  stringifyParams,
  useLocationQuery,
} from "../../../../base/hooks/useQueryString";

import { ReactComponent as DeleteIcon } from "../../../../base/components/Select/DropdownSelect/grey.svg";
import { useHistory } from "react-router-dom";

export const SearchInput = ({
  value: search,
  onChange,
  placeholder,
  autoFocus,
  autoComplete,
  className,
  isDeleteButton = false,
  isDisabled = false,
}) => {
  return (
    <>
      <div
        className={classnames(
          "search-box me-4  d-inline-block table-search",
          className
        )}
      >
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <input
              autoComplete={autoComplete}
              autoFocus={autoFocus}
              id="search-bar-0"
              type="text"
              aria-labelledby="search-bar-0-label"
              className="form-control "
              placeholder={""}
              value={search}
              onChange={onChange}
              disabled={isDisabled}
            />
            {!search && (
              <div className={"search-placeholder"}>{placeholder}</div>
            )}
          </label>
          <i className="bx bx-search-alt search-icon" />
          {isDeleteButton && search && (
            <DeleteIcon
              className="cursor-pointer delete-icon"
              onClick={() =>
                onChange({
                  target: { value: "" },
                  currentTarget: { value: "" },
                })
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

const ExpenseSearch = ({ isActiveSearch }) => {
  const {
    params: { query: q },
  } = useLocationQuery();
  const { params } = useLocationQuery();
  const [search, onChange] = useState(q || "");
  const query = useDebounce(search, 300);
  const history = useHistory();

  useEffect(() => {
    const newParams = {
      ...params,
      query: query.trim(),
      offset: 0,
    };

    if (query === "") {
      newParams.query = undefined;
    }

    history.push({
      search: stringifyParams(newParams),
    });
  }, [query, params]);

  return (
    <SearchInput
      placeholder={
        <>
          Search by <span className={"bold"}> Title </span>
        </>
      }
      isDisabled={isActiveSearch}
      value={search}
      onChange={({ target: { value } }) => {
        onChange(value);
      }}
    />
  );
};

export default ExpenseSearch;
