import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

const ShopNameComponent = ({ title, subtitle }) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>{title}</span>
      <span className={styles.subtitle}>{subtitle}</span>
    </div>
  );
};

ShopNameComponent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default ShopNameComponent;
