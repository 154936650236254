import { useCallback, useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useLoading } from "../../../base/hooks/useLoading";
import { useService } from "../../../base/hooks/useService";
import ExpensesService from "../../../services/ExpensesService";

export const getCategoryIdFromLabel = (label, categoriesList) => {
  const selectedCategory = categoriesList.find(
    (category) => category.name === label
  );
  if (!selectedCategory) return null;

  return selectedCategory.id;
};

export const useExpensesCategories = () => {
  const [categoriesList, setCategoriesList] = useState([]);
  const [loading, { registerPromise }] = useLoading();

  /**
   * @type {ExpensesService}
   */
  const expensesService = useService(ExpensesService);

  const fetchCategories = useCallback(
    (setData) => {
      registerPromise(expensesService.getExpensesCategories())
        .then(({ data }) => {
          setData(data);
        })
        .catch((e) => Bugsnag.notify(e));
    },
    [registerPromise, expensesService]
  );

  useEffect(() => {
    fetchCategories(setCategoriesList);
  }, []);

  return [categoriesList, { fetchCategories, getCategoryIdFromLabel }, loading];
};
