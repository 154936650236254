import React, { useCallback } from "react";
import PropTypes from "prop-types";

import CustomButton from "../../../../CustomButton";
import { CustomModal, ModalBody } from "../../../../CustomModal";

const SubscriberModal = ({ subscriberModal, onClose, onError }) => {
  const { isOpen, title, text, Logo, isError } = subscriberModal;

  const handleClose = useCallback(() => {
    onClose();
    if (isError) onError();
  }, [onClose, isError, onError]);

  return (
    <CustomModal isOpen={isOpen} onClose={handleClose}>
      <ModalBody className="pt-4 pb-4">
        <div className="d-flex justify-content-center mb-4">
          <Logo />
        </div>
        <h3 className="text-center mb-3">{title}</h3>
        <div className="text-center mb-5 ml-4 mr-4">{text}</div>
        <div className="d-flex justify-content-center">
          <CustomButton className="filled-primary" onClick={handleClose}>
            Close
          </CustomButton>
        </div>
      </ModalBody>
    </CustomModal>
  );
};

SubscriberModal.propTypes = {
  subscriberModal: PropTypes.object,
  onClose: PropTypes.func,
  onError: PropTypes.func,
};

export default SubscriberModal;
