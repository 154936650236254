import { DATA_MAX_LENGTH } from "../constants/analyticsAvailableInventory";
import {
  DATE_FORMAT_ISO_8601,
  FRACTIONS_ARRAY_DEFAULT_VALUE,
  FRACTIONS_ARRAY_LENGTH,
} from "../constants/analisticsSalesPerPlatform";
import moment from "moment";
import { MONTH_TYPE } from "../constants";


class Utils {
  static formatPrice (price) {
    price = Math.round(price)
    return Number(price).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    })
  }

  static completeData(data) {
    const itemPlaceholder = {
      name: "-",
      count: "-",
      priceSoldSum: 0,
      avgProfit: 0,
      avgDaysTillSold: 0,
      available: "-",
    }

    const numberOfAdditionalItems = DATA_MAX_LENGTH - data.length
    const additionalItemsArray = Array(numberOfAdditionalItems).fill(itemPlaceholder)
    data = data.concat(additionalItemsArray)
    return data
  }

  static getStartDate = () => {
    return moment().startOf(MONTH_TYPE).format(DATE_FORMAT_ISO_8601);
  }

    static getEndDate = () => {
    return moment().endOf(MONTH_TYPE).format(DATE_FORMAT_ISO_8601);
  }

  static concatDataArrays = (template, data) => {
    const newTemplate = [...template]
    data.map((item) => {
      const index = template.findIndex(i => i.marketplaceType === item.marketplaceType)
      newTemplate.splice(index, 1, item)
    })
    return newTemplate

  }

  static setFractionsArray = (data, total) => {
    let array = []
      data.forEach((item) => {
        const percent = Math.round(item.priceSoldSum/total*100)
        array.push(percent)
      })
    return total ? array : new Array(FRACTIONS_ARRAY_LENGTH).fill(FRACTIONS_ARRAY_DEFAULT_VALUE)
  }

  static setTotalSales = (data) => {
    return data.reduce((acc, currentValue) => {
          return acc + currentValue.priceSoldSum;
        }, 0)
  }
}


export default Utils