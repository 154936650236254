import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { isLastOfArray } from "../../../../base/helpers/isLastOfArray";


const InventoryDropdown = ({
                             defaultSelected,
                             onChange = () => {
                             },
                             list = [],
                             classes,
                             right = true,
                             classesContainer,
                           }) => {
  const [isOpen, updateIsOpen] = useState(false);
  const [selected, updateSelected] = useState(defaultSelected);

  useEffect(() => {
    onChange && onChange(selected.value);
  }, [onChange, selected]);

  const toggle = useCallback(() => {
    updateIsOpen(isOpen => !isOpen);
  }, []);

  const handleSelect = (selectedItem) => {
    updateSelected(selectedItem);
  };

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle className={classes ? classes : "custom-button-dropdown"}>
        {selected.label}
        <i className='mdi mdi-chevron-down'></i>
      </DropdownToggle>
      <DropdownMenu right={right} className={classesContainer ? classesContainer : "dropdown-menu"}>
        {list.map((item, index, arr) => (
          <React.Fragment key={index}>
            <DropdownItem
              value={item.value}
              onClick={() => handleSelect(item)}
              active={item.value === selected.value}
            >
              {item.label}
            </DropdownItem>
            {!isLastOfArray(arr, index) && <DropdownItem divider/>}
          </React.Fragment>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

InventoryDropdown.propTypes = {
  defaultSelected: PropTypes.object,
  onChange: PropTypes.func,
  list: PropTypes.array,
};

export default InventoryDropdown;