import React from 'react'
import PropTypes from "prop-types";
import classnames from 'classnames';

import CustomButton from '../../../CustomButton';
import { ReactComponent as Line } from '../../../../../assets/images/fill-75.svg';
import { ACTIVE_CANCELED_STATUS } from '../../constant/subscription';

const SubscriptionOption = ({ 
  notation,
  title,
  price,
  options,
  isAvailable,
  onChooseType,
  isChosen,
  isActivatedType,
  onOpenModal,
  statusSubscription
}) => {
  return (
    <div 
      onClick={() => isAvailable ? onChooseType() : void 0}
      className={classnames(
        'subscription-card-container', 
        { 'subscription-card-container_disabled': !isAvailable },
        { 'subscription-card-container_chosen': isChosen }
      )}
    >
      <p className='regular-orange subscription-card-container__notification'>{notation || <>&nbsp;</>}</p>
      <h3 className='bold-green subscription-card-container__title'>
        {title}
      </h3>
      <p className='regular-black subscription-card-container__subtitle'>
        Membership
      </p>
      <div className='subscription-price-container'>        
        <h3 className='subscription-price-container__price'>
          <span className='subscription-price-container__dollar'>$</span>
          {price}
          <span className='subscription-price-container__date'>/ month</span>
        </h3>
      </div>
      <Line className='subscription-card-container__line'/>
      <ul>
        {options.map((option, index) => (
          <li key={index} className='md-black'>{option}</li>
        ))}
      </ul>
      {(isActivatedType && statusSubscription !== ACTIVE_CANCELED_STATUS) && (
        <div className='d-flex justify-content-center mt-4'>
          <CustomButton 
            className='outline-primary'
            onClick={onOpenModal}
          >
            Cancel Subscription
          </CustomButton>
        </div>
      )}      
    </div>
  )
};

SubscriptionOption.propTypes = {
  notation: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.string,
  options: PropTypes.array,
  isAvailable: PropTypes.bool,
  onChooseType: PropTypes.func,
  isActivatedType: PropTypes.bool,
  isChosen: PropTypes.bool,
  onOpenModal: PropTypes.func,
  statusSubscription: PropTypes.number
};

export default SubscriptionOption;