import React, { useMemo } from "react";
import styles from "./index.module.scss";
import { CHART_LABELS, COLORS, MARKETPLACES } from "./params";
import { ReactComponent as Oval } from "./Oval.svg";
import { useChart } from "./useChart";

const ChartBlock = ({ data, labels, className }) => {
  const { ref } = useChart(data, { labels });
  return (
    <canvas ref={ref} className={className} />
  );
};

const ChartWrap = ({ children, inner = null }) => {
  return (
    <div className={styles.chartWrap}>
      {children}
      <p className={styles.chartInner}>{inner}</p>
    </div>
  );
};

export const DounatChartBase = ({ children, data, customLabels, customChartData }) => {
  const chartData = useMemo(() => ({
    labels: MARKETPLACES,
    datasets: [
      {
        backgroundColor: COLORS,
        data,
      },
    ],
  }), [data]);

  const labels = useMemo(() => ({
    percents: CHART_LABELS.HIDDEN,
  }), []);

  return (
    <ChartWrap inner={children}>
      <ChartBlock data={customChartData || chartData} labels={customLabels || labels}/>
    </ChartWrap>
  );
};

export const DounatChartWithLabels = ({ children, data, classNameChart, colors=COLORS}) => {
  const chartData = useMemo(() => ({
    labels: MARKETPLACES,
    datasets: [
      {
        backgroundColor: colors,
        data,
      },
    ],
  }), [data]);

  const labels = useMemo(() => ({
    percents: CHART_LABELS.PERCENTS,
  }), []);

  return (
    <ChartWrap inner={children}>
      <ChartBlock data={chartData} labels={labels} className={classNameChart}/>
    </ChartWrap>
  );
};


export const Progress = ({ current, total, children, dashboard }) => {
  const HALF_CIRCLE_DEGREES = 180;
  current = current > total ? 100 : current;
  const angle = HALF_CIRCLE_DEGREES - (current * HALF_CIRCLE_DEGREES / total);
  const style = useMemo(() => ({ transform: `rotate(-${angle}deg)` }), [angle]);

  return (
    <div className={dashboard ? styles.ovalDashboard : styles.ovalAnalytics }>
      <Oval className={styles.ovalBg}/>
      <div style={style} className={styles.ovalProgress}>
        <Oval/>
      </div>
      <div  className={styles.ovalProgressAbove}>
      </div>
      <div className={styles.inner}>
        {children}
      </div>
    </div>
  );
};


// TODO - need to remove example
const exampleData = [59.7, 79, 8, 8, 14];
const AllCharts = () => {
  return (
    <>
      <Progress total={100} current={50}>
        Content
      </Progress>

      <DounatChartBase data={exampleData}>
        Content
      </DounatChartBase>
      <DounatChartWithLabels data={exampleData}>
        Content
      </DounatChartWithLabels>
    </>
  );
};

export default AllCharts;
