import {Col, Row} from "reactstrap";
import React from "react";
import Divider from "../Divider";

const TitleForBlock = ({title, description, classNameWrapper = ""}) => {
    return (
        <>
            <Row className={classNameWrapper}>
                <Col>
                    <h3 className='bold-black mb-1'>
                        {title}
                    </h3>
                    {description &&
                    <p className="text-gray-gomi mb-0">
                        {description}
                    </p>}
                </Col>
            </Row>
            <Divider />
        </>

    )
}

export default TitleForBlock;