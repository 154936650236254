import Http from "./Http";

class TaskManagement extends Http {
  static $displayName = "TaskManagement";

  getTasks(params) {
    return this.get("/tasks", { params });
  }

  createTask(model) {
    return this.post("/tasks", model);
  }

  editTask(taskId, model) {
    return this.put(`/tasks/${taskId}`, model);
  }

  deleteTask(taskId) {
    return this.delete(`/tasks/${taskId}`);
  }

  getComments(taskId) {
    return this.get(`/tasks/${taskId}/comments`);
  }

  createComment(taskId, { message }) {
    return this.post(`/tasks/${taskId}/comments`, { message });
  }

  updateComment(commentId, model) {
    return this.put(`/comments/${commentId}`, model);
  }

  deleteComment(commentId) {
    return this.delete(`/comments/${commentId}`);
  }
}

export default TaskManagement;
