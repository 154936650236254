import * as yup from "yup";
import {
  DRAFT_BRAND,
  DRAFT_COST,
  DRAFT_DESCRIPTION,
  DRAFT_DIMENSION,
  DRAFT_LB,
  DRAFT_NOTES,
  DRAFT_OZ,
  DRAFT_PRICE,
  DRAFT_SKU,
  DRAFT_TAGS,
  DRAFT_TITLE,
  DRAFT_ZIP,
} from "../../../../validation/draft";
import { tagsValidation } from "../../helpers/tagsValidation";
import { EBAY_MAX_DRAFT_IMAGES_COUNT } from "../../components/DraftImages/constants";

const maxCountTags = 16;

export const validationSchema = yup.object().shape({
  title: DRAFT_TITLE,
  description: DRAFT_DESCRIPTION,
  brand: DRAFT_BRAND,
  zip: DRAFT_ZIP,
  sku: DRAFT_SKU,
  tags: tagsValidation(DRAFT_TAGS, maxCountTags),
  notes: DRAFT_NOTES,
  weightLb: DRAFT_LB,
  weightOz: DRAFT_OZ,
  height: DRAFT_DIMENSION,
  width: DRAFT_DIMENSION,
  depth: DRAFT_DIMENSION,
  listingPrice: DRAFT_PRICE.nullable(),
  itemCost: DRAFT_COST.nullable(),
});
export const FILES_VALIDATION = yup
  .array()
  .of(yup.object().shape({}))
  .test({
    message: "No selected photos",
    test: (arr) =>
      arr.length !== 0 && arr.length <= EBAY_MAX_DRAFT_IMAGES_COUNT,
  });
export const initialValues = {
  files: [],
  title: "",
  description: "",
  brand: "",
  itemCondition: null,
  primaryColor: null,
  secondaryColor: null,
  categoryId: null,
  category: null,
  sku: "",
  zip: "",
  tags: "",
  notes: "",
  weightLb: "",
  weightOz: "",
  height: "",
  width: "",
  depth: "",
  listingPrice: "",
  itemCost: null,
  createdAt: "",
  updatedAt: "",
};

export const fieldsToMerge = [
  // base, nested custom form key
  { base: "files", custom: null }, // spec. field only for base model
  { base: "title", custom: null },
  { base: "description", custom: null },
  { base: "brand", custom: null },
  { base: "itemCondition", custom: null },
  { base: "primaryColor", custom: null },
  { base: "secondaryColor", custom: null },
  { base: "tags", custom: null },
  { base: "sku", custom: null },
  { base: "zip", custom: null },
  { base: "notes", custom: null },
  { base: "listingPrice", custom: null },
  { base: "itemCost", custom: null, allowZero: true, toForm: (val) => val },
  { base: "weightLb", custom: null },
  { base: "weightOz", custom: null },
  { base: "height", custom: null },
  { base: "width", custom: null },
  { base: "depth", custom: null },
  { base: "categoryId", custom: null },
  { base: "category", custom: null },
  { base: "categoryName", custom: null, excludeSaving: true },
  { base: "categoryShortName", custom: null, excludeSaving: true },
  { base: "createdAt", custom: null, excludeSaving: true },
  { base: "updatedAt", custom: null, excludeSaving: true },
];
