export const DRAFT = "draft";

export const BASIC_CONDITIONS = {
  newWithTagsBox: 1,
  newWithoutTagsBox: 2,
  newWithDefects: 3,
  preOwned: 4,
  poor: 5
};

export const conditions = [
  { label: "New with Tags/Box", id: BASIC_CONDITIONS.newWithTagsBox },
  { label: "New without Tags/Box", id: BASIC_CONDITIONS.newWithoutTagsBox },
  { label: "New with defects", id: BASIC_CONDITIONS.newWithDefects },
  { label: "Pre-owned", id: BASIC_CONDITIONS.preOwned },
  { label: "Poor", id: BASIC_CONDITIONS.poor },
];

export const BASIC_COLORS = {
  BLACK: 1,
  BLUE: 2,
  BROWN: 3,
  CREAM: 4,
  GOLD: 5,
  GREY: 6,
  ORANGE: 7,
  MULTI: 8,
  PINK: 9,
  PURPLE: 10,
  RED: 11,
  SILVER: 12,
  YELLOW: 13,
  TAN: 14,
  WHITE: 15,
  GREEN: 16,
  BEIGE: 17,
  BRONZE: 18,
  CLEAR: 19,
  COPPER: 20,
  RAINBOW: 21,
  ROSE_GOLD: 22,
};


export const BASIC_COLOR_NAMES = {
  BLACK: "Black",
  BLUE: "Blue",
  BROWN: "Brown",
  CREAM: "Cream",
  GOLD: "Gold",
  GREY: "Grey",
  ORANGE: "Orange",
  MULTI: "Multicolor",
  PINK: "Pink",
  PURPLE: "Purple",
  RED: "Red",
  SILVER: "Silver",
  YELLOW: "Yellow",
  TAN: "Tan",
  WHITE: "White",
  GREEN: "Green",
  BEIGE: "Beige",
  BRONZE: "Bronze",
  CLEAR: "Clear",
  COPPER: "Copper",
  RAINBOW: "Rainbow",
  ROSE_GOLD: "Rose Gold",
};

export const productColors = [
  {
    label: BASIC_COLOR_NAMES.BLACK,
    id: BASIC_COLORS.BLACK,
  },
  {
    label: BASIC_COLOR_NAMES.BLUE,
    id: BASIC_COLORS.BLUE,
  },
  {
    label: BASIC_COLOR_NAMES.BROWN,
    id: BASIC_COLORS.BROWN,
  },
  {
    label: BASIC_COLOR_NAMES.BEIGE,
    id: BASIC_COLORS.BEIGE,
  },
  {
    label: BASIC_COLOR_NAMES.BRONZE,
    id: BASIC_COLORS.BRONZE,
  },
  {
    label: BASIC_COLOR_NAMES.COPPER,
    id: BASIC_COLORS.COPPER,
  },
  {
    label: BASIC_COLOR_NAMES.CLEAR,
    id: BASIC_COLORS.CLEAR,
  },
  {
    label: BASIC_COLOR_NAMES.CREAM,
    id: BASIC_COLORS.CREAM,
  },
  {
    label: BASIC_COLOR_NAMES.GOLD,
    id: BASIC_COLORS.GOLD,
  },
  {
    label: BASIC_COLOR_NAMES.GREY,
    id: BASIC_COLORS.GREY,
  },
  {
    label: BASIC_COLOR_NAMES.GREEN,
    id: BASIC_COLORS.GREEN,
  },
  {
    label: BASIC_COLOR_NAMES.ORANGE,
    id: BASIC_COLORS.ORANGE,
  },
  {
    label: BASIC_COLOR_NAMES.MULTI,
    id: BASIC_COLORS.MULTI,
  },
  {
    label: BASIC_COLOR_NAMES.PINK,
    id: BASIC_COLORS.PINK,
  },
  {
    label: BASIC_COLOR_NAMES.PURPLE,
    id: BASIC_COLORS.PURPLE,
  },
  {
    label: BASIC_COLOR_NAMES.RAINBOW,
    id: BASIC_COLORS.RAINBOW,
  },
  {
    label: BASIC_COLOR_NAMES.ROSE_GOLD,
    id: BASIC_COLORS.ROSE_GOLD,
  },
  {
    label: BASIC_COLOR_NAMES.RED,
    id: BASIC_COLORS.RED,
  },
  {
    label: BASIC_COLOR_NAMES.SILVER,
    id: BASIC_COLORS.SILVER,
  },
  {
    label: BASIC_COLOR_NAMES.YELLOW,
    id: BASIC_COLORS.YELLOW,
  },
  {
    label: BASIC_COLOR_NAMES.TAN,
    id: BASIC_COLORS.TAN,
  },
  {
    label: BASIC_COLOR_NAMES.WHITE,
    id: BASIC_COLORS.WHITE,
  },
];

export const COLORS_NAMES = {};
// Fill object
productColors.forEach(({ label, id }) => {
  COLORS_NAMES[id] = label;
});

export const SHIPPING_AREA_TYPES = {
  domestic: 1,
  international: 2,
};

export const SHIPPING_TYPE_TYPES = {
  flat: 1,
  calculated: 2,
};

export const shippingArea = [
  {
    label: "Domestic",
    id: SHIPPING_AREA_TYPES.domestic,
  },
  // {
  //   label: "International",
  //   id: SHIPPING_AREA_TYPES.international,
  // },
];

export const shippingType = [
  {
    label: "Flat",
    id: SHIPPING_TYPE_TYPES.flat,
  },
  {
    label: "Calculate",
    id: SHIPPING_TYPE_TYPES.calculated,
  },
];

export const FLAT_SHIPPING_TYPE = 1;
export const CALCULATED_SHIPPING_TYPE = 2;
export const PREPAID_SHIP_LABEL = 1
export const SHIP_OWN_LABEL = 2
export const BUYER_SHIPPING_PAYER = 1
export const SELLER_SHIPPING_PAYER = 2
export const IS_FREE_SHIPPING = true;
export const DOMESTIC_SHIPPING_TEMPLATE_ID = 135748181678;
export const FREE_DOMESTIC_SHIPPING_TEMPLATE_ID = 143347662495;
export const FREE_INTERNATIONAL_SHIPPING_TEMPLATE_ID = 142359068686;

export const pricingFormatStatuses = {
  auctionStyles: 1,
  fixedPrice: 2,
};

export const pricingFormat = [
  {
    label: "Auction style",
    id: 1,
  },
  {
    label: "Fixed price",
    id: 2,
  },
];

export const durationAuction = [
  {
    label: "1 day",
    id: 6,
  },
  {
    label: "3 days",
    id: 1,
  },
  {
    label: "5 days",
    id: 2,
  },
  {
    label: "7 days",
    id: 3,
  },
  {
    label: "10 days",
    id: 4,
  },
];

export const durationFixed = [
  {
    label: "Good ‘Til Canceled",
    id: 5,
  },
];

export const handlingTime = [
  {
    label: "Same business day",
    id: 1,
  },
  {
    label: "1 business day",
    id: 2,
    days: 1,
  },
  {
    label: "2 business day",
    id: 3,
    days: 2,
  },
  {
    label: "3 business day",
    id: 4,
    days: 3,
  },
  {
    label: "4 business day",
    id: 5,
    days: 4,
  },
  {
    label: "5 business day",
    id: 6,
    days: 5,
  },
  {
    label: "10 business day",
    id: 7,
    days: 10,
  },
  {
    label: "15 business day",
    id: 8,
    days: 15,
  },
  {
    label: "20 business day",
    id: 9,
    days: 20,
  },
  {
    label: "30 business day",
    id: 10,
    days: 30,
  },
];

export const handlingTimeForInternationalShipping = [
  {
    label: "4 business day",
    id: 5,
    days: 4,
  },
];
