import { useState } from "react";

export const useTooltip = (ref) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return {
    isOpen: tooltipOpen,
    target: ref,
    toggle,
  };
};
