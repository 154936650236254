import React from "react";
import PropTypes from "prop-types";
import { getCurrentDate } from "../../../../helpers/getCurrentDate";
import MarketplaceConnectedPlaceholder from "../../page/MarketplaceConnection/MarketplaceConnectedPlaceholder";

export const CardContent = ({ data }) => {
  if (data?.expirationDate || data?.id) {
    const isNotExpired = data?.expirationDate > getCurrentDate();
    return (isNotExpired || data?.id) && <MarketplaceConnectedPlaceholder />;
  }

  return (
    <p className="regular-grey text-center marketplace-body-text">
      Connect your account to get started.
    </p>
  );
};

CardContent.propTypes = {
  data: PropTypes.any,
};

export default CardContent;
