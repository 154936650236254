import moment from "moment";
import { useCallback, useEffect, useState } from "react";

import { useLocationQuery } from "../../../base/hooks/useQueryString";
import { DEFAULT_OFFSET } from "../../inventory/constants/importInventory";
import {
  END_DATE_FIELD,
  MOMENT_DAY,
  OFFSET_FIELD,
  START_DATE_FIELD,
} from "../constants";

const emptyDateRange = [null, null];

export const useExpenseDateFilter = () => {
  const { merge, remove } = useLocationQuery();

  const [dateRange, setDateRange] = useState(emptyDateRange);

  const deleteDateFiltersFromQuery = useCallback(() => {
    remove(START_DATE_FIELD);
    remove(END_DATE_FIELD);
  }, [remove]);

  const checkIsEmptyRangeDate = (date) => {
    if (!date) return true;
    return !date[0] && !date[1];
  };

  const onChangeDateRange = useCallback(
    (newDate) => {
      const [, endDate] = newDate;

      if (checkIsEmptyRangeDate(newDate)) {
        deleteDateFiltersFromQuery();
      }
      setDateRange(newDate);

      if (!endDate) return;
      merge(OFFSET_FIELD, DEFAULT_OFFSET);
    },
    [setDateRange, deleteDateFiltersFromQuery, merge]
  );

  useEffect(() => {
    if (dateRange[1]) {
      merge(
        START_DATE_FIELD,
        moment(dateRange[0]).startOf(MOMENT_DAY).format()
      );
      merge(END_DATE_FIELD, moment(dateRange[1]).endOf(MOMENT_DAY).format());
    }
  }, [dateRange, merge]);

  return [dateRange, onChangeDateRange];
};
