import {
  initialValues as baseInitial,
  fieldsToMerge as baseFieldsMerge,
  validationSchema as baseValidation,
} from "./forms/base";

import {
  fieldsToMerge as etsyFieldsToMerge,
  initialValues as etsyInitial,
  validationSchema as etsyValidation,
} from "./forms/etsy";

import {
  initialValues as ebayInitial,
  validationSchema as ebayValidation,
  fieldsToMerge as ebayFieldsMerge,
} from "./forms/ebay";

import {
  fieldsToMerge as poshmarkFieldsToMerge,
  initialValues as poshmarkInitial,
  validationSchema as poshmarkValidation,
} from "./forms/poshmark";
import {
  fieldsToMerge as mercaryFieldsToMerge,
  initialValues as mercariInitial,
  validationSchema as mercaryValidation,
} from "./forms/mercari";

import { FORM_TYPES } from "../const/form";
import {
  getProductModel,
  getInitialFormValues,
} from "../helpers/mapFormValues";

class FormPartials {
  static SpecificFields = {
    Ebay: "productEbaySpecificFields",
    Etsy: "productEtsySpecificFields",
    Poshmark: "productPoshmarkSpecificFields",
    Mercari: "productMercariSpecificFields",
  };

  constructor(type) {
    this.type = type;

    this._validations = {
      [FORM_TYPES.BASE]: baseValidation,
      [FORM_TYPES.EBAY]: ebayValidation,
      [FORM_TYPES.ETSY]: etsyValidation,
      [FORM_TYPES.POSHMARK]: poshmarkValidation,
      [FORM_TYPES.MERCARI]: mercaryValidation,
    };

    // to prevent cross-file mutation
    const baseInitialCopy = Object.assign({}, baseInitial);
    const ebayInitialCopy = Object.assign({}, ebayInitial);
    const etsyInitialCopy = Object.assign({}, etsyInitial);
    const poshmarkInitialCopy = Object.assign({}, poshmarkInitial);
    const mercariInitialCopy = Object.assign({}, mercariInitial);

    this._initialValues = {
      [FORM_TYPES.BASE]: baseInitialCopy,
      [FORM_TYPES.EBAY]: ebayInitialCopy,
      [FORM_TYPES.ETSY]: etsyInitialCopy,
      [FORM_TYPES.POSHMARK]: poshmarkInitialCopy,
      [FORM_TYPES.MERCARI]: mercariInitialCopy,
    };

    this._mapFields = {
      [FORM_TYPES.BASE]: baseFieldsMerge,
      [FORM_TYPES.EBAY]: ebayFieldsMerge,
      [FORM_TYPES.ETSY]: etsyFieldsToMerge,
      [FORM_TYPES.POSHMARK]: poshmarkFieldsToMerge,
      [FORM_TYPES.MERCARI]: mercaryFieldsToMerge,
    };

    this._formKeys = {
      [FORM_TYPES.BASE]: "",
      [FORM_TYPES.EBAY]: "productEbaySpecificFields",
      [FORM_TYPES.ETSY]: "productEtsySpecificFields",
      [FORM_TYPES.POSHMARK]: "productPoshmarkSpecificFields",
      [FORM_TYPES.MERCARI]: "productMercariSpecificFields",
    };
  }

  get initialValues() {
    return this._initialValues[this.type];
  }

  get validations() {
    return this._validations[this.type];
  }

  get mapFields() {
    return this._mapFields[this.type];
  }

  get formKey() {
    return this._formKeys[this.type];
  }

  getProductSpecificField(name) {
    return this._formKeys[name];
  }

  mapModelToInitialValues(fullForm) {
    const base = getInitialFormValues(
      fullForm,
      baseInitial,
      baseFieldsMerge,
      ""
    );
    const productEbaySpecificFields = getInitialFormValues(
      fullForm,
      ebayInitial,
      ebayFieldsMerge,
      "productEbaySpecificFields"
    );
    const productEtsySpecificFields = getInitialFormValues(
      fullForm,
      etsyInitial,
      etsyFieldsToMerge,
      "productEtsySpecificFields"
    );
    const productPoshmarkSpecificFields = getInitialFormValues(
      fullForm,
      poshmarkInitial,
      poshmarkFieldsToMerge,
      "productPoshmarkSpecificFields"
    );

    const productMercariSpecificFields = getInitialFormValues(
      fullForm,
      mercariInitial,
      mercaryFieldsToMerge,
      this.getProductSpecificField(FORM_TYPES.MERCARI)
    );
    const model = base;

    const cleanDuplicated = (fields, customForm) => {
      // Clear duplicated data
      fields.forEach(({ base, custom, excludeSaving }) => {
        if (
          base &&
          custom &&
          !excludeSaving &&
          customForm[custom] &&
          model[base] === customForm[custom]
        ) {
          delete customForm[custom];
        }
      });
    };

    cleanDuplicated(ebayFieldsMerge, productEbaySpecificFields);
    cleanDuplicated(etsyFieldsToMerge, productEtsySpecificFields);
    cleanDuplicated(poshmarkFieldsToMerge, productPoshmarkSpecificFields);
    cleanDuplicated(mercaryFieldsToMerge, productMercariSpecificFields);

    const mergedForm = Object.assign({}, base);
    mergedForm.productEbaySpecificFields = Object.assign(
      {},
      productEbaySpecificFields
    );
    mergedForm.productEtsySpecificFields = Object.assign(
      {},
      productEtsySpecificFields
    );
    mergedForm.productMercariSpecificFields = Object.assign(
      {},
      productMercariSpecificFields
    );
    mergedForm.productPoshmarkSpecificFields = Object.assign(
      {},
      productPoshmarkSpecificFields
    );

    return mergedForm;
  }

  reduceFormModel(fullForm) {
    const base = getProductModel(fullForm, baseFieldsMerge, "");
    const productEbaySpecificFields = getProductModel(
      fullForm,
      ebayFieldsMerge,
      "productEbaySpecificFields"
    );
    const productEtsySpecificFields = getProductModel(
      fullForm,
      etsyFieldsToMerge,
      "productEtsySpecificFields"
    );
    const productPoshmarkSpecificFields = getProductModel(
      fullForm,
      poshmarkFieldsToMerge,
      "productPoshmarkSpecificFields"
    );
    const productMercariSpecificFields = getProductModel(
      fullForm,
      mercaryFieldsToMerge,
      "productMercariSpecificFields"
    );

    return {
      ...base,
      ...(Object.values(productEbaySpecificFields).length
        ? { productEbaySpecificFields }
        : null),
      ...(Object.values(productEtsySpecificFields).length
        ? { productEtsySpecificFields }
        : null),
      ...(Object.values(productPoshmarkSpecificFields).length
        ? { productPoshmarkSpecificFields }
        : null),
      ...(Object.values(productMercariSpecificFields).length
        ? { productMercariSpecificFields }
        : null),
    };
  }
}

export default FormPartials;
