
export const mapEtsyProperties = (option) => {
	const {displayName, propertyId, isMultivalued, isRequired, scales, possibleValues, supportsVariations} = option;
  const fieldName = "productAdditionalFields.propertyId_" + propertyId;

	let options;
	let optionsMap = {};

	if (scales?.length) {
		options = scales.map(({displayName: label, scaleId: id}, index) => {
			// save all indexes
			optionsMap[id] = index;

			return {
				label,
				id,
				variants: supportsVariations ? [] : undefined
			}
		})
	}

	// in depends on scales
	if (!scales?.length && possibleValues?.length) {
		options = possibleValues.map(({name: label, valueId: id}) => ({label, id}))
	// depends on scales
	} else if (scales?.length && possibleValues?.length) {
		possibleValues.forEach(({name: label, valueId: id, scaleId}) => {
			const index = optionsMap[scaleId];

			if (typeof index !== "number")return;

			const nestedOptions = options[index].variants ;

			nestedOptions.push({label, id});
		});


	}

	return {
		options,
		isRequired,
		name: fieldName,
		key: propertyId,
		label: displayName,
		isMulti: isMultivalued
	}
};



export const groupFields = ((fields) => {
	let grouped = [];

	for (let i = 0; i < fields.length; i += 2) {
		grouped.push([
			fields[i],
			fields[i + 1]
		]);
	}

	return grouped;
});
