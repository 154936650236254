import filterObjectFalsy from "../../../../base/helpers/filterObjectFalsy";

export const transformSellerPrivateInfo = (data) => {
    data.seller_private_info = {
        cost_price_amount: data.cost_price_amount.val ? data.cost_price_amount : null,
        other_info: data.other_info,
        sku: data.sku
    }

    data.seller_private_info = filterObjectFalsy(data.seller_private_info)

    delete data.cost_price_amount
    delete data.other_info
    delete data.sku

    return data
}