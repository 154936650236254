import React from "react";

import CustomButton from "../../../../../../base/components/CustomButton";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../../../base/components/CustomModal";

const Modal = ({ title, message, isOpen, onClose, onConfirm, processing }) => {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <ModalHeader onClose={onClose}>{title}</ModalHeader>
      <ModalBody>
        <p className="ui-regular mb-0">{message}</p>
      </ModalBody>
      <ModalFooter>
        <CustomButton onClick={onClose} className="outline-primary" disabled={processing}>
          No
        </CustomButton>
        <CustomButton onClick={onConfirm} className="filled-primary" disabled={processing}>
          Yes
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );
};

export default Modal;
