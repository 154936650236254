/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../../../../base/hooks/useService";
import PoshmarkExtension from "../../../../../../services/PoshmarkExtension";
import { Col, Row } from "reactstrap";
import FormikSingleSelect from "../../../../../../base/components/FormikSingleSelect";
import FormPoshmarkSizes from "../FormPoshmarkSizes";
import FormikInput from "../../../../../../base/components/FormikInput";
import { useParams } from "react-router-dom";
import { getCategoryValue } from "../../../../helpers/Poshmark/getCategoryData";
import { useField } from "formik";
import { getCategoryShortName } from "../../../../helpers/getCategoryShortName";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import DotsLoader from "../../../../../../base/components/DotLoader";
import { findElement } from "../../../DraftMercariFormInner/partials/hooks";

const CATEGORY_TYPES = {
  DEPARTMENT: "d",
  CATEGORY: "c",
  CATEGORY_FEATURE: "cf",
  GROUP: "group",
};
const toFlat = (categories, keys) => {
  const [key, ...other] = keys;

  if (!keys.length) return categories;

  return categories
    .map((category) => {
      return [category, toFlat(category[key], other)];
    })
    .flat(3)
    .filter((notEmpty) => notEmpty);
};

const setSuggestedCategoryTree = (categories, suggested, setFieldValue) => {
  const allCategories = toFlat(categories, ["categories", "category_features"]);

  const findCategories = (matched) => {
    const ids = [matched.id];

    if (!matched.parent_id) return ids;

    if (matched?.type === CATEGORY_TYPES.DEPARTMENT) return ids;

    ids.unshift(matched.parent_id);

    if (matched.type === CATEGORY_TYPES.DEPARTMENT) return ids;

    const top = findElement(allCategories, matched.parent_id);
    const topId = top?.parent_id;

    if (top?.type === CATEGORY_TYPES.DEPARTMENT) return ids;

    if (topId) {
      ids.unshift(topId);
    }

    return ids;
  };

  const applySuggestedCategory = (matched) => {
    const ids = findCategories(matched);
    const orderDropdowns = ["department", "category", "category_features"];

    ids.forEach((id, index) => {
      const field = orderDropdowns[index];

      setFieldValue(field, id);
    });
  };

  const bestMatch = allCategories.filter(({ id }) => id === suggested);

  // Auto-select
  if (bestMatch.length) {
    const [matched] = bestMatch;
    applySuggestedCategory(matched);
  }
};

const FormPoshmarkCategories = ({
  label,
  description,
  values,
  setFieldValue,
  setColors,
  isLoading,
  setCategoriesData,
}) => {
  const [categories, setCategories] = useState([]);
  const [sizeSystems, setSizeSystems] = useState([]);
  const [isSizeClicked, setIsSizeClicked] = useState(false);
  const { draftId } = useParams();
  const [loadingCategories, { registerPromise: awaitCategories }] =
    useLoading();

  const handleSetSizeClicked = () => {
    setIsSizeClicked(true);
  };

  const [, , { setValue }] = useField({ name: "categoryName" });
  const [, , { setValue: setCategoryShortName }] = useField({
    name: "categoryShortName",
  });

  const subCategories = useMemo(() => {
    return (
      categories?.find((category) => category.id === values.department)
        ?.categories || []
    );
  }, [values.department, categories]);

  const additionalSubCategoriesList = useMemo(() => {
    return (
      subCategories?.find(
        (subCategory) => subCategory.id === values.category
      ) || []
    );
  }, [values.category, subCategories]);

  const additionalSubCategories = useMemo(() => {
    return additionalSubCategoriesList?.category_features || [];
  }, [additionalSubCategoriesList]);

  const feature = useMemo(() => {
    return additionalSubCategories.find(({ id }) => id === values.categoryId);
  }, [values.categoryId, additionalSubCategories]);

  const sizeSets = useMemo(() => {
    if (feature?.size_sets?.length) {
      return feature.size_sets;
    }

    return additionalSubCategoriesList?.size_sets || [];
  }, [additionalSubCategoriesList, feature]);

  /**
   * @type {PoshmarkExtension}
   */
  const poshmark = useService(PoshmarkExtension);

  const transformListToOption = (list) => {
    return list.map((item) => ({ ...item, label: item.display }));
  };

  const findSameCategoryIdsInGroups = (categoryId, categories) => {
    const categoriesGroups = categories.filter(
      (category) => category.type === CATEGORY_TYPES.GROUP
    );

    if (!categoriesGroups.length) return true;

    let result = true;

    categoriesGroups.forEach((groupCategory) => {
      groupCategory.category_features.forEach((subCategory) => {
        if (subCategory.id === categoryId) {
          result = false;
        }
      });
    });

    return result;
  };

  const getFilteredCategories = (departments) => {
    return departments.map((department) => {
      const categories = department?.categories;

      if (!categories) return department;

      return {
        ...department,
        categories: categories?.filter((category) =>
          findSameCategoryIdsInGroups(category.id, department.categories)
        ),
      };
    });
  };

  const initCategories = useCallback(
    (categories) => {
      setFieldValue("category_features", values.categoryId);
      setSuggestedCategoryTree(categories, values.categoryId, setFieldValue);
    },
    [values.categoryId, setFieldValue]
  );

  useEffect(() => {
    awaitCategories(poshmark.getCategories())
      .then((data) => {
        const filteredDepartments = getFilteredCategories(
          data.catalog.departments
        );

        setSizeSystems(data.size_systems);
        setCategories(filteredDepartments);
        setCategoriesData(filteredDepartments);
        setColors(data.colors);
        initCategories(filteredDepartments);
      })
      .catch((e) => Bugsnag.notify(e));
  }, [poshmark, setSizeSystems, setCategories, setColors, awaitCategories]);

  const resetSubCategories = () => {
    setFieldValue("category", "");
    setFieldValue("category_features", "");
    setFieldValue("sizeId", "");
  };

  const resetAdditionalSubCategories = () => {
    setFieldValue("category_features", "");
    setFieldValue("sizeId", "");
  };

  useEffect(() => {
    if (draftId) {
      setFieldValue("category_features", values.categoryId);
    }
  }, [draftId, values.categoryId, setFieldValue]);

  useEffect(() => {
    setFieldValue(
      "categoryId",
      values.category && additionalSubCategories.length
        ? values.category_features
        : values.category
    );
  }, [
    values.category_features,
    setFieldValue,
    draftId,
    values.category,
    additionalSubCategories,
  ]);

  useEffect(() => {
    /**
     *
     * @type {number}
     * @desc - generated by chrome extension for --- Women/Man > Global & Traditional Wear > ..etc
     */
    const CUSTOM_GENERATED_ID_MAX_LENGTH = 8;
    const isCustomCategory =
      values.category?.length < CUSTOM_GENERATED_ID_MAX_LENGTH;

    const catalogData = {
      category: !isCustomCategory ? values.category : values.category_features,
      category_features:
        values.category_features &&
        values.category !== values.category_features &&
        !isCustomCategory
          ? [values.category_features]
          : [],
      department: values.department,
    };

    setFieldValue("catalog", catalogData);

    const departmentValue = getCategoryValue(categories, values.department);
    const categoryValue = getCategoryValue(subCategories, values.category);
    const categoryFeaturesValue = getCategoryValue(
      additionalSubCategories,
      values.category_features
    );

    if (departmentValue && categoryValue) {
      setValue(
        `${departmentValue} / ${categoryValue} ${
          categoryFeaturesValue ? `/ ${categoryFeaturesValue}` : ""
        }`
      );
    }
  }, [
    values.category,
    values.category_features,
    values.department,
    categories,
    subCategories,
    additionalSubCategories,
    getCategoryValue,
  ]);

  useEffect(() => {
    setCategoryShortName(getCategoryShortName(values.categoryName));
  }, [values.categoryName]);

  useEffect(() => {
    isLoading(loadingCategories);
  }, [loadingCategories]);

  return (
    <>
      <Row className="section-wrapper">
        <Col md={4}>
          <h3 className="bold-black mb-1">
            {label} {loadingCategories && <DotsLoader />}
          </h3>
          <p className="text-gray-gomi">{description}</p>
        </Col>
        <Col md={8}>
          <Row>
            <Col md={6}>
              <FormikSingleSelect
                name="department"
                options={transformListToOption(categories)}
                placeholder="Select category"
                disabled={!categories.length}
                onChange={resetSubCategories}
                label="Category"
                isHideLabel
                isSizeClicked={isSizeClicked}
                sizeSets={sizeSets}
              />
            </Col>
            <Col md={6}>
              {values.department && !!subCategories?.length && (
                <FormikSingleSelect
                  name="category"
                  options={transformListToOption(subCategories)}
                  placeholder="Select sub-category"
                  onChange={resetAdditionalSubCategories}
                  label="Sub-category"
                  isHideLabel
                />
              )}
            </Col>
          </Row>
          <Row className="d-flex justify-content-end">
            <Col md={6}>
              {values.category && !!additionalSubCategories?.length && (
                <FormikSingleSelect
                  name="category_features"
                  options={transformListToOption(additionalSubCategories)}
                  placeholder="Select sub-category"
                  label="Sub-category"
                  isHideLabel
                />
              )}
            </Col>
          </Row>
          <Row className="d-none">
            <FormikInput name="categoryId" />
          </Row>
        </Col>
      </Row>

      <FormPoshmarkSizes
        sizeSystems={sizeSystems}
        sizeSets={sizeSets}
        loading={loadingCategories}
        values={values}
        setFieldValue={setFieldValue}
        onSetSizeClicked={handleSetSizeClicked}
        isSizeClicked={isSizeClicked}
      />
    </>
  );
};

export default FormPoshmarkCategories;
