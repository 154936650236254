import React, { useCallback, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useLoading } from "../../../base/hooks/useLoading";
import { useService } from "../../../base/hooks/useService";
import ExpensesService from "../../../services/ExpensesService";
import { toNumber } from "../../sold/helpers";
import ConfirmCloseModal from "../components/ConfirmCloseModal";
import CreateExpenseModal from "../components/CreateExpenseModal";
import { formFields } from "../components/CreateExpenseModal/form";

const modalStrings = {
  title: "Expense editing",
  closeBtn: "Cancel",
  confirmBtn: "Save",
};

export const useEditExpense = (refresh, categories) => {
  const [selectedId, setSelectedId] = useState();
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [formData, setFormData] = useState();
  const [isConfirmCloseModal, setIsConfirmCloseModal] = useState(false);

  const [_, { registerPromise }] = useLoading();

  const onCloseConfirmModal = useCallback(() => {
    setIsConfirmCloseModal(false);
  }, [setIsConfirmCloseModal]);

  const onConfirmCloseCreateModal = useCallback(() => {
    setIsConfirmCloseModal(false);
    setIsOpenEditModal(false);
  }, [setIsConfirmCloseModal, setIsOpenEditModal]);

  /**
   * @type {ExpensesService}
   */
  const expensesService = useService(ExpensesService);

  const formatFormData = (data) => {
    return {
      [formFields.title]: data?.title,
      [formFields.description]: data?.description,
      [formFields.category]: data?.expenseCategory?.name,
      [formFields.price]: data?.price,
      [formFields.expenseMadeAt]: data?.expenseMadeAt,
      [formFields.imageId]: { id: data?.image?.id, preview: data?.image?.link },
      [formFields.memo]: data?.memo,
    };
  };

  const getExpenseById = useCallback(
    (id) => {
      registerPromise(expensesService.getExpense(id))
        .then(({ data }) => {
          setFormData(formatFormData(data));
        })
        .catch((e) => Bugsnag.notify(e));
    },
    [registerPromise, expensesService, setFormData]
  );

  const onOpenEditModal = useCallback(
    async (id) => {
      setSelectedId(id);

      try {
        await getExpenseById(id);
        setIsOpenEditModal(true);
      } catch (error) {
        Bugsnag.notify(error);
      }
    },
    [setIsOpenEditModal, getExpenseById]
  );

  const onCloseEditModal = useCallback(
    (_, isDirty) => {
      if (isDirty) {
        setIsConfirmCloseModal(true);
        return;
      }
      setIsOpenEditModal(false);
    },
    [setIsOpenEditModal]
  );

  const onConfirmEdit = useCallback(
    (values, fullCategoryData) => {
      const formattedValues = {
        [formFields.title]: values[formFields.title],
        [formFields.description]: values[formFields.description],
        ...(values[formFields.memo] && {
          [formFields.memo]: values[formFields.memo],
        }),
        [formFields.category]: fullCategoryData?.label,
        [formFields.price]: toNumber(values[formFields.price]),
        [formFields.expenseMadeAt]: values[formFields.expenseMadeAt],
        [formFields.imageId]: toNumber(values[formFields.imageId]),
      };

      const filteredValues = Object.entries(formattedValues).filter(
        (field) => !!field[1]
      );

      registerPromise(
        expensesService.updateExpense(
          selectedId,
          Object.fromEntries(filteredValues)
        )
      )
        .then(() => {
          refresh();
        })
        .catch((e) => Bugsnag.notify(e))
        .finally(() => {
          onCloseEditModal();
        });
    },
    [registerPromise, expensesService, selectedId, refresh, onCloseEditModal]
  );

  const editModal = (
    <>
      <CreateExpenseModal
        isOpen={isOpenEditModal}
        onClose={onCloseEditModal}
        afterSubmit={refresh}
        modalStrings={modalStrings}
        onSubmit={onConfirmEdit}
        categories={categories}
        data={formData}
      />
      <ConfirmCloseModal
        isOpen={isConfirmCloseModal}
        onClose={onCloseConfirmModal}
        onConfirm={onConfirmCloseCreateModal}
      />
    </>
  );

  return [onOpenEditModal, editModal];
};
