import React from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";

import Image from "../../../drafts/components/DraftImages/Image";

import styles from "./index.module.scss";

const UploadImage = ({
  containerClassName,
  image,
  onUpload,
  onDelete,
  errorMessage,
  children,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onUpload,
  });

  return (
    <>
      {image ? (
        <Image
          path={image}
          onDelete={onDelete}
          containerClassName={styles.selectedImageWrapper}
        />
      ) : (
        <div className={containerClassName} {...getRootProps()}>
          <input {...getInputProps()} />
          <div className={styles.uploaderWrapper}>
            <i className="bx bxs-cloud-upload cloud" />
            <p
              className={classNames("upload-container__text", {
                "error-message": errorMessage,
              })}
            >
              {errorMessage || children}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

UploadImage.propTypes = {
  containerClassName: PropTypes.string,
  image: PropTypes.any,
  onUpload: PropTypes.func,
  onDelete: PropTypes.func,
  errorMessage: PropTypes.string,
  children: PropTypes.any,
};

export default UploadImage;
