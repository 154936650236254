import React from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";

import styles from "./index.module.scss";

const ShippingTabletColumnRow = ({
  containerClassName,
  defaultData,
  emptyValue = "-",
}) => {
  return (
    <div
      className={ClassNames(containerClassName, {
        [styles.wrapper]: !containerClassName,
      })}
    >
      <span className={styles.rowItemTitle}>{defaultData.label}</span>
      <span className="fw-bold">{defaultData.value || emptyValue}</span>
    </div>
  );
};

ShippingTabletColumnRow.propTypes = {
  containerClassName: PropTypes.string,
  defaultData: PropTypes.object,
  emptyValue: PropTypes.any,
};

export default ShippingTabletColumnRow;
