import Http from "./Http";

class SubscriptionService extends Http {
  static $displayName = "SubscriptionService";

  subscribe(values) {
    return this.post('/subscriptions', values)
  }

  cancelSubscribe(){
    return this.delete('/subscriptions')
  }

  changeSubscription(values){
    return this.put('/subscriptions', values)
  }

  getSubscriptions(){
    return this.get('/subscriptions')
  }
}

export default SubscriptionService;