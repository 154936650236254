import { useMemo } from "react";

import { useInfiniteScroll } from "../../../base/components/InfiniteScrollWrapper/hooks/useInfiniteScroll";
import { useLocationQuery } from "../../../base/hooks/useQueryString";

import { useService } from "../../../base/hooks/useService";

import MileagesService from "../../../services/MileageService";

export const useGetMileageList = () => {
  const {
    params: { query: q, orderBy, orderType },
  } = useLocationQuery();

  /**
   * @type {MileagesService}
   */
  const mileagesService = useService(MileagesService);

  const { data, handleFetch, hasMore, handleRefresh, totalCount, isLoading } =
    useInfiniteScroll({
      fetchData: (params) => mileagesService.getMileageList(params),
      addedParams: useMemo(() => {
        return { q, orderBy, orderType };
      }, [q, orderBy, orderType]),
    });

  return [data, { handleFetch, hasMore, handleRefresh, totalCount, isLoading }];
};
