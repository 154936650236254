import React from 'react';
import PropTypes from 'prop-types';
import Menu from './components/Menu';
import Blanket from './components/Blanket';

const SelectDropdown =  React.forwardRef(({children, isOpen, target, onClose}, ref) => (
  <div style={{position: 'relative'}} ref={ref}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose}/> : null}
  </div>
));

SelectDropdown.propTypes = {
  children: PropTypes.any
}

export default SelectDropdown;