import { useService } from "../../../base/hooks/useService";
import TaskManagement from "../../../services/TaskManagement";
import { useInfiniteScroll } from "../../../base/components/InfiniteScrollWrapper/hooks/useInfiniteScroll";

export const useGetTaskList = () => {
  /**
   * @type {TaskManagement}
   */
  const TaskManagementService = useService(TaskManagement);

  const fetchData = (params) => TaskManagementService.getTasks(params);

  const { data, handleFetch, hasMore, handleRefresh, totalCount, isLoading } =
    useInfiniteScroll({ fetchData });

  return { data, handleFetch, hasMore, handleRefresh, totalCount, isLoading };
};
