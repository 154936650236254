import STYLE from "../constants/style";

export const customStyles = {
  option: (styles, state) => {
    const inlineStyles = {
      ...styles,
      color: STYLE.DEFAULT_COLOR,
      borderRadius: 4,
      backgroundColor: null,
      ":active": {
        ...styles[":active"],
        backgroundColor: null,
      },
      cursor: "pointer",
    };

    if (state.isFocused) {
      inlineStyles.backgroundColor = STYLE.FOCUSED_COLOR;
    }

    if (state.isSelected) {
      inlineStyles.backgroundColor = STYLE.FOCUSED_COLOR;
      inlineStyles[":active"].backgroundColor = STYLE.FOCUSED_COLOR;
    }

    return inlineStyles;
  },

  input: (styles, { isDisabled }) => ({
    ...styles,
    input: {
      opacity: isDisabled ? "0.5" :"1 !important",
    },
  }),

  control: (styles, { isDisabled }) => ({
    ...styles,
    boxShadow: "",
    borderColor: isDisabled
      ? STYLE.OPTION_BORDER_COLOR
      : STYLE.OPTION_BORDER_DISABLED_COLOR,
    backgroundColor: isDisabled
      ? STYLE.OPTION_BACKGROUND_DISABLED_COLOR
      : STYLE.OPTION_BACKGROUND_DEFAULT_COLOR,
    opacity: isDisabled ? 0.5 : 1,
    color: isDisabled
      ? STYLE.OPTION_DEFAULT_COLOR
      : STYLE.OPTION_DISABLED_COLOR,
    ":hover": {
      borderColor: isDisabled
        ? STYLE.OPTION_BORDER_COLOR
        : STYLE.OPTION_BORDER_HOVER_COLOR,
    },
  }),

  placeholder: (styles, { isDisabled }) => {
    return {
    ...styles,
      color: isDisabled
      ? STYLE.PLACEHOLDER_DISABLED_COLOR
      : STYLE.PLACEHOLDER_DEFAULT_COLOR,
    }
  },
  menuList: (provided, state) => {
    return {
      ...provided,
      paddingLeft: 12,
      paddingRight: 12,
      position: "static",

      "&::-webkit-scrollbar": {
        width: ".3125rem",
      },

      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.1)",
      },

      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#2f665c",
        borderRadius: "5px",
      },
    };
  },
};
