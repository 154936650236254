import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import {DATE_FORMAT} from "../../../drafts/helpers/format";

const defaultDate = [null, null];

const ExpenseDateFilter = ({
  dateRange = defaultDate,
  onChange = () => {},
  dateFormat,
  placeholder = DATE_FORMAT,
  containerClass,
}) => {
  const [startDate, endDate] = dateRange;

  return (
    <div className={containerClass}>
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        selectsRange
        isClearable
        shouldCloseOnSelect
        dateFormat={dateFormat}
        placeholderText={placeholder}
      />
    </div>
  );
};

ExpenseDateFilter.propTypes = {
  dateRange: PropTypes.array,
  onChange: PropTypes.func,
  dateFormat: PropTypes.string,
  placeholder: PropTypes.string,
  containerClass: PropTypes.string,
};

export default ExpenseDateFilter;
