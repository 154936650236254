import classnames from "classnames";
import React from "react";

export const labelDiscount = ({label, footerMessage, isDisabled}) => {
  const discountPart = label.split(":")
  const discountPriceTitle = discountPart[1] ? discountPart[1].trim() : discountPart

  return(
    <div className={classnames({
      isDisabled: isDisabled
    })}>
      <p className="mb-0">{discountPriceTitle}</p>
      {footerMessage &&
      <p className="discount-footer mb-0 text-gray-gomi font-size-12">{footerMessage}</p>}
    </div>
  )
}