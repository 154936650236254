import { useCallback, useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { PRODUCT_TYPE } from "../../../base/constants/product";
import { useService } from "../../../base/hooks/useService";
import DraftsService from "../../../services/DraftsService";
import { MARKETPLACE_TYPES } from "./useProductStatus/components/SoldModal/constants/index";
import { FilterOption } from "../contexts/inventory-dropdown";

export interface UseProductCallbackProps {
  orderType?: "asc" | "desc";
  orderBy?: string;
  offset?: number | string;
  query?: string;
  marketplaceType?: number | number[];
  isExcludeListedToAll?: boolean;
  mergeDisplayed?: boolean;
}

interface GetDraftsParams {
  limit: number;
  orderBy?: string;
  orderType?: "asc" | "desc";
  offset?: number | string;
  q?: string;
  status: number;
  marketplaceTypes?: number[];
  isExcludeListedToAll?: boolean;
  displayMerged?: boolean;
}

export const useProduct = (
  status: number = PRODUCT_TYPE.DRAFT,
  limit: number = 10,
  markToUpdate: {
    [key: string]: any;
  } | null = null
) => {
  /**
   * @type {DraftsService}
   */
  const draftService = useService(DraftsService);

  return useCallback(
    ({
      orderType,
      orderBy,
      offset,
      query: q,
      marketplaceType,
      isExcludeListedToAll,
      mergeDisplayed = true,
    }: UseProductCallbackProps) => {
      let marketplaceTypes: number[] = [];

      if (marketplaceType && !Array.isArray(marketplaceType)) {
        marketplaceTypes.push(marketplaceType);
      } else if (marketplaceType && Array.isArray(marketplaceType)) {
        marketplaceTypes = marketplaceTypes.concat(marketplaceType);
      }

      const uniqMarketplaces = marketplaceTypes.filter(
        (marketplace, index) => marketplaceTypes.indexOf(marketplace) === index
      );

      if (status === PRODUCT_TYPE.ACTIVE) {
        isExcludeListedToAll = false;
      }

      const params: GetDraftsParams = {
        limit,
        orderBy,
        orderType,
        offset,
        q,
        status,
        marketplaceTypes: uniqMarketplaces,
        isExcludeListedToAll,
        displayMerged: false,
      };

      if (status === PRODUCT_TYPE.ACTIVE && marketplaceTypes.length > 0) {
        params.displayMerged = mergeDisplayed;
      } else {
        delete params.marketplaceTypes;
        delete params.displayMerged;
      }

      return draftService.getListDrafts(params);
    },

    [draftService, status, limit, markToUpdate]
  );
};

interface ProductState {
  total: number;
  load: boolean;
}

export const useTotalProducts = (type: number) => {
  const load = useProduct(type, 1);
  const [state, update] = useState<ProductState>({ total: 0, load: true });

  useEffect(() => {
    load({})
      .then(({ pagination }) => {
        update({ load: false, total: pagination.totalCount });
      })
      .catch((e) => Bugsnag.notify(e));
  }, [load]);

  return [state, update];
};
