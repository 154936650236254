import React from "react";
import PropTypes from "prop-types";

import BrandOption from "../BrandOption";

import { COMMA_SYMBOL, DOT_SYMBOL } from "../../constants";

const BrandOptions = ({ brandList, onSelectBrand }) => {
  return (
    <>
      {brandList?.map((brand, index) => {
        const isLastItem = brandList.length - 1 === index;
        const separateSymbol = isLastItem ? DOT_SYMBOL : COMMA_SYMBOL;

        return (
          <React.Fragment key={brand}>
            <BrandOption label={brand} onClick={onSelectBrand} />
            {`${separateSymbol} `}
          </React.Fragment>
        );
      })}
    </>
  );
};

BrandOptions.propTypes = {
  brandList: PropTypes.array,
  onSelectBrand: PropTypes.func,
};

export default BrandOptions;
