import React from "react";
import PropTypes from "prop-types";

import classnames from 'classnames';

const CustomButton = (props) => {
  const {
    id, 
    type = "button", 
    onClick, 
    className, 
    disabled, 
    children, 
    ...otherProps 
  } = props;

  return (
    <button 
      id={id} 
      type={type} 
      className={classnames(className, 'btn')}
      onClick={onClick}
      disabled={disabled}
      {...otherProps}
    >
      {children}
    </button>
  );
};

CustomButton.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf(["button", "submit"]),
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

export default CustomButton;