import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./index.module.scss";

const DropdownItem = ({ title, onClick, disabled = false}) => {
  return (
    <span
      className={classNames(styles.dropdownItem, !disabled ? styles.activeItem : styles.disabledItem)}
      onClick={onClick}
    >
      {title}
    </span>
  );
};

DropdownItem.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default DropdownItem;
