import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import FormikInput from "../../../../base/components/FormikInput";
import SendCodeButton from "../SendCodeButton";
import AuthService from "../../../../services/AuthService";
import CustomAlert from "../../../auth/components/CustomAlert";

import StorageService from "../../../../services/StorageService";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";
import {
  ACCESS_CODE_LENGTH,
  DEV_PHONE_NUMBER_LENGTH,
  MIN_PHONE_NUMBER_LENGTH,
} from "../../../../validation/lengthConstants";
import {
  CODE_NAME_KEY,
  EMPTY_STRING,
  PHONE_STORAGE_KEY,
} from "../../../../base/constants/forms";
import {
  PHONE_NUMBER_PATTERN,
  DEV_PHONE_NUMBER_PATTERN,
} from "../../../../base/constants/patterns";
import CustomErrorMessage from "../../../../base/components/ErrorMessage";
import { useCustomError } from "../../../../base/contexts/CustomErrorMessage";
import {
  getPrefixDevPhpne,
  getPrefixPhone,
} from "../../../../base/helpers/getPrefixPhone";
import { useIsDev } from "../../../../base/hooks/useIsDev";
import { INVALID_REGION, PHONE_NUMBER_IS_USED } from "./constants";

const SecureContent = ({
  values,
  setFieldValue,
  setFieldError,
  errors,
  onDisabledChange,
}) => {
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);

  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const isDev = useIsDev();

  const { setError } = useCustomError();

  const [isDisabledButton, updateDisabledButton] = useState(true);
  const [isSendedCode, updateIsSendedCode] = useState(false);

  const [, { registerPromise }] = useLoading();

  const handleSendCode = useCallback(
    (values, setFieldValue) => {
      values?.code && setFieldValue(CODE_NAME_KEY, EMPTY_STRING);

      registerPromise(authService.sendPhoneNumber(values))
        .then(() => {
          updateDisabledButton(false);
          updateIsSendedCode(true);
          storage.set(PHONE_STORAGE_KEY, values.phoneNumber);
        })
        .catch((error) => {
          Bugsnag.notify(error);

          const {
            error: { message },
          } = error;

          // we'll need to standardise these from the BE
          if (message.indexOf("Permission to send") !== -1) {
            setFieldError("phoneNumber", INVALID_REGION.ERROR_FIELD_MESSAGE);
            return;
          }

          setFieldError(
            "phoneNumber",
            PHONE_NUMBER_IS_USED.ERROR_FIELD_MESSAGE
          );
        });
    },
    [authService, registerPromise, storage, setFieldError]
  );

  const isDisabledSendButton = useMemo(() => {
    return !!(errors.phoneNumber || isDev
      ? !DEV_PHONE_NUMBER_PATTERN.test(values.phoneNumber)
      : !PHONE_NUMBER_PATTERN.test(values.phoneNumber));
  }, [errors.phoneNumber, values.phoneNumber, isDev]);

  return (
    <div>
      <h3 className="ui-black-bold">
        Secure your account with 2-factor authentication
      </h3>
      <Row className="mb-4">
        <Col md="6">
          <FormikInput
            type="phone"
            id="phoneNumber"
            name="phoneNumber"
            label="Phone number"
            maxLength={
              isDev ? DEV_PHONE_NUMBER_LENGTH : MIN_PHONE_NUMBER_LENGTH
            }
            placeholder="Enter your phone number"
            onChange={({ target: { value } }) => {
              setFieldValue(
                "phoneNumber",
                isDev ? getPrefixDevPhpne(value) : getPrefixPhone(value)
              );
            }}
          />
        </Col>
        <Col md="6">
          <FormikInput
            type="text"
            id="code"
            name={CODE_NAME_KEY}
            label="Code"
            disabled={isDisabledButton}
            maxLength={ACCESS_CODE_LENGTH}
            placeholder="Enter the 6-digit code"
            ErrorMessage={CustomErrorMessage}
            onChange={({ target: { value } }) => {
              onDisabledChange(value?.length === ACCESS_CODE_LENGTH);
              setError(CODE_NAME_KEY, "");
            }}
          />
        </Col>
      </Row>
      <SendCodeButton
        className="mb-4"
        isSendedCode={isSendedCode}
        disabled={isDisabledSendButton}
        isShowCounter={!errors.phoneNumber}
        onClick={() => handleSendCode(values, setFieldValue)}
        isErrors={errors.phoneNumber}
      />
      {isSendedCode && (
        <CustomAlert className="justify-content-start">
          The verification code was sent to your phone number
        </CustomAlert>
      )}
    </div>
  );
};

SecureContent.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  setFieldError: PropTypes.func,
  dirty: PropTypes.bool,
  errors: PropTypes.object,
  onDisabledChange: PropTypes.func,
};

export default SecureContent;
