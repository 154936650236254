import React from 'react'

import PropTypes from 'prop-types';
import {ReactComponent as CloseIcon} from "../grey.svg"

const SelectControl = ({ children, selectProps }) => {
  const { onToggleClick } = selectProps;

  return (
    <div className='dropdown-select-wrap-list'>
      <div className='head'>
        <span className='md-title-grey'>Category selection</span>
        <CloseIcon 
          className='cursor-pointer'
          onClick={onToggleClick}
        />
      </div>
      {children}
    </div>
  );
};

SelectControl.propTypes = {
  children: PropTypes.any,
  selectProps: PropTypes.any,
  props: PropTypes.array
}

export default SelectControl;