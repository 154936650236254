import React from "react";
import { components } from "react-select";

import { MAX_CATEGORY } from "../../constants";

const CategoryInput = (props) => {
  if (props.isHidden) {
    return <components.Input {...props} />;
  }
  return (
    <div>
      <components.Input {...props} maxLength={MAX_CATEGORY} />
    </div>
  );
};

export default CategoryInput;
