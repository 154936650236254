import React, { useCallback } from 'react';
import PropTypes from "prop-types";

import Checkbox from '../../../base/components/Checkbox';

import PrivacyContent from './PrivacyContent';

const AuthPrivacy = ({ id, checked, onChange }) => {
  const handleChange = useCallback((id, checked) => {
    onChange(id, !checked);
  }, []);

  return (
    <div className='mb-3'>
      <Checkbox
        className='custom-green-checkbox'
        id={id}
        text={<PrivacyContent />}
        value={checked} 
        onChange={() => handleChange(id, checked)} 
      />
    </div>
  )
};

AuthPrivacy.propTypes = {
  id: PropTypes.any,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
};

export default AuthPrivacy;

