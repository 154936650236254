/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { PoshmarkProductImages } from "../../hooks/useProductImages";
import FormPoshmarkInput from "./partials/FormPoshmarkInput";
import FormPoshmarkTextArea from "./partials/FormPoshmarkTextArea";
import {
  COUNT_OF_ROWS_STR,
  MAX_DESCRIPTION_POSHMARK_LENGTH,
  MAX_TITLE_POSHMARK_LENGTH,
} from "../../const/poshmark";
import FormPoshmarkCategories from "./partials/FormPoshmarkCategories";

import "./index.scss";
import FormPoshmarkNewWithTags from "./partials/FormPoshmarkNewWithTags";
import FormPoshmarkBrand from "./partials/FormPoshmarkBrand";
import FormPoshmarkColors from "./partials/FormPoshmarkColors";
import FormPoshmarkTags from "./partials/FormPoshmarkTags";
import FormPoshmarkPricing from "./partials/FormPoshmarkPricing";
import FormPoshmarkAdditionalDetails from "./partials/FormPoshmarkAdditionalDetails";
import ListingUrl from "../DraftEbayFormInner/partials/ListingUrl";
import { useField } from "formik";
import {
  MAX_DESCRIPTION_LENGTH,
  MAX_TITLE_LENGTH,
  MIN_TITLE_LENGTH,
} from "../../../../validation/lengthConstants";

const DraftPoshmarkFormInner = ({
  values,
  setFieldValue,
  postedInfo,
  formRef,
  status,
  suggestedFields,
  isLoading,
  setCategoriesData,
}) => {
  const [colors, setColors] = useState([]);
  const [{ value: titleValue }, , { setTouched: setTouchedTitle }] = useField({
    name: "title",
  });
  const [{ value: descriptionValue }, , { setTouched: setTouchedDescription }] =
    useField({ name: "description" });

  useEffect(() => {
    if (titleValue?.length > MAX_TITLE_POSHMARK_LENGTH) {
      setTouchedTitle(true, true);
    }

    if (descriptionValue?.length > MAX_DESCRIPTION_POSHMARK_LENGTH) {
      setTouchedDescription(true, true);
    }
  }, []);

  return (
    <>
      {/* All images */}
      <PoshmarkProductImages />

      <FormPoshmarkInput
        id="title"
        name="title"
        label="Title*"
        description="Share key details like Brand, Size, and Color."
        placeholder="What are you selling?"
        maxLength={MAX_TITLE_LENGTH}
        minLength={MIN_TITLE_LENGTH}
        maxLengthForCounter={MAX_TITLE_POSHMARK_LENGTH}
        isShowLengthDescription
        isHideLabel
      />

      <FormPoshmarkTextArea
        id="description"
        name="description"
        label="Description*"
        description="Share keywords including Brand, Size, Color, Condition, Material, and any other noteworthy details."
        placeholder="Enter item description"
        maxLength={MAX_DESCRIPTION_LENGTH}
        maxLengthDescription={MAX_DESCRIPTION_POSHMARK_LENGTH}
        type="textarea"
        isShowLengthDescription
        rows={COUNT_OF_ROWS_STR}
        isHideLabel
      />

      <FormPoshmarkCategories
        label="Category*"
        description="Add details to help your buyers find your item quickly."
        values={values}
        setFieldValue={setFieldValue}
        setColors={setColors}
        suggested={suggestedFields.categoryName}
        isLoading={isLoading}
        setCategoriesData={setCategoriesData}
      />

      <FormPoshmarkNewWithTags values={values} setFieldValue={setFieldValue} />

      <FormPoshmarkBrand
        values={values}
        setFieldValue={setFieldValue}
        suggested={suggestedFields.brand}
      />

      <FormPoshmarkColors
        values={values}
        colors={colors}
        setFieldValue={setFieldValue}
      />

      <FormPoshmarkTags />

      <FormPoshmarkPricing
        values={values}
        setFieldValue={setFieldValue}
        status={status}
      />

      <FormPoshmarkAdditionalDetails setFieldValue={setFieldValue} />

      <ListingUrl
        values={values}
        postedInfo={postedInfo}
        setFieldValue={setFieldValue}
        isDisabledCopyBtn={formRef?.current?.errors?.link}
        description={
          'A listing URL is required to delist. Please enter one if you manually changed the status to "Listed". The listing URL should look like: https://poshmark.com/listing/any-name-number'
        }
      />
    </>
  );
};

export default DraftPoshmarkFormInner;
