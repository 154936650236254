const excludes = {
  "&": true,
  "/": true,
};

export const createFullTextSearch = (query = "", useWeight = false) => {
  const queryLowerCase = query
    .split(" ")
    .map((word) => word.trim())
    .filter((name) => name && !excludes[name])
    .join("|");

  const regQueryLowerCase = new RegExp(queryLowerCase, "gi");

  const matches = {};

  return (categoriesForSearch, getCategoryName) => {
    const searchedCategories = [];

    for (let i = 0; i < categoriesForSearch.length; i++) {
      const categoryItem = categoriesForSearch[i];
      const name = getCategoryName(categoryItem);

      if (name === query) return [categoryItem];

      if (regQueryLowerCase.test(name)) {
        // By name
        matches[name] = name.match(regQueryLowerCase).length;

        searchedCategories.push(categoryItem);
      }
    }

    if (!useWeight) {
      return searchedCategories;
    }

    return searchedCategories.sort((a, b) => {
      const nameA = getCategoryName(a);
      const nameB = getCategoryName(b);
      return matches[nameB] - matches[nameA];
    });
  };
};
