import React from "react";
import { CardBody, Row } from "reactstrap";
import PropTypes from "prop-types";

import CustomButton from "../../../../base/components/CustomButton";
import emptyExpensesIcon from "../../images/expenses-empty.png"

const defaultStrings = {
  messageFirstPart: "No expenses for now. To create a new",
  messageSecondPart: 'expense, click "+ Add expense"',
  btnTitle: "+ Add expense",
};
const ExpensesPlaceholder = ({
  onClick = () => {},
  strings = defaultStrings,
  isMileages = false
}) => {
  return (
    <CardBody className="returned-placeholder expenses-placeholder d-flex flex-column">
      <Row>
        {<img src={emptyExpensesIcon} className="expenses-placeholder-img"/>}
      </Row>
      <Row className="text-center mt-4">
        <h3 className="bold-black font-size-15 text-muted">
          {strings.messageFirstPart}
          <br />
          {strings.messageSecondPart}
        </h3>
      </Row>
      <Row className="mt-4">
        <CustomButton className="filled-primary" onClick={onClick}>
          {strings.btnTitle}
        </CustomButton>
      </Row>
    </CardBody>
  );
};

ExpensesPlaceholder.propTypes = {
  onClick: PropTypes.func,
  strings: PropTypes.object,
  isMileages: PropTypes.bool
};

export default ExpensesPlaceholder;
