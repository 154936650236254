import React, { useCallback, useState } from "react";
import { Formik } from "formik";
import { Card, CardBody, Col, Row } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import FormikEmail from "../../../../base/components/FormikEmail";
import CustomButton from "../../../../base/components/CustomButton";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../base/components/CustomModal";

import ProfileService from "../../../../services/ProfileService";
import StorageService from "../../../../services/StorageService";
import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";
import { KEY_USER } from "../../../../base/constants/storage";
import { validationSchema } from "./form";
import { CHANGED_EMAIL_KEY } from "../../../../base/constants/forms";
import PageMeta from "../../../../base/components/MetaTags";

const ChangeEmail = () => {
  /**
   * @type {StorageService}
   */
  const profileService = useService(ProfileService);

  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const [isOpenModal, updateIsOpenModal] = useState(false);

  const handleToggleModal = useCallback(() => {
    updateIsOpenModal((isOpen) => !isOpen);
  }, []);

  const [, { registerPromise }] = useLoading();

  const handleSubmit = useCallback(
    (values) => {
      registerPromise(profileService.changeEmail(values))
        .then(() => {
          storage.set(CHANGED_EMAIL_KEY, values.phoneNumber);
          handleToggleModal();
        })
        .catch((e) => Bugsnag.notify(e));
    },
    [profileService, registerPromise, handleToggleModal, storage]
  );

  return (
    <Row className="mt-3">
      <PageMeta title="Profile" />
      <Col lg="12" xl="5">
        <Card>
          <CardBody>
            <h3 className="bold-black mb-4">Change email</h3>
            <Formik
              initialValues={{
                email:
                  storage.get(CHANGED_EMAIL_KEY) ||
                  storage.get(KEY_USER)?.email ||
                  "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, errors, dirty }) => (
                <form className="form-horizontal p-2" onSubmit={handleSubmit}>
                  <FormikEmail
                    id="email"
                    name="email"
                    label="Current email address"
                    containerClassName="mb-5"
                    placeholder="Enter your email"
                    type="email"
                  />
                  <div className="d-flex justify-content-end mt-3">
                    <CustomButton
                      type="submit"
                      className="filled-primary"
                      disabled={!!Object.keys(errors).length || !dirty}
                    >
                      Change
                    </CustomButton>
                  </div>
                </form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
      <CustomModal isOpen={isOpenModal} onClose={handleToggleModal}>
        <ModalHeader onClose={handleToggleModal}>
          Verify email address
        </ModalHeader>
        <ModalBody>
          <p className="ui-regular">
            To confirm changes please validate your email. The link was sent to
            your new email.
          </p>
        </ModalBody>
        <ModalFooter>
          <CustomButton className="filled-primary" onClick={handleToggleModal}>
            Ok
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </Row>
  );
};

export default ChangeEmail;
