import React from 'react'
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

import CustomButton from '../../../../../../base/components/CustomButton';

import { CustomModal, ModalBody, ModalFooter, ModalHeader } from '../../../../../../base/components/CustomModal';
import { SETTINGS_LINKS } from '../../../../../drafts/pages/Settings/config';

const ErrorMarketplaceConnection = ({ isOpen, onClose, name = "eBay" }) => {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <ModalHeader onClose={onClose}>
      Account Connection Required
      </ModalHeader>
      <ModalBody>
        <p className='md-black'>
          Please connect your {name} account under the Marketplace connections tab in&nbsp;
          <Link
            to={SETTINGS_LINKS.MARKETPLACE}
            className="bold-green"
          >
            Settings.
          </Link>
        </p>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          onClick={onClose}
          className='filled-primary'
        >
          Close
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  )
};

ErrorMarketplaceConnection.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
}

export default ErrorMarketplaceConnection;
