import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import styles from "./index.module.scss";
import { BOOKKEEPING_DATE_FORMAT, MOMENT_YEAR } from "../../constants";

const INITIAL_DATE_RANGE = {
  start: moment().startOf(MOMENT_YEAR).format(BOOKKEEPING_DATE_FORMAT),
  end: moment().format(BOOKKEEPING_DATE_FORMAT),
};

const BookkeepingHeader = ({ title, dateRange = INITIAL_DATE_RANGE }) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>{title}</span>
      <span
        className={styles.dateRange}
      >{`${dateRange.start} - ${dateRange.end}`}</span>
    </div>
  );
};

BookkeepingHeader.propTypes = {
  title: PropTypes.string,
  dateRange: PropTypes.object,
};

export default BookkeepingHeader;
