import yup from "./yup";

import {
  MAX_DESCRIPTION_LENGTH,
  MAX_REASON_LENGTH,
  MAX_TITLE_LENGTH,
  MIN_DESCRIPTION_LENGTH,
  MIN_TITLE_LENGTH,
} from "./lengthConstants";
import {
  DEFAULT_MAX_NUMBER,
  DESCRIPTION_ERROR,
  MIN_VALIDATION_NUMBER,
  PRISE_ERROR,
  RECEIVED_CONDITION_ERROR,
  RETURN_PERSONAL_NOTES_ERROR,
  RETURN_REASON_ERROR,
  TITLE_ERROR,
} from "../base/constants/forms";

export const RETURN_TITLE = yup
  .string()
  .min(MIN_TITLE_LENGTH, TITLE_ERROR)
  .max(MAX_TITLE_LENGTH, TITLE_ERROR)
  .trim();

export const RETURN_DESCRIPTION = yup
  .string()
  .min(MIN_DESCRIPTION_LENGTH, DESCRIPTION_ERROR)
  .max(MAX_DESCRIPTION_LENGTH, DESCRIPTION_ERROR)
  .trim()
  .required(DESCRIPTION_ERROR);

export const RETURN_ITEM_COST = yup
  .number()
  .min(MIN_VALIDATION_NUMBER, PRISE_ERROR)
  .max(DEFAULT_MAX_NUMBER, PRISE_ERROR)
  .nullable();

export const SHIPPING_COST = yup
  .number()
  .min(MIN_VALIDATION_NUMBER, PRISE_ERROR)
  .max(DEFAULT_MAX_NUMBER, PRISE_ERROR)
  .nullable();

export const SHIPPING_CHARGED_TO_BUYER = yup
  .number()
  .min(MIN_VALIDATION_NUMBER, PRISE_ERROR)
  .max(DEFAULT_MAX_NUMBER, PRISE_ERROR)
  .nullable();

export const PRICE_SOLD = yup
  .number()
  .min(MIN_VALIDATION_NUMBER, PRISE_ERROR)
  .max(DEFAULT_MAX_NUMBER, PRISE_ERROR)
  .nullable();

export const RETURN_SOLD_AT = yup.string().nullable();

export const RETURN_MARKETPLACE_TYPE = yup.number().nullable();

export const RETURN_RETURNED_AT = yup.string().nullable();

export const RETURN_REASON = yup
  .string()
  .min(MIN_TITLE_LENGTH, RETURN_REASON_ERROR)
  .max(MAX_REASON_LENGTH, RETURN_REASON_ERROR)
  .trim();

export const RETURN_RECEIVED_AT = yup.string().nullable();

export const RETURN_RECEIVED_CONDITION = yup
  .string()
  .min(MIN_TITLE_LENGTH, RECEIVED_CONDITION_ERROR)
  .max(MAX_TITLE_LENGTH, RECEIVED_CONDITION_ERROR)
  .trim();

export const RETURN_SHIPPING_COST = yup
  .number()
  .min(MIN_VALIDATION_NUMBER, PRISE_ERROR)
  .max(DEFAULT_MAX_NUMBER, PRISE_ERROR)
  .nullable();

export const RETURN_PERSONAL_NOTES = yup
  .string()
  .min(MIN_TITLE_LENGTH, RETURN_PERSONAL_NOTES_ERROR)
  .max(MAX_TITLE_LENGTH, RETURN_PERSONAL_NOTES_ERROR)
  .trim();

export const RETURN_STATUS = yup.number();
