import { useCallback, useEffect, useRef, useState } from "react";

import { validateFile } from "../../../base/components/DropzoneFile/utils/validateFile";

import { useService } from "../../../base/hooks/useService";
import S3Service from "../../../services/S3Service";

import { EXPENSES_IMAGE_CODE } from "../constants";
import Bugsnag from "@bugsnag/js";

export const useExpensesImage = ({
  formFieldName,
  onValidate = validateFile,
  initImage,
}) => {
  const formRef = useRef();
  const [selectedImage, setSelectedImage] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  /**
   * @type {S3Service}
   */
  const imageService = useService(S3Service);

  const onUploadImage = async (files) => {
    setIsLoading(true);
    const error = onValidate(...files);
    if (error) {
      setErrorMessage(error);
      return;
    }

    try {
      const [
        {
          file: { id },
        },
      ] = await imageService.uploadImageFiles([...files], EXPENSES_IMAGE_CODE);

      setSelectedImage({ ...files, preview: URL.createObjectURL(...files) });

      formRef.current.setFieldValue(formFieldName, id);
    } catch (error) {
      Bugsnag.notify(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onDeleteImage = useCallback(() => {
    setSelectedImage({});
    formRef.current.setFieldValue(formFieldName, undefined);
  }, [setSelectedImage, formFieldName]);

  useEffect(() => {
    setSelectedImage({ preview: initImage });
  }, [initImage]);

  return [
    onUploadImage,
    onDeleteImage,
    selectedImage,
    formRef,
    errorMessage,
    isLoading,
  ];
};
