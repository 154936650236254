/* eslint-disable react/prop-types */
import React, { memo } from "react";
import { useDrop } from "react-dnd";
import Button from "../../../base/components/Button";

export const RightCategory = memo(function Dustbin({
  accept,
  name,
  id: categoryId,
  onDrop,
  onCancel,
  items,
  style,
  onQuery,
}) {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = isOver && canDrop;

  const s = {};

  if (items.length) {
    s.backgroundColor = "whitesmoke";
  }

  if (isActive) {
    s.backgroundColor = "#15bf75";
  }

  const words = name.split("/").map((w) => w.trim());

  const jsx = words.map((word, index) => {
    return (
      <React.Fragment key={word}>
        <a key={word} onClick={() => onQuery(word)}>
          {word}
        </a>{" "}
        {words[index + 1] ? "/ " : ""}
      </React.Fragment>
    );
  });
  return (
    <div
      ref={drop}
      role="Dustbin"
      className={"item"}
      style={{ ...style, ...s }}
    >
      {!!items.length && <p> Basic: </p>}
      {items.map((item, index) => {
        const { name, id } = item;

        return (
          <p key={id}>
            {index + 1}. {name}
            <Button
              onClick={() => onCancel({ id, categoryId })}
              className={"outline-primary small"}
            >
              Undo
            </Button>
          </p>
        );
      })}

      {!!items.length && <p> ---------- </p>}
      {jsx}
    </div>
  );
});
