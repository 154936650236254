import React, { useCallback, useMemo } from "react";
import { Row, Col } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";

import Checkbox from "../../../../../../base/components/Checkbox";

import { customStyles } from "../../../../../../base/components/Select/shared/utils/defaultSelectStyle";
import {
  DISPLAY_COLUMN_OPTIONS,
  ACCOUNTING_METHODS,
} from "../../../../constants";

import styles from "./index.module.scss";

const ColumnsSettings = ({ settings, setSettings }) => {
  const { displayColumnView, accountingMethod } = settings;

  const onChangeDisplayColumnView = useCallback(
    ({ id: value }) => {
      setSettings({ displayColumnView: value });
    },
    [setSettings]
  );

  const onChangeAccrualCheckbox = useCallback(
    ({ target: { checked } }) => {
      const newValue = checked
        ? ACCOUNTING_METHODS.accrual
        : ACCOUNTING_METHODS.cash;
      setSettings({ accountingMethod: newValue });
    },
    [setSettings]
  );

  const onChangeCashCheckbox = useCallback(
    ({ target: { checked } }) => {
      const newValue = checked
        ? ACCOUNTING_METHODS.cash
        : ACCOUNTING_METHODS.accrual;
      setSettings({ accountingMethod: newValue });
    },
    [setSettings]
  );

  const getSelectedColumnView = useMemo(() => {
    return (
      DISPLAY_COLUMN_OPTIONS.find(
        (option) => option.id === displayColumnView
      ) || ""
    );
  }, [displayColumnView]);

  return (
    <Row className={styles.wrapper}>
      <Col md="3">
        <label className="bookkeeping-settings-label" htmlFor="columnView">
          Display columns by
        </label>
        <Select
          name="columnView"
          value={getSelectedColumnView}
          options={DISPLAY_COLUMN_OPTIONS}
          getOptionValue={({ id }) => id}
          placeholder="Choose columns view"
          styles={customStyles}
          onChange={onChangeDisplayColumnView}
          components={{
            ClearIndicator: null,
            IndicatorSeparator: null,
          }}
        />
      </Col>
      <Col md="2">
        <Row>
          <label className="bookkeeping-settings-label" htmlFor="Accrual">
            Accounting method
          </label>
          <Col md="5" className={styles.checkboxWrapper}>
            <Checkbox
              name="Accrual"
              id="Accrual"
              className="custom-green-checkbox"
              text="Accrual"
              checked={accountingMethod === ACCOUNTING_METHODS.accrual}
              onChange={onChangeAccrualCheckbox}
              type="radio"
            />
          </Col>
          <Col md="5" className={styles.checkboxWrapper}>
            <Checkbox
              className="custom-green-checkbox"
              text="Cash"
              id="Cash"
              type="radio"
              checked={accountingMethod === ACCOUNTING_METHODS.cash}
              onChange={onChangeCashCheckbox}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

ColumnsSettings.propTypes = {
  settings: PropTypes.object,
  setSettings: PropTypes.func,
};

export default ColumnsSettings;
