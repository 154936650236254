import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import FormikMultiSelect from "../../../../../../base/components/FormikMultiSelect";
import { useService } from "../../../../../../base/hooks/useService";
import PoshmarkExtension from "../../../../../../services/PoshmarkExtension";
import { poshmarkTagsListToOptions } from "../../../../helpers/listToOptions";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import DotsLoader from "../../../../../../base/components/DotLoader";

const FormPoshmarkTags = () => {
  const [tagsOptions, updateTagsOptions] = useState([]);
  /**
   * @type {PoshmarkExtension}
   */
  const poshmark = useService(PoshmarkExtension);

  const [loading, { registerPromise }] = useLoading();

  useEffect(() => {
    registerPromise(poshmark.getTags())
      .then(poshmarkTagsListToOptions)
      .then((list) => updateTagsOptions(list))
      .catch((e) => Bugsnag.notify(e));

    return () => {
      updateTagsOptions([]);
    };
  }, [poshmark, registerPromise]);

  const getTagsOptions = useCallback(
    (query) => {
      return registerPromise(poshmark.getTags(query))
        .then(poshmarkTagsListToOptions)
        .then((list) => {
          updateTagsOptions(list);
          return list;
        })
        .catch((e) => Bugsnag.notify(e));
    },
    [poshmark, updateTagsOptions, registerPromise]
  );

  return (
    <Row className="section-wrapper">
      <Col md={4}>
        <h3 className="bold-black mb-1">
          Style tags {loading && <DotsLoader />}
        </h3>
        <p className="text-gray-gomi">
          Make it easy for buyers to find your listings by selecting or creating
          up to 3 tags to describe your item (aesthetic, material, style, etc.)
        </p>
      </Col>
      <Col md={8}>
        <FormikMultiSelect
          id={"tags"}
          name={"tags"}
          placeholder={"Add up to 3 tags"}
          options={tagsOptions}
          allowCreate
          description={"Use up to 3 tags"}
          onRequestAPI={getTagsOptions}
        />
      </Col>
    </Row>
  );
};

export default FormPoshmarkTags;
