import React, { useState, useMemo, useEffect, Fragment } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import PropTypes from "prop-types";

import Icon from "../../../../base/components/Icon";

import ExpenseCategoryItem from "../ExpenseCategoryItem";

import { useDropdown } from "../../../../base/hooks/useDropdown";

import { useLocationQuery } from "../../../../base/hooks/useQueryString";

import "./index.scss";

const defaultFilter = { id: null, name: "All" };

const ExpenseCategoryFilter = ({ categories }) => {
  const [isOpen, toggle] = useDropdown();

  const {
    params: { categoryId },
  } = useLocationQuery();

  const currentItem = useMemo(() => {
    return categories.find((element) => element.id === categoryId);
  }, [categories, categoryId]);

  const [currentFilter, updateCurrentFilter] = useState(defaultFilter);

  useEffect(() => {
    updateCurrentFilter(currentItem ? currentItem : defaultFilter);
  }, [categoryId, currentItem, updateCurrentFilter, defaultFilter]);

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle className="btn header-item waves-effect" tag="button">
        <button type="button" className="expense-category-filter-wrapper">
          <span className="text-truncate">{currentFilter.name}</span>
          <Icon icon="bottomArrow" className="expense-category-filter-icon " />
        </button>
      </DropdownToggle>
      <DropdownMenu persist className="filter-block__menu">
        <ExpenseCategoryItem
          id={null}
          name="All"
          updateCurrentFilter={updateCurrentFilter}
        />
        {categories.map((item, index) => (
          <Fragment key={index}>
            <ExpenseCategoryItem
              id={item.id}
              name={item.name}
              updateCurrentFilter={updateCurrentFilter}
            />
          </Fragment>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

ExpenseCategoryFilter.propTypes = {
  categories: PropTypes.array,
};

export default ExpenseCategoryFilter;
