import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useField } from "formik";
import { FormGroup } from "reactstrap";

import AttachedFile from "./AttachedFile";
import DropzoneFile from "./DropzoneFile/index";
import S3Service from "../../services/S3Service";
import { useService } from "../hooks/useService";
import { getFileName } from "../helpers/getFileName";

const FormikDropzone = ({ name, label }) => {
  const [, { error, touched }, { setValue }] = useField({ name });
  const [currentFile, updateCurrentFile] = useState(null);
  const imageService = useService(S3Service);

  const handleChange = useCallback(
    async (file) => {
      const data = await imageService.uploadImageFile(file);
      updateCurrentFile(getFileName(file));
      const fileId = data[0]?.file?.id;

      if (!fileId) return;
      setValue(fileId);
    },
    [updateCurrentFile]
  );

  const handleRemoveFile = useCallback(() => {
    updateCurrentFile(null);
    setValue(null);
  }, [updateCurrentFile]);

  return (
    <>
      <FormGroup className="mb-3">
        {label && (
          <label
            htmlFor={name}
            className={classNames({ "text-danger": error && touched })}
          >
            {label}
          </label>
        )}
        <DropzoneFile onReciveFile={handleChange} />
      </FormGroup>
      <AttachedFile fileName={currentFile} onRemove={handleRemoveFile} />
    </>
  );
};

FormikDropzone.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onSelectedFile: PropTypes.func,
};

export default FormikDropzone;
