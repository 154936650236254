export const ENV = {
  GOOGLE_CLIENT_ID:
    process.env.REACT_APP_GOOGLE_CLIENT_ID ||
    "693389261153-233rsiuqs3dldhatcs8vg1elp5eg94ee.apps.googleusercontent.com",
  LANDING_APP_DOMAIN:
    process.env.REACT_APP_LANDING_DOMAIN || "https://dev.fearn.co",
  STRIPE_APP:
    process.env.REACT_APP_STRIPE_PK ||
    "pk_test_51IxHXhLZ9pCsnaduSq3F93zpTpwMsuRZSfYKweZrDfGE83R7jlJLItsvGjyDpZayUn5jBB2j80LAgo4HUPg7PClx00ETPYPARk",
  SOCKET_APP: process.env.REACT_APP_SOCKET_URL || "wss://api.dev.fearn.co",
  BASIC_CATEGORIES_URL:
    process.env.REACT_APP_BASIC_CATEGORIES_URL ||
    "https://gomi-bucket-dev.s3.eu-central-1.amazonaws.com",
  IS_DEV: process.env.NODE_ENV === "development",
  IS_CSRF_ENABLED: process.env.REACT_APP_IS_CSRF_ENABLED || false,
  CSRF_TOKEN_NAME: process.env.REACT_APP_CSRF_TOKEN_NAME || "CSRF-TOKEN",
  CSRF_HEADER_NAME: process.env.REACT_APP_CSRF_HEADER_NAME || "X-CSRF-TOKEN",
};
