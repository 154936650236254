import { useEffect, useState } from "react";

import EbayService from "../../../services/EbayService";

import { useFormatEbayDomesticShippingServices } from "./useFormatEbayDomesticShippingServices";
import Bugsnag from "@bugsnag/js";

export const useEbayCalculatedDomesticShippingServices = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [calculatedShippingServices, setCalculatedShippingServices] = useState(
    []
  );

  const { getFormattedServices, filterDomesticServices } =
    useFormatEbayDomesticShippingServices();

  const getCalculatedDomesticShippingServices = async () => {
    try {
      setIsLoading(true);

      new EbayService().getShippingData().then((response) => {
        const domesticServices = filterDomesticServices(response);
        const calculatedServices = filterCalculatedServices(domesticServices);
        const formattedCalculatedDomesticServices =
          getFormattedServices(calculatedServices);

        setCalculatedShippingServices(formattedCalculatedDomesticServices);
      });
    } catch (error) {
      Bugsnag.notify(error);
    } finally {
      setIsLoading(false);
    }
  };

  const filterCalculatedServices = (data) => {
    const flatServices = data.filter(
      (service) => service.serviceType?.indexOf("Calculated") !== -1
    );

    return flatServices;
  };

  useEffect(() => {
    getCalculatedDomesticShippingServices();
  }, []);

  return [calculatedShippingServices, isLoading];
};
