import React from "react";
import classNames from "classnames";
import { Container } from "reactstrap";
import PropTypes from "prop-types";

import PageMeta from "../MetaTags";
import TitleButtonPage from "../TitleButtonPage";
import VerticalTabs from "../VerticalTabs";

const VerticalPageWrapper = ({
  title = "Returns",
  subTitle,
  tabs = [],
  className,
  titleMeta,
  buttonText,
  children,
  onClick,
  hasButton,
  tabChildren,
  data
}) => {
  return (
    <div className={classNames(className, "page-content")}>
      <Container fluid>
        <PageMeta title={titleMeta} />
        <TitleButtonPage
          title={title}
          subTitle={subTitle}
          onClick={onClick}
          buttonText={buttonText}
          hasButton={hasButton}
          data={data}
        />
      </Container>
      <Container fluid>
        {!!tabs.length && (
          <VerticalTabs tabs={[...tabs]} className="mb-3" data={data} >
            {tabChildren}
          </VerticalTabs>
        )}
        {children}
      </Container>
    </div>
  );
};

VerticalPageWrapper.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  tabs: PropTypes.array,
  className: PropTypes.string,
  titleMeta: PropTypes.string,
  buttonText: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  hasButton: PropTypes.bool,
  tabChildren: PropTypes.any,
};

export default VerticalPageWrapper;
