import Cookies from 'js-cookie';
import { ENV } from '../../base/constants/env';

export const csrfRequestInterceptor = (request) => {
    if(ENV.IS_CSRF_ENABLED) {
        const csrfToken = Cookies.get(ENV.CSRF_TOKEN_NAME);
        if (csrfToken) {
            request.headers[ENV.CSRF_HEADER_NAME] = csrfToken;
        }
    }
    return request;
}
  