import { INVENTORY_STATUSES } from "../../inventory/pages/EditInventory/constants";

import PoshmarkService from "../../../services/PoshmarkService";
import { FORM_TYPES } from "../const/form";
import MercariExtension from "../../../services/MercariExtension";
import MercariService from "../../../services/MercariService";

export const USER_SUBSCRIPTION_TYPES = {
  trial: 1,
  standard: 2,
  business: 3,
  unlimited: 4,
};

export const MAX_DRAFT_COUNT = {
  trial: null,
  standard: 50,
  business: 100,
  unlimited: null,
};

export const MAX_LISTING_COUNT = {
  trial: null,
  standard: 100,
  business: 500,
  unlimited: null,
};

export const AVAILABLE_DRAFT = {
  [USER_SUBSCRIPTION_TYPES.trial]: MAX_DRAFT_COUNT.trial,
  [USER_SUBSCRIPTION_TYPES.standard]: MAX_DRAFT_COUNT.standard,
  [USER_SUBSCRIPTION_TYPES.business]: MAX_DRAFT_COUNT.business,
  [USER_SUBSCRIPTION_TYPES.unlimited]: MAX_DRAFT_COUNT.unlimited,
};

export const AVAILABLE_LISTING = {
  [USER_SUBSCRIPTION_TYPES.trial]: MAX_LISTING_COUNT.trial,
  [USER_SUBSCRIPTION_TYPES.standard]: MAX_LISTING_COUNT.standard,
  [USER_SUBSCRIPTION_TYPES.business]: MAX_LISTING_COUNT.business,
  [USER_SUBSCRIPTION_TYPES.unlimited]: MAX_LISTING_COUNT.unlimited,
};

export const PRODUCT_STATUSES = {
  [INVENTORY_STATUSES.listed]: "Listed",
  [INVENTORY_STATUSES.notListed]: "Not listed",
  [INVENTORY_STATUSES.delisted]: "Delisted",
};

export const PRODUCT_STATUS_METHODS = {
  relist: "relist",
  sold: "sold",
  delist: "delist",
  unmerge: "unmerge",
};

export const ORDER_BY = {
  desc: "desc",
  asc: "asc",
};

export const ORDER_BY_LABEL = "orderBy";
export const ORDER_BY_DATE_LISTED_NAME = "firstListedAt";
export const ORDER_TYPE_LABEL = "orderType";
export const ORDER_BY_DATE_IMPORTED_NAME = "statusAt";
export const INVENTORY_TABLE_EVENT_TYPE_SORT = "sort";

export const MARKETPLACES_NAMES = {
  ebay: "eBay",
  etsy: "Etsy",
  poshmark: "Poshmark",
  mercari: "Mercari",
  other: "Other",
};

export const MARKETPLACES_NAMES_REQUEST = {
  ebay: FORM_TYPES.EBAY,
  etsy: FORM_TYPES.ETSY,
  poshmark: FORM_TYPES.POSHMARK,
  mercari: FORM_TYPES.MERCARI,
};

export const ACTION_DELIST_MARKETPLACES = {
  [MARKETPLACES_NAMES_REQUEST.poshmark]: (
    id,
    marketplace,
    listingId,
    fetchedData,
    formValues
  ) =>
    new PoshmarkService().delistPoshmarkInventory(
      id,
      marketplace,
      listingId,
      fetchedData,
      formValues
    ),
  [MARKETPLACES_NAMES_REQUEST.mercari]: (_, __, listingId) =>
    new MercariExtension().deleteActiveInventory(listingId),
  [MARKETPLACES_NAMES_REQUEST.ebay]: () => Promise.resolve(),
  [MARKETPLACES_NAMES_REQUEST.etsy]: () => Promise.resolve(),
};

export const ACTION_SOLD_MARKETPLACES = {
  [MARKETPLACES_NAMES_REQUEST.poshmark]: ({
    id,
    marketplace,
    listingId,
    fetchedData,
    formValues,
  }) => {
    return new PoshmarkService().delistPoshmarkInventory(
      id,
      marketplace,
      listingId,
      fetchedData,
      formValues
    );
  },
  [MARKETPLACES_NAMES_REQUEST.mercari]: ({ listingId }) =>
    new MercariExtension().deleteActiveInventory(listingId),
  [MARKETPLACES_NAMES_REQUEST.ebay]: () => Promise.resolve(),
  [MARKETPLACES_NAMES_REQUEST.etsy]: () => Promise.resolve(),
};

export const ACTION_RELIST_MARKETPLACES = {
  [MARKETPLACES_NAMES_REQUEST.poshmark]: (
    id,
    marketplace,
    listingId,
    fetchedData,
    formValues
  ) =>
    new PoshmarkService().relistPoshmarkInventory(
      id,
      marketplace,
      listingId,
      fetchedData,
      formValues
    ),
  [MARKETPLACES_NAMES_REQUEST.mercari]: (
    id,
    marketplace,
    listingId,
    fetchedData,
    formValues
  ) =>
    new MercariService().relistMercariInventory(
      id,
      marketplace,
      listingId,
      fetchedData,
      formValues
    ),
  [MARKETPLACES_NAMES_REQUEST.ebay]: () => Promise.resolve(),
  [MARKETPLACES_NAMES_REQUEST.etsy]: () => Promise.resolve(),
};

export const MARKETPLACES = {
  ebay: 1,
  etsy: 2,
  poshmark: 3,
  mercari: 4,
};

export const MARKETPLACES_NAMES_REQUEST_BY_ID = {
  [MARKETPLACES.ebay]: "ebay",
  [MARKETPLACES.etsy]: "etsy",
  [MARKETPLACES.poshmark]: "poshmark",
  [MARKETPLACES.mercari]: "mercari",
};

export const MarketplacesMap = {
  1: MARKETPLACES_NAMES.ebay,
  2: MARKETPLACES_NAMES.etsy,
  3: MARKETPLACES_NAMES.poshmark,
  4: MARKETPLACES_NAMES.mercari,
  5: MARKETPLACES_NAMES.other,
};

export const SortingOptions = [
  {
    name: "Date listed",
    value: "firstListedAt",
  },
  {
    name: "Date imported",
    value: "statusAt",
  },
];

export const MarketplacesFilterOptions = Object.entries(MarketplacesMap).map(
  ([key, value]) => ({ name: value, value: parseInt(key, 10) })
);

export const DEFAULT_SIZE_SYSTEM = "us";
export const DEFAULT_TAB_ID = 0;
export const CUSTOM_TAB = "Custom";

export const LABEL_DISCOUNT_7_45 = "Buyer Shipping: $7.45";
export const LABEL_NO_DISCOUNT = "No discount";

export const DRAFT_PRIORITIES = [
  FORM_TYPES.BASE,
  FORM_TYPES.EBAY,
  FORM_TYPES.POSHMARK,
  FORM_TYPES.MERCARI,
  FORM_TYPES.ETSY,
];

export const MARKETPLACES_ACTIVE = [
  FORM_TYPES.EBAY,
  FORM_TYPES.POSHMARK,
  FORM_TYPES.MERCARI,
  FORM_TYPES.ETSY,
];

export const DRAFT_FIELDS = {
  TITLE: {
    [FORM_TYPES.BASE]: "title",
    [FORM_TYPES.EBAY]: "productEbaySpecificFields.title",
    [FORM_TYPES.POSHMARK]: "productPoshmarkSpecificFields.title",
    [FORM_TYPES.MERCARI]: "productMercariSpecificFields.title",
    [FORM_TYPES.ETSY]: "productEtsySpecificFields.title",
  },
  SKU: {
    [FORM_TYPES.BASE]: "sku",
    [FORM_TYPES.EBAY]: "productEbaySpecificFields.sku",
    [FORM_TYPES.POSHMARK]: "productPoshmarkSpecificFields.sku",
    [FORM_TYPES.MERCARI]: "productMercariSpecificFields.sku",
    [FORM_TYPES.ETSY]: "productEtsySpecificFields.sku",
  },
  PRICE: {
    [FORM_TYPES.BASE]: "listingPrice",
    [FORM_TYPES.EBAY]: "productEbaySpecificFields.buyItNowPrice",
    [FORM_TYPES.POSHMARK]: "productPoshmarkSpecificFields.listingPrice",
    [FORM_TYPES.MERCARI]: "productMercariSpecificFields.listingPrice",
    [FORM_TYPES.ETSY]: "productEtsySpecificFields.price",
  },
  CREATED_AT: {
    [FORM_TYPES.BASE]: "createdAt",
    [FORM_TYPES.EBAY]: "productEbaySpecificFields.createdAt",
    [FORM_TYPES.POSHMARK]: "productPoshmarkSpecificFields.createdAt",
    [FORM_TYPES.MERCARI]: "productMercariSpecificFields.createdAt",
    [FORM_TYPES.ETSY]: "productEtsySpecificFields.createdAt",
  },
  STATUS_AT: {
    [FORM_TYPES.BASE]: "statusAt",
    [FORM_TYPES.EBAY]: "productEbaySpecificFields.statusAt",
    [FORM_TYPES.POSHMARK]: "productPoshmarkSpecificFields.statusAt",
    [FORM_TYPES.MERCARI]: "productMercariSpecificFields.statusAt",
    [FORM_TYPES.ETSY]: "productEtsySpecificFields.statusAt",
  },
  FIRST_LISTED_AT: {
    [FORM_TYPES.BASE]: "firstListedAt",
    [FORM_TYPES.EBAY]: "firstListedAt",
    [FORM_TYPES.POSHMARK]: "firstListedAt",
    [FORM_TYPES.MERCARI]: "firstListedAt",
    [FORM_TYPES.ETSY]: "firstListedAt",
  },
  STATUS: {
    [FORM_TYPES.BASE]: "status",
    [FORM_TYPES.EBAY]: "productEbaySpecificFields.status",
    [FORM_TYPES.POSHMARK]: "productPoshmarkSpecificFields.status",
    [FORM_TYPES.MERCARI]: "productMercariSpecificFields.status",
    [FORM_TYPES.ETSY]: "productEtsySpecificFields.status",
  },
  ADDITIONAL_PRICE: {
    [FORM_TYPES.EBAY]: "productEbaySpecificFields.startingPrice",
  },
};

export const SOLD_FIELDS = {
  PRICE_SOLD: {
    [MARKETPLACES_NAMES_REQUEST.poshmark]: "listingPrice",
    [MARKETPLACES_NAMES_REQUEST.etsy]: "price",
    [MARKETPLACES_NAMES_REQUEST.mercari]: "listingPrice",
    [MARKETPLACES_NAMES_REQUEST.ebay]: "buyItNowPrice",
  },
  COST_OF_GOOD: {
    [MARKETPLACES_NAMES_REQUEST.poshmark]: "costPrice",
    [MARKETPLACES_NAMES_REQUEST.etsy]: "costOfItem",
    [MARKETPLACES_NAMES_REQUEST.mercari]: "costOfItem",
    [MARKETPLACES_NAMES_REQUEST.ebay]: "costOfItem",
  },
  TITLE: {
    [MARKETPLACES_NAMES_REQUEST.poshmark]: "title",
    [MARKETPLACES_NAMES_REQUEST.etsy]: "title",
    [MARKETPLACES_NAMES_REQUEST.mercari]: "title",
    [MARKETPLACES_NAMES_REQUEST.ebay]: "title",
  },
  SKU: {
    [MARKETPLACES_NAMES_REQUEST.poshmark]: "sku",
    [MARKETPLACES_NAMES_REQUEST.etsy]: "sku",
    [MARKETPLACES_NAMES_REQUEST.mercari]: "sku",
    [MARKETPLACES_NAMES_REQUEST.ebay]: "sku",
  },
  COLOR: {
    [MARKETPLACES_NAMES_REQUEST.poshmark]: "colorName",
    [MARKETPLACES_NAMES_REQUEST.etsy]: "colorName",
    [MARKETPLACES_NAMES_REQUEST.mercari]: "colorName",
    [MARKETPLACES_NAMES_REQUEST.ebay]: "colorName",
  },
  SHIPPING_COST: {
    [MARKETPLACES_NAMES_REQUEST.poshmark]: "NOT_FOUND",
    [MARKETPLACES_NAMES_REQUEST.etsy]: "NOT_FOUND",
    [MARKETPLACES_NAMES_REQUEST.mercari]: "shippingFee",
    [MARKETPLACES_NAMES_REQUEST.ebay]: "shippingCost",
  },
};

export const EBAY_DRAFT_HIDDEN_VALIDATION = {
  title: { maxValue: 80 },
  desc: { maxValue: 4000 },
  conditionDescription: { maxValue: 1000 },
};

export const coefficientLbToOz = 16;
export const FIXED_PRICE_FORMAT = 2;

export const SUCCESS_RELIST_MESSAGE = "Item was successfully relisted";
export const SUCCESS_DELIST_MESSAGE = "Item was successfully delisted";
export const SUCCESS_UNMERGE_MESSAGE = "Item was successfully unmerged";
export const UNSUCCESS_DELIST_MESSAGE =
  "Something went wrong. Item may have been deleted on this marketplace.";

export const DRAFT_PREV_PATH = "DRAFT_PREV_PATH";
export const INVENTORY_PREV_PATH = "INVENTORY_PREV_PATH";
export const SOLD_PREV_PATH = "SOLD_PREV_PATH";

export const LINK_TO_WEBSTORE =
  "https://chrome.google.com/webstore/detail/fearn-marketplace-connect/dgicjglldniknbjjipebginbhlhnkigh";
