export const PURCHASE_SUBSCRIPTION_DAYS = "28";
export const TRIAL_DAYS = "14";
export const PURCHASE_SUBSCRIPTION_MONTH = 1;

export const TRIAL_TYPE = 1;

export const ACTIVE_STATUS = 1;
export const INACTIVE_STATUS = 2;
export const INCOMPLETE_STATUS = 3;
export const ACTIVE_CANCELED_STATUS = 4;

export const ACTIVE = "active";
export const INACTIVE = "inactive";

export const MAX_DRAFT_STARTER = 50;
export const MAX_DRAFT_BUSINESS = 100;

export const MAX_LISTING_STARTER = 100;
export const MAX_LISTING_BUSINESS = 500;

export const TYPES = {
  TRIAL: 1,
  STARTER: 2,
  BUSINESS: 3,
  UNLIMITED: 4,
  EXPIRED: -1, // Used only localy
  BETA: 7,
};
