export const COUNT_DIGITS = 2;

export const COUNT_DIGITS_0 = 0;
export const COUNT_DIGITS_2 = 2;

export const PERCENTAGE_NUMBER = 100;

export const MONTH_TYPE = "month";

export const ANALYTICS_CUSTOM_DATE_FILTER_TYPES = {
  day: "day",
  week: "week",
  month: "month",
  year: "year",
};

export const MOMENT_SEVEN_DAYS = 7;
export const MOMENT_ONE_MONTH = 1;
export const MOMENT_ONE_YEAR = 1;

export const MOMENT_MIN_DATE = "1970-01-01";
