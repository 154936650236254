import PropTypes from "prop-types";
import React from "react";
import {
  STATUS_POSHMARK_ACTIVE,
  STATUS_POSHMARK_NOT_CONNECTED,
  STATUS_POSHMARK_NOT_INSTALLED,
} from "../../const/poshmark";

const PoshmarkStatus = ({ className, status }) => {
  return (
    <div className={className}>
      <p className="md-title-grey mb-0 text-uppercase">Status:</p>
      {status === STATUS_POSHMARK_NOT_INSTALLED && (
        <p className="regular-red">Not installed</p>
      )}
      {status === STATUS_POSHMARK_NOT_CONNECTED && (
        <p className="regular-red">Not connected</p>
      )}
      {status === STATUS_POSHMARK_ACTIVE && (
        <p className="regular-green">Active</p>
      )}
    </div>
  );
};

PoshmarkStatus.propTypes = {
  status: PropTypes.string,
  className: PropTypes.string,
};

export default PoshmarkStatus;
