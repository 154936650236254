import { useEffect, useState } from "react";
import { ONE_THOUSAND_MILLISECONDS } from "../constants/connectButton";

export const useDraftConnectionBadget = () => {
  const [showConnectBadget, setShowConnectBadget] = useState(false)
  const handleD = () => {
    setShowConnectBadget(true)
  }
  useEffect(()=>{
    const timeoutID = setTimeout(handleD, ONE_THOUSAND_MILLISECONDS)
    return function cleanup() {
      clearTimeout(timeoutID)
    }
  }, [])
  return {showConnectBadget}
}