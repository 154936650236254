import { MARKETPLACE_TYPES } from "../../drafts/hooks/useProductStatus/components/SoldModal/constants";
import { calcEtsyFees } from "../../drafts/helpers/Etsy/fees";


class EtsyProduct {
  constructor({
                priceSold,
                listingId,
                dateSold,
                buyer,
                paymentMethod
              }) {

    this.data = {
      buyer,
      priceSold,
      listingId,
      dateSold,
      paymentMethod,
      transactionFees: parseFloat(calcEtsyFees(priceSold, paymentMethod)),
      marketplaceType: MARKETPLACE_TYPES.etsy
    };
  }

  loadAdditionalFields() {
    return Promise.resolve();
  }
}

export default EtsyProduct;
