import {
  EXTENSION_MARKETPLACES,
  FORM_SPECIFIC_FIELDS_TYPES,
} from "../../drafts/const/form";

export const getPostedMarketplacesViaExtension = (
  formsData,
  currentMarketplace
) => {
  return EXTENSION_MARKETPLACES.filter((marketplaceName) => {
    if (
      !formsData[FORM_SPECIFIC_FIELDS_TYPES[marketplaceName]].link ||
      marketplaceName === currentMarketplace
    )
      return;

    return marketplaceName;
  });
};
