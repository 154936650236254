import Http from "./Http";
import { awaitQueueAdapter, WAIT_GROUPS } from "./adapters/queueAdapter";
import axios from "axios";

export const axiosAdapter = axios.defaults.adapter;

class SoldService extends Http {
  static $displayName = "SoldService";

  async getProducts(params) {
    return await this.get("/products/orders", { params });
  }

  async getProduct(id) {
    return await this.get(`/products/orders/${id}`);
  }

  async reactivate(id, values = { isSetHidden: true }) {
    return await this.delete(`/products/orders/${id}`);
  }

  async updateSold(id, values) {
    return await this.put(`/products/orders/${id}`, values);
  }

  async returnSold(id) {
    return await this.patch(`/products/${id}`);
  }

  copySold(id, values = { isSetHidden: false }) {
    return this.patch(`/products/orders/${id}`, values);
  }

  importSold(values) {
    return this.post(`/products/orders/import`, values, {
      adapter: awaitQueueAdapter(axiosAdapter, WAIT_GROUPS.CREATE_ORDERS),
    });
  }
}

export default SoldService;
