
const onErrorDefault = (err) => {
  console.log(err);
  return null
};

class QueuePromises {

  constructor() {
    this.queue = Promise.resolve();
  }

  add(callback, onError = onErrorDefault) {
    this.queue = this.queue
      .then(callback)
      .catch(onError);

    return this;
  }

  getPromise () {
    return this.queue
  }

  finally(callback) {
    return this.queue.finally(callback);
  }
}

export default QueuePromises;
