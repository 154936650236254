import React from "react";
import { Row, Col } from "reactstrap";
import ListToDo from "../../components/ListToDo/ListToDo";
import AnalyticsMonth from "../../components/AnalyticsMonth/AnalyticsMonth";
import AnalyticsToday from "../../components/AnalyticsToday/AnalyticsToday";

const DashboardStoreOwner = () => {
  return (
    <>
      <Row>
        <Col>
          <AnalyticsMonth />
        </Col>
      </Row>
      <>
      <Row>
        <Col>
          <AnalyticsToday />
        </Col>
        <Col>
          <ListToDo />
        </Col>
      </Row>
      </>
    </>

  );
};

export default DashboardStoreOwner;
