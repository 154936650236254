/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

import { SearchInput } from "../../../../../pages/drafts/components/Search";
import CustomButton from "../../../CustomButton";
import ShopNameComponent from "./components/ShopNameComponent";
import styles from "./index.module.scss";

const ImportHeader = ({
  onChangeSearch,
  onStartImportAll,
  isShopNameComponent,
  shopName,
  children = null,
}) => {
  const [searchValue, setSearchValue] = useState("");

  const handleInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const onSearch = () => {
    onChangeSearch(searchValue);
  };

  return (
    <Row className="mb-3">
      <div className={"mb-2"}>{children}</div>
      <Col>
        <form
          className={styles.searchWrapper}
          onSubmit={(event) => event.preventDefault()}
        >
          <SearchInput
            value={searchValue}
            onChange={handleInputChange}
            placeholder={
              <span className="regular-grey">
                Search by&nbsp;
                <span className="bold-sm-gray">Title</span>
              </span>
            }
          />
          <button
            onClick={onSearch}
            className={"filled-primary " + styles.searchButton}
          >
            Search
          </button>
          {isShopNameComponent && (
            <ShopNameComponent title="Shop name" subtitle={shopName} />
          )}
        </form>
      </Col>
      <Col className="d-flex justify-content-end">
        <CustomButton onClick={onStartImportAll} className="filled-primary">
          Import all
        </CustomButton>
      </Col>
    </Row>
  );
};

ImportHeader.propTypes = {
  search: PropTypes.string,
  onChangeSearch: PropTypes.func,
  onStartImportAll: PropTypes.func,
  isShopNameComponent: PropTypes.bool,
  shopName: PropTypes.string,
};

export default ImportHeader;
