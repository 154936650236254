import * as yup from "yup";

export const validationSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string(),
  email: yup.string().email().required(),
  phone: yup.number().required(),
  typeOfProblem: yup.string().required(),
  problemDescription: yup.string().required(),
  multiSelectField: yup.array(),
  file: yup.string(),
});

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  typeOfProblem: "",
  problemDescription: "",
  multiSelectField: [],
  file: "",
};
