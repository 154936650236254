import React from "react";
import PropTypes from "prop-types";

import Icon from "../../../../../../base/components/Icon";

const ButtonText = ({ children, iconClassName, iconType }) => {
  return (
    <div>
      {children}
      {iconType && <Icon className={iconClassName} icon={iconType} />}
    </div>
  );
};

ButtonText.propTypes = {
  children: PropTypes.any,
  iconClassName: PropTypes.string,
  iconType: PropTypes.string,
};

export default ButtonText;
