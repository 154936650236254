/* eslint-disable react/prop-types */
import React, { useCallback, useRef } from "react";
import { AutoSizer, List, WindowScroller } from "react-virtualized";

export const VirtualTable = ({
  calcRowHeight,
  data,
  renderRow,
  customElement,
  getListRef,
}) => {
  const refList = useRef(null);

  const getRow = useCallback(
    (index) => {
      return data[index];
    },
    [data]
  );

  const getRowHeight = useCallback(
    ({ index }) => {
      return calcRowHeight(getRow(index));
    },
    [getRow, calcRowHeight]
  );

  const onMountRef = useCallback(
    (el) => {
      getListRef(el);
    },
    [getListRef]
  );

  const setOverScannedIndexes = ({
    cellCount,
    overscanCellsCount,
    startIndex,
    stopIndex,
  }) => {
    return {
      overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
      overscanStopIndex: Math.min(
        cellCount - 1,
        stopIndex + overscanCellsCount
      ),
    };
  };

  return (
    <>
      <WindowScroller ref={refList} scrollElement={customElement}>
        {({ height, isScrolling, registerChild, onChildScroll, scrollTop }) => (
          <AutoSizer disableHeight>
            {({ width }) => (
              <>
                <div
                  ref={registerChild}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <List
                    style={{
                      height: "100%",
                    }}
                    ref={onMountRef}
                    autoHeight
                    height={height + 1000 || 100}
                    width={width || 100}
                    isScrolling={isScrolling}
                    onScroll={onChildScroll}
                    overscanRowCount={15}
                    overscanIndicesGetter={setOverScannedIndexes}
                    rowCount={data.length}
                    rowHeight={getRowHeight}
                    rowRenderer={renderRow}
                    scrollTop={scrollTop}
                  />
                </div>
              </>
            )}
          </AutoSizer>
        )}
      </WindowScroller>
    </>
  );
};
