import React from 'react'
import Button from '../../../base/components/Button';
import {ENV} from "../../../base/constants/env";

const BlockedButton = () => {
    const contactUsLink = ENV.LANDING_APP_DOMAIN
  return (
		<Button className='filled-primary' onClick={() => {
            window.open(`${contactUsLink}/contact-us/`, '_blank')
        }}>
      Contact us
    </Button>
  )
};

export default BlockedButton;
