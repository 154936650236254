import React from "react";
import PageMeta from "../../base/components/MetaTags";
import PageTitle from "../profile/components/PageName";
import VerticalTabs from "../../base/components/VerticalTabs";
import { Container } from "reactstrap";
import { ANALYTICS_GROUP_LINKS, ANALYTICS_TABS } from "./config";
import { Redirect, Route, Switch } from "react-router-dom";
import MonthlyOverview from "./pages/MontlyOverview";
import SoldInventory from "./pages/SoldInventory";
import AvailableInventory from "./pages/AvailableInventory"
import SalesPerPlatform from "./pages/SalesPerPlatform";


const Analytics = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <PageMeta title="Analytics" />
        <PageTitle title="ANALYTICS" />
        <VerticalTabs tabs={ANALYTICS_TABS} />
        <Switch>
          <Route
            path={ANALYTICS_GROUP_LINKS.MONTHLY_OVERVIEW}
            component={MonthlyOverview}
            exact
          />
          <Route
            path={ANALYTICS_GROUP_LINKS.SALES_PLATFORM}
            component={SalesPerPlatform}
            exact
          />
          <Route
            path={ANALYTICS_GROUP_LINKS.SOLD_INVENTORY}
            component={SoldInventory}
            exact
          />
          <Route
            path={ANALYTICS_GROUP_LINKS.AVAILABLE_INVENTORY}
            component={AvailableInventory}
            exact
          />
          <Redirect to={ANALYTICS_GROUP_LINKS.MONTHLY_OVERVIEW} />
        </Switch>
      </Container>
    </div>
  );
};

export default Analytics;