import React, {useCallback, useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import FormikSingleSelect from "../../../../../../base/components/FormikSingleSelect";
import { useTriggerFormChanges } from "../../../../hooks/useDetectDirtyForm";

const FormPoshmarkColors = ({values, colors, setFieldValue}) => {

    const { markChanges, containerRef } = useTriggerFormChanges();

    const [colorsOptions, updateColorsOptions] = useState([])
    const [secondaryColorsOptions, updateSecondaryColorsOptions] = useState([])

    const colorsToOption = useCallback((colors) => {
        const colorsOptions = colors.map((color) => (
            {
                ...color,
                id: color.rgb,
                label: color.name
            }
        ))

        updateColorsOptions(colorsOptions)
    }, [updateColorsOptions])
    useEffect(() => {
        colorsToOption(colors)
    }, [colors])

    useEffect(() => {
        updateSecondaryColorsOptions(colorsOptions.filter(option => option.id !== values.primaryColor))
    }, [values.primaryColor, colorsOptions, updateSecondaryColorsOptions])

    const resetSecondaryColor = useCallback(() => {
        setFieldValue("secondaryColor", '')
    }, [values.primaryColor, setFieldValue])

    useEffect(() => {
        const primaryColor = colorsOptions?.find(color => color.id === values.primaryColor)
        const secondaryColor = colorsOptions?.find(color => color.id === values.secondaryColor)
        const colors = [primaryColor?.label, secondaryColor?.label]
        const filteredColors = colors.filter(Boolean);
        setFieldValue("colors", filteredColors)
        if (primaryColor?.label) {
            setFieldValue("colorName", primaryColor?.label)
        }
    }, [values.primaryColor, values.secondaryColor, colorsOptions])

    return (
        <Row className="section-wrapper">
            <Col md={4}>
                <h3 className='bold-black mb-1'>
                    Color
                </h3>
                <p className="text-gray-gomi">
                    Select up to 2 colors
                </p>
            </Col>
            <Col md={8}>
                <Row>
                    <Col md={6}>
                        <FormikSingleSelect
                            name="primaryColor"
                            options={colorsOptions}
                            placeholder="Choose primary  color"
                            disabled={!colors.length}
                            onChange={resetSecondaryColor}
                        />
                    </Col>
                    <Col md={6}>
                        <FormikSingleSelect
                            name="secondaryColor"
                            options={secondaryColorsOptions}
                            placeholder="Choose secondary color"
                            disabled={!(values.primaryColor && colors.length)}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default FormPoshmarkColors;
