import React from "react";
import { Card, CardBody } from "reactstrap";

const TaskPlaceholder = () => {
  return (
    <Card>
      <CardBody className="returned-placeholder">
        <h3 className="bold-black">No tasks for now.</h3>
      </CardBody>
    </Card>
  );
};

export default TaskPlaceholder;
