import React, { memo, useEffect } from "react";
import InfiniteScrollWrapper from "../../../../base/components/InfiniteScrollWrapper";

import ReturnCard from "../../components/ReturnCard";
import ReturnPlaceholder from "../../components/ReturnPlaceholder";

import {
  COMPLETED_TAB,
  RETURN_STATUSES,
} from "../../constants/returns-constants";
import { useGetReturnsList } from "../../hooks/useGetReturnsList";
import { noop } from "lodash/util";
import { useRelistReturnProduct } from "../../hooks/useRelistReturnProduct";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { DRAFTS_GROUP_LINKS } from "../../../drafts/config";
import Placeholder from "../../../inventory/components/Placeholder";
import emptyReturnsIcon from "../../images/returns-empty.png";
import CreateButton from "../../components/CreateReturnButton";
import WhiteBox from "../../../drafts/components/WhiteBox";

const ReturnsCompletedList = ({
  getCount = noop,
  onMountList = noop,
  updateTabs,
  onCreateButtonClick
}) => {
  const { data, hasMore, handleFetch, isLoading, refresh, totalCount } =
    useGetReturnsList(RETURN_STATUSES.RETURNED);
  const history = useHistory();

  const afterRelist = useCallback(
    ({ id }) => {
      history.push(DRAFTS_GROUP_LINKS.VIEW_DRAFT.replace(":draftId", id));
    },
    [history]
  );

  const [onRelist, relistModalView, isDisabledRelist] = useRelistReturnProduct({
    afterRelist,
  });

  useEffect(() => {
    onMountList({ refresh });
  }, [onMountList, refresh]);

  useEffect(() => {
    getCount({ [COMPLETED_TAB]: totalCount });
  }, [totalCount]);

  return (
    <>
      <InfiniteScrollWrapper hasMore={hasMore} fetchData={handleFetch} hasInfiniteLoader={!!data?.length}>
        {isLoading ? (
          <WhiteBox>
            <p>Loading...</p>
          </WhiteBox>
        ) : !data?.length && <Placeholder
          text={`To create a new return, click "Create return". Or you can mark a sold item as returned on the Sold page.`}
          imgSrc={emptyReturnsIcon}
          onCreateButtonClick={onCreateButtonClick}
          CreateButton={CreateButton}
        />}
        {data.map((returnItem) => (
          <ReturnCard
            key={returnItem.id}
            onRefresh={refresh}
            {...returnItem}
            updateTabs={updateTabs}
            onRelist={onRelist}
            isDisableRelist={ isDisabledRelist}
          />
        ))}
      </InfiniteScrollWrapper>
      {relistModalView}
    </>
  );
};

export default memo(ReturnsCompletedList);
