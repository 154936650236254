import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router";
import { Container } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import PageTitleBreadcrumb from "../../../../base/components/PageTitleBreadcrumb";
import VerticalTabs from "../../../../base/components/VerticalTabs";
import PreventClosePage from "../../../drafts/components/PreventClosePage";
import CreateDraftEtsy from "../../../drafts/pages/CreateDraft/pages/Etsy";
import EditDraftBasic from "../../../drafts/pages/EditDraft/pages/Basic";
import EditDraftEbay from "../../../drafts/pages/EditDraft/pages/Ebay";

import { useService } from "../../../../base/hooks/useService";
import DraftsService from "../../../../services/DraftsService";
import { useSaveDraft } from "../../../drafts/hooks/useSaveSubmit";
import {
  DRAFT_UPDATE_SUCCESS,
  INVENTORY_UPDATE_SUCCESS,
} from "../../../../base/constants/messages";
import { useDetectFormChanges } from "../../../drafts/hooks/useDetectDirtyForm";
import { CREATE_DRAFT_LINKS } from "../../../drafts/pages/CreateDraft/config";
import FormPartials from "../../../drafts/classes/FormPartials";
import { EDIT_INVENTORY_LINKS, INVENTORY_GROUP_LINKS } from "../../config";
import { usePostedInventory } from "./hooks/usePostedInventory";
import CreateDraftPoshmark from "../../../drafts/pages/CreateDraft/pages/Poshmark";
import CreateDraftMercari from "../../../drafts/pages/CreateDraft/pages/Mercari";
import {
  INVENTORY_PREV_PATH,
  MARKETPLACES_NAMES_REQUEST,
} from "../../../drafts/constants";
import { usePrevLocation } from "../../../../base/hooks/usePrevLocation";
import { useTotalProducts } from "../../../drafts/hooks/useProduct";
import { PRODUCT_TYPE } from "../../../../base/constants/product";
import { useProfile } from "../../../profile/hooks/useProfile";
import Permissions from "../../../../base/helpers/Permission";
import { Loading } from "../../../drafts/pages/EditDraft";
import { useLoadProduct } from "../../../drafts/hooks/useLoadProduct";
import { useLoading } from "../../../../base/hooks/useLoading";
import FormResources from "../../../../services/FormResources";
import { getInitialEmptyForm } from "../../../drafts/pages/Settings/utils/getInitialEmptyForm";
import { UNPROCESSABLE_ERROR } from "../../../../base/constants/statusCodes";
import ToasterService from "../../../../services/ToastService";

const INVENTORY_BREADCRUMBS = [
  { title: "Inventory", link: INVENTORY_GROUP_LINKS.BASE },
  { title: "Inventory details", link: "#" },
];

const EditInventory = () => {
  const {
    params: { draftId },
  } = useRouteMatch();
  const initialFormValues = getInitialEmptyForm();

  const [fullFormData, updateFullForm] = useState(initialFormValues);
  const [fetchedData, setFetchedData] = useState({});
  const [isLoading, { registerPromise }] = useLoading();
  const { url } = useRouteMatch();
  const { containerRef, dirty, cancel } = useDetectFormChanges();
  const [{ total }] = useTotalProducts(PRODUCT_TYPE.DRAFT);
  const [{ activeSubscription: { type } = {} }] = useProfile();
  const [formLoading, setFormLoading] = useState(false);
  const toastr = useService(ToasterService);

  const isDelistBtnDisabled = Permissions.disableCreateDraft(type, total);

  const { getEditInventoryTabs } = usePostedInventory({
    inventoryData: fetchedData,
  });

  const onAfterUpdate = (model) => {
    return draftService
      .updateDraft(draftId, model)
      .then((data) => {
        cancel();
        return data;
      })
      .catch((e) => {
        if (e?.error?.code === UNPROCESSABLE_ERROR) {
          toastr.error(e?.error?.message);

          return Promise.reject(e.error);
        }

        Bugsnag.notify(e);
      });
  };

  const onSave = useSaveDraft(
    onAfterUpdate,
    INVENTORY_GROUP_LINKS.BASE,
    INVENTORY_UPDATE_SUCCESS
  );

  const onSaveAndCreateNew = useSaveDraft(
    onAfterUpdate,
    CREATE_DRAFT_LINKS.BASIC,
    DRAFT_UPDATE_SUCCESS
  );

  const onHandleDelete = useCallback(() => {
    cancel();
  }, [cancel]);

  const toggleStatus = useCallback((marketplace, newStatus) => {
    const specificField = {
      [MARKETPLACES_NAMES_REQUEST.ebay]: FormPartials.SpecificFields.Ebay,
      [MARKETPLACES_NAMES_REQUEST.etsy]: FormPartials.SpecificFields.Etsy,
      [MARKETPLACES_NAMES_REQUEST.poshmark]:
        FormPartials.SpecificFields.Poshmark,
      [MARKETPLACES_NAMES_REQUEST.mercari]: FormPartials.SpecificFields.Mercari,
    }[marketplace];

    if (!specificField) return;

    setFetchedData((form) => {
      form[specificField].status = newStatus;

      return { ...form };
    });
  }, []);

  const loadLatest = useCallback(() => {
    registerPromise(
      FormResources.waitResources([
        ...FormResources.prefetchFormResources(),
        loadProduct({
          setFetchedData,
          updateFullForm,
        })
          .then(cancel)
          .catch((e) => Bugsnag.notify(e))
          .finally(cancel),
      ])
    );
  }, []);

  const commonProps = {
    onSaveAndCreateNew,
    onSave,
    updateFullForm,
    fullFormData,
    fetchedData,
    onHandleDelete,
    isDelistBtnDisabled,
    isDirty: dirty,
    toggleStatus,
    setFormLoading,
    loadLatest,
  };

  /**
   * @type {DraftsService}
   */
  const draftService = useService(DraftsService);

  const loadProduct = useLoadProduct(draftId);

  useEffect(() => {
    loadLatest();
  }, [cancel, registerPromise, loadProduct, setFetchedData, updateFullForm]);

  const editTabs = useMemo(
    () => getEditInventoryTabs(url),
    [url, getEditInventoryTabs]
  );

  const { prevPath } = usePrevLocation(INVENTORY_PREV_PATH);

  useEffect(() => {
    return () => {
      updateFullForm(initialFormValues);
    };
  }, []);

  return (
    <Container fluid>
      <div ref={containerRef}>
        <PageTitleBreadcrumb
          title="INVENTORY DETAILS"
          breadcrumbItems={INVENTORY_BREADCRUMBS}
          onBackPath={prevPath.current || INVENTORY_GROUP_LINKS.BASE}
        />
        <VerticalTabs tabs={editTabs} isLoading={isLoading || formLoading} />

        {isLoading ? (
          <Loading />
        ) : (
          <>
            <PreventClosePage
              isBlocked={dirty}
              allowPath={INVENTORY_GROUP_LINKS.DETAILS_VIEW.replace(
                ":draftId",
                draftId
              )}
            />
            <Switch>
              <Route path={EDIT_INVENTORY_LINKS.BASIC} exact>
                <EditDraftBasic {...commonProps} />
              </Route>
              <Route path={EDIT_INVENTORY_LINKS.EBAY} exact>
                <EditDraftEbay {...commonProps} />
              </Route>
              <Route path={EDIT_INVENTORY_LINKS.ETSY} exact>
                <CreateDraftEtsy {...commonProps} />
              </Route>
              <Route path={EDIT_INVENTORY_LINKS.POSHMARK} exact>
                <CreateDraftPoshmark {...commonProps} />
              </Route>
              <Route path={EDIT_INVENTORY_LINKS.MERCARI} exact>
                <CreateDraftMercari {...commonProps} />
              </Route>
              <Redirect to={EDIT_INVENTORY_LINKS.BASIC} />
            </Switch>
          </>
        )}
      </div>
    </Container>
  );
};

export default EditInventory;
