class SizeUtils {

    static setSize = ({setFieldValue, tab, system, id}) => {
        setFieldValue("sizeId", tab + "," + system + "," + id)
    }

    static getSize = (size) => {
        if (!size) return []
        return size.split(",")
    }

}

export default SizeUtils;