import React, { useCallback } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useField } from "formik";
import CustomInput from "./Input";
import { useCustomError } from "../contexts/CustomErrorMessage";
import ErrorMessageDefault from "./ErrorMessageDefault";
import {
  DEFAULT_MAX_NUMBER,
  DEFAULT_MIN_NUMBER,
  DEFAULT_STEP,
} from "../constants/forms";
import { getCutPrice } from "../helpers/getCutPrice";
import { useTranslate } from "../hooks/useTranslate";

const FormikNumber = ({
  placeholder,
  name,
  type,
  maxLength,
  readOnly,
  label,
  disabled,
  containerClassName,
  onChange = () => {},
  ErrorMessage = ErrorMessageDefault,
  min = DEFAULT_MIN_NUMBER,
  max = DEFAULT_MAX_NUMBER,
  step = DEFAULT_STEP,
  onKeyPress,
}) => {
  const [field, meta, { setValue }] = useField({ name, type, placeholder });
  const isError = meta.error && meta.touched;
  const [translate] = useTranslate();

  const { getError } = useCustomError();
  const errorMessage = getError(name);

  const handleOnChange = useCallback(
    (event) => {
      if (!onChange) {
        const {
          target: { value },
        } = event;
        setValue(getCutPrice(value));
        return;
      }

      onChange(event, setValue);
    },
    [setValue, onChange]
  );

  return (
    <section className={classnames(containerClassName, "custom-form-group")}>
      <label className={isError || errorMessage ? "text-danger" : ""}>
        {label}
      </label>
      <CustomInput
        type="number"
        {...field}
        onChange={handleOnChange}
        className={classnames({
          "is-invalid-input is-invalid": isError || errorMessage,
        })}
        maxLength={maxLength}
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        step={step}
        min={min}
        max={max}
        onKeyPress={onKeyPress}
      />
      <ErrorMessage name={name}>
        {translate(meta.error, { label })}
      </ErrorMessage>
    </section>
  );
};

FormikNumber.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  ErrorMessage: PropTypes.any,
  disabled: PropTypes.bool,
  step: PropTypes.string,
  onKeyPress: PropTypes.func,
};

export default FormikNumber;
