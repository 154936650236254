import moment from "moment";

import { toNumber } from "../../sold/helpers";

export class PoshmarkHelpers {
  static getOrderFields(order) {
    const { seller_shipping_discount_amount, pm_fee_amount } = order;

    const data = {
      isSold: true,
      buyer: order.buyer?.username || order.buyer?.full_name || "",
      dateSold: PoshmarkHelpers.convertDate(
        order.inventory_booked_at || order.inventory?.status_changed_at
      ),
      priceSold: toNumber(order.total_price_amount?.val),
    };

    data.marketplaceFees = pm_fee_amount?.val;

    if (seller_shipping_discount_amount?.val) {
      // data.discountedShipping = seller_shipping_discount_amount?.val;
      data.shippingExpenses = seller_shipping_discount_amount?.val;
    }

    return data;
  }

  static convertDate(date) {
    return moment(date).toISOString();
  }
}
