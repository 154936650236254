import { useField } from "formik";
import { useCallback } from "react";

export const useFormikSelect = (name, onChange) => {
  const [
    { value }, 
    { error, touched }, 
    { setValue, setTouched }
  ] = useField({ name });

  const isError = error && touched;

  const handleChange = useCallback(
    (value) => {
      if (!value) {
        setValue("");
        setTouched(true);
        return;
      }

      const creatableValue = value.value;
      const newValue = onChange ? onChange(value) : value;

      setValue(creatableValue || newValue);
    },
    [onChange, setValue, setTouched]
  );

  const handleBlur = useCallback(() => {
    if (!value) {
      setValue("");
      setTouched(true);
    }
  }, [value, setValue, setTouched]);

  return { isError, handleChange, handleBlur, error };
};