/* eslint-disable react/prop-types */
import React from "react";
import styles from "../../index.module.scss";
import CardMergeItem from "../MergeItem";

const MergeItems = ({
  items,
  selectedItem,
  onSelectItem,
  selectedMarketplacesMap,
  checkIsNotAvailableToMerge,
  searchHighlight,
  lastItem = { lastItem },
}) => {
  return (
    <div className={styles.cardMergeContainer}>
      {!items?.length && (
        <p className="bold-gray card-import-container__no-matches">
          No matches are found.
        </p>
      )}
      {items.map((item, index) => {
        return (
          <CardMergeItem
            key={item.id}
            item={item}
            lastItem={items.length === index + 1 ? lastItem : null}
            selectedItem={selectedItem}
            onSelectItem={onSelectItem}
            selectedMarketplacesMap={selectedMarketplacesMap}
            checkIsNotAvailableToMerge={checkIsNotAvailableToMerge}
            searchHighlight={searchHighlight}
          />
        );
      })}
    </div>
  );
};

export default MergeItems;
