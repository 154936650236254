/* eslint-disable react/prop-types */
import React from "react";
import { Col, Row } from "reactstrap";

import FormikInput from "../../../../../base/components/FormikInput";
import FormikTextarea from "../../../../../base/components/FormikTextarea";
import {
  MAX_DESCRIPTION_BASE_LENGTH,
  MAX_TITLE_LENGTH,
} from "../../../../../validation/lengthConstants";

const FormTitleAndDesc = ({
  titleLabel = "Title",
  descLabel = "Description",
}) => {
  return (
    <>
      <Row className="mb-4">
        <Col md="6">
          <FormikInput
            id="title"
            name="title"
            label={titleLabel}
            containerClassName="mb-3"
            placeholder="Enter item title"
            type="text"
            maxLength={MAX_TITLE_LENGTH}
            isShowLengthDescription
          />
        </Col>
      </Row>

      <FormikTextarea
        id="desc"
        name="description"
        label={descLabel}
        placeholder="Enter item description"
        rows="3"
        maxLength={MAX_DESCRIPTION_BASE_LENGTH}
        isShowLengthDescription
      />
    </>
  );
};

export default FormTitleAndDesc;
