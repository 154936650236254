import {
  DOMESTIC_SHIPPING_TEMPLATE_ID,
  FREE_DOMESTIC_SHIPPING_TEMPLATE_ID,
  FREE_INTERNATIONAL_SHIPPING_TEMPLATE_ID,
} from "../../../../base/constants/draft";

export const getEtsyDimensionsLabel = (shippingValue) => {
  const isDimensionRequired = shippingValue === DOMESTIC_SHIPPING_TEMPLATE_ID ||
    shippingValue === FREE_DOMESTIC_SHIPPING_TEMPLATE_ID ||
    shippingValue === FREE_INTERNATIONAL_SHIPPING_TEMPLATE_ID;
  return isDimensionRequired ? "Package dimensions*" : "Package dimensions"
}