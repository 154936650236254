import { useCallback, useEffect, useRef } from "react";

export const useLastChild = (setOffsetFunc) => {

  const observer = useRef();

  const lastItem = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries => {
      if (entries[0].isIntersecting) {
        setOffsetFunc();
      }
    }));
    if (node) observer.current.observe(node);
  }, [setOffsetFunc]);

  useEffect(() => {
    return function cleanup() {
      if (observer.current) observer.current.disconnect();
    };
  }, []);

  return { lastItem };

};