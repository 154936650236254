export const VERIFICATION_SUCCESS_MESSAGE =
  "Email has been successfully verified";
export const VIRIFICATION_ERROR_MESSAGE = "Email hasn't been verified";
export const VERIFICATION_TOKEN_ERROR_MESSAGE =
  "Your verification session has expired.";
export const PHONE_NUMBER_ERROR = 'Incorrect phone number';
export const ACCESS_CODE_ERROR = 'Invalid SMS code';
export const MIN_PHONE_NUMBER_ERROR = 'Phone number must have 10 digits';
export const DRAFT_SAVE_SUCCESS = 'Draft is saved';
export const DRAFT_UPDATE_SUCCESS = 'Draft is updated';

export const INVENTORY_UPDATE_SUCCESS = 'Item has been successfully updated!';
export const PRODUCT_LISTED_SUCCESS = "Item has been successfully listed!";

export const FORM_DRAFT_IS_EMPTY = "Form draft is empty";
