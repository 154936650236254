import React from 'react'
import PropTypes from "prop-types";

const InputWrapper = ({ label, children, error }) => {
  return (
    <section className='custom-form-group mb-3'>
      <label>{label}</label>
      <div className='custorm-form-group__input-wrapper'>
        {children}
      </div>
      <span className="custom-invalid-feedback">
        {error}
      </span>
    </section>
  )
};

InputWrapper.propTypes = {
  label: PropTypes.string,
  error: PropTypes.string,
  children: PropTypes.any
}

export default InputWrapper;