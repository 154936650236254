export const STORAGE = {
  LOCAL: "local",
  SESSION: "session",
};

export const STORAGE_SPLITTER = "::";

export const KEY_USER = `${STORAGE.LOCAL}::user`;
export const KEY_HEADER_MENU = `${STORAGE.LOCAL}::toggle-menu`;
export const KEY_LANGUAGE = `${STORAGE.LOCAL}::lng`;
export const KEY_LOCATION_SESSION = `${STORAGE.LOCAL}::session_place`;

export const KEY_COOKIES_ACCEPTED = `${STORAGE.LOCAL}::cookiesAccept`;
export const KEY_COOKIES_HIDDEN = `${STORAGE.LOCAL}::cookiesHidden`;
export const KEY_STEP_REGISTRATION = `${STORAGE.LOCAL}::stepRegistration`;

export const KEY_EBAY_CONNECT = `${STORAGE.LOCAL}::ebay_connect`;
export const KEY_ETSY_CONNECT = `${STORAGE.LOCAL}::etsy_connect`;
export const KEY_POSHMARK_CONNECT = `${STORAGE.LOCAL}::poshmark_connect`;

export const KEY_EBAY_SESSION = `${STORAGE.LOCAL}::ebay_session`;

export const KEY_VERIFY_EMAIL = `${STORAGE.LOCAL}::verify_email`;
