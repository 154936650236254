import { useCallback, useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../../../../../base/hooks/useService";
import EmployeesService from "../../../../../../../services/EmployeesService";
import { useProfile } from "../../../../../../profile/hooks/useProfile";
import { SUBSCRIPTIONS_STATUSES } from "../constants";

export const useEmployee = (limit = 100) => {
  /**
   * @type {EmployeesService}
   */
  const employeesService = useService(EmployeesService);

  return useCallback(
    ({ offset }) => {
      return employeesService.getEmployees({
        limit,
        offset,
      });
    },
    [employeesService, limit]
  );
};

export const useTotalEmployees = () => {
  const load = useEmployee();
  const [state, update] = useState({ total: 0, allowed: 0, load: true });
  const [
    {
      activeSubscription: { status },
    },
  ] = useProfile();

  // TODO FIX
  const allowedEmployees = {
    [SUBSCRIPTIONS_STATUSES.trial]: -1,
    [SUBSCRIPTIONS_STATUSES.standard]: 0,
    [SUBSCRIPTIONS_STATUSES.business]: 1,
    [SUBSCRIPTIONS_STATUSES.unlimited]: -1,
    [SUBSCRIPTIONS_STATUSES.expired]: 0,
    [SUBSCRIPTIONS_STATUSES.beta]: -1,
  };

  useEffect(() => {
    load({})
      .then(({ pagination }) => {
        update({
          load: false,
          total: pagination.totalCount,
          allowed: allowedEmployees[status],
        });
      })
      .catch((e) => Bugsnag.notify(e));
  }, [load, status]);

  return [state, update];
};
