/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";

export const Clear = (props) => {
  const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
  } = props;

  const styles = {
    ...getStyles("clearIndicator", props),
    ...{
      padding: 2,
      paddingRight: 2,
      paddingLeft: 2,
    },
  };

  return (
    <div {...restInnerProps} ref={ref} style={styles}>
      <div>
        <svg
          height="15"
          width="15"
          viewBox="0 0 20 20"
          aria-hidden="true"
          fill="#74818D"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 
            0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 
            3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
          ></path>
        </svg>
      </div>
    </div>
  );
};

Clear.propTypes = {
  props: PropTypes.object,
};

export default Clear;
