import React, {useRef} from "react";
import IconButton from "../../../drafts/components/IconButton";
import { ReactComponent as Edit } from "../../../taskManagement/components/Icons/Edit.svg";
import { ReactComponent as Delete } from "../../../taskManagement/components/Icons/Delete.svg";
import { ReactComponent as ArrowDown } from "../../../taskManagement/components/Icons/ArrowDown.svg";
import { Tooltip } from "reactstrap";
import { useTooltipProps } from "../../../sold/pages/SoldList/table";

const ExpenseActionsButton = ({
    isOpen,
    onDeleteExpense,
    onEditExpense,
}) => {
    const arrowRef = useRef(null);

  const handleEditExpense = (event) => {
    event.stopPropagation();
    onEditExpense();
  };

  const handleDeleteExpense = (event) => {
    event.stopPropagation();
    onDeleteExpense();
  };
    return(
        <>
            <div className="flex-grow-1" />
            <IconButton
                onClick={handleEditExpense}
                className="ms-2 action-icon-button d-flex align-items-center"
            >
                <Edit />
            </IconButton>
            <IconButton
                onClick={handleDeleteExpense}
                className="ms-2 action-icon-button d-flex align-items-center"
            >
                <Delete />
            </IconButton>

            <div className="flex-grow-1" />
            <IconButton
                ref={arrowRef}
                className={`ms-2 action-icon-button d-flex align-items-center ${
                    isOpen ? "is-active" : ""
                }`}
            >
                <ArrowDown />
            </IconButton>

            <Tooltip placement="top" {...useTooltipProps(arrowRef)}>
                Show details
            </Tooltip>
        </>
    )
}

export default ExpenseActionsButton;