/* eslint-disable react/prop-types */
import React from "react";
import { FixedSizeList as List } from "react-window";

const VirtualizeMenuList = (props) => {
  const { options, children, maxHeight, getValue } = props;
  if (!children || !Array.isArray(children)) return null;

  const height = 35;
  const selectedValues = getValue();
  const initialOffset = selectedValues[0]
    ? options.indexOf(selectedValues[0]) * height
    : 0;

  return (
    <List
      width=""
      itemSize={height}
      height={maxHeight}
      itemCount={children.length}
      initialScrollOffset={initialOffset}
      className="custom-green-scroll"
    >
      {({ index, style }) => {
        return <div style={style}>{children[index]}</div>;
      }}
    </List>
  );
};

export default VirtualizeMenuList;
