export const TEXT_TYPE = "text";
export const PASSWORD_TYPE = "password";

export const ERROR_EMAIL_MESSAGE =
  "User with such email has already been registered";
export const ERROR_INVALID_CREDENTIALS_MESSAGE = "Invalid credentials";

export const ERROR_EMPTY_STRING = "";
export const EMPTY_STRING = "";

export const EMAIL_ERROR = "Email is invalid";
export const PASSWORD_PATTERN_ERROR =
  "Password should contain at least 1 digit, 1 upper and lowercase letter";

export const INCORRECT_MESSAGE = "Incorrect email or password";

export const WRONG_CREDENTIALS_CODE = "WRONG_CREDENTIALS";
export const EMAIL_NOT_VERIFIED_CODE = "EMAIL_NOT_VERIFIED";
export const BLOCKED_BY_ADMIN_CODE = "BLOCKED_BY_ADMIN";
export const BLOCKED_BY_STORE_OWNER_CODE = "BLOCKED_BY_STORE_OWNER";
export const STORE_OWNER_SUBSCRIPTION_EXPIRED_CODE =
  "STORE_OWNER_SUBSCRIPTION_EXPIRED";

export const CODE_NAME_KEY = "code";
export const PHONE_STORAGE_KEY = "2FAPhoneNumber";

export const ERROR_SMS_CODE = "Invalid SMS code";
export const CHANGED_PHONE_KEY = "ChangedPhoneNumber";
export const CHANGED_EMAIL_KEY = "ChangedEmail";
export const DEFAULT_START_PHONE_NUMBER = "+1";
export const FIRST_PREFIX_LENGTH = 1;
export const DEFAULT_FRACTION_LENGTH = 2;

export const DEV_DEFAULT_START_PHONE_NUMBER = "+";
export const DEV_FIRST_PREFIX_LENGTH = 0;

export const INCORRECT_OLD_PASSWORD = "Incorrect old password";
export const PASSWORD_REQUIRED = "Password is required";
export const REPEAT_PASSWORD_REQUIRED = "Repeat password is required";
export const ONLY_LETTERS_ERROR = "Must be only letters";

export const TITLE_ERROR = "Missing title";
export const DESCRIPTION_ERROR = "Max 1000 characters for description";
export const POSHMARK_DESCRIPTION_ERROR = "Max 1500 characters for description";
export const ETSY_DESCRIPTION_ERROR = "Max 4000 characters for description";
export const EBAY_DESCRIPTION_ERROR = "Max 4000 characters for description";
export const MERCARY_DESCRIPTION_ERROR = "Max 1000 characters for description";
export const COLOR_ERROR = "Missing color";
export const BRAND_ERROR = "Missing brand";
export const LB_ERROR = "Please enter a valid weight (lb)";
export const OZ_ERROR = "Please enter a valid weight (oz)";
export const PRISE_ERROR = "Missing price";
export const DIMENSION_ERROR = "Incorrect dimension";
export const DIMENSION_REQUIRED_ERROR = "Package dimensions  is required";
export const COST_ERROR = "Incorrect cost of item";
export const ZIP_ERROR = "Incorrect zip code";
export const TAG_ERROR = "Only letters, digits, spaces and commas are allowed";

export const RETURN_REASON_ERROR = "Missing return reason";
export const RECEIVED_CONDITION_ERROR = "Missing received condition";
export const RETURN_PERSONAL_NOTES_ERROR = "Missing personal notes";
export const RETURN_DATE_ERROR = "Missing date";
export const RETURN_DATE_INVALID = "Invalid date";

export const RETURN_DATE_LENGTH = 10;

export const CATEGORY_ERROR = "Missing category";
export const DRESS_OCCASION_ERROR = "Missing dress occasion";
export const DRESS_STYLE_ERROR = "Missing dress style";
export const HANDLING_TIME_ERROR = "Missing handling time";
export const SHIPPING_ERROR = "Missing shipping service";
export const PAYPAL_ERROR = "Enter paypal email";

export const DEFAULT_STEP = "1";
export const PRICE_STEP = "0.01";
export const STEP_BY_HUNDREDTH = "0.01";
export const BUY_IT_NOW_PRICE_MIN_NUMBER = 0.99;
export const DEFAULT_MIN_NUMBER = "0.00";
export const DEFAULT_MAX_NUMBER = "999999999.99";

export const DRAFT_KEY_TAGS = "tags";
export const DRAFT_TAGS_KEY_NAME = "name";
export const DRAFT_KEY_BRAND = "brand";

export const MIN_VALIDATION_NUMBER = 0;
export const MIN_PRICE = 0.01;
export const MIN_BUY_IT_NOW_PRICE = 0.99;
export const MIN_ORIGINAL_PRICE = 0;
export const MIN_LB_OZ = 0.01;
export const MAX_VALIDATION_NUMBER = 999_999_999.99;
export const MAX_VALIDATION_PRICE = 99_999_999.99;

export const MIN_LENGTH_UPC = 9999_9999_9;
export const MAX_LENGTH_SKU = 50;
export const MAX_LENGTH_ZIP = 5;
export const MAX_LENGTH_TAGS = 800;
export const UPC_MAX = 9999_9999_9999_9;

export const UPC_REQUIRED_ERROR = "UPC is required";
export const UPC_MIN_LENGTH_ERROR = "Min. 10 digits";
export const UPC_INVALID_ERROR = "UPC is invalid";

export const MESSAGE_REQUIRED = "Message is required";
export const MESSAGE_MAX_LENGTH = ({ max }) =>
  `Add comment must be from 1 to ${max} characters`;

export const TASK_TITLE_REQUIRED = "Title is required";
export const TASK_TITLE_MAX_LENGTH = ({ max }) =>
  `Title must be from 1 to ${max} characters`;

export const TASK_DESCRIPTION_MAX_LENGTH = ({ max }) =>
  `Description must be from 1 to ${max} characters`;

export const TASK_ASSIGNEE_REQUIRED = "Employee is required";
