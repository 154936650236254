import _ from "lodash";
import { useRef } from "react";

const MAX_BRAND_LIST_LENGTH = 3;
const MIN_SEARCH_STRING_LENGTH = 2;

export const useFindAlternativeBrands = () => {
  const startMatchList = useRef([]);
  const middleMatchList = useRef([]);
  const endMatchList = useRef([]);

  const result = {};

  const findCompleteMatch = (allBrands, selectedBrand) => {
    if (_.isEmpty(allBrands)) return [];

    const regularStart = new RegExp(`^${selectedBrand}`, "i");
    const regularMiddle = new RegExp(`.*${selectedBrand}.*$`, "i");
    const regularEnd = new RegExp(`${selectedBrand}$`, "i");

    for (const brand of allBrands) {
      if (result[brand]) continue;

      if (regularStart.test(brand)) {
        startMatchList.current.push(brand);
        result[brand] = true;
      } else if (regularMiddle.test(brand)) {
        middleMatchList.current.push(brand);
        result[brand] = true;
      } else if (regularEnd.test(brand)) {
        endMatchList.current.push(brand);
        result[brand] = true;
      }

      if (startMatchList.current.length >= MAX_BRAND_LIST_LENGTH) {
        return startMatchList.current;
      }
    }

    if (selectedBrand.length <= MIN_SEARCH_STRING_LENGTH) {
      return [
        ...startMatchList.current,
        ...middleMatchList.current,
        ...endMatchList.current,
      ];
    }

    return findCompleteMatch(
      allBrands,
      selectedBrand.substring(0, selectedBrand.length - 1)
    );
  };

  const findAlternativeBrands = (allBrands, selectedBrand, formatAllBrands) => {
    const formattedAllBrands = formatAllBrands(allBrands);

    const alternativeBrands = findCompleteMatch(
      formattedAllBrands,
      selectedBrand
    );

    return alternativeBrands.slice(0, MAX_BRAND_LIST_LENGTH);
  };

  return [findAlternativeBrands];
};
