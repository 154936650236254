import React, { useCallback, useState } from "react";
import { CustomModal, ModalBody, ModalFooter, ModalHeader } from "../../../CustomModal";
import CustomButton from "../../../CustomButton";
import { useLimitationEvent } from "../../../../hooks/useSocketLimitationEvent";
import { getCurrentSubscriptionType } from "../../../../helpers/getCurrentSubscriptionType";
import { getAvailableListingFromType } from "../../../../helpers/getLimitFromSubscriptionType";
import { useGetCounterDraft } from "../../../../hooks/useGetCounterDraft";

const ChangeSubscriptionEmployeeModal = ({importedCount}) => {
  const subscriptionType = getCurrentSubscriptionType();
  const availableListing = getAvailableListingFromType(subscriptionType);
  const handleGetCounter = useGetCounterDraft();

  const [isOpenChangeSubscriptionModal, setIsOpenChangeSubscriptionModal] = useState(false);


  const handleEvent = useCallback(async () => {
    setIsOpenChangeSubscriptionModal(true);
  }, [availableListing, handleGetCounter]);

  useLimitationEvent(handleEvent);


  const onCloseChangeSubscriptionModal = useCallback(() => {
    setIsOpenChangeSubscriptionModal(false);
  }, [setIsOpenChangeSubscriptionModal]);

  return (
    <CustomModal isOpen={isOpenChangeSubscriptionModal} onClose={onCloseChangeSubscriptionModal}>
      <ModalHeader onClose={onCloseChangeSubscriptionModal}>Should upgrade subscription</ModalHeader>
      <ModalBody>
        <p className="ui-regular">
          { `${importedCount} number of items were successfully imported. To import more please contact your store owner for subscription upgrading.`}
        </p>
      </ModalBody>
      <ModalFooter>
        <CustomButton onClick={onCloseChangeSubscriptionModal} className="outline-primary ">
          Cancel
        </CustomButton>
        <CustomButton
          className="filled-primary"
          onClick={onCloseChangeSubscriptionModal}
        >
          Ok
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );
};

export default ChangeSubscriptionEmployeeModal;
