import React from "react";
import { Modal } from "reactstrap";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./index.module.scss";

export const ModalBody = ({ children, className }) => (
  <div className={classnames("modal-body", className)}>{children}</div>
);

ModalBody.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export const ModalHeader = ({ onClose, isEmpty, children }) => (
  <div className="modal-header">
    <h3
      className={classnames("modal-title mt-0", { [styles.header]: isEmpty })}
      id="myModalLabel"
    >
      {children}
    </h3>
    <button
      type="button"
      onClick={onClose}
      className="close"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true"></span>
    </button>
  </div>
);

ModalHeader.propTypes = {
  onClose: PropTypes.func,
  isEmpty: PropTypes.bool,
  children: PropTypes.any,
};

export const CustomModal = ({
  isOpen,
  onClose,
  children,
  backdrop,
  keyboard,
  size = "md",
  unmountOnClose,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      size={size}
      centered={true}
      backdrop={backdrop}
      keyboard={keyboard}
      unmountOnClose={unmountOnClose}
    >
      {children}
    </Modal>
  );
};

CustomModal.propTypes = {
  children: PropTypes.any,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["static"])]),
  size: PropTypes.string,
  keyboard: PropTypes.bool,
  unmountOnClose: PropTypes.bool,
  className: PropTypes.string,
};

export const ModalFooter = ({ children }) => (
  <div className="modal-footer">{children}</div>
);

ModalFooter.propTypes = {
  children: PropTypes.any,
};
