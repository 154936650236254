import ExtensionService from "../Extension";
import { EXTENSION_EVENTS, MARKETPLACES } from "../../base/constants/extension";

class ImportPoshmark extends ExtensionService {
  constructor() {
    super(MARKETPLACES.POSHMARK);
  }

  static $displayName = "ImportPoshmark";

  importActiveProducts(tabId, pagination) {
    return this.request(EXTENSION_EVENTS.IMPORT_MODAL_GET_ACTIVE_PRODUCTS, {
      params: { marketplaceTabId: tabId, pagination },
    });
  }

  importSoldProducts(tabId, pagination) {
    return this.request(EXTENSION_EVENTS.IMPORT_MODAL_GET_SOLD_PRODUCTS, {
      params: {
        marketplaceTabId: tabId,
        pagination,
      },
    });
  }
}

export default ImportPoshmark;
