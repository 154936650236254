import React, { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { Container } from "../components/Container";
import { FORM_TYPES } from "../../drafts/const/form";

const Etsy = () => {
  const [base, updateBase] = useState([]);
  const [categories, updateCategories] = useState([]);

  useEffect(() => {
    import("../data/basic")
      .then(({ basic }) => {
        updateBase(basic);
      })
      .catch((e) => Bugsnag.notify(e));
    import("../data/etsy")
      .then(({ etsy }) => {
        updateCategories(etsy);
      })
      .catch((e) => Bugsnag.motify(e));
  }, []);

  return (
    <Container form={FORM_TYPES.ETSY} categories={categories} basic={base} />
  );
};

export default Etsy;
