/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState } from "react";

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState("");

  const setErrorData = (errorData) => setError(errorData);

  return (
    <ErrorContext.Provider value={{ error, setErrorData }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => useContext(ErrorContext);
