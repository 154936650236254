import React, { useCallback } from "react";
import { FieldArray } from "formik";
import { Col, Row } from "reactstrap";

import { useDraftImages } from "./useDraftImages";
import {
  EBAY_MAX_DRAFT_IMAGES_COUNT,
  ETSY_MAX_DRAFT_IMAGES_COUNT,
  MAX_DRAFT_IMAGES_COUNT,
  MERCARI_MAX_DRAFT_IMAGES_COUNT,
} from "../components/DraftImages/constants";
import useUploadImagesToS3 from "./useUploadImagesS3";
import DraftImages from "../components/DraftImages";
import UploaderModal from "../components/DraftImages/UploaderModal";
import EditModal from "../components/DraftImages/EditModal";
import TitleForBlock from "../components/TitleForBlock";
import { useTriggerFormChanges } from "./useDetectDirtyForm";

const useProductImages = (
  maxImagesCount = MAX_DRAFT_IMAGES_COUNT,
  description = ""
) => {
  const {
    selectedImages,
    isOpenUploadModal,
    isOpenEditModal,
    onOpenUploadModal,
    onCloseUploadModal,
    onSave,
    setImages,
    onEditImage,
    selectedImage,
    onCropImage,
    onCloseEditModal,
    onDeleteImage,
    clearAll,
    field,
    setValue,
  } = useDraftImages(maxImagesCount);

  const uploadImagesToS3 = useUploadImagesToS3();
  const { markChanges } = useTriggerFormChanges();

  const uploadImages = useCallback(
    (files) => {
      return uploadImagesToS3(files);
    },
    [uploadImagesToS3]
  );

  function decorate(handler) {
    return function (...params) {
      markChanges();
      handler(params);
    };
  }

  const imageContainer = (
    <>
      <DraftImages
        selectedImages={selectedImages}
        onOpenUploadModal={onOpenUploadModal}
        onEditImage={onEditImage}
        setOrderedImages={setValue}
        onDelete={onDeleteImage}
        description={description}
        setOrderedImagesList={setValue}
        maxImagesCount={maxImagesCount}
      />
      <UploaderModal
        isOpen={isOpenUploadModal}
        setImages={setImages}
        onClose={onCloseUploadModal}
        onSave={onSave}
      />
      <EditModal
        isOpen={isOpenEditModal}
        selectedImage={selectedImage}
        onCropImage={onCropImage}
        onClose={onCloseEditModal}
      />
      <FieldArray {...field} />
    </>
  );

  const imageEtsyContainer = (
    <>
      <TitleForBlock
        title={"Photos"}
        description={"Add as many as you can so buyers can see every details"}
      />
      <Row>
        <Col md={4}>
          <h3 className="bold-black mb-1">Photos</h3>
          <p className="text-gray-gomi">
            {
              "Use up to ten photos to show your item's most important qualities."
            }
          </p>
        </Col>
        <Col md={8}>
          <DraftImages
            selectedImages={selectedImages}
            onOpenUploadModal={onOpenUploadModal}
            onEditImage={onEditImage}
            setOrderedImages={setValue}
            onDelete={onDeleteImage}
            setOrderedImagesList={setValue}
            description={"Add up to 10 photos "}
            maxImagesCount={ETSY_MAX_DRAFT_IMAGES_COUNT}
          />
          <UploaderModal
            isOpen={isOpenUploadModal}
            setImages={setImages}
            onClose={onCloseUploadModal}
            onSave={onSave}
          />
          <EditModal
            isOpen={isOpenEditModal}
            selectedImage={selectedImage}
            onCropImage={onCropImage}
            onClose={onCloseEditModal}
          />
          <FieldArray {...field} />
        </Col>
      </Row>
    </>
  );

  const imagePoshmarkContainer = (
    <Row>
      <Col md={4}>
        <h3 className="bold-black mb-1">Item photos*</h3>
        <p className="text-gray-gomi">
          Add up to 16 photos to show the angles and details of your item.
        </p>
      </Col>
      <Col md={8}>
        <DraftImages
          selectedImages={selectedImages}
          onOpenUploadModal={onOpenUploadModal}
          onEditImage={onEditImage}
          setOrderedImages={setValue}
          onDelete={onDeleteImage}
          setOrderedImagesList={setValue}
          description={"Add up to 16 photos"}
        />
        <UploaderModal
          isOpen={isOpenUploadModal}
          setImages={setImages}
          onClose={onCloseUploadModal}
          onSave={onSave}
        />
        <EditModal
          isOpen={isOpenEditModal}
          selectedImage={selectedImage}
          onCropImage={onCropImage}
          onClose={onCloseEditModal}
        />
        <FieldArray {...field} />
      </Col>
    </Row>
  );

  return {
    selectedImages,
    clearAll,
    uploadImages,
    imageContainer,
    imageEtsyContainer,
    imagePoshmarkContainer,
  };
};

export const ProductImages = () => {
  const { imageContainer } = useProductImages();

  return imageContainer;
};

export const MercariProductImages = () => {
  const { imageContainer } = useProductImages(MERCARI_MAX_DRAFT_IMAGES_COUNT);

  return imageContainer;
};

export const EtsyProductImages = () => {
  const { imageEtsyContainer } = useProductImages(ETSY_MAX_DRAFT_IMAGES_COUNT);

  return imageEtsyContainer;
};

export const EbayProductImages = () => {
  const { imageContainer } = useProductImages(
    EBAY_MAX_DRAFT_IMAGES_COUNT,
    "Add up to 16 photos."
  );

  return imageContainer;
};

export const PoshmarkProductImages = () => {
  const { imagePoshmarkContainer } = useProductImages();

  return imagePoshmarkContainer;
};
