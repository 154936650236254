import classnames from "classnames";
import React from "react";

import "./index.scss";

const SizeTab = ({tabs, current, updateTab}) => {
    return (
        <div className="wrapper-tabs-size">
            <div className="tabs-wrapper">
                <ul className="navtab-bg nav nav-pills">
                    {tabs?.map((tab, index) => {
                            return (
                                <li className="nav-item" key={`${index}`}>
                                    <a
                                        className={classnames("nav-link", {
                                            active: index === Number(current)
                                        })}
                                        onClick={() => updateTab(index)}
                                    >
                                        {tab.name}
                                    </a>
                                </li>
                            )
                        }
                    )}
                </ul>
            </div>
        </div>
    )
}

export default SizeTab;