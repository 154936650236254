const STRIPE_OPTIONS = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap'
    }
  ]
}

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "13px",
      color: "#495057",
      fontFamily: "Poppins",
      fontWeight: "500",
      "::placeholder": {
        color: "#a1aab3",
        fontSize: "13px",
      },
    },
    invalid: {
      color: "#f46a6a",
    },
  },
  placeholder: "____ ____ ____ ____",
};

const ELEMENT_OPTIONS_DATE = {
  style: {
    base: {
      fontSize: "13px",
      color: "#495057",
      fontFamily: "Poppins",
      fontWeight: "500",
      "::placeholder": {
        color: "#a1aab3",
        fontSize: "13px",
      },
    },
    invalid: {
      color: "#f46a6a",
    },
  },
  placeholder: "MM/YY",
};

const ELEMENT_OPTIONS_CVC = {
  style: {
    base: {
      fontSize: "13px",
      color: "#495057",
      fontFamily: "Poppins",
      fontWeight: "500",
      "::placeholder": {
        color: "#a1aab3",
        fontSize: "13px",
      },
    },
    invalid: {
      color: "#f46a6a",
    },
  },
  placeholder: "CVV",
};

export const CREDIT_STYLES = { STRIPE_OPTIONS, ELEMENT_OPTIONS, ELEMENT_OPTIONS_DATE, ELEMENT_OPTIONS_CVC };
