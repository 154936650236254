import ProductReturnsService from "../../../services/ProductReturnsService";
import { useInfiniteScroll } from "../../../base/components/InfiniteScrollWrapper/hooks/useInfiniteScroll";
import { useService } from "../../../base/hooks/useService";
import { DEFAULT_RETURNS_LIMIT, RETURN_STATUSES } from "../constants/returns-constants";

export const useGetReturnsList = (
  status = RETURN_STATUSES.IN_PROGRESS, 
  limit = DEFAULT_RETURNS_LIMIT
) => {
  const productReturnsService = useService(ProductReturnsService);

  const { data, handleFetch, hasMore, handleRefresh, totalCount, isLoading } = useInfiniteScroll({
    limit,
    fetchData: (params) => productReturnsService.getListReturnProducts(params),
    addedParams: { status }
  });

  return { data, handleFetch, hasMore, refresh: handleRefresh, totalCount, isLoading};
};
