import React from "react";
import InputWrapper from "../../../../../InputWrapper";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";

const StripeInputs = ({onChange, styleObject, error}) => {
  return (
    <>
      <InputWrapper label="Card number">
        <CardNumberElement
          onChange={onChange}
          options={styleObject.ELEMENT_OPTIONS}
        />
      </InputWrapper>
      <InputWrapper label='Expiry date' error={error}>
        <CardExpiryElement
          onChange={onChange}
          options={styleObject.ELEMENT_OPTIONS_DATE}
        />
      </InputWrapper>
      <InputWrapper label='Secure code'>
        <CardCvcElement
          onChange={onChange}
          options={styleObject.ELEMENT_OPTIONS_CVC}
        />
      </InputWrapper>
    </>
  );
};

export default StripeInputs;