/* eslint-disable react/prop-types */
import React, { useCallback, useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Formik } from "formik";
import { Card, CardBody, Col, Row } from "reactstrap";

import CreationDate from "../../../../components/CreationDate";
import CustomButton from "../../../../../../base/components/CustomButton";
import DraftBasicFormInner from "../../../../components/DraftBasicFormInner";
import { useHanleSaveDraftErrors } from "../../../../hooks/useSaveSubmit";
import { validationSchema } from "./form";
import { useDeleteDraft } from "../../../../hooks/useDeleteDraft";
import { DRAFTS_GROUP_LINKS } from "../../../../config";
import { ProductImages } from "../../../../hooks/useProductImages";
import FormPartials from "../../../../classes/FormPartials";
import { FORM_TYPES } from "../../../../const/form";
import { useInitialFormValues } from "../../../../helpers/mapFormValues";
import DraftHeader from "../../../../components/DraftHeader";
import {
  BRAND_ERROR,
  DESCRIPTION_ERROR,
  LB_ERROR,
  OZ_ERROR,
  PRISE_ERROR,
  TITLE_ERROR,
} from "../../../../../../base/constants/forms";
import Divider from "../../../../components/Divider";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import ProgressBar from "../../../../../sold/components/ProgressBar";
import Bugsnag from "@bugsnag/js";
import { INVENTORY_STATUSES } from "../../../../../inventory/pages/EditInventory/constants";
import { DraftFormMonitor } from "../../../../components/DraftFormMonitor";

const EditDraftBasic = ({
  fullFormData,
  updateFullForm,
  onSave,
  toggleStatus,
  onSaveAndCreateNew,
  isDisableCreateDraft,
}) => {
  const [isLoading, { registerPromise }] = useLoading();
  const [loading, setLoading] = useState(false);
  const [processMessage, setProcessMessage] = useState("");

  // changes
  const history = useHistory();
  const {
    params: { draftId },
  } = useRouteMatch();

  const showProgressBar = (message) => {
    setLoading(true);
    setProcessMessage(message);
  };

  const hideProgressBar = () => {
    setLoading(false);
    setProcessMessage("");
  };

  const touched = {
    title: true,
    description: true,
    brand: true,
    primaryColor: true,
    weightLb: true,
    weightOz: true,
    listingPrice: true,
  };

  const baseErrors = {
    title: TITLE_ERROR,
    description: DESCRIPTION_ERROR,
    brand: BRAND_ERROR,
    weightLb: LB_ERROR,
    weightOz: OZ_ERROR,
    listingPrice: PRISE_ERROR,
  };

  const {
    view: popupView,
    onDelete,
    deleting,
  } = useDeleteDraft(() => {
    history.push(DRAFTS_GROUP_LINKS.BASE);
  });

  const handleDelete = () => onDelete(draftId);

  const formPartials = new FormPartials(FORM_TYPES.BASE);

  const initialValues = useInitialFormValues(
    fullFormData,
    formPartials.initialValues,
    formPartials.mapFields,
    ""
  );

  const onCatchError = useHanleSaveDraftErrors();

  const handleSave = useCallback(
    (values, { setErrors, setTouched }) => {
      showProgressBar("Saving your changes");
      return registerPromise(onSave(values))
        .catch((err) =>
          onCatchError(err, () => {
            Bugsnag.notify(err);
            setTouched(touched, false);
            setErrors(baseErrors);
          })
        )
        .finally(hideProgressBar);
    },

    [onSave, registerPromise]
  );

  const handleSaveNewDraftSubmit = useCallback(
    (values, { resetForm, setErrors, setTouched }) => {
      showProgressBar("Updating your changes");
      return registerPromise(onSaveAndCreateNew(values))
        .then(() => {
          resetForm();
        })
        .catch((err) =>
          onCatchError(err, () => {
            Bugsnag.notify(err);
            setTouched(touched, false);
            setErrors(baseErrors);
          })
        )
        .finally(hideProgressBar);
    },

    [onSaveAndCreateNew, registerPromise]
  );

  useEffect(() => {
    document.getElementById("basic-form").reset();
  }, []);

  return (
    <Card className="mt-3">
      {popupView}
      <CardBody>
        {loading && <ProgressBar isActive processMessage={processMessage} />}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(_, helpers) => handleSave(fullFormData, helpers)}
        >
          {(helpers) => {
            const { handleSubmit, setFieldValue, values } = helpers;
            const isPostedItem =
              fullFormData?.productEbaySpecificFields?.status ===
                INVENTORY_STATUSES.listed ||
              fullFormData?.productEtsySpecificFields?.status ===
                INVENTORY_STATUSES.listed ||
              fullFormData?.productPoshmarkSpecificFields?.status ===
                INVENTORY_STATUSES.listed ||
              fullFormData?.productMercariSpecificFields?.status ===
                INVENTORY_STATUSES.listed;

            return (
              <form
                className="form-horizontal p-2"
                onSubmit={handleSubmit}
                id="basic-form"
              >
                <DraftHeader
                  title={"Basic form"}
                  status={""}
                  toggleStatus={toggleStatus}
                />

                {!isPostedItem && (
                  <DraftFormMonitor
                    form={{
                      ...fullFormData,
                      ...values,
                    }}
                    updateFullForm={updateFullForm}
                  />
                )}

                <h3 className="bold-black mb-4">Item photos</h3>
                <Divider />
                <ProductImages />

                <DraftBasicFormInner
                  values={values}
                  setFieldValue={setFieldValue}
                />
                <Row>
                  <Col md="5" className="d-flex">
                    <CreationDate
                      text="Creation date:"
                      date={values.createdAt}
                      className="mr-60"
                    />
                    {!!draftId && (
                      <CreationDate
                        text="Last Updated:"
                        date={values.updatedAt}
                        className="mr-60"
                      />
                    )}
                  </Col>
                  <Col
                    md="7"
                    className="d-flex justify-content-md-end align-items-center"
                  >
                    {!!draftId && (
                      <CustomButton
                        className="outline-danger w-80 mr-2"
                        onClick={handleDelete}
                        disabled={deleting}
                      >
                        Delete
                      </CustomButton>
                    )}
                    <CustomButton
                      className="filled-primary w-80 mr-2"
                      onClick={() => handleSave(fullFormData, helpers)}
                      disabled={deleting || isLoading}
                    >
                      Save
                    </CustomButton>
                    <CustomButton
                      disabled={deleting || isDisableCreateDraft || isLoading}
                      onClick={() =>
                        handleSaveNewDraftSubmit(fullFormData, helpers)
                      }
                      className="outline-primary"
                    >
                      Save + New draft
                    </CustomButton>
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default EditDraftBasic;
