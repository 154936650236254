/* eslint-disable react/prop-types */
import React, { useState, useEffect, useCallback } from "react";
import { useDebounce } from "use-debounce";
import Bugsnag from "@bugsnag/js";

import styles from "../../index.module.scss";
import MergeRightHeader from "../MergeRightHeader";
import MergeItemsRight from "../MergeItemsRight";
import ClockLoader from "react-spinners/ClipLoader";
import DraftsService from "../../../../../services/DraftsService";
import { useService } from "../../../../hooks/useService";
import { PRODUCT_TYPE } from "../../../../constants/product";
import { CREATED_AT_FIELD } from "../../../../../pages/expenses/components/MileagesHeader/constants";
import {
  ID_LABEL,
  MERGE_OFFSET,
  SORTING_TYPE,
} from "../../constants/constants";
import Loader from "../../../InfiniteScrollWrapper/components/Loader";
import { useLastChild } from "../../hooks/useLastChild";
import { filterDuplicatedBy } from "../../utils/filterDuplicatedBy";

const RightSide = ({
  marketplacesTypesForSuggestion,
  selectedItem,
  onSelectItem,
  suggestedItems,
  onSetSuggestedItems,
  selectedMarketplacesMap,
  checkIsNotAvailableToMerge,
  isDisabledSearch,
  searchTerm,
  onGetFullData,
  endItemsList,
  onSetSearchTerm,
  searchHighlight,
  onClearSelectedSuggestedItem,
}) => {
  const [isSearching, setIsSearching] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [debouncedSearchTerm] = useDebounce(searchTerm, 300);
  const [debouncedIsDisabledSearch] = useDebounce(isDisabledSearch, 300);

  const draftService = useService(DraftsService);

  const handleSearchTerm = (e) => {
    onSetSearchTerm(e.target.value);
  };

  const fetchSuggestedItems = () => {
    const requestBody = {
      q: debouncedSearchTerm || "",
      status: PRODUCT_TYPE.ACTIVE,
      orderBy: CREATED_AT_FIELD,
      orderType: SORTING_TYPE,
      isListedAtMarketplaces: true,
      marketplaceTypes: JSON.stringify(marketplacesTypesForSuggestion),
      offset: offset,
    };

    return draftService.getListDrafts(requestBody);
  };

  useEffect(() => {
    if (endItemsList) {
      setOffset(0);
      return;
    }
    if (!isDisabledSearch) {
      setIsLoading(true);
      fetchSuggestedItems()
        .then((response) => {
          const cumulativeItems = [...suggestedItems, ...response.data];
          if (offset === 0) {
            onSetSuggestedItems(response.data);
          } else {
            onSetSuggestedItems(filterDuplicatedBy(cumulativeItems, ID_LABEL));
          }
          onGetFullData(response);
          setIsLoading(false);
        })
        .catch((e) => Bugsnag.notify(e));
    } else {
      onSetSuggestedItems([]);
      onGetFullData([]);
      setIsSearching(false);
    }
  }, [offset]);

  useEffect(() => {
    setOffset(0);
    onClearSelectedSuggestedItem();
    onGetFullData([]);
    if (!isDisabledSearch) {
      setIsSearching(true);
      fetchSuggestedItems()
        .then((response) => {
          setIsSearching(false);
          onSetSuggestedItems(response.data);
        })
        .catch((e) => Bugsnag.notify(e));
    } else {
      setIsSearching(false);
      onSetSuggestedItems([]);
    }
  }, [debouncedSearchTerm, debouncedIsDisabledSearch]);

  const setOffsetFunc = useCallback(() => {
    if (isLoading) {
      return;
    }
    setOffset((prevState) => prevState + MERGE_OFFSET);
  }, [isLoading, setOffset]);

  const { lastItem } = useLastChild(setOffsetFunc);

  return (
    <div className={styles.halfContainer}>
      <MergeRightHeader
        search={searchTerm}
        onSearchTerm={handleSearchTerm}
        isDisabledSearch={isDisabledSearch}
      />
      {suggestedItems.length ? (
        <MergeItemsRight
          items={suggestedItems}
          lastItem={lastItem}
          selectedItem={selectedItem}
          onSelectItem={onSelectItem}
          selectedMarketplacesMap={selectedMarketplacesMap}
          checkIsNotAvailableToMerge={checkIsNotAvailableToMerge}
          searchHighlight={searchHighlight}
        />
      ) : (
        <div
          className={
            styles.cardMergeContainerEmpty +
            " d-flex justify-content-center align-items-center"
          }
        >
          <div className="d-flex justify-content-center align-items-center">
            {isSearching ? (
              <ClockLoader color={"#2f665c"} size={48} />
            ) : (
              <span className="bold-gray">No suggested items</span>
            )}
          </div>
        </div>
      )}
      {isLoading && !isSearching && !endItemsList && (
        <div className={styles.infinitLoader}>
          <Loader key="loader" />
        </div>
      )}
    </div>
  );
};

export default RightSide;
