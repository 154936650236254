import React from "react";
import PropTypes from "prop-types";

import CustomButton from "../CustomButton";

import "./index.scss";

const TitleButtonPage = ({
  onClick,
  title,
  subTitle,
  buttonText = "Create return",
  hasButton = true,
  data
}) => {
  return (
    <div className="d-flex justify-content-between align-items-center mb-4">
      <div>
        <h4 className="mb-0 font-weight-semibold font-size-16 text-uppercase">
          {title}
        </h4>
        {subTitle && (
          <span className="title-button-page-subtitle">{subTitle}</span>
        )}
      </div>

      {hasButton && !!data?.length &&  (
        <CustomButton className="filled-primary" onClick={onClick}>
          {buttonText}
        </CustomButton>
      )}
    </div>
  );
};

TitleButtonPage.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  hasButton: PropTypes.bool,
};

export default TitleButtonPage;
