/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Formik } from "formik";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../../../../base/hooks/useService";
import CreationDate from "../../../../components/CreationDate";
import CustomButton from "../../../../../../base/components/CustomButton";
import FormPartials from "../../../../classes/FormPartials";
import { FORM_TYPES } from "../../../../const/form";
import { useInitialFormValues } from "../../../../helpers/mapFormValues";
import MarketPlaceService from "../../../../../../services/MarketplaceService";
import DraftHeader from "../../../../components/DraftHeader";
import DraftEtsyFormInner from "../../../../components/DraftEtsyFormInner";
import { highlightDraftFields } from "../../../../classes/forms/etsy";
import { useHanleSaveDraftErrors } from "../../../../hooks/useSaveSubmit";
import EtsyService from "../../../../../../services/EtsyService";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { ConnectButtonEtsy } from "../../../../components/ConnectButton/ConnectButton";
import { useListingUrl } from "../../../../components/DraftEbayFormInner/partials/ListingUrl/hooks/useListingUrl";
import {
  EDIT_INVENTORY_LINKS,
  INVENTORY_GROUP_LINKS,
} from "../../../../../inventory/config";
import { useHistory, useRouteMatch } from "react-router-dom";
import { MARKETPLACES_NAMES_REQUEST } from "../../../../constants";
import { useDeleteDraft } from "../../../../hooks/useDeleteDraft";
import { DRAFTS_GROUP_LINKS } from "../../../../config";
import { useCustomModal } from "../../../../hooks/useCustomModal";
import ToasterService from "../../../../../../services/ToastService";
import {
  INVENTORY_UPDATE_SUCCESS,
  PRODUCT_LISTED_SUCCESS,
} from "../../../../../../base/constants/messages";
import { generateFormModelWithBool } from "../../../../helpers/generateFormModelWithBool";
import { useProductStatusLabel } from "../../../../hooks/useProductStatusLabel";
import { usePathIncludes } from "../../../../hooks/usePathIncludes";
import { useEtsyIsConnect } from "../../../../hooks/useEtsyIsConnect";
import { useDelistAction } from "../../../../hooks/useProductStatus";
import { getCountOfActiveItems } from "../../../../helpers/getTableFieldValue";
import AutoReloadMarketplace from "../../../../components/AutoReloadMarketplace";
import "../../../../index.scss";
import { EDIT_DRAFT_LINKS } from "../../../EditDraft/config";
import { CREATE_DRAFT_LINKS } from "../../config";
import { PRODUCT_TYPE } from "../../../../../../base/constants/product";
import {
  getTouchedObj,
  scrollToErrors,
} from "../../../../hooks/useFormikScrollToErrors";
import { useExtensionInstall } from "../../../../hooks/Poshmark/useExtensionInstall";
import InstallExtensionPlaceholder from "../../../../components/InstallExtensionPlaceholder";
import ConnectPlaceholder from "../../../../components/ConnectPlaceholder";
import { useDraftConnectionBadget } from "../../../../hooks/useDraftConnectionBadget";
import ConnectedStatus from "../../../../components/ConnectedStatus/ConnectedStatus";
import DraftUtils from "../../../../utils/DraftUtils";
import ProgressBar from "../../../../../sold/components/ProgressBar";
import { MarketplaceFormMonitor } from "../../../../components/MarketplaceFormMonitor";

const CreateDraftEtsy = ({
  updateFullForm,
  fullFormData,
  onSave,
  onSaveAndCreateNew,
  onHandleDelete,
  fetchedData,
  toggleStatus,
  isDelistBtnDisabled,
  isDisableCreateDraft,
  setFormLoading = () => {},
}) => {
  const [marketplaceData, updateMarketplaceData] = useState(null);
  const marketplaceService = useService(MarketPlaceService);
  const [processing, { registerPromise }] = useLoading();
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processMessage, setProcessMessage] = useState("");

  const showProgressBar = (message) => {
    setLoading(true);
    setProcessMessage(message);
  };

  const hideProgressBar = () => {
    setLoading(false);
    setProcessMessage("");
  };

  const formRef = useRef();
  const { isInstalled } = useExtensionInstall();
  const {
    params: { draftId },
  } = useRouteMatch();

  const history = useHistory();
  /**
   * @type {EtsyService}
   */
  const etsy = useService(EtsyService);
  /**
   * @type {ToasterService}
   */
  const toastr = useService(ToasterService);

  const handleUpdateMarketplace = useCallback(() => {
    marketplaceService
      .getMarketplace()
      .then(({ data }) => updateMarketplaceData({ data }))
      .catch((e) => Bugsnag.notify(e));
  }, [marketplaceService]);

  useEffect(() => {
    handleUpdateMarketplace();
  }, [handleUpdateMarketplace]);

  const formPartials = new FormPartials(FORM_TYPES.ETSY, true);

  const onCatchError = useHanleSaveDraftErrors();

  const handleSave = useCallback(
    (values, { setErrors, setTouched }) => {
      showProgressBar("Saving your changes");
      return registerPromise(
        onSave(values).catch((err) =>
          onCatchError(err, () => {
            Bugsnag.notify(err);
            setTouched(highlightDraftFields, false);
            setErrors(highlightDraftFields);
          })
        )
      ).finally(hideProgressBar);
    },

    [onSave, registerPromise]
  );

  const { reloadPage } = usePathIncludes(INVENTORY_GROUP_LINKS.BASE);

  const handlePost = useCallback(
    (values) => {
      showProgressBar("Posting item to Etsy");

      return registerPromise(
        onSaveAndCreateNew(values, null, "")
          .then(({ data: { id: draftId } }) => {
            return etsy
              .postProduct(draftId)
              .then(() => {
                toastr.success(PRODUCT_LISTED_SUCCESS);
                history.replace(
                  EDIT_INVENTORY_LINKS.ETSY.replace(":draftId", draftId)
                );
                reloadPage();
              })
              .catch((baseError) => {
                Bugsnag.notify(baseError);
                const { error } = baseError;
                const isCreateEtsy = history.location.pathname.includes(
                  CREATE_DRAFT_LINKS.ETSY
                );
                if (isCreateEtsy) {
                  history.replace(
                    EDIT_DRAFT_LINKS.ETSY.replace(":draftId", draftId)
                  );
                }
                if (fetchedData.status === PRODUCT_TYPE.ACTIVE) {
                  history.replace(
                    EDIT_INVENTORY_LINKS.ETSY.replace(":draftId", draftId)
                  );
                } else {
                  history.replace(
                    EDIT_DRAFT_LINKS.ETSY.replace(":draftId", draftId)
                  );
                }
                toastr.error(error.message);
              });
          })
          .catch((err) => {
            return onCatchError(err, () => Bugsnag.notify(err));
          })
      ).finally(hideProgressBar);
    },

    [onSaveAndCreateNew, registerPromise, history, etsy]
  );

  const handleSaveNewDraftSubmit = useCallback(
    (values, { resetForm, setErrors, setTouched }) => {
      showProgressBar("Saving your changes");
      return registerPromise(
        onSaveAndCreateNew(values)
          .then(() => {
            resetForm();
          })
          .catch((err) =>
            onCatchError(err, () => {
              Bugsnag.notify(err);
              setTouched(highlightDraftFields, false);
              setErrors(highlightDraftFields);
            })
          )
      ).finally(hideProgressBar);
    },

    [onSaveAndCreateNew, registerPromise]
  );

  const {
    view: popupView,
    onDelete,
    deleting,
  } = useDeleteDraft(() => {
    history.push(DRAFTS_GROUP_LINKS.BASE);
  });

  const handleDelete = () => {
    onHandleDelete();
    onDelete(draftId);
  };

  const handleUpdateAction = () => {
    fullFormData.productEtsySpecificFields.isUpdated = true;

    showProgressBar("Updating your changes");
    return registerPromise(
      onSave(fullFormData, "", null)
        .then(() => {
          toastr.success(INVENTORY_UPDATE_SUCCESS);
          history.replace(
            EDIT_INVENTORY_LINKS.ETSY.replace(":draftId", draftId)
          );

          const model = generateFormModelWithBool(formRef.current?.values);

          formRef.current?.setTouched(model, false);
          formRef.current?.setErrors(model);
        })
        .catch((e) => Bugsnag.notify(e))
    ).finally(hideProgressBar);
  };

  const { modal: modalUpdate, onShowModal: onShowModalUpdate } = useCustomModal(
    {
      title: "Update item",
      message: "Are you sure you want to apply changes on your Etsy listing?",
      onAccept: handleUpdateAction,
    }
  );

  const { delist } = useDelistAction({
    onDelist: onHandleDelete,
    onToggleStatus: toggleStatus,
  });

  const handleDelistAction = () => {
    const countOfActiveListing = getCountOfActiveItems(fetchedData);

    showProgressBar("Delisting");
    registerPromise(
      delist({
        draftId,
        marketplace: MARKETPLACES_NAMES_REQUEST.etsy,
        countOfActiveListing,
      })
    ).finally(hideProgressBar);
  };

  const { modal: modalDelist, onShowModal: onShowModalDelist } = useCustomModal(
    {
      title: "Delist item",
      message: "Do you want to delist the item from  Etsy?",
      onAccept: handleDelistAction,
    }
  );

  const initialValues = useInitialFormValues(
    fullFormData,
    formPartials.initialValues,
    formPartials.mapFields,
    formPartials.formKey
  );

  const { isPosted, link } = useListingUrl({ fetchedData });

  const [getProductStatusLabel] = useProductStatusLabel();
  const { isConnected } = useEtsyIsConnect();
  const { showConnectBadget } = useDraftConnectionBadget();

  useEffect(() => {
    setFormLoading(processing || categoriesLoading);
  }, [processing, categoriesLoading]);

  useEffect(() => {
    document.getElementById("etsy-form").reset();
  }, []);

  return (
    <Card className="mt-3">
      <CardBody>
        {loading && <ProgressBar isActive processMessage={processMessage} />}
        {popupView}
        {modalUpdate}
        {modalDelist}
        <Formik
          initialValues={initialValues}
          validationSchema={formPartials.validations}
          onSubmit={(_, helpers) => handlePost(fullFormData, helpers)}
          innerRef={formRef}
          isInitialValid={false}
          initialTouched={false}
        >
          {(helpers) => {
            const {
              handleSubmit,
              setFieldValue,
              values,
              isValid,
              dirty,
              touched,
            } = helpers;
            return (
              <form
                className="form-horizontal p-2"
                onSubmit={handleSubmit}
                id="etsy-form"
              >
                <MarketplaceFormMonitor
                  item={fullFormData}
                  formKey="productEtsySpecificFields"
                  localForm={values}
                  updateFullForm={updateFullForm}
                  touchedFields={touched}
                />

                <DraftHeader
                  showSettings
                  title={"Etsy form"}
                  status={getProductStatusLabel(
                    fetchedData?.productEtsySpecificFields?.status
                  )}
                  marketplace={MARKETPLACES_NAMES_REQUEST.etsy}
                  fetchedData={fetchedData}
                  onHandleDelete={onHandleDelete}
                  toggleStatus={toggleStatus}
                  marketplaceData={marketplaceData}
                  marketplaceAccountKey="etsy"
                  isConnected={isConnected}
                >
                  {isPosted && (
                    <>
                      <CustomButton
                        className="filled-sm w-80 mr-2"
                        onClick={() => window.open(link, "_blank")}
                        disabled={!!formRef?.current?.errors?.link}
                      >
                        View item
                      </CustomButton>
                      <CustomButton
                        className="filled-sm mr-2"
                        onClick={
                          isValid
                            ? onShowModalUpdate
                            : () => {
                                helpers.validateForm().then((errors) => {
                                  helpers
                                    .setTouched(getTouchedObj(errors))
                                    .then(scrollToErrors)
                                    .catch((e) => Bugsnag.notify(e));
                                });
                              }
                        }
                        disabled={
                          !dirty ||
                          processing ||
                          !marketplaceData?.data.etsyAccount
                        }
                      >
                        Revise
                      </CustomButton>
                    </>
                  )}
                  <ConnectButtonEtsy
                    handleUpdateMarketplace={handleUpdateMarketplace}
                    marketplaceData={marketplaceData}
                  />
                </DraftHeader>

                {!isInstalled && showConnectBadget && (
                  <InstallExtensionPlaceholder marketPlace={"Etsy"} />
                )}
                {isInstalled &&
                  showConnectBadget &&
                  !marketplaceData?.data.etsyAccount && (
                    <ConnectPlaceholder>
                      We had troubles accessing your Etsy account. Please be
                      sure you are logged into etsy.com in a different tab. Once
                      you are logged in, click “Connect” so we can try again.
                    </ConnectPlaceholder>
                  )}

                <DraftEtsyFormInner
                  setFieldValue={setFieldValue}
                  values={values}
                  postedInfo={{ isPosted, link }}
                  formRef={formRef}
                  marketplaceData={marketplaceData}
                  suggestedFields={DraftUtils.extractSuggestedFields(
                    fullFormData
                  )}
                  isLoading={setCategoriesLoading}
                />
                <Row>
                  <Col className="d-flex">
                    <CreationDate
                      text="Creation date:"
                      date={values.createdAt}
                      className="mr-60"
                    />
                    {!!values.updatedAt && (
                      <CreationDate
                        className="mr-60"
                        text="Last Updated:"
                        date={values.updatedAt}
                      />
                    )}
                    <ConnectedStatus
                      text="Status:"
                      className="mr-60"
                      isActive={
                        !!marketplaceData?.data.etsyAccount?.id && isConnected
                      }
                      isConnected={isConnected}
                      isExpired={marketplaceData?.data?.etsyAccount?.isExpired}
                    />
                  </Col>

                  <Col
                    ms="7"
                    className="d-flex justify-content-md-end align-items-center"
                  >
                    {!isPosted && (
                      <>
                        {draftId && (
                          <CustomButton
                            className="outline-danger w-80 mr-2"
                            onClick={handleDelete}
                            disabled={deleting || processing}
                          >
                            Delete
                          </CustomButton>
                        )}
                        <CustomButton
                          onClick={() => handleSave(fullFormData, helpers)}
                          disabled={processing}
                          type={"button"}
                          className="filled-primary w-80 mr-2"
                        >
                          Save
                        </CustomButton>
                        <CustomButton
                          type={"button"}
                          disabled={processing || isDisableCreateDraft}
                          onClick={() =>
                            handleSaveNewDraftSubmit(fullFormData, helpers)
                          }
                          className="outline-primary mr-2 save-new-btn"
                        >
                          Save + New draft
                        </CustomButton>

                        <CustomButton
                          className="outline-primary w-80 mr-2"
                          disabled={
                            processing || !marketplaceData?.data.etsyAccount
                          }
                          type={isValid ? "submit" : "button"}
                          onClick={
                            isValid
                              ? undefined
                              : () => {
                                  helpers.validateForm().then((errors) => {
                                    helpers
                                      .setTouched(getTouchedObj(errors))
                                      .then(scrollToErrors)
                                      .catch((e) => Bugsnag.notify(e));
                                  });
                                }
                          }
                        >
                          Post
                        </CustomButton>
                      </>
                    )}

                    {isPosted && (
                      <>
                        <CustomButton
                          className="outline-danger w-80 mr-2"
                          onClick={onShowModalDelist}
                          disabled={processing || isDelistBtnDisabled}
                        >
                          Delist
                        </CustomButton>
                        <CustomButton
                          className="filled-primary w-80 mr-2"
                          onClick={() => window.open(link, "_blank")}
                          disabled={!!formRef?.current?.errors?.link}
                        >
                          View item
                        </CustomButton>
                        <CustomButton
                          className="filled-primary w-80"
                          disabled={
                            processing ||
                            !dirty ||
                            !marketplaceData?.data.etsyAccount
                          }
                          onClick={
                            isValid
                              ? onShowModalUpdate
                              : () => {
                                  helpers.validateForm().then((errors) => {
                                    helpers
                                      .setTouched(getTouchedObj(errors))
                                      .then(scrollToErrors)
                                      .catch((e) => Bugsnag.notify(e));
                                  });
                                }
                          }
                        >
                          Revise
                        </CustomButton>
                      </>
                    )}
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default (props) => {
  const { isPending, updateIsPending } = useEtsyIsConnect();
  const reload = isPending;

  return (
    <AutoReloadMarketplace
      reload={reload}
      onReload={() => updateIsPending(false)}
    >
      <CreateDraftEtsy {...props} key={reload} />
    </AutoReloadMarketplace>
  );
};
