import { useCallback, useEffect, useState } from "react";
import { getValidationFields } from "../helpers/getValidationFields";

export const useDisabledSubmit = (
  requiredFields = [],
  validationFields = {}
) => {
  const [fields, updateFields] = useState(getValidationFields(requiredFields));
  const [isDisabled, updateIsDisabled] = useState(true);
  const [errors, updateErrors] = useState({});

  const onChange = useCallback(
    (event) => {
      const { complete, elementType, error } = event;
      const isValidFields = complete && !error;

      updateFields((fields) => ({
        ...fields,
        [elementType]: isValidFields,
      }));

      const validationField = validationFields[elementType];
      if (validationField) {
        const codeOfError = error?.code;

        updateErrors((errors) => ({
          ...errors,
          [elementType]: validationField?.[codeOfError] || "",
        }));
      }
    },
    [validationFields]
  );

  useEffect(() => {
    const isValidForm = Object.values(fields).every((value) => value);
    updateIsDisabled(!isValidForm);
  }, [fields]);

  return {
    isDisabled,
    onChange,
    errors,
  };
};
