import { DEFAULT_DECIMAL_PART_LIMIT } from "../../bookkeeping/constants";
import { toNumber } from "../../sold/helpers";
import { PoshmarkBaseProduct } from "./PoshmarkBaseProduct";
import { PoshmarkHelpers } from "./PoshmarkHelpers";

export class PoshmarkSoldBundle extends PoshmarkBaseProduct {
  constructor(order, products) {
    super();
    this.products = products;
    this.order = order;
  }

  getProducts() {
    const sumOfProducts = this.products.reduce((sum, { price }) => {
      return sum + price;
    }, 0);

    return this.products.map((draft) => {
      const draftData = this.getDraftFields(draft);
      const { price } = draft;

      return {
        ...draftData,
        ...this.getOrderFields(this.order, { price, sumOfProducts }),
      };
    });
  }

  getOrderFields(order, { price, sumOfProducts }) {
    const { seller_shipping_discount_amount, pm_fee_amount } = order;
    const totalBundlePrice = toNumber(order.total_price_amount?.val);

    const priceSold = this.calcPriceSold(
      totalBundlePrice,
      sumOfProducts,
      price
    ).toFixed(DEFAULT_DECIMAL_PART_LIMIT);

    const marketplaceFees = this.calcMarketplaceFees(
      priceSold,
      totalBundlePrice,
      pm_fee_amount?.val
    ).toFixed(DEFAULT_DECIMAL_PART_LIMIT);

    const data = {
      ...PoshmarkHelpers.getOrderFields(order),
      priceSold,
      marketplaceFees,
    };

    if (seller_shipping_discount_amount?.val) {
      // data.discountedShipping = seller_shipping_discount_amount?.val;
      data.shippingExpenses = this.calcShippingCost(
        seller_shipping_discount_amount?.val,
        this.products.length
      ).toFixed(DEFAULT_DECIMAL_PART_LIMIT);
    }

    return data;
  }

  calcPriceSold(totalBundlePrice, sumOfItemsPrice, itemPrice) {
    return (totalBundlePrice / sumOfItemsPrice) * itemPrice;
  }

  calcMarketplaceFees(soldItemPrice, totalBundlePrice, totalMarketplaceFees) {
    return (soldItemPrice / totalBundlePrice) * totalMarketplaceFees;
  }

  calcShippingCost(shippingCost, countOfItems) {
    return shippingCost / countOfItems;
  }
}
