import React from "react"
import "./index.scss"

const DraftCaption = ({children}) => {
	return (
		<p className={"draft-caption-text"}>
			{children}
		</p>
	)
};

export default DraftCaption
