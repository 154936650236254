import Http from "./Http";

class EmployeesService extends Http {
  static $displayName = "EmployeesService";

  async getEmployees(values) {
    return await this.get("/employees", { params: values });
  }

  async changeEmployeeStatus({ id, status }) {
    return await this.put(`/employees/${id}/status`, { status });
  }

  async sendInvite(values) {
    return await this.post("/employees", values);
  }

  async deleteEmployee(id) {
    return await this.delete(`/employees/${id}`, { params: id });
  }

  verifyEmployee(token) {
    return this.get("employees/verifications/password", {
      params: { token }
    })
  }

  createPassword(model) {
    return this.post("employees/verifications/password", model)
  }
}

export default EmployeesService;
