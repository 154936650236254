import React from "react";
import { Formik, Form } from "formik";

import FormikTextarea from "../../../../base/components/FormikTextarea";
import CustomButton from "../../../../base/components/CustomButton";
import { commentMessage } from "../../forms/commentMessage";

import "./styles.scss";
import { MAX_MESSAGE_LENGTH } from "../../../../validation/lengthConstants";

const initialValues = { text: "" };

const CommentForm = ({
  onSubmit,
  label = "Add comment",
  placeholder = "Write something",
}) => {
  const handleSubmit = async (values, { resetForm }) => {
    await onSubmit(values);
    resetForm();
  };

  return (
    <div className="mt-4">
      <Formik
        validationSchema={commentMessage}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {({ isValid, dirty }) => (
          <Form>
            <FormikTextarea
              name="text"
              label={label}
              placeholder={placeholder}
              rows="3"
              maxLength={MAX_MESSAGE_LENGTH}
            />
            <div className="d-flex justify-content-end">
              <CustomButton
                type="submit"
                className="filled-primary comment__button task-form__button"
                disabled={!isValid || !dirty}
              >
                Send
              </CustomButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CommentForm;
