/* eslint-disable react/prop-types */
import React from "react";
import { useHistory } from "react-router-dom";

import CustomButton from "../../../../base/components/CustomButton";
import { DRAFTS_GROUP_LINKS } from "../../../drafts/config";

const AddDraftButton = ({ className = "mt-3", disabled }) => {
  const history = useHistory();

  const goToCreateDraft = () => {
    history.push(DRAFTS_GROUP_LINKS.ADD_DRAFT);
  };

  return (
    <CustomButton
      onClick={goToCreateDraft}
      className={`filled-primary ${className}`}
      disabled={disabled}
    >
      + Add new
    </CustomButton>
  );
};

export default AddDraftButton;
