import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import SizeTab from "../SizeTab";
import SizeSystem from "../SizeSystem";
import SizeItem from "../SizeItem";
import SizeCustom from "../SizeCustom";

const useUpdateEffect = (onEffect) => {
    const mounted = useRef(false);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true
            return
        }

        onEffect()
    }, [onEffect])
}


const SizeDropdownContent = ({tabs, sizeSystems, sizeSets, values, setFieldValue, tabId, systemId}) => {
    const [currentSystem, updateCurrentSystem] = useState({})
    const [currentTab, updateCurrentTab] = useState(tabId)

    useUpdateEffect(useCallback(() => {
        const result = sizeSystems.find((system) => system.id === systemId) || sizeSystems[0]
        updateCurrentSystem(result)

        updateCurrentTab(tabId)
        }, [updateCurrentSystem, sizeSystems, systemId, updateCurrentTab, tabId])
    )

    const currentSizeSet = useMemo(() => {
        if (currentTab > sizeSets.length) {
            updateCurrentTab(tabId)
        } else {
            updateCurrentTab(currentTab)
        }
        return sizeSets[currentTab]?.size_systems[currentSystem?.id]?.sizes || []
    }, [sizeSets, currentSystem?.id, currentTab, updateCurrentTab, tabId])

    return(
        <div className="p-3">
            <div className="d-flex justify-content-between align-items-center">
                <SizeTab tabs={tabs} current={currentTab} updateTab={updateCurrentTab}/>
                <SizeSystem sizeSystems={sizeSystems} currentSystem={currentSystem}
                            updateCurrentSystem={updateCurrentSystem}/>
            </div>
            <div className={"size-divider"}/>
            {currentSizeSet.length
                ?
                <div className="d-flex flex-wrap">
                    {currentSizeSet.map((size) => {
                        return(
                            <SizeItem
                                key={size.id}
                                size={size}
                                values={values}
                                setFieldValue={setFieldValue}
                                currentSystem={currentSystem}
                                currentTab={currentTab}
                            />
                        )
                    })
                    }
                </div>
                :
                <SizeCustom
                    values={values}
                    setFieldValue={setFieldValue}
                    currentSystem={currentSystem}
                    currentTab={currentTab}
                    sizeSets={sizeSets}
                />
            }
        </div>
    )
}

export default SizeDropdownContent;