/* eslint-disable react/prop-types */
import ProductPreview from "../../../drafts/components/ProductPreview";
import WithHighlight from "../../../drafts/components/WithHighlight";
import React from "react";
import HeaderFormatter from "../../../drafts/components/Table/HeaderFormatter";
import { FormatPrice } from "../../../drafts/helpers/format";
import {
  ActionCopy,
  ActionDelete,
  ActionOpenInTab,
} from "../../../drafts/pages/ListDrafts/columns";
import { PRODUCT_TYPE } from "../../../../base/constants/product";
import MarketplaceCell from "../../components/MarketplaceCell";

import { useStatelessProvider } from "../../../../base/hooks/useProvider";
import { TableContext } from "../../../drafts/contexts/table";
import {
  getTableFieldValue,
  getTableFieldValuePostedStatus,
} from "../../../drafts/helpers/getTableFieldValue";
import { DRAFT_FIELDS } from "../../../drafts/constants";
import { MARKETPLACES_LIST } from "../../../sold/constants";
import DateListingHeaderFormatter from "../../../drafts/components/Table/DateListingHeaderFormater";
import DateListingColumn from "../../../drafts/components/Table/DateListingColumn";

export const IsAcceptCopy = ({ children }) => {
  const [{ isAcceptCopy }] = useStatelessProvider(TableContext);

  return <>{isAcceptCopy && children}</>;
};

export function TableStructure() {
  return [
    {
      text: "Title",
      style: {
        width: "31%",
      },
      headerFormatter: HeaderFormatter,
      formatter: (_, model) => {
        const titleValue = getTableFieldValuePostedStatus(
          model,
          DRAFT_FIELDS.TITLE,
          "-"
        );

        return (
          <div className={"drafts-table-cell-title"}>
            <ProductPreview src={model.files[0]?.link} />
            <WithHighlight text={titleValue || "-"} />
          </div>
        );
      },
      sort: true,
      dataField: "title",
    },
    {
      sort: true,

      headerFormatter: HeaderFormatter,
      text: "SKU",
      formatter: (_, model) => {
        const skuValue =
          getTableFieldValuePostedStatus(model, DRAFT_FIELDS.SKU, "") ||
          getTableFieldValue(model, DRAFT_FIELDS.SKU, "-");
        return (
          <WithHighlight
            text={skuValue || "-"}
            className="cell-semibold"
            exact
          />
        );
      },
      dataField: "sku",
    },
    {
      text: "Marketplace",
      dataField: "updatedAt",
      formatter: (_, product) => {
        const marketplaces = [];

        MARKETPLACES_LIST.forEach(({ key, label }) => {
          if (product[key] && product[key].status === PRODUCT_TYPE.ACTIVE) {
            marketplaces.push(label);
          }
        });

        return marketplaces.map((market) => (
          <MarketplaceCell key={market}>{market}</MarketplaceCell>
        ));
      },
    },
    {
      dataField: "statusAt",
      headerFormatter: DateListingHeaderFormatter,
      sort: false,
      style: {
        width: "15.5%",
      },
      text: "Date listed",
      formatter: DateListingColumn,
    },
    {
      dataField: "listingPrice",
      headerFormatter: HeaderFormatter,
      sort: true,
      formatter: (_, model) => {
        const priceValue = getTableFieldValuePostedStatus(
          model,
          DRAFT_FIELDS.PRICE
        );
        const additionalPrice = getTableFieldValuePostedStatus(
          model,
          DRAFT_FIELDS.ADDITIONAL_PRICE
        );
        return FormatPrice(priceValue || additionalPrice);
      },
      text: "Price",
    },
    {
      dataField: "id",
      text: "Actions",
      headerAlign: "right",
      align: "right",
      style: {
        width: "14%",
      },
      formatter: (_, data) => {
        const { id } = data;

        return (
          <div
            className={"table-actions"}
            onClick={(event) => event.stopPropagation()}
          >
            <ActionOpenInTab data={data} />
            <IsAcceptCopy>
              <ActionCopy id={id} />
            </IsAcceptCopy>
            <ActionDelete id={id} />
          </div>
        );
      },
    },
  ];
}
