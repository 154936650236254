/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import FilterDropdown from "../../../../base/components/FilterDropdown";
import { MarketplacesFilterOptions } from "../../../drafts/constants";
import styles from "./index.module.scss";
import Button from "../../../../base/components/Button";

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
  gap: 4px;

  &.mobile {
    display: none;
  }
`;

const FilterContainer = styled(FilterWrapper)`
  margin-bottom: 16px;

  @media (max-width: 600px) {
    flex-direction: column;

    .mobile {
      display: flex;
    }
  }
`;

const PickingFilters = ({ sort, setSort }) => {
  let defaultButtonClass = "outline-primary";
  let activeButtonClass = "filled-primary";

  return (
    <FilterContainer>
      <FilterWrapper>
        <span className={styles.title}>Filter by marketplace:</span>
        <FilterDropdown
          className="ml-3"
          withAllOption
          filterName="marketplaceType"
          filterOptions={MarketplacesFilterOptions}
        />
      </FilterWrapper>
      <FilterWrapper className="mobile">
        <span className={styles.title}>Sort by SKU</span>
        <Button
          type="button"
          className={sort === -1 ? activeButtonClass : defaultButtonClass}
          onClick={() => setSort(-1)}
        >
          Ascending
        </Button>
        <Button
          type="button"
          className={sort === 1 ? activeButtonClass : defaultButtonClass}
          onClick={() => setSort(1)}
        >
          Descending
        </Button>
      </FilterWrapper>
    </FilterContainer>
  );
};

PickingFilters.propTypes = {
  containerClassName: PropTypes.string,
};

export default PickingFilters;
