import { useEffect, useState } from "react";

import { DEFAULT_DEBOUNCE_DELAY } from "../constants/shared";

export const useDebounce = (value, delay = DEFAULT_DEBOUNCE_DELAY) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const useAccumulateDebounce = (onAccumulate) => {
  const [state, update] = useState([]);

  useEffect(() => {
    let time = 0;

    if (state.length) {
      time = setTimeout(() => {
        onAccumulate((prev) => [...prev, ...state]);
        update([]);
      }, 1000);
    }
    return () => clearTimeout(time);
  }, [state, onAccumulate]);

  return update;
};
