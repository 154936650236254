import { useMemo } from "react";

import { useProfile } from "../../profile/hooks/useProfile";

import { SUBSCRIPTIONS_STATUSES } from "../../drafts/pages/Settings/page/EmployeeManagement/constants";

export const useCheckIsNeedToLogout = () => {
  const [{ activeSubscription }] = useProfile();

  const isLogoutEmployee = useMemo(
    () =>
      !activeSubscription ||
      activeSubscription?.type === SUBSCRIPTIONS_STATUSES.expired,
    [activeSubscription]
  );

  return [isLogoutEmployee];
};
