import React from "react";
import { CalendarContainer } from "react-datepicker";
import PropTypes from "prop-types";

import CalendarContainerRightPanel from "../CalendarContainerRightPanel";

import styles from "./index.module.scss";

const AnalyticCalendarContainer = ({
  className,
  dateRangeType,
  onChangeDate,
  children,
}) => {
  return (
    <div className={styles.calendarChildrenWrapper}>
      <CalendarContainer className={className}>{children}</CalendarContainer>
      <CalendarContainerRightPanel
        dateRangeType={dateRangeType}
        onChangeDate={onChangeDate}
      />
    </div>
  );
};

AnalyticCalendarContainer.propTypes = {
  className: PropTypes.string,
  dateRangeType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  onChangeDate: PropTypes.func,
  children: PropTypes.any,
};

export default AnalyticCalendarContainer;
