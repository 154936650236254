import {useService} from "./useService";
import StorageService from "../../services/StorageService";
import {useCallback, useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";

export const usePrevLocation = (storageKey) => {
    /**
     * @type {StorageService}
     */
    const storage = useService(StorageService);
    const location = useLocation();
    const prevPath = useRef();

    useEffect(() => {
        prevPath.current = storage.get(storageKey)
    }, [storage, prevPath, storageKey])

    const saveLocation = useCallback(() => {
        storage.set(storageKey, location.pathname + location?.search)
    }, [storage, location, storageKey])

    return {
        prevPath,
        saveLocation,
    }
}