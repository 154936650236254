import React from 'react';
import PropTypes from "prop-types";

import StripeForm from '../../../../../../../../base/components/StripeForm';
import { useStripeSubmit } from '../../hooks/useStripeSubmit';

const StripeFormInner = ({ onUpdateCreditCard }) => {
  const { handleSubmit, handleCancel, isLoading } = useStripeSubmit(onUpdateCreditCard);

  return (
    <StripeForm 
      onSubmit={handleSubmit} 
      onCancel={handleCancel} 
      isLoading={isLoading}
    />
  )
};

StripeFormInner.propTypes = {
  onUpdateCreditCard: PropTypes.func
}

export default StripeFormInner;