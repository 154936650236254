import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormGroup } from "reactstrap";
import classNames from "classnames";
import { useField } from "formik";

import { Popout } from "./Select/DropdownSelect";
import { getConfigInitialValue } from "../../pages/drafts/helpers/getConfigInitialValue";
import ErrorMessageDefault from "./ErrorMessageDefault";
import { useTranslate } from "../hooks/useTranslate";
import DotsLoader from "./DotLoader";

const FormikDropdownSelect = ({
  name,
  placeholder,
  label,
  onGetAsyncOptions,
  onChange,
  onMount,
  loading = false,
  ErrorMessage = ErrorMessageDefault,
  onInitBreadcrumbs = (id) => [],
  initialValue,
  innerRef,
  filterOption,
  isHideLabel = false,
  setFieldValue,
}) => {
  const [field, { error, touched }, helpers] = useField({ name });
  const [translate] = useTranslate();

  const [breadcrumbs, updateBreadcrumbs] = useState(() => {
    if (field.value) {
      return onInitBreadcrumbs(field.value);
    }

    return [];
  });

  const [value, updateValue] = useState(
    initialValue ? getConfigInitialValue(initialValue) : ""
  );

  useEffect(() => {
    if (!field.value) {
      updateBreadcrumbs([]);
    }
  }, [field.value]);

  const onClear = useCallback(() => {
    helpers.setValue(null);
    updateValue(null);
    setFieldValue("category", null);
  }, [helpers]);

  const handleChange = useCallback(
    (value) => {
      const newValue = onChange ? onChange(value) : value;
      helpers.setValue(newValue);
      updateValue(value);
    },
    [helpers, onChange]
  );

  return (
    <FormGroup className="mb-3">
      {label && !isHideLabel && (
        <label
          htmlFor={name}
          className={classNames({ "text-danger": error && touched })}
        >
          {label} {loading && <DotsLoader />}
        </label>
      )}
      <Popout
        value={value}
        breadcrumbs={breadcrumbs}
        onUpdateBreadcrumbs={updateBreadcrumbs}
        onSelect={handleChange}
        placeholder={placeholder}
        onGetAsyncOptions={onGetAsyncOptions}
        className={error && touched ? "is-invalid" : ""}
        filterOption={filterOption}
        onClear={onClear}
        innerRef={innerRef}
      >
        <ErrorMessage name={name}>{translate(error, { label })}</ErrorMessage>
      </Popout>
    </FormGroup>
  );
};

FormikDropdownSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  onGetAsyncOptions: PropTypes.func,
  initialValue: PropTypes.any,
  isHideLabel: PropTypes.bool,
};

export default FormikDropdownSelect;
