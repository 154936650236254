import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { getFormattedDate } from "../../helpers/format";

const CreationDate = ({ text, date, className }) => {
  const actualDate = useMemo(() => {
    return date ? getFormattedDate(date) : "-";
  }, [date]);

  return (
    <div className={className}>
      <p className="md-title-grey mb-0 text-uppercase">{text}</p>
      <p className="regular-green mb-0">{actualDate}</p>
    </div>
  );
};

CreationDate.propTypes = {
  text: PropTypes.string.isRequired,
  date: PropTypes.any,
  className: PropTypes.string,
};

export default CreationDate;
