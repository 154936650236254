import React, { useRef } from "react";
import { Tooltip } from "reactstrap";

import IconButton from "../../../drafts/components/IconButton";
import { ReactComponent as Message } from "../Icons/Message.svg";
import { ReactComponent as Edit } from "../Icons/EditTask.svg";
import { ReactComponent as Delete } from "../Icons/Delete.svg";
import { ReactComponent as ArrowDown } from "../Icons/ArrowDown.svg";
import { useTooltipProps } from "../../../sold/pages/SoldList/table";
import { DEFAULT_COUNT } from "../../constants/default";

import "./styles.scss";

const ActionsButtons = ({
  isOpen,
  onDeleteTask,
  onEditTask,
  isAssignee,
  commentsCount,
}) => {
  const arrowRef = useRef(null);

  const handleEditTask = (event) => {
    event.stopPropagation();
    onEditTask();
  };

  const handleDeleteTask = (event) => {
    event.stopPropagation();
    onDeleteTask();
  };

  return (
    <>
      <div className="flex-grow-1" />
      <IconButton
        className={`action-icon-button d-flex align-items-center${
          !isAssignee ? " action-icon-button__without-actions" : ""
        }`}
      >
        <Message />{" "}
        <p className="mb-0 ms-1 fw-medium font-size-13 action-icon-button__text">
          ({commentsCount || DEFAULT_COUNT})
        </p>
      </IconButton>
      {isAssignee && (
        <>
          <IconButton
            onClick={handleEditTask}
            className="ms-2 action-icon-button d-flex align-items-center"
          >
            <Edit />
          </IconButton>
          <IconButton
            onClick={handleDeleteTask}
            className="ms-2 action-icon-button d-flex align-items-center"
          >
            <Delete />
          </IconButton>
        </>
      )}

      <div className="flex-grow-1" />
      <IconButton
        ref={arrowRef}
        className={`ms-2 action-icon-button d-flex align-items-center ${
          isOpen ? "is-active" : ""
        }`}
      >
        <ArrowDown />
      </IconButton>

      <Tooltip placement="top" {...useTooltipProps(arrowRef)}>
        Show details
      </Tooltip>
    </>
  );
};

export default ActionsButtons;
