import { FORM_TYPES } from "../drafts/const/form";

export const INVENTORY_GROUP_LINKS = {
  BASE: "/app/inventories",
  DETAILS: "/app/inventories/:draftId",
  DETAILS_VIEW: "/app/inventories/:draftId/view",
  DRAFT_DETAILS: "/app/drafts/:draftId/view",
};

export const EDIT_INVENTORY_LINKS = {
  BASIC: `${INVENTORY_GROUP_LINKS.DETAILS_VIEW}/${FORM_TYPES.BASE}`,
  EBAY: `${INVENTORY_GROUP_LINKS.DETAILS_VIEW}/${FORM_TYPES.EBAY}`,
  ETSY: `${INVENTORY_GROUP_LINKS.DETAILS_VIEW}/${FORM_TYPES.ETSY}`,
  POSHMARK: `${INVENTORY_GROUP_LINKS.DETAILS_VIEW}/${FORM_TYPES.POSHMARK}`,
  MERCARI: `${INVENTORY_GROUP_LINKS.DETAILS_VIEW}/${FORM_TYPES.MERCARI}`,
};
