import { useCallback, useRef, useState } from "react";
import moment from "moment";

import { useMercariIsConnect } from "../../drafts/hooks/useMercariIsConnect";
import { MARKETPLACES } from "../../../base/constants/extension";
import { useService } from "../../../base/hooks/useService";
import SoldService from "../../../services/SoldService";
import MercariExtension from "../../../services/MercariExtension";
import QueuePromises from "../../../base/classes/QueuePromises";
import {
  compareTwoDate,
  getLastSyncedDateForMarketplace,
  toNumber,
} from "../helpers";
import { MARKETPLACE_TYPES } from "../../drafts/hooks/useProductStatus/components/SoldModal/constants";
import filterObjectFalsy from "../../../base/helpers/filterObjectFalsy";
import MercariProduct from "../../inventory/classes/MercariProduct";
import MercariService from "../../../services/MercariService";

import TabConnection from "../../../services/importServices/TabConnection";

import { useDelistByMarketplace } from "./useDelistByMarketplace";

export const useGetSoldItemsMercari = () => {
  /**
   * @type {MercariExtension}
   */
  const mercari = useService(MercariExtension);

  /**
   * @type {MercariService}
   */
  const mercariService = useService(MercariService);

  /**
   * @type {TabConnection}
   */
  const tabConnection = useRef(new TabConnection(MARKETPLACES.MERKARI));

  /**
   * @type {SoldService}
   */
  const sold = useService(SoldService);

  const { isConnected } = useMercariIsConnect();
  const [isProcessing, setFetchProcessing] = useState(false);

  const itemsRef = useRef([]);
  const newSyncDate = useRef();
  const dateForFilter = useRef();

  const [delistProduct] = useDelistByMarketplace();

  const marketplace = MARKETPLACES.MERKARI;

  const onComplete = useCallback(
    (activeInventories) => {
      setFetchProcessing(true);
      const soldInventories = itemsRef.current.map(
        (product) => new MercariProduct(product)
      );

      const queue = new QueuePromises();
      const markAsSoldQueue = new QueuePromises();

      soldInventories.forEach((product) => {
        const loadData = () =>
          product?.loadAdditionalFields() || Promise.resolve();
        queue.add(loadData);
      });

      const listToMarkAsSold = [];

      return queue.finally(() => {
        const listToSync = soldInventories.filter(
          (sold) => activeInventories[sold.listingId]
        );

        listToSync.forEach((syncItem) => {
          syncItem = {
            ...syncItem,
            productId: activeInventories[syncItem.listingId]?.data?.id,
          };
          listToMarkAsSold.push(syncItem);
        });

        listToMarkAsSold.forEach((soldItem) => {
          const soldDetails = {
            priceSold: toNumber(soldItem.data?.priceSold),
            youMade: toNumber(soldItem.data?.youMade),
            marketplaceFees: toNumber(soldItem.data?.marketplaceFees),
            shippingExpenses: toNumber(soldItem.data?.shippingExpenses),
            transactionFees: toNumber(soldItem.data?.transactionFees),
            listingId: soldItem.listingId,
            buyer: soldItem.data?.buyer,
            dateSold: soldItem.data?.dateSold,
            marketplaceType: MARKETPLACE_TYPES.mercari,
          };

          const formattedSoldDetails = filterObjectFalsy(soldDetails);

          markAsSoldQueue.add(() =>
            sold
              .importSold(formattedSoldDetails)
              .finally(() =>
                delistProduct(
                  MARKETPLACES.MERKARI,
                  formattedSoldDetails.listingId,
                  activeInventories
                )
              )
          );
        });

        markAsSoldQueue.finally(() => setFetchProcessing(false));

        mercariService.updateLastSyncedDate(newSyncDate.current);
      });
    },
    [mercariService, sold, delistProduct]
  );

  const getFilteredItemsByDate = (data, dateForFilter) =>
    data.filter(({ updated }) =>
      compareTwoDate(MercariProduct.convertDate(updated), dateForFilter)
    );

  const sync = useCallback(
    (list, marketplaceData) => {
      const updateItems = (callback) => {
        itemsRef.current = callback(itemsRef.current);
      };

      dateForFilter.current = getLastSyncedDateForMarketplace(marketplaceData);
      newSyncDate.current = moment().toISOString();

      setFetchProcessing(true);

      const { unSubscribe } = mercari.importSoldProducts((response) => {
        const { data: responseData, err, marketplaceTabId } = response;

        if (err) {
          unSubscribe();
          onComplete(list);
          setFetchProcessing(false);
          return;
        }

        const {
          result: { data, processing },
        } = responseData;

        if (!data) {
          unSubscribe();
          onComplete(list);
          setFetchProcessing(false);
          return;
        }

        const filteredItems = getFilteredItemsByDate(
          data,
          dateForFilter.current
        );

        if (filteredItems.length !== data.length) {
          updateItems((prevList) => [...prevList, ...filteredItems]);

          unSubscribe();

          onComplete(list);
          tabConnection.current.disconnectById(marketplaceTabId);

          return unSubscribe;
        }

        updateItems((prevList) => [...prevList, ...data]);

        if (!processing) {
          unSubscribe();
          onComplete(list);
        }
      });

      return unSubscribe;
    },

    [mercari, itemsRef, mercariService]
  );

  return {
    isProcessing,
    sync,
    isConnected,
    marketplace,
  };
};
