import React from "react";
import TitleWrapper from "../../TitleWrapper";
import { getCurrentDate } from "../../../../drafts/helpers/getCurrentDate";
import PerformanceItem from "../PerformanceItem";
import { separateThousandth } from "../../../helpers/separateThousandth";


const Performance = ({performance}) => {

  return (
    <>
      <TitleWrapper title={"Performance"}>
        <p className="mb-0 title-text-color">{getCurrentDate()}</p>
      </TitleWrapper>
      <div>
        <PerformanceItem label={"Sales So Far This Month"} value={separateThousandth(performance?.currentMonthSales)} />
        <PerformanceItem label={"Sales Needed Per Day"} value={separateThousandth(performance?.salesNeededPerDay)} />
        <PerformanceItem label={"Sales Needed To Be On Track"} value={separateThousandth(performance?.salesNeededToBeOnTrack)} />
        <PerformanceItem label={"On Track To Make"} value={separateThousandth(performance?.onTrackToMake)} />
      </div>
    </>
  )
}

export default Performance;