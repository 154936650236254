import React, { useCallback, useState, useRef } from "react";
import PropTypes from "prop-types";
import Bugsnag from "@bugsnag/js";

import Avatar from "../../../../base/components/Avatar";
import CustomButton from "../../../../base/components/CustomButton";
import { useUploadAvatar } from "../../../profile/hooks/useUploadAvatar";
import ImageUploader from "../../../../base/components/ImageUploader";

import "./index.scss";

const AvatarStep = ({ steps, TopNavigation, onClickNext }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const [blob, setBlob] = useState(null);

  const actions = useRef({
    open: () => {},
    close: () => {},
  });

  const handleDeleteImage = () => {
    setBlob(null);
    setCroppedImage(null);
  };

  const { uploadAvatar: upload } = useUploadAvatar();

  const onOpenModal = useCallback(() => actions.current.open(), [actions]);

  const onCropImage = useCallback((image) => {
    const imageBlobUrl = URL.createObjectURL(image);
    setCroppedImage(imageBlobUrl);
    setBlob(image);
    actions.current.close();
  }, []);

  const onNext = useCallback(async () => {
    try {
      setIsLoading(true);
      await upload(blob);
      onClickNext();
    } catch (e) {
      Bugsnag.notify(e);
    } finally {
      setIsLoading(false);
    }
  }, [setIsLoading, upload, onClickNext, blob]);

  return (
    <div className="step-form-wrapper">
      <div className="step-form-container">
        <div className="steps-navigation">
          <TopNavigation steps={steps} />
        </div>
        <div className="vertical-divider" />
        <div className="step-form-content">
          <div className="edit-avatar-wrapper">
            <Avatar image={croppedImage} wrapperClassName="avatar-wrapper" />
            <CustomButton className="filled-sm button" onClick={onOpenModal}>
              {blob ? "Change photo" : "Upload photo"}
            </CustomButton>
            {!!blob && (
              <CustomButton
                className="outline-primary-sm ml-2"
                onClick={handleDeleteImage}
              >
                Delete
              </CustomButton>
            )}
          </div>
          <ImageUploader
            onCropImage={onCropImage}
            onMount={({ onOpen, onClose }) => {
              actions.current.open = onOpen;
              actions.current.close = onClose;
            }}
          />
        </div>
      </div>
      <div className="step-worm-buttons-wrapper">
        <CustomButton className="outline-primary mr-3 w-130" onClick={onNext}>
          Skip
        </CustomButton>
        <CustomButton
          className="filled-primary"
          onClick={onNext}
          disabled={isLoading}
        >
          Next
        </CustomButton>
      </div>
    </div>
  );
};

AvatarStep.propTypes = {
  steps: PropTypes.array,
  TopNavigation: PropTypes.func,
  onClickNext: PropTypes.func,
};

export default AvatarStep;
