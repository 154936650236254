import { useCallback, useRef, useState } from "react";
import moment from "moment";

import { MOMENT_MONTH } from "../../bookkeeping/constants";
import { MOMENT_DAY } from "../../expenses/constants";

export const INIT_DATE_FILTER = {
  startDate: moment().startOf(MOMENT_MONTH).toDate(),
  endDate: moment().endOf(MOMENT_MONTH).toDate(),
};

export const useAnalyticRangeDateFilter = (initialState = INIT_DATE_FILTER) => {
  const [dateFilter, setDateFilter] = useState({
    dateRangeType: null,
    dateRange: initialState,
  });

  const dateFilterRef = useRef(null);

  const onChangeDateFilter = useCallback(
    (newDateFilterValues, _, dateRangeType = null) => {
      const [startDate, endDate] = newDateFilterValues;

      const formattedStartDate = moment(startDate).startOf(MOMENT_DAY).toDate();
      const formattedEndDate = endDate
        ? moment(endDate).endOf(MOMENT_DAY).toDate()
        : endDate;

      setDateFilter({
        dateRangeType,
        dateRange: { startDate: formattedStartDate, endDate: formattedEndDate },
      });

      if (dateRangeType) dateFilterRef.current?.setOpen(false);
    },
    [setDateFilter]
  );

  const formatDate = useCallback((date) => {
    return moment(date).format();
  }, []);

  return [dateFilter, { onChangeDateFilter, formatDate }, dateFilterRef];
};
