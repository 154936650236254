import React from 'react'
import PropTypes from "prop-types";
import { DropdownItem as DropdownReactstrapItem } from 'reactstrap';

const DropdownLogoutItem = ({ text, logo: Logo, onClick }) => {
  return (
    <DropdownReactstrapItem onClick={onClick}>
      <Logo />
      <span className='ml-2'>
        {text}
      </span>      
    </DropdownReactstrapItem>
  )
};

DropdownLogoutItem.propTypes = {
  text: PropTypes.string,
  logo: PropTypes.any,
  onClick: PropTypes.func
};

export default DropdownLogoutItem
