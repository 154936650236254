import FormPartials from "../../../classes/FormPartials";
import { initialValues as initialEbayForm } from "../../../classes/forms/ebay";
import { initialValues as initialEtsyForm } from "../../../classes/forms/etsy";
import { initialValues as initialMercariForm } from "../../../classes/forms/mercari";
import { initialValues as initalPoshmarkForm } from "../../../classes/forms/poshmark";
import { FORM_TYPES } from "../../../const/form";

export const getInitialEmptyForm = () => {
  const formBasic = new FormPartials(FORM_TYPES.BASE);
  const form = Object.assign({}, formBasic.initialValues);
  form.productEbaySpecificFields = Object.assign({}, initialEbayForm);
  form.productPoshmarkSpecificFields = Object.assign({}, initalPoshmarkForm);
  form.productEtsySpecificFields = Object.assign({}, initialEtsyForm);
  form.productMercariSpecificFields = Object.assign({}, initialMercariForm);

  return form;
};
