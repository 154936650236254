import React from 'react'

const ToolTipIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.47217 12H7.50674V6.1355H8.47217V12ZM7.42847 4.57995C7.42847 4.41734 7.47543 4.28004 7.56937 4.16802C7.66678 4.05601 7.80942 4 7.99729 4C8.18515 4 8.32779 4.05601 8.42521 4.16802C8.52262 4.28004 8.57132 4.41734 8.57132 4.57995C8.57132 4.74255 8.52262 4.87805 8.42521 4.98645C8.32779 5.09485 8.18515 5.14905 7.99729 5.14905C7.80942 5.14905 7.66678 5.09485 7.56937 4.98645C7.47543 4.87805 7.42847 4.74255 7.42847 4.57995Z" fill="#495057" />
        <rect x="0.571429" y="0.571429" width="14.8571" height="14.8571" rx="7.42857" stroke="#495057" strokeWidth="1.14286" />
    </svg>
);

export default ToolTipIcon;
