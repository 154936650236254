import React, {useEffect, useMemo, useRef, useState} from "react";
import {Collapse} from "reactstrap";

import "./index.scss";

import DropdownIcon from "../DropdownIcon/DropdownIcon";
import SizeDropdownContent from "../SizeDropdownContent";
import {CUSTOM_TAB, DEFAULT_SIZE_SYSTEM, DEFAULT_TAB_ID} from "../../../../../constants";
import {useClickOutside} from "../../../../../hooks/Poshmark/useClickOutside";
import SizeUtils from "../../../../../utils/Poshmark/SizeUtils";

const SizesSelect = ({values, setFieldValue, isDisabled, sizeSets, sizeSystems}) => {
    const outsideRef = useRef();
    const [isOpenCollapse, setIsOpenCollapse] = useState(false);

    const handleToggleCollapse = () =>
        setIsOpenCollapse((prevState) => !prevState);

    const sizeTabs = useMemo(() => {
        const baseTabs = []
        sizeSets.map(size => {
            baseTabs.push({ name: size.name })
        })
        baseTabs.push({name: CUSTOM_TAB})
        return baseTabs
    }, [sizeSets])

    useClickOutside({refName: outsideRef, isOpen: isOpenCollapse, updateIsOpen: setIsOpenCollapse})

    useEffect(() => {
        setIsOpenCollapse(false)
    }, [values.sizeId, setIsOpenCollapse])

    const [tabId = DEFAULT_TAB_ID, systemId = DEFAULT_SIZE_SYSTEM, sizeId] = useMemo(() => {
        return SizeUtils.getSize(values.sizeId)
    }, [values.sizeId])

    const sizeValue = useMemo(() => {
        const result = sizeSets[tabId]?.size_systems[systemId]?.sizes.find((size) => size.id === sizeId)

        const inventoryData = {
            "size_quantities": [
              {
                "quantity_available": 1,
                "quantity_sold": 0,
                "seller_inventory_private_info": {},
                "size_id": result?.id || sizeId,
                "size_obj": result || {
                    "display": sizeId,
                    "display_with_size_set": sizeId,
                    "id": sizeId,
                    "size_system": systemId
                },
                "size_set_tags": sizeSets[tabId]?.tags || [],
                "size_system": result?.size_system || systemId,
              }
            ],
            size_quantity_revision: 0,
            status: "available"
        }

        setFieldValue("inventory", inventoryData)

        return result?.display_with_size_system || sizeId
    }, [tabId, systemId, sizeId, sizeSets])

    return (
        <>
            <div className={`size-container ${isDisabled ? "size-disabled" : ""}`} ref={outsideRef} >
                <div className="size-control" onClick={handleToggleCollapse} >
                    <div className="size-ValueContainer">
                        {sizeValue
                            ? <div className="size-singleValue">{sizeValue}</div>
                            : <div className="size-placeholder">Select size</div>
                        }

                    </div>
                    <div className="size-IndicatorsContainer">
                        <DropdownIcon />
                    </div>
                </div>
                <Collapse isOpen={isOpenCollapse}>
                    <div className="size-menu">
                        <SizeDropdownContent
                            tabs={sizeTabs}
                            sizeSystems={sizeSystems}
                            sizeSets={sizeSets}
                            values={values}
                            setFieldValue={setFieldValue}
                            tabId={tabId}
                            systemId={systemId}
                        />
                    </div>
                </Collapse>
            </div>
        </>
    )
}

export default SizesSelect;