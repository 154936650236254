import React from "react";
import { Form, Formik } from "formik";

import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../base/components/CustomModal";
import CustomButton from "../../../../base/components/CustomButton";
import TaskForm from "../TaskForm";
import { taskSchema } from "../../forms/task";

const EditTaskModal = ({
  isOpen,
  onClose,
  onClick,
  initialState,
  initialStateSelect,
}) => {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <ModalHeader onClose={onClose}>Edit task</ModalHeader>
      <Formik
        initialValues={initialState}
        onSubmit={onClick}
        validationSchema={taskSchema}
      >
        {({ isValid, dirty }) => (
          <Form>
            <ModalBody>
              <TaskForm defaultValueSelect={initialStateSelect} />
            </ModalBody>
            <ModalFooter>
              <CustomButton onClick={onClose} className="outline-primary">
                Cancel
              </CustomButton>
              <CustomButton
                type="submit"
                onClick={onClick}
                className="filled-primary"
                disabled={!isValid || !dirty}
              >
                Save
              </CustomButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default EditTaskModal;
