export const listToOptions = ({ data }) => {
  return data.map((item) => {
    return {
      label: item.name,
      ...item,
    };
  });
};

export const basicListToOptions = (data) => {
  return data.map((item) => {
    const parent = item.parent;

    if (parent && parent.parent) {
      parent.parentId = parent.parent.id;
    }

    return {
      parentId: item.parent?.id,
      parent,
      id: item.id,
      hasChildren: !!item.children?.length,
      label: item.name,
      children: item.children,
      prevPath: item?.prevPath || "",
      prevArr: item?.prevArr || [],
    };
  });
};

export const listCategoriesToOptions = (results) => {
  return results.map((item) => {
    return {
      parentId: item.parent_id,
      hasChildren: !!item.children?.length,
      label: item.name,
      ...item,
    };
  });
};

export const ebayListCategoriesToOptions = (list) => {
  return list.map((item) => {
    const parent = item.parent;

    if (parent && parent.parent) {
      parent.parentId = parent.parent.id;
    }

    return {
      parentId: item.parent?.id,
      parent,
      id: item.category.categoryId,
      hasChildren: !!item.childCategoryTreeNodes?.length,
      label: item.category.categoryName,
      children: item.childCategoryTreeNodes,
      prevPath: item?.prevPath || "",
      prevArr: item?.prevArr || [],
    };
  });
};

export const poshmarkBrandlistToOptions = (response) => {
  const { data = [] } = response;

  return data.map((item) => {
    return {
      label: item.brand_name,
      id: item.brand_id,
      name: item.brand_name,
      ...item,
    };
  });
};

export const poshmarkTagsListToOptions = (response) => {
  const { data = [] } = response;

  return data.map((item) => {
    return {
      label: item,
      id: item,
      name: item,
    };
  });
};
