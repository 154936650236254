export const generateFormModelWithBool = (values) => {
  if (!values) return null;

  const model = {};
  Object.entries(values).forEach(([key]) => {
    model[key] = false;
  });

  return model;
};
