import { parseQuery, stringifyParams } from "./useQueryString";
import { useLocation, useHistory } from "react-router-dom";
import { useCallback } from "react";

export const useMarkToReload = (namespace) => {
  const {search} = useLocation();
  const history = useHistory();
  const marker = parseQuery(search)[namespace];

  const markUpdates = useCallback(() => {
    const params = {
      ...parseQuery(search),
      [namespace]: Date.now()
    };

    const query = stringifyParams(params);

    history.replace({
      search: query
    })
  }, [namespace, search, history]);

  return {
    marker,
    markUpdates
  }
};
