import { EBAY_FIELDS_TYPES } from "../const/ebay";

export const getEbayFieldsByTypes = (fields) => {
  return fields.reduce(
    (result, field) => {
      result[EBAY_FIELDS_TYPES[field.type]].push(field);
      return result;
    },
    {
      [EBAY_FIELDS_TYPES.required]: [],
      [EBAY_FIELDS_TYPES.recommended]: [],
      [EBAY_FIELDS_TYPES.additional]: [],
    }
  );
};
