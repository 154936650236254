import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const FieldDescription = ({
  isWithError,
  description,
  maxLength,
  valueLength,
}) => (
  <p
    className={classnames("font-size-10 text-gray-gomi mb-1", {
      "text-danger": isWithError,
    })}
  >
    {description
      ? description
      : maxLength
      ? `${valueLength} / ${maxLength}  characters`
      : valueLength === 1
      ? `${valueLength} character`
      : `${valueLength} characters`}
  </p>
);

FieldDescription.propTypes = {
  maxLength: PropTypes.number,
  valueLength: PropTypes.number,
  description: PropTypes.string,
  isWithError: PropTypes.bool,
};

export default FieldDescription;
