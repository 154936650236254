import React, { useMemo } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

import FormikCheckbox from "../../../../../../../../base/components/FormikCheckbox";
import FormikSingleSelect from "../../../../../../../../base/components/FormikSingleSelect";

import { EBAY_RETURNS_STRINGS } from "../../constants/strings";
import {
  DAY_DROPDOWN_OPTIONS,
  FORMIK_NAMES,
  PAID_BY_DROPDOWN_OPTIONS,
} from "../../constants";

import styles from "./index.module.scss";

const EbayReturns = ({
  isAccepted,
  isReplacement,
  onToggleAccept,
  onToggleReplacement,
  type,
}) => {
  const string = useMemo(() => EBAY_RETURNS_STRINGS[type], [type]);
  const formikNames = useMemo(() => FORMIK_NAMES[type], [type]);

  return (
    <Row>
      <Col md="6">
        <FormikCheckbox
          name={formikNames.mainCheckboxName}
          id={formikNames.mainCheckboxName}
          className={styles.checkbox}
          checked={isAccepted}
          onChange={onToggleAccept}
          text={string.mainCheckboxLabel}
        />
        {isAccepted && (
          <div className={styles.contentWrapper}>
            <FormikSingleSelect
              name={formikNames.daysDropdownName}
              containerClassName={classnames(
                styles.dropdown,
                styles.daysDropdown
              )}
              label={string.daysDropdownLabel}
              options={DAY_DROPDOWN_OPTIONS}
            />
            <FormikSingleSelect
              name={formikNames.paidByDropdownName}
              containerClassName={classnames(
                styles.dropdown,
                styles.returnsShippingDropdown
              )}
              label={string.paidByDropdown}
              options={PAID_BY_DROPDOWN_OPTIONS}
            />
            <FormikCheckbox
              name={formikNames.replacementCheckboxName}
              id={formikNames.replacementCheckboxName}
              className={styles.checkbox}
              checked={isReplacement}
              onChange={onToggleReplacement}
              text={string.replacementCheckboxLabel}
            />
          </div>
        )}
      </Col>
    </Row>
  );
};

EbayReturns.propTypes = {
  isAccepted: PropTypes.bool,
  isReplacement: PropTypes.bool,
  onToggleAccept: PropTypes.func,
  onToggleReplacement: PropTypes.func,
  type: PropTypes.string,
};

export default EbayReturns;
