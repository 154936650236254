import * as yup from "yup";

import {
  MESSAGE_MAX,
  MESSAGE_MIN,
  NAME_MAX,
  NAME_MIN,
  SUBJECT_MAX,
  SUBJECT_MIN,
} from "./constants";

export const formFields = {
  name: "name",
  email: "email",
  subject: "subject",
  message: "message",
};

export const formMinMaxValues = {
  [formFields.name]: {
    min: NAME_MIN,
    max: NAME_MAX,
  },
  [formFields.subject]: {
    min: SUBJECT_MIN,
    max: SUBJECT_MAX,
  },
  [formFields.message]: {
    min: MESSAGE_MIN,
    max: MESSAGE_MAX,
  },
};

export const validationSchema = yup.object().shape({
  [formFields.name]: yup
    .string()
    .min(formMinMaxValues[formFields.name].min)
    .max(formMinMaxValues[formFields.name].max)
    .trim()
    .required(),
  [formFields.email]: yup.string().email().trim().required(),
  [formFields.subject]: yup
    .string()
    .min(formMinMaxValues[formFields.subject].min)
    .max(formMinMaxValues[formFields.subject].max)
    .trim()
    .required(),
  [formFields.message]: yup
    .string()
    .min(formMinMaxValues[formFields.message].min)
    .max(formMinMaxValues[formFields.message].max)
    .trim()
    .required(),
});

export const initialValues = {
  [formFields.name]: "",
  [formFields.email]: "",
  [formFields.subject]: "",
  [formFields.message]: "",
};
