import React, { useRef } from "react";
import PropTypes from "prop-types";

import FormikDropdownSelect from "../../../../../base/components/FormikDropdownSelect";

const filterOption = () => true;

export const useActionsRef = () => {
  return useRef({
    open: () => {},
    setQuery: () => {},
    scroll: () => {},
  });
};

export const applySuggested = (actions, suggested) => {
  actions?.scroll();

  setTimeout(() => {
    actions?.setQuery(suggested);
    actions?.open();
  }, 1000);
};

const FormCategory = ({
  initialValue,
  getCategoryOptions,
  onInitBreadcrumbs,
  label = "Category*",
  name = "categoryId",
  placeholder = "Click to select a category",
  isHideLabel = false,
  innerRef,
  loading,
  setFieldValue,
}) => {
  return (
    <FormikDropdownSelect
      initialValue={initialValue}
      name={name}
      label={label}
      onInitBreadcrumbs={onInitBreadcrumbs}
      placeholder={placeholder}
      onGetAsyncOptions={getCategoryOptions}
      onChange={(value) => value.id}
      isHideLabel={isHideLabel}
      filterOption={filterOption}
      loading={loading}
      setFieldValue={setFieldValue}
      innerRef={innerRef}
    />
  );
};

FormCategory.propTypes = {
  initialValue: PropTypes.any,
  getCategoryOptions: PropTypes.func,
  onInitBreadcrumbs: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string,
  isHideLabel: PropTypes.bool,
  loading: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default FormCategory;
