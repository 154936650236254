import React, { useMemo } from 'react';
import PropTypes from "prop-types";
import { getFormattedLink } from '../../utils/getFormatedLink';

const VideoContainer = ({ src }) => {
  const formattedLink = useMemo(() => getFormattedLink(src), [src]);

  if (!src) return null;

  return (
    <div className='video-container'>
      <iframe 
        width="100%" 
        height="315" 
        src={formattedLink}
        frameBorder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen
      ></iframe>
    </div>
  )
};

VideoContainer.propTypes = {
  src:  PropTypes.string,
};

export default VideoContainer;