/* eslint-disable react/prop-types */
import React from "react";

export const CostAlert = ({
  title,
  price,
  isChangingSubscription,
  pricesDifference,
}) => {
  return (
    <div className="cost-secondary-alert">
      <h2 className="bold-green mb-1">{title}</h2>
      {isChangingSubscription ? (
        <p className="md-green mb-0">${pricesDifference}</p>
      ) : (
        <p className="md-green mb-0">${price} / month</p>
      )}
    </div>
  );
};

export default CostAlert;
