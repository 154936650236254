import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../base/hooks/useService";
import ToasterService from "../../../services/ToastService";
import { DRAFTS_GROUP_LINKS } from "../config";
import { checkIsEmptyForm } from "../helpers/getIsEmptyForm";
import { useUploadFiles } from "./useDraftImages";
import {
  DRAFT_SAVE_SUCCESS,
  FORM_DRAFT_IS_EMPTY,
} from "../../../base/constants/messages";
import FormPartials from "../classes/FormPartials";
import { FORM_TYPES } from "../const/form";
import { getFilteredForm } from "../helpers/getFilteredForm";
import { BRAND_LABEL } from "../components/DraftEbayFormInner/partials/OptionalFields";
import { UNPROCESSABLE_ERROR } from "../../../base/constants/statusCodes";
import { FIXED_PRICE_FORMAT } from "../constants";
import EbayService from "../../../services/EbayService";
import { useFormatEbayDomesticShippingServices } from "./useFormatEbayDomesticShippingServices";

export const useHanleSaveDraftErrors = () => {
  /**
   * @type {ToasterService}
   */
  const toastr = useService(ToasterService);

  return useCallback(
    (error, onSubmitEmptyForm) => {
      if (error?.message === FORM_DRAFT_IS_EMPTY) {
        onSubmitEmptyForm();
      }

      toastr.error(error?.error?.message || error?.message);
    },
    [toastr]
  );
};

const ADDITIONAL_FIELD_TYPES = {
  OBJECT: 5,
  STRING: 1,
  NUMBER: 2,
  BOOLEAN: 3,
  DATE: 6,
  STRING_ARRAY: 4,
};

export const convertSpecFieldForView = (productAdditionalFields = []) => {
  let fields = {};

  productAdditionalFields.forEach(({ name, value }) => {
    const { scale_id, value_ids, values } = JSON.parse(value);

    fields["propertyId_".concat(name)] = {
      values,
      ...(scale_id ? { value_ids, scale_id } : null),
      ...(!scale_id && value_ids ? { scale_id: value_ids[0] } : null),
    };
  });

  return fields;
};

export const convertSpecFieldEbayForView = (productAdditionalFields = []) => {
  let fields = {};

  productAdditionalFields.forEach(({ name, value: scale_id, type }) => {
    if (type === ADDITIONAL_FIELD_TYPES.STRING_ARRAY) {
      scale_id = JSON.parse(scale_id);
    }

    fields["propertyId_".concat(name)] = {
      scale_id,
    };
  });

  return fields;
};

const convertSpecEtsyFieldsForSaving = (productAdditionalFields) => {
  return Object.entries(productAdditionalFields)
    .map(([field, { value_ids, scale_id, values }]) => {
      const [, propertyId] = field.split("_");

      let value = {};

      if (value_ids) {
        value = {
          scale_id,
          value_ids,
        };
      } else if (scale_id) {
        value = {
          value_ids: [scale_id].flat(),
        };
      }

      if (values) {
        value.values = values.map((val) => String(val));
      }

      if (Object.keys(value).length === 0) return null;

      return {
        name: propertyId,
        value: JSON.stringify(value),
        type: ADDITIONAL_FIELD_TYPES.OBJECT,
      };
    })
    .filter((field) => field !== null);
};

const convertSpecEbayFieldsForSaving = (productAdditionalFields) => {
  return Object.entries(productAdditionalFields).map(
    ([field, { scale_id }]) => {
      const [, name] = field.split("_");
      const value = Array.isArray(scale_id)
        ? JSON.stringify(scale_id)
        : String(scale_id);

      return {
        name,
        value,
        type: Array.isArray(scale_id)
          ? ADDITIONAL_FIELD_TYPES.STRING_ARRAY
          : ADDITIONAL_FIELD_TYPES.STRING,
      };
    }
  );
};

export const useSaveDraft = (
  saveService,
  redirect = DRAFTS_GROUP_LINKS.BASE,
  message = DRAFT_SAVE_SUCCESS
) => {
  const { push } = useHistory();
  const uploadImagesToS3 = useUploadFiles();
  const toastr = useService(ToasterService);
  const { filterDomesticServices } = useFormatEbayDomesticShippingServices();

  return useCallback(
    async (
      values,
      redirectAlternative = redirect,
      alternativeMessage = message
    ) => {
      const formBasic = new FormPartials(FORM_TYPES.BASE);
      const newValues = formBasic.reduceFormModel(getFilteredForm(values));

      if (!newValues?.categoryId) {
        newValues.categoryId = newValues?.category?.id;
      }
      delete newValues?.category;
      if (
        newValues?.productEbaySpecificFields?.pricingFormat ===
        FIXED_PRICE_FORMAT
      ) {
        delete newValues?.productEbaySpecificFields?.startingPrice;
      }

      if (newValues?.productPoshmarkSpecificFields?.category_features) {
        newValues.productPoshmarkSpecificFields.categoryId =
          newValues.productPoshmarkSpecificFields.category_features;
        delete newValues.productPoshmarkSpecificFields.category_features;
      }

      if (
        Array.isArray(newValues?.productPoshmarkSpecificFields?.tags) &&
        !newValues?.productPoshmarkSpecificFields?.tags.length
      ) {
        delete newValues?.productPoshmarkSpecificFields?.tags;
      }

      if (
        Array.isArray(newValues?.productMercariSpecificFields?.tags) &&
        !newValues?.productMercariSpecificFields?.tags.length
      ) {
        delete newValues?.productMercariSpecificFields?.tags;
      }

      if (newValues?.productEtsySpecificFields?.productAdditionalFields) {
        newValues.productEtsySpecificFields.productAdditionalFields =
          convertSpecEtsyFieldsForSaving(
            newValues.productEtsySpecificFields.productAdditionalFields
          );
      }

      if (newValues?.productEbaySpecificFields?.productAdditionalFields) {
        newValues.productEbaySpecificFields.productAdditionalFields =
          convertSpecEbayFieldsForSaving(
            newValues.productEbaySpecificFields.productAdditionalFields
          );
      }

      // TODO - refactoring
      if (newValues?.productEbaySpecificFields?.brand) {
        newValues.productEbaySpecificFields.productAdditionalFields =
          newValues.productEbaySpecificFields.productAdditionalFields?.filter(
            ({ name }) => {
              return name !== BRAND_LABEL;
            }
          ) || [];

        newValues.productEbaySpecificFields.productAdditionalFields.push({
          name: BRAND_LABEL,
          value: newValues?.productEbaySpecificFields?.brand,
          type: 1,
        });
      }

      if (
        newValues?.productEbaySpecificFields &&
        !newValues?.productEbaySpecificFields?.pricingFormat
      ) {
        delete newValues.productEbaySpecificFields.allowBestOffer;
      }

      if (
        newValues?.productEbaySpecificFields?.upc &&
        newValues.productEbaySpecificFields?.upc?.toString().trim() !== ""
      ) {
        const existingUpcValue = newValues.productEbaySpecificFields.upc;

        if (isNaN(existingUpcValue)) {
          newValues.productEbaySpecificFields.doesNotApplyUpc = true;
          delete newValues.productEbaySpecificFields.upc;
        } else {
          newValues.productEbaySpecificFields.upc += "";
        }
      }

      if (
        newValues?.productEbaySpecificFields?.shippingService &&
        newValues?.productEbaySpecificFields?.shippingType === 1
      ) {
        const services = await new EbayService().getShippingData();
        const domesticServices = filterDomesticServices(services);

        const service = domesticServices.find(
          (service) =>
            service.shippingServiceId ===
            newValues?.productEbaySpecificFields?.shippingService
        );

        if (service) {
          const { shippingService } = service;

          newValues.productEbaySpecificFields.shippingService = shippingService;
        }
      }

      const isEmpty = checkIsEmptyForm(newValues);

      if (isEmpty) {
        return Promise.reject(new Error(FORM_DRAFT_IS_EMPTY));
      }

      if (newValues.files) {
        newValues.files = await uploadImagesToS3(newValues.files);
      }

      return saveService(newValues)
        .then((data) => {
          if (alternativeMessage) toastr.success(alternativeMessage);
          if (redirectAlternative) push(redirectAlternative);

          return data;
        })
        .catch((baseError) => {
          const { error } = baseError;
          Bugsnag.notify(baseError);

          if (error.code === UNPROCESSABLE_ERROR) {
            toastr.error(error.message);
            return Promise.reject(error);
          }
        });
    },

    [redirect, message, saveService, uploadImagesToS3, toastr, push]
  );
};
