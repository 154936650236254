import * as yup from "yup";
import {
  DRAFT_DESCRIPTION,
  DRAFT_DESCRIPTION_ETSY,
  DRAFT_DIMENSION,
  DRAFT_LB,
  DRAFT_OZ,
  DRAFT_PRICE,
  DRAFT_SKU,
  DRAFT_TAGS,
  DRAFT_TAGS_ETSY,
  ETSY_DRAFT_DIMENSION,
  LISTING_URL,
} from "../../../../validation/draft";

import { MIN_TITLE_LENGTH } from "../../../../validation/lengthConstants";
import {
  DESCRIPTION_ERROR,
  PRISE_ERROR,
  TITLE_ERROR,
} from "../../../../base/constants/forms";
import {
  MAX_ETSY_MATERIALS_ELEMENTS,
  MAX_ETSY_TITLE_LENGTH,
} from "../../const/eatsy";
import { tagsValidation } from "../../helpers/tagsValidation";
import { FILES_VALIDATION } from "./base";

const maxCountTags = 13;

export const DRAFT_ETSY_TITLE = yup
  .string()
  .min(MIN_TITLE_LENGTH, TITLE_ERROR)
  .max(MAX_ETSY_TITLE_LENGTH)
  .trim();

export const nullableNumber = yup.number().nullable(true);

const DRAFT_ETSY_WHO_MADE_IT = nullableNumber;
const DRAFT_ETSY_WHAT_IS_IT = nullableNumber;
const DRAFT_ETSY_WAS_IT_MADE = nullableNumber;
const DRAFT_ETSY_CATEGORY = nullableNumber;
const DRAFT_ETSY_TEMPLATE_ID = nullableNumber;
const DRAFT_ETSY_RENEWAL_OPTIONS = nullableNumber;
const DRAFT_ETSY_MATERIALS = yup.array().max(MAX_ETSY_MATERIALS_ELEMENTS);

export const validationSchema = yup.object().shape({
  files: FILES_VALIDATION,
  title: DRAFT_ETSY_TITLE.required(),
  description: DRAFT_DESCRIPTION_ETSY.required(),
  whoMadeIt: DRAFT_ETSY_WHO_MADE_IT.required(),
  whatIsIt: DRAFT_ETSY_WHAT_IS_IT.required(),
  whenWasItMade: DRAFT_ETSY_WAS_IT_MADE.required(),
  categoryId: DRAFT_ETSY_CATEGORY.required(),
  shippingTemplateId: DRAFT_ETSY_TEMPLATE_ID.required(),
  renewalOptions: DRAFT_ETSY_RENEWAL_OPTIONS.required(),
  price: DRAFT_PRICE.nullable().required(),
  materials: DRAFT_ETSY_MATERIALS,
  //costOfItem: DRAFT_PRICE.nullable(),
  sku: DRAFT_SKU,
  tags: tagsValidation(DRAFT_TAGS_ETSY, maxCountTags),
  weightLb: DRAFT_LB,
  weightOz: DRAFT_OZ,
  height: ETSY_DRAFT_DIMENSION,
  width: ETSY_DRAFT_DIMENSION,
  depth: ETSY_DRAFT_DIMENSION,
  link: LISTING_URL,
  sizeName: yup.string(),
});

export const highlightDraftFields = {
  title: TITLE_ERROR,
  description: DESCRIPTION_ERROR,
  whoMadeIt: "Who Made It is required",
  whatIsIt: "What Is It is required",
  whenWasItMade: "When Was It Made is required",
  shippingTemplateId: "Shipping Template Id is required",
  renewalOptions: "Renewal Options is required",
  price: PRISE_ERROR,
};

export const initialValues = {
  files: [],
  title: "",
  description: "",
  primaryColor: null,
  whoMadeIt: null,
  whatIsIt: null,
  whenWasItMade: null,
  tags: "",
  categoryName: "",
  categoryShortName: "",
  categoryId: null,
  materials: [],
  shippingTemplateId: null,
  renewalOptions: 1,
  price: "",
  costOfItem: null,
  sku: "",
  weightLb: "",
  weightOz: "",
  height: "",
  width: "",
  depth: "",
  link: "",
};

export const fieldsToMerge = [
  // base, nested custom form key
  { base: "files", custom: null }, // spec. field only for base model
  { base: null, custom: "title" },
  { base: null, custom: "description" },
  { base: null, custom: "primaryColor" },
  { base: null, custom: "whoMadeIt" }, // spec. field only for nested model
  { base: null, custom: "whatIsIt" }, // spec. field only for nested model
  { base: null, custom: "whenWasItMade" }, // spec. field only for nested model
  { base: null, custom: "tags" },
  { base: null, custom: "categoryId" },
  { base: null, custom: "categoryName" },
  { base: null, custom: "categoryShortName" },
  { base: null, custom: "materials" },
  { base: null, custom: "productAdditionalFields" },
  { base: null, custom: "shippingTemplateId" },
  { base: null, custom: "renewalOptions" },
  { base: null, custom: "price" },
  { base: null, custom: "costOfItem", toForm: (val) => val },
  { base: null, custom: "sku" },
  { base: null, custom: "weightLb" },
  { base: null, custom: "weightOz" },
  { base: null, custom: "height" },
  { base: null, custom: "width" },
  { base: null, custom: "depth" },
  { base: null, custom: "sizeName" },
  { base: null, custom: "brandName" },
  { base: null, custom: "isUpdated" },
  { base: null, custom: "link", excludeSaving: true },
  { base: null, custom: "status", excludeSaving: true },
  { base: "createdAt", custom: "createdAt", excludeSaving: true },
  { base: "updatedAt", custom: "updatedAt", excludeSaving: true },
];
