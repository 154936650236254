import React, { useCallback } from "react";
import PropTypes from "prop-types";
import Bugsnag from "@bugsnag/js";

import CustomButton from "../../../CustomButton";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../CustomModal";

import SubscriptionService from "../../../../../services/SubscriptionService";
import { useService } from "../../../../hooks/useService";

const ChangeSubscriptionModal = ({
  isOpen,
  onBackStep,
  chosenSubscription,
  onClose,
}) => {
  const subscriptionService = useService(SubscriptionService);

  const handleChangeSubscription = useCallback(() => {
    subscriptionService
      .changeSubscription({
        subscriptionType: chosenSubscription.type,
      })
      .then(() => onClose())
      .catch((e) => Bugsnag.notify(e));
  }, [subscriptionService, chosenSubscription?.type, onClose]);

  return (
    <CustomModal isOpen={isOpen} unmountOnClose={false}>
      <ModalHeader onClose={onClose}>
        <h3 className="modal-title mt-0">Change subscription</h3>
      </ModalHeader>
      <ModalBody>
        <p className="mb-0">
          Do you really want to change the subscription plan? You will be
          transferred to the chosen one. Money will be refunded. It can take
          some time.
        </p>
      </ModalBody>
      <ModalFooter>
        <CustomButton className="outline-primary" onClick={onBackStep}>
          Cancel
        </CustomButton>
        <CustomButton
          className="filled-primary"
          onClick={handleChangeSubscription}
        >
          Confirm
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );
};

ChangeSubscriptionModal.propTypes = {
  isOpen: PropTypes.bool,
  onBackStep: PropTypes.func,
  chosenSubscription: PropTypes.any,
  onClose: PropTypes.func,
};

export default ChangeSubscriptionModal;
