export const MAX_IMAGE_WIDTH = 1080;
export const MAX_IMAGE_HEIGHT = 1080;

export const MIN_CROP_BOX_WIDTH = 40;
export const MIN_CROP_BOX_HEIGHT = 40;

export const INITIAL_CROPPER_STATE = {
  ratio1: 1,
  ratio2: 1,
  zoom: 0.2,
  dragMode: "crop",
  rotate: 0,
  scaleX: 1,
  scaleY: 1,
  enable: true,
  disable: false,
  viewMode: 2,
};
