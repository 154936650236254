export const EXTENSION_EVENTS = {
  PING: "PING",
  CREATE_DRAFT: "CREATE_DRAFT",
  POST_DRAFT: "POST_DRAFT",
  EDIT_INVENTORY: "EDIT_INVENTORY",
  DELETE_DRAFT: "DELETE_DRAFT",
  DELETE_INVENTORY: "DELETE_INVENTORY",
  ADD_MEDIA: "ADD_MEDIA",
  LOG_OUT: "LOG_OUT",
  SHIPPING_PARAMS: "SHIPPING_PARAMS",
  COMPLETED_LOG_OUT: "COMPLETED_LOG_OUT",
  GET_CATEGORIES: "GET_CATEGORIES",
  IS_CONNECTED: "IS_CONNECTED",
  GET_DRESS_OCCASIONS: "GET_DRESS_OCCASIONS",
  GET_DRESS_STYLES: "GET_DRESS_STYLES",
  ON_INSTALL_EXTENSION: "ON_INSTALL_EXTENSION",
  ON_UNINSTALL_EXTENSION: "ON_UNINSTALL_EXTENSION",
  INVALIDATED: "INVALIDATED",
  SEARCH_BRANDS: "SEARCH_BRANDS",
  GET_ALL_BRANDS: "GET_ALL_BRANDS",
  CALC_FEES: "CALC_FEES",
  GET_TAGS: "GET_TAGS",
  IMPORT_PRODUCTS: "IMPORT_PRODUCTS",
  GET_SOLD_ITEMS: "GET_SOLD_ITEMS",
  IMPORT_DETAIL_PRODUCTS: "IMPORT_DETAIL_PRODUCTS",
  SALE_DETAILS: "SALE_DETAILS",
  COMPLETED_AUTH: "COMPLETED_AUTH",
  GET_DRAFT: "GET_DRAFT",
  GET_PROFILE: "GET_PROFILE",
  GET_SELLER_EARNINGS: "GET_SELLER_EARNINGS",
  GET_SELLER_SHIPPING_DISCOUNTS: "GET_SELLER_SHIPPING_DISCOUNTS",
  SOLD_DETAILS: "SOLD_DETAILS",
  INTERNATIONAL_SHIPPING_OPTIONS: "INTERNATIONAL_SHIPPING_OPTIONS",
  FLAT_DOMESTIC_SHIPPING_OPTIONS: "FLAT_DOMESTIC_SHIPPING_OPTIONS",
  CALCULATED_DOMESTIC_SHIPPING_OPTIONS: "CALCULATED_DOMESTIC_SHIPPING_OPTIONS",
  OPEN_IMPORT_MODAL_CONNECTION: "OPEN_IMPORT_MODAL_CONNECTION",
  CLOSE_IMPORT_MODAL_CONNECTION: "CLOSE_IMPORT_MODAL_CONNECTION",
  IMPORT_MODAL_GET_ACTIVE_PRODUCTS: "IMPORT_MODAL_GET_ACTIVE_PRODUCTS",
  IMPORT_MODAL_GET_SOLD_PRODUCTS: "IMPORT_MODAL_GET_SOLD_PRODUCTS",
  SHIPPING_CLASSES: "SHIPPING_CLASSES",
};

export const MARKETPLACES = {
  POSHMARK: "POSHMARK",
  EBAY: "EBAY",
  ETSY: "ETSY",
  EXTENSION: "EXTENSION",
  MERKARI: "MERKARI",
};
