import {
  MARKETPLACE_MERCARI_TYPE,
  MARKETPLACE_POSHMARK_TYPE,
} from "../constants/importInventory";
import { toNumber } from "../../sold/helpers";
import filterObjectFalsy from "../../../base/helpers/filterObjectFalsy";
import { MARKETPLACES } from "../../drafts/constants";

const FIELDS_TO_STRING = [
  { field: "categoryId" },
  { field: "brandId" },
  { field: "conditionId" },
  { field: "colorId" },
  { field: "sizeId" },
  { field: "shippingId" },
];

export const transformProducts = (formattedProducts, marketplace) => {
  const marketplaceSpecFields = {
    [MARKETPLACE_POSHMARK_TYPE]: "productPoshmarkSpecificFields",
    [MARKETPLACE_MERCARI_TYPE]: "productMercariSpecificFields",
  };

  const transformedProducts = formattedProducts.map((product) => {
    if (marketplace === MARKETPLACES.mercari) {
      FIELDS_TO_STRING.forEach((element) => {
        product[element.field] = product[element.field]?.toString();
      });
    }

    const soldDetails = {
      productOrder: {
        priceSold: toNumber(product?.priceSold),
        itemCost: toNumber(product?.costPrice),
        youMade: product?.youMade ? toNumber(product.youMade) : null,
        marketplaceFees: toNumber(product?.marketplaceFees),
        shippingExpenses:
          product?.shippingExpenses > 0
            ? toNumber(product?.shippingExpenses)
            : undefined,
        transactionFees: toNumber(product?.transactionFees),
        buyer: product?.buyer,
        dateSold: product?.dateSold,
      },
    };

    const updatedProduct = {
      firstListedAt: product.firstListedAt,
      [marketplaceSpecFields[marketplace]]: {
        ...product,
      },
      ...(product.isSold && {
        productOrder: filterObjectFalsy(soldDetails.productOrder),
      }),
    };

    // Clean order fields from productPoshmarkSpecificFields object
    Object.entries(soldDetails.productOrder).forEach((key) => {
      delete updatedProduct[marketplaceSpecFields[marketplace]][key[0]];
    });

    delete updatedProduct[marketplaceSpecFields[marketplace]].isSold;
    delete updatedProduct[marketplaceSpecFields[marketplace]].firstListedAt;

    if (
      updatedProduct[marketplaceSpecFields[marketplace]]?.shippingExpenses <= 0
    ) {
      delete updatedProduct[marketplaceSpecFields[marketplace]]
        ?.shippingExpenses;
    }

    if (
      Array.isArray(updatedProduct[marketplaceSpecFields[marketplace]]?.tags) &&
      !updatedProduct[marketplaceSpecFields[marketplace]]?.tags.length
    ) {
      delete updatedProduct[marketplaceSpecFields[marketplace]]?.tags;
    }

    return updatedProduct;
  });

  return transformedProducts;
};
