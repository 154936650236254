import React, { useCallback, useMemo, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import SubscriptionOption from "../../../../../../base/components/Subscription/components/SubscriptionOption";
import SubscriptionFooter from "../../components/SubscriptionFooter";
import SubscriptionChangeModal from "../../components/SubscriptionChangeModal";
import { subscriptionList } from "../../../../../../base/components/Subscription/subscriptionList";

import { useGetAvailableSubscription } from "../../hooks/useGetAvailableSubscription";
import { useGetActiveSubscription } from "../../hooks/useGetActiveSubscription";
import {
  getPurchasedDates,
  getRenewalDate,
} from "../../../../../../base/components/Subscription/utils/getPurchasedDates";
import { getIsDisabledChangeButton } from "../../utils/getIsDisabledChangeButton";
import { useCancelSubscription } from "../../../../../../base/components/Subscription/hooks/useCancelSubscription";

const SubscriptionSetting = () => {
  const availableSubscriptions = useGetAvailableSubscription();
  const activeSubscription = useGetActiveSubscription();

  const [chosenSubscription, updateChosenSubscription] = useState(null);

  const handleChoseSubscribe = useCallback((subscription) => {
    updateChosenSubscription(subscription);
  }, []);

  const purchasedDates = useMemo(() => {
    return getPurchasedDates(activeSubscription);
  }, [activeSubscription]);

  const isDisabledChangeButton = useMemo(() => {
    return getIsDisabledChangeButton(
      chosenSubscription?.type,
      activeSubscription?.type
    );
  }, [chosenSubscription, activeSubscription?.type]);

  const { open, close, isOpen, loading, handleCancelSubscription } =
    useCancelSubscription();

  return (
    <div className="mt-3">
      <Card>
        <CardBody>
          <div className="subscription-setting-card-outer">
            <Row className="justify-content-around">
              {subscriptionList.map((item, index) => (
                <Col lg="6" xl="4" key={index}>
                  <SubscriptionOption
                    statusSubscription={activeSubscription?.status}
                    isAvailable={availableSubscriptions[index]?.isAvailable}
                    isActivatedType={item.type === activeSubscription?.type}
                    isChosen={item.type === chosenSubscription?.type}
                    onChooseType={() => handleChoseSubscribe(item)}
                    onOpenModal={open}
                    {...item}
                  />
                </Col>
              ))}
            </Row>
          </div>
          <SubscriptionFooter
            purchasedDates={purchasedDates}
            isDisabledChangeButton={isDisabledChangeButton}
            subscriptionType={chosenSubscription?.type}
            chosenSubscription={chosenSubscription}
            activeSubscription={activeSubscription}
          />
        </CardBody>
      </Card>
      <SubscriptionChangeModal
        isOpen={isOpen}
        onClose={close}
        onClick={handleCancelSubscription}
        isLoading={loading}
        title="Cancel subscription"
        text={`Are you sure you want to cancel? Your current subscription will not renew and will expire on ${getRenewalDate(
          activeSubscription
        )}.`}
      />
    </div>
  );
};

export default SubscriptionSetting;
