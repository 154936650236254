import {
  fieldsToMergeServer,
  transformTags,
} from "../../classes/forms/poshmark";
import { transformSellerPrivateInfo } from "./transformSellerPrivateInfo";
import { PoshmarkSoldProduct } from "../../../inventory/classes/PoshmarkProduct";

export const convertSpecPoshmarkFieldsForSaving = (
  productAdditionalFields,
  val = null
) => {
  const data = {};
  fieldsToMergeServer.forEach(({ from, to, transform }) => {
    data[from] = transform(val?.[to]) || null;
  });

  if (val?.isAddToBoutique && val?.isTagsAttached) {
    data.condition = PoshmarkSoldProduct.CONDITION.WITH_TAGS_AND_BOUTIQUE;
  }

  data.inventory = val?.inventory ?? {};
  data.catalog = val?.catalog ?? {};
  data.colors = val?.colors ?? (val?.colorName ? [val?.colorName] : []);
  data.pictures = val?.files ?? [];

  delete data.size;

  data.style_tags = transformTags(data?.style_tags) || [];

  data.videos = [];

  return transformSellerPrivateInfo(data);
};
