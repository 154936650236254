import React from 'react'
import { Link } from 'react-router-dom';

import { AUTH_GROUP_LINKS } from '../config';

const ForgotPasswordFooter = () => {
  return (
    <div className='sign-up-footer text-center'>
      <p>
        Remember It?{" "}&nbsp;
        <Link
          to={AUTH_GROUP_LINKS.LINK_LOGIN}
          className="font-weight-medium"
        >
          Sign In here
        </Link>
      </p>
    </div>
  )
};

export default ForgotPasswordFooter;