import Bugsnag from "@bugsnag/js";

import ExtensionService from "../Extension";
import { EXTENSION_EVENTS } from "../../base/constants/extension";

class TabConnection extends ExtensionService {
  constructor(marketplaceName) {
    super(marketplaceName);
    this.connection = Promise.resolve();
    this.tabId = null;

    /// handle manually closed tab
  }

  static $displayName = "TabConnection";

  connect() {
    return (this.connection = this.connection
      .then(() =>
        this.request(EXTENSION_EVENTS.OPEN_IMPORT_MODAL_CONNECTION).then(
          (resp) => {
            const {
              result: { id },
            } = resp;
            this.tabId = id;

            return id;
          }
        )
      )
      .catch((e) => Bugsnag.notify(e)));
  }

  disconnect() {
    const tabId = this.tabId;

    this.tabId = null;

    return this.disconnectById(tabId);
  }

  disconnectById(tabId) {
    return this.request(EXTENSION_EVENTS.CLOSE_IMPORT_MODAL_CONNECTION, {
      params: { tabId },
    });
  }

  getLoadedTab() {
    if (!this.tabId) this.connect();

    return this.connection
      .then(() => {
        return this.tabId;
      })
      .catch((e) => Bugsnag.notify(e));
  }
}

export default TabConnection;
