import * as yup from "yup";

import {
  MAX_DESCRIPTION,
  MAX_TITLE,
  MIN_DESCRIPTION,
  MIN_TITLE,
} from "./constants";

export const formFields = {
  title: "title",
  description: "description",
  category: "category",
  price: "price",
  imageId: "imageId",
  expenseMadeAt: "expenseMadeAt",
  memo: "memo"
};

export const validationSchema = yup.object().shape({
  [formFields.title]: yup
    .string()
    .min(MIN_TITLE)
    .max(MAX_TITLE)
    .required("Title is required"),
  [formFields.memo]: yup
    .string(),
  [formFields.description]: yup
    .string()
    .min(MIN_DESCRIPTION)
    .max(MAX_DESCRIPTION),
  [formFields.category]: yup.string().required("Category is required"),
  [formFields.price]: yup.number().required("Price is required"),
  [formFields.expenseMadeAt]: yup.string().nullable().required("Expense date is required"),
  [formFields.image]: yup.number(),
});

export const initialValues = {
  [formFields.title]: "",
  [formFields.description]: "",
  [formFields.category]: "",
  [formFields.price]: "",
  [formFields.expenseMadeAt]: new Date(),
  [formFields.memo]: "",
};
