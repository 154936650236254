import { useCallback, useState, useMemo, useRef } from "react";
import Bugsnag from "@bugsnag/js";

import {
  DEFAULT_LIMIT,
  INITIAL_NEXT_OFFSET,
  INITIAL_TOTAL_COUNT,
} from "../constants/infiniteScroll";
import { useLoading } from "../../../hooks/useLoading";

export const useInfiniteScroll = ({
  limit = DEFAULT_LIMIT,
  fetchData = () => {},
  addedParams = {},
}) => {
  const [isLoading, { registerPromise }] = useLoading();
  const [data, updateData] = useState({
    data: [],
    pagination: {
      nextOffset: INITIAL_NEXT_OFFSET,
      totalCount: INITIAL_TOTAL_COUNT,
    },
  });

  const isBlocked = useRef(false);

  const handleFetch = useCallback(async () => {
    const params = {
      limit,
      offset: data.pagination.nextOffset,
      ...addedParams,
    };
    if (isBlocked.current) return;

    isBlocked.current = true;

    registerPromise(fetchData(params))
      .then(({ data, pagination }) => {
        updateData((previous) => ({
          data: [...previous.data, ...data],
          pagination,
        }));
      })
      .catch((e) => Bugsnag.notify(e))
      .finally(() => (isBlocked.current = false));
  }, [
    data.pagination.nextOffset,
    fetchData,
    limit,
    addedParams,
    registerPromise,
  ]);

  const handleRefresh = useCallback(async () => {
    const params = { limit, offset: INITIAL_NEXT_OFFSET, ...addedParams };

    if (isBlocked.current) return;
    isBlocked.current = true;

    registerPromise(fetchData(params))
      .then(({ data, pagination }) => {
        updateData(() => ({
          data: [...data],
          pagination,
        }));
      })
      .catch((e) => Bugsnag.notify(e))
      .finally(() => {
        isBlocked.current = false;
      });
  }, [fetchData, limit, addedParams, registerPromise]);

  const hasMore = useMemo(() => {
    const {
      pagination: { totalCount },
      data: items,
    } = data;

    return !(items.length >= totalCount && totalCount !== null);
  }, [data]);

  return {
    data: data.data,
    handleFetch,
    hasMore,
    handleRefresh,
    isLoading,
    totalCount: data.pagination.totalCount,
  };
};
