import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import StripeFormInner from '../StripeFormInner';

import { ENV } from '../../../../../../../../base/constants/env';
import { CREDIT_STYLES } from '../../../../../../../../base/components/Subscription/components/CreditCard/CreditCard/style';

const stripePromise = loadStripe(ENV.STRIPE_APP);

const StripeFormWrapper = ({ onUpdateCreditCard }) => {
  return (
    <Elements stripe={stripePromise} options={CREDIT_STYLES.STRIPE_OPTIONS}>
      <StripeFormInner onUpdateCreditCard={onUpdateCreditCard} />
    </Elements>
  )
};

export default StripeFormWrapper