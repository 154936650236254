export const MAX_TITLE_POSHMARK_LENGTH = 80;
export const MAX_OTHER_INFO_LENGTH = 500;
export const MAX_DESCRIPTION_POSHMARK_LENGTH = 1500;
export const MAX_LISTING_PRICE_FOR_DISCOUNT = 500;
export const MAX_TAGS_POSHMARK_LENGTH = 100;
export const INITIAL_DISCOUNT_OBJECT = { label: "FREE", id: undefined };

export const EXTENSION_NOT_INSTALLED = "Extension is not installed";
export const MARKETPLACE_NOT_CONNECTED = "Not connected marketplace";

export const STATUS_POSHMARK_NOT_INSTALLED = "NOT_INSTALLED";
export const STATUS_POSHMARK_NOT_CONNECTED = "NOT_CONNECTED";
export const STATUS_POSHMARK_ACTIVE = "ACTIVE";
export const TAGS_ATTACHED_LABEL = "isTagsAttached";

export const LINK_TO_CHROME_EXTENSION = "chrome://extensions/";

export const CATEGORY_ERROR = "Missing category";
export const SIZE_ERROR = "Missing size";
export const NEW_WITH_TAGS_ERROR = "Missing new with tags";
export const BRAND_ERROR = "Missing brand";
export const OTHER_ERROR = "Missing other info";

export const COUNT_OF_ROWS_STR = "5";

export const NOT_FOR_SALE_STATUS = "not_for_sale";

export const POSHMARK_CONDITIONS = {
  newWithTagsBox: true,
  newWithoutTagsBox: false,
  newWithDefects: false,
  preOwned: false,
  poor: false,
};
