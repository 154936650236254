import React, { useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import ProfileImage from "./shared/ProfileImage";
import CustomButton from "../CustomButton";
import DropdownMenuItem from "./shared/DropdownLogoutItem";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../CustomModal";
import { ReactComponent as IconProfile } from "../../../assets/images/navigation/icon-16-delete-grey.svg";
import { ReactComponent as IconLogOut } from "../../../assets/images/navigation/icon-16-log-out-grey.svg";
import { AUTH_GROUP_LINKS } from "../../../pages/auth/config";
import { useProfile } from "../../../pages/profile/hooks/useProfile";
import { PROFILE_GROUP_LINKS } from "../../../pages/profile/config";
import { DEFAULT_AVATAR_IMAGE } from "../../../pages/profile/constants";

const ProfileMenu = () => {
  const { push } = useHistory();
  const [{ image, firstName }] = useProfile();
  const [isOpen, updateIsOpen] = useState(false);
  const handleOpenMenu = () => updateIsOpen((isOpen) => !isOpen);

  const [isOpenModal, updateIsOpenModal] = useState(false);
  const handleToggleModal = useCallback((_, isOpen = false) => {
    updateIsOpenModal(isOpen);
  }, []);

  return (
    <>
      <Dropdown
        isOpen={isOpen}
        toggle={handleOpenMenu}
        className="d-inline-block"
      >
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          <ProfileImage
            name={firstName}
            src={image?.link || DEFAULT_AVATAR_IMAGE}
          />
        </DropdownToggle>
        <DropdownMenu>
          <DropdownMenuItem
            text="Profile"
            logo={IconProfile}
            onClick={() => {
              push(PROFILE_GROUP_LINKS.BASE);
            }}
          />
          <DropdownItem divider />
          <DropdownMenuItem
            text="Log out"
            logo={IconLogOut}
            onClick={(event) => handleToggleModal(event, true)}
          />
        </DropdownMenu>
      </Dropdown>

      <CustomModal isOpen={isOpenModal} onClose={handleToggleModal}>
        <ModalHeader onClose={handleToggleModal}>Log out</ModalHeader>
        <ModalBody>
          <p className="ui-regular">Do you want to log out?</p>
        </ModalBody>
        <ModalFooter>
          <CustomButton
            className="filled-primary"
            onClick={() => {
              window.Intercom("shutdown");
              window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: "upcp9err",
              });

              push(AUTH_GROUP_LINKS.LINK_LOG_OUT);
            }}
          >
            Ok
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

export default ProfileMenu;
