/* eslint-disable react/prop-types */
import React from "react";
import { Col, Row } from "reactstrap";
import CustomButton from "../../../../../../base/components/CustomButton";

import "./index.scss";
import { useTriggerFormChanges } from "../../../../hooks/useDetectDirtyForm";

const FormPoshmarkNewWithTags = ({ values, setFieldValue }) => {
  const { markChanges, containerRef } = useTriggerFormChanges();

  const setNewWithTags = (value) => {
    if (!value) {
      setFieldValue("isAddToBoutique", null);
    }
    setFieldValue("isTagsAttached", value);
    markChanges();
  };

  const setIsAddToBoutique = (value) => {
    setFieldValue("isAddToBoutique", value);
    markChanges();
  };

  return (
    <>
      <Row className="section-wrapper">
        <Col md={4}>
          <h3 className="bold-black mb-1">New with tags</h3>
        </Col>
        <Col md={8}>
          <Row>
            <p className="fw-medium">Does this item have tags attached?</p>
          </Row>
          <Row>
            <div className="d-flex w-100" ref={containerRef}>
              <CustomButton
                className={`${
                  values.isTagsAttached ? "filled-xsm" : "outline-primary-xsm"
                } tag-button mr-3`}
                onClick={() => setNewWithTags(true)}
              >
                Yes
              </CustomButton>

              <CustomButton
                className={`${
                  values?.isTagsAttached === false ||
                  values?.isTagsAttached === null
                    ? "filled-xsm"
                    : "outline-primary-xsm"
                } tag-button`}
                onClick={() => setNewWithTags(false)}
              >
                No
              </CustomButton>
              <p className="text-gray-gomi ms-5 mb-0 w-75">
                An item is New With Tags (NWT) if it&apos;s brand-new, unused,
                and has all of its original tags attached. For more information,
                please visit Poshmark&apos;s NWT Policy.
              </p>
            </div>
          </Row>
        </Col>
      </Row>

      {values.isTagsAttached && (
        <Row className="section-wrapper">
          <Col md={4}>
            <h3 className="bold-black mb-1"></h3>
          </Col>
          <Col md={8}>
            <Row>
              <p className="fw-medium">Add this item to your Boutique?</p>
            </Row>
            <Row>
              <div className="d-flex w-100">
                <CustomButton
                  className={`${
                    values.isAddToBoutique
                      ? "filled-xsm"
                      : "outline-primary-xsm"
                  } tag-button mr-3`}
                  onClick={() => setIsAddToBoutique(true)}
                >
                  Yes
                </CustomButton>

                <CustomButton
                  className={`${
                    !values.isAddToBoutique && values.isAddToBoutique !== null
                      ? "filled-xsm"
                      : "outline-primary-xsm"
                  } tag-button`}
                  onClick={() => setIsAddToBoutique(false)}
                >
                  No
                </CustomButton>
                <p className="text-gray-gomi ms-5 mb-0 w-75">
                  An item is Boutique if it has been purchased directly from a
                  brand or distributor and is now being sold for the first time.
                </p>
              </div>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default FormPoshmarkNewWithTags;
