import moment from "moment";

export const DATE_RENEWAL_FORMAT = "MMM D, YYYY";

export const getPurchasedDates = (activeSubscription) => {
  const createdAt = activeSubscription?.createdAt
    ? moment(activeSubscription.createdAt)
    : null;
  const expiresAt = activeSubscription?.expiresAt
    ? moment(activeSubscription?.expiresAt)
    : null;

  return {
    purchaseDate: createdAt,
    renewalDate: expiresAt,
  };
};

export const getRenewalDate = (activeSubscription) => {
  const expiresAt = activeSubscription?.expiresAt;

  return moment(expiresAt).format(DATE_RENEWAL_FORMAT);
};
