import React, { memo } from "react";
import { DragPreviewImage, useDrag } from "react-dnd";

const _style = {
  border: "1px dashed gray",
  padding: "0.5rem 1rem",
  cursor: "move",
};
const PREVIEW = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfmAx0NNSfm+UMjAAAEc0lEQVRIx6XWe4jUVRTA8c/Mrq81NbOHZKbhZplhafagEIvCHmZaalGhaJkUESW9A4seRBFFgWlqEiRFJVT0ILIUXxWZKdFr0zKM2tRc19xdZ2d2Z25/zG9mfzPj6lbn99e95/y+99xzzz3nVjm01LjFApMNkpSS8j9llNekBEGw1yZLzTHWURKd/9K5qq9Z5hkq7T27jTHcANBouy2+tMV2jV337XwfyAq+M1NP9HOGmRaqj/zNafCVJW42Rv/DwY4x305Bk4WGlWhm2RkBO769NlrsJqMdeTBY0qXWCoKNpqiOafp5UnMM9K5bLfaVvdG4wZcWuKAUN9izGgUNnjKoRDPMCrkiLO2FKKL9nWWOpTbaLwh+6filu+k2C4LVLpYswV1oU8km5+lRtrMzbYy0kZziZS2Ceg9Faxek2s3+iOF+MbUiUKOtibRZqDFHnaDde84rM+3vaS0x3BfOrcBdoU7QapOMLGO8KS34zW1qykyHezsWuZwVTiqzqDbXLsF+97tGq3Z+iMy3mm+8o2PGF0cxzX+tnq3IuD6e0CL43QxcKq2NrFC8Xs02W2CqIXqb688Ybo87dC/DDfKqrOAbF4HLpWVokvOY27xth3ZB0GabNSU5t9XkisiNskoQfOy0YizT0uwW3IRuhrneEt86UHYXNhtbgZvgO0G7ZQYW5ybKaGWH4M7iZMJxLvN1CfBnz5niRFWRTZXZ/hQ0eUTv2CJX5oE/Ch4qW/+t2MnmTzljm9fd4nTHeESzoN7s4hJ5mSQjVa2ZknWojhW1fZ4xxAWGqVXrejvtNkI335vnk9g96WudhISQBx6hM2nzjjrHOcN444w00ECsdpdvY1bX6W2dBAVgTafApO7YZaWV+jjZHWb52/wirkpv9LJavliHZLTl0mIQYsAOTZPNNiDrQHFusOcdq0ZtAVjwsEquaJT1ovWmGaCPfiU1sZAJHYcxVrVGNaooxLCpwsNgrbWW62aAG2PeQK7M6x72S0XjyMMm9FItXebHPvzl4bJg5JCIzaWdKmmHfkbmA5WPYc+Ke9rhbbZknBVKgAlk1TlTTlIifyhBT910Tcq3zAFXmO5JDW6QlK7WLKtXl4HlHm63w2yP+9UiVztgUR7YvaJHdO5hHJhwsiEeVm+JyVIe9TwTtNhVLEGHk2u0S0X1jxHeMMrx3he0uDufYuM02lfRSzqTKdq0uiQaHWuIwT4SNLmzkJ2j7RJ87kHnHOJOF+QqGa0mFMdDrRTsd3tHXPtari3qt6s96Jyy2lMqE2WkXR6Naq0S7HNrabb2N8VLfpCJsKs84OxOsPm+MREMt0bQaM7B3nAJx7vaYj9G3jb41H3GVmAnaJVxFUZYL2gw+1ARShhkqqXqYth7nRUrb5do1WaSU3wu2GNGV04y4QTTvOynqAvu8Yl7jFGD8VIyHvWZYLcbupgbEXaw6ZbZWsSudJfbpeQ0CXa69t/gCpJ0ouu8YluEbYmaVv1Bnkz/Abs1yoTfDtL4/4NUqTXXhzYcCvcPJW3tLW3vIrAAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMDMtMjlUMTM6NTM6MzErMDA6MDAagCFsAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIyLTAzLTI5VDEzOjUzOjMxKzAwOjAwa92Z0AAAAABJRU5ErkJggg==";
const MIN_OPACITY =0.5;

export const Box = memo(function Box({ name, id, style, isDropped, onQuery }) {
  const [{ opacity }, drag, connectDragPreview] = useDrag(() => ({
    type: "a",
    item: { name, id },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? MIN_OPACITY : 1,
    }),
  }), [name, id]);

  const words = name.split("/")
    .map((w) => w.trim());

  const jsx = words.map((word, index) => {
    return (<React.Fragment key={word}><a key={word} onClick={() => onQuery(word)}>
        {word}
      </a>{" "}{words[index + 1] ? "/ " : ""}
      </React.Fragment>
    );
  });


  return (
    <>
      <DragPreviewImage src={PREVIEW} connect={connectDragPreview} />
      <div ref={drag} role="Box" className={"item draggable " + (isDropped ? "cross-line" : "")}
           style={{ ...style, ..._style, backgroundColor: opacity === MIN_OPACITY ? "#15bf75": "" }}>
        {isDropped ? <s>{jsx}</s> : jsx}
      </div>
    </>
  );
});
