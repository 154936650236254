import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { isLastOfArray } from '../../../../../../base/helpers/isLastOfArray';


const AvailableInventoryDropdown = ({
                          defaultSelected,
                          onChange= () => {},
                          list = [],
                          classes
                        }) => {
  const [ isOpen, updateIsOpen ] = useState(false);
  const [ selected, updateSelected ] = useState(() => defaultSelected);

  useEffect(() => {
    onChange(selected.value)
  }, [onChange, selected]);

  const toggle = useCallback(() => {
    updateIsOpen(isOpen => !isOpen);
  }, []);

  const handleSelect = (selectedItem) => {
    updateSelected(selectedItem)
  };

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle className={classes ? classes :'custom-button-dropdown'}>
        {selected.label}
        <i className='mdi mdi-chevron-down'></i>
      </DropdownToggle>
      <DropdownMenu right>
        {list.map((item, index, arr) => (
          <React.Fragment  key={index}>
            <DropdownItem
              value={item.value}
              onClick={() => handleSelect(item)}
              active={item.value === selected.value}
            >
              {item.label}
            </DropdownItem>
            {!isLastOfArray(arr, index) && <DropdownItem divider />}
          </React.Fragment>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
};

AvailableInventoryDropdown.propTypes = {
  defaultSelected: PropTypes.object,
  onChange: PropTypes.func,
  list: PropTypes.array
};

export default AvailableInventoryDropdown;