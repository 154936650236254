import React from "react";
import PropTypes from "prop-types";

import { Row, Col, Modal } from "reactstrap";

export const CustomModal = ({ title, isOpen, onClose, children }) => {
  return (
    <Row>
      <Col className="col-12">
        <Modal isOpen={isOpen} size="lg">
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              {title}
            </h5>
            <button
              type="button"
              onClick={onClose}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">{children}</div>
        </Modal>
      </Col>
    </Row>
  );
};

CustomModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
};
