import React from "react";
import { Card, CardBody } from "reactstrap";
import ItemToDo from "../ItemToDo/ItemToDo";


const ListToDo = () => {

  return (
    <Card className={'h-100 '}>
      <CardBody className="py-3">
        <h3 className="font-size-19 font-weight-semibold title-color">
          To Do
        </h3>
        <ul className="task-list">
          <ItemToDo />
        </ul>
      </CardBody>
    </Card>
  );
};

export default ListToDo;
