import { useCallback, useContext } from "react";
import { MDTextContext } from "../contexts/TranslateContainer";

export const useTranslate = () => {
  const { Translate, language, selectLanguage } = useContext(MDTextContext);
  const translate = useCallback(
    (key, params) => {
      return Translate.translate(key, {
        ...params,
				label: params?.label?.replace("*", ""),
      });
    },
    [Translate]
  );
  return [translate, language, selectLanguage];
};
