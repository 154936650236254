import React, { useCallback, useState, useEffect } from "react";

import DropzoneFile from "../DropzoneFile/index";
import { CustomModal } from "../GoogleMap/components/Modal";
import { ImageCropper } from "../ImageCropper";

import PropTypes from "prop-types";

const ImageUploader = ({ onCropImage, onMount }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const onOpenModal = useCallback(() => setIsOpenModal(true), [setIsOpenModal]);

  const onCloseModal = useCallback(() => {
    setIsOpenModal(false);
    setSelectedImage(null);
  }, [setIsOpenModal, setSelectedImage]);

  const onCloseCropperModal = useCallback(
    () => setSelectedImage(null),
    [setSelectedImage]
  );

  const onSelectedImage = useCallback(
    (file) => {
      setSelectedImage({
        ...file,
        preview: URL.createObjectURL(file),
      });
    },
    [setSelectedImage]
  );

  useEffect(() => {
    setIsOpenModal(false);
  }, [selectedImage]);

  useEffect(() => {
    onMount({
      onOpen: onOpenModal,
      onClose: onCloseModal,
    });
  }, [onOpenModal, onCloseModal, onMount]);

  return (
    <>
      <CustomModal
        title="Choose photo of your profile"
        isOpen={isOpenModal && !selectedImage}
        onClose={onCloseModal}
      >
        <DropzoneFile
          onReceiveFile={onSelectedImage}
          onCloseModal={onCloseModal}
        />
      </CustomModal>
      <CustomModal
				title="Crop your profile photo"
				isOpen={!!selectedImage}
				onClose={onCloseCropperModal}
      >
        <ImageCropper
          selectedImage={selectedImage}
          onCropImage={onCropImage}
          onCloseCropper={onCloseCropperModal}
          isRoundedCropper={false}
        />
      </CustomModal>
    </>
  );
};

ImageUploader.propTypes = {
  onMount: PropTypes.func,
  onCropImage: PropTypes.func,
};

export default ImageUploader;
