import Http from "./Http";

class ExpensesService extends Http {
  static $displayName = "ExpensesService";

  getListExpenses(params) {
    return this.get("/expenses", { params });
  }

  createExpense(values) {
    return this.post("/expenses", values);
  }

  updateExpense(id, values) {
    return this.put(`/expenses/${id}`, values);
  }

  deleteExpense(id) {
    return this.delete(`/expenses/${id}`);
  }

  getExpense(id) {
    return this.get(`/expenses/${id}`);
  }

  getExpensesCategories() {
    return this.get("/expenses/categories");
  }
}

export default ExpensesService;
