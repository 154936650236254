import React from "react";
import PropTypes from "prop-types";

import { CustomModal } from "../../../../../base/components/GoogleMap/components/Modal";
import DropzoneFile from "../../../../../base/components/DropzoneFile";

const UploaderModal = ({ isOpen, setImages, onClose, onSave }) => {
  return (
    <CustomModal title={`Choose photo`} isOpen={isOpen} onClose={onClose}>
      <DropzoneFile
        onReceiveFile={setImages}
        onCloseModal={onClose}
        onSave={onSave}
      />
    </CustomModal>
  );
};

UploaderModal.propTypes = {
  isOpen: PropTypes.bool,
  setImages: PropTypes.func,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
};

export default UploaderModal;
