import { useMemo, useRef, useState, useEffect } from "react";
import Bugsnag from "@bugsnag/js";

import { useGetSoldItemsPoshmark } from "./useGetSoldItemsPoshmark";
import { useGetSoldItemsMercari } from "./useGetSoldItemsMercari";
import { useGetSoldItemsEtsy } from "./useGetSoldItemsEtsy";
import { useGetSoldItemsEbay } from "./useGetSoldItemsEbay";
import { useLoading } from "../../../base/hooks/useLoading";
import { useProduct } from "../../drafts/hooks/useProduct";
import { PRODUCT_TYPE } from "../../../base/constants/product";
import { MARKETPLACES } from "../../../base/constants/extension";
import { useService } from "../../../base/hooks/useService";
import MarketPlaceService from "../../../services/MarketplaceService";
import { MARKETPLACES_ACCOUNT_CODES } from "../../drafts/pages/Settings/page/AutomationSetup/constants";
import { getMarketplacesForDelist } from "../helpers";
import { phrases } from "../../../store/phrases";

const LIMIT_ACTIVE_INVENTORIES = 25;

export const useUpdateSoldItems = () => {
  const poshmark = useGetSoldItemsPoshmark();
  const mercari = useGetSoldItemsMercari();
  const etsy = useGetSoldItemsEtsy();
  const ebay = useGetSoldItemsEbay();
  const [isDownloadingItems, { registerPromise }] = useLoading();
  const marketplaceService = useService(MarketPlaceService);
  const [loaderMessage, setLoaderMessage] = useState(null);

  const inventoryListRef = useRef([]);
  const inventoryList = inventoryListRef.current;
  const updateInventoryList = (callback) => {
    inventoryListRef.current = callback(inventoryListRef.current);
  };

  const allMarketplaces = useMemo(
    () => [poshmark, etsy, mercari, ebay],
    [ebay, etsy, mercari, poshmark]
  );

  const getCollection = useProduct(
    PRODUCT_TYPE.ACTIVE,
    LIMIT_ACTIVE_INVENTORIES
  );

  const getActiveInventory = (offset = 0) => {
    const inventoryRequest = getCollection({ offset });
    return inventoryRequest
      .then((data) => {
        const { data: invData = {} } = data || {};

        updateInventoryList((prevState) => [...prevState, ...invData]);
        if (
          inventoryList.length <= data.pagination.totalCount &&
          data?.data.length
        ) {
          return getActiveInventory(data.pagination.nextOffset);
        }
      })
      .catch((e) => Bugsnag.notify(e));
  };
  const marketplaces = [
    {
      field: "productEbaySpecificFields",
      marketplace: MARKETPLACES.EBAY,
    },
    {
      field: "productEtsySpecificFields",
      marketplace: MARKETPLACES.ETSY,
    },
    {
      field: "productPoshmarkSpecificFields",
      marketplace: MARKETPLACES.POSHMARK,
    },
    {
      field: "productMercariSpecificFields",
      marketplace: MARKETPLACES.MERKARI,
    },
  ];

  const getActiveProducts = (inventoryList) => {
    let activeProductsMap = {
      [MARKETPLACES.EBAY]: {},
      [MARKETPLACES.ETSY]: {},
      [MARKETPLACES.POSHMARK]: {},
      [MARKETPLACES.MERKARI]: {},
    };

    marketplaces.forEach((item) => {
      inventoryList.forEach((inventory) => {
        if (
          inventory[item.field]?.listingId &&
          inventory[item.field]?.status === PRODUCT_TYPE.ACTIVE
        ) {
          activeProductsMap[item.marketplace] = {
            ...activeProductsMap[item.marketplace],
            [inventory[item.field]?.listingId]: {
              data: inventory[item.field],
              marketplacesForDelist: getMarketplacesForDelist(
                marketplaces,
                inventory,
                item
              ),
            },
          };
        }
      });
    });
    return activeProductsMap;
  };

  const syncAll = async () => {
    const { data: marketplaceData } = await marketplaceService.getMarketplace();

    setLoaderMessage(
      `${phrases.updatingYourSales}: ${phrases.fetchingActiveInventory}`
    );

    await registerPromise(getActiveInventory());
    const activeInventory = getActiveProducts(inventoryListRef.current);

    setLoaderMessage(
      `${phrases.updatingYourSales}: ${phrases.fetchingSoldItems}`
    );

    allMarketplaces.forEach((market) => {
      const marketplaceInfo =
        marketplaceData[MARKETPLACES_ACCOUNT_CODES[market.marketplace]];

      if (market.isConnected && !market.isProcessing && marketplaceInfo) {
        market.sync(activeInventory[market.marketplace], marketplaceInfo);
      }
    });
  };

  const isSyncActive = useMemo(() => {
    return allMarketplaces.some(({ isProcessing }) => isProcessing);
  }, [allMarketplaces]);

  useEffect(() => {
    if (!isSyncActive && !isDownloadingItems) {
      setLoaderMessage(null);
    }
  }, [isSyncActive, isDownloadingItems]);

  return {
    isSyncActive,
    isDownloadingItems,
    syncAll,
    getActiveInventory,
    loaderMessage,
  };
};
