import PropTypes from "prop-types";
import { useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useService } from "../../../../base/hooks/useService";
import AuthService from "../../../../services/AuthService";
import { logout } from "../../../../services/LogoutWithSimpleAxios";
import StorageService from "../../../../services/StorageService";
import { AUTH_GROUP_LINKS } from "../../config";

const Logout = () => {
  const history = useHistory();
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);

  useEffect(() => {
    storage.clear();
    logout();
    history.replace(AUTH_GROUP_LINKS.LINK_LOGIN);
  }, [storage, history, authService]);

  return null;
};

Logout.propTypes = {
  logoutUser: PropTypes.func,
};

export default Logout;
