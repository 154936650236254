/* eslint-disable react/prop-types */
import { useEffect, useMemo, useRef, useState } from "react";

import Divider from "../../Divider";
import { Col, Row } from "reactstrap";
import FormikSingleSelect from "../../../../../base/components/FormikSingleSelect";
import React from "react";
import { findElement, useMercaryCategory } from "./hooks";
import { createFullTextSearch } from "../../../../../base/helpers/categories";
import { useService } from "../../../../../base/hooks/useService";
import MercariExtension from "../../../../../services/MercariExtension";

const useSuggestedCategoryTree = (categories, suggested, setFieldValue) => {
  const searchSuggested = useMemo(() => {
    return createFullTextSearch(suggested);
  }, [suggested]);
  const ref = useRef();

  useEffect(() => {
    if (!categories.length) return;

    const bestMatch = searchSuggested(categories, ({ label }) => label);

    const findCategories = (matched) => {
      const ids = [matched.id];

      if (!matched.parentId) return ids;

      ids.unshift(matched.parentId);

      const topId = findElement(categories, matched.parentId)?.parentId;

      if (topId) {
        ids.unshift(topId);
      }

      return ids;
    };

    const applySuggestedCategory = (matched) => {
      const ids = findCategories(matched);

      const orderDropdowns = [
        "topCategoryId",
        "nestedCategoryId",
        "categoryId",
      ];

      ids.forEach((id, index) => {
        setFieldValue(orderDropdowns[index], id);
      });

      ref.current?.scrollIntoView({
        block: "center",
      });
    };

    // Auto-select
    // if (bestMatch.length === 1) {
    //   const [matched] = bestMatch;
    //   applySuggestedCategory(matched);
    //   // Best Match - First
    // } else
    if (bestMatch.length) {
      const [matched] = bestMatch;
      applySuggestedCategory(matched);
    }
  }, [searchSuggested, categories]);

  return ref;
};

export const Categories = ({
  categories,
  topCategoryId,
  nestedCategoryId,
  categoryId,
  setFieldValue,
  errorMessage,
  loading = false,
  suggested,
  dressOccasion,
  dressStyle,
}) => {
  const {
    categoryName,
    subCategories,
    nestedCategories,
    topCategories,
    categoryShortName,
    category,
  } = useMercaryCategory(categories, {
    categoryId,
    nestedCategoryId,
    topCategoryId,
  });

  const [displayDressOccasion, setDisplayDressOccasion] = useState(false);
  const [dressOccasionOptions, setDressOccasionOptions] = useState([]);
  const [dressOccasionId, setDressOccasionId] = useState(null);
  const [displayDressStyle, setDisplayDressStyle] = useState(false);
  const [dressStyleOptions, setDressStyleOptions] = useState([]);
  const [dressStyleId, setDressStyleId] = useState(null);

  const extension = useService(MercariExtension);

  // Set category name
  useEffect(() => {
    setFieldValue("categoryShortName", categoryShortName);
  }, [categoryShortName]);

  useEffect(() => {
    setFieldValue("categoryName", categoryName);
  }, [categoryName]);

  useEffect(() => {
    if (dressOccasion === null) return;

    setFieldValue("dressOccasion", dressOccasion);
    setDressOccasionId(dressOccasion);
  }, [dressOccasion]);

  useEffect(() => {
    if (dressStyle === null) return;

    setFieldValue("dressStyle", dressStyle);
    setDressStyleId(dressStyle);
  }, [dressOccasion]);

  const resetSubCategories = () => {
    setFieldValue("categoryId", null);
    setFieldValue("categoryShortName", "");
    setFieldValue("categoryName", "");
  };

  const resetNestedCategories = () => {
    setFieldValue("nestedCategoryId", null);
    resetSubCategories();
  };

  // Use this hook for edit form, recovery categories tree from one category id
  useEffect(() => {
    if (!categories.length) return;

    const isRecoveryFormValues = categoryId && !topCategoryId;
    const ids = [categoryId];
    const orderFields = ["topCategoryId", "nestedCategoryId", "categoryId"];

    if (!isRecoveryFormValues) return;

    const nestedId = findElement(categories, categoryId)?.parentId;

    if (nestedId) {
      ids.unshift(nestedId);
    }

    const topId = findElement(categories, nestedId)?.parentId;

    if (topId) {
      ids.unshift(topId);
    }

    ids.forEach((id, index) => {
      setFieldValue(orderFields[index], id);
    });
  }, [categoryId, topCategoryId, categories]);

  useEffect(() => {
    const handleFieldChange = async () => {
      const options = await extension.getDressOccasionOptions();
      const {
        data: {
          searchCustomItemFields: { valuesWithNextFieldList: fields },
        },
      } = options;

      const formattedOptions = fields.map((field) => {
        const { value } = field;

        return {
          ...value,
          value: value.id,
          label: value.name,
        };
      });

      setDressOccasionOptions(formattedOptions);
    };

    const permittedCategoryIds = [149, 150, 151, 152, 153, 154];

    if (permittedCategoryIds.some((catId) => catId === categoryId)) {
      setDisplayDressOccasion(true);
      handleFieldChange();
    } else {
      setDisplayDressOccasion(false);
      setDisplayDressStyle(false);
      setDressOccasionId(null);
    }
  }, [categoryId, extension]);

  useEffect(() => {
    const handleDressOccasionChange = async () => {
      const options = await extension.getDressStyleOptions(dressOccasionId);

      const {
        data: {
          searchCustomItemFields: { valuesWithNextFieldList: fields },
        },
      } = options;

      const formattedOptions = fields.map((field) => {
        const { value } = field;

        return {
          ...value,
          value: value.id,
          label: value.name,
        };
      });

      setDressStyleOptions(formattedOptions);
    };

    setFieldValue("dressOccasion", dressOccasionId);

    if (dressOccasionId !== null) {
      setDisplayDressStyle(true);
      handleDressOccasionChange();
    }
  }, [dressOccasionId, extension, setFieldValue]);

  useEffect(() => {
    setFieldValue("dressStyle", dressStyleId);
  }, [dressStyleId, setFieldValue]);

  return (
    <>
      <h3 className="bold-black mb-4">Category</h3>
      <Divider />

      <Row>
        <Col md={6}>
          <FormikSingleSelect
            name="topCategoryId"
            options={topCategories}
            placeholder="Select category"
            onChange={resetNestedCategories}
            label={"Category*"}
            loading={loading}
            customError={!topCategoryId && errorMessage}
          />
        </Col>

        <Col md={6}>
          <FormikSingleSelect
            label={"Subcategory*"}
            name="nestedCategoryId"
            options={nestedCategories}
            placeholder="Select sub-category"
            onChange={resetSubCategories}
            disabled={!topCategoryId}
            customError={!nestedCategoryId && errorMessage}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          {nestedCategoryId && (
            <FormikSingleSelect
              isHideLabel
              name="categoryId"
              label={"Subcategory"}
              options={subCategories}
              placeholder="Select sub-category"
              customError={errorMessage}
            />
          )}
        </Col>
      </Row>
      {displayDressOccasion && (
        <Row>
          <Col md={6}>
            <FormikSingleSelect
              name="dressOccasion"
              options={dressOccasionOptions}
              placeholder="Select an occasion"
              label="Dress Occasion*"
              customError={errorMessage}
              onChange={(e) => setDressOccasionId(e?.value)}
            />
          </Col>
          {displayDressStyle && (
            <Col md={6}>
              <FormikSingleSelect
                name="dressStyle"
                options={dressStyleOptions}
                placeholder="Select a style"
                label="Dress Style*"
                customError={errorMessage}
                onChange={(e) => setDressStyleId(e?.value)}
              />
            </Col>
          )}
        </Row>
      )}
    </>
  );
};
