/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useState } from "react";
import { Card, CardBody } from "reactstrap";
import moment from "moment";
import Bugsnag from "@bugsnag/js";

import TitleWrapper from "../TitleWrapper";
import { ReactComponent as IconPercent } from "../../assets/images/ic-percent.svg";
import { ReactComponent as IconSales } from "../../assets/images/ic-sales.svg";
import { ReactComponent as IconShipCart } from "../../assets/images/ic-ship-cart.svg";
import { ReactComponent as IconGrossProfit } from "../../assets/images/ic-grossprofit.svg";
import { ReactComponent as IconDays } from "../../assets/images/ic-days.svg";
import { ReactComponent as IconShipping } from "../../assets/images/ic-shipping.svg";
import { ReactComponent as IconFees } from "../../assets/images/ic-platform-fees.svg";
import { useService } from "../../../../base/hooks/useService";
import AnalyticsService from "../../../../services/AnalyticsService";
import { separateThousandth } from "../../helpers/separateThousandth";
import AnalyticItem from "../AnalyticItem";
import InventoryDropdown from "../InventoryDropdown";
import { marketplaceTypeList } from "../../constants/analisticsSalesPerPlatform";
import style from "../../pages/SalesPerPlatform/index.module.scss";
import { useAnalyticRangeDateFilter } from "../../hooks/useAnalyticRangeDateFilter";
import AnalyticRangeDateFilter from "../AnalyticRangeDateFilter";
import AnalyticRangeDateInput from "../AnalyticRangeDateInput";

import { MOMENT_MONTH } from "../../../bookkeeping/constants";
import { changeDataToPercent } from "../../helpers/changeDataToPercent";

import "../Overview/index.scss";

const Averages = ({
  isPerPlatform = false,
  onSelectPlatform = () => {},
  marketplaceType = null,
}) => {
  const [averages, updateAverages] = useState({});

  const [dateFilter, { onChangeDateFilter, formatDate }, dateFilterRef] =
    useAnalyticRangeDateFilter({
      startDate: moment().startOf(MOMENT_MONTH).toDate(),
      endDate: moment().endOf(MOMENT_MONTH).toDate(),
    });

  /**
   * @type {AnalyticsService}
   */
  const analytics = useService(AnalyticsService);

  useEffect(() => {
    if (!dateFilter.dateRange.endDate) {
      analytics
        .getAverages({
          marketplaceType,
        })
        .then(({ data }) => {
          updateAverages(data);
        })
        .catch((e) => Bugsnag.notify(e));
    } else {
      analytics
        .getAverages({
          startDate: formatDate(dateFilter.dateRange.startDate),
          endDate: formatDate(dateFilter.dateRange.endDate),
          marketplaceType,
        })
        .then(({ data }) => {
          updateAverages(data);
        })
        .catch((e) => Bugsnag.notify(e));
    }
  }, [marketplaceType, dateFilter.dateRange]);

  return (
    <Card className={"h-100"}>
      <CardBody className={"overview-container"}>
        <TitleWrapper title={"Averages"}>
          <AnalyticRangeDateFilter
            dateRange={useMemo(
              () => [
                dateFilter.dateRange.startDate,
                dateFilter.dateRange.endDate,
              ],
              [dateFilter]
            )}
            dateRangeType={dateFilter.dateRangeType}
            onChange={onChangeDateFilter}
            CustomInput={AnalyticRangeDateInput}
            dateFilterRef={dateFilterRef}
          />
        </TitleWrapper>
        {isPerPlatform && (
          <InventoryDropdown
            list={marketplaceTypeList}
            onChange={onSelectPlatform}
            defaultSelected={marketplaceTypeList[0]}
            classes={style.dropdownButton}
            right={false}
          />
        )}
        <AnalyticItem
          icon={<IconPercent />}
          label={"Avg. Profit Margin"}
          value={
            averages?.avgProfitMargin &&
            changeDataToPercent(averages?.avgProfitMargin)
          }
        />
        <AnalyticItem
          icon={<IconSales />}
          label={"Avg. COGS"}
          value={separateThousandth(averages?.avgCogs)}
        />
        <AnalyticItem
          icon={<IconShipCart />}
          label={"Avg. Sales price"}
          value={separateThousandth(averages?.avgSalesPrice)}
        />
        <AnalyticItem
          icon={<IconGrossProfit />}
          label={"Avg.  GrossProfit"}
          value={separateThousandth(averages?.avgGrossProfit)}
        />
        <AnalyticItem
          icon={<IconDays />}
          label={"Avg.  Days till sold"}
          value={Math.round(averages?.avgDaysTillSold)}
        />
        <AnalyticItem
          icon={<IconShipping />}
          label={"Avg.  Shipping"}
          value={separateThousandth(averages?.avgShipping)}
        />
        <AnalyticItem
          icon={<IconFees />}
          label={"Avg.  Platform fees"}
          value={separateThousandth(averages?.avgPlatformFees)}
        />
      </CardBody>
    </Card>
  );
};

export default Averages;
