import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import VideoContainer from "../../../completeProfile/components/VideoContainer";

import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";
import SettingsService from "../../../../services/Settings";

import styles from "./index.module.scss";

const Instruction = () => {
  const [data, updateData] = useState({});

  const [, { registerPromise }] = useLoading();

  /**
   * @type {SettingsService}
   */
  const settingsService = useService(SettingsService);

  useEffect(() => {
    registerPromise(settingsService.getYouTubeLinks())
      .then(({ data }) => updateData(data))
      .catch((e) => Bugsnag.notify(e));
  }, [registerPromise, settingsService]);

  return (
    <Row className="mt-3">
      <Col lg="12" xl="7">
        <Card>
          <CardBody>
            <div className="onboarding-instruction">
              <VideoContainer src={data.videoLink} />
              <h2 className={styles.title}>{data.title}</h2>
              <p className={styles.description}>{data.description}</p>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Instruction;
