import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import AuthCardContainer from "../../components/AuthCardContainer";

import {
  VERIFICATION_SUCCESS_MESSAGE,
  VERIFICATION_TOKEN_ERROR_MESSAGE,
} from "../../../../base/constants/messages";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useService } from "../../../../base/hooks/useService";
import AuthService from "../../../../services/AuthService";
import { AUTH_GROUP_LINKS } from "../../config";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../base/components/CustomModal";
import Button from "../../../../base/components/Button";
import { getIsExpiredToken } from "../../utils/getIsExpiredToken";
import ExpitedCard from "../../components/ExpiredCard";
import StorageService from "../../../../services/StorageService";
import { KEY_VERIFY_EMAIL } from "../../../../base/constants/storage";
import Bugsnag from "@bugsnag/js";

const Verification = () => {
  const history = useHistory();

  const [isOpenModal, updateIsOpenModal] = useState(false);
  const [isOpenResendModal, updateIsOpenResendModal] = useState(false);
  const [isDisabledButton, updateIsDisabledButton] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const [errorToken, updateTokenError] = useState({
    isError: false,
    message: "",
    isExpired: null,
  });

  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);

  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const {
    params: { token },
  } = useLocationQuery();

  const verifyToken = useCallback(
    async (values) => {
      try {
        await authService.verifyUser(values);

        updateIsOpenModal(true);
        setSuccessMessage(VERIFICATION_SUCCESS_MESSAGE);
      } catch (baseError) {
        const { error } = baseError;
        Bugsnag.notify(baseError);
        const isExpired = getIsExpiredToken(error?.code);

        updateTokenError({
          isError: true,
          message: isExpired
            ? VERIFICATION_TOKEN_ERROR_MESSAGE
            : error?.message,
          isExpired: isExpired,
        });

        updateIsOpenModal(!isExpired);
      }
    },
    [authService]
  );

  const handleToogleResendModal = useCallback(() => {
    updateIsOpenResendModal((isOpen) => !isOpen);
  }, []);

  const handleResendVerification = useCallback(() => {
    authService.resendVerificationLink({ token }).then(() => {
      handleToogleResendModal();
      updateIsDisabledButton(true);
    });
  }, [token, handleToogleResendModal, authService]);

  useEffect(() => {
    storage.set(KEY_VERIFY_EMAIL, Date.now());
  }, [storage]);

  useEffect(() => {
    verifyToken({ token });
  }, []);

  const handleRedirect = useCallback(() => {
    history.push(AUTH_GROUP_LINKS.LINK_LOGIN);
  }, [history]);

  return (
    <AuthCardContainer metaText="Verification">
      <CustomModal isOpen={isOpenModal} onClose={handleRedirect}>
        <ModalHeader onClose={handleRedirect}>Verify email address</ModalHeader>
        <ModalBody>
          <p className="ui-regular">{errorToken.message || successMessage}</p>
        </ModalBody>
        <ModalFooter>
          <Button className="filled-primary" onClick={handleRedirect}>
            {errorToken.message && "Close"}
            {successMessage && "Ok"}
          </Button>
        </ModalFooter>
      </CustomModal>
      {errorToken.isExpired && (
        <ExpitedCard
          isDisabledButton={isDisabledButton}
          handleResendVerification={handleResendVerification}
        />
      )}
      <CustomModal isOpen={isOpenResendModal} onClose={handleToogleResendModal}>
        <ModalHeader onClose={handleToogleResendModal}>
          Verify email address
        </ModalHeader>
        <ModalBody>
          <p className="ui-regular">A new link was sent to your email</p>
        </ModalBody>
      </CustomModal>
    </AuthCardContainer>
  );
};

export default Verification;
