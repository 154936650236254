import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import PropTypes from "prop-types";

import Table from "../../../../../../base/components/Table";
import {
  CustomModal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "../../../../../../base/components/CustomModal";
import CustomButton from "../../../../../../base/components/CustomButton";

import { useLocationQuery } from "../../../../../../base/hooks/useQueryString";
import { useTable } from "../../../../../../base/hooks/useTable";
import { useStatefullProvider } from "../../../../../../base/hooks/useProvider";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { TableContext } from "../../../../contexts/table";
import { useService } from "../../../../../../base/hooks/useService";
import EmployeesService from "../../../../../../services/EmployeesService";

import styles from "./index.module.scss";
import { TableStructure, LIMIT } from "./table";
import TableHeader from "./components/TableHeader";
import CreateEmployee from "./components/CreateEmployee";
import { useEmployee, useTotalEmployees } from "./hooks/useEmployee";
import { EMPLOYEE_STATUSES } from "./constants";

const EmployeeManagement = ({ total, allowed }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenConfirmBlockModal, setIsOpenConfirmBlockModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [blockEmployeeInfo, setBlockEmployeeInfo] = useState({});

  const deleteId = useRef(null);

  const [asyncLoading, { registerPromise }] = useLoading();

  const { params, merge } = useLocationQuery();
  const { offset } = params;

  const [, updateTableContext] = useStatefullProvider(TableContext);

  const getEmployees = useEmployee(LIMIT);
  const [loading, pagination, list, handleTableChange, { refresh }] = useTable({
    getNewDataService: getEmployees,
    limit: LIMIT,
  });

  /**
   * @type {EmployeesService}
   */
  const employeesService = useService(EmployeesService);

  const handleAddEmployee = useCallback(() => {
    setIsOpenModal(true);
  }, [setIsOpenModal]);

  const onCloseModal = useCallback(() => {
    setIsOpenModal(false);
  }, [setIsOpenModal]);

  const onOpenErrorModal = useCallback(() => {
    setIsOpenErrorModal(true);
  }, [setIsOpenErrorModal]);

  const onCloseErrorModal = useCallback(() => {
    setIsOpenErrorModal(false);
  }, [setIsOpenErrorModal]);

  const onOpenConfirmModal = useCallback(() => {
    setIsOpenConfirmModal(true);
  }, [setIsOpenConfirmModal]);

  const onCloseConfirmModal = useCallback(() => {
    setIsOpenConfirmModal(false);
  }, [setIsOpenConfirmModal]);

  const onConfirmInvite = useCallback(() => {
    updateTableContext((data) => ({
      ...data,
      total: data.total + 1,
    }));

    refresh();
  }, [refresh, updateTableContext]);

  const onConfirmDelete = useCallback(async () => {
    await registerPromise(employeesService.deleteEmployee(deleteId.current));

    const noData = !(list.length - 1);

    updateTableContext((data) => ({
      ...data,
      total: data.total - 1,
    }));

    refresh();

    setIsOpenDeleteModal(false);

    if (noData && offset) {
      merge("offset", offset - LIMIT);
      return;
    }
  }, [
    refresh,
    list.length,
    merge,
    offset,
    updateTableContext,
    employeesService,
    registerPromise,
    setIsOpenDeleteModal,
  ]);

  const onCloseConfirmDelete = useCallback(() => {
    setIsOpenDeleteModal(false);
  }, [setIsOpenDeleteModal]);

  const onDelete = useCallback(
    (id) => {
      deleteId.current = id;
      setIsOpenDeleteModal(true);
    },
    [setIsOpenDeleteModal]
  );

  const onConfirmBlock = useCallback(async () => {
    await employeesService.changeEmployeeStatus(blockEmployeeInfo);

    const action = {
      [EMPLOYEE_STATUSES.active.code]: () => {
        updateTableContext((data) => ({
          ...data,
          allowed: data.allowed + 1,
        }));
      },
      [EMPLOYEE_STATUSES.blocked.code]: () => {
        updateTableContext((data) => ({
          ...data,
          allowed: data.allowed - 1,
        }));
      },
    };

    action[blockEmployeeInfo?.status]();
    refresh();
    setIsOpenConfirmBlockModal(false);
  }, [employeesService, refresh, updateTableContext, blockEmployeeInfo]);

  const onBlock = useCallback(
    (id, newStatus) => {
      setIsOpenConfirmBlockModal(true);
      setBlockEmployeeInfo({ id, status: newStatus });
    },
    [setIsOpenConfirmBlockModal]
  );

  const onCloseConfirmBlockEmployee = useCallback(() => {
    setIsOpenConfirmBlockModal(false);
  }, [setIsOpenConfirmBlockModal]);

  useEffect(() => {
    updateTableContext((data) => ({
      ...data,
      onBlock,
      onDelete,
    }));
  }, [onBlock, updateTableContext, onDelete]);

  useEffect(() => {
    updateTableContext((data) => ({
      ...data,
      total,
      allowed,
    }));
  }, [total, allowed, updateTableContext]);

  return (
    <>
      <div className={styles.tableWrapper}>
        <Table
          data={list}
          dataStructure={TableStructure}
          loading={loading}
          HeaderComponent={useCallback(
            () => (
              <TableHeader onAddEmployee={handleAddEmployee} />
            ),
            [handleAddEmployee]
          )}
          limit={LIMIT}
          offset={params.offset || 0}
          totalCount={pagination?.totalCount}
          handleTableChange={handleTableChange}
          noDataMessage="No Employee for now"
        />
      </div>
      <CustomModal isOpen={isOpenModal} onClose={onCloseModal}>
        <ModalHeader onClose={onCloseModal}>New Employee</ModalHeader>
        <ModalBody>
          <CreateEmployee
            onCloseModal={onCloseModal}
            onOpenErrorModal={onOpenErrorModal}
            onOpenConfirmModal={onOpenConfirmModal}
            onConfirmInvite={onConfirmInvite}
          />
        </ModalBody>
      </CustomModal>
      <CustomModal isOpen={isOpenConfirmModal} onClose={onCloseConfirmModal}>
        <ModalHeader onClose={onCloseConfirmModal}>Invite employee</ModalHeader>
        <ModalBody>Invitation link was successfully sent</ModalBody>
        <ModalFooter>
          <CustomButton
            className="filled-primary"
            onClick={onCloseConfirmModal}
          >
            Ok
          </CustomButton>
        </ModalFooter>
      </CustomModal>
      <CustomModal isOpen={isOpenErrorModal} onClose={onCloseErrorModal}>
        <ModalHeader onClose={onCloseErrorModal} isEmpty={true}></ModalHeader>
        <ModalBody>
          User with such email has already been registered as Employee.
        </ModalBody>
        <ModalFooter>
          <CustomButton className="filled-primary" onClick={onCloseErrorModal}>
            Ok
          </CustomButton>
        </ModalFooter>
      </CustomModal>
      <CustomModal
        isOpen={isOpenConfirmBlockModal}
        onClose={onCloseConfirmBlockEmployee}
      >
        <ModalHeader onClose={onCloseConfirmBlockEmployee}>
          {blockEmployeeInfo?.status === EMPLOYEE_STATUSES.active.code
            ? "Unblock Employee"
            : "Block Employee"}
        </ModalHeader>
        <ModalBody>
          {blockEmployeeInfo?.status === EMPLOYEE_STATUSES.active.code
            ? "Are you sure you want to unblock the Employee?"
            : "Are you sure you want to block the chosen employee?"}
        </ModalBody>
        <ModalFooter>
          <CustomButton
            className="outline-primary"
            onClick={onCloseConfirmBlockEmployee}
          >
            No
          </CustomButton>
          <CustomButton className="filled-primary" onClick={onConfirmBlock}>
            Ok
          </CustomButton>
        </ModalFooter>
      </CustomModal>
      <CustomModal isOpen={isOpenDeleteModal} onClose={onCloseConfirmDelete}>
        <ModalHeader onClose={onCloseConfirmDelete}>
          Delete employee
        </ModalHeader>
        <ModalBody>Do you really want to delete this Employee?</ModalBody>
        <ModalFooter>
          <CustomButton
            className="outline-primary"
            onClick={onCloseConfirmDelete}
          >
            No
          </CustomButton>
          <CustomButton
            className="filled-primary"
            onClick={onConfirmDelete}
            disabled={asyncLoading}
          >
            Yes
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </>
  );
};

EmployeeManagement.propTypes = {
  total: PropTypes.number,
  allowed: PropTypes.number,
};

export default () => {
  const [{ total, allowed }] = useTotalEmployees();

  const providedData = useMemo(() => {
    return {
      onBlock: () => {},
      onDelete: () => {},
      total,
      allowed,
    };
  }, [total, allowed]);

  return (
    <TableContext.Provider value={providedData}>
      <EmployeeManagement total={total} allowed={allowed} />
    </TableContext.Provider>
  );
};
