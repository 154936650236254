import React from 'react'
import { FormGroup, Input, Label } from 'reactstrap';

const InputGroupDisabled = ({
  id,
  label,
  type='text',
  name,
  placeholder,
  defaultValue
}) => {
  return (
    <FormGroup className='mb-3 custom-input-group-payment'>
      <Label for={id}>{label}</Label>
      <Input
        defaultValue={defaultValue}
        type={type} 
        name={name} 
        id={id} 
        placeholder={placeholder}
        disabled
      />
    </FormGroup>
  );
};

export default InputGroupDisabled;