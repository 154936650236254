
export const MARKETPLACES = ["Poshmark", "Etsy", "Mercari", "Ebay", "Other"];
export const COLORS = ["#2f665c", "#4f8c83",  "#5eaca1",  "#82b1ac", "#b7d8d5", ];
export const ANALYTICS_COLORS = ["#2f665c", "#82b1ac", "#4f8c83",  "#b7d8d5", "#5eaca1"];

export const CHART_LABELS = {
  HIDDEN: {
    anchor: "top",
    align: "end",
    offset: 8,
    formatter: () => " "
  },
  PERCENTS: {
    color: "#2f665c",
    anchor: "end",
    align: "end",
    offset: 8,
    font: {
      weight: "bold",
      size: 14
    },
    formatter: value => value ? value + "%" : ''
  }
};
