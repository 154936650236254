import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';

import PaymentViewForm from './components/PaymentViewForm';
import StripeFormWrapper from './components/StripeFormWrapper';

import { useCreditCardData } from '../../../../../../base/components/Subscription/hooks/useCreditCardData';
import { PAYMENT_LINKS } from './config';

export const PaymentSetting = () => {
  const { loading, creditCard, handleUpdateCreditCard } = useCreditCardData(); 

  return (    
    <div className='mt-3'>
      <Row>
        <Col xl='4' lg='6' md='6' sm='8'>
          <Card>
            <CardBody>
              <CardTitle className='mb-4'>Сard details</CardTitle>
              {!loading && (
                <Switch>
                  <Route path={PAYMENT_LINKS.VIEW}>
                    <PaymentViewForm 
                      lastFourDigits={creditCard?.lastFourDigits}
                    />
                  </Route>
                  <Route path={PAYMENT_LINKS.EDIT}>
                    <StripeFormWrapper onUpdateCreditCard={handleUpdateCreditCard} />
                  </Route>
                  <Redirect from={PAYMENT_LINKS.BASE} to={PAYMENT_LINKS.VIEW} exact />
                </Switch>
              )}              
            </CardBody>
          </Card>
        </Col>
      </Row>      
    </div>
  )
};

export default PaymentSetting