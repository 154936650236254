import React, { useCallback } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useField } from "formik";

import { useCustomError } from "../contexts/CustomErrorMessage";
import ErrorMessageDefault from "./ErrorMessageDefault";

import { useTranslate } from "../hooks/useTranslate";
import NumberFormat from "react-number-format";

const FormikDecimal = ({
  placeholder = "0.00",
  decimalScale = 2,
  name,
  type,
  readOnly,
  label,
  disabled,
  containerClassName,
  ErrorMessage = ErrorMessageDefault,
}) => {
  const [{ value }, meta, { setValue, setTouched }] = useField({
    name,
    type,
    placeholder,
  });
  const isError = meta.error && meta.touched;
  const [translate] = useTranslate();

  const { getError } = useCustomError();
  const errorMessage = getError(name);

  return (
    <section className={classnames(containerClassName, "custom-form-group")}>
      <label className={isError || errorMessage ? "text-danger" : ""}>
        {label}
      </label>
      <NumberFormat
        readOnly={readOnly}
        disabled={disabled}
        name={name}
        value={value}
        onValueChange={({ value }) => {
          setTouched(true);
          setValue(value);
        }}
        onBlur={() => {
          setTouched(true);
        }}
        className={classnames("form-control", {
          "is-invalid-input is-invalid": isError || errorMessage,
        })}
        placeholder={placeholder}
        thousandsGroupStyle="thousand"
        prefix=""
        isNumericString
        decimalSeparator="."
        displayType="input"
        type="text"
        thousandSeparator={true}
        allowNegative={false}
        decimalScale={decimalScale}
        fixedDecimalScale={true}
      />
      <ErrorMessage name={name}>
        {translate(meta.error, { label })}
      </ErrorMessage>
    </section>
  );
};

FormikDecimal.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  ErrorMessage: PropTypes.any,
  disabled: PropTypes.bool,
  step: PropTypes.string,
  onKeyPress: PropTypes.func,
};

export default FormikDecimal;
