import React from "react";
import { ReactComponent as GoogleLogo } from "../../../../../../assets/images/settings/icon-google.svg";
import { useExtensionInstall } from "../../../../hooks/Poshmark/useExtensionInstall";

const InstallExtension = () => {
  const {onInstall} = useExtensionInstall()
  return (
    <div className="d-flex marketplace-body-text">
      <GoogleLogo className="mr-2" />
      <p className="regular-black mb-0">
        Please install the{" "}
        <span
          className='bold-green cursor-pointer'
          onClick={() => onInstall()}
        >
          Chrome extension
        </span>{" "}
        before continuing.
      </p>
    </div>
  )
}

export default InstallExtension;
