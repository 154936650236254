import { DEFAULT_FRACTION_LENGTH } from "../constants/forms";

export const getCutPrice = (price) => {
  const [wholePart, fractionalPart] = price.split(/[,.]/);

  if (!fractionalPart) return +price;

  return fractionalPart.length > DEFAULT_FRACTION_LENGTH 
    ? +`${wholePart}.${fractionalPart.slice(0, 2)}` 
    : +`${wholePart}.${fractionalPart}`;
}