import StorageService from "../../services/StorageService"
import { KEY_USER } from "../constants/storage";

export const getCurrentSubscriptionType = () => {
  const storage = new StorageService();

  const { 
    activeSubscription: { type } = {}
  } = storage.get(KEY_USER, {});

  return type;
};