/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { useHistory, useLocation } from "react-router-dom";
import Placeholder from "./placeholder";
import overlayFactory from "react-bootstrap-table2-overlay";
import { useLocationQuery } from "../../hooks/useQueryString";
import tableStructure from "./tableStructureEXAMPLE";
import { noop } from "bootstrap/js/src/util";

const spinnerStyles = {
  overlay: (base) => ({ ...base, background: "rgba(255, 255, 255, 1)" }),
  spinner: (base) => ({
    ...base,
    width: "54px",
    "& svg circle": {
      stroke: "rgba(47, 102, 92, 1)",
      strokeWidth: "3",
    },
  }),
};

function calculateCurrentPage(offset, limit) {
  return parseInt((parseInt(offset) + parseInt(limit)) / parseInt(limit));
}

const EXAMPLEHeaderComponent = () => {
  return <section className="d-flex flex-row">Table</section>;
};

export default function Table({
  noDataMessage,
  imgSrc,
  selectRow,
  expandRow,
  dataStructure = tableStructure,
  data = [],
  HeaderComponent = EXAMPLEHeaderComponent,
  PlaceholderComponent,
  isPlaceholder = false,
  handleTableChange = () => {},
  totalCount = 0,
  limit = 15,
  offset = 15,
  isRowClick = true,
  loading = false,
  onRowClick = noop,
  searchField = "",
  headerProps = {},
  classes = "",
  withLocation = true,
}) {
  const location = useLocation();
  const history = useHistory();

  const { params: query } = useLocationQuery();

  const pagination = useMemo(
    () =>
      paginationFactory({
        sizePerPage: limit,
        totalSize: totalCount,
        custom: true,
        page: calculateCurrentPage(offset, limit),
      }),
    [limit, totalCount, offset]
  );

  const rowEvents = {
    onClick: (event, row) => {
      onRowClick(row);
    },
  };

  return (
    <Row className="m-0">
      <Col lg="12" className="p-0">
        <Card>
          <CardBody className={classes}>
            <PaginationProvider pagination={pagination}>
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="id"
                  data={data || []}
                  columns={dataStructure(location)}
                  bootstrap4
                  search={{
                    defaultSearch: query[searchField] || "",
                  }}
                >
                  {(toolkitProps) => (
                    <>
                      <HeaderComponent
                        toolkitProps={toolkitProps}
                        headerProps={headerProps}
                      />
                      {isPlaceholder && (
                        <PlaceholderComponent
                          text={noDataMessage}
                          imgSrc={imgSrc}
                        />
                      )}
                      <Row>
                        <Col xl="12">
                          <div
                            className={`table-responsive${
                              isPlaceholder ? " d-none" : ""
                            }`}
                          >
                            <BootstrapTable
                              responsive
                              remote
                              rowEvents={isRowClick && rowEvents}
                              bordered={false}
                              striped={false}
                              loading={loading}
                              selectRow={selectRow}
                              expandRow={expandRow}
                              overlay={overlayFactory({
                                spinner: true,
                                styles: spinnerStyles,
                              })}
                              noDataIndication={() => (
                                <Placeholder text={noDataMessage} />
                              )}
                              classes={
                                "table align-middle table-nowrap table-hover"
                              }
                              headerWrapperClasses={
                                "table-light rounded-header"
                              }
                              {...toolkitProps.baseProps}
                              onTableChange={handleTableChange}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Row
                        className={`align-items-md-center mt-4${
                          isPlaceholder ? " d-none" : ""
                        }`}
                      >
                        <Col className="pagination pagination-rounded mb-0 inner-custom-pagination">
                          <PaginationListStandalone
                            {...paginationProps}
                            hidePageListOnlyOnePage
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

Table.propTypes = {
  data: PropTypes.array,
  dataStructure: PropTypes.any,
  HeaderComponent: PropTypes.any,
  handleTableChange: PropTypes.func,
  totalCount: PropTypes.number,
  limit: PropTypes.number,
  offset: PropTypes.number,
  isRowClick: PropTypes.bool,
  loading: PropTypes.bool,
  onRowClick: PropTypes.func,
  searchField: PropTypes.string,
  noDataMessage: PropTypes.string,
  withLocation: PropTypes.bool,
};
