/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

import FormSubTitle from "../../../../../FormSubTitle";
import { groupFields } from "../../../../../../helpers/customProperties";
import RenderCustomField from "../../../../../DraftEtsyFormInner/partials/RenderCustomField";
import ShowMoreFieldsButton from "../../../../../ShowMoreFieldsButton";

import styles from "./index.module.scss";

import ButtonText from "../../../../../ShowMoreFieldsButton/components/ButtonText";
import { useCallback } from "react";

const AdditionalFields = ({
  groupTitle,
  groupSubTitle,
  fields,
  isLoading,
  limit = Infinity,
  excludeProperties,
  suggestedFields,
  isShowMoreButton = false,
}) => {
  const [additionalFieldLimit, setAdditionalFieldsLimit] = useState(limit);

  const onToggleAdditionalFieldsView = useCallback(() => {
    const newValue = additionalFieldLimit === Infinity ? limit : Infinity;

    setAdditionalFieldsLimit(newValue);
  }, [additionalFieldLimit, limit]);

  return (
    <>
      {!!fields.length && (
        <FormSubTitle
          title={groupTitle}
          subTitle={groupSubTitle}
          fontWeight="500"
          loading={isLoading}
        />
      )}
      {groupFields(fields)
        .slice(0, additionalFieldLimit)
        .map(([first, second]) => {
          const props = first;
          const propSeconds = second && second;

          return (
            <Row
              className="mb-4 optional-fields"
              key={`${props.key}_${propSeconds?.key}`}
            >
              <Col md="6">
                <RenderCustomField
                  customComponents={excludeProperties}
                  shouldSetInitialOption={true}
                  suggestedFields={suggestedFields}
                  textFieldName={".scale_id"}
                  {...props}
                />
              </Col>
              <Col md="6">
                {!!second && (
                  <RenderCustomField
                    customComponents={excludeProperties}
                    suggestedFields={suggestedFields}
                    shouldSetInitialOption={true}
                    textFieldName={".scale_id"}
                    {...propSeconds}
                  />
                )}
              </Col>
            </Row>
          );
        })}
      {!!fields.length && isShowMoreButton && (
        <ShowMoreFieldsButton
          className={styles.showMoreAdditionsFieldsBtn}
          onClick={onToggleAdditionalFieldsView}
        >
          {additionalFieldLimit === limit ? (
            <ButtonText iconType="arrowDownIcon">
              Show additional options
            </ButtonText>
          ) : (
            <ButtonText
              iconClassName={styles.flippedIcon}
              iconType="arrowDownIcon"
            >
              Show less options
            </ButtonText>
          )}
        </ShowMoreFieldsButton>
      )}
    </>
  );
};

AdditionalFields.propTypes = {
  groupTitle: PropTypes.string,
  groupSubTitle: PropTypes.string,
  fields: PropTypes.array,
  isLoading: PropTypes.bool,
  limit: PropTypes.number,
  excludeProperties: PropTypes.any,
  isShowMoreButton: PropTypes.bool,
};

export default AdditionalFields;
