import axios from "axios";
import { createCacheAdapter } from "../../../services/adapters/cacheAdapter";
import { BASE_URL_FOR_BASIC_CATEGORIES } from "../const/links";
import Bugsnag from "@bugsnag/js";

const instance = axios.create({
  baseURL: BASE_URL_FOR_BASIC_CATEGORIES,
});

instance.interceptors.request.use((request) => request);

instance.interceptors.response.use(({ data }) => data);

function listToTree(list) {
  let map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i;
    list[i].children = [];
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    if (node.parentId !== null) {
      list[map[node.parentId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}
const categoriesMap = {
  isEmpty: true,
};
const categoriesForSearch = [];

function fillCategories(
  categories,
  parentId = null,
  prevPath = "",
  prevArr = []
) {
  const promises = categories.map((category) => {
    const { id, children } = category;

    category.parent = { id: parentId };
    categoriesMap[id] = category;
    categoriesForSearch.push(category);

    category.prevPath = prevPath;
    category.prevArr = prevArr;

    if (children) {
      return Promise.resolve()
        .then(() => {
          return fillCategories(
            children,
            id,
            [category.prevPath, category.name].filter(Boolean).join("/"),
            [
              ...category.prevArr,
              {
                id: category.id,
                name: category.name,
              },
            ]
          );
        })
        .catch((e) => Bugsnag.notify(e));
    }
  });

  return Promise.all(promises);
}
export const getCategoryById = (id) => {
  return categoriesMap[id];
};

const getCategoriesFromSearch = (categoriesForSearch = [], query = "") => {
  const MAX_SEARCH_LENGTH = 50;
  if (!categoriesForSearch?.length) return [];
  const queryLowerCase = query.split(" ").join("|");

  const regQueryLowerCase = new RegExp(queryLowerCase, "gi");

  const searchedCategories = [];

  for (let i = 0; i < categoriesForSearch.length; i++) {
    if (searchedCategories.length >= MAX_SEARCH_LENGTH) break;

    const categoryItem = categoriesForSearch[i];

    const { name } = categoryItem;
    const { parent } = categoryItem;

    if (regQueryLowerCase.test(name)) {
      categoryItem.parent = categoriesMap[parent?.id];
      searchedCategories.push(categoryItem);
    }
  }

  return searchedCategories;
};

export const getCategories = async () => {
  const cacheAdapter = await createCacheAdapter();

  try {
    const data = await instance.get(`/basic_categories.json`, {
      adapter: cacheAdapter.adapter,
    });

    return data;
  } catch (e) {
    Bugsnag.notify(e);
    return [];
  }
};

export const getCategoriesTree = (query, parentId) => {
  return getCategories()
    .then((data) => {
      return listToTree(data);
    })
    .then((data) => {
      if (categoriesMap.isEmpty) {
        return fillCategories(data)
          .then(() => data)
          .finally(() => {
            categoriesMap.isEmpty = false;
          });
      }

      return data;
    })
    .then((data) => {
      if (query) {
        return getCategoriesFromSearch(categoriesForSearch, query);
      }

      if (parentId && categoriesMap[parentId]) {
        return categoriesMap[parentId]?.children || [];
      }

      return data;
    })
    .catch((e) => Bugsnag.notify(e));
};
