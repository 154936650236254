import React from "react";
import { Card, CardBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import { TASK_MANAGEMENT_GROUP_LINKS } from "../../../taskManagement/config";
import { useGetTasks } from "../../hooks/useGetTasks";
import TaskPlaceholder from "../../../taskManagement/components/TaskPlaceholder";

const ListTasks = () => {
  const history = useHistory();
  const { data } = useGetTasks();

  return (
    <Card className={"h-100"}>
      <CardBody className="py-3">
        <h3 className="font-size-19 font-weight-semibold title-color">
          Tasks
        </h3>
        <ul
          className="task-list"
          onClick={() => history.push(TASK_MANAGEMENT_GROUP_LINKS.BASE)}
        >
          {!data?.length && <TaskPlaceholder />}
          {data.map((task) => {
            return (
              <li
                key={task.id}
                className="task-list__item"
              >
                <div className="list-heading">
                  {task.title}
                </div>
                <div className="list-text">
                  From: <span className="list-text_bold">{task.creator.firstName} {task.creator.lastName}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </CardBody>
    </Card>
  );
};

export default ListTasks;
