import { setupCache } from "axios-cache-adapter";
import memoryDriver from "localforage-memoryStorageDriver";
import localforage from "localforage";

let forageStore;

export const createCacheAdapter = async (clearCacheEntry = false) => {
  if (!forageStore) {
    await localforage.defineDriver(memoryDriver);

    // Create `localforage` instance
    forageStore = localforage.createInstance({
      // List of drivers used
      driver: [
        localforage.INDEXEDDB,
        localforage.LOCALSTORAGE,
        memoryDriver._driver,
      ],
      // Prefix all storage keys to prevent conflicts
      name: "cache-requests",
    });
  }

  return setupCache({
    maxAge: 7 * 24 * 60 * 60 * 1000, // 1 day
    exclude: {
      query: false,
    },
    invalidate: async (config) => {
      if (clearCacheEntry) {
        await config?.store?.removeItem(config?.uuid);
      }
    },
    store: forageStore,
    key: (req) => {
      return req.url + (JSON.stringify(req.params) || "");
    },
  });
};
