import React from "react";
import PropTypes from "prop-types";

import WhiteBox from "../WhiteBox";

import "./index.scss";
import ImportInventory from "../../../inventory/components/ImportInventory";

const EmptyList = ({
                     children = "No drafts yet. Use the “add draft” option to create one",
                     AddButton=null,
                     UpdateButton=null,
                     imgSrc,
                     link,
                     buttonContent,
                     addButtonClassName,
                     updateButtonClassName,
                     importButton=false,
                     inventoryStatusLabel
                   }) => {
  return (
    <WhiteBox>
      <img src={imgSrc}/>
      <p>{children}</p>
      <div className={"d-flex align-items-center"}>
        {importButton && <ImportInventory status={inventoryStatusLabel} />}
        {AddButton && <AddButton link={link} className={addButtonClassName} buttonContent={buttonContent}/>}
        {UpdateButton && <UpdateButton className={updateButtonClassName}/>}
      </div>
    </WhiteBox>
  );
};


EmptyList.propTypes = {
  Button: PropTypes.element,
  children: PropTypes.element,
};

export default EmptyList;
