import { cloneDeep } from "lodash";

import * as yup from "yup";
import {
	DRAFT_BRAND,
	DRAFT_COST,
	DRAFT_DESCRIPTION,
	DRAFT_DIMENSION,
	DRAFT_LB,
	DRAFT_NOTES,
	DRAFT_OZ,
	DRAFT_PRICE,
	DRAFT_SKU,
	DRAFT_TAGS,
	DRAFT_TITLE,
	DRAFT_ZIP
} from "../../../../../../validation/draft";
import {tagsValidation} from "../../../../helpers/tagsValidation";

const maxCountTags = 16;

export const validationSchema = yup.object().shape({
	title: DRAFT_TITLE,
	description: DRAFT_DESCRIPTION,
	brand: DRAFT_BRAND,
	zip: DRAFT_ZIP,
	sku: DRAFT_SKU,
	tags: tagsValidation(DRAFT_TAGS, maxCountTags),
	notes: DRAFT_NOTES,
	weightLb: DRAFT_LB,
	weightOz: DRAFT_OZ,
	height: DRAFT_DIMENSION,
	width: DRAFT_DIMENSION,
	depth: DRAFT_DIMENSION,
	listingPrice: DRAFT_PRICE.nullable().required(),
	itemCost: DRAFT_COST.nullable()
});

export const defaultSchemaValues = {
	files: [],
	title: '',
	description: '',
	brand: '',
	itemCondition: null,
	primaryColor: null,
	secondaryColor: null,
	categoryId: null,
	sku: '',
	zip: '',
	tags: '',
	notes: '',
	weightLb: 0,
	weightOz: 0,
	height: 0,
	width: 0,
	depth: 0,
	listingPrice: 0,
	itemCost: 0,
	productEbaySpecificFields: [],
	productEtsySpecificFields: [],
};

export const initialValues = cloneDeep(defaultSchemaValues);
