import React from "react";
import PropTypes from "prop-types";

import BrandOptions from "./components/BrandOptions";

import { DEFAULT_TITLE, ALTERNATIVE_BRAND_LIST_EMPTY } from "./constants";

import styles from "./index.module.scss";

const CustomBrandMessage = ({
  title = DEFAULT_TITLE,
  brandList,
  onSelectBrand,
}) => {
  return (
    <div className={styles.messageWrapper}>
      {brandList.length ? (
        <>
          <span>{title}</span>{" "}
          <BrandOptions brandList={brandList} onSelectBrand={onSelectBrand} />
        </>
      ) : (
        <span>{ALTERNATIVE_BRAND_LIST_EMPTY}</span>
      )}
    </div>
  );
};

CustomBrandMessage.propTypes = {
  title: PropTypes.string,
  brandList: PropTypes.array,
  onSelectBrand: PropTypes.func,
};

export default CustomBrandMessage;
