import React from "react";
import Draggable from "react-draggable";
import styled from "styled-components";
import styles from "./index.module.scss";
import PropTypes from "prop-types";
import { ReactComponent as Close } from "./assets/ic_close_grey.svg";
import { ReactComponent as Completed } from "./assets/ic_completed.svg";
import classNames from "classnames";
import { Spinner } from "../../../../base/components/GlobalLoader/GlobalLoader";

const ProgressContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ProgressText = styled.div`
  font-size: 12px;
  margin-left: 16px;
`;

const ProgressClose = styled.div`
  cursor: pointer;
  margin-left: 16px;
`;

const ProgressBar = ({
  isActive,
  processMessage,
  completedMessage,
  isOffset = false,
  onClose,
}) => {
  return (
    <>
      <Draggable bounds="#root">
        <div
          className={classNames(
            styles.progressBar,
            !isOffset
              ? styles.positionProgressbar
              : styles.offsetPositionProgressBar
          )}
        >
          <ProgressContent>
            <ProgressContent>
              <div>{isActive ? <Spinner /> : <Completed />}</div>
              <ProgressText>
                {isActive ? processMessage : completedMessage}
              </ProgressText>
            </ProgressContent>
            {!isActive && (
              <ProgressClose>
                <Close onClick={onClose} />
              </ProgressClose>
            )}
          </ProgressContent>
        </div>
      </Draggable>
    </>
  );
};

ProgressBar.propTypes = {
  isActive: PropTypes.bool,
  isOffset: PropTypes.bool,
  processMessage: PropTypes.string,
  completedMessage: PropTypes.string,
  onClose: PropTypes.func,
};

export default ProgressBar;
