import { ID_LABEL } from "../constants/constants";

export const cutFirstFourWords = (name) => {
  const titleWordsArray = name?.split(" ");
  titleWordsArray?.splice(4);
  return titleWordsArray?.join(" ");
};

export const isSelectedItem = (arr, id) => {
  if (Array.isArray(arr)) {
    return arr.find((item) => item.id === id);
  } else {
    return arr.id === id;
  }
};

export const hasSameItem = (arr, id) => {
  return arr.find((item) => item.id === id);
};

export const removeSelectedSuggestedItem = (selectedItems, item) => {
  return selectedItems.filter((i) => {
    return i.id !== item.id;
  });
};

export const checkIsNotAvailable = (item, selectedMarketplacesMap) => {
  let isNotAvailable = false;

  item?.marketplaces?.forEach((marketplace) => {
    if (selectedMarketplacesMap[marketplace?.marketplaceType]) {
      isNotAvailable = true;
    }
  });

  return isNotAvailable;
};

export const filterDuplicatedBy = (items, key) => {
  const mapFiltered = {};

  return items.filter((item) => {
    const id = item[key];

    const isFiltered = !mapFiltered[id];

    mapFiltered[id] = true;

    return isFiltered;
  });
};

export const getConcatedItems = (items, mergedItemsIds) => {
  const shouldConcat = !!mergedItemsIds?.length;
  const filteredItems = [];
  const newMergedArray = [];
  mergedItemsIds.forEach((mergedItem) => {
    return items.forEach((item) => {
      if (item.id === mergedItem) {
        newMergedArray.push(item);
      } else {
        filteredItems.push(item);
      }
    });
  });

  newMergedArray.reverse();
  const concatedArray = newMergedArray.concat(
    filterDuplicatedBy(filteredItems, ID_LABEL)
  );
  return shouldConcat ? filterDuplicatedBy(concatedArray, ID_LABEL) : items;
};

export const getCuttedData = (items, idx, res) => {
  return items.slice(0, idx);
};
