import moment from "moment";

import {
  MARKETPLACE_BASE,
  MARKETPLACES_LIST,
  UPDATE_SOLD_MAX_DAY,
  UPDATE_SOLD_DAY_2,
} from "../constants";
import { replaceURLParams } from "../../../base/helpers/replaceURLParams";
import { COUNT_DIGITS } from "../../analytics/constants";
import { MOMENT_DAY } from "../../bookkeeping/constants";
import { EMPTY_VALUE } from "../../bookkeeping/components/BookkeepingTables/constants";
import { PRODUCT_TYPE } from "../../../base/constants/product";

export const toNumber = (stringValue) => {
  if (!stringValue) return stringValue;

  return +stringValue;
};

export const toStringWithDecimalPart = (value) => {
  if (!value) return EMPTY_VALUE;

  return value.toFixed(COUNT_DIGITS);
};

export const getActiveInventoryHrefByCondition = (formData, callback) => {
  const activeMarket =
    MARKETPLACES_LIST.find(({ key }) => {
      return callback(formData[key]);
    }) || MARKETPLACE_BASE;

  return replaceURLParams(activeMarket.href, { draftId: formData.id });
};

export const getLastSyncedDateForMarketplace = (marketplaceData) => {
  const MOMENT_DAY_31 = moment()
    .subtract(UPDATE_SOLD_MAX_DAY, MOMENT_DAY)
    .utcOffset(0, true)
    .toISOString();

  if (marketplaceData?.lastSyncedAt) {
    return compareTwoDate(
      moment().subtract(UPDATE_SOLD_MAX_DAY, MOMENT_DAY),
      moment(marketplaceData?.lastSyncedAt)
    )
      ? MOMENT_DAY_31
      : moment(marketplaceData?.lastSyncedAt)
          .subtract(UPDATE_SOLD_DAY_2, MOMENT_DAY)
          .toISOString(); // TODO UPDATE_SOLD_HOUR_12
  }
  return MOMENT_DAY_31;
};

export const compareTwoDate = (firstDate, secondDate) =>
  moment(firstDate).isAfter(secondDate);

export const getMarketplacesForDelist = (
  marketplacesList,
  inventoryList,
  currentMarketplaceData
) =>
  marketplacesList.reduce((marketplacesForDelist, marketplace) => {
    if (
      inventoryList[marketplace.field].listingId &&
      inventoryList[marketplace.field]?.status === PRODUCT_TYPE.ACTIVE &&
      marketplace.marketplace !== currentMarketplaceData.marketplace
    ) {
      marketplacesForDelist.push({
        marketplaceType: marketplace.marketplace,
        listingId: inventoryList[marketplace.field].listingId,
        id: inventoryList[marketplace.field].id,
      });
    }

    return marketplacesForDelist;
  }, []);
