export default {
  // Container styles
  DEFAULT_COLOR: "#000000",
  SELECTED_COLOR: "#B2D4FF",
  FOCUSED_COLOR: "#eff6f2",

  // Options style
  OPTION_BORDER_COLOR: "rgba(73, 80, 87, 0.2)",
	OPTION_BORDER_HOVER_COLOR: "#2f665c",
  OPTION_BORDER_DISABLED_COLOR: "hsl(0,0%,80%)",
  OPTION_BACKGROUND_DEFAULT_COLOR: "transparent",
  OPTION_BACKGROUND_DISABLED_COLOR: "#eff2f7",
  OPTION_DEFAULT_COLOR: "rgba(73, 80, 87, 0.2)",
  OPTION_DISABLED_COLOR: "#495057",
  PLACEHOLDER_DEFAULT_COLOR: "#a1aab2",
  PLACEHOLDER_DISABLED_COLOR: "#495057",
};
