import Http from "./Http";

class ProfileService extends Http {
  static $displayName = "ProfileService";

  async changePassword(values) {
    return await this.put("/users/me/password", values);
  }

  async editProfile(values) {
    return await this.put("/users/me", values);
  }

  async createAvatar(imageId) {
    return imageId
      ? await this.put("/users/me/image", { imageId })
      : await this.put("/users/me/image");
  }

  async getProfileInfo() {
    return await this.get("/users/me");
  }

  async completeRegistration() {
    return await this.patch("/users/complete-registration");
  }

  async changePhoneNumber({ phoneNumber }) {
    return await this.put("/users/me/phone-number", { phoneNumber });
  }

  async verifyPhoneNumber({ code }) {
    return await this.post("/users/me/phone-number", { code });
  }

  async changeEmail({ email }) {
    return await this.put("/users/me/email", { email });
  }

  async verifyEmail({ token }) {
    return await this.post("/users/me/email", { token });
  }
}

export default ProfileService;
