import React from 'react';
import PropTypes from "prop-types";
import { useHistory } from 'react-router-dom';

import CustomButton from '../../../../../../../../base/components/CustomButton';
import InputGroupDisabled from '../../../../../../../../base/components/InputGroupDisabled';

import { PAYMENT_LINKS } from '../../config';

const PaymentViewForm = ({ lastFourDigits }) => {
  const { push } = useHistory();

  return (
    <>
      <div className='mb-5'>
        <InputGroupDisabled
          label='Card number'
          defaultValue={`**** **** **** ${lastFourDigits || '****'}`}
        />
        <InputGroupDisabled 
          label='Expiry date field'
          defaultValue='**/****'
        />
        <InputGroupDisabled 
          label='Secure code'
          defaultValue='***'
        />
      </div>
      <div className='d-flex justify-content-end'>
        <CustomButton
          className='filled-primary'
          onClick={() => push(PAYMENT_LINKS.EDIT)}
        >
          Change card
        </CustomButton>
      </div>
    </>      
  )
};

PaymentViewForm.propTypes = {
  lastFourDigits: PropTypes.string
}

export default PaymentViewForm;