import ImageService from "../../../../services/ImageService";

export const parseMercariImages = (images = []) => {
  let sumOfAllImages = 0;

  images.forEach((image) => {
    sumOfAllImages = sumOfAllImages + image.file.size;
  });

  sumOfAllImages = sumOfAllImages / 1024 / 1024; // to reference in MB
  let toCompress = false;
  const totalLimit = 18; // we've noticed that Mercari throws a weird error if the total size is greater than 18MB or so

  if (sumOfAllImages >= totalLimit) {
    toCompress = true;
  }

  const fileSizeLimit = totalLimit / images.length;

  const picturesBase64 = images.map((picture) => {
    return ImageService.fileToBase64(picture.file, toCompress, fileSizeLimit);
  });

  return picturesBase64;
};
