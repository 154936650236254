import axios from "axios";
import Bugsnag from "@bugsnag/js";

import { AUTH } from "../../../base/constants/headers";
import { KEY_EBAY_SESSION } from "../../../base/constants/storage";
import { initService } from "../../../base/hooks/useService";
import StorageService from "../../../services/StorageService";
import { CONDITIONS_MAP } from "../const/ebay";
import { createFullTextSearch } from "../../../base/helpers/categories";
import EbayService from "../../../services/EbayService";

const instance = axios.create({
  baseURL: "https://api.ebay.com",
  params: {
    marketplace_id: "EBAY_US",
  },
});

instance.interceptors.request.use((request) => {
  const storage = initService(StorageService);
  const data = storage.get(KEY_EBAY_SESSION, { accessToken: null });
  const { accessToken } = data;

  request.headers = {
    ...request.headers,
    [AUTH]: accessToken ? `Bearer ${accessToken}` : "",
  };

  return request;
});

instance.interceptors.response.use(({ data }) => data);

const categoriesMap = {
  isEmpty: true,
};

export const getCachedCategories = () => {
  return categoriesMap;
};

const categoriesForSearch = [];

function fillCategories(
  categories,
  parentId = null,
  prevPath = "",
  prevArr = []
) {
  const promises = categories.map((category) => {
    const {
      category: { categoryId: id },
      childCategoryTreeNodes: children,
    } = category;

    category.id = id;

    category.name = category?.category.categoryName;
    category.parent = { id: parentId };
    categoriesMap[id] = category;
    categoriesForSearch.push(category);

    category.prevPath = prevPath;
    category.prevArr = prevArr;

    if (children) {
      return Promise.resolve()
        .then(() => {
          return fillCategories(
            children,
            id,
            [category.prevPath, category.name].filter(Boolean).join("/"),
            [
              ...category.prevArr,
              {
                id: category.id,
                name: category.name,
              },
            ]
          );
        })
        .catch((e) => Bugsnag.notify(e));
    }
  });

  return Promise.all(promises);
}

const getCategoriesFromSearch = (categoriesForSearch = [], query = "") => {
  const searchIn = createFullTextSearch(query, true);

  const MAX_SEARCH_LENGTH = 50;

  return searchIn(
    categoriesForSearch,
    ({ name, prevPath }) => `${prevPath || ""} ${name}`
  )
    .map((categoryItem) => {
      const { parent } = categoryItem;
      categoryItem.parent = categoriesMap[parent?.id];
      return categoryItem;
    })
    .slice(0, MAX_SEARCH_LENGTH);
  //
  // if (!categoriesForSearch?.length) return [];
  // const queryLowerCase = query.split(" ").join('|')
  //
  // const regQueryLowerCase = new RegExp(queryLowerCase, 'gi')
  //
  // const searchedCategories = [];
  //
  // for (let i = 0; i < categoriesForSearch.length; i++) {
  //  if (searchedCategories.length >= MAX_SEARCH_LENGTH) break;
  //
  //   const categoryItem = categoriesForSearch[i];
  //
  //   const { categoryName} = categoryItem.category;
  //   const { parent } = categoryItem;
  //
  //   if (regQueryLowerCase.test(categoryName)) {
  //
  //     categoryItem.parent = categoriesMap[parent?.id];
  //     searchedCategories.push(categoryItem);
  //   }
  // }
  //
  // return searchedCategories;
};

export const getDefaultCategoryTreeId = async () => {
  return Promise.resolve({
    categoryTreeId: 0, // for USA
  });
};

export const getProperties = async (category_id) => {
  return getDefaultCategoryTreeId()
    .then(({ categoryTreeId }) => {
      const ebayService = new EbayService();

      return ebayService.getEbayCategoryItems(categoryTreeId, category_id);
    })
    .catch((e) => Bugsnag.notify(e));
};

export const getRootCategoriesOption = async (query, parentId) => {
  return getDefaultCategoryTreeId()
    .then(({ categoryTreeId }) => {
      const ebayService = new EbayService();

      return ebayService.getEbayCategories(categoryTreeId);
    })
    .then((data) => {
      const { data: categoryData } = data;

      if (categoriesMap.isEmpty) {
        return fillCategories(categoryData)
          .then(() => data)
          .finally(() => {
            categoriesMap.isEmpty = false;
          });
      }

      return data;
    })
    .then((data) => {
      if (query) {
        return getCategoriesFromSearch(categoriesForSearch, query);
      }

      if (parentId && categoriesMap[parentId]) {
        return categoriesMap[parentId]?.childCategoryTreeNodes || [];
      }

      return data.data;
    })
    .catch((e) => Bugsnag.notify(e));
};

export const getParentCategories = async (categoryId) => {
  return getRootCategoriesOption()
    .then(() => {
      const map = getCachedCategories();

      function findAllParentCategories(parentId, collection) {
        const category = map[parentId];

        if (!category) return collection;

        collection.unshift(category);

        return findAllParentCategories(category?.parent?.id, collection);
      }

      const parentId = map[categoryId]?.parent?.id;

      return {
        onInitBreadcrumbs: () => findAllParentCategories(parentId, []),
        initialValue: map[categoryId],
      };
    })
    .catch((e) => Bugsnag.notify(e));
};

export const getSubRootCategoriesOption = async (category_id) => {
  const ebayService = new EbayService();

  return ebayService
    .getEbayCategorySubTree(0, category_id)
    .then((data) => {
      return data.categorySubtreeNode.childCategoryTreeNodes;
    })
    .catch((e) => Bugsnag.notify(e));
};

export const getConditionItems = (categoryId) => {
  const ebayService = new EbayService();

  return ebayService
    .getEbayConditions(categoryId)
    .then(({ data: { itemConditionPolicies } }) => itemConditionPolicies[0])
    .then(({ itemConditionRequired, itemConditions }) => {
      const items = itemConditions.map(
        ({ conditionId, conditionDescription }) => ({
          id: CONDITIONS_MAP[conditionId] || conditionId,
          label: conditionDescription,
        })
      );

      return { items, required: itemConditionRequired };
    })
    .catch((e) => Bugsnag.notify(e));
};
