/* eslint-disable react/prop-types */
import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";

import CardImportItem from "../CardImportItem";

import { List, AutoSizer } from "react-virtualized";

const ImportItems = ({
  items,
  search,
  onChangeChecked,
  onStartImporting,
  checkIsChecked,
}) => {
  const rowRenderer = useCallback(
    ({
      key, // Unique key within array of rows
      index, // Index of row within collection
      style, // Style object to be applied to row (to position it)
    }) => {
      const item = items[index];

      return (
        <div key={key} style={style}>
          <CardImportItem
            index={index}
            key={index}
            search={search}
            onChangeChecked={onChangeChecked}
            onStartImporting={onStartImporting}
            valueForCSV={item}
            isChecked={checkIsChecked(item)}
            imageSrc={item.imageSrc}
            isImported={item.isImported}
            price={item.price}
            title={item.title}
            dateSold={item.dateSold}
            listingId={item.listingId.toString()}
            orderId={item.orderId}
            isBundle={item.isBundle}
            bundleSize={item.size}
          />
        </div>
      );
    },

    [items, onChangeChecked, onStartImporting, checkIsChecked]
  );

  return (
    <div className="card-import-container">
      {!items?.length && (
        <p className="bold-gray card-import-container__no-matches">
          <Spinner color="primary" />
        </p>
      )}
      <AutoSizer>
        {({ height, width }) => (
          <List
            width={width}
            height={height}
            rowCount={items.length}
            rowHeight={82}
            rowRenderer={rowRenderer}
            className={"custom-green-scroll"}
          />
        )}
      </AutoSizer>
    </div>
  );
};

ImportItems.propTypes = {
  items: PropTypes.array,
  search: PropTypes.string,
  onChangeChecked: PropTypes.func,
  onStartImporting: PropTypes.func,
};

export default ImportItems;
