import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import CustomButton from "../../../../../../base/components/CustomButton";
import CreationDate from "../../../../components/CreationDate";
import { useModal } from "../../../../../../base/hooks/useModal";
import SubscriptionChangeModal from "../SubscriptionChangeModal";
import { useService } from "../../../../../../base/hooks/useService";
import SubscriptionService from "../../../../../../services/SubscriptionService";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import CreditCard from "../../../../../../base/components/Subscription/components/CreditCard";
import {
  DOWNGRADE_SUBSCRIPTION_MESSAGE,
  UPGRADE_SUBSCRIPTION_MESSAGE,
  subscriptionPrices,
} from "../../../../constants/settings";
import { TYPES } from "../../../../../../base/components/Subscription/constant/subscription";

const SubscriptionFooter = ({
  purchasedDates,
  isDisabledChangeButton,
  subscriptionType,
  chosenSubscription,
  activeSubscription,
}) => {
  const [isCardModalOpen, setIsCardModalOpen] = useState(false);
  const { open, close, isOpen } = useModal();

  const subscriptionService = useService(SubscriptionService);

  const isUpgrade = chosenSubscription?.type > activeSubscription?.type;
  const isPostSubscription = activeSubscription?.type === TYPES.EXPIRED;

  const activeSubscriptionPrice = subscriptionPrices[activeSubscription?.type];
  const chosenSubscriptionPrice = subscriptionPrices[chosenSubscription?.type];

  let pricesDifference = chosenSubscriptionPrice - activeSubscriptionPrice;

  if (pricesDifference < 0) {
    pricesDifference = 0;
  }

  const [loading, { registerPromise }] = useLoading();

  const handleOpenSubscriptionChangeModal = () => {
    setIsCardModalOpen(false);
    open();
  };
  const handleOpenCreditCardModal = () => {
    close();
    setIsCardModalOpen(true);
  };

  const handleChangeSubscription = useCallback(async () => {
    await registerPromise(
      subscriptionService.changeSubscription({ subscriptionType })
    )
      .then(() => {
        setIsCardModalOpen(false);
        close();
        window.location.reload(true); // this resets the state throughout
      })
      .catch((e) => Bugsnag.notify(e));
  }, [subscriptionService, subscriptionType, close, registerPromise]);

  return (
    <Row>
      <Col className="d-flex">
        <CreationDate
          text="Purchase Date:"
          date={purchasedDates.purchaseDate}
          className="mr-60 mb-0"
        />
        <CreationDate
          text="Renewal Date:"
          date={purchasedDates.renewalDate}
          className="mr-60 mb-0"
        />
      </Col>

      <Col className="d-flex justify-content-end">
        <div>
          <CustomButton
            className="filled-primary w-180"
            onClick={open}
            disabled={isDisabledChangeButton}
          >
            Change Plan
          </CustomButton>
        </div>
      </Col>
      <SubscriptionChangeModal
        isOpen={isOpen}
        onClose={close}
        onClick={
          isUpgrade ? handleOpenCreditCardModal : handleChangeSubscription
        }
        onChangeSubscriptionPlan={handleChangeSubscription}
        isLoading={loading}
        text={
          isUpgrade
            ? UPGRADE_SUBSCRIPTION_MESSAGE
            : DOWNGRADE_SUBSCRIPTION_MESSAGE
        }
        activeSubscription={activeSubscription}
      />
      <CreditCard
        isOpen={isCardModalOpen}
        onChangeStep={handleOpenSubscriptionChangeModal}
        onChangeSubscriptionPlan={handleChangeSubscription}
        chosenSubscription={chosenSubscription}
        activeSubscription={activeSubscription}
        title={"Payment card info"}
        isChangingSubscription={true}
        isPostSubscription={isPostSubscription}
        pricesDifference={pricesDifference}
        onClose={() => setIsCardModalOpen(false)}
      />
    </Row>
  );
};

SubscriptionFooter.propTypes = {
  purchasedDates: PropTypes.object,
  activeSubscription: PropTypes.object,
  chosenSubscription: PropTypes.object,
  isDisabledChangeButton: PropTypes.bool,
  subscriptionType: PropTypes.number,
};

export default SubscriptionFooter;
