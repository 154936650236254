import React from "react";

const UpdateMarketplaceContainer = ({title, children}) => {
  return (
    <div className={"d-flex flex-column align-items-center mr-3"}>
      {children}
      <span className={"fw-bold"}>{title}</span>
    </div>
  );
};

export default UpdateMarketplaceContainer;