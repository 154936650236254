import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Col, CardBody, Card } from "reactstrap";
import { Formik } from "formik";
import Bugsnag from "@bugsnag/js";

import PageMeta from "../../../../base/components/MetaTags";
import CustomErrorMessage from "../../../../base/components/ErrorMessage";
import FormikPassword from "../../../../base/components/FormikPassword";
import CustomButton from "../../../../base/components/CustomButton";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../base/components/CustomModal";

import ProfileService from "../../../../services/ProfileService";
import { useService } from "../../../../base/hooks/useService";
import { initialValues, validationSchema } from "./form";
import { useLoading } from "../../../../base/hooks/useLoading";
import {
  useCleanCustomErrors,
  useCustomError,
} from "../../../../base/contexts/CustomErrorMessage";
import {
  CHANGED_EMAIL_KEY,
  INCORRECT_OLD_PASSWORD,
} from "../../../../base/constants/forms";
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
} from "../../../../validation/lengthConstants";
import { PROFILE_GROUP_LINKS } from "../../config";
import { Link } from "react-router-dom";
import { useCustomModal } from "../../../drafts/hooks/useCustomModal";
import FormikEmail from "../../../../base/components/FormikEmail";
import { KEY_USER } from "../../../../base/constants/storage";
import StorageService from "../../../../services/StorageService";
import AuthService from "../../../../services/AuthService";
import ToasterService from "../../../../services/ToastService";

const ChangePassword = () => {
  /**
   * @type {ProfileService}
   */
  const profileService = useService(ProfileService);
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  /**
   * @type {ToasterService}
   */
  const toaster = useService(ToasterService);

  const { push } = useHistory();
  const { setError } = useCustomError();
  useCleanCustomErrors();

  const [isDisabledSubmit, updateIsDisabledSubmit] = useState(false);
  const [isOpenModal, updateIsOpenModal] = useState(false);
  const [, { registerPromise }] = useLoading();

  const handleToggleModal = useCallback(() => {
    updateIsOpenModal((isOpen) => !isOpen);
  }, []);

  const changePassword = useCallback(
    ({ password, newPassword }, { resetForm }) => {
      registerPromise(profileService.changePassword({ password, newPassword }))
        .then(() => {
          handleToggleModal();
          resetForm({});
        })
        .catch((e) => {
          Bugsnag.notify(e);
          setError("password", INCORRECT_OLD_PASSWORD);
          updateIsDisabledSubmit(true);
        });
    },
    [handleToggleModal, registerPromise, profileService, setError]
  );

  const handleRestore = () => {
    authService
      .sendRestorePasswordEmail(
        storage.get(CHANGED_EMAIL_KEY) || storage.get(KEY_USER)?.email || ""
      )
      .then(() => {
        toaster.success("Restoration link was sent to your email");
      })
      .catch((e) => Bugsnag.notify(e));
  };

  const { modal: modalRestore, onShowModal: onShowModalRestore } =
    useCustomModal({
      title: "Forgot password",
      message: (
        <Formik
          initialValues={{
            email:
              storage.get(CHANGED_EMAIL_KEY) ||
              storage.get(KEY_USER)?.email ||
              "",
          }}
          validationSchema={validationSchema}
          onSubmit={() => {}}
        >
          {() => (
            <form className="form-horizontal p-2" onSubmit={() => {}}>
              <FormikEmail
                id="email"
                name="email"
                label="Email"
                placeholder="Enter your email"
                type="email"
                readOnly={true}
                disabled={true}
              />
            </form>
          )}
        </Formik>
      ),
      onAccept: handleRestore,
      acceptTitle: "Restore",
      isHideNoButton: true,
    });

  return (
    <div className="wrapper mt-3">
      <PageMeta title="Profile" />
      <Col lg={6} xl={5}>
        <Card className="card-auth overflow-hidden">
          {modalRestore}
          <CardBody>
            <h3 className="bold-black mb-4">Change Password</h3>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={changePassword}
            >
              {({ errors, handleSubmit, dirty }) => (
                <form className="form-horizontal p-2" onSubmit={handleSubmit}>
                  <FormikPassword
                    type="password"
                    id="password"
                    label="Old password"
                    name="password"
                    containerClassName="mb-3"
                    placeholder="Enter old password"
                    ErrorMessage={CustomErrorMessage}
                    onChange={() => {
                      setError("password", "");
                      if (isDisabledSubmit) {
                        updateIsDisabledSubmit(false);
                      }
                    }}
                  />
                  <FormikPassword
                    type="password"
                    id="newPassword"
                    label="New password"
                    name="newPassword"
                    containerClassName="mb-3"
                    placeholder="Enter new password"
                    min={MIN_PASSWORD_LENGTH}
                    max={MAX_PASSWORD_LENGTH}
                  />
                  <FormikPassword
                    type="password"
                    id="confirmNewPassword"
                    label="Confirm password"
                    name="confirmNewPassword"
                    containerClassName="mb-3"
                    placeholder="Enter confirm password"
                  />
                  <div className="d-flex justify-content-between align-items-center mt-4">
                    <div>
                      <Link to="#" onClick={onShowModalRestore}>
                        <span className="green-color font-size-11">
                          Forgot password
                        </span>
                      </Link>
                    </div>
                    <div className="d-flex justify-content-end">
                      <CustomButton
                        className="outline-primary w-130 mr-2"
                        onClick={() => push(PROFILE_GROUP_LINKS.BASE)}
                      >
                        Cancel
                      </CustomButton>
                      <CustomButton
                        type="submit"
                        className="filled-primary"
                        disabled={
                          !!Object.keys(errors).length ||
                          !dirty ||
                          isDisabledSubmit
                        }
                      >
                        Save
                      </CustomButton>
                    </div>
                  </div>
                </form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
      <CustomModal isOpen={isOpenModal} onClose={handleToggleModal}>
        <ModalHeader onClose={handleToggleModal}>Change password</ModalHeader>
        <ModalBody>
          <p className="ui-regular">Your password was successfully changed.</p>
        </ModalBody>
        <ModalFooter>
          <CustomButton className="filled-primary" onClick={handleToggleModal}>
            Ok
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    </div>
  );
};

export default ChangePassword;
