import Bugsnag from "@bugsnag/js";

export function getBlobUrl(blob) {
  return URL.createObjectURL(blob);
}

export function getImageFromBlob(blob) {
  const url = getBlobUrl(blob);
  const img = new Image();

  return new Promise((resolve, reject) => {
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = url;
  });
}

export function imageToCanvas(image) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);

  return Promise.resolve(canvas);
}

export function canvasToBlob(canvas) {
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      if (file) return resolve(file);

      reject("Error canvas to blob");
    }, "image/jpeg");
  });
}

export function convertJPGToJPEG(file) {
  return getImageFromBlob(file)
    .then(imageToCanvas)
    .then(canvasToBlob)
    .catch((e) => Bugsnag.notify(e));
}
