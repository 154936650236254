import React, { useEffect, useMemo }  from "react";
import { useHistory } from "react-router-dom";
import { NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import qs from "query-string";

import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useService } from "../../../../base/hooks/useService";
import StorageService from "../../../../services/StorageService";
import { KEY_USER } from "../../../../base/constants/storage";

const TopNavigation = ({ steps }) => {
  const { replace } = useHistory();
  const storage = useService(StorageService);
  const userStorage = storage.get(KEY_USER);

  const { params } = useLocationQuery();
  const { step } = params;

  useEffect(() => {
    const { registrationStatus } = userStorage;

    if (steps.some(({ id }) => id === registrationStatus)) {
      replace({
        search: qs.stringify({
          ...params,
          step: registrationStatus
        })
      })
      return;
    }

    replace('/app/dashboard');
  }, [replace, params]);

  return (
    <div className="vertical-wizard steps">
      <ul>
        {steps.map(({ id, label }) => {
          return (
            <NavItem key={id} className={classnames({ current: step === id })}>
              <NavLink
                className={classnames({ current: step === id })}
                disabled={true}
              >
                <span className="number">0{id}</span>
                <span className="step-label">{label}</span>
              </NavLink>
            </NavItem>
          );
        })}
      </ul>
    </div>
  );
}

TopNavigation.propTypes = {
  steps: PropTypes.array,
  isNavDisabled: PropTypes.bool,
};

export default TopNavigation;