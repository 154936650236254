export const filterDuplicatedBy = (items, key) => {
  const mapFiltered = {};

  return items.filter((item) => {
    const id = item[key];

    const isFiltered = !mapFiltered[id];

    mapFiltered[id] = true;

    return isFiltered;
  });
};