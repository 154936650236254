import React, { useCallback, useState } from "react";
import Bugsnag from "@bugsnag/js";

import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../base/components/CustomModal";
import CustomButton from "../../../base/components/CustomButton";

import { useService } from "../../../base/hooks/useService";
import SoldService from "../../../services/SoldService";

export const useCopySold = (afterCopy) => {
  const [soldToCopy, updateSoldToCopy] = useState(null);
  /**
   * @type {SoldService}
   */
  const soldService = useService(SoldService);

  const onCopy = useCallback((id) => {
    updateSoldToCopy(id);
  }, []);

  const cancel = useCallback(() => {
    updateSoldToCopy(null);
  }, []);

  const copySold = useCallback(() => {
    const id = soldToCopy;

    soldService
      .copySold(id, { isSetHidden: false })
      .then(({ data }) => {
        afterCopy(data?.id);
      })
      .catch((e) => Bugsnag.notify(e));

    cancel();
  }, [soldToCopy, cancel, soldService]);

  const view = (
    <CustomModal isOpen={!!soldToCopy} onClose={cancel}>
      <ModalHeader onClose={cancel}>Copy</ModalHeader>
      <ModalBody>
        <p className="ui-regular">
          Copying this sold listing will create a draft that can be listed to
          marketplaces. Sold Analytics and Bookkeeping will not be affected.
        </p>
      </ModalBody>
      <ModalFooter>
        <CustomButton onClick={cancel} className="outline-primary ">
          Cancel
        </CustomButton>
        <CustomButton onClick={copySold} className="filled-primary ">
          Copy
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );

  return [view, onCopy];
};
