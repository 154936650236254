import React from 'react'
import PropTypes from 'prop-types';
import { components } from 'react-select';

import Breadcrumbs from './Breadcrumbs';

const MenuList = ({ children, selectProps, ...rest }) => {
  const { breadcrumbs } = selectProps;
  return (
    <>
      {!!breadcrumbs?.length && <Breadcrumbs selectProps={selectProps} />}
      <components.MenuList {...rest}>
        {children}
      </components.MenuList>
    </>
  );
};

MenuList.propTypes = {
  children: PropTypes.any,
  selectProps: PropTypes.object
}

export default MenuList;