import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import DropdownText from "../../components/DropdownText";

import styles from "./index.module.scss";
import { FAQ_LIST } from "./constants";

const FaqPage = () => {
  return (
    <Row className="mt-3">
      <Col lg="12" xl="8">
        <Card>
          <CardBody>
            <div className={styles.wrapper}>
              {FAQ_LIST.map((faqItem, index) => (
                <DropdownText
                  title={faqItem.title}
                  description={faqItem.description}
                  key={index}
                />
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default FaqPage;
