import Http from "./Http";

class SettingsService extends Http {
  static $displayName = 'SettingsService';

  async getYouTubeLinks() {
    return await this.get('/settings/onboarding');
  } 
}

export default SettingsService;
