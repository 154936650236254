import React from "react";
import PropTypes from "prop-types";

import Button from "../../../base/components/Button";

const ConfirmButton = ({ onClick }) => {
  return (
    <Button className="filled-primary" onClick={onClick}>
      Ok
    </Button>
  );
};

ConfirmButton.propTypes = {
  onClick: PropTypes.func,
};

export default ConfirmButton;
