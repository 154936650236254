export const getCategoryData = (categories, categoryId) => {
    const categoryList = []
    for (const category of categories) {
        const foundCategories = category.categories.filter(subCategory => {
            return subCategory.category_features?.filter(additionalSubCategory => additionalSubCategory.id === categoryId).length
        })
        categoryList.push(foundCategories);
    }

   return categoryList?.filter(category => category.length);
}

export const getOtherCategoryData = (categories, categoryId) => {
    const categoryList = []
    for (const category of categories) {
        const foundCategories = category.categories.find((subCategory) => subCategory.id === categoryId)
        categoryList.push(foundCategories);
    }

    return categoryList?.filter(category => !!category);
}

export const getCategoryValue = (categoriesList, idCategory) => {
    return categoriesList?.find((category) => category.id === idCategory)?.display || ""
}