import { cloneDeep } from "lodash";
import {
  DRAFT_KEY_BRAND,
  DRAFT_KEY_TAGS,
  DRAFT_TAGS_KEY_NAME,
} from "../../../base/constants/forms";
import {
  END_CUT_INDEX_STRING,
  START_INDEX_STRING,
} from "../../../base/constants/shared";
import { defaultSchemaValues } from "../pages/EditDraft/pages/Basic/form";

const REQUIRED_KEY = ["productEbaySpecificFields", "productEtsySpecificFields"];

const allowZeroValuesForFields = {
  costOfItem: true,
  itemCost: true,
};

const getIsFilledValueFromType = (value, key) => {
  if (Array.isArray(value)) {
    return !!value.length;
  }

  if (value === false) return true;
  if (allowZeroValuesForFields[key] && value === 0) return true;

  return !!value;
};

const joinStringFromObjectKey = (listOfArray, objectKey) => {
  const stringValue = listOfArray.reduce((acc, next) => {
    return (acc += `${next[objectKey]}, `);
  }, "");
  return stringValue.substr(
    START_INDEX_STRING,
    stringValue.length - END_CUT_INDEX_STRING
  );
};

export const getFilteredForm = (values) => {
  const newValues = Object.entries(values).reduce((acc, [key, value]) => {
    const isFilledValue = getIsFilledValueFromType(value, key);
    if (isFilledValue) acc[key] = value;
    if (REQUIRED_KEY.some((requiredKey) => requiredKey === key) && acc[key]) {
      acc[key] = getFilteredForm(acc[key]);
    }
    return acc;
  }, {});

  if (newValues.tags && typeof newValues.tags === "string") {
    newValues.tags = newValues.tags
      .split(/,\s{0,}/)
      .filter((tag) => !!tag.length);
  }

  return newValues;
};

const transformByField = (key, value) => {
  const transformation = {
    [DRAFT_KEY_TAGS]: (value) =>
      joinStringFromObjectKey(value, DRAFT_TAGS_KEY_NAME),
    [DRAFT_KEY_BRAND]: (value) => value?.name || "",
  };
  return transformation[key] ? transformation[key]?.(value) : value;
};

export const getFilteredFormFromServer = (serverValues) => {
  return Object.assign(
    cloneDeep(defaultSchemaValues),
    Object.entries(serverValues).reduce((acc, [key, value]) => {
      acc[key] = transformByField(key, value);

      if (REQUIRED_KEY.some((requiredKey) => requiredKey === key) && acc[key]) {
        acc[key] = getFilteredFormFromServer(acc[key]);
      }

      return acc;
    }, {})
  );
};
