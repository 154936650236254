const MIN_SHIPPING_DAYS = 1;

export const filterDomesticServices = (data) => {
  const { data: shippingServices } = data;

  return shippingServices.filter((service) => !service.internationalService);
};

export const useFormatEbayDomesticShippingServices = () => {
  const getFilteredServices = (services) => {
    return services.filter((service) => service.label !== "-");
  };

  const filterInternationalServices = (data) => {
    const { data: shippingServices } = data;

    return shippingServices.filter((service) => service.internationalService);
  };

  const getFormattedServices = (services) => {
    return services.map((service) => {
      const {
        shippingTimeMin: from,
        shippingTimeMax: to,
        description,
        shippingServiceId,
        shippingService,
      } = service;

      const dateRange = from && to ? [from, to] : [MIN_SHIPPING_DAYS, Infinity];

      return {
        ...service,
        range: dateRange,
        label: description,
        id: shippingServiceId,
        value: shippingService,
      };
    });
  };

  return {
    getFilteredServices,
    getFormattedServices,
    filterDomesticServices,
    filterInternationalServices,
  };
};
