/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import classnames from "classnames";
import SelectWithCreate from "react-select/creatable";
import Select from "react-select";
import { useField } from "formik";
import { FormGroup } from "reactstrap";
import { customStyles } from "./Select/shared/utils/defaultSelectStyle";
import { useTranslate } from "../hooks/useTranslate";
import ErrorMessageDefault from "./ErrorMessageDefault";
import Arrow from "./Select/DropdownSelect/components/Arrow";
import { noop } from "lodash/util";
import { matchStrings } from "../helpers/matchStrings";
import { useTriggerFormChanges } from "../../pages/drafts/hooks/useDetectDirtyForm";
import DotsLoader from "./DotLoader";
import Clear from "./Select/DropdownSelect/components/Clear";

const FormikSingleSelect = ({
  name,
  options,
  placeholder = "Select",
  label,
  fieldForSet = "id",
  subText,
  customError = "",
  validate,
  initialOption = null,
  allowCreate,
  disabled = false,
  containerClassName = "mb-3",
  CustomOption,
  CustomInput,
  loading = false,
  CustomMenuList,
  sizeSets,
  isSizeClicked = false,
  onChange = noop,
  isHideLabel = false,
  onBlur,
  description,
  isClearBtn = true,
  onClear = noop,
  ...props
}) => {
  const [field, { error, touched }, helpers] = useField({ name, validate });
  const [created, updateCreated] = useState(null);
  const [translate] = useTranslate();
  const isError = error && touched;
  const { markChanges, containerRef } = useTriggerFormChanges();

  const allOptions = useMemo(() => {
    if (created) return [...options, created];
    return options;
  }, [options, created]);

  useEffect(() => {
    // Created custom field
    // Need add to options
    if (
      field.value &&
      !options?.find(({ id }) => id === field.value) &&
      !options
    ) {
      updateCreated({
        id: field.value,
        label: field.value,
      });
    }
  }, [options, field.value]);

  const getCurrentValue = useMemo(() => {
    return allOptions?.find(({ id }) => matchStrings(id, field.value)) || "";
  }, [allOptions, field.value]);

  const handleChange = useCallback(
    (option) => {
      markChanges();

      if (!option) {
        helpers.setValue(null);
        helpers.setTouched(true);
        onClear();
      } else {
        helpers.setTouched(true);
        helpers.setValue(option?.[fieldForSet]);

        onChange(option);
      }
    },
    [markChanges, helpers, onClear, fieldForSet, onChange]
  );

  const Component = allowCreate ? SelectWithCreate : Select;

  const components = useMemo(() => {
    const value = {
      isClearable: true,
      IndicatorSeparator: null,
      DropdownIndicator: Arrow,
      ClearIndicator: isClearBtn ? Clear : null,
    };

    if (CustomOption) {
      value.Option = CustomOption;
    }

    if (CustomInput) {
      value.Input = CustomInput;
    }

    if (CustomMenuList) {
      value.MenuList = CustomMenuList;
    }

    return value;
  }, [CustomOption, CustomInput, CustomMenuList, isClearBtn]);

  return (
    <FormGroup className={containerClassName}>
      {label && !isHideLabel && (
        <label
          htmlFor={props.name}
          className={classNames({ "text-danger": isError || !!customError })}
        >
          {label} {loading && <DotsLoader />}
        </label>
      )}
      <span ref={containerRef} />
      <Component
        className={classnames(
          {
            "is-invalid-input is-invalid react-select-wrap":
              isError || !!customError,
          },
          {
            "is-invalid-input react-select-wrap":
              isSizeClicked && !sizeSets.length,
          }
        )}
        options={allOptions}
        value={getCurrentValue || initialOption}
        getOptionValue={({ id }) => id}
        onChange={handleChange}
        placeholder={placeholder}
        createOptionPosition="first"
        styles={customStyles}
        isClearable={!disabled}
        onBlur={onBlur}
        components={components}
        onCreateOption={(id) => {
          const newOption = { id, label: id };
          updateCreated(newOption);
          handleChange(newOption);
        }}
        isValidNewOption={(value) => !!value}
        isDisabled={disabled}
      />
      {description && (
        <span className="font-size-10 text-gray-gomi ">{description}</span>
      )}
      {subText && <span className="regular-caption-gray">{subText}</span>}
      <ErrorMessageDefault>
        {translate(customError || error, { label })}
      </ErrorMessageDefault>
    </FormGroup>
  );
};

FormikSingleSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
  subText: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  initialOption: PropTypes.any,
  isHideLabel: PropTypes.bool,
  isClearBtn: PropTypes.bool,
};

export default FormikSingleSelect;
