import { useRef, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../../base/hooks/useService";
import StorageService from "../../../../services/StorageService";
import qs from "query-string";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { KEY_USER } from "../../../../base/constants/storage";

const noOperation = () => {};
const noChanges = (values) => values;

export const FORM_STORAGE_KEY = "FORM_STORAGE_KEY";

export const useWizardForms = ({
  onFinish = noOperation,
  onSubmit = noOperation,
  onChange = noOperation,
  steps = [],
  handleStepChange = noOperation,
  stepSubmitTransformer = noChanges,
} = {}) => {
  /**
     @type {StorageService}
     */
  const storage = useService(StorageService);
  const savedValues = storage.get(FORM_STORAGE_KEY, []);
  const userStorage = storage.get(KEY_USER);

  const forms = useRef({});

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [values, setValues] = useState(savedValues);

  const { params } = useLocationQuery();
  const history = useHistory();

  const currentStepId = params.step || 1;

  const isLast = (currentStepId) =>
    steps[steps.length - 1]?.id === currentStepId;

  const onFormChanges = (form) => {
    if (forms.current[currentStepId]?.values !== form.values) {
      forms.current[currentStepId] = form;
    }
    handleStepChange(currentStepId);
  };

  const getInitialValues = (id) => {
    return values[id];
  };

  const onClickPrev = () => {
    if (values[currentStepId]) {
      const newValues = [...values];
      delete newValues[currentStepId];
      setValues(newValues);
    }

    const currentIndex = steps.findIndex((step) => step.id === currentStepId);

    if (currentIndex > 0) {
      history.replace({
        search: qs.stringify({
          ...params,
          step: currentStepId - 1,
        }),
      });
    }
  };

  const goToNext = () => {
    storage.set(KEY_USER, {
      ...userStorage,
      registrationStatus: userStorage.registrationStatus + 1,
    });

    if (isLast(currentStepId)) {
      onFinish();
      return;
    }

    if (
      steps.length - 1 >
      steps.findIndex((step) => step.id === currentStepId)
    ) {
      history.replace({
        search: qs.stringify({
          ...params,
          step: currentStepId + 1,
        }),
      });
    }
  };

  const onClickNext = () => {
    let form;

    if (isSubmitting) return;
    setIsSubmitting(true);

    form = forms.current[currentStepId];

    if (!form) {
      setIsSubmitting(false);
      setValues([...values]);
      return goToNext();
    }

    form
      .submitForm()
      .then(() => {
        if (
          !form.dirty &&
          (!form.dirty || form.isValid) &&
          !values[currentStepId]
        )
          return;
        setValues((values) => {
          values[currentStepId] = stepSubmitTransformer({
            formModel: form.values,
            isValid: true,
          });
          return [...values];
        });

        goToNext();
      })
      .catch((e) => Bugsnag.notify(e))
      .finally(() => setIsSubmitting(false));
  };

  const getCurrentValues = () => values;

  const handleSubmit = useCallback(async () => {
    const currentForm = forms.current[currentStepId];

    if (isLast(currentStepId)) {
      try {
        onSubmit(values);
        // storage.remove(FORM_STORAGE_KEY);
      } catch (e) {
        Bugsnag.notify(e);
      } finally {
        currentForm && currentForm.setSubmitting(false);
      }
    } else {
      onChange(values);
      // storage.set(FORM_STORAGE_KEY, values);
    }
  }, [values, currentStepId]);

  useEffect(() => {
    handleSubmit();
  }, [handleSubmit]);

  return {
    isSubmitting,
    onFormChanges,
    onClickPrev,
    onClickNext,
    getInitialValues,
    getCurrentValues,
  };
};
