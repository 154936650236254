import {
  MARKETPLACE_EBAY_TYPE,
  MARKETPLACE_ETSY_TYPE,
} from "../constants/importInventory";
import {ACTIVE, ENDED} from "../../../base/constants/importEbay";

export const getFilteredProducts = (products = [], marketplaceType) => {
  const importKeys = {
    [MARKETPLACE_EBAY_TYPE]: "sku",
    [MARKETPLACE_ETSY_TYPE]: "listingId",
  };

  return products.reduce((acc, product) => {
    const {
      title = "-",
      image = "",
      imageUrls = [],
      isImported = false,
      orderStatus,
      price = 0,
        sku,
        orderId,
        listingId,
      paidAt
    } = product;


    acc.push({
      title,
      isImported,
      sku: product[importKeys[marketplaceType]]?.toString() || sku,
      price: price?.value || price,
      imageSrc: imageUrls[0] || image,
      listingStatus: orderId ? ENDED : ACTIVE,
      dateSold: paidAt,
      orderId,
      orderStatus,
      listingId
    });


    return acc;
  }, []);
};
