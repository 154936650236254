import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Checkbox = forwardRef(
  (
    {
      text,
      value,
      onChange,
      id,
      className,
      disabled,
      type = "checkbox",
      ...other
    },
    ref
  ) => {
    return (
      <div className={classNames("form-check", className)}>
        <input
          ref={ref}
          type={type}
          className="form-check-input"
          id={id}
          onChange={onChange}
          checked={value}
          disabled={disabled}
          {...other}
        />
        <label className="form-check-label" htmlFor={id}>
          {text}
        </label>
      </div>
    );
  }
);

Checkbox.propTypes = {
  text: PropTypes.any,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.any,
  className: PropTypes.string,
  type: PropTypes.string,
};

export default Checkbox;
