import React from "react";
import PropTypes from "prop-types";

import TopNavigation from "./TopNavigation";
import FormContent from "./FormContent";

import { useWizardForms } from "./useFormWizard";
import { useHistory } from "react-router-dom";

export default function FormWizard({ steps }) {
  const { replace } = useHistory();

  const {
    onFormChanges,
    onClickNext,
    onClickPrev,
    getInitialValues,
    getCurrentValues,
  } = useWizardForms({
    steps,
    onSubmit: async () => {},
    onFinish: () => {
      replace({
        pathname: "/app/dashboard",
        state: { fromComplitedProfile: true },
      });
    },
  });

  return (
    <div className="wizard clearfix">
      <div className="steps clearfix">
        <FormContent
          steps={steps}
          onFormChanges={onFormChanges}
          getInitialValues={getInitialValues}
          getCurrentValues={getCurrentValues}
          TopNavigation={TopNavigation}
          onClickPrev={onClickPrev}
          onClickNext={onClickNext}
        />
      </div>
    </div>
  );
}

FormWizard.propTypes = {
  steps: PropTypes.array,
  label: PropTypes.string,
};
