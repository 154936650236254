import { useCallback } from "react";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../base/hooks/useService";
import S3Service from "../../../services/S3Service";
import { fileTypes } from "../../../base/constants/fileTyles";

const useUploadImagesToS3 = () => {
  /**
   * @type {S3Service}
   */
  const s3 = useService(S3Service);

  return useCallback(
    async (files, savedFiles = []) => {
      return Promise.all(
        files.map((blob, index) => {
          // Optimization - no need to upload same files
          // Reduce saving time
          if (savedFiles[index]?.id)
            return Promise.resolve(savedFiles[index]?.id);

          // If not have ID - need upload file to S3
          return s3
            .uploadImageFiles([blob], fileTypes.productImage)
            .then((files) => files[0].file.id)
            .catch((e) => Bugsnag.notify(e));
        })
      );
    },
    [s3]
  );
};

export default useUploadImagesToS3;
