import React from "react";
import { Col, Row } from "reactstrap";

import FormikInput from "../../../../../base/components/FormikInput";
import FormikTextarea from "../../../../../base/components/FormikTextarea";

import {
  MAX_DESCRIPTION_LENGTH_MERCARI,
  MAX_TITLE_LENGTH_MERCARI,
} from "../../../const/mercari";
import {
  MAX_DESCRIPTION_LENGTH,
  MAX_TITLE_LENGTH,
  MIN_TITLE_LENGTH,
} from "../../../../../validation/lengthConstants";

export const FormTitleAndDesc = ({
  titleLabel = "Title*",
  descLabel = "Description*",
}) => {
  return (
    <>
      <Row className="mb-4">
        <Col md="6">
          <FormikInput
            id="title"
            name="title"
            label={titleLabel}
            containerClassName="mb-3"
            placeholder="Enter item title"
            type="text"
            maxLength={MAX_TITLE_LENGTH}
            min={MIN_TITLE_LENGTH}
            maxLengthForCounter={MAX_TITLE_LENGTH_MERCARI}
            isShowLengthDescription
          />
        </Col>
      </Row>

      <FormikTextarea
        id="desc"
        name="description"
        label={descLabel}
        isShowLengthDescription
        placeholder="Enter item description"
        rows="3"
        maxLength={MAX_DESCRIPTION_LENGTH}
        maxLengthDescription={MAX_DESCRIPTION_LENGTH_MERCARI}
      />
    </>
  );
};
