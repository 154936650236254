import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { DropdownItem } from "reactstrap";

import {
  stringifyParams,
  useLocationQuery,
} from "../../../../base/hooks/useQueryString";

import "./index.scss";

const ExpenseCategoryItem = ({ name, id, updateCurrentFilter }) => {
  const { params } = useLocationQuery();
  const history = useHistory();

  const onItemClick = useCallback(() => {
    updateCurrentFilter({
      id: id,
      name: name,
    });

    const newParams = {
      ...params,
      categoryId: id,
      offset: 0,
    };

    history.push({ search: stringifyParams(newParams) });
  }, [params, updateCurrentFilter, stringifyParams]);

  return (
    <React.Fragment>
      <DropdownItem
        className="expense-category-item-wrapper"
        onClick={() => onItemClick()}
      >
        <div className="text-truncate">{name}</div>
      </DropdownItem>
    </React.Fragment>
  );
};

export default ExpenseCategoryItem;
