import React, {useEffect} from 'react'
import InfiniteScrollWrapper from '../../../../base/components/InfiniteScrollWrapper';

import ReturnCard from '../../components/ReturnCard';

import {IN_PROGRESS_TAB, RETURN_STATUSES} from '../../constants/returns-constants';
import { useGetReturnsList } from '../../hooks/useGetReturnsList';
import {noop} from "lodash/util";
import Placeholder from "../../../inventory/components/Placeholder";
import emptyReturnsIcon from "../../images/returns-empty.png"
import CreateButton from "../../components/CreateReturnButton";
import WhiteBox from "../../../drafts/components/WhiteBox";

const ReturnsProgressList = ({getCount = noop, onMountList = noop, updateTabs, onCreateButtonClick}) => {
  const { data, hasMore, handleFetch, refresh, totalCount, isLoading } = useGetReturnsList(RETURN_STATUSES.IN_PROGRESS);

  useEffect(() => {
    onMountList({ refresh })
  }, [onMountList, refresh])

  useEffect(() => {
    getCount({[IN_PROGRESS_TAB]: totalCount});
  }, [totalCount])

  return (
      <InfiniteScrollWrapper hasMore={hasMore} fetchData={handleFetch} hasInfiniteLoader={!!data?.length}>
        {isLoading ? (
          <WhiteBox>
            <p>Loading...</p>
          </WhiteBox>
        ) : !data?.length && <Placeholder
          text={`To create a new return, click "Create return". Or you can mark a sold item as returned on the Sold page.`}
          imgSrc={emptyReturnsIcon}
          onCreateButtonClick={onCreateButtonClick}
          CreateButton={CreateButton}
        />}
        {data.map((returnItem) => (
            <ReturnCard key={returnItem.id} onRefresh={refresh} {...returnItem}  updateTabs={updateTabs}/>
        ))}
      </InfiniteScrollWrapper>
  )
};

export default ReturnsProgressList;