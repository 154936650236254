import { useCallback, useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../base/hooks/useService";
import TaskManagement from "../../../services/TaskManagement";
import { useLoading } from "../../../base/hooks/useLoading";

const INITIAL_LIMIT = 5;

export const useGetTasks = () => {
  /**
   * @type {TaskManagement}
   */
  const [_, { registerPromise }] = useLoading(true);
  const TaskManagementService = useService(TaskManagement);
  const [data, updateData] = useState([]);

  const fetchData = useCallback(() => {
    registerPromise(TaskManagementService.getTasks({ limit: INITIAL_LIMIT }))
      .then(({ data }) => updateData(data))
      .catch((e) => Bugsnag.notify(e));
  }, [TaskManagementService, registerPromise]);

  useEffect(() => fetchData(), [fetchData]);

  return { data };
};
