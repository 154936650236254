const getTransactionFees = (value, interestRate, fixedRate, shippingCost) => {
  const result = (+value + shippingCost) * interestRate + fixedRate;
  return result.toFixed(2);
};

export const calcTransactionFeesByFormulaForMercari = (
  value,
  shippingCost = 0
) => {
  if (!value) return 0;

  const fixedRate = 0.5;
  const interestRate = 0.029;

  return getTransactionFees(value, interestRate, fixedRate, shippingCost);
};
