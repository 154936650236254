import React, { useEffect, useRef } from 'react'
import { Card } from 'reactstrap';
import PropTypes from "prop-types";

import { ReactComponent as ExpiredLogo } from '../../../assets/images/ic-experied-link.svg';
import Button from '../../../base/components/Button';

const ExpiredCard = ({ handleResendVerification, isDisabledButton }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    buttonRef.current.focus();
  }, []);

  return (
    <Card className='d-flex align-items-center custom-expired-card'>
      <ExpiredLogo />
      <h3 className='mt-4'>Sorry!</h3>
      <p className='text-center'>
        The verification link has expired. To request a new link, click &ldquo;Resend&ldquo;.
      </p>
      <Button
				innerRef={buttonRef}
				disabled={isDisabledButton}
				className='filled-primary'
				onClick={handleResendVerification}
				onKeyPress={handleResendVerification}
      >
        Resend
      </Button>
    </Card>
  )
};

ExpiredCard.propTypes = {
  handleResendVerification: PropTypes.func,
  isDisabledButton: PropTypes.bool
};

export default ExpiredCard;
