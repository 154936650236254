export const MAX_TITLE_LENGTH = 80;
export const MAX_TITLE_LENGTH_MERCARI = 80;
export const MAX_DESCRIPTION_LENGTH_MERCARI = 1000;

export const MERCARI_CONDITIONS = {
  newWithTagsBox: 1,
  newWithoutTagsBox: 2,
  newWithDefects: 3,
  preOwned: 4,
  poor: 5,
};
