import React from "react";
import { Col, Row } from "reactstrap";
import FormikInput from "../../../../../../base/components/FormikInput";
import { MAX_LENGTH_SKU, STEP_BY_HUNDREDTH } from "../../../../../../base/constants/forms";
import FormikGroupAddon from "../../../GroupAddon";
import FormikTextarea from "../../../../../../base/components/FormikTextarea";
import {
  COUNT_OF_ROWS_STR,
  MAX_DESCRIPTION_POSHMARK_LENGTH,
} from "../../../../const/poshmark";
import { formatInputPrice } from "../../../../helpers/formatInputPrice";
import { usePriceValidation } from "../../../../../../base/hooks/usePriceValidation";

const FormPoshmarkAdditionalDetails = ({ setFieldValue }) => {
  const [onChangeWithValidation, onKeyPress] = usePriceValidation({});

  return (
    <Row className="section-wrapper">
      <Col md={4}>
        <h3 className="bold-black mb-1">Additional details (Private)</h3>
      </Col>
      <Col md={8}>
        <Row className="mb-3">
          <Col md={6}>
            <FormikInput
              id="sku"
              name="sku"
              label="Listing SKU"
              placeholder="Enter SKU number"
              type="text"
              maxLength={MAX_LENGTH_SKU}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <FormikGroupAddon
              label="Cost of item"
              name="costPrice"
              groupText="$"
              placeholder="00.00"
              step={STEP_BY_HUNDREDTH}
              description="For internal use only"
              inputClassName="priceInputWrapper"
              onChange={(event, setValue) =>
                onChangeWithValidation(event, setValue)
              }
              onKeyPress={onKeyPress}
              isFieldOnChange={false}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <FormikTextarea
            id="other_info"
            name="otherInfo"
            label="Other info"
            placeholder="Write something"
            type="text"
            maxLength={MAX_DESCRIPTION_POSHMARK_LENGTH}
            isShowLengthDescription
            description="For internal use only"
            rows={COUNT_OF_ROWS_STR}
            className={"mb-0"}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default FormPoshmarkAdditionalDetails;
