import React from "react";
import { Row, Col } from "reactstrap";


const PerformanceItem = ({label, value}) => {
  return(
    <Row className="align-items-center mb-3">
      <Col>
        <p className="font-size-16 mb-0">
          {label}
        </p>
      </Col>
      <Col className="d-flex justify-content-end">
        <p className="font-size-19 fw-bold title-text-color mb-0">
          { value ? `${value}` : "-"}
        </p>
      </Col>
    </Row>
  )
}

export default PerformanceItem;