import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Register from "./pages/Register/index";
import ForgetPassword from "./pages/ForgetPassword";
import BlockedAccount from "./pages/BlockedAccount";
import VerifyEmail from "./pages/VerifyEmail";

import { AUTH_GROUP_LINKS } from "./config";

export default function AuthRoutes() {
  return (
    <Switch>
      <Route path={AUTH_GROUP_LINKS.LINK_LOGIN} component={Login} exact />

      <Route path={AUTH_GROUP_LINKS.LINK_REGISTER} component={Register} exact />

      <Route
        path={AUTH_GROUP_LINKS.LINK_FORGOT_PASSWORD}
        component={ForgetPassword}
        exact
      />

      <Route
        path={AUTH_GROUP_LINKS.LINK_BLOCKED_ACCOUNT}
        component={BlockedAccount}
        exact
      />

      <Route path={AUTH_GROUP_LINKS.LINK_LOG_OUT} component={Logout} exact />

      <Route
        path={AUTH_GROUP_LINKS.VERIFY_EMAIL}
        component={VerifyEmail}
        exact
      />

      <Redirect to={AUTH_GROUP_LINKS.LINK_REGISTER} />
    </Switch>
  );
}
