import ExtensionService from "./Extension";
import { EXTENSION_EVENTS, MARKETPLACES } from "../base/constants/extension";

class MercariExtension extends ExtensionService {
  static $displayName = "MercariExtension";
  authToken = null;

  constructor() {
    super(MARKETPLACES.MERKARI);

    if (localStorage.getItem("mercariAuthToken")) {
      this.authToken = localStorage.getItem("mercariAuthToken");
    }
  }

  /**
   *
   * @param callback {(function(any): any )}
   * @returns {{toPromise: (function(): Promise<any>), unSubscribe: unSubscribe}}
   * @todo - call unSubscribe after checking completed auth
   * @desc - provide callback and listen products chunks
   */
  onCompletedAuth(callback) {
    return this.subscribe(EXTENSION_EVENTS.COMPLETED_AUTH, ({ err }) => {
      if (!err) callback();
    });
  }

  onCompletedLogout(callback) {
    return this.subscribe(EXTENSION_EVENTS.COMPLETED_LOG_OUT, ({ err }) => {
      if (!err) callback();
    });
  }

  logOut() {
    return this.request(EXTENSION_EVENTS.LOG_OUT);
  }

  /**
   *
   * @returns {Promise<any>}
   */
  isConnected() {
    return this.request(EXTENSION_EVENTS.IS_CONNECTED);
  }

  /**
   *
   * @returns {Promise<any>}
   */
  getCategories() {
    return this.request(EXTENSION_EVENTS.GET_CATEGORIES, {
      token: this.authToken,
    });
  }

  /**
   *
   * @param price {number}
   * @param floorPrice {number}
   * @param shippingPayerId {number}
   * @param zipCode {number|string}
   * @returns {Promise<any>}
   */
  calcFees(price, shippingPayerId, zipCode, floorPrice, shippingId) {
    return this.request(EXTENSION_EVENTS.CALC_FEES, {
      params: { price, shippingPayerId, zipCode, floorPrice, shippingId },
      token: this.authToken,
    });
  }

  getShippingParams({
    categoryId,
    name,
    brandId,
    description,
    shippingPayerId,
  }) {
    return this.request(EXTENSION_EVENTS.SHIPPING_PARAMS, {
      params: { categoryId, name, brandId, description, shippingPayerId },
      token: this.authToken,
    });
  }

  /**
   *
   * @param model {object}
   * @param images {array<string>}
   * @param id {string}
   * @returns {Promise<any>}
   */
  editInventory(images, model, id) {
    return this.request(EXTENSION_EVENTS.EDIT_INVENTORY, {
      images,
      model,
      id,
      token: this.authToken,
    });
  }

  /**
   *
   * @param model {object}
   * @param images {array<string>}
   * @returns {Promise<any>}
   */
  postDraft(images, model) {
    return this.request(EXTENSION_EVENTS.POST_DRAFT, {
      images,
      model,
      token: this.authToken,
    });
  }

  /**
   *
   * @param callback
   * @returns {*}
   */
  importProducts(callback) {
    return this.subscribe(EXTENSION_EVENTS.IMPORT_PRODUCTS, callback, {
      token: this.authToken,
    });
  }

  /**
   *
   * @param callback {(function(any): any )}
   * @returns {{toPromise: (function(): Promise<any>), unSubscribe: unSubscribe}}
   */
  importSoldProducts(callback) {
    return this.subscribe(EXTENSION_EVENTS.GET_SOLD_ITEMS, callback, {
      token: this.authToken,
    });
  }

  /**
   *
   * @param collection {array<string>}
   * @returns {*}
   */
  importProductDetails(collection) {
    return this.request(EXTENSION_EVENTS.IMPORT_DETAIL_PRODUCTS, {
      params: { collection },
      token: this.authToken,
    });
  }

  /**
   *
   * @param draftId {string|number}
   * @returns {Promise<any>}
   */
  deleteInventory(draftId) {
    return this.request(EXTENSION_EVENTS.DELETE_DRAFT, {
      draftId,
      token: this.authToken,
    });
  }

  /**
   *
   * @param listingId {string|number}
   * @returns {Promise<any>}
   */
  deleteActiveInventory(listingId) {
    //Complete removal of items from the marketplace mercari
    return this.request(EXTENSION_EVENTS.DELETE_INVENTORY, {
      listingId,
      token: this.authToken,
    });
  }

  /**
   *
   * @returns {*}
   */
  getProfile() {
    return this.request(EXTENSION_EVENTS.GET_PROFILE, {
      token: this.authToken,
    });
  }

  /**
   * Gets all options for dress occasions
   *
   * @returns {*}
   */
  getDressOccasionOptions() {
    return this.request(EXTENSION_EVENTS.GET_DRESS_OCCASIONS, {
      token: this.authToken,
    });
  }

  /**
   * Gets all options for dress styles based on selected dress occasion
   *
   * @param {string} dressOccasionId The selected dress occasion
   * @returns {*}
   */
  getDressStyleOptions(dressOccasionId) {
    return this.request(EXTENSION_EVENTS.GET_DRESS_STYLES, {
      dressOccasionId,
      token: this.authToken,
    });
  }

  /**
   * Gets shipping classes from Mercari
   *
   * @param {string} categoryId (optional)
   * @returns
   */
  getShippingClasses(categoryId = null, packageWeight = null) {
    return this.request(EXTENSION_EVENTS.SHIPPING_CLASSES, {
      categoryId,
      packageWeight,
      token: this.authToken,
    });
  }
}

export default MercariExtension;
