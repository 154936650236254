export const MAX_PRICE = 99999999.99;

export const ONLY_NUMBERS_AND_EMPTY = /^(\s*|(\d+[,.]?(\d{0,2})))$/;

export const PAYMENTS_METHODS = {
  etsy: 1,
  payPal: 2,
};

export const PAYMENT_METHOD_OPTIONS = [
  { id: 1, label: "Etsy" },
  { id: 2, label: "PayPal" },
];

export const PAYMENT_METHODS_LABELS = {
  [PAYMENTS_METHODS.etsy]: "Etsy",
  [PAYMENTS_METHODS.payPal]: "PayPal",
};

export const MARKETPLACE_TYPES = {
  ebay: 1,
  etsy: 2,
  poshmark: 3,
  mercari: 4,
  other: 5,
};

export const MARKETPLACES_OPTIONS = [
  { label: "Poshmark", id: MARKETPLACE_TYPES.poshmark },
  { label: "eBay", id: MARKETPLACE_TYPES.ebay },
  { label: "Etsy", id: MARKETPLACE_TYPES.etsy },
  { label: "Mercari", id: MARKETPLACE_TYPES.mercari },
  { label: "Other", id: MARKETPLACE_TYPES.other },
];

export const SHIPPING_CHARGED_TO_BUYER_TOOLTIP_TEXT =
  "Shipping charged to buyer that you received as revenue e.g. eBay flat shipping.";

export const ADDITIONAL_SELLING_FEES_TOOLTIP_TEXT =
  "Fees associated with the sale that are not standard marketplace fees e.g. eBay Promoted Listing fees, etc.";
