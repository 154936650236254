export const DEFAULT_LIMIT = 100;
export const DEFAULT_OFFSET = 0;

export const INITIAL_IMPORT_LIMIT = 20;

export const MARKETPLACE_EBAY_TYPE = 1;
export const MARKETPLACE_ETSY_TYPE = 2;
export const MARKETPLACE_POSHMARK_TYPE = 3;
export const MARKETPLACE_MERCARI_TYPE = 4;
export const MARKETPLACE_OTHER_TYPE = 5;

export const MARKETPLACE_TYPE = {

  [MARKETPLACE_EBAY_TYPE]: 'Ebay',
  [MARKETPLACE_ETSY_TYPE]: 'Etsy',
  [MARKETPLACE_POSHMARK_TYPE]: 'Poshmark',
  [MARKETPLACE_MERCARI_TYPE]: 'Mercari',
  [MARKETPLACE_OTHER_TYPE]: 'Other',
};

export const ACTIVE_INVENTORY_LIMIT = "ACTIVE_INVENTORY_LIMIT"
