import Bugsnag from "@bugsnag/js";

class ImportPopupService {
  static LIMIT = 20;

  constructor() {
    this.reset();
    this.markChanges = () => {};
  }

  reset() {
    this.items = [];
    this.pagination = null;
    this.filters = [];
    this.totalCount = null;
    this.maxVisibleCount = 0;
    this.processing = false;
  }

  hasFilters() {
    return !!this.filters.length;
  }

  loadMore() {
    this.maxVisibleCount += ImportPopupService.LIMIT;
  }

  getLimit() {
    return ImportPopupService.LIMIT;
  }

  loadAll() {
    this.updateLoadAllLimit();
  }

  updateLoadAllLimit() {
    this.maxVisibleCount = 999_999_999;
  }

  hasMore() {
    return (
      this.totalCount === null ||
      this.totalCount > this.items.length ||
      this.getFilteredItems().length > this.maxVisibleCount
    );
  }

  checkIsAllDataLoaded() {
    return this.totalCount === this.items.length;
  }

  _loadChunks() {
    return Promise.resolve();
  }

  checkIsLoadedNextPage() {
    return this.maxVisibleCount <= this.getFilteredItems().length;
  }

  checkIsNeededAdditionalLoad() {
    if (!this.hasMore()) return false;
    return this.maxVisibleCount > this.getFilteredItems().length;
  }

  setFilters(filters) {
    this.filters = filters;
    this.markChanges();
  }

  validate() {
    if (this.checkIsNeededAdditionalLoad() && !this.processing) {
      return Promise.resolve()
        .then(() => {
          return this._loadChunks();
        })
        .catch((e) => Bugsnag.notify(e));
    }

    return Promise.resolve();
  }

  invalidate() {
    this.totalCount = this.items.length;
    this.markChanges();
  }

  getFilteredItems() {
    const startFiltering = Array(this.filters.length).fill(true);

    return this.items.filter((product, index, arr) => {
      const pass = this.filters.every((filter, indexOfFilter) => {
        const passFilter = filter(
          product,
          index,
          arr,
          startFiltering[indexOfFilter]
        );
        startFiltering[indexOfFilter] = false;
        return passFilter;
      });

      return pass;
    });
  }

  getVisibleItems() {
    return this.getFilteredItems().slice(0, this.maxVisibleCount);
  }

  onUpdate(rerender) {
    this.markChanges = rerender;
  }
}

export default ImportPopupService;
