import React, { useRef } from "react";
import styled from "styled-components";
import { useTooltip } from "../../hooks/useTooltip";
import { Tooltip } from "reactstrap";
import { useGlobalLoading } from "../../contexts/LoadingContext";

export const Spinner = styled.div`
  width: 32px;
  height: 32px;
  border: 2px solid #2f665c;
  border-right: 0;
  border-bottom: 0;
  border-radius: 32px;
  animation: spinner-chase 0.5s infinite ease both;
  margin-left: 16px;

  @keyframes spinner-chase {
    100% {
      transform: rotate(360deg);
    }
  }
`;

const TooltipText = styled.div`
  color: #fff;
  text-align: left;
`;

const globalState = [];

/**
 * Sets data in the global load state
 *
 * @param {object} data
 * @returns {string} id
 */
export const globalSetLoading = (data) => {
  const newId = (Math.random() + 1).toString(36).substring(7);
  globalState.merge((currentState) => [
    ...currentState,
    { ...data, id: newId },
  ]);

  setTimeout(() => {
    globalUnsetLoading(newId);
  }, 150000);

  return newId;
};

/**
 * Deletes item from global state
 *
 * @param {string} id
 * @returns {void}
 */
export const globalUnsetLoading = (id) => {
  globalState.merge((currentState) =>
    currentState.filter((item) => item.id !== id)
  );
};

export const GlobalLoader = () => {
  const tooltipRef = useRef(null);
  const tooltipProps = useTooltip(tooltipRef);
  const { loading, loadingMessage } = useGlobalLoading();

  if (!loading) return null;

  return (
    <>
      <Spinner ref={tooltipRef} />
      {loadingMessage && loadingMessage.trim() !== "" && (
        <Tooltip placement="right" {...tooltipProps}>
          <TooltipText>{loadingMessage}</TooltipText>
        </Tooltip>
      )}
    </>
  );
};
