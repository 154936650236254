import React from "react";
import PropTypes from "prop-types";

import defaultImage from "../../../assets/images/no-avatar.png";

import "./index.scss";

const Avatar = ({ image, wrapperClassName }) => {
  return (
    <div className={wrapperClassName}>
      {defaultImage ? (
        <img className="avatar-preview" src={image || defaultImage} />
      ) : null}
    </div>
  );
};

Avatar.propTypes = {
  image: PropTypes.any,
  wrapperClassName: PropTypes.string,
};

export default Avatar;
