import React, { useCallback, useEffect, useRef, useState } from "react";
import Viewer from "react-viewer";
import { Modal } from "reactstrap";
import PropTypes from "prop-types";

import "./index.scss";

const ProductPreview = ({ src, className = "drafts-product-preview", alt, hasFullScreenMode = false }) => {
  const [modal, setModal] = useState(false);
  const [container, setContainer] = useState(null);
  const [visible, setVisible] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [images, setImages] = useState([]);
  const containerImages = useRef(null);

  useEffect(() => {
    if (!hasFullScreenMode) return;
    const newImageStructure = {
        src: src,
        alt: src,
        downloadUrl: src,
      };
    setImages([newImageStructure]);
  }, [src, hasFullScreenMode]);

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const togModal = () => {
    setModal(!modal);
    removeBodyCss();
  };

  const fullScreenMode = useCallback((event) => {
    if (hasFullScreenMode) {
      event.preventDefault();
      setCurrentIndex(0);
      togModal();
    }
  }, [hasFullScreenMode])

  return src ? (
    <>
      <img className={className} src={src} alt={alt} onClick={(event) => fullScreenMode(event)}/>
      <Modal
        isOpen={modal}
        toggle={togModal}
        size="lg"
        centered={true}
        onOpened={() => {
          setContainer(document.getElementById("container"));
          setVisible(true);
        }}
      >
        <div className="modal-header">
          <h5
            className="modal-title text-dark-octta mt-0"
            id="myModalLabel"
          >
            View image
          </h5>
          <button
            type="button"
            onClick={() => {
              setModal(false);
              setVisible(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          ref={containerImages}
          id="container"
          className="modal-body text-muted"
        />
      </Modal>
      <Viewer
        visible={visible}
        container={container}
        images={images}
        activeIndex={currentIndex}
        noClose
        noImgDetails
        noNavbar
        drag
        zoomable
        scalable={false}
        rotatable={false}
        changeable={false}
        downloadable={false}
        attribute={false}
      />
    </>
  ) : (
    <div className={"drafts-product-preview"} />
  );
};

ProductPreview.propTypes = {
  src: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
  hasFullScreenMode: PropTypes.bool
};

export default ProductPreview;
