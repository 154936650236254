import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

const MobileRowItem = ({ label, children }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>{label}</div>
      {children}
    </div>
  );
};

MobileRowItem.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
};

export default MobileRowItem;
