import PropTypes from "prop-types";
import React from "react";

import { Switch, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

import AuthRoutes from "./pages/auth";
import ContactRoutes from "./pages/contact";

import PrivateRoute from "./base/components/PrivateRoute";
import Page404 from "./base/pages/404";
import Page500 from "./base/pages/500";

import "./assets/scss/theme.scss";

import Privacy from "./pages/privacy";
import AppRoutes from "./pages/app";
import ErrorBoundary from "./base/components/ErrorBoundary";
import { PRIVACY_GROUP_LINKS } from "./pages/privacy/config";
import { AUTH_GROUP_LINKS } from "./pages/auth/config";
import { APP_GROUP_LINKS } from "./pages/app/config";
import CompleteProfile from "./pages/completeProfile";
import { COMPLETE_PROFILE_GROUP_LINKS } from "./pages/completeProfile/config";
import { BASE_GROUP_LINKS } from "./base/config";
import { CONTACT_US_GROUP_LINKS } from "./pages/contact/config";
import Verification from "./pages/auth/pages/Verification";
import ResetPassword from "./pages/auth/pages/ResetPassword";
import ChangeEmail from "./pages/auth/pages/ChangeEmail";
import CompleteRedirect from "./pages/completeProfile/components/CompleteRedirect";
import NewEmployee from "./pages/auth/pages/NewEmployee";
import CreatePassword from "./pages/auth/pages/CreatePassword";
import Charts from "./base/components/Charts";
import CategoriesPage from "./pages/categories";
import { BASIC } from "./pages/categories/config";
import { DropdownFilterProvider } from "./pages/drafts/contexts/inventory-dropdown";

const App = () => {
  return (
    <ErrorBoundary>
      <DropdownFilterProvider>
        <Switch>
          {/* Auth */}
          <Route path={AUTH_GROUP_LINKS.BASE} component={AuthRoutes} />

          <Route path={BASIC} component={CategoriesPage} />

          {/* Setup steps */}
          <PrivateRoute
            path={COMPLETE_PROFILE_GROUP_LINKS.BASE}
            component={CompleteProfile}
            exact
          />

          {/* App */}
          <PrivateRoute path={APP_GROUP_LINKS.BASE} component={AppRoutes} />

          <Route path="/" component={CompleteRedirect} exact />

          {/* Privacy policy */}
          <Route path={PRIVACY_GROUP_LINKS.BASE} component={Privacy} />

          {/* Contact Us */}
          <Route
            path={CONTACT_US_GROUP_LINKS.BASE}
            component={ContactRoutes}
            exact
          />

          <Route
            path={AUTH_GROUP_LINKS.LINK_VERIFICATION}
            component={Verification}
            exact
          />

          <Route
            path={AUTH_GROUP_LINKS.LINK_RESET_PASSWORD}
            component={ResetPassword}
            exact
          />

          <Route
            path={AUTH_GROUP_LINKS.LINK_CHANGE_EMAIL}
            component={ChangeEmail}
            exact
          />

          <Route path={AUTH_GROUP_LINKS.NEW_EMPLOYEE} component={NewEmployee} />

          <Route
            path={AUTH_GROUP_LINKS.CREATE_PASSWORD}
            component={CreatePassword}
          />

          <Route path={BASE_GROUP_LINKS.LINK_500} component={Page500} />
          <Route path={"/components"} component={Charts} />

          {/* Not found page */}
          <Route path={BASE_GROUP_LINKS.LINK_404} component={Page404} />
          <Redirect to={BASE_GROUP_LINKS.LINK_404} />
        </Switch>
      </DropdownFilterProvider>
    </ErrorBoundary>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

AppRoutes.propTypes = {
  match: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps)(App);
