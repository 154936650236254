import BlockedButton from "../components/BlockedButton";
import ResendButton from "../components/ResendButton";

import {
  BLOCKED_BY_ADMIN_CODE,
  BLOCKED_BY_STORE_OWNER_CODE,
  EMAIL_NOT_VERIFIED_CODE,
  STORE_OWNER_SUBSCRIPTION_EXPIRED_CODE,
  WRONG_CREDENTIALS_CODE,
} from "../../../base/constants/forms";
import ConfirmButton from "../components/ConfirmButton";

export const getIsInvalidCredential = (error) => {
  return error?.code === WRONG_CREDENTIALS_CODE;
};

export const signUpPopup = {
  [EMAIL_NOT_VERIFIED_CODE]: {
    isOpen: true,
    title: "Verify email address",
    text: "Your email address is not verified. Follow the link that was sent to your email to sign in",
    Button: ResendButton,
  },
  [BLOCKED_BY_ADMIN_CODE]: {
    isOpen: true,
    title: "Blocked account",
    text: "Your account was blocked by the Admin. Please use the Contact us option to get more information",
    Button: BlockedButton,
  },
  [BLOCKED_BY_STORE_OWNER_CODE]: {
    isOpen: true,
    title: "Store Owner's subscription has expired",
    text: "You were blocked by the Store Owner.",
    Button: ConfirmButton,
  },
  [STORE_OWNER_SUBSCRIPTION_EXPIRED_CODE]: {
    isOpen: true,
    title: "Store Owner's subscription has expired",
    text: "Some problems have occurred with the subscription plan. Please contact the Store Owner.",
    Button: ConfirmButton,
  },
};
