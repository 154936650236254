import {COUNT_DIGITS} from "../../../analytics/constants";

export const calcPoshmarkFees = (soldPrice) => {
    const POSHMARK_FEES_VALUE_PERCENT = 0.2
    const POSHMARK_FEES_VALUE_CONDITION = 2.95

    const HIGH_LIMIT = 15
    const MID_LIMIT = 14
    const LOW_LIMIT = 3

    let poshmarkFees = 0;

    if (soldPrice >= HIGH_LIMIT) {
        poshmarkFees = (+soldPrice * POSHMARK_FEES_VALUE_PERCENT).toFixed(COUNT_DIGITS)
    }

    if (soldPrice <= MID_LIMIT && soldPrice > LOW_LIMIT) {
        poshmarkFees = POSHMARK_FEES_VALUE_CONDITION
    }

    return poshmarkFees
}