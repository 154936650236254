import React from 'react'
import PropTypes from 'prop-types';
import { components } from 'react-select';
import { ReactComponent as Arrow } from '../../../../../assets/images/ic-chevron-category.svg';
import { useHighlight } from '../../../../hooks/useHighlight';

const SelectOption = ({ children, selectProps, ...rest }) => {
  const { searchValue } = selectProps;
  const { data: { hasChildren, prevPath } } = rest;
  const isMultipleHighlight = true;
  const exact = false;
  const {decorateText} = useHighlight(searchValue, exact, isMultipleHighlight);

  return (
    <components.Option {...rest}>
      <div className='d-flex justify-content-between'>
        <p className='mb-0'>
          <span {...decorateText(`${prevPath ? prevPath.concat(' / ') : ''}${children}`)} />
        </p>
        {hasChildren && <Arrow />}
      </div>
    </components.Option>
  )
};

SelectOption.propTypes = {
  children: PropTypes.any,
  selectProps: PropTypes.object,
  rest: PropTypes.array
}

export default SelectOption