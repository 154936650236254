/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { FormGroup } from "reactstrap";
import classnames from "classnames";
import AsyncSelect from "./Select/AsyncSelect";
import ErrorMessageDefault from "./ErrorMessageDefault";
import { useTranslate } from "../hooks/useTranslate";
import { useFormikSelect } from "../hooks/useFormikSelect";
import DotsLoader from "./DotLoader";

const FormikAsyncSelect = ({
  name,
  label,
  onChange,
  getValue,
  loading = false,
  noOptionsMessage,
  isHideLabel = false,
  ...props
}) => {
  const [translate] = useTranslate();
  const { isError, handleChange, handleBlur, error } = useFormikSelect(
    name,
    onChange
  );

  return (
    <FormGroup className="mb-3">
      {label && !isHideLabel && (
        <label
          htmlFor={name}
          className={classnames({ "text-danger": isError })}
        >
          {label} {loading && <DotsLoader />}
        </label>
      )}
      <AsyncSelect
        className={classnames({
          "is-invalid-input is-invalid react-select-wrap": isError,
        })}
        onBlur={handleBlur}
        onChange={handleChange}
        getOptionValue={({ label }) => label}
        getValue={getValue}
        noOptionsMessage={() => {
          return noOptionsMessage;
        }}
        {...props}
      />
      <ErrorMessageDefault>{translate(error, { label })}</ErrorMessageDefault>
    </FormGroup>
  );
};

FormikAsyncSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  isHideLabel: PropTypes.bool,
  onChange: PropTypes.func,
};

export default FormikAsyncSelect;
