/* eslint-disable react/prop-types */
import { Col, Row } from "reactstrap";
import FormikSingleSelect from "../../../../../base/components/FormikSingleSelect";
import React, { useEffect, useState } from "react";
import { useField } from "formik";
import { useMercariBrand } from "./hooks";
import VirtualizeMenuList from "../../../../../base/components/VirtualizeMenuList";
import CustomBrandMessage from "../../CustomBrandMessage";
import { matchStrings } from "../../../../../base/helpers/matchStrings";
import { useFindAlternativeBrands } from "../../../hooks/useFindAlternativeBrands";

const initialBrand = {
  label: "No brand/Not sure",
};

export const Brands = ({ brands, loading = false, suggested }) => {
  const [{ value: brandId }, , helpers] = useField({ name: "brandId" });
  const { setValue: setId } = helpers;

  const [, , { setValue }] = useField({
    name: "brandName",
  });
  const { brandLabel } = useMercariBrand(brands, brandId);
  const [defaultBrand, setDefaultBrand] = useState(null);
  const [isCleared, setIsCleared] = useState(false);
  const [alternativeBrandsList, setAlternativeBrandsList] = useState([]);

  const [findAlternativeBrands] = useFindAlternativeBrands();

  const onSelectAlternativeBrand = (alternativeBrand) => {
    const brand = brands.find(({ label }) =>
      matchStrings(label, alternativeBrand)
    );

    if (brand?.id) setId(brand.id);
  };

  const formatAllBrands = (allBrands) => allBrands.map(({ name }) => name);

  useEffect(() => {
    setValue(brandLabel);
  }, [brandLabel]);

  const selectDefaultBrand = () => {
    setDefaultBrand(() => initialBrand);
    setValue(initialBrand.label);
    setIsCleared(true);
    helpers.setValue(null);
  };

  const isCustom = suggested && !isCleared && !brandId && !!brands.length;

  useEffect(() => {
    const loadedBrands = !!brands.length;
    const allowApplySuggested =
      loadedBrands && !isCleared && !brandId && suggested;

    if (!allowApplySuggested) return;

    const brand = brands.find(({ label }) => matchStrings(label, suggested));

    if (brand?.id) setId(brand.id);
  }, [brands, brandId, suggested]);

  useEffect(() => {
    if (!isCustom) return;

    const alternativeBrand = findAlternativeBrands(
      brands,
      suggested,
      formatAllBrands
    );

    setAlternativeBrandsList(alternativeBrand);
  }, [isCustom]);

  const onClearBrand = () => {
    setDefaultBrand(null);
    setIsCleared(true);
    helpers.setValue(null);
    setValue(null);
  };

  return (
    <Row className={"mb-4 mt-4"}>
      <Col md={6}>
        <FormikSingleSelect
          name="brandId"
          options={brands}
          loading={loading}
          placeholder="Select brand"
          label={"Brand"}
          CustomMenuList={VirtualizeMenuList}
          initialOption={defaultBrand}
          description={
            <>
              Not in the list? Select:{" "}
              <span
                className="font-size-10 mercary-brands "
                onClick={() => selectDefaultBrand()}
              >
                No brand/Not sure
              </span>
              {isCustom && (
                <CustomBrandMessage
                  brandList={alternativeBrandsList}
                  onSelectBrand={onSelectAlternativeBrand}
                />
              )}
            </>
          }
          onClear={onClearBrand}
        />
      </Col>
    </Row>
  );
};
