export const WINDOW_NAME_POSHMARK = "Connection";
export const WINDOW_CONFIG_POSHMARK = "width=700, height=700, scrollbars=no";
export const authUrl_POSHMARK =
  "https://poshmark.com/feed?autoclosing_by_extension";
export const profile_MERCARI = "https://www.mercari.com/mypage";
export const authUrl_ETSY =
  "https://www.etsy.com/api/v3/ajax/member/users/listings-favorites-collections-map";
export const authUrl_EBAY =
  "https://www.ebay.com/gh/user_profile?modules=USER_PROFILE&behavior=GET_PROFILE&moduleType=USER_PROFILE&correlation=operationId%3D2062857&v=2";

export const ONE_THOUSAND_MILLISECONDS = 1000;
