import * as yup from "yup";
import { ONLY_NUMBERS_AND_EMPTY, MAX_PRICE } from "./constants";

export const isValidPrice = (value) => {
  return ONLY_NUMBERS_AND_EMPTY.test(value.toString().trim());
};

const MAX_BUYER_NAME = 50;
export const MAX_BUYER_LENGTH = "51";

export const validationSchema = yup.object().shape({
  priceSold: yup.number().max(MAX_PRICE),
  marketplaceFees: yup.number().max(MAX_PRICE),
  costOfGood: yup.number().max(MAX_PRICE),
  shippingCost: yup.number().max(MAX_PRICE),
  paymentMethod: yup.number(),
  transactionFees: yup.number().max(MAX_PRICE),
  buyer: yup.string().max(MAX_BUYER_NAME).trim()
});
