import React from "react";
import PropTypes from "prop-types";

import { CustomModal } from "../../../../../base/components/GoogleMap/components/Modal";
import { ImageCropper } from "../../../../../base/components/ImageCropper";

const EditModal = ({ isOpen, selectedImage, onCropImage, onClose }) => {
  return (
    <CustomModal title="Crop image" isOpen={isOpen} onClose={onClose}>
      <ImageCropper
        selectedImage={selectedImage}
        onCropImage={onCropImage}
        onCloseCropper={onClose}
        isRoundedCropper={false}
      />
    </CustomModal>
  );
};

EditModal.propTypes = {
  isOpen: PropTypes.bool,
  selectedImage: PropTypes.any,
  onCropImage: PropTypes.func,
  onClose: PropTypes.func,
};

export default EditModal;
