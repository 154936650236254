import { EBAY_RETURNS_TYPES } from "./index";

export const EBAY_RETURNS_STRINGS = {
  [EBAY_RETURNS_TYPES.domestic]: {
    mainCheckboxLabel: "Returns accepted",
    daysDropdownLabel:
      "After receiving the item, your buyer should contact you within:",
    paidByDropdown: "Return shipping will be paid by:",
    replacementCheckboxLabel: "Replacement or exchange available",
  },
  [EBAY_RETURNS_TYPES.international]: {
    mainCheckboxLabel: "International returns accepted",
    daysDropdownLabel:
      "After receiving the item, your buyer should contact you within:",
    paidByDropdown: "Return shipping will be paid by:",
    replacementCheckboxLabel: "Replacement or exchange available",
  },
};

export const EBAY_RETURNS_NOTE = `The item can always be returned if it doesn’t match the listing description.`;
