import { EXTENSION_EVENTS, MARKETPLACES } from "../base/constants/extension";
import { ENV } from "../base/constants/env";
import Bugsnag from "@bugsnag/js";

const waitLoad =
  document.readyState === "complete"
    ? Promise.resolve()
    : new Promise((resolve) => {
        document.addEventListener("DOMContentLoaded", resolve);
      });

class ExtensionService {
  static $displayName = "ExtensionService";

  /**
   *
   * @param marketplace {string}
   */
  constructor(marketplace) {
    this.marketplace = marketplace;
    this.queues = {};
  }

  /**
   *
   * @param event {string}
   * @param {(function(param: any):any)} [callback]
   * @returns {{toPromise: (function(): Promise<any>), unSubscribe: unSubscribe}}
   */
  _subscribe(event, callback = () => {}) {
    const eventName = `ON:${this.marketplace}:${event}`;
    const promiseActions = [];

    const unSubscribe = () => {
      document.removeEventListener(eventName, onGetData);
    };

    function onGetData(e) {
      const { err = null, marketplaceTabId = null } = e.detail;

      promiseActions.forEach(({ resolve, reject }) => {
        unSubscribe();

        if (err) return reject(err);
        resolve(e.detail);
      });

      callback({ data: e.detail, err, marketplaceTabId });
    }

    const toPromise = () => {
      return new Promise((resolve, reject) => {
        promiseActions.push({
          resolve,
          reject,
        });
      });
    };

    document.addEventListener(eventName, onGetData);

    // Avoid cycle dependencies
    if (event !== EXTENSION_EVENTS.PING) {
      // Check chrome extension connection
      this.ping().catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        // Break request connection
        onGetData({ detail: { err } });
      });
    }

    return {
      toPromise,
      unSubscribe,
    };
  }

  dispatchEvent(eventToEmit, payload) {
    if (ENV.IS_DEV)
      // eslint-disable-next-line no-console
      console.log(
        `%c > ${eventToEmit}`,
        "color:white;font-size:12px;padding: 2px;background: grey;",
        payload
      );

    document.dispatchEvent(new CustomEvent(eventToEmit, { detail: payload }));
  }

  subscribe(event, callback, payload = {}) {
    const eventToEmit = `APP:${this.marketplace}:${event}`;

    waitLoad
      .then(() => this.dispatchEvent(eventToEmit, payload))
      .catch((e) => Bugsnag.notify(e));

    return this._subscribe(event, callback);
  }

  /**
   *
   * @param event {string} - use constant
   * @param {object} [payload] - provide params if needed
   * @desc - example this.request(EXTENSION_EVENTS.EDIT_DRAFT, {draftId, body, params})
   */
  _request(event, payload = {}) {
    const eventToEmit = `APP:${this.marketplace}:${event}`;

    return waitLoad.then(() => {
      const promise = this._subscribe(event).toPromise();

      this.dispatchEvent(eventToEmit, payload);

      return promise;
    });
  }

  /**
   *
   * @param event {string} - use constant
   * @param {object} [payload] - provide params if needed
   * @desc - example this.request(EXTENSION_EVENTS.EDIT_DRAFT, {draftId, body, params})
   */
  request(event, payload) {
    const req = this.queues[event] || Promise.resolve();

    return new Promise((resolve) => req.finally(resolve)).then(() => {
      const req = this._request(event, payload);

      this.queues[event] = req;

      return req;
    });
  }

  /**
   * @desc Check - Is an extension installed?
   * @returns {Promise<{result: boolean}>}
   */
  ping() {
    return waitLoad.then(() => {
      return Promise.race([
        this._request(EXTENSION_EVENTS.PING),
        new Promise((_, reject) =>
          setTimeout(() => reject({ err: "Extension is not installed" }), 5000)
        ),
      ]);
    });
  }

  onInstall(callback) {
    return this.subscribe(EXTENSION_EVENTS.ON_INSTALL_EXTENSION, ({ err }) => {
      if (!err) callback();
    });
  }

  onUninstall(callback) {
    return this.subscribe(
      EXTENSION_EVENTS.ON_UNINSTALL_EXTENSION,
      ({ err }) => {
        if (!err) callback();
      }
    );
  }

  onInvalidate(callback) {
    return this.subscribe(
      MARKETPLACES.EXTENSION.concat(":").concat(EXTENSION_EVENTS.INVALIDATED),
      ({ err }) => {
        if (!err) callback();
      }
    );
  }
}

export default ExtensionService;
