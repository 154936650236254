import React, { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { Container } from "../components/Container";
import { FORM_TYPES } from "../../drafts/const/form";

const Poshmark = () => {
  const [base, updateBase] = useState([]);
  const [categories, updateCategories] = useState([]);

  useEffect(() => {
    import("../data/basic")
      .then(({ basic }) => {
        updateBase(basic);
      })
      .catch((e) => Bugsnag.notify(e));
    import("../data/poshmark")
      .then(({ poshmark }) => {
        updateCategories(poshmark);
      })
      .catch((e) => Bugsnag.notify(e));
  }, []);

  return (
    <Container
      form={FORM_TYPES.POSHMARK}
      categories={categories}
      basic={base}
    />
  );
};
export default Poshmark;
