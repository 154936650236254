import React, { useCallback, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useField } from "formik";

import CustomInput from "./Input";
import ErrorMessageDefault from "./ErrorMessageDefault";

import { ReactComponent as ViewPassword } from "../../assets/images/ic_show-alt_grey.svg";
import { ReactComponent as HidePassword } from "../../assets/images/ic-hide-grey.svg";
import { PASSWORD_TYPE, TEXT_TYPE } from "../constants/forms";
import { useTranslate } from "../hooks/useTranslate";
import { useCustomError } from "../contexts/CustomErrorMessage";

const FormikPassword = ({
  placeholder,
  name,
  type,
  maxLength,
  readOnly,
  label,
  containerClassName,
  onChange = () => {},
  ErrorMessage = ErrorMessageDefault,
  min,
  max,
  isHideLabel = false,
  isHideEyeOption = false,
}) => {
  const [currentType, updateCurrentType] = useState(type);
  const [isHidePassword, updateIsHidePassword] = useState(true);

  const [field, meta] = useField({ name, type, placeholder });
  const isError = meta.error && meta.touched;
  const [translate] = useTranslate();

  const { getError } = useCustomError();
  const errorPassword = getError(name);

  const handleOnChange = (event) => {
    field.onChange(event);
    onChange(event);
  };

  const handleViewPassword = useCallback(() => {
    updateCurrentType((type) =>
      type === TEXT_TYPE ? PASSWORD_TYPE : TEXT_TYPE
    );
    updateIsHidePassword((isHide) => !isHide);
  }, []);

  return (
    <section
      className={classnames(
        containerClassName,
        "custom-form-group",
        "form-group-password"
      )}
    >
      {!isHideLabel && (
        <label className={isError || errorPassword ? "text-danger" : ""}>
          {label}
        </label>
      )}
      <div
        className={classnames("input-password-wrapper", {
          "is-invalid-input is-invalid": isError || errorPassword,
        })}
      >
        <CustomInput
          {...field}
          type={currentType}
          onChange={handleOnChange}
          autoComplete="new-password"
          maxLength={maxLength}
          readOnly={readOnly}
          placeholder={placeholder}
        />
        {!isHideEyeOption && (
          <span className="view-password" onClick={handleViewPassword}>
            {isHidePassword ? <ViewPassword /> : <HidePassword />}
          </span>
        )}
      </div>
      <ErrorMessage name={name}>
        {translate(meta.error, { label, min, max })}
      </ErrorMessage>
    </section>
  );
};

FormikPassword.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  isPassword: PropTypes.bool,
  onChange: PropTypes.func,
  ErrorMessage: PropTypes.any,
  isHideEyeOption: PropTypes.bool,
};

export default FormikPassword;
