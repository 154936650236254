import CustomButton from "../../../../base/components/CustomButton";
import React from "react";
import {useHistory} from "react-router-dom";


const AddDraftButton = ({className = "mt-3", disabled, link, buttonContent}) => {
	const history = useHistory();

	const goToCreateDraft = () => {
		history.push(
			link
		)
	};

	return (
		<CustomButton
			disabled={disabled}
			onClick={goToCreateDraft}
			className={`filled-primary ${className}`}
		>
			{buttonContent}
		</CustomButton>
	)
};


export default AddDraftButton