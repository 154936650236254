import React from "react";

const TitleWrapper = ({title, children}) => {
  return(
    <div className="p-4 mb-4 title-wrapper">
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <h3 className="font-size-19 text-primary-gomi font-weight-semibold mb-0 title-text-color">
          {title}
        </h3>
        <div className="title-text-color font-size-15 ">
          {children}
        </div>
      </div>
    </div>
  )
}

export default TitleWrapper;