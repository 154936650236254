import { useCallback, useEffect, useState } from "react";

import StorageService from "../../../services/StorageService";
import { useLocationQuery } from "../../../base/hooks/useQueryString";
import { useService } from "../../../base/hooks/useService";

const WINDOW_NAME = "Connection";
const WINDOW_CONFIG = "width=600, height=600, scrollbars=no";

export const useOpenWindow = (keyStorage) => {
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const [isOpenedWindow, updateIsOpenedWindow] = useState(false);

  const getFromStorage = useCallback(() => {
    return storage.get(keyStorage, {});
  }, [storage, keyStorage]);

  const [connectionInfo, updateConnectionInfo] = useState(() =>
    getFromStorage()
  );

  const { params } = useLocationQuery();

  useEffect(() => {
    if (!params) return;

    storage.set(keyStorage, params);

    if (window.opener) {
      window.close();
    }
  }, [params, storage, isOpenedWindow, keyStorage]);

  useEffect(() => {
    return storage.onChangeDataInSiblingTab(keyStorage, () => {
      updateConnectionInfo(getFromStorage());
    });
  }, [storage, getFromStorage, keyStorage]);

  const handleOpenWindow = useCallback((authUrl) => {
    window.open(authUrl, WINDOW_NAME, WINDOW_CONFIG);
    updateIsOpenedWindow(true);
  }, []);

  return [connectionInfo, handleOpenWindow];
};
