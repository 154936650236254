import {
  MARKETPLACE_EBAY_TYPE,
  MARKETPLACE_ETSY_TYPE,
  MARKETPLACE_POSHMARK_TYPE,
  MARKETPLACE_MERCARI_TYPE,
  MARKETPLACE_OTHER_TYPE,
} from "../../../../inventory/constants/importInventory";

export const EMPTY_VALUE = "-";

export const TABLES_ITEMS_WHO_DISABLED_WHEN_IS_CASH = ["Cost of Goods Sold"];

export const TABLES_TYPES_LIST_WITHOUT_MAPPING_TABLE_ITEMS = ["expenses"];

export const REVENUE_LESS_RETURNS_AND_ALLOWANCES = "lessReturnsAndAllowances";
export const SHIPPING_CHARGED_TO_BUYER = "shippingChargedToBuyer";

export const COST_OF_REVENUES_ITEM_TITLES = {
  platformFees: "platformFees",
  additionalSellingFees: "additionalSellingFees",
  transactionFees: "Transaction Fees",
  shippingCosts: "shippingCosts",
  returnShippingCosts: "returnShippingCosts",
  costOfGoodsSold: "Cost of Goods Sold",
};

export const TABLE_TYPES_TITLES = {
  revenues: "Revenue",
  costOfRevenues: "Cost of Revenues",
  grossProfit: "Gross Profit",
  expenses: "Expenses",
  netProfit: "Net Profit",
};

export const TABLES_TYPES = [
  ["revenues", "totalRevenue"],
  ["costOfRevenues", "totalCostOfRevenues"],
  ["grossProfit", "totalGrossProfit"],
  ["expenses", "totalExpenses"],
  ["netProfit", "totalNetProfit"],
];

export const BOOKKEEPING_TABLE_TITLES_MAP = {
  revenues: {
    mainTitle: "Revenue",
    itemTitles: {
      [MARKETPLACE_EBAY_TYPE]: "eBay Sales",
      [MARKETPLACE_ETSY_TYPE]: "Etsy Sales",
      [MARKETPLACE_POSHMARK_TYPE]: "Poshmark Sales",
      [MARKETPLACE_MERCARI_TYPE]: "Mercari Sales",
      [MARKETPLACE_OTHER_TYPE]: "Other Sales",
      [SHIPPING_CHARGED_TO_BUYER]: "Shipping Charged to Buyer",
      [REVENUE_LESS_RETURNS_AND_ALLOWANCES]: "Less Returns and Allowances",
    },
    totalTitle: "Total Revenue",
  },
  costOfRevenues: {
    mainTitle: "Cost of Revenues",
    itemTitles: {
      [COST_OF_REVENUES_ITEM_TITLES.platformFees]: "Platform Fees",
      [COST_OF_REVENUES_ITEM_TITLES.additionalSellingFees]:
        "Additional Selling Fees",
      [COST_OF_REVENUES_ITEM_TITLES.transactionFees]: "Transaction Fees",
      [COST_OF_REVENUES_ITEM_TITLES.shippingCosts]: "Shipping Costs",
      [COST_OF_REVENUES_ITEM_TITLES.returnShippingCosts]:
        "Return Shipping Costs",
      [COST_OF_REVENUES_ITEM_TITLES.costOfGoodsSold]: "Cost of Goods Sold",
    },
    totalTitle: "Total Cost of Revenues",
  },
  grossProfit: {
    mainTitle: "Gross Profit",
    itemTitles: {},
    totalTitle: null,
  },
  expenses: {
    mainTitle: "Expenses",
    itemTitles: {
      Other: "Other",
      Package: "Packaging",
    },
    totalTitle: "Total Expenses",
  },
  netProfit: {
    mainTitle: "Net Profit",
    itemTitles: {},
    totalTitle: null,
  },
};
