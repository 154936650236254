import React from "react";
import { getTableFieldValuePostedStatus } from "../../helpers/getTableFieldValue";
import { DRAFT_FIELDS } from "../../constants";
import { FormatDateSorting } from "../../helpers/format";
import { parseQuery } from "../../../../base/hooks/useQueryString";

const DateListingColumn = (_, model) => {

  const { orderBy = ""} = parseQuery(location.search);
  const isOrderByDateImported = orderBy === "statusAt"

  const dateValue = getTableFieldValuePostedStatus(model, isOrderByDateImported ? DRAFT_FIELDS.STATUS_AT : DRAFT_FIELDS.FIRST_LISTED_AT, )

  return (
    <>
      <FormatDateSorting
        date={dateValue}
      />
      </>
  );
};

export default DateListingColumn;
