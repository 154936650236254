import React, { useCallback } from "react";
import { Formik } from "formik";
import { Card, CardBody, Col, Row } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import CustomButton from "../../../../base/components/CustomButton";
import FormikInput from "../../../../base/components/FormikInput";
import FormikTextarea from "../../../../base/components/FormikTextarea";

import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";
import HelpService from "../../../../services/HelpService";
import ToasterService from "../../../../services/ToastService";
import { useProfile } from "../../../profile/hooks/useProfile";

import {
  formFields,
  formMinMaxValues,
  initialValues,
  validationSchema,
} from "./form";
import { SUCCESS_RESEND } from "./constants";

const ContactUs = () => {
  const [{ firstName, lastName, email }] = useProfile();
  /**
   * @type {HelpService}
   */
  const helpService = useService(HelpService);
  /**
   * @type {ToasterService}
   */
  const toastr = useService(ToasterService);

  const [loading, { registerPromise }] = useLoading();

  const handleSubmit = useCallback(
    (values, { resetForm }) => {
      registerPromise(helpService.contactUs(values))
        .then(() => {
          resetForm();
          toastr.success(SUCCESS_RESEND);
        })
        .catch((e) => Bugsnag.notify(e));
    },
    [registerPromise, helpService, toastr]
  );

  return (
    <Row className="mt-3">
      <Col lg="12" xl="6">
        <Card>
          <CardBody>
            <h3 className="bold-black mb-4">Contact us</h3>
            <Formik
              initialValues={{
                ...initialValues,
                [formFields.name]: `${firstName} ${lastName}`,
                [formFields.email]: email,
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, errors, dirty }) => (
                <form className="form-horizontal" onSubmit={handleSubmit}>
                  <FormikInput
                    id={formFields.name}
                    name={formFields.name}
                    label="Name*"
                    containerClassName="mb-4"
                    placeholder="Enter your name"
                    min={formMinMaxValues[formFields.name].min}
                    maxLength={200}
                    maxLengthForCounter={formMinMaxValues[formFields.name].max}
                  />
                  <FormikInput
                    id={formFields.email}
                    name={formFields.email}
                    label="Email*"
                    containerClassName="mb-4"
                    placeholder="Enter your Email"
                    type="email"
                  />
                  <FormikInput
                    id={formFields.subject}
                    name={formFields.subject}
                    label="Subject*"
                    containerClassName="mb-4"
                    placeholder="Write theme"
                    min={formMinMaxValues[formFields.subject].min}
                    maxLength={200}
                    maxLengthForCounter={
                      formMinMaxValues[formFields.subject].max
                    }
                  />
                  <FormikTextarea
                    id={formFields.message}
                    name={formFields.message}
                    label="Message*"
                    rows="3"
                    placeholder="Write your message"
                    min={formMinMaxValues[formFields.message].min}
                    max={formMinMaxValues[formFields.message].max}
                  />
                  <div className="d-flex justify-content-end mt-3">
                    <CustomButton
                      type="submit"
                      className="filled-primary"
                      disabled={
                        !!Object.keys(errors).length || !dirty || loading
                      }
                    >
                      Send
                    </CustomButton>
                  </div>
                </form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ContactUs;
