import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import List from "./pages/ListDrafts";
import InventoryDetails from "./pages/EditInventory";
import {INVENTORY_GROUP_LINKS} from "./config";

const Drafts = () => {
	return (
		<div className="page-content">
			<Switch>
				<Route path={INVENTORY_GROUP_LINKS.BASE} exact>
					<List/>
				</Route>
				<Route
					path={INVENTORY_GROUP_LINKS.DETAILS_VIEW}
					component={InventoryDetails}
				/>
				<Route
					path={INVENTORY_GROUP_LINKS.DETAILS}
					component={InventoryDetails}
					exact
				/>
				<Redirect to={INVENTORY_GROUP_LINKS.BASE}/>
			</Switch>
		</div>
	);
};

export default Drafts;
