/* eslint-disable react/prop-types */
import React from "react";
import { Col, Row } from "reactstrap";
import styled from "styled-components";

import CountEntries from "../../../../../pages/drafts/components/CountEntries";
import Checkbox from "../../../Checkbox";
import CustomDropdown from "../../../CustomDropdown";
import {
  INDEX_SHOW_ACTIVE_INVENTORY,
  DROPDOWN_IMPORT_LIST_INVENTORY,
  EBAY_TIME_PERIOD_OPTIONS,
} from "../../../../constants/importEbay";
import { MARKETPLACE_EBAY_TYPE } from "../../../../../pages/inventory/constants/importInventory";

const SpacedCol = styled(Col)`
  gap: 8px;
`;

const timeDropdownEnabled = false;

const ImportFilters = ({
  count,
  isCheckedAll,
  onChangeCheckedAll,
  onChangeFilter,
  marketplaceType = null,
  onChangeTimePeriod,
  isSold = false,
}) => {
  return (
    <Row className="mb-3">
      <Col className="d-flex align-items-center">
        <Checkbox
          value={isCheckedAll}
          onChange={onChangeCheckedAll}
          className="custom-green-checkbox ml-3 mr-3"
          text={isCheckedAll ? "Deselect all" : "Select all"}
        />
        <CountEntries count={count}>Items found</CountEntries>
      </Col>
      <SpacedCol className="d-flex justify-content-end">
        {marketplaceType === MARKETPLACE_EBAY_TYPE &&
          isSold &&
          timeDropdownEnabled && (
            <CustomDropdown
              defaultSelected={EBAY_TIME_PERIOD_OPTIONS[6].label}
              list={EBAY_TIME_PERIOD_OPTIONS.map((option) => option.label)}
              onChange={onChangeTimePeriod}
            />
          )}
        <CustomDropdown
          defaultSelected={
            DROPDOWN_IMPORT_LIST_INVENTORY[INDEX_SHOW_ACTIVE_INVENTORY]
          }
          list={DROPDOWN_IMPORT_LIST_INVENTORY}
          onChange={onChangeFilter}
        />
      </SpacedCol>
    </Row>
  );
};

export default ImportFilters;
