import styles from "./index.module.scss";
import React from "react";
import joinClassNames from "../../helpers/joinClassNames";

const DotsLoader = ({className = ""}) => {
  return (
    <div className={joinClassNames(styles.loader, className)}>
      <div className={styles.spinner}>
        <div className={styles.bounce1}/>
        <div className={styles.bounce2}/>
        <div className={styles.bounce3}/>
      </div>
    </div>
  );
};

export default DotsLoader;
