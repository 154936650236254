import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";
import { SearchInput } from "../SearchInput";
import styles from "./index.module.scss";


const MergeRightHeader = ({ onSearchTerm, search, isDisabledSearch }) => {
  return (
    <Row className="mb-3 d-flex align-items-center">
      <Col>
        <div className={styles.title}>Suggested items</div>
      </Col>
      <Col className="d-flex justify-content-end">
        <div className={styles.searchWrapper}>
          <SearchInput
            onChange={onSearchTerm}
            value={search}
            isDisabled={isDisabledSearch}
            placeholder={
              <span className="regular-grey">
                Search by&nbsp;
                <span className="bold-sm-gray">SKU </span>
                or
                <span className="bold-sm-gray"> Title</span>
              </span>
            }
          />

        </div>
      </Col>
    </Row>
  );
};

MergeRightHeader.propTypes = {
  search: PropTypes.string,
  onSearchTerm: PropTypes.func,
};

export default MergeRightHeader;