const BASE_PAGE_LINK = "/app/help";

export const HELP_GROUP_LINKS = {
  BASE: BASE_PAGE_LINK,
  INSTRUCTION: `${BASE_PAGE_LINK}/instruction`,
  CONTACT_US: `${BASE_PAGE_LINK}/contact-us`,
  TERMS: `${BASE_PAGE_LINK}/terms-and-conditions`,
  PRIVACY: `${BASE_PAGE_LINK}/privacy-policy`,
  FAQ: `${BASE_PAGE_LINK}/faq`,
};

export const HELP_TABS = [
  {
    title: "Onboarding",
    link: HELP_GROUP_LINKS.INSTRUCTION,
    isLink: false,
  },
  {
    title: "Help Center",
    link: "http://help.fearn.co/",
    isLink: true,
  },
  {
    title: "Contact Us",
    link: HELP_GROUP_LINKS.CONTACT_US,
    isLink: false,
  },
];
