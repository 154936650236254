import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";

import { GoogleLoginButton } from "./components/GoogleLoginButton/index";
import { ReactComponent as GoogleImage } from "../../assets/google-icon.svg";

const SocialLogin = ({ onSuccess, onFail, children }) => {
  const [isLoadingGoogleLogin, setIsLoadingGoogleLogin] = useState(false);

  const onSuccessLogin = useCallback(
    (token, socialType) => {
      onSuccess(token, socialType);
    },
    [onSuccess]
  );

  const onFailLogin = useCallback((error) => onFail(error), [onFail]);

  return (
    <>
      <div className="social-body">
        <GoogleLoginButton
          isDisabled={!isLoadingGoogleLogin}
          onPending={setIsLoadingGoogleLogin}
          onSuccess={onSuccessLogin}
          onFail={onFailLogin}
        >
          <GoogleImage />
          <span>{children}</span>
        </GoogleLoginButton>
      </div>
    </>
  );
};

SocialLogin.propTypes = {
  onSuccess: PropTypes.func,
  onFail: PropTypes.func,
  isRegister: PropTypes.bool,
  children: PropTypes.any,
};

export default SocialLogin;
