import React from 'react';
import PropTypes from "prop-types";

const Counter = ({ seconds }) => {
  if (!seconds) return null;

  return (
    <span className='regular-dark-grey'>
      {seconds} sec
    </span>    
  )
};

Counter.propTypes = {
  seconds: PropTypes.string,
};

export default Counter; 