import React, { useCallback, useMemo } from "react";
import moment from "moment";
import { Col, Row } from "reactstrap";
import Select from "react-select";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { customStyles } from "../../../../../../base/components/Select/shared/utils/defaultSelectStyle";

import ReportDateInput from "./components/ReportDateInput";
import { REPORT_PERIOD_OPTIONS } from "../../../../constants";
import { MOMENT_DAY } from "../../../../../expenses/constants";

import styles from "./index.module.scss";
import "./datePicker.scss";

const CUSTOM_DATE_RANGE_ID = "custom";

const ReportPeriod = ({ settings, setSettings }) => {
  const { reportPeriod, dateRange } = settings;
  const isShowDateRangeInputs = reportPeriod === CUSTOM_DATE_RANGE_ID;

  const onChangeReportPeriod = useCallback(
    ({ id: value }) => {
      setSettings({ reportPeriod: value });
    },
    [setSettings]
  );

  const onChangeStartDate = useCallback(
    (newValue) => {
      setSettings({
        dateRange: {
          ...dateRange,
          start: moment(newValue).startOf(MOMENT_DAY).format(),
        },
      });
    },
    [setSettings, dateRange]
  );

  const onChangeEndDate = useCallback(
    (newValue) => {
      setSettings({
        dateRange: {
          ...dateRange,
          end: moment(newValue).endOf(MOMENT_DAY).format(),
        },
      });
    },
    [setSettings, dateRange]
  );

  const getSelectedReportPeriod = useMemo(() => {
    return (
      REPORT_PERIOD_OPTIONS.find((option) => option.id === reportPeriod) || ""
    );
  }, [reportPeriod]);

  return (
    <Row className={styles.wrapper}>
      <Col md="3">
        <label className="bookkeeping-settings-label" htmlFor="reportPeriod">
          Report period
        </label>
        <Select
          value={getSelectedReportPeriod}
          name="reportPeriod"
          options={REPORT_PERIOD_OPTIONS}
          getOptionValue={({ id }) => id}
          placeholder="Choose report period"
          styles={customStyles}
          onChange={onChangeReportPeriod}
          components={{
            ClearIndicator: null,
            IndicatorSeparator: null,
          }}
        />
      </Col>
      {isShowDateRangeInputs && (
        <Col className={styles.dateWrapper}>
          <div className="dateInputContainer">
            <ReactDatePicker
              selected={moment(dateRange.start).toDate()}
              onChange={onChangeStartDate}
              customInput={<ReportDateInput />}
              maxDate={moment(dateRange.end).toDate()}
            />
          </div>
          <span className={styles.dateSeparator}>to</span>
          <div className="dateInputContainer">
            <ReactDatePicker
              selected={moment(dateRange.end).toDate()}
              onChange={onChangeEndDate}
              customInput={<ReportDateInput />}
              minDate={moment(dateRange.start).toDate()}
            />
          </div>
        </Col>
      )}
    </Row>
  );
};

ReportPeriod.propTypes = {
  settings: PropTypes.object,
  setSettings: PropTypes.func,
};

export default ReportPeriod;
