/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import UploaderButton from "./UploaderButton";
import Image from "./Image";
import PropTypes from "prop-types";
import "./index.scss";
import { MAX_DRAFT_IMAGES_COUNT } from "./constants";
import { useTriggerFormChanges } from "../../hooks/useDetectDirtyForm";

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-drag";
import { useField } from "formik";
import ErrorMessageDefault from "../../../../base/components/ErrorMessageDefault";

const BOX_WIDTH = 200;
const BOX_MARGIN_RIGHT = 25;
const BOX_FULL_WIDTH = BOX_WIDTH + BOX_MARGIN_RIGHT;

const useResize = (callback) => {
  useEffect(() => {
    callback();

    window.addEventListener("resize", callback);
    return () => window.removeEventListener("resize", callback);
  }, [callback]);
};

const useCalcGridOptions = (containerRef, countImages) => {
  const [boxesPerRow, updateBoxesPerRow] = useState(4);

  useResize(
    useCallback(() => {
      const width = containerRef.current?.clientWidth;
      const _boxesPerRow = parseInt(width / BOX_FULL_WIDTH, 10);
      updateBoxesPerRow(_boxesPerRow);
    }, [containerRef])
  );

  const countItems = countImages + 1;

  const countColumns =
    parseInt(countItems / boxesPerRow, 10) + (countItems % boxesPerRow ? 1 : 0);

  const dropzoneStyle = useMemo(
    () => ({
      height: `${countColumns * BOX_FULL_WIDTH}px`,
      width: `${boxesPerRow * BOX_FULL_WIDTH}px`,
    }),
    [countColumns, boxesPerRow]
  );

  const loadImagesStyle = useMemo(
    () => ({
      width: `${BOX_WIDTH}px`,
      position: "absolute",
      top: 0,
      left: 0,
      transition: `transform 0.5s ease 0s`,
      transform: `translate(${
        (countImages % boxesPerRow) * BOX_FULL_WIDTH
      }px, ${countColumns * BOX_FULL_WIDTH - BOX_FULL_WIDTH}px)`,
    }),
    [countColumns, countImages, boxesPerRow]
  );

  const gridItemStyle = useMemo(
    () => ({
      marginRight: `${BOX_MARGIN_RIGHT}px`,
      width: `${BOX_WIDTH}px`,
      height: `${BOX_WIDTH}px`,
    }),
    []
  );

  return { boxesPerRow, dropzoneStyle, loadImagesStyle, gridItemStyle };
};

const DraftImages = ({
  selectedImages = [],
  onOpenUploadModal,
  onEditImage,
  onDelete,
  setOrderedImages,
  maxImagesCount = MAX_DRAFT_IMAGES_COUNT,
  description = "",
}) => {
  const { markChanges, containerRef } = useTriggerFormChanges();
  const [productImages, setProductImages] = useState(selectedImages);
  const hasImages = !!productImages.length;

  useEffect(() => {
    setProductImages(selectedImages);
  }, [selectedImages]);

  function onChange(sourceId, sourceIndex, targetIndex, targetId) {
    const nextState = swap(productImages, sourceIndex, targetIndex);
    setProductImages(nextState);
    markChanges();
    setOrderedImages(nextState);
  }

  const { boxesPerRow, dropzoneStyle, gridItemStyle, loadImagesStyle } =
    useCalcGridOptions(containerRef, productImages.length);
  const [, { error, touched }, { setTouched }] = useField({ name: "files" });

  useEffect(() => {
    if (hasImages) {
      setTouched(true);
    }
  }, [hasImages]);

  return (
    <div ref={containerRef}>
      <GridContextProvider onChange={onChange}>
        <GridDropZone
          id="items"
          boxesPerRow={boxesPerRow}
          rowHeight={BOX_FULL_WIDTH}
          style={dropzoneStyle}
        >
          {productImages.map((image, index) => (
            <GridItem key={image.preview} style={gridItemStyle}>
              <div>
                <Image
                  path={image.preview}
                  onDelete={() => {
                    onDelete(index);
                    markChanges();
                  }}
                  onEdit={() => {
                    onEditImage(index);
                    markChanges();
                  }}
                  wrapperClassName={"draft-wrapper-image"}
                  key={index}
                />
              </div>
            </GridItem>
          ))}
          <>
            <div style={loadImagesStyle} className={"drop-zone-draft-card"}>
              {selectedImages.length < maxImagesCount && (
                <UploaderButton
                  onClick={() => {
                    onOpenUploadModal();
                    markChanges();
                  }}
                  description={description}
                >
                  Upload images
                </UploaderButton>
              )}
            </div>
          </>
        </GridDropZone>
      </GridContextProvider>

      <input className={"is-invalid d-none"} />
      <ErrorMessageDefault>
        {touched &&
          error &&
          productImages.length === 0 &&
          "Please select at least 1 image"}
        {touched &&
          error &&
          productImages.length > maxImagesCount &&
          `Please select a maximum of ${maxImagesCount} images`}
      </ErrorMessageDefault>
    </div>
  );
};

DraftImages.propTypes = {
  selectedImages: PropTypes.array,
  onOpenUploadModal: PropTypes.func,
  onEditImage: PropTypes.func,
  onDelete: PropTypes.func,
  maxImagesCount: PropTypes.number,
};

export default DraftImages;
