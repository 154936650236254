import React, { useCallback, useMemo, useState } from "react";
import Bugsnag from "@bugsnag/js";

import CustomButton from "../../../base/components/CustomButton";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../base/components/CustomModal";
import { PRODUCT_TYPE } from "../../../base/constants/product";
import { KEY_USER } from "../../../base/constants/storage";
import { DRAFT } from "../../../base/constants/draft";

import { useLoading } from "../../../base/hooks/useLoading";
import { useService } from "../../../base/hooks/useService";
import { useTotalProducts } from "../../drafts/hooks/useProduct";

import ProductReturnsService from "../../../services/ProductReturnsService";
import StorageService from "../../../services/StorageService";

import { isDisabledDraft } from "../../drafts/helpers/isDisabledDraft";

const defaultModalConfig = {
  title: "Relist item",
  message: "Do you want to relist the chosen item?",
  closeBtn: "No",
  confirmBtn: "Yes",
};

export const useRelistReturnProduct = ({
  afterRelist = () => {},
  modalConfig = defaultModalConfig,
}) => {
  const [loading, { registerPromise }] = useLoading();

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [returnId, setReturnId] = useState(null);

  const onOpenModal = useCallback(
    (id) => {
      setIsOpenConfirmModal(true);
      setReturnId(id);
    },
    [setIsOpenConfirmModal, setReturnId]
  );

  const onCloseModal = useCallback(() => {
    setIsOpenConfirmModal(false);
  }, [setIsOpenConfirmModal]);

  /**
   * @type {ProductReturnsService}
   */
  const productReturnsService = useService(ProductReturnsService);

  const onRelist = useCallback(() => {
    registerPromise(productReturnsService.relistReturnProduct(returnId))
      .then(({ data }) => {
        afterRelist(data);
      })
      .catch((e) => Bugsnag.notify(e));
  }, [registerPromise, productReturnsService, afterRelist, returnId]);

  const modalView = (
    <CustomModal isOpen={isOpenConfirmModal} onClose={onCloseModal}>
      <ModalHeader onClose={onCloseModal}>{modalConfig.title}</ModalHeader>
      <ModalBody>
        <p className="md-black">{modalConfig.message}</p>
      </ModalBody>
      <ModalFooter>
        <CustomButton onClick={onCloseModal} className="outline-primary ">
          {modalConfig.closeBtn}
        </CustomButton>
        <CustomButton
          className="filled-primary"
          onClick={onRelist}
          disabled={loading}
        >
          {modalConfig.confirmBtn}
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );

  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);
  const { activeSubscription } = storage.get(KEY_USER, {});
  const [{ total }] = useTotalProducts(PRODUCT_TYPE.DRAFT);

  const isDisabled = useMemo(() => {
    if (!activeSubscription) return false;

    const { type } = activeSubscription;
    return isDisabledDraft(type, total, DRAFT);
  }, [total, activeSubscription]);

  return [onOpenModal, modalView, isDisabled];
};
