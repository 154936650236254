import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";

import CustomButton from "../../../../base/components/CustomButton";
import Counter from "../../../../base/components/Counter/Counter";
import { useTimer } from "../../../../base/hooks/useTimer";
import { RESEND_ACCESS_CODE_SECONDS } from "../../../../base/constants/shared";

const SendCodeButton = ({
  onClick,
  disabled,
  className,
  isSendedCode,
  isShowCounter = true,
  isErrors = null,
}) => {
  const { seconds, isActive, onStart, onCancel } = useTimer({
    initialSeconds: RESEND_ACCESS_CODE_SECONDS,
  });

  const handleSendCode = useCallback(async () => {
    await onClick();
    onStart();
  }, [onClick, onStart]);

  useEffect(() => {
    if (isErrors) onCancel();
  }, [isErrors, onCancel]);

  const isDisabledButton = useMemo(() => {
    return disabled || isActive || !isShowCounter;
  }, [disabled, isActive, isShowCounter]);

  return (
    <div className={className}>
      <CustomButton
        className="outline-primary w-130 mr-3"
        onClick={handleSendCode}
        disabled={isDisabledButton}
      >
        {isSendedCode ? "Resend code" : "Send code"}
      </CustomButton>
      {isShowCounter && isActive && <Counter seconds={seconds} />}
    </div>
  );
};

SendCodeButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isSendedCode: PropTypes.bool,
  isShowCounter: PropTypes.bool,
  isErrors: PropTypes.string,
};

export default SendCodeButton;
