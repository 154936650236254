import moment from "moment";
import React from "react";
import DraftUtils from "../utils/DraftUtils";
import { getCurrentDate } from "./getCurrentDate";

export const DATE_FORMAT = "MM/DD/YYYY";

export const getFormattedDate = (date, pattern = DATE_FORMAT) => {
  return moment(date).format(pattern);
};

export const FormatDate = (date) => {
  return <span className={"table-cell-date"}>{getFormattedDate(date)}</span>;
};

export const FormatDateSorting = ({date}) => {

  return  <span className={"table-cell-date"}>{date ? getFormattedDate(date) : "-"}</span> ;
};

export const FormatPrice = (price) => {
  return <>{DraftUtils.formatPrice(price || 0)}</>;
};

export const getActiveStatus = (expirationDate) => {
  if (!expirationDate) return false;
  return moment(getFormattedDate(expirationDate)).isAfter(getCurrentDate());
};
