import ExtensionService from "../Extension";
import { EXTENSION_EVENTS, MARKETPLACES } from "../../base/constants/extension";

class ImportEbay extends ExtensionService {
  constructor() {
    super(MARKETPLACES.EBAY);
  }

  static $displayName = "ImportEbay";

  importSoldProducts(tabId, pagination, timePeriod) {
    return this.request(EXTENSION_EVENTS.IMPORT_MODAL_GET_SOLD_PRODUCTS, {
      params: {
        marketplaceTabId: tabId,
        pagination,
        timePeriod,
      },
    });
  }
}

export default ImportEbay;
