import Http from "./Http";

class CreditCardService extends Http {
	static $displayName = "CreditCardService";

  addCard(values) {
    return this.put('/cards', values);
  }

  getCard(){
    return this.get('/cards');
  }
}

export default CreditCardService;