import { DOMAIN_PATTERN } from "../../../base/constants/patterns";

const mappedLinks = {
  'youtu.be': (link) => {
    return link.replace('youtu.be', 'www.youtube.com/embed')
  },
}

export const getFormattedLink = (link) => {
  if (!link) return link;
  const domain = link.match(DOMAIN_PATTERN)[0];

  return mappedLinks[domain] 
    ? mappedLinks[domain](link) 
    : link.replace(domain, domain + '/embed');
};