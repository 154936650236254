/* eslint-disable react/prop-types */
import { Tooltip } from "reactstrap";
import React, { useRef } from "react";

import {
  ORDER_BY,
  ORDER_BY_LABEL,
  ORDER_TYPE_LABEL,
  ORDER_BY_DATE_LISTED_NAME,
  ORDER_BY_DATE_IMPORTED_NAME,
  SortingOptions,
} from "../../constants";
import {
  parseQuery,
  useLocationQuery,
} from "../../../../base/hooks/useQueryString";
import Icon from "../../../../base/components/Icon";
import "./index.scss";
import IconButton from "../IconButton";
import { useTooltipProps } from "../../../sold/pages/SoldList/table";
import FilterSortingDropdown from "../../../../base/components/FilterSortingDropdown";

const DESC_SORT = ORDER_BY.desc;
const ASC_SORT = ORDER_BY.asc;

const sortByDateImportMessage =
  'The items are currently displayed by the Date imported. To view when an item was listed on a marketplace, select "Date listed" from the dropdown menu.';
const sortByDateListedMessage =
  'The items are currently displayed by the Date listed. To view when an item was imported into Fearn,\n select "Date imported" from the dropdown menu.';

const InfoIcon = ({ orderBy }) => {
  const ref = useRef();

  return (
    <div className="info-icon">
      <div>
        <IconButton ref={ref}>
          <Icon icon={"infoIcon"} />
        </IconButton>
      </div>
      <Tooltip placement="top" {...useTooltipProps(ref)}>
        {orderBy === ORDER_BY_DATE_IMPORTED_NAME
          ? sortByDateImportMessage
          : sortByDateListedMessage}
      </Tooltip>
    </div>
  );
};

const SortButton = ({ iconType, orderType = "", orderBy = "" }) => {
  const { merge } = useLocationQuery();

  const handleSortingButtonClick = () => {
    if (
      orderBy !== ORDER_BY_DATE_LISTED_NAME &&
      orderBy !== ORDER_BY_DATE_IMPORTED_NAME
    ) {
      merge(ORDER_BY_LABEL, ORDER_BY_DATE_LISTED_NAME);
    }
    orderType === DESC_SORT || orderType === ""
      ? merge(ORDER_TYPE_LABEL, ASC_SORT)
      : merge(ORDER_TYPE_LABEL, DESC_SORT);
  };

  return (
    <button
      className={"sort-inventory-button"}
      onClick={handleSortingButtonClick}
    >
      <Icon icon={iconType} className="table-sort-icon date-sort-icon" />
    </button>
  );
};

const DateListingHeaderFormatter = ({ onClick }) => {
  const { orderBy = "", orderType = "" } = parseQuery(location.search);

  const getSortIcon = () => {
    if (
      orderBy === ORDER_BY_DATE_LISTED_NAME ||
      orderBy === ORDER_BY_DATE_IMPORTED_NAME ||
      orderBy === ""
    ) {
      return orderType === ASC_SORT ? "tableSortAsc" : "tableSortDesc";
    }
    return "tableSortDefault";
  };

  const iconType = getSortIcon();

  return (
    <div className={"data-list-container"}>
      <InfoIcon orderBy={orderBy} />
      <FilterSortingDropdown
        withAllOption
        filterName={ORDER_BY_LABEL}
        defaultItem={SortingOptions[0]}
        filterOptions={SortingOptions}
      />
      <SortButton
        iconType={iconType}
        orderType={orderType}
        orderBy={orderBy}
        onClick={onClick}
      />
    </div>
  );
};

export default DateListingHeaderFormatter;
