import { useEffect } from "react";
import PropTypes from "prop-types"

import webSocketService from '../../services/WebSocketService';

export const useSocketIo = ({ events }) => {
  useEffect(() => {
    if (!webSocketService.connectionCounter) {
      webSocketService.open();
    }

    webSocketService.incrementCounter();

    events.forEach(event => {
      webSocketService.socket.on(event.event, ({ data }) => {
        event.handleEvent(data)
      });
    })

    return () => {
      webSocketService.decremenCounter();
      if (!webSocketService.connectionCounter) {
        webSocketService.close();
      }     
    };
    
  }, [events]);
};

useSocketIo.propTypes = {
  events: PropTypes.array,
}