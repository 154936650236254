import { useCallback, useMemo } from "react";
import moment from "moment";

import { useFormatBookkeepingDataForMapping } from "./useFormatBookkeepingDataForMapping";
import { getCroppedDecimalPart } from "../../../helpers/getCroppedDecimalPart";

import {
  BOOKKEEPING_TABLE_TITLES_MAP,
  EMPTY_VALUE,
  TABLES_ITEMS_WHO_DISABLED_WHEN_IS_CASH,
  TABLES_TYPES,
  TABLES_TYPES_LIST_WITHOUT_MAPPING_TABLE_ITEMS,
  TABLE_TYPES_TITLES,
} from "../constants";
import {
  BOOKKEEPING_DATE_FORMAT,
  BOOKKEEPING_INTERVAL_BY_MONTH_FORMAT,
  BOOKKEEPING_INTERVAL_CURRENT_QUARTER_END_DATE_FORMAT,
  BOOKKEEPING_INTERVAL_CURRENT_QUARTER_START_DATE_FORMAT,
  BOOKKEEPING_INTERVAL_END_DATE_FORMAT,
  BOOKKEEPING_INTERVAL_START_DATE_FORMAT,
  DEFAULT_DECIMAL_PART_LIMIT,
  MOMENT_MONTH,
  MOMENT_QUARTER,
  MOMENT_YEAR,
} from "../../../constants";

const getIntervalDateTitle = ({ year, month, quarter }) => {
  if (!year) return "";

  if (!!year && !!month) {
    return moment()
      .set({ year, month: month - 1 })
      .startOf(MOMENT_MONTH)
      .format(BOOKKEEPING_INTERVAL_BY_MONTH_FORMAT);
  }

  if (!!year && !!quarter) {
    const isCurrentQuarter =
      moment().quarter() === moment().set({ year, quarter }).quarter();

    const startDate = moment()
      .set({ year, quarter })
      .startOf(MOMENT_QUARTER)
      .format(
        isCurrentQuarter
          ? BOOKKEEPING_INTERVAL_CURRENT_QUARTER_START_DATE_FORMAT
          : BOOKKEEPING_INTERVAL_START_DATE_FORMAT
      );

    const endDate = isCurrentQuarter
      ? moment().format(BOOKKEEPING_INTERVAL_CURRENT_QUARTER_END_DATE_FORMAT)
      : moment()
          .set({ year, quarter })
          .endOf(MOMENT_QUARTER)
          .format(BOOKKEEPING_INTERVAL_END_DATE_FORMAT);

    return `${startDate} - ${endDate}`;
  }

  if (!!year && !month && !quarter) {
    const startDate = moment()
      .set({ year })
      .startOf(MOMENT_YEAR)
      .format(BOOKKEEPING_INTERVAL_CURRENT_QUARTER_START_DATE_FORMAT);

    const endDate = moment()
      .set({ year })
      .endOf(MOMENT_YEAR)
      .format(BOOKKEEPING_DATE_FORMAT);

    return `${startDate} - ${endDate}`;
  }
};

export const concatValueWithDollarChar = (value) => {
  if (!value) return EMPTY_VALUE;
  const prefix = value >= 0 ? "$" : "-$";
  return (
    prefix + getCroppedDecimalPart(Math.abs(value), DEFAULT_DECIMAL_PART_LIMIT)
  );
};

export const useBookkeepingTable = ({
  bookkeepingData,
  isViewTotalsOnly,
  isCash,
}) => {
  const [formattedTotalDataForMapping, formattedIntervalDataForMapping] =
    useFormatBookkeepingDataForMapping({ bookkeepingData, isCash });

  const formatFullTableHeader = (table) => {
    const intervalTitlesList = (table?.intervals || []).map(
      (interval) => interval.intervalDateRangeTitle
    );

    return [
      { values: [table.title], className: "title" },
      { values: intervalTitlesList, className: "intervalPeriod" },
      { values: ["Total"], className: "total" },
    ];
  };

  const formatFullTableBody = useCallback(
    (table) => {
      return (table?.itemTotals || []).map((bodyItem, index) => {
        const bodyItemIntervalValues = (table?.intervals || []).map(
          (interval) => interval?.intervalItems?.[index]?.value
        );

        if (
          isCash &&
          TABLES_ITEMS_WHO_DISABLED_WHEN_IS_CASH.includes(bodyItem.title)
        )
          return;

        return [
          { values: [bodyItem.title], className: "itemTitle" },
          { values: bodyItemIntervalValues, className: "itemValue" },
          { values: [bodyItem.value], className: "itemValue" },
        ];
      });
    },
    [isCash]
  );

  const formatFullTableFooter = (table) => {
    const intervalTotalValuesList = (table?.intervals || []).map((interval) =>
      concatValueWithDollarChar(interval.intervalTotal)
    );
    return [
      { values: [table.total.title], className: "totalTitle" },
      { values: intervalTotalValuesList, className: "totalValue" },
      {
        values: [concatValueWithDollarChar(table.total.value)],
        className: "totalValue",
      },
    ];
  };

  const formatCutTableHeader = (table) => {
    const intervalTotalValuesList = (table?.intervals || []).map((interval) =>
      concatValueWithDollarChar(interval.intervalTotal)
    );
    return [
      { values: [table.title], className: "title" },
      { values: intervalTotalValuesList, className: "total" },
      {
        values: [concatValueWithDollarChar(table.total.value)],
        className: "total",
      },
    ];
  };

  const formatHorizontalIntervalMethodsMap = useMemo(
    () => ({
      [TABLE_TYPES_TITLES.revenues]: {
        formatHeader: formatFullTableHeader,
        formatBody: formatFullTableBody,
        formatFooter: formatFullTableFooter,
      },
      [TABLE_TYPES_TITLES.costOfRevenues]: {
        formatHeader: formatFullTableHeader,
        formatBody: formatFullTableBody,
        formatFooter: formatFullTableFooter,
      },
      [TABLE_TYPES_TITLES.grossProfit]: {
        formatHeader: formatCutTableHeader,
        formatBody: () => [],
        formatFooter: () => null,
      },
      [TABLE_TYPES_TITLES.expenses]: {
        formatHeader: formatFullTableHeader,
        formatBody: formatFullTableBody,
        formatFooter: formatFullTableFooter,
      },
      [TABLE_TYPES_TITLES.netProfit]: {
        formatHeader: formatCutTableHeader,
        formatBody: () => [],
        formatFooter: () => null,
      },
    }),
    [formatFullTableBody]
  );

  const onMapTotalOnly = useCallback(() => {
    return TABLES_TYPES.map((tableType) => {
      const tableTypeName = tableType[0];
      const tableTotalName = tableType[1];

      const tableItemsData = formattedTotalDataForMapping?.[tableTypeName];
      const mainTitle =
        BOOKKEEPING_TABLE_TITLES_MAP?.[tableTypeName]?.mainTitle;
      const itemTitles = Object.entries(
        BOOKKEEPING_TABLE_TITLES_MAP?.[tableTypeName]?.itemTitles
      );
      const totalTitle =
        BOOKKEEPING_TABLE_TITLES_MAP?.[tableTypeName]?.totalTitle;

      const displayTableItemsWithoutMapping =
        TABLES_TYPES_LIST_WITHOUT_MAPPING_TABLE_ITEMS.includes(tableTypeName);

      return {
        title: mainTitle,
        itemTotals: displayTableItemsWithoutMapping
          ? tableItemsData
          : itemTitles.map((itemTitle) => {
              const selectedTableItem = tableItemsData?.find(
                (item) => item.title.toString() === itemTitle[0]
              );

              if (
                isCash &&
                TABLES_ITEMS_WHO_DISABLED_WHEN_IS_CASH.includes(itemTitle[1])
              )
                return;

              return {
                title: itemTitle[1],
                value: concatValueWithDollarChar(selectedTableItem?.value),
              };
            }),
        total: {
          title: totalTitle,
          value: formattedTotalDataForMapping?.[tableTotalName],
        },
      };
    });
  }, [formattedTotalDataForMapping, isCash]);

  const onMapFullData = useCallback(() => {
    const verticalMappedData = TABLES_TYPES.map((tableType) => {
      const tableTypeName = tableType[0];
      const tableTotalName = tableType[1];

      const tableItemsDataByIntervals =
        formattedIntervalDataForMapping?.[tableTypeName];
      const tableItemsData = formattedTotalDataForMapping?.[tableTypeName];
      const mainTitle =
        BOOKKEEPING_TABLE_TITLES_MAP?.[tableTypeName]?.mainTitle;
      const itemTitles = Object.entries(
        BOOKKEEPING_TABLE_TITLES_MAP?.[tableTypeName]?.itemTitles
      );
      const totalTitle =
        BOOKKEEPING_TABLE_TITLES_MAP?.[tableTypeName]?.totalTitle;

      const displayTableItemsWithoutDefaultValues =
        TABLES_TYPES_LIST_WITHOUT_MAPPING_TABLE_ITEMS.includes(tableTypeName);

      return {
        title: mainTitle,
        intervals: tableItemsDataByIntervals?.map((interval) => {
          return {
            intervalDateRangeTitle: getIntervalDateTitle(interval.dateRange),
            intervalItems: displayTableItemsWithoutDefaultValues
              ? tableItemsData.map((tableItem) => {
                  const selectedInterval = interval?.items.find(
                    (intervalItem) => intervalItem?.title === tableItem?.title
                  );

                  return {
                    title: tableItem?.title,
                    value: concatValueWithDollarChar(selectedInterval?.value),
                  };
                })
              : itemTitles.map((itemTitle) => {
                  const selectedTableItem = interval?.items?.find(
                    (item) => item.title.toString() === itemTitle[0]
                  );

                  return {
                    title: itemTitle[1],
                    value: concatValueWithDollarChar(selectedTableItem?.value),
                  };
                }),
            intervalTotal: interval.intervalTotal,
          };
        }),
        itemTotals: displayTableItemsWithoutDefaultValues
          ? tableItemsData
          : itemTitles.map((itemTitle) => {
              const selectedTableItem = tableItemsData?.find(
                (item) => item.title.toString() === itemTitle[0]
              );
              return {
                title: itemTitle[1],
                value: concatValueWithDollarChar(selectedTableItem?.value),
              };
            }),
        total: {
          title: totalTitle,
          value: formattedTotalDataForMapping?.[tableTotalName],
        },
      };
    });

    return verticalMappedData.map((table) => {
      const { formatHeader, formatBody, formatFooter } =
        formatHorizontalIntervalMethodsMap[table.title];

      return {
        header: formatHeader(table),
        body: formatBody(table),
        footer: formatFooter(table),
      };
    });
  }, [
    formattedIntervalDataForMapping,
    formattedTotalDataForMapping,
    formatHorizontalIntervalMethodsMap,
  ]);

  const onMapTableData = isViewTotalsOnly ? onMapTotalOnly : onMapFullData;

  return [onMapTableData()];
};
