import * as yup from "yup";

import { USER_PASSWORD } from "../../../../validation/user";

export const validationSchema = yup.object().shape({
  password: USER_PASSWORD.required(),
  repeatPassword: USER_PASSWORD.required().test(
    "passwords-match",
    "Passwords must match",
    function (value) {
      return this.parent.password === value;
    }
  ),
});

export const initialValues = {
  password: "",
  repeatPassword: "",
};
