import { ReactComponent as DashboardLogo } from "../../assets/images/sidebar/icon-sidebar-dashboard.svg";
import { ReactComponent as DraftsLogo } from "../../assets/images/sidebar/icon-sidebar-drafts.svg";
import { ReactComponent as InventoryLogo } from "../../assets/images/sidebar/icon-sidebar-inventory.svg";
import { ReactComponent as SoldLogo } from "../../assets/images/sidebar/icon-sidebar-sold.svg";
import { ReactComponent as ReturnLogo } from "../../assets/images/sidebar/icon-sidebar-returns.svg";
import { ReactComponent as ShippingLogo } from "../../assets/images/sidebar/icon-sidebar-shipping.svg";
import { ReactComponent as TaskManagementLogo } from "../../assets/images/sidebar/icon-sidebar-task-management.svg";
import { ReactComponent as AnalyticsLogo } from "../../assets/images/sidebar/icon-sidebar-analytics.svg";
import { ReactComponent as ExpensesLogo } from "../../assets/images/sidebar/icon-sidebar-bookkeeping-expenses.svg";
import { ReactComponent as BookKeepingLogo } from "../../assets/images/sidebar/icon-sidebar-bookkeeping-finance.svg";
import { ReactComponent as HelpLogo } from "../../assets/images/sidebar/icon-sidebar-help.svg";
import { ANALYTICS_GROUP_LINKS } from "../analytics/config";
import { EXPENSES_GROUP_LINKS } from "../expenses/config";

export const BUSINESS_TITLE = "Business";

export const EMPLOYEE_RESTRICTED_ROUTES = {
  [ANALYTICS_GROUP_LINKS.BASE]: "/app/analytics",
  [EXPENSES_GROUP_LINKS.BASE]: "/app/expenses",
  "/app/bookkeeping": "/app/bookkeeping",
}

export const sidebarSchema = [
  {
    isTitle: true,
    label: "General",
  },
  {
    label: "Dashboard",
    linkTo: "/app/dashboard",
    icon: DashboardLogo,
  },
  {
    label: "Drafts",
    linkTo: "/app/drafts",
    icon: DraftsLogo,
  },
  {
    label: "Inventory",
    linkTo: "/app/inventories",
    icon: InventoryLogo,
  },
  {
    label: "Sold",
    linkTo: "/app/sold-list",
    icon: SoldLogo,
  },
  {
    isTitle: true,
    label: "Operations",
  },
  {
    label: "Shipping",
    linkTo: "/app/shipping",
    icon: ShippingLogo,
  },
  {
    label: "Returns",
    linkTo: "/app/returns",
    icon: ReturnLogo,
  },
  {
    label: "Task management",
    linkTo: "/app/task-management",
    icon: TaskManagementLogo,
  },
  {
    isTitle: true,
    label: BUSINESS_TITLE,
  },
  {
    label: "Analytics",
    linkTo: "/app/analytics",
    icon: AnalyticsLogo,
  },
  {
    label: "Expenses",
    linkTo: "/app/expenses",
    icon: ExpensesLogo,
  },
  {
    label: "Bookkeeping",
    linkTo: "/app/bookkeeping",
    icon: BookKeepingLogo,
  },
  {
    label: "Help",
    linkTo: "/app/help",
    icon: HelpLogo,
  },
];
