export const selectStyles = {
  
  option: (provided, {isSelected, isFocused}) => {
    return ({
      ...provided,
      padding: 8,
      backgroundColor: isFocused
        ? '#eff6f2'
        : null,
      paddingLeft: 12,
      paddingRight: 12,
      fontSize: 13,
      color: "#495057",
      borderRadius: 10,
      fontWeight: 500,
      cursor: 'pointer',
      "&:hover": {
        backgroundColor: "#eff6f2",
      }
    })
  },

  control: () => ({
    width: 400,
  }),

  menuList: (provided, state) => {
    return {
      ...provided,
      paddingLeft: 12,
      paddingRight: 12,
      position: "static",

      "&::-webkit-scrollbar": {
        width: '.3125rem',
      },

      "&::-webkit-scrollbar-track": {
        boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.1)',
      },

      "&::-webkit-scrollbar-thumb": {
        backgroundColor: '#2f665c',
        borderRadius: '5px',
      }
    }
  },

  menu: (provided, state) => {
    return {
      ...provided,
      boxShadow: "none",
      position: "static",
    }
  },

  menuPortal: (provided, state) => {
    return {...provided, position: "static"}
  },

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return {...provided, opacity, transition};
  }
};