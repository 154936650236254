import React, {useCallback} from 'react';
import PropTypes from "prop-types";
import { Col, Row } from 'reactstrap';

import { ReactComponent as Delete } from "../../../../pages/drafts/components/Icons/Delete.svg";
import { ReactComponent as Edit } from "../../../../assets/images/icon-16-edit-green.svg";
import {useCreateEditReturn} from "../../hooks/useCreateEditReturn";
import {useDeleteReturn} from "../../hooks/useDeleteReturn";

import "./index.scss";

const ReturnCardTitle = ({ title, props, onRefresh, updateTabs }) => {
    const {viewCreateEditReturn, onShowModal} = useCreateEditReturn(true, onRefresh)
    const {viewDeleteReturn, onDelete} = useDeleteReturn({...props}, onRefresh)

    const handleUpdate = useCallback((oldData, newData) => {
        if (newData.status === oldData.status) return;
        updateTabs(prevState => {
            prevState[oldData.status - 1].count =  prevState[oldData.status - 1].count - 1;

            return [...prevState]
        })
    }, [updateTabs])

  return (
    <Row className='return-card-header'>
        {viewCreateEditReturn}
        {viewDeleteReturn}
      <Col sm='9'>
        <h3 className='bold-black mb-0'>
          {title}
        </h3>
      </Col>

      <Col className='d-flex justify-content-end' sm='3'>
        <Edit
          className='mr-3 cursor-pointer'
          onClick={() => {
              const initialData = {title: title, ...props}
              onShowModal(initialData, (newData) => handleUpdate(initialData, newData))
          }}
        />
        <Delete
          className='cursor-pointer'
          onClick={() => onDelete(props.id)}
        />
      </Col>
    </Row>
  )
};

ReturnCardTitle.propTypes = {
  title: PropTypes.string
}

export default ReturnCardTitle;