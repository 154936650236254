import React, { useCallback, useRef, useState } from "react";
import CustomButton from "../../../../base/components/CustomButton";
import { useStatelessProvider } from "../../../../base/hooks/useProvider";
import IconButton from "../../../drafts/components/IconButton";
import ProductPreview from "../../../drafts/components/ProductPreview";
import WithHighlight from "../../../drafts/components/WithHighlight";
import { TableContext } from "../../../drafts/contexts/table";
import { FormatPrice, getFormattedDate } from "../../../drafts/helpers/format";
import MarketplaceCell from "../../../inventory/components/MarketplaceCell";
import { ReactComponent as Delete } from "../../../drafts/components/Icons/Delete.svg";

import styles from "./index.module.scss";
import { Tooltip } from "reactstrap";
import {
  MARKETPLACE_SPECIFIC_FIELDS,
  MARKETPLACE_TYPES,
} from "../../constants";
import Icon from "../../../../base/components/Icon";
import HeaderFormatter from "../../../drafts/components/Table/HeaderFormatter";
import { MARKETPLACE_OTHER_TYPE } from "../../../inventory/constants/importInventory";

export const IsAcceptCopy = ({ children }) => {
  const [{ disableActivate }] = useStatelessProvider(TableContext);

  return <>{!disableActivate && children}</>;
};

export const useTooltipProps = (ref) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return {
    isOpen: tooltipOpen,
    target: ref,
    toggle,
  };
};

export const ActionCopy = ({ soldId }) => {
  const ref = useRef();
  const [{ onCopySold }] = useStatelessProvider(TableContext);

  const handleCopy = useCallback(() => {
    onCopySold(soldId);
  }, [soldId, onCopySold]);

  return (
    <>
      <IconButton
        className={styles.actionIconButton}
        ref={ref}
        onClick={handleCopy}
      >
        <Icon icon="copyIcon" />
      </IconButton>
      <Tooltip placement="top" {...useTooltipProps(ref)}>
        Copy
      </Tooltip>
    </>
  );
};

export const ActionDelete = ({ productId }) => {
  const ref = useRef();
  const [{ onDelete }] = useStatelessProvider(TableContext);

  const handleDelete = useCallback(() => {
    onDelete(productId);
  }, [productId, onDelete]);

  return (
    <>
      <IconButton
        className={styles.actionIconButton}
        ref={ref}
        onClick={handleDelete}
      >
        <Delete />
      </IconButton>
      <Tooltip placement="top" {...useTooltipProps(ref)}>
        Delete
      </Tooltip>
    </>
  );
};

export const ActionReactivate = ({ id, productId }) => {
  const [{ onReactivate, disableActivate }] =
    useStatelessProvider(TableContext);

  const handleReactivate = useCallback(() => {
    onReactivate(id, productId);
  }, [id, productId, onReactivate]);

  return (
    <CustomButton
      className="filled-sm"
      onClick={handleReactivate}
      disabled={disableActivate}
    >
      Activate
    </CustomButton>
  );
};

export const ActionReturn = ({ productId }) => {
  const [{ onReturnProduct }] = useStatelessProvider(TableContext);

  const handleReturn = useCallback(() => {
    onReturnProduct(productId);
  }, [productId, onReturnProduct]);

  return (
    <CustomButton className="outline-primary-sm" onClick={handleReturn}>
      Returned
    </CustomButton>
  );
};

export function TableStructure() {
  return [
    {
      text: "Title",
      style: {
        width: "31%",
      },

      formatter: (_, model) => {
        const titleValue = model.marketplaceType == MARKETPLACE_OTHER_TYPE ?  model.product?.title : model.product?.[MARKETPLACE_SPECIFIC_FIELDS[model.marketplaceType]]?.title;
        return (
          <div className={"drafts-table-cell-title"}>
            <ProductPreview src={model.product.files[0]?.link} />
            <WithHighlight text={titleValue || "-"} className={styles.title} />
          </div>
        );
      },
      sort: false,
      dataField: "title",
    },
    {
      sort: false,
      text: "SKU",
      formatter: (_, model) => {
        const skuValue = model.marketplaceType == MARKETPLACE_OTHER_TYPE ? model.product?.sku : model.product?.[MARKETPLACE_SPECIFIC_FIELDS[model.marketplaceType]]?.sku;
        return (
          <div className={styles.tableTitleWrapper}>
            <WithHighlight
              text={skuValue || "-"}
              exact
              className={styles.title}
            />
          </div>
        );
      },
      dataField: "sku",
    },
    {
      text: "Marketplace",
      dataField: "updatedAt",
      formatter: (_, { marketplaceType }) => {
        return (
          <MarketplaceCell key={MARKETPLACE_TYPES[marketplaceType]}>
            {MARKETPLACE_TYPES[marketplaceType]}
          </MarketplaceCell>
        );
      },
    },
    {
      dataField: "dateSold",
      sort: true,
      style: {
        width: "15.5%",
      },
      text: "Date sold",
      headerFormatter: HeaderFormatter,
      formatter: (_, { dateSold }) => (
        <span className="table-cell-date">{getFormattedDate(dateSold)}</span>
      ),
    },
    {
      dataField: "priceSold",
      sort: true,
      headerFormatter: HeaderFormatter,
      formatter: FormatPrice,
      text: "Sold Price",
      style: {
        fontFamily: "Poppins",
        fontWeight: "600",
        lineHeight: "20px",
      },
    },
    {
      dataField: "id",
      text: "Actions",
      headerAlign: "right",
      align: "right",
      style: {
        width: "14%",
      },
      formatter: (_, { id, product }) => {
        return (
          <div
            className={styles.tableActionsWrapper}
            onClick={(event) => event.stopPropagation()}
          >
            <IsAcceptCopy>
              <ActionCopy soldId={id} />
            </IsAcceptCopy>
            <ActionReturn productId={product?.id} />
            <ActionReactivate id={id} productId={product?.id} />
            <ActionDelete productId={product?.id} />
          </div>
        );
      },
    },
  ];
}
