import { EXTENSION_EVENTS, MARKETPLACES } from "../../base/constants/extension";
import MercariExtension from "../MercariExtension";

class ImportMercari extends MercariExtension {
  constructor() {
    super(MARKETPLACES.MERKARI);
  }

  static $displayName = "ImportMercari";

  importActiveProducts(tabId, pagination) {
    return this.request(EXTENSION_EVENTS.IMPORT_MODAL_GET_ACTIVE_PRODUCTS, {
      params: { marketplaceTabId: tabId, pagination },
      token: this.authToken,
    });
  }

  importSoldProducts(tabId, pagination) {
    return this.request(EXTENSION_EVENTS.IMPORT_MODAL_GET_SOLD_PRODUCTS, {
      params: {
        marketplaceTabId: tabId,
        pagination,
      },
      token: this.authToken,
    });
  }
}

export default ImportMercari;
