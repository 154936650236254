import {useCallback} from "react";

let tasks = [];

window.onbeforeunload = function () {
	return tasks.find(check => check());
};

export const useBeforeunload = () => {
	return useCallback((task) => {
		tasks.push(task);

		return () => {
			tasks = tasks.filter(func => func !== task)
		};
	}, [])
};

