import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";

import { isValidPrice } from "../../../../../drafts/hooks/useProductStatus/components/SoldModal/form";
import { MAX_PRICE } from "../../../../../drafts/hooks/useProductStatus/components/SoldModal/constants";

import styles from "./index.module.scss";

const PriceInput = ({ value, onChange, maxValue = MAX_PRICE }) => {
  const onChangeValue = useCallback(
    ({ target: { value } }) => {
      if (!isValidPrice(value) || value > maxValue) return;
      onChange(value);
    },
    [onChange, maxValue]
  );

  return (
    <Input
      className={styles.inputWrapper}
      type="text"
      value={value}
      onChange={onChangeValue}
    />
  );
};

PriceInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  maxValue: PropTypes.number,
};

export default PriceInput;
