import ExtensionService from "./Extension";
import { EXTENSION_EVENTS, MARKETPLACES } from "../base/constants/extension";

class EtsyExtension extends ExtensionService {
  static $displayName = "EtsyExtension";

  constructor() {
    super(MARKETPLACES.ETSY);
  }

  importSoldProducts(callback) {
    return this.subscribe(EXTENSION_EVENTS.GET_SOLD_ITEMS, callback);
  }

  onCompletedAuth(callback) {
    return this.subscribe(EXTENSION_EVENTS.COMPLETED_AUTH, ({ err }) => {
      if (!err) callback();
    });
  }

  onCompletedLogout(callback) {
    return this.subscribe(EXTENSION_EVENTS.COMPLETED_LOG_OUT, ({ err }) => {
      if (!err) callback();
    });
  }

  isConnected() {
    return this.request(EXTENSION_EVENTS.IS_CONNECTED);
  }

  getSoldDetails() {
    return this.request(EXTENSION_EVENTS.SOLD_DETAILS, {
      params: {},
    });
  }
}

export default EtsyExtension;
