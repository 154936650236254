/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Bugsnag from "@bugsnag/js";

import CustomButton from "../../../../../../base/components/CustomButton";
import { useCustomModal } from "../../../../../drafts/hooks/useCustomModal";
import UpdateMarketplaceContainer from "../../../../../../base/components/UpdateMarketplaceContainer";
import { ReactComponent as EbayLogo } from "../../../../../../assets/images/settings/logo ebay.svg";
import { ReactComponent as EtsyLogo } from "../../../../../../assets/images/settings/logo-etsy.svg";
import { ReactComponent as PoshmarkLogo } from "../../../../../../assets/images/settings/logo-poshmark.svg";
import { ReactComponent as MercariLogo } from "../../../../../../assets/images/settings/logo-mercari.svg";
import { useEbayIsConnect } from "../../../../../drafts/hooks/useEbayIsConnect";
import { useEtsyIsConnect } from "../../../../../drafts/hooks/useEtsyIsConnect";
import { usePoshmarkIsConnect } from "../../../../../drafts/hooks/Poshmark/usePoshmarkIsConnect";
import { useMercariIsConnect } from "../../../../../drafts/hooks/useMercariIsConnect";
import { useService } from "../../../../../../base/hooks/useService";
import MarketPlaceService from "../../../../../../services/MarketplaceService";
import { useLoading } from "../../../../../../base/hooks/useLoading";
import { useStatelessProvider } from "../../../../../../base/hooks/useProvider";
import { SoldContext } from "../../../../context/sold";
import { phrases } from "../../../../../../store/phrases";

const UpdateButton = ({ className = "" }) => {
  const marketplaceService = useService(MarketPlaceService);
  const [marketplaceData, updateMarketplaceData] = useState(null);
  const [, { registerPromise }] = useLoading();

  const handleUpdateMarketplace = useCallback(() => {
    registerPromise(marketplaceService.getMarketplace())
      .then(({ data }) => updateMarketplaceData(data))
      .catch((e) => Bugsnag.notify(e));
  }, [marketplaceService, registerPromise]);

  useEffect(() => {
    handleUpdateMarketplace();
  }, [handleUpdateMarketplace]);

  const [{ syncAll, isDownloadingItems, isSyncActive }] =
    useStatelessProvider(SoldContext);

  const { isConnected: isEbayConnectedExtension } = useEbayIsConnect();
  const { isConnected: isEtsyConnectedExtension } = useEtsyIsConnect();
  const { isConnected: isPoshmarkConnectedExtension } = usePoshmarkIsConnect();
  const { isConnected: isMercariConnectedExtension } = useMercariIsConnect();

  const isEbayConnected =
    isEbayConnectedExtension && marketplaceData?.ebayAccount;
  const isEtsyConnected =
    isEtsyConnectedExtension && marketplaceData?.etsyAccount;
  const isPoshmarkConnected =
    isPoshmarkConnectedExtension && marketplaceData?.poshmarkAccountDto;
  const isMercariConnected =
    isMercariConnectedExtension && marketplaceData?.mercariAccountDto;

  const isMarketplaceConnected = useMemo(() => {
    return (
      isEbayConnected ||
      isEtsyConnected ||
      isPoshmarkConnected ||
      isMercariConnected
    );
  }, [
    isEbayConnected,
    isEtsyConnected,
    isPoshmarkConnected,
    isMercariConnected,
  ]);

  const handleUpdateAction = useCallback(() => {
    syncAll();
  }, [syncAll]);

  const connectedMarketplaces = useMemo(() => {
    return (
      <div className="mt-3">
        <p className="mb-0 fw-medium">Sold items will be updated for:</p>
        <div className="d-flex mt-1">
          {isEbayConnected && (
            <UpdateMarketplaceContainer title={"Ebay"}>
              <EbayLogo />
            </UpdateMarketplaceContainer>
          )}
          {isEtsyConnected && (
            <UpdateMarketplaceContainer title={"Etsy"}>
              <EtsyLogo />
            </UpdateMarketplaceContainer>
          )}
          {isPoshmarkConnected && (
            <UpdateMarketplaceContainer title={"Poshmark"}>
              <PoshmarkLogo />
            </UpdateMarketplaceContainer>
          )}
          {isMercariConnected && (
            <UpdateMarketplaceContainer title={"Mercari"}>
              <MercariLogo />
            </UpdateMarketplaceContainer>
          )}
        </div>
      </div>
    );
  }, [
    isEbayConnected,
    isEtsyConnected,
    isPoshmarkConnected,
    isMercariConnected,
  ]);

  const { modal: modalUpdate, onShowModal: onShowModalUpdate } = useCustomModal(
    {
      title: phrases.updateConfirmationTitle,
      message: phrases.updateConfirmationDescription,
      additional: connectedMarketplaces,
      onAccept: handleUpdateAction,
    }
  );

  return (
    <>
      {modalUpdate}
      <CustomButton
        className={"filled-primary " + className}
        onClick={onShowModalUpdate}
        disabled={isDownloadingItems || isSyncActive || !isMarketplaceConnected}
      >
        {"Update"}
      </CustomButton>
    </>
  );
};

export default UpdateButton;
