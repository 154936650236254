import React from "react";

import styles from "./index.module.scss";

const TableItem = ({ title, value = "-", toolTip }) => {
  return (
    <div className={styles.wrapper}>
      <div>
        <span className={styles.title}>{title}</span>
      </div>
      {toolTip && (toolTip)}
      <div>
        <span className={styles.value}>{value}</span>
      </div>
    </div>
  );
};

export default TableItem;
