import React from "react";
import PropTypes from "prop-types";
import { DropdownItem } from "reactstrap";
import classnames from "classnames";

import { useModal } from "../../../../../base/hooks/useModal";

const ImportInventoryItem = ({
  title,
  ModalComponent,
  status,
  ...otherProps
}) => {
  const { open, close, isOpen } = useModal();
  const { data } = otherProps;

  return (
    <>
      <DropdownItem
        onClick={open}
        className={classnames({ "opacity-5": !data })}
      >
        {title}
      </DropdownItem>
      <ModalComponent
        isOpen={isOpen}
        onClose={close}
        status={status}
        {...otherProps}
      />
    </>
  );
};

ImportInventoryItem.propTypes = {
  title: PropTypes.string,
  ModalComponent: PropTypes.any,
  status: PropTypes.any,
};

export default ImportInventoryItem;
