import {
  CHANGE_SIDEBAR_PICKING_COUNTER,
  CHANGE_SIDEBAR_SHIPPING_COUNTER,
  CHANGE_SIDEBAR_TASKS_COUNTER,
} from "./actionTypes";

const INIT_STATE = {
  shippingCount: {
    picking: 0,
    shipping: 0,
  },
  tasksCount: 0,
};

const Counters = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_SIDEBAR_PICKING_COUNTER:
      return {
        ...state,
        shippingCount: {
          ...state.shippingCount,
          picking: action.payload,
        },
      };

    case CHANGE_SIDEBAR_SHIPPING_COUNTER:
      return {
        ...state,
        shippingCount: {
          ...state.shippingCount,
          shipping: action.payload,
        },
      };

    case CHANGE_SIDEBAR_TASKS_COUNTER:
      return {
        ...state,
        tasksCount: action.payload,
      };

    default:
      return state;
  }
};

export default Counters;
