/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";
import styled from "styled-components";

import styles from "./index.module.scss";
import SortDefaultIcon from "../../../../assets/images/sort-default.svg";

const SortIcon = styled.img`
  margin-left: 4px;
`;

const PickingHeader = ({
  sortData = () => {},
  sortIconImage = SortDefaultIcon,
}) => (
  <div
    className={classNames(
      styles.shippingListHeader,
      styles.shippingListItem,
      styles.pickingHeader
    )}
  >
    <div className={"row"} style={{ alignItems: "center" }}>
      <div className={"fw-bold col-5"}>Title</div>
      <div
        className={"fw-bold col-2"}
        onClick={sortData}
        style={{ cursor: "pointer" }}
      >
        SKU
        <SortIcon src={sortIconImage} />
      </div>
      <div className={"fw-bold col-2"}>Color</div>
      <div className={"fw-bold col-2"}>Marketplace</div>
      <div className={classNames("fw-bold col-1", styles.pickedTitle)}>
        Picked
      </div>
    </div>
  </div>
);

export default PickingHeader;
