import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import TaskList from "./pages/TaskList";
import { TASK_MANAGEMENT_GROUP_LINKS } from "./config";

const TasksManagementComponent = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <Switch>
          <Route path={TASK_MANAGEMENT_GROUP_LINKS.BASE} exact>
            <TaskList />
          </Route>
          <Redirect to={TASK_MANAGEMENT_GROUP_LINKS.BASE} />
        </Switch>
      </Container>
    </div>
  );
};

export default TasksManagementComponent;
