import ExtensionService from "./Extension";
import { EXTENSION_EVENTS, MARKETPLACES } from "../base/constants/extension";

class EbayExtension extends ExtensionService {
  static $displayName = "EbayExtension";

  constructor() {
    super(MARKETPLACES.EBAY);
  }

  importSoldProducts(callback) {
    return this.subscribe(EXTENSION_EVENTS.GET_SOLD_ITEMS, callback);
  }

  onCompletedAuth(callback) {
    return this.subscribe(EXTENSION_EVENTS.COMPLETED_AUTH, ({ err }) => {
      if (!err) callback();
    });
  }

  onCompletedLogout(callback) {
    return this.subscribe(EXTENSION_EVENTS.COMPLETED_LOG_OUT, ({ err }) => {
      if (!err) callback();
    });
  }

  isConnected() {
    return this.request(EXTENSION_EVENTS.IS_CONNECTED);
  }

  getInternationalShippingServices() {
    return this.request(EXTENSION_EVENTS.INTERNATIONAL_SHIPPING_OPTIONS);
  }

  getFlatDomesticShippingServices() {
    return this.request(EXTENSION_EVENTS.FLAT_DOMESTIC_SHIPPING_OPTIONS);
  }

  getCalculatedDomesticShippingServices() {
    return this.request(EXTENSION_EVENTS.CALCULATED_DOMESTIC_SHIPPING_OPTIONS);
  }
}

export default EbayExtension;
