import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./index.module.scss";
import DotsLoader from "../../../../base/components/DotLoader";

const FormSubTitle = ({ title = "", subTitle = "", fontWeight = "600", loading }) => {
  return (
    <div className="mb-4 mt-4">
      <h3
        className={classNames("bold-black", {
          [styles.fontWeight600]: fontWeight === "600",
          [styles.fontWeight500]: fontWeight === "500",
        })}
      >
        {title} { loading && <DotsLoader/> }
      </h3>
      {subTitle && <span className={styles.subTitle}>{subTitle}</span>}
    </div>
  );
};

FormSubTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  fontWeight: PropTypes.string,
};

export default FormSubTitle;
