import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import PageMeta from "../../../../base/components/MetaTags";
import CustomButton from "../../../../base/components/CustomButton";
import { useGetTaskList } from "../../hooks/useGetTaskList";
import InfiniteScrollWrapper from "../../../../base/components/InfiniteScrollWrapper";
import TaskCard from "../../components/TaskCard";
import TaskHeader from "../../components/TaskHeader";
import { TASK_THRESHOLD } from "../../constants/default";
import useGetIdProfile from "../../hooks/useGetIdProfile";
import { useService } from "../../../../base/hooks/useService";
import TaskManagement from "../../../../services/TaskManagement";
import CreateTaskModal from "../../components/CreateTaskModal";
import { filterFalsyValues } from "../../helpers/format";
import ToasterService from "../../../../services/ToastService";

import { changeSidebarTasksCounter } from "../../../../store/actions";
import Placeholder from "../../../inventory/components/Placeholder";
import emptyTasksIcon from "../../images/tasks-empty.png";
import CreateButton from "../../../returns/components/CreateReturnButton";
import WhiteBox from "../../../drafts/components/WhiteBox";
import Bugsnag from "@bugsnag/js";

const TaskList = () => {
  const [isOpenModalCreateTask, setIsOpenModalCreateTask] = useState(false);
  const { data, hasMore, handleFetch, isLoading, totalCount, handleRefresh } =
    useGetTaskList();

  const dispatch = useDispatch();

  const handleToggleModalCreateTask = () =>
    setIsOpenModalCreateTask((prevState) => !prevState);

  /**
   * @type {TaskManagement}
   */
  const TaskManagementService = useService(TaskManagement);

  /**
   * @type {ToasterService}
   */
  const toasterService = useService(ToasterService);

  const profileId = useGetIdProfile();

  const handleChangeMessage = ({ commentId, text: message }) =>
    TaskManagementService.updateComment(commentId, { message });

  const handleDeleteTask = async (taskId) => {
    await TaskManagementService.deleteTask(taskId);
    await handleRefresh();
  };

  const handleEditTask = async (taskId, closeModal, model) => {
    try {
      const formattedModel = filterFalsyValues(model);

      await TaskManagementService.editTask(taskId, formattedModel);
      await handleRefresh();
      closeModal();
    } catch (baseError) {
      Bugsnag.notify(baseError);
      const { error } = baseError;
      toasterService.error(error?.message);
    }
  };

  const handleDeleteMessage = async ({ commentId }) => {
    await TaskManagementService.deleteComment(commentId);
    await handleRefresh();
  };

  const handleCreateMessage = async ({ taskId, text }) => {
    await TaskManagementService.createComment(taskId, { message: text });
    await handleRefresh();
  };

  const handleCreateTask = async (model) => {
    try {
      const formattedModel = filterFalsyValues(model);

      await TaskManagementService.createTask(formattedModel);
      await handleRefresh();
      handleToggleModalCreateTask();
    } catch (baseError) {
      Bugsnag.notify(baseError);
      const { error } = baseError;
      toasterService.error(error?.message);
    }
  };

  useEffect(() => {
    dispatch(changeSidebarTasksCounter(totalCount));
  }, [totalCount]);

  return (
    <>
      <PageMeta title="Task management" />
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h4 className="mb-0 font-weight-semibold font-size-16 text-uppercase">
          Task management {totalCount ? `(${totalCount})` : ""}
        </h4>
        {!!data?.length && (
          <CustomButton
            className="filled-primary"
            onClick={handleToggleModalCreateTask}
          >
            Create task
          </CustomButton>
        )}
      </div>
      {!!data?.length && <TaskHeader />}
      <InfiniteScrollWrapper
        threshold={TASK_THRESHOLD}
        hasMore={hasMore}
        fetchData={handleFetch}
        hasInfiniteLoader={!!data?.length}
      >
        {isLoading ? (
          <WhiteBox>
            <p>Loading...</p>
          </WhiteBox>
        ) : (
          !data?.length && (
            <Placeholder
              text={`To create a new task, click "Create task".`}
              imgSrc={emptyTasksIcon}
              onCreateButtonClick={handleToggleModalCreateTask}
              buttonText={"Create task"}
              CreateButton={CreateButton}
            />
          )
        )}
        {data.map((task) => {
          const isCreator = task?.creatorId === profileId;
          const isAssignee = task?.assigneeId === profileId;

          return (
            <TaskCard
              key={task.id}
              isCreator={isCreator}
              isAssignee={isAssignee}
              profileId={profileId}
              onChangeMessage={handleChangeMessage}
              onDeleteTask={handleDeleteTask}
              onEditTask={handleEditTask}
              onDeleteMessage={handleDeleteMessage}
              onCreateMessage={handleCreateMessage}
              {...task}
            />
          );
        })}
      </InfiniteScrollWrapper>

      <CreateTaskModal
        isOpen={isOpenModalCreateTask}
        onClose={handleToggleModalCreateTask}
        onClick={handleCreateTask}
      />
    </>
  );
};

export default TaskList;
