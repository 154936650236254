import React from "react";
import PropTypes from "prop-types";

import DomesticReturns from "./components/DomesticReturns";

import { EBAY_RETURNS_NOTE } from "./constants/strings";

import styles from "./index.module.scss";

const ReturnOptions = ({ label, values, setFieldValue }) => {
  return (
    <>
      <label className={styles.label}>{label}</label>
      <DomesticReturns values={values} setFieldValue={setFieldValue} />
      <span className={styles.note}>{EBAY_RETURNS_NOTE}</span>
    </>
  );
};

ReturnOptions.propTypes = {
  label: PropTypes.string,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
};

export default ReturnOptions;
