import React from 'react'
import PropTypes from 'prop-types';

const SelectValueContainer = ({children}) => {
  return (
      <div style={{
          position: "relative"
      }}>{children}</div>
  );
};

SelectValueContainer.propTypes = {
  children: PropTypes.any,
}

export default SelectValueContainer;