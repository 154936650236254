import yup from "./yup";

import {
  BRAND_ERROR,
  COST_ERROR,
  DESCRIPTION_ERROR,
  DIMENSION_ERROR,
  LB_ERROR,
  MAX_VALIDATION_NUMBER,
  MIN_PRICE,
  MIN_VALIDATION_NUMBER,
  OZ_ERROR,
  PRISE_ERROR,
  TITLE_ERROR,
  ZIP_ERROR,
  TAG_ERROR,
  MIN_BUY_IT_NOW_PRICE,
  MIN_LENGTH_UPC,
  UPC_INVALID_ERROR,
  UPC_MIN_LENGTH_ERROR,
  ETSY_DESCRIPTION_ERROR,
} from "../base/constants/forms";

import {
  MAX_BRAND_LENGTH,
  MAX_DESCRIPTION_BASE_LENGTH,
  MAX_DESCRIPTION_LENGTH,
  MAX_NOTES_LENGTH,
  MAX_SKU_LENGTH,
  MAX_TAGS_LENGTH,
  MAX_TITLE_LENGTH,
  MIN_BRAND_LENGTH,
  MIN_DESCRIPTION_BASE_LENGTH,
  MIN_DESCRIPTION_LENGTH,
  MIN_NOTES_LENGTH,
  MIN_SKU_LENGTH,
  MIN_TAGS_LENGTH,
  MIN_TITLE_LENGTH,
} from "./lengthConstants";
import { SKU_CODE_PATTERN, TAG_CODE_PATTERN } from "../base/constants/patterns";
import {
  LISTING_URL_ERROR,
  LISTING_URL_PATTERN,
  MIN_LISTING_URL_ERROR,
  MIN_LISTING_URL_VALUE,
} from "../pages/drafts/components/DraftEbayFormInner/partials/ListingUrl/constants";
import { INVENTORY_STATUSES } from "../pages/inventory/pages/EditInventory/constants";
import { MAX_DESCRIPTION_LENGTH_MERCARI } from "../pages/drafts/const/mercari";
import {
  DOMESTIC_SHIPPING_TEMPLATE_ID,
  FLAT_SHIPPING_TYPE,
  FREE_DOMESTIC_SHIPPING_TEMPLATE_ID,
  FREE_INTERNATIONAL_SHIPPING_TEMPLATE_ID,
  SELLER_SHIPPING_PAYER,
  SHIP_OWN_LABEL,
} from "../base/constants/draft";

export const DRAFT_TITLE = yup
  .string()
  .min(MIN_TITLE_LENGTH, TITLE_ERROR)
  .max(MAX_TITLE_LENGTH, TITLE_ERROR)
  .trim();

export const DRAFT_DESCRIPTION = yup
  .string()
  .min(MIN_DESCRIPTION_BASE_LENGTH, DESCRIPTION_ERROR)
  .max(MAX_DESCRIPTION_BASE_LENGTH, DESCRIPTION_ERROR)
  .trim();

export const DRAFT_DESCRIPTION_ETSY = yup
  .string()
  .min(MIN_DESCRIPTION_LENGTH, ETSY_DESCRIPTION_ERROR)
  .max(MAX_DESCRIPTION_LENGTH, ETSY_DESCRIPTION_ERROR)
  .trim();

export const DRAFT_DESCRIPTION_MERCARI = yup
  .string()
  .min(MIN_DESCRIPTION_LENGTH, DESCRIPTION_ERROR)
  .max(MAX_DESCRIPTION_LENGTH_MERCARI, DESCRIPTION_ERROR)
  .trim();

export const DRAFT_BRAND = yup
  .string()
  .min(MIN_BRAND_LENGTH, BRAND_ERROR)
  .max(MAX_BRAND_LENGTH, BRAND_ERROR)
  .trim();

export const DRAFT_SKU = yup
  .string()
  .min(MIN_SKU_LENGTH)
  .max(MAX_SKU_LENGTH)
  .trim();

export const DRAFT_ZIP = yup
  .string()
  .min(1, ZIP_ERROR)
  .max(5, ZIP_ERROR)
  .matches(SKU_CODE_PATTERN, ZIP_ERROR)
  .trim();

export const DRAFT_TAGS = yup
  .string()
  .min(MIN_TAGS_LENGTH)
  .max(MAX_TAGS_LENGTH)
  .trim();

export const DRAFT_TAGS_ETSY = yup
  .string()
  .min(MIN_TAGS_LENGTH)
  .max(MAX_TAGS_LENGTH)
  .matches(TAG_CODE_PATTERN, TAG_ERROR)
  .trim();

export const DRAFT_NOTES = yup
  .string()
  .min(MIN_NOTES_LENGTH)
  .max(MAX_NOTES_LENGTH)
  .trim();

export const DRAFT_LB = yup
  .number()
  .min(MIN_VALIDATION_NUMBER, LB_ERROR)
  .max(MAX_VALIDATION_NUMBER, LB_ERROR)
  .test("Test", LB_ERROR, (_, { parent }) => {
    return !!parent.weightOz || !!parent.weightLb;
  });

export const DRAFT_OZ = yup
  .number()
  .min(MIN_VALIDATION_NUMBER, OZ_ERROR)
  .max(MAX_VALIDATION_NUMBER, OZ_ERROR)
  .test("Test", OZ_ERROR, (_, { parent }) => {
    return !!parent.weightOz || !!parent.weightLb;
  });

export const MERCARI_DRAFT_LB = yup
  .number()
  .min(MIN_VALIDATION_NUMBER, LB_ERROR)
  .max(MAX_VALIDATION_NUMBER, LB_ERROR)
  .test("Test", LB_ERROR, (_, { parent }) => {
    if (
      parent.shippingPayer === null ||
      (parent.shippingPayer === SELLER_SHIPPING_PAYER &&
        parent.howToShip === SHIP_OWN_LABEL)
    )
      return true;

    return !!parent.weightOz || !!parent.weightLb;
  });

export const MERCARI_DRAFT_OZ = yup
  .number()
  .min(MIN_VALIDATION_NUMBER, OZ_ERROR)
  .max(MAX_VALIDATION_NUMBER, OZ_ERROR)
  .test("Test", OZ_ERROR, (_, { parent }) => {
    if (
      parent.shippingPayer === null ||
      (parent.shippingPayer === SELLER_SHIPPING_PAYER &&
        parent.howToShip === SHIP_OWN_LABEL)
    )
      return true;

    return !!parent.weightOz || !!parent.weightLb;
  });

export const EBAY_DRAFT_LB = yup
  .number()
  .min(MIN_VALIDATION_NUMBER, LB_ERROR)
  .max(MAX_VALIDATION_NUMBER, LB_ERROR)
  .test("Test", LB_ERROR, (_, { parent }) => {
    if (parent.freeShipping || parent.shippingType === FLAT_SHIPPING_TYPE)
      return true;

    return !!parent.shippingType;
  });

export const EBAY_DRAFT_OZ = yup
  .number()
  .min(MIN_VALIDATION_NUMBER, OZ_ERROR)
  .max(MAX_VALIDATION_NUMBER, OZ_ERROR)
  .test("Test", OZ_ERROR, (_, { parent }) => {
    if (parent.freeShipping || parent.shippingType === FLAT_SHIPPING_TYPE)
      return true;

    return !!parent.shippingType;
  });

export const DRAFT_DIMENSION = yup
  .number()
  .min(MIN_VALIDATION_NUMBER, DIMENSION_ERROR)
  .max(MAX_VALIDATION_NUMBER, DIMENSION_ERROR);

export const ETSY_DRAFT_DIMENSION = yup
  .number()
  .min(MIN_VALIDATION_NUMBER, DIMENSION_ERROR)
  .max(MAX_VALIDATION_NUMBER, DIMENSION_ERROR)
  .when("shippingTemplateId", {
    is: DOMESTIC_SHIPPING_TEMPLATE_ID,
    then: yup
      .number()
      .min(MIN_VALIDATION_NUMBER, OZ_ERROR)
      .max(MAX_VALIDATION_NUMBER, OZ_ERROR)
      .required(),
  })
  .when("shippingTemplateId", {
    is: FREE_DOMESTIC_SHIPPING_TEMPLATE_ID,
    then: yup
      .number()
      .min(MIN_VALIDATION_NUMBER, OZ_ERROR)
      .max(MAX_VALIDATION_NUMBER, OZ_ERROR)
      .required(),
  })
  .when("shippingTemplateId", {
    is: FREE_INTERNATIONAL_SHIPPING_TEMPLATE_ID,
    then: yup
      .number()
      .min(MIN_VALIDATION_NUMBER, OZ_ERROR)
      .max(MAX_VALIDATION_NUMBER, OZ_ERROR)
      .required(),
  });

export const DRAFT_PRICE = yup
  .number()
  .min(MIN_PRICE, PRISE_ERROR)
  .max(MAX_VALIDATION_NUMBER, PRISE_ERROR);
// .required(PRISE_ERROR);

export const DRAFT_BUY_IT_NOW_PRICE = yup
  .number()
  .min(MIN_BUY_IT_NOW_PRICE, PRISE_ERROR)
  .max(MAX_VALIDATION_NUMBER, PRISE_ERROR);

export const DRAFT_COST = yup
  .number()
  .min(MIN_VALIDATION_NUMBER, COST_ERROR)
  .max(MAX_VALIDATION_NUMBER, COST_ERROR);

export const DRAFT_UPC = yup
  .number(UPC_INVALID_ERROR)
  .min(MIN_LENGTH_UPC, UPC_MIN_LENGTH_ERROR)
  .nullable();

export const LISTING_URL = yup.string().when("status", {
  is: (status) => status === INVENTORY_STATUSES.listed,
  then: yup
    .string()
    .test(
      "isEmpty",
      MIN_LISTING_URL_ERROR,
      (value) => value?.length >= MIN_LISTING_URL_VALUE
    )
    .min(MIN_LISTING_URL_VALUE, MIN_LISTING_URL_ERROR)
    .matches(LISTING_URL_PATTERN, LISTING_URL_ERROR)
    .trim(),
});
