import Http from "./Http";

class HelpService extends Http {
  static $displayName = "HelpService";

  async contactUs(values) {
    return await this.post("/guests/inbox", values);
  }
}

export default HelpService;
