import { useCallback, useEffect, useRef, useState } from "react";

export const useDetectFormChanges = () => {
  const containerRef = useRef(null);
  const [dirty, update] = useState(false);

  useEffect(() => {
    const handleChanges = (event) => {
      event.stopPropagation();

      if (dirty === event.detail.dirty) return;

      update(event.detail.dirty);
    };

    containerRef.current?.addEventListener("formchange", handleChanges, false);
    return () =>
      containerRef.current?.removeEventListener("formchange", handleChanges);
  }, [containerRef, dirty]);

  const cancel = useCallback(() => {
    update(false);
  }, []);

  return { dirty, containerRef, cancel };
};

export const useTriggerFormChanges = () => {
  const containerRef = useRef(null);

  const markChanges = () => {
    const event = new CustomEvent("formchange", {
      detail: { dirty: true },
      bubbles: true,
    });
    containerRef?.current?.dispatchEvent(event);
  };

  const unmarkChanges = () => {
    const event = new CustomEvent("formchange", {
      detail: { dirty: false },
      bubbles: true,
    });
    containerRef?.current?.dispatchEvent(event);
  };

  return { markChanges, unmarkChanges, containerRef };
};
