import Http from "./Http";

class BrandsService extends Http {
  static $displayName = "BrandService";

  search(q) {
    return this.get("/brands", { params: { q } });
  }
}

export default BrandsService;
