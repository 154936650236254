import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "reactstrap";

import { DEFAULT_SIZE_LABEL, DEFAULT_SIZE_TEXT } from "./constants";

const ReturnLabel = ({
  id = "",
  label = "",
  text = "",
  className = "",
  sizeLabel = DEFAULT_SIZE_LABEL,
  sizeText = DEFAULT_SIZE_TEXT,
  toolTip
}) => {
  return (
    <Row className="d-flex custom-return">
      <Col className="d-flex" md={sizeLabel}>
        <p className="md-black custom-return__label mb-0">{label}</p>
        {toolTip && (toolTip)}
      </Col>
      <Col md={sizeText}>
        <p id={id} className={`md-dark-grey mb-0 ${text ? className : ""}`}>
          {text || "-"}
        </p>
      </Col>
    </Row>
  );
};

ReturnLabel.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  sizeLabel: PropTypes.number,
  sizeText: PropTypes.number,
};

export default ReturnLabel;
