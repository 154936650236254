import React from 'react'
import "./index.scss"

const MarketplaceCell = ({children}) => {
	return (
		<div className={'cell-market'}>
			{children}
		</div>
	)
};


export default MarketplaceCell;
