import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { Formik, Form } from "formik";

import IconButton from "../../../drafts/components/IconButton";
import FormikTextarea from "../../../../base/components/FormikTextarea";
import { ReactComponent as Edit } from "../Icons/Edit.svg";
import { ReactComponent as Delete } from "../Icons/Delete.svg";
import { getFormattedDate } from "../../../drafts/helpers/format";
import { TIME_PATTERN } from "../../constants/default";

import "./styles.scss";
import CustomButton from "../../../../base/components/CustomButton";
import { commentMessage } from "../../forms/commentMessage";
import { MAX_MESSAGE_LENGTH } from "../../../../validation/lengthConstants";

const CommentItem = ({
  id,
  message,
  updatedAt,
  from: { firstName, lastName },
  isCreator,
  onChangeMessage,
  isEdited,
  onDeleteMessage,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const handleToggleEdit = () => setIsEdit((prevState) => !prevState);

  const handleSubmitMessage = async ({ text }) => {
    await onChangeMessage({ commentId: id, text });
    handleToggleEdit();
  };

  const handleDeleteMessage = async () => {
    await onDeleteMessage({ commentId: id });
    handleToggleEdit();
  };

  const computedFullName = `${firstName} ${lastName}`;

  return (
    <>
      <div className="mt-3 mb-3 d-flex justify-content-between">
        <p className="mb-0 fw-medium">{computedFullName}</p>
        <div className="d-flex align-items-center">
          {isCreator && (
            <>
              <IconButton
                onClick={handleToggleEdit}
                className="action-icon-button d-flex align-items-center"
              >
                <Edit />
              </IconButton>
              <IconButton
                onClick={handleDeleteMessage}
                className="action-icon-button d-flex align-items-center"
              >
                <Delete />
              </IconButton>
            </>
          )}
        </div>
      </div>
      <Row>
        {isEdit ? (
          <Formik
            initialValues={{ text: message }}
            validationSchema={commentMessage}
            onSubmit={handleSubmitMessage}
          >
            {({ isValid, dirty }) => (
              <Form>
                <FormikTextarea
                  name="text"
                  placeholder={"Write something"}
                  rows="3"
                  maxLength={MAX_MESSAGE_LENGTH}
                />
                <div className="d-flex mb-3 justify-content-end">
                  <CustomButton
                    type="submit"
                    className="outline-primary me-2 comment__button task-form__button"
                    onClick={handleToggleEdit}
                  >
                    Cancel
                  </CustomButton>
                  <CustomButton
                    type="submit"
                    className="filled-primary comment__button task-form__button"
                    disabled={!isValid || !dirty}
                  >
                    Save
                  </CustomButton>
                </div>
              </Form>
            )}
          </Formik>
        ) : (
          <>
            <Col>
              <p
                className={`text-gray-gomi text-break${
                  isEdited ? " mb-1" : ""
                }`}
              >
                {message}
              </p>
              {isEdited && <p className="text-gray-gomi">(Edited)</p>}
            </Col>
            <Col
              sm="3"
              className="d-flex flex-wrap align-content-end justify-content-end me-2"
            >
              <p className="mb-0 text-gray-gomi">
                {getFormattedDate(updatedAt)}
              </p>
              <p className="ms-2 mb-3 text-gray-gomi">
                {getFormattedDate(updatedAt, TIME_PATTERN)}
              </p>
            </Col>
          </>
        )}
      </Row>
      <div className="comment__bottom-line" />
    </>
  );
};

export default CommentItem;
