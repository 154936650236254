


class BaseProduct {

  constructor() {
    this.onBeforeSave = () => {};

  }

  addBeforeSaveHook (callback) {
    this.onBeforeSave = callback;
  }

  loadAdditionalFields() {

  }
}

export default BaseProduct
