import { PAYMENTS_METHODS } from "../../hooks/useProductStatus/components/SoldModal/constants";


export const calcEtsyFees = (price, paymentMethod = PAYMENTS_METHODS.etsy) => {

  const getTransactionFees = (value, interestRate, fixedRate) => {
    const result = +value * interestRate + fixedRate;
    return result.toFixed(2);
  };

  const getTransactionFeesByFormulaForEtsy = {
    [PAYMENTS_METHODS.etsy]: (value) => {
      if (!value) return 0;
      const fixedRate = 0.25;
      const interestRate = 0.03;
      return getTransactionFees(value, interestRate, fixedRate);
    },
    [PAYMENTS_METHODS.payPal]: (value) => {
      if (!value) return 0;
      const fixedRate = 0.3;
      const interestRate = 0.029;

      return getTransactionFees(value, interestRate, fixedRate);
    },
  };

  return getTransactionFeesByFormulaForEtsy[paymentMethod]?.(price);
};
