import { EXPENSES_GROUP_LINKS } from "../config";

export const EXPENSES_TABS = [
  {
    title: "Expenses",
    link: EXPENSES_GROUP_LINKS.EXPENSES,
    count: 999,
  },
  {
    title: "Mileage",
    link: EXPENSES_GROUP_LINKS.MILEAGES,
    count: 999,
  },
];

export const EXPENSES_TAB = 0;
export const MILEAGES_TAB = 1;
