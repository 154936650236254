import React from "react";
import { TabContent, TabPane } from "reactstrap";
import PropTypes from "prop-types";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";

export default function FormContent({
  steps = [],
  onFormChanges,
  getInitialValues,
  getCurrentValues,
  TopNavigation,
  onClickPrev,
  onClickNext,
}) {
  const {
    params: { step },
  } = useLocationQuery();
  
  return (
    <TabContent activeTab={step}>
      {steps.map(({ Component, id }) => {
        return (
          <TabPane tabId={id} key={id}>
            <Component
              step={id}
              steps={steps}
              onFormChanges={onFormChanges}
              getInitialValues={getInitialValues}
              getCurrentValues={getCurrentValues}
              TopNavigation={TopNavigation}
              onClickPrev={onClickPrev}
              onClickNext={onClickNext}
            />
          </TabPane>
        );
      })}
    </TabContent>
  );
}

FormContent.propTypes = {
  steps: PropTypes.array,
  onFormChanges: PropTypes.func,
  getInitialValues: PropTypes.func,
  getCurrentValues: PropTypes.func,
  TopNavigation: PropTypes.func,
  onClickPrev: PropTypes.func,
  onClickNext: PropTypes.func,
};
