import React from 'react';
import PropTypes from "prop-types";

import CustomButton from '../../../../../../base/components/CustomButton';
import { CustomModal, ModalBody, ModalFooter, ModalHeader } from '../../../../../../base/components/CustomModal';
import CustomSpinner from '../../../../../../base/components/CustomSpinner';

const SubscriptionChangeModal = ({ 
  isOpen, 
  onClose, 
  onClick, 
  isLoading,
  title = 'Change subscription',
  text = 'Do you really want to change the subscription plan? You will be transferred to the chosen one. Money will be refunded. It can take some time.'
}) => {
  return (
    <CustomModal 
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader onClose={onClose}>
        {title}
      </ModalHeader>
      {isLoading ? (
        <ModalBody>
          <CustomSpinner />
        </ModalBody>        
      ) : (
        <>
          <ModalBody>
            <p className='regular-black mb-0'>
              {text}
            </p>
          </ModalBody>
          <ModalFooter>
            <CustomButton 
              className='outline-primary'
              onClick={onClose}
            >
              No
            </CustomButton>
            <CustomButton
              onClick={onClick}
              className='filled-primary'        
            >
              Yes
            </CustomButton>
          </ModalFooter>
        </>
      )}      
    </CustomModal>
  )
};

SubscriptionChangeModal.propTypes = {
  isOpen: PropTypes.bool, 
  onClose: PropTypes.func, 
  onOpen: PropTypes.func, 
  onClick: PropTypes.func,
  isLoading: PropTypes.bool
}

export default SubscriptionChangeModal;