/* eslint-disable react/prop-types */
import React, { Fragment, useState } from "react";
import classNames from "classnames";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import { useLocationQuery } from "../../hooks/useQueryString";
import "./index.scss";
import Icon from "../Icon";
import { DEFAULT_OFFSET } from "../../../pages/inventory/constants/importInventory";

const allOption = { value: null, name: "All" };

const FilterDropdown = ({ initialOpen, filterName, filterOptions = [] }) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const { params, merge } = useLocationQuery();

  const options = [allOption, ...filterOptions];

  const currentItem = options.find(
    (element) => element?.value === params?.[filterName]
  );

  const toggle = () => setIsOpen((prevIsOpen) => !prevIsOpen);
  const onFilterClick = (value) => {
    merge("offset", DEFAULT_OFFSET);
    merge(filterName, value);
  };

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle className="filter-dropdown-wrapper" tag="button">
        {currentItem?.name || allOption.name}
        <Icon icon="bottomArrow" className="filter-dropdown-icon" />
      </DropdownToggle>
      <DropdownMenu persist className="filter-dropdown-menu__menu to-right">
        {options?.map(({ name, value }) => (
          <Fragment key={value}>
            <DropdownItem
              className="filter-dropdown-item-wrapper"
              onClick={() => onFilterClick(value)}
            >
              <div
                className={classNames(
                  currentItem?.value === value && "fw-bold"
                )}
              >
                {name}
              </div>
            </DropdownItem>
          </Fragment>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default FilterDropdown;
