
export const getTouchedObj = (errors) => {
   if (Array.isArray(errors)) {
     const touched = []
     errors.map((val) => {
        touched.push(getTouchedObj(val))
      })
      return touched
    }
    const touched = {}
    Object.keys(errors).map(key => {
      if (Array.isArray(errors[key])) {
        errors[key].map((val, index) => {
          if (index == 0) touched[key] = []
          const ret = getTouchedObj(val)
          touched[key].push(ret)
        })
      } else if (typeof errors[key] !== 'string') {
        touched[key] = getTouchedObj(errors[key])
      } else {
        touched[key] = true
      }
    })
    return touched
  }

export const scrollToErrors = () => {
  const node  =   [...document
  .querySelectorAll(".invalid-feedback")]
  .find(node => !!node.innerText.trim());
  
  node?.scrollIntoView( {
    block: "center", 
    inline: "nearest", 
    behavior : "smooth"
  })
};

