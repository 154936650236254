import React from "react";
import style from "./index.module.scss"
import { separateThousandthFloored } from "../../../helpers/separateThousandth";


const OverviewItem = ({data}) => {

  return (

      <div className={style.container}>
        {data.map((item, idx ) => {
          return <div key={idx} className={style.itemContainer}>

            <div className={style.infoContainer}>
              <div className={style.mark}></div>
              <div className={style.text}>{item.label}</div>

            </div>
            <div  className={style.price}>{separateThousandthFloored(item.priceSoldSum) }</div>
          </div>
          }
        )}
      </div>
  )
};

export default OverviewItem;