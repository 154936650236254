import { useEffect } from "react";


export const useHttpInterceptors = (service, interceptors) => {

  useEffect(() => {
    const id = service.instance.interceptors.response.use(...interceptors);
    return () => {
      service.instance.interceptors.response.eject(id)
    }
  }, [service, interceptors])

};
