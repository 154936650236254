import {
  CHANGE_IMPORT_COUNTER,
  CHANGE_SIDEBAR_PICKING_COUNTER,
  CHANGE_SIDEBAR_SHIPPING_COUNTER,
  CHANGE_SIDEBAR_TASKS_COUNTER
} from "./actionTypes";

export const changeImportCounters = (callback) => ({
  type: CHANGE_IMPORT_COUNTER,
  payload: callback,
});

