import React from "react";
import { Col, Row } from "reactstrap";
import FormikGroupAddon from "../GroupAddon";
import { PRICE_STEP } from "../../../../base/constants/forms";
import { usePriceValidation } from "../../../../base/hooks/usePriceValidation";

const CostOfItem = () => {
  const [onChangeWithValidation, onKeyPress] = usePriceValidation({});
  return (
    <Row className="mb-4">
      <Col md="6">
        <FormikGroupAddon
          label="Cost of item"
          name="costOfItem"
          groupText="$"
          inputClassName="priceInputWrapper"
          onChange={(event, setValue) =>
            onChangeWithValidation(event, setValue)
          }
          onKeyPress={onKeyPress}
          isFieldOnChange={false}
          placeholder={"00.00"}
          step={PRICE_STEP}
        />
        <span className="font-size-10 text-gray-gomi ">
            For internal usage only
          </span>
      </Col>
    </Row>
  );
};

export default CostOfItem;