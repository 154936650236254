import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import {isLastOfArray} from "../../../../helpers/isLastOfArray";
import styles from "./index.module.scss"


const InventoryDropdown = ({
                             onChange=()=>{},
                             list = [],
                             classesContainer,
                           }) => {
  const [ isOpen, updateIsOpen ] = useState(false);
  const [ selected, updateSelected ] = useState(list[0]);

  const toggle = useCallback(() => {
    updateIsOpen(isOpen => !isOpen);
  }, []);

  const handleSelect = (selectedItem) => {
    updateSelected(selectedItem)
    onChange(selectedItem)
  };

  return (
    <Dropdown isOpen={isOpen} toggle={toggle} >
      <DropdownToggle className={styles.dropdownContainer}>
        {isOpen ?
          <i className='mdi mdi-chevron-up'></i> :
          <i className='mdi mdi-chevron-down'></i>
        }
        {selected.label}
      </DropdownToggle>
      <DropdownMenu right className={classesContainer ? classesContainer : "dropdown-menu"}>
        {list.map((item, index, arr) => (
          item.marketplaceType !== selected.marketplaceType &&
          <React.Fragment  key={index}>
            <DropdownItem
              value={item.marketplaceType}
              onClick={() => handleSelect(item)}
              active={item.marketplaceType === selected.marketplaceType}
            >
              {item.label}
            </DropdownItem>
            {!isLastOfArray(arr, index) && <DropdownItem divider />}
          </React.Fragment>

        ))}
      </DropdownMenu>
    </Dropdown>
  )
};

InventoryDropdown.propTypes = {
  defaultSelected: PropTypes.object,
  onChange: PropTypes.func,
  list: PropTypes.array
};

export default InventoryDropdown;