import { useState } from "react";

export const useUpdateState = (initialState = {}) => {
  const [state, setState] = useState(initialState);

  const updateState = (newValue) =>
    setState((prevState) => ({
      ...prevState,
      ...newValue,
    }));

  return [state, updateState];
};
