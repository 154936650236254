import { DRAFTS_GROUP_LINKS } from "../../config";

export const EDIT_DRAFT_LINKS = {
  BASIC: `${DRAFTS_GROUP_LINKS.VIEW_DRAFT}/base`,
  EBAY: `${DRAFTS_GROUP_LINKS.VIEW_DRAFT}/ebay`,
  ETSY: `${DRAFTS_GROUP_LINKS.VIEW_DRAFT}/etsy`,
  POSHMARK: `${DRAFTS_GROUP_LINKS.VIEW_DRAFT}/poshmark`,
  MERCARI: `${DRAFTS_GROUP_LINKS.VIEW_DRAFT}/mercari`,
};

export const getEditTabs = (url) => {
	return [
		{
			title: "Basic",
			link: url + '/base',
		},
		{
			title: "eBay",
			link: url + '/ebay',
		},
		{
			title: "Etsy",
			link: url + '/etsy',
		},
		{
			title: "Poshmark",
			link: url + '/poshmark',
		},
		{
			title: "Mercari",
			link: url + '/mercari',
		},
	]
};