/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import { prepareFormModelForSaving } from "../../helpers/mapFormValues";
import { useDebounce } from "../../../../base/hooks/useDebounce";
import { get } from "lodash";
import {
  BASIC_COLORS,
  BASIC_CONDITIONS,
  COLORS_NAMES,
  pricingFormatStatuses,
} from "../../../../base/constants/draft";
import { ETSY_COLORS } from "../../const/eatsy";
import Associations, { TransformField } from "../../classes/Associations";
import FormPartials from "../../classes/FormPartials";
import { FORM_TYPES } from "../../const/form";
import { EBAY_CONDITIONS } from "../../const/ebay";
import { POSHMARK_CONDITIONS } from "../../const/poshmark";
import { MERCARI_CONDITIONS } from "../../const/mercari";
import { parseFromHtml } from "../../../../base/helpers/parseFromHtml";
import CategoriesService from "../../../../services/CategoriesService";
import { marketplacesList } from "../../../../base/components/MergeModal/constants/constants";
import { INVENTORY_STATUSES } from "../../../inventory/pages/EditInventory/constants";

const FORM = {
  EBAY: "productEbaySpecificFields",
  ETSY: "productEtsySpecificFields",
  POSHMARK: "productPoshmarkSpecificFields",
  MERCARI: "productMercariSpecificFields",
};

const POSHMARK_COLORS = {
  [BASIC_COLORS.RED]: "#ea2e2e",
  [BASIC_COLORS.PINK]: "#fb1680",
  [BASIC_COLORS.ORANGE]: "#fca628",
  [BASIC_COLORS.YELLOW]: "#ffee37",
  [BASIC_COLORS.GREEN]: "#3c9c44",
  [BASIC_COLORS.BLUE]: "#137fc1",
  [BASIC_COLORS.PURPLE]: "#7f0f81",
  [BASIC_COLORS.GOLD]: "#ffd72e",
  [BASIC_COLORS.SILVER]: "#e9ebec",
  [BASIC_COLORS.BLACK]: "#000000",
  [BASIC_COLORS.GREY]: "#929292",
  [BASIC_COLORS.WHITE]: "#FFFFFF",
  [BASIC_COLORS.CREAM]: "#f4e0ca",
  [BASIC_COLORS.BROWN]: "#663509",
  [BASIC_COLORS.TAN]: "#d1b48e",
};

const MERCARI_COLORS = {
  [BASIC_COLORS.BLACK]: 1,
  [BASIC_COLORS.GREY]: 2,
  [BASIC_COLORS.WHITE]: 3,
  [BASIC_COLORS.BEIGE]: 4,
  [BASIC_COLORS.RED]: 5,
  [BASIC_COLORS.PINK]: 6,
  [BASIC_COLORS.PURPLE]: 7,
  [BASIC_COLORS.BLUE]: 8,
  [BASIC_COLORS.GREEN]: 9,
  [BASIC_COLORS.YELLOW]: 10,
  [BASIC_COLORS.ORANGE]: 11,
  [BASIC_COLORS.BROWN]: 12,
  [BASIC_COLORS.GOLD]: 13,
  [BASIC_COLORS.SILVER]: 14,
};

const ETSY_COLOR_SCHEMA = {
  [BASIC_COLORS.BLACK]: ETSY_COLORS.BLACK,
  [BASIC_COLORS.BEIGE]: ETSY_COLORS.BEIGE,
  [BASIC_COLORS.BRONZE]: ETSY_COLORS.BRONZE,
  [BASIC_COLORS.BROWN]: ETSY_COLORS.BROWN,
  [BASIC_COLORS.BLUE]: ETSY_COLORS.BLUE,
  [BASIC_COLORS.GREY]: ETSY_COLORS.GREY,
  [BASIC_COLORS.GOLD]: ETSY_COLORS.GOLD,
  [BASIC_COLORS.GREEN]: ETSY_COLORS.GREEN,
  [BASIC_COLORS.CLEAR]: ETSY_COLORS.CLEAR,
  [BASIC_COLORS.COPPER]: ETSY_COLORS.COPPER,
  [BASIC_COLORS.ORANGE]: ETSY_COLORS.ORANGE,
  [BASIC_COLORS.WHITE]: ETSY_COLORS.WHITE,
  [BASIC_COLORS.RED]: ETSY_COLORS.RED,
  [BASIC_COLORS.PINK]: ETSY_COLORS.PINK,
  [BASIC_COLORS.PURPLE]: ETSY_COLORS.PURPLE,
  [BASIC_COLORS.RAINBOW]: ETSY_COLORS.RAINBOW,
  [BASIC_COLORS.ROSE_GOLD]: ETSY_COLORS.ROSE_GOLD,
  [BASIC_COLORS.YELLOW]: ETSY_COLORS.YELLOW,
  [BASIC_COLORS.SILVER]: ETSY_COLORS.SILVER,
  [BASIC_COLORS.TAN]: ETSY_COLORS.BEIGE,
};

const EBAY_COLORS = COLORS_NAMES;

const EBAY_CONDITION_SCHEMA = {
  [BASIC_CONDITIONS.newWithTagsBox]: EBAY_CONDITIONS.newWithTagsBox,
  [BASIC_CONDITIONS.newWithoutTagsBox]: EBAY_CONDITIONS.newWithoutTagsBox,
  [BASIC_CONDITIONS.newWithDefects]: EBAY_CONDITIONS.newWithDefects,
  [BASIC_CONDITIONS.preOwned]: EBAY_CONDITIONS.preOwned,
  [BASIC_CONDITIONS.poor]: EBAY_CONDITIONS.preOwned,
};

const POSHMARK_CONDITION_SCHEMA = {
  [BASIC_CONDITIONS.newWithTagsBox]: POSHMARK_CONDITIONS.newWithTagsBox,
  [BASIC_CONDITIONS.preOwned]: POSHMARK_CONDITIONS.newWithoutTagsBox,
};

const MERCARI_CONDITION_SCHEMA = {
  [BASIC_CONDITIONS.newWithTagsBox]: MERCARI_CONDITIONS.newWithTagsBox,
  [BASIC_CONDITIONS.newWithoutTagsBox]: MERCARI_CONDITIONS.newWithoutTagsBox,
  [BASIC_CONDITIONS.preOwned]: MERCARI_CONDITIONS.newWithDefects,
  [BASIC_CONDITIONS.poor]: MERCARI_CONDITIONS.poor,
};

const toTag = (tag) => {
  return tag ? "#" + tag : tag;
};

const parseTags = (basic) => {
  if (typeof basic === "string") {
    const response = (basic || "")
      .toString()
      .split(",")
      .map((data) => (data?.trim ? data?.trim() : data))
      .filter(Boolean);

    return response;
  }
};

const COLOR_ASSOCIATIONS = [
  {
    associations: new Associations("primaryColor")
      .setDefaultValue(null)
      .addPropertyValues(`${FORM.EBAY}.color`, EBAY_COLORS)
      .onBeforeApply(`${FORM.EBAY}.color`, (_, __, basic) => {
        return {
          key: `${FORM.EBAY}.colorName`,
          value: COLORS_NAMES[basic] || "",
        };
      })
      .addPropertyValues(`${FORM.ETSY}.primaryColor`, ETSY_COLOR_SCHEMA)
      .onBeforeApply(`${FORM.ETSY}.primaryColor`, (_, __, basic) => ({
        key: `${FORM.ETSY}.colorName`,
        value: COLORS_NAMES[basic] || "",
      }))
      .addPropertyValues(`${FORM.POSHMARK}.primaryColor`, POSHMARK_COLORS)
      .onBeforeApply(`${FORM.POSHMARK}.primaryColor`, (_, __, basic) => {
        return {
          key: `${FORM.POSHMARK}.colorName`,
          value: COLORS_NAMES[basic] || "",
        };
      })
      .addPropertyValues(`${FORM.MERCARI}.colorId`, MERCARI_COLORS)
      .onBeforeApply(`${FORM.MERCARI}.colorId`, (_, __, basic) => {
        return {
          key: `${FORM.MERCARI}.colorName`,
          value: COLORS_NAMES[basic] || "",
        };
      }),
  },
  {
    associations: new Associations("secondaryColor")
      .setDefaultValue(null)
      .addPropertyValues(`${FORM.POSHMARK}.secondaryColor`, POSHMARK_COLORS),
  },
];

const CONDITION_ASSOCIATIONS = [
  {
    associations: new Associations("itemCondition")
      .addPropertyValues(`${FORM.EBAY}.condition`, EBAY_CONDITION_SCHEMA)
      .addPropertyValues(
        `${FORM.POSHMARK}.isTagsAttached`,
        POSHMARK_CONDITION_SCHEMA
      )
      .addPropertyValues(
        `${FORM.MERCARI}.conditionId`,
        MERCARI_CONDITION_SCHEMA
      ),
  },
];

const TAG_ASSOCIATIONS = [
  {
    associations: new TransformField("tags", `${FORM.POSHMARK}.tags`)
      .fromBasic(parseTags)
      .onBeforeApply(`${FORM.POSHMARK}.tags`, (_, __, ___, tags) => {
        let tagArray = [];

        if (typeof tags === "string") {
          tagArray = parseTags(tags);
        }

        if (tagArray.length === 0) {
          tagArray = tags?.slice() || [];
        }

        return {
          key: `${FORM.POSHMARK}.tags`,
          value: tagArray,
        };
      }),
  },
  {
    associations: new TransformField("tags", `${FORM.ETSY}.tags`),
  },
  {
    associations: new TransformField("tags", `${FORM.MERCARI}.tags`)
      .fromBasic(parseTags)
      .onBeforeApply(`${FORM.MERCARI}.tags`, (_, __, ___, tags) => {
        let tagArray = [];

        if (typeof tags === "string") {
          tagArray = parseTags(tags);
        }

        if (tagArray.length === 0) {
          tagArray = tags?.slice() || [];
        }

        return {
          key: `${FORM.MERCARI}.tags_1`,
          value: toTag(tagArray[0] ?? null),
        };
      })
      .onBeforeApply(`${FORM.MERCARI}.tags`, (_, __, ___, tags) => {
        let tagArray = [];

        if (typeof tags === "string") {
          tagArray = parseTags(tags);
        }

        if (tagArray.length === 0) {
          tagArray = tags?.slice() || [];
        }

        return {
          key: `${FORM.MERCARI}.tags_2`,
          value: toTag(tagArray[1] ?? null),
        };
      })
      .onBeforeApply(`${FORM.MERCARI}.tags`, (_, __, ___, tags) => {
        let tagArray = [];

        if (typeof tags === "string") {
          tagArray = parseTags(tags);
        }

        if (tagArray.length === 0) {
          tagArray = tags?.slice() || [];
        }

        return {
          key: `${FORM.MERCARI}.tags_3`,
          value: toTag(tagArray[2] ?? null),
        };
      }),
  },
];

const BRAND_ASSOCIATIONS = [
  {
    associations: new TransformField("brand", `${FORM.EBAY}.brandName`)
      .fromBasic((brandName) => {
        if (typeof brandName == "string") {
          return brandName;
        }
      })
      .fromMarketplace((brandName) => {
        return String(brandName).toLowerCase();
      })
      .onBeforeApply(`${FORM.EBAY}.brandName`, (_, __, basic) => ({
        key: `${FORM.EBAY}.brand`,
        value: basic, // Need to reset on the form value will be selected from suggested
      })),
  },
  {
    associations: new TransformField("brand", `${FORM.MERCARI}.brandName`)
      .fromBasic((brandName) => {
        if (typeof brandName == "string") {
          return brandName;
        }
      })
      .fromMarketplace((brandName) => {
        return String(brandName).toLowerCase();
      })
      .onBeforeApply(`${FORM.MERCARI}.brandName`, () => ({
        key: `${FORM.MERCARI}.brandName`,
        value: "", // Need to reset on the form value will be selected from suggested
      }))
      .onBeforeApply(`${FORM.MERCARI}.brandName`, () => ({
        key: `${FORM.MERCARI}.brandId`,
        value: null, // Need to reset on the form value will be selected from suggested
      })),
  },
  {
    associations: new TransformField("brand", `${FORM.POSHMARK}.brandName`)
      .fromBasic((brandName) => {
        if (typeof brandName == "string") {
          return String(brandName).toLowerCase();
        }
      })
      .fromMarketplace((brandName) => {
        return brandName;
      })
      .onBeforeApply(`${FORM.POSHMARK}.brandName`, (_, __, basic) => {
        return {
          key: `${FORM.POSHMARK}.brandName`,
          value: basic,
        };
      })
      .onBeforeApply(`${FORM.POSHMARK}.brandName`, (_, __, basic) => {
        return {
          key: `${FORM.POSHMARK}.brandId`,
          value: basic,
        };
      }),
  },
];

const TITLE_ASSOCIATIONS = [
  {
    associations: new TransformField("title", `${FORM.EBAY}.title`),
  },
  {
    associations: new TransformField("title", `${FORM.ETSY}.title`),
  },
  {
    associations: new TransformField("title", `${FORM.POSHMARK}.title`),
  },
  {
    associations: new TransformField("title", `${FORM.MERCARI}.title`),
  },
];

const DESCRIPTION_ASSOCIATIONS = [
  {
    associations: new TransformField("description", `${FORM.EBAY}.description`),
  },
  {
    associations: new TransformField(
      "description",
      `${FORM.ETSY}.description`
    ).fromBasic((html) => {
      return parseFromHtml(html);
    }),
  },
  {
    associations: new TransformField(
      "description",
      `${FORM.POSHMARK}.description`
    ).fromBasic((html) => {
      return parseFromHtml(html);
    }),
  },
  {
    associations: new TransformField(
      "description",
      `${FORM.MERCARI}.description`
    ).fromBasic((html) => {
      return parseFromHtml(html);
    }),
  },
];

const SKU_ASSOCIATIONS = [
  {
    associations: new TransformField("sku", `${FORM.EBAY}.sku`),
  },
  {
    associations: new TransformField("sku", `${FORM.ETSY}.sku`),
  },
  {
    associations: new TransformField("sku", `${FORM.POSHMARK}.sku`),
  },
  {
    associations: new TransformField("sku", `${FORM.MERCARI}.sku`),
  },
];

const WEIGHT_LB_ASSOCIATIONS = [
  {
    associations: new TransformField("weightLb", `${FORM.EBAY}.weightLb`),
  },
  {
    associations: new TransformField("weightLb", `${FORM.ETSY}.weightLb`),
  },
  {
    associations: new TransformField("weightLb", `${FORM.MERCARI}.weightLb`),
  },
];

const WEIGHT_OZ_ASSOCIATIONS = [
  {
    associations: new TransformField("weightOz", `${FORM.EBAY}.weightOz`),
  },
  {
    associations: new TransformField("weightOz", `${FORM.ETSY}.weightOz`),
  },
  {
    associations: new TransformField("weightOz", `${FORM.MERCARI}.weightOz`),
  },
];

const HEIGHT_ASSOCIATIONS = [
  {
    associations: new TransformField("height", `${FORM.EBAY}.height`),
  },
  {
    associations: new TransformField("height", `${FORM.ETSY}.height`),
  },
  {
    associations: new TransformField("height", `${FORM.MERCARI}.height`),
  },
];

const WIDTH_ASSOCIATIONS = [
  {
    associations: new TransformField("width", `${FORM.EBAY}.width`),
  },
  {
    associations: new TransformField("width", `${FORM.ETSY}.width`),
  },
  {
    associations: new TransformField("width", `${FORM.MERCARI}.width`),
  },
];

const DEPTH_ASSOCIATIONS = [
  {
    associations: new TransformField("depth", `${FORM.EBAY}.depth`),
  },
  {
    associations: new TransformField("depth", `${FORM.ETSY}.depth`),
  },
  {
    associations: new TransformField("depth", `${FORM.MERCARI}.depth`),
  },
];

const LISTING_PRICE_ASSOCIATIONS = [
  {
    associations: new TransformField("listingPrice", `${FORM.ETSY}.price`),
  },
  {
    associations: new TransformField(
      "listingPrice",
      `${FORM.EBAY}.buyItNowPrice`
    ).onBeforeApply(`${FORM.EBAY}.buyItNowPrice`, (form) => ({
      key: `${FORM.EBAY}.pricingFormat`,
      value: get(
        form,
        `${FORM.EBAY}.pricingFormat`,
        pricingFormatStatuses.fixedPrice
      ),
    })),
  },
  {
    associations: new TransformField(
      "listingPrice",
      `${FORM.POSHMARK}.listingPrice`
    ),
  },
  {
    associations: new TransformField(
      "listingPrice",
      `${FORM.MERCARI}.listingPrice`
    ),
  },
];

const ITEM_COST_ASSOCIATIONS = [
  {
    associations: new TransformField("itemCost", `${FORM.EBAY}.costOfItem`),
  },
  {
    associations: new TransformField("itemCost", `${FORM.ETSY}.costOfItem`),
  },
  {
    associations: new TransformField("itemCost", `${FORM.POSHMARK}.costPrice`),
  },
  {
    associations: new TransformField("itemCost", `${FORM.MERCARI}.costOfItem`),
  },
];

const ZIP_ASSOCIATIONS = [
  {
    associations: new TransformField("zip", `${FORM.MERCARI}.shipsFrom`),
  },
];

const NOTES_ASSOCIATIONS = [
  {
    associations: new TransformField("notes", `${FORM.POSHMARK}.otherInfo`),
  },
];

const CATEGORY_ASSOCIATIONS = [
  {
    associations: new Associations("categoryId")
      .setDefaultValue(null)
      .addPropertyValues(
        `${FORM.EBAY}.category`,
        CategoriesService.EBAY_CATEGORIES
      )
      .addPropertyValues(
        `${FORM.ETSY}.categoryId`,
        CategoriesService.ETSY_CATEGORIES
      )
      .addPropertyValues(
        `${FORM.POSHMARK}.categoryId`,
        CategoriesService.POSHMARK_CATEGORIES
      )
      .addPropertyValues(
        `${FORM.MERCARI}.categoryId`,
        CategoriesService.MERCARI_CATEGORIES
      ),
  },
];

const BASIC_DETECT_CHANGES = [
  ...COLOR_ASSOCIATIONS,
  ...CONDITION_ASSOCIATIONS,
  ...TAG_ASSOCIATIONS,
  ...TITLE_ASSOCIATIONS,
  ...DESCRIPTION_ASSOCIATIONS,
  ...SKU_ASSOCIATIONS,
  ...WEIGHT_LB_ASSOCIATIONS,
  ...WEIGHT_OZ_ASSOCIATIONS,
  ...HEIGHT_ASSOCIATIONS,
  ...WIDTH_ASSOCIATIONS,
  ...DEPTH_ASSOCIATIONS,
  ...LISTING_PRICE_ASSOCIATIONS,
  ...ITEM_COST_ASSOCIATIONS,
  ...ZIP_ASSOCIATIONS,
  ...NOTES_ASSOCIATIONS,
  ...BRAND_ASSOCIATIONS,
  ...CATEGORY_ASSOCIATIONS,
];

export const getCrosslistingChanges = (item, isDraftEditing = false) => {
  let listedMarketplace = marketplacesList.find((marketplace) => {
    return item[marketplace.key]?.status === INVENTORY_STATUSES.listed;
  });
  let listedMarketplaceKey = listedMarketplace ? listedMarketplace.key : null;
  const allChanges = [];

  // checking if iten listed or not
  if (listedMarketplaceKey) {
    const sourceForm = item[listedMarketplaceKey]; // grabbing the listed form

    BASIC_DETECT_CHANGES.forEach((field) => {
      const {
        associations: {
          basic: basicFormField,
          map,
          keys,
          formField = null,
          converters,
        },
      } = field;

      let sourceFormField;

      if (keys) {
        const fieldName = keys.find(
          (key) => key.indexOf(listedMarketplaceKey) !== -1
        );

        sourceFormField = fieldName;
      } else {
        sourceFormField =
          formField.indexOf(listedMarketplaceKey) !== -1 ? formField : null;
      }

      if (sourceFormField) {
        // skip it if value is undefined; this occurs when there's no mapping field
        const fragments = sourceFormField.split(".");
        const sourceFieldNode = fragments[1];
        const sourceFieldValue = sourceForm[sourceFieldNode];

        // some values are bool so we can't directly do `if (sourceFieldValue)`
        if (sourceFieldValue !== null && sourceFieldValue !== undefined) {
          // skip it if it has no value; can't crosslist values that don't exist
          if (map) {
            const sourceFieldMap =
              map[`${listedMarketplaceKey}.${sourceFieldNode}`];
            const targetFieldMapIndex = Object.values(sourceFieldMap).findIndex(
              (listVal, index) => {
                if (
                  Array.isArray(listVal) &&
                  listVal.indexOf(sourceFieldValue) !== -1
                ) {
                  return index;
                }

                if (listVal === sourceFieldValue) {
                  return index;
                }
              }
            );

            const basicFormValue =
              Object.keys(sourceFieldMap)[targetFieldMapIndex];

            // push changes to basic form first if it doesn't exist and if value is valid
            if (
              basicFormValue &&
              (!item[basicFormField] || item[basicFormField]?.trim?.() === "")
            ) {
              allChanges.push({
                key: basicFormField,
                value: basicFormValue,
              });
            }

            const filteredMap = Object.keys(map).filter(
              (mapItem) => mapItem.indexOf(listedMarketplaceKey) === -1
            );

            // push changes to other marketplaces
            filteredMap.forEach((mapItem) => {
              const fragments = mapItem.split(".");
              const marketplaceName = fragments[0];
              const marketplaceProperty = fragments[1];
              const marketplaceMap = map[mapItem];
              let marketplaceValue = marketplaceMap[basicFormValue];

              if (Array.isArray(marketplaceValue)) {
                marketplaceValue = marketplaceValue[0];
              }

              if (
                item[marketplaceName].status !== INVENTORY_STATUSES.listed &&
                marketplaceValue &&
                (!item[marketplaceName][marketplaceProperty] ||
                  item[marketplaceName][marketplaceProperty]?.trim?.() === "")
              ) {
                allChanges.push({
                  key: mapItem,
                  value: marketplaceValue,
                });
              }
            });
          } else if (converters) {
            const { fromMarketplace } = converters;
            const basicFormValue = fromMarketplace?.mapper?.(sourceFieldValue);

            // push changes to basic form first if value is valid and dest field is empty
            if (
              basicFormValue &&
              (!item[basicFormField] || item[basicFormField]?.trim?.() === "")
            ) {
              allChanges.push({
                key: basicFormField,
                value: basicFormValue,
              });
            }

            const otherMarketplaceMapping = BASIC_DETECT_CHANGES.filter(
              (change) =>
                change.associations.basic === basicFormField &&
                change.associations.formField.indexOf(listedMarketplaceKey) ===
                  -1
            );

            const freshBasicFormValue = item[basicFormField]; // using this in case basic form already had a value from another crosslisting run/DB

            // push changes to other marketplaces
            otherMarketplaceMapping.forEach((mapping) => {
              const marketplaceValue =
                mapping.associations.converters.fromBasic?.mapper?.(
                  freshBasicFormValue
                );

              const marketplaceFieldKey = mapping.associations.formField;
              const fragments = marketplaceFieldKey.split(".");
              const marketplaceName = fragments[0];
              const marketplaceProperty = fragments[1];

              if (
                item[marketplaceName].status !== INVENTORY_STATUSES.listed &&
                marketplaceValue &&
                (!item[marketplaceName][marketplaceProperty] ||
                  item[marketplaceName][marketplaceProperty]?.trim?.() === "")
              ) {
                allChanges.push({
                  key: marketplaceFieldKey,
                  value: marketplaceValue,
                });
              }
            });
          }
        }
      }
    });
  } else {
    // handle drafts
    const sourceForm = item;

    BASIC_DETECT_CHANGES.forEach((field) => {
      const {
        associations: {
          basic: basicFormField,
          map,
          keys,
          formField: marketplaceFieldKey = null,
          converters,
        },
      } = field;

      const sourceFormField = basicFormField;
      const sourceFormValue = sourceForm[sourceFormField];

      // we don't map unless there's a source value; some of these values can be bool, so `if (sourceFormValue)` may not work
      if (sourceFormValue !== null && sourceFormValue !== undefined) {
        if (keys) {
          keys.forEach((marketplaceFieldKey) => {
            const marketplaceMap = map[marketplaceFieldKey];
            let marketplaceValue = marketplaceMap[sourceFormValue];
            const fragments = marketplaceFieldKey.split(".");
            const marketplaceName = fragments[0];
            const marketplaceProperty = fragments[1];
            let continueCrosslisting = false;

            if (Array.isArray(marketplaceValue)) {
              marketplaceValue = marketplaceValue[0];
            }

            // we push changes only if a value is valid and if the destination field doesn't exist or is empty
            // however, if `isDraftEditing = true` then we don't check if destination is empty; we overwrite what there is
            if (isDraftEditing) {
              if (marketplaceValue) {
                continueCrosslisting = true;
              }
            } else {
              if (
                marketplaceValue &&
                (!sourceForm[marketplaceName]?.[marketplaceProperty] ||
                  sourceForm[marketplaceName]?.[
                    marketplaceProperty
                  ]?.trim?.() === "")
              ) {
                continueCrosslisting = true;
              }
            }

            if (continueCrosslisting) {
              allChanges.push({
                key: marketplaceFieldKey,
                value: marketplaceValue,
              });
            }
          });
        } else if (converters) {
          const { fromBasic } = converters;
          const fragments = marketplaceFieldKey.split(".");
          const marketplaceName = fragments[0];
          const marketplaceProperty = fragments[1];
          const marketplaceValue = fromBasic?.mapper?.(sourceFormValue);
          let continueCrosslisting = false;

          // we push changes only if a value is valid and if the destination field doesn't exist or is empty
          // however, if `isDraftEditing = true` then we don't check if destination is empty; we overwrite what there is
          if (isDraftEditing) {
            if (marketplaceValue) {
              continueCrosslisting = true;
            }
          } else {
            if (
              marketplaceValue &&
              (!sourceForm[marketplaceName]?.[marketplaceProperty] ||
                sourceForm[marketplaceName]?.[marketplaceProperty]?.trim?.() ===
                  "")
            ) {
              continueCrosslisting = true;
            }
          }

          if (continueCrosslisting) {
            allChanges.push({
              key: marketplaceFieldKey,
              value: marketplaceValue,
            });
          }
        }
      }
    });
  }

  return allChanges;
};

// unused: was part of original crosslisting logic
export const getMigrationBasicFields = (formValues, fullForm) => {
  let allChanges = [];

  const formPartials = new FormPartials(FORM_TYPES.BASE);

  let model = prepareFormModelForSaving(
    {
      ...fullForm,
    },
    formValues,
    formPartials.mapFields,
    ""
  );

  BASIC_DETECT_CHANGES.forEach(({ associations }) => {
    const { basic } = associations;
    const to = formValues[basic];
    const from = fullForm[basic];

    // No data
    if (!from) return;

    const changes = associations.compareChanges(model, from, to, basic);

    allChanges = allChanges.concat(changes);
  });

  return allChanges;
};

// unused: was part of original crosslisting logic
const SyncFormModels = ({
  updateFullForm,
  formValues: form,
  fieldsSchema,
  formKey,
}) => {
  const isBasic = !formKey;
  const formValues = useDebounce(form, 150);
  const [copied, setCopied] = useState(false);

  const getFormTimeout = (formKey) => {
    if (!formKey) return 0;

    let timeout = 1000;

    return timeout;
  };

  const formHash = JSON.stringify(formValues); // using a string hash

  useEffect(() => {
    const parseBasicToMarketplace = (fullForm, model) => {
      let allChanges = [];
      // When change some value on the Basic
      BASIC_DETECT_CHANGES.forEach(({ associations }) => {
        const { basic, formField = null } = associations;
        let to = null;
        let marketplaceField = null;

        if (formField && formField.indexOf(".") !== -1) {
          const fragments = formField.split(".");
          marketplaceField = fragments[0]?.trim();
          const formFieldName = fragments[1]?.trim();
          to = fullForm?.[marketplaceField]?.[formFieldName] || null;
        }

        if (formKey) {
          const { link = null } = fullForm[formKey];

          if (link && link !== "" && formKey === marketplaceField) return;
        }

        if (!to) {
          to = null;
        }

        const from = model[basic];

        // No changes
        if (from === to) return;

        const changes = associations.compareChanges(
          model,
          from,
          to,
          basic,
          true
        );

        allChanges = allChanges.concat(changes);
      });

      const cleanedChanges = allChanges.filter(
        (change) => change !== undefined
      );

      Associations.applyChanges(model, cleanedChanges);
    };

    const parseMarketplaceToBasic = (model) => {
      let allChanges = [];
      const { link = "" } = formValues;

      if (link && link !== "") {
        BASIC_DETECT_CHANGES.forEach(({ associations }) => {
          const { basic, keys = [], formField = null } = associations;

          let fieldName =
            keys?.find((keyName) => keyName.indexOf(formKey) !== -1) ||
            formField;

          if (fieldName) {
            if (fieldName.indexOf(".") !== -1) {
              const fragments = fieldName.split(".");
              fieldName = fragments[1]?.trim();
            }

            const to =
              formValues[fieldName] === undefined // this check is critical as `false` and `null` values are used to map to other fields
                ? null
                : formValues[fieldName];
            const from = model[basic];

            if (from == to) return;

            const changes = associations.compareChanges(model, from, to, basic);

            allChanges = allChanges.concat(changes);
          }
        });

        // we remove duplicate entries by key
        let uniqueChanges = [];
        allChanges.forEach((change) => {
          if (change) {
            const { key } = change;

            if (key) {
              if (
                !uniqueChanges.some((uniqueChange) => uniqueChange.key === key)
              ) {
                uniqueChanges.push(change);
              }
            }
          }
        });

        Associations.applyChanges(model, uniqueChanges);
      }
    };

    updateFullForm((fullForm) => {
      let model = prepareFormModelForSaving(
        {
          ...fullForm,
        },
        formValues,
        fieldsSchema,
        formKey
      );

      if (isBasic) {
        parseBasicToMarketplace(fullForm, model);
      } else {
        if (
          !copied &&
          fullForm[formKey]?.link &&
          fullForm[formKey]?.link !== ""
        ) {
          const timeout = getFormTimeout(formKey);

          parseMarketplaceToBasic(model);

          if (timeout !== 0) {
            setTimeout(() => {
              parseBasicToMarketplace(fullForm, model);
            }, timeout);
          } else {
            parseBasicToMarketplace(fullForm, model);
          }
        }

        setCopied(true);
      }

      model.files = formValues.files;

      return model;
    });
  }, [formHash, formKey]);

  return null;
};

export default SyncFormModels;
