/* eslint-disable react/prop-types */
import React, { useEffect, useMemo } from "react";
import { Col, Row } from "reactstrap";
import { useField } from "formik";

import FormikCheckbox from "../../../../../../base/components/FormikCheckbox";
import FormikInput from "../../../../../../base/components/FormikInput";
import FormikNumber from "../../../../../../base/components/FormikNumber";
import FormikSingleSelect from "../../../../../../base/components/FormikSingleSelect";
import {
  handlingTime,
  shippingArea,
  shippingType,
  SHIPPING_TYPE_TYPES,
} from "../../../../../../base/constants/draft";
import {
  MAX_LENGTH_ZIP,
  PRICE_STEP,
} from "../../../../../../base/constants/forms";
import Divider from "../../../Divider";
import { usePriceValidation } from "../../../../../../base/hooks/usePriceValidation";
import { useEbayFlatDomesticShippingServices } from "../../../../hooks/useEbayFlatDomesticShippingServices";
import { useEbayCalculatedDomesticShippingServices } from "../../../../hooks/useEbayCalculatedDomesticShippingServices";

import { StoredField } from "../../../../../../base/components/StoredField";

const ShippingSection = ({ values }) => {
  const [, , { setValue, setTouched }] = useField({ name: "shippingCost" });
  const [{ value: shippingServiceId }, , { setValue: setShippingService }] =
    useField({ name: "shippingService" });
  const [{ value: shippingTypeValue }] = useField({ name: "shippingType" });

  const [flatShippingServices] = useEbayFlatDomesticShippingServices();

  const [calculatedShippingServices] =
    useEbayCalculatedDomesticShippingServices();

  const filteredShippingServices = useMemo(() => {
    // for now we only display domestic options
    if (shippingTypeValue === SHIPPING_TYPE_TYPES.flat) {
      return flatShippingServices;
    }

    if (shippingTypeValue === SHIPPING_TYPE_TYPES.calculated) {
      return calculatedShippingServices;
    }

    return flatShippingServices.concat(calculatedShippingServices);
  }, [calculatedShippingServices, flatShippingServices, shippingTypeValue]);

  const [onChangeWithValidation, onKeyPress] = usePriceValidation({});

  const isHiddenShippingCost =
    (values.shippingType === shippingType[0].id && values.freeShipping) ||
    values.freeShipping ||
    values.shippingType === null ||
    values.shippingType === shippingType[1].id;

  // Reset field
  useEffect(() => {
    if (isHiddenShippingCost) {
      setValue(null);
      setTouched(false, false);
    }
  }, [isHiddenShippingCost]);

  // Reset field
  useEffect(() => {
    if (!shippingServiceId) return;

    if (filteredShippingServices.find(({ id }) => id === shippingServiceId))
      return;

    if (!filteredShippingServices.length) return;

    if (shippingServiceId) {
      const shippingService = filteredShippingServices.find(
        (service) => service.shippingService === shippingServiceId
      );

      if (shippingService) {
        const { shippingServiceId: id } = shippingService;
        setShippingService(id);

        return;
      }
    }

    setShippingService(null);
  }, [filteredShippingServices, setShippingService, shippingServiceId]);

  return (
    <>
      <h3 className="bold-black mb-4 mt-4">Shipping details</h3>
      <Divider />

      <Row>
        <Col md="6">
          <StoredField fieldKey="shippingArea" options={shippingArea} />
          <FormikSingleSelect
            name="shippingArea"
            label="Shipping area*"
            options={shippingArea}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="6">
          <StoredField fieldKey="shippingType" options={shippingType} />
          <FormikSingleSelect
            name="shippingType"
            label="Shipping type*"
            options={shippingType}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="6">
          <FormikCheckbox
            name="freeShipping"
            id="freeShipping"
            text={
              <>
                <span className="md-black">Free shipping</span>
                <p className="regular-black">
                  If you select Free shipping, Insurance, Package and handling
                  charges will not be applicable.{" "}
                </p>
              </>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <StoredField fieldKey="handlingTime" options={handlingTime} />
          <FormikSingleSelect
            name="handlingTime"
            label="Handling time*"
            options={handlingTime}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          {filteredShippingServices?.length > 0 && (
            <StoredField
              fieldKey="shippingService"
              options={filteredShippingServices}
            />
          )}
          <FormikSingleSelect
            name="shippingService"
            label="Shipping service*"
            options={filteredShippingServices}
          />
        </Col>
      </Row>
      <Row>
        {!isHiddenShippingCost && (
          <Col md="6" className="mb-3">
            <StoredField fieldKey="shippingCost" />
            <FormikNumber
              name="shippingCost"
              label="Shipping cost*"
              containerClassName="priceInputWrapper"
              onChange={(event, setValue) =>
                onChangeWithValidation(event, setValue)
              }
              onKeyPress={onKeyPress}
              placeholder="00.00"
              step={PRICE_STEP}
            />
          </Col>
        )}
      </Row>
      <Row className="mb-3">
        <Col md="6">
          <StoredField fieldKey="shippingLocation" />
          <FormikInput
            name="shippingLocation"
            label="Shipping location (Zip code)*"
            placeholder={"Enter Zip code"}
            maxLength={MAX_LENGTH_ZIP}
            containerClassName="mb-3"
          />
        </Col>
        <Col md="6">
          <StoredField fieldKey="city" />
          <FormikInput
            name="city"
            label="City*"
            placeholder={"Enter City"}
            containerClassName="mb-3"
          />
        </Col>
      </Row>
    </>
  );
};

export default ShippingSection;
