import React from "react";
import PropTypes from "prop-types";
import { getCurrentDate } from "../../helpers/getCurrentDate";

const ExpiredStatus = ({ className, expiredDate, isConnected, isActive = false }) => {
  const isNotExpired = expiredDate > getCurrentDate();

  return (
    <div className={className}>
      <p className="md-title-grey mb-0 text-uppercase">Status:</p>
      {!expiredDate && isActive && !isConnected ? (
        <p className="regular-red">Not connected</p>
      ) : isNotExpired || isActive ? (
        <p className="regular-green">Active</p>
      ) : (
        <p className="regular-red">Expired</p>
      )}
    </div>
  );
};

ExpiredStatus.propTypes = {
  expiredDate: PropTypes.string,
  className: PropTypes.string,
  isActive: PropTypes.bool,
};

export default ExpiredStatus;
