import FormikSingleSelect from "../../../../../base/components/FormikSingleSelect";
import {productColors} from "../../../../../base/constants/draft";
import React from "react";


const FormPrimaryColor = ({colors = productColors, placeholder = 'Choose color', isHideLabel = false}) => (
	<FormikSingleSelect
		name='primaryColor'
		label="Primary color"
		options={colors}
		placeholder={placeholder}
		isHideLabel={isHideLabel}
	/>
);

export default FormPrimaryColor
