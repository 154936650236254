import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Bugsnag from "@bugsnag/js";

import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";
import ProductReturnsService from "../../../../services/ProductReturnsService";
import DraftsService from "../../../../services/DraftsService";
import { PRODUCT_TYPE } from "../../../../base/constants/product";
import { DRAFTS_GROUP_LINKS } from "../../../drafts/config";
import { SHIPPING_GROUP_LINKS } from "../../../shipping/config";
import { RETURN_GROUP_LINKS } from "../../../returns/config";
import { RETURN_STATUSES } from "../../../returns/constants/returns-constants";
import { TASK_MANAGEMENT_GROUP_LINKS } from "../../../taskManagement/config";
import { ReactComponent as DraftsIcon } from "../../../../assets/images/sidebar/icon-sidebar-drafts.svg";
import { ReactComponent as ShippingIcon } from "../../../../assets/images/sidebar/icon-sidebar-shipping.svg";
import { ReactComponent as ReturnsIcon } from "../../../../assets/images/sidebar/icon-sidebar-returns.svg";
import { ReactComponent as TasksIcon } from "../../../../assets/images/sidebar/icon-sidebar-task-management.svg";

const COUNTERED_ITEMS = {
  shipping: "Shipping",
  tasks: "Tasks",
};
const INITIAL_LIMIT = 1;
const INITIAL_STATUS = PRODUCT_TYPE.DRAFT;

const ItemToDo = () => {
  const history = useHistory();
  const [_, { registerPromise }] = useLoading(true);
  const productReturnsService = useService(ProductReturnsService);
  const draftsService = useService(DraftsService);
  const [data, setData] = useState({});

  const fetchReturns = useCallback(() => {
    registerPromise(
      productReturnsService.getListReturnProducts({
        limit: INITIAL_LIMIT,
        status: RETURN_STATUSES.IN_PROGRESS,
      })
    )
      .then(({ pagination }) =>
        setData((prevState) => ({
          ...prevState,
          returnsCount: pagination?.totalCount,
        }))
      )
      .catch((e) => Bugsnag.notify(e));
  }, [productReturnsService, registerPromise]);

  const fetchDrafts = useCallback(() => {
    registerPromise(
      draftsService.getListDrafts({
        limit: INITIAL_LIMIT,
        status: INITIAL_STATUS,
      })
    )
      .then(({ pagination }) =>
        setData((prevState) => ({
          ...prevState,
          draftsCount: pagination?.totalCount,
        }))
      )
      .catch((e) => Bugsnag.notify(e));
  }, [draftsService, registerPromise]);

  useEffect(() => {
    fetchDrafts();
    fetchReturns();
  }, [fetchReturns, fetchDrafts]);

  const counters = useSelector((state) => state.Counters);

  const shippingCountSum =
    counters.shippingCount.picking + counters.shippingCount.shipping;

  const countersMap = {
    [COUNTERED_ITEMS.shipping]: shippingCountSum,
    [COUNTERED_ITEMS.tasks]: counters.tasksCount,
  };

  const items = useMemo(() => [
    {
      title: "Drafts",
      Icon: <DraftsIcon />,
      value: data.draftsCount,
      link: DRAFTS_GROUP_LINKS.BASE,
    },
    {
      title: "Shipping",
      Icon: <ShippingIcon />,
      value: countersMap.Shipping,
      link: SHIPPING_GROUP_LINKS.picking,
    },
    {
      title: "Returns",
      Icon: <ReturnsIcon />,
      value: data.returnsCount,
      link: RETURN_GROUP_LINKS.PROGRESS,
    },
    {
      title: "Tasks",
      Icon: <TasksIcon />,
      value: countersMap.Tasks,
      link: TASK_MANAGEMENT_GROUP_LINKS.BASE,
    },
  ]);

  return (
    <>
      {items.map((item) => {
        return (
          <li
            className="task-list__item task-list__item_secondary"
            key={item.title}
            onClick={() => history.push(item.link)}
          >
            <div className="list-info">
              <div className="list-icon">{item.Icon}</div>
              <div className="list-part">{item.title}</div>
            </div>
            <div className="list-numb">{item.value}</div>
          </li>
        );
      })}
    </>
  );
};

export default ItemToDo;
