import { useCallback, useEffect, useState } from "react";

import EtsyExtension from "../../../services/EtsyExtension";
import { useExtensionInstall } from "./Poshmark/useExtensionInstall";
import { useService } from "../../../base/hooks/useService";
import {
  authUrl_ETSY,
  WINDOW_CONFIG_POSHMARK,
  WINDOW_NAME_POSHMARK,
} from "../constants/connectButton";

export const useEtsyIsConnect = () => {
  const [isConnect, updateIsConnect] = useState(false);
  const [isPending, updateIsPending] = useState(false);
  const { isInstalled } = useExtensionInstall();

  /**
   * @type {EtsyExtension}
   */
  const etsy = useService(EtsyExtension);

  const handleSignIn = useCallback(() => {
    const openedWindow = open(
      authUrl_ETSY,
      WINDOW_NAME_POSHMARK,
      WINDOW_CONFIG_POSHMARK
    );

    if (isConnect && isInstalled && openedWindow) {
      setTimeout(() => openedWindow.close(), 3000);
    }
  }, [isConnect, isInstalled]);

  useEffect(() => {
    const { unSubscribe } = etsy.onCompletedAuth((data) => {
      if (data?.err) return;
      updateIsConnect(true);
      updateIsPending(true);
    });

    return unSubscribe;
  }, [etsy]);

  useEffect(() => {
    const { unSubscribe } = etsy.onCompletedLogout(() =>
      updateIsConnect(false)
    );

    return unSubscribe;
  }, [etsy]);

  useEffect(() => {
    etsy
      .isConnected()
      .then((result) => {
        updateIsConnect(result);
      })
      .catch(() => {
        updateIsConnect(false);
      });
  }, [etsy]);

  return {
    handleSignIn,
    isConnected: isConnect && isInstalled,
    isPending,
    updateIsPending,
    isInstalled,
  };
};
