import React from "react";
import { Col, Row } from "reactstrap";
import { SearchInput } from "../SearchInput";
import MergeDropdown from "../MergeDropdown"

import styles from "./index.module.scss";


const MergeLeftHeader = ({search, onSearchTerm, marketplacesList, onSelectMarketplace}) => {

  return (
    <Row className="mb-3">
      <Col>
        <div className={styles.searchWrapper}>
          <SearchInput
            onChange={onSearchTerm}
            value={search}
            placeholder={
              <span className="regular-grey">
                Search by&nbsp;
                <span className="bold-sm-gray">SKU </span>
                or
                <span className="bold-sm-gray"> Title</span>
              </span>
            }
          />
          <MergeDropdown
            list={marketplacesList}
            onChange={onSelectMarketplace}
          />
        </div>
      </Col>
    </Row>
  );
};

export default MergeLeftHeader;
