/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Tooltip } from "reactstrap";
import classnames from "classnames";

import styles from "./index.module.scss";
import { ReactComponent as CopySvg } from "./images/icon-16-copy-green.svg";
import { MAX_LISTING_URL_VALUE, MIN_LISTING_URL_VALUE } from "./constants";

import Divider from "../../../Divider";
import IconButton from "../../../IconButton";
import FormikInput from "../../../../../../base/components/FormikInput";

const useTooltipProps = (ref) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return {
    isOpen: tooltipOpen,
    target: ref,
    toggle,
  };
};

const Icon = ({ link, isDisabled }) => {
  const ref = useRef();
  const [isCopied, updateIsCopied] = useState(false);

  return (
    <>
      <div
        onAnimationEnd={() => updateIsCopied(false)}
        className={classnames(styles.labelCopied, {
          [styles.labelIsCopiedShow]: isCopied,
        })}
      >
        <span>URL is copied</span>
      </div>
      <IconButton
        ref={ref}
        className={classnames(styles.copyBtn, {
          [styles.disabledCopyBtn]: isDisabled,
        })}
        onClick={(event) => {
          event.preventDefault();
          if (isDisabled) return;
          navigator.clipboard.writeText(link);
          updateIsCopied(true);
        }}
      >
        <CopySvg className={styles.copySvg} ref={ref} />
      </IconButton>
      <Tooltip placement="top" {...useTooltipProps(ref)}>
        Copy
      </Tooltip>
    </>
  );
};

const ListingUrl = ({
  values,
  postedInfo: { isPosted, link } = {},
  setFieldValue,
  isDisabledCopyBtn,
  description = "",
}) => {
  useEffect(() => {
    if (isPosted && link) {
      setFieldValue("link", link);
    }
  }, [link, setFieldValue]);

  if (!isPosted) return null;

  return (
    <>
      <h3 className="bold-black mb-4 mt-4">Listing URL</h3>
      <Divider />
      <Row className="mb-5">
        <Col md="6">
          <div className={styles.listingUrlInputContainer}>
            <FormikInput
              name="link"
              onChange={({ target: { value } }) => {
                setFieldValue("link", value);
              }}
              containerClassName={styles.formikInputContainer}
              min={MIN_LISTING_URL_VALUE}
              max={MAX_LISTING_URL_VALUE}
              maxLength={MAX_LISTING_URL_VALUE}
              placeholder={"Enter listing URL"}
            />
            <Icon link={values?.link || ""} isDisabled={isDisabledCopyBtn} />
          </div>
          {description && (
            <p className={"font-size-10 text-gray-gomi mb-1 mt-2"}>
              {description}
            </p>
          )}
        </Col>
      </Row>
    </>
  );
};

export default ListingUrl;
