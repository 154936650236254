export const MAX_INVENTORY_COUNT = {
  standard: 50,
  business: 100,
};

export const MAX_ACTIVE_INVENTORY_COUNT = {
  standard: 100,
  business: 500,
};

export const ACTIVE_INVENTORY_LABEL = "active"
export const SOLD_INVENTORY_LABEL = "sold"

export const MARKETPLACE_LISTING_URL = {
  productPoshmarkSpecificFields: "https://poshmark.com/listing/",
  productMercariSpecificFields: "https://poshmark.com/listing/"
}
