/* eslint-disable react/prop-types */
import React from "react";
import {
  parseQuery,
  useLocationQuery,
} from "../../../../base/hooks/useQueryString";

import { ORDER_BY, ORDER_BY_LABEL, ORDER_TYPE_LABEL } from "../../constants";
import Icon from "../../../../base/components/Icon";

const DESC_SORT = ORDER_BY.desc;
const ASC_SORT = ORDER_BY.asc;

const IconCont = ({ iconType, dataField, text, orderType }) => {
  const { params, merge } = useLocationQuery();

  const handleClick = () => {
    merge(ORDER_BY_LABEL, dataField);
  };

  return (
    <div onClick={handleClick}>
      {text}
      <Icon icon={iconType} className="table-sort-icon" />
    </div>
  );
};

const HeaderFormatter = ({ text, dataField, sortBy: sortByField }) => {
  const { orderBy = "", orderType = DESC_SORT } = parseQuery(location.search);

  const getSortIcon = (cellName) => {
    if (orderBy === cellName) {
      return orderType !== DESC_SORT ? "tableSortAsc" : "tableSortDesc";
    }
    return "tableSortDefault";
  };

  const iconType = getSortIcon(dataField || sortByField);

  return (
    <IconCont
      iconType={iconType}
      text={text}
      dataField={dataField}
      orderType={orderType}
    />
  );
};

export default HeaderFormatter;
