const BASE_PAGE_LINK = "/app/analytics";

export const ANALYTICS_GROUP_LINKS = {
  BASE: BASE_PAGE_LINK,
  MONTHLY_OVERVIEW: `${BASE_PAGE_LINK}/monthly-overview`,
  SALES_PLATFORM: `${BASE_PAGE_LINK}/sales-platform`,
  SOLD_INVENTORY: `${BASE_PAGE_LINK}/sold-inventory`,
  AVAILABLE_INVENTORY: `${BASE_PAGE_LINK}/available-inventory`,
};

export const ANALYTICS_TABS = [
  {
    title: "Monthly Sales Overview",
    link: ANALYTICS_GROUP_LINKS.MONTHLY_OVERVIEW,
  },
  {
    title: "Sales Per Platform",
    link: ANALYTICS_GROUP_LINKS.SALES_PLATFORM,
  },
  {
    title: "Sold inventory",
    link: ANALYTICS_GROUP_LINKS.SOLD_INVENTORY,
  },
  {
    title: "Available inventory",
    link: ANALYTICS_GROUP_LINKS.AVAILABLE_INVENTORY,
  },
];