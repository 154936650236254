import { RETURN_GROUP_LINKS } from "./config";

export const RETURNS_TABS = [
  {
    title: "In progress",
    link: RETURN_GROUP_LINKS.PROGRESS,
    count: 0,
  },
  {
    title: "Completed",
    link: RETURN_GROUP_LINKS.COMPLETED,
    count: 0,
  },
];
