/* eslint-disable react/prop-types */
import React from "react";

import styles from "../../../shipping/common.module.scss";
import FilterDropdown from "../../../../base/components/FilterDropdown";

const FilterInventory = ({ withAllOption, filterName, filterOptions }) => {
  return (
    <div className={styles.tabChildrenWrapper}>
      <span className={styles.filterTitle}>Filter:</span>
      <FilterDropdown
        withAllOption={withAllOption}
        filterName={filterName}
        filterOptions={filterOptions}
      />
    </div>
  );
};

export default FilterInventory;
