import React from "react";
import PropTypes from "prop-types";
import { getCurrentDate } from "../../helpers/getCurrentDate";

const ConnectedStatus = ({ className, expiredDate, isConnected, isExpired, isActive = false }) => {
  const isNotExpired = expiredDate > getCurrentDate();

  return (
    <div className={className}>
      <p className="md-title-grey mb-0 text-uppercase">Status:</p>
      {!isExpired && isActive && <p className="regular-green">Active</p>}
      {!isExpired && !isActive && <p className="regular-red">Not connected</p>}
      {isExpired && <p className="regular-red">Expired</p>}
    </div>
  );
};

ConnectedStatus.propTypes = {
  expiredDate: PropTypes.string,
  className: PropTypes.string,
  isActive: PropTypes.bool,
};

export default ConnectedStatus;