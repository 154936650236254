import { useService } from "./useService";
import StorageService from "../../services/StorageService";
import { useCallback, useEffect, useState } from "react";
import { KEY_EBAY_SESSION } from "../constants/storage";

export const useEbaySession = () => {
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const getFromStorage = useCallback(() => {
    return storage.get(KEY_EBAY_SESSION, {
      accessToken: "",
      expirationAt: "",
    })
  }, [storage]);

  const [sessionInfo, setSessionInfo] = useState(() => {
    return getFromStorage()
  });

  useEffect(() => {
    return storage.onChange(KEY_EBAY_SESSION, () => {
      setSessionInfo(getFromStorage())
    })
  }, [getFromStorage]);

  useEffect(() => {
    return storage.onChangeDataInSiblingTab(KEY_EBAY_SESSION, () => {
      setSessionInfo(getFromStorage())
    })
  }, [getFromStorage]);

  const updateProfile = useCallback((session) => {

    storage.set(KEY_EBAY_SESSION, session)
  }, [storage]);

  return [sessionInfo, updateProfile]
};
