/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Bugsnag from "@bugsnag/js";

import Arrow from "../DropdownSelect/components/Arrow";
import Clear from "../DropdownSelect/components/Clear";

import { customStyles } from "../shared/utils/defaultSelectStyle";
import { defaultConfigOptions } from "../shared/utils/configOptions";
import { useTriggerFormChanges } from "../../../../pages/drafts/hooks/useDetectDirtyForm";

const AsyncSelect = ({
  onChange = () => {},
  onRequestAPI,
  setFilter,
  menuIsOpen,
  controlShouldRenderValue,
  autoFocus,
  value,
  components,
  configOptions = defaultConfigOptions,
  styles = customStyles,
  isDisabled = false,
  isMulti = false,
  placeholder = "Choose",
  onBlur,
  getValue = () => value,
  parentId,
  isCreatable,
  search,
  updateSearch,
  ...props
}) => {
  const [options, updateOptions] = useState([]);
  const { markChanges, containerRef } = useTriggerFormChanges();

  const handleRequestAPI = useCallback(
    (q, parentId) => {
      return onRequestAPI(q, parentId)
        .then((data) => {
          updateOptions(configOptions(data));
        })
        .catch((e) => Bugsnag.notify(e));
    },
    [configOptions, onRequestAPI]
  );

  useEffect(() => {
    handleRequestAPI(search, parentId);
  }, [handleRequestAPI, search, parentId]);

  const filteredOption = useMemo(() => {
    const variants = setFilter ? setFilter(options, search) : options;

    return (
      variants?.sort(function (prevValue, currentValue) {
        let fistLabel = prevValue.label.toLowerCase();
        let secondLabel = currentValue.label.toLowerCase();

        if (search && (prevValue.prevPath || (prevValue.prevPath = ""))) {
          fistLabel = prevValue.prevPath.toLowerCase() + fistLabel;
          secondLabel = currentValue.prevPath.toLowerCase() + secondLabel;
        }

        if (fistLabel < secondLabel) {
          return -1;
        }
        if (fistLabel > secondLabel) {
          return 1;
        }
        return 0;
      }) || []
    );
  }, [options, search, setFilter]);

  const CustomSelect = isCreatable ? CreatableSelect : Select;

  const handleChange = (...params) => {
    markChanges();

    onChange(...params);
  };

  return (
    <>
      <CustomSelect
        menuIsOpen={menuIsOpen}
        autoFocus={autoFocus}
        isMulti={isMulti}
        options={filteredOption}
        onChange={handleChange}
        onInputChange={updateSearch}
        isClearable
        isDisabled={isDisabled}
        inputValue={search}
        components={{
          ...components,
          isClearable: true,
          ClearIndicator: Clear,
          IndicatorSeparator: null,
          DropdownIndicator: Arrow,
        }}
        controlShouldRenderValue={controlShouldRenderValue}
        styles={styles}
        placeholder={placeholder}
        value={getValue(filteredOption)}
        onBlur={onBlur}
        {...props}
      />
      <span ref={containerRef} />
    </>
  );
};

AsyncSelect.propTypes = {
  onChange: PropTypes.func,
  onRequestAPI: PropTypes.func.isRequired,
  configOptions: PropTypes.func,
  setFilter: PropTypes.func,
  isDisabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  placeholder: PropTypes.string,
  styles: PropTypes.object,
  components: PropTypes.object,
  menuIsOpen: PropTypes.bool,
  controlShouldRenderValue: PropTypes.any,
  autoFocus: PropTypes.bool,
  value: PropTypes.any,
  onBlur: PropTypes.func,
  parentId: PropTypes.any,
};

export default AsyncSelect;
