import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

import { concatValueWithDollarChar } from "../../hooks/useBookkeepingTable";

const BookkeepingTable = ({ table, isViewTotalsOnly }) => {
  const { header, body, footer } = table;

  return (
    <div className={styles.wrapper}>
      {isViewTotalsOnly ? (
        <>
          <div className={styles.topPart}>
            <span className={styles.title}>{table.title}</span>
            <span className={styles.total}>
              {table.total.title
                ? "Total"
                : concatValueWithDollarChar(table.total.value)}
            </span>
          </div>
          {table?.itemTotals?.map(
            (item, index) =>
              item?.title && (
                <div className={styles.mainPart} key={index}>
                  <span className={styles.itemTitle}>{item.title}</span>
                  <span className={styles.itemValue}>{item.value}</span>
                </div>
              )
          )}
          {table.total.title && (
            <div className={styles.bottomPart}>
              <span className={styles.totalTitle}>{table.total.title}</span>
              <span className={styles.totalValue}>
                {concatValueWithDollarChar(table.total.value)}
              </span>
            </div>
          )}
        </>
      ) : (
        <>
          <div className={styles.topPart}>
            {header.map((headerItem) =>
              headerItem.values.map((value, index) => (
                <span className={styles[headerItem.className]} key={index}>
                  {value}
                </span>
              ))
            )}
          </div>
          {body.map(
            (bodyItem, index) =>
              bodyItem && (
                <div className={styles.mainPart} key={index}>
                  {bodyItem.map((item) =>
                    item.values.map((value, index) => (
                      <span className={styles[item.className]} key={index}>
                        {value}
                      </span>
                    ))
                  )}
                </div>
              )
          )}
          {footer && (
            <div className={styles.bottomPart}>
              {footer.map((footerItem) =>
                footerItem.values.map((value, index) => (
                  <span className={styles[footerItem.className]} key={index}>
                    {value}
                  </span>
                ))
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

BookkeepingTable.propTypes = {
  table: PropTypes.object,
  isViewTotalsOnly: PropTypes.bool,
};

export default BookkeepingTable;
