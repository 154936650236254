import React, { useRef } from "react";
import { Tooltip } from "reactstrap";
import { useTooltip } from "../../hooks/useTooltip";
import ToolTipIcon from "./ToolTipIcon";
import PropTypes from "prop-types";

const ToolTip = ({ text, classNames }) => {
  const toolTipRef = useRef(null);

  return (
    <div className={classNames}>
      <div ref={toolTipRef}>
        <ToolTipIcon />
      </div>
      <Tooltip
        className="custom-tooltip"
        placement="top"
        {...useTooltip(toolTipRef)}
      >
        {text}
      </Tooltip>
    </div>
  );
};

ToolTip.propTypes = {
  text: PropTypes.string,
  classNames: PropTypes.string,
};

export default ToolTip;
