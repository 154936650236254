import * as yup from "yup";
import { CONFIRM_PASSWORD, USER_EMAIL, USER_FIRSTNAME, USER_LASTNAME, USER_PASSWORD } from "../../../../validation/user";

export const validationSchema = yup.object().shape({
  firstName: USER_FIRSTNAME.required(),
  lastName: USER_LASTNAME.required(),
  email: USER_EMAIL.required(),
  password: USER_PASSWORD.required(),
  confirmPassword: CONFIRM_PASSWORD.test(
    "passwords-match",
    "Passwords do not match",
    function (value) {
      return this.parent.password === value;
    }
  ),
});

export const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  checked: false,
};
