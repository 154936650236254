import React from "react";
import { useHistory } from "react-router-dom";

import { Container, Row, Col } from "reactstrap";

import error from "../../../assets/images/error-img.png";
import CustomButton from "../../components/CustomButton";
import PageMeta from "../../components/MetaTags";

const Page500 = () => {
  const { goBack } = useHistory();

  return (
    <div className="account-pages">
      <PageMeta title='500' />
      <Container>
        <Row>
          <Col lg="12">
            <div className="text-center mb-5">
              <h4>Oops…</h4>
              <p className='regular-black'>Something went wrong. Please, refresh the page or try again later</p>
              <div className="mt-5 text-center">
                <CustomButton
                  onClick={goBack}
                  className='btn btn-primary waves-effect waves-light'
                >
                  Reload
                </CustomButton>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8" xl="6">
            <div>
              <img src={error} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Page500;