import React, { useCallback, useState } from "react";
import Bugsnag from "@bugsnag/js";

import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../base/components/CustomModal";
import CustomButton from "../../../base/components/CustomButton";
import { useService } from "../../../base/hooks/useService";
import { useLoading } from "../../../base/hooks/useLoading";
import SoldService from "../../../services/SoldService";

const defaultConfig = {
  title: "Delete draft",
  message: "Do you want to delete the draft?",
  btnAccept: "Yes",
  btnClose: "No",
};

export const useActivateSold = (
  afterReactivate,
  { title, message, btnAccept, btnClose } = defaultConfig
) => {
  const [soldToActivate, setSoldToActivate] = useState(null);
  const [loading, { registerPromise }] = useLoading();

  /**
   * @type {SoldService}
   */
  const soldService = useService(SoldService);

  const onActivate = useCallback((orderId, productId) => {
    setSoldToActivate({ orderId, productId });
  }, []);

  const close = useCallback(() => {
    setSoldToActivate(null);
  }, []);

  const activateSold = useCallback(() => {
    const { orderId, productId } = soldToActivate;

    registerPromise(
      soldService
        .reactivate(orderId)
        .then(({ data }) => {
          afterReactivate(productId);
        })
        .catch((e) => Bugsnag.notify(e))
    );

    close();
  }, [soldToActivate, close, soldService, registerPromise, afterReactivate]);

  const view = (
    <CustomModal isOpen={!!soldToActivate} onClose={close}>
      <ModalHeader onClose={close}>{title}</ModalHeader>
      <ModalBody>
        <p className="ui-regular">{message}</p>
      </ModalBody>
      <ModalFooter>
        <CustomButton onClick={close} className="outline-primary ">
          {btnClose}
        </CustomButton>

        <CustomButton onClick={activateSold} className="filled-primary ">
          {btnAccept}
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );

  return {
    view,
    loading,
    onActivate,
  };
};
