/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";
import styled from "styled-components";

import styles from "./index.module.scss";
import SortDefaultIcon from "../../../../assets/images/sort-default.svg";

const SortIcon = styled.img`
  margin-left: 4px;
`;

const ShippingHeader = ({
  sortData = () => {},
  sortIconImage = SortDefaultIcon,
}) => (
  <div
    className={classNames(
      styles.shippingListHeader,
      styles.shippingListItem,
      styles.shippingHeader
    )}
  >
    <div className={"row"} style={{ alignItems: "center" }}>
      <div className={"fw-bold col-3"}>Title</div>
      <div
        className={"fw-bold col-1"}
        onClick={sortData}
        style={{ cursor: "pointer" }}
      >
        SKU
        <SortIcon src={sortIconImage} />
      </div>
      <div className={"fw-bold col"}>Color</div>
      <div className={"fw-bold col-2"}>Buyer</div>
      <div className={"fw-bold col-2 d-flex align-items-center"}>
        Marketplace
      </div>
      <div className={"fw-bold col"}>Shipping cost</div>
      <div className={"fw-bold col"}>Shipped</div>
    </div>
  </div>
);

export default ShippingHeader;
