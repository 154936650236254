import * as yup from "yup";

import {
  MAX_MILEAGE,
  MAX_TITLE, MILEAGE_DATE_ERROR_MESSAGE,
  MILEAGE_ERROR_MESSAGE,
  MILEAGE_MIN_MAX_ERROR_MESSAGE,
  MIN_MILEAGE,
  MIN_TITLE,
  TITLE_ERROR_MESSAGE,
} from "./constants";

export const formFields = {
  title: "title",
  mileage: "mileageQuantity",
  mileageMadeAt: "mileageMadeAt",
};

export const validationSchema = yup.object().shape({
  [formFields.title]: yup
    .string()
    .min(MIN_TITLE)
    .max(MAX_TITLE)
    .required(TITLE_ERROR_MESSAGE),
  [formFields.mileage]: yup
    .number()
    .min(MIN_MILEAGE, MILEAGE_MIN_MAX_ERROR_MESSAGE)
    .max(MAX_MILEAGE, MILEAGE_MIN_MAX_ERROR_MESSAGE)
    .required(MILEAGE_ERROR_MESSAGE),
  [formFields.mileageMadeAt]: yup
    .string()
    .nullable()
    .required(MILEAGE_DATE_ERROR_MESSAGE),
});

export const initialValues = {
  [formFields.title]: "",
  [formFields.mileage]: '',
  [formFields.mileageMadeAt]: new Date(),
};
