import React, { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { CardBody, Card } from "reactstrap";
import { Formik } from "formik";
import Bugsnag from "@bugsnag/js";

import FormikPassword from "../../../../base/components/FormikPassword";
import AuthCardContainer from "../../components/AuthCardContainer";
import AuthHeader from "../../components/Header";
import Button from "../../../../base/components/Button";
import AuthService from "../../../../services/AuthService";

import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useService } from "../../../../base/hooks/useService";
import { validationSchema, initialValues } from "./form";
import { useLoading } from "../../../../base/hooks/useLoading";
import { AUTH_GROUP_LINKS } from "../../config";
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
} from "../../../../validation/lengthConstants";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../base/components/CustomModal";
import StorageService from "../../../../services/StorageService";

export default function ResetPassword() {
  const [isLoading, { registerPromise }] = useLoading();
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);
  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const history = useHistory();

  const [isOpenModal, updateIsOpenModal] = useState(false);

  const handleCloseModal = useCallback(() => {
    storage.clear();
    history.push(AUTH_GROUP_LINKS.LINK_LOGIN);
  }, [history]);

  const {
    params: { token },
  } = useLocationQuery();

  const resetPassword = useCallback(
    ({ password }) => {
      registerPromise(
        authService
          .resetPassword({ password, token })
          .then(() => {
            updateIsOpenModal(true);
          })
          .catch((e) => Bugsnag.notify(e))
      );
    },

    [registerPromise, token]
  );

  const checkLinkRelevance = useCallback(() => {
    registerPromise(
      authService
        .checkVerificationToken({ token })
        .catch(() => history.push(AUTH_GROUP_LINKS.LINK_LOGIN))
    );
  }, [token, registerPromise]);

  useEffect(() => {
    checkLinkRelevance();
  }, [checkLinkRelevance]);

  return (
    <AuthCardContainer metaText="Login">
      <Card className="overflow-hidden">
        <AuthHeader headerText="Change your password" description="&nbsp;" />

        <CardBody className="pt-0">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={resetPassword}
          >
            {({ errors, handleSubmit, dirty }) => (
              <form className="form-horizontal p-2" onSubmit={handleSubmit}>
                <FormikPassword
                  type="password"
                  id="password"
                  label="New password"
                  name="password"
                  containerClassName="mb-3"
                  placeholder="Enter new password"
                  min={MIN_PASSWORD_LENGTH}
                  max={MAX_PASSWORD_LENGTH}
                />

                <FormikPassword
                  type="password"
                  id="repeatPassword"
                  label="Confirm password"
                  name="repeatPassword"
                  containerClassName="mb-3"
                  placeholder="Enter confirm password"
                  min={MIN_PASSWORD_LENGTH}
                  max={MAX_PASSWORD_LENGTH}
                />

                <button
                  className="btn btn-primary btn-block waves-effect waves-light mt-3 w-100"
                  type="submit"
                  disabled={isLoading || Object.keys(errors).length || !dirty}
                >
                  Confirm
                </button>
              </form>
            )}
          </Formik>
        </CardBody>
      </Card>
      <CustomModal isOpen={isOpenModal} onClose={handleCloseModal}>
        <ModalHeader onClose={handleCloseModal}>Set new password</ModalHeader>
        <ModalBody>
          <p className="ui-regular">
            Your password has been set up successfully.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button className="filled-primary" onClick={handleCloseModal}>
            Ok
          </Button>
        </ModalFooter>
      </CustomModal>
    </AuthCardContainer>
  );
}
