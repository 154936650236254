import React from "react";

const MarketplaceConnectedPlaceholder = () => {
  return (
    <div>
      <p className="mb-0 fw-medium">Marketplace connected</p>
    </div>
  );
};

export default MarketplaceConnectedPlaceholder;
