import { useEffect, useState } from "react";
import { useService } from "../../../../base/hooks/useService";
import PoshmarkExtension from "../../../../services/PoshmarkExtension";
import { LINK_TO_WEBSTORE } from "../../constants";

const useExtensionUpdateReady = () => {
  const [isReadyUpdate, updateIsReady] = useState(false);

  /**
   * @type {PoshmarkExtension}
   */
  const poshmark = useService(PoshmarkExtension);

  useEffect(() => {
    const { unSubscribe } = poshmark.onInstall(() => updateIsReady(true));
    return unSubscribe;
  }, [poshmark]);

  return isReadyUpdate;
};

export const useExtensionInstall = () => {
  const [isInstalled, updateIsInstalled] = useState(false);
  const isPending = useExtensionUpdateReady();

  /**
   * @type {PoshmarkExtension}
   */
  const poshmark = useService(PoshmarkExtension);

  useEffect(() => {
    poshmark
      .ping()
      .then(() => updateIsInstalled(true))
      .catch(() => updateIsInstalled(false));
  }, [poshmark]);

  useEffect(() => {
    const { unSubscribe } = poshmark.onUninstall(() =>
      updateIsInstalled(false)
    );
    return unSubscribe;
  }, [poshmark]);

  const onInstall = () => {
    window.open(LINK_TO_WEBSTORE, "_blank");
  };

  useEffect(() => {
    if (isPending) {
      updateIsInstalled(true);
    }
  }, [isPending]);

  return {
    onInstall,
    isInstalled,
    isPending,
  };
};
