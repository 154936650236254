import React, { useCallback } from "react";
import { InputGroupAddon } from "reactstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useField } from "formik";

import CustomInput from "../../../../../../../../base/components/Input";

import styles from "./index.module.scss";
import { isValidPrice } from "../../form";
import { MAX_PRICE } from "../../constants";

const InfoPriceItem = ({
  title,
  value,
  name,
  inputType = "$",
  onChange,
  isRequired = false,
  isColumnView = false,
  isOnlyInput = false,
  max = MAX_PRICE,
  placeholder = "",
  isPrice = true,
  maxLength = "",
  toolTip,
}) => {
  const [, meta, { setTouched }] = useField({ name });
  const isError = meta.error && meta.touched;

  const onChangeValue = useCallback(
    ({ target: { value } }) => {
      setTouched(true);
      if ((!isValidPrice(value) || value > max) && isPrice) return;
      onChange(value);
    },
    [onChange, setTouched, max, isPrice]
  );

  return (
    <div
      className={classNames(
        styles.wrapper,
        {
          [styles.columnView]: isColumnView,
        },
        { [styles.errorWrapper]: isError && isRequired }
      )}
    >
      <p
        className={classNames(styles.title, {
          ["text-danger"]: isError && isRequired,
        })}
      >
        {title}
      </p>
      {toolTip && (toolTip)}
      <div className={`d-flex ${styles.wrapperInput}`}>
        <CustomInput
          onChange={onChangeValue}
          type="text"
          className="custom-input-addon"
          value={value}
          name={name}
          placeholder={placeholder}
          maxLength={isPrice ? "" : maxLength}
        />
        {!isOnlyInput && (
          <InputGroupAddon
            className="d-flex flex-column-reverse custom-input-group-addon"
            addonType="append"
          >
            {inputType}
          </InputGroupAddon>
        )}
      </div>
      {isError && isRequired && (
        <span className={styles.priceError}>{meta.error}</span>
      )}
    </div>
  );
};

InfoPriceItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.any,
  name: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  isColumnView: PropTypes.bool,
  isOnlyInput: PropTypes.bool,
  max: PropTypes.number,
  placeholder: PropTypes.string,
  isPrice: PropTypes.bool,
  maxLength: PropTypes.string
};

export default InfoPriceItem;
