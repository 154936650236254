import React, { useCallback, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import HeaderFormatter from "../../../drafts/components/Table/HeaderFormatter";
import { ORDER_BY } from "../../../drafts/constants";

import {
  CREATED_AT_FIELD,
  ORDER_TYPE,
  ORDER_BY as ORDER_BY_FIELD,
} from "../MileagesHeader/constants";

const ORDERS = {
  [ORDER_BY.desc]: ORDER_BY.asc,
  [ORDER_BY.asc]: ORDER_BY.desc,
};

const ExpensesHeader = () => {
  const { merge } = useLocationQuery();
  const [orderType, setOrderType] = useState(ORDER_BY.desc);

  const onSortByDate = useCallback(() => {
    merge(ORDER_BY_FIELD, CREATED_AT_FIELD);
    setOrderType(ORDERS[orderType]);

    merge(ORDER_TYPE, orderType);
  }, [merge, setOrderType, orderType]);

  return (
    <Card className="task-header d-none d-sm-block mb-1">
      <CardBody className="py-3">
        <Row>
          <Col xs="12" sm="5" md="4">
            <p className="mb-0 fw-bold font-size-13">Title</p>
          </Col>
          <Col xs="2" md="2">
            <p className="mb-0 fw-bold font-size-13 text-truncate">Total</p>
          </Col>
          <Col xs="2" md="2">
            <p
              className="mb-0 fw-bold font-size-13 text-truncate cursor-pointer"
              onClick={onSortByDate}
            >
              <HeaderFormatter
                text="Date"
                dataField={CREATED_AT_FIELD}
                sortBy={CREATED_AT_FIELD}
              />
            </p>
          </Col>
          <Col xs="2" md="2">
            <Row className="d-flex flex-row">
              <Col md="7">
                <p className="mb-0 fw-bold font-size-13 text-nowrap">
                  Category
                </p>
              </Col>
            </Row>
          </Col>
          <Col xs="9" sm="5" md="2">
            <p className="mb-0 fw-bold font-size-13 text-center">Actions</p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ExpensesHeader;
