import moment from "moment";
import { toNumber } from "../../sold/helpers";

export class PoshmarkBaseProduct {
  // 2021-10-11T07:59:05-07:00 => 2021-11-02T10:32:37.199Z
  static convertDate(date) {
    return moment(date).toISOString();
  }

  get imageSrc() {
    return this.data.imageUrls[0];
  }

  get title() {
    return this.data.title;
  }

  get isSold() {
    return this.data.isSold;
  }

  get price() {
    return this.data.listingPrice;
  }

  static CONDITION = {
    NO_TAGS: "not_nwt",
    WITH_TAGS: "nwt",
    WITH_TAGS_AND_BOUTIQUE: "ret",
  };

  static parseCondition(condition) {
    return {
      isTagsAttached: [
        PoshmarkBaseProduct.CONDITION.WITH_TAGS,
        PoshmarkBaseProduct.CONDITION.WITH_TAGS_AND_BOUTIQUE,
      ].includes(condition),
      isAddToBoutique:
        condition === PoshmarkBaseProduct.CONDITION.WITH_TAGS_AND_BOUTIQUE,
    };
  }

  getDraftFields(draftData) {
    const labels = [
      draftData.department_obj?.display,
      draftData.category_obj?.display,
      draftData.category_features[0]?.display,
    ].filter(Boolean);

    const { original_price: originalPrice, title, price } = draftData;

    const imageUrls = [{ url: draftData.picture_url }]
      .concat(draftData.pictures)
      .map(({ url }) => url);

    const data = {
      originalPrice,
      title,
      ...(draftData.brandName && {
        brandName: draftData.brandName,
        brandId: draftData.brandName,
      }),
      description: draftData.description,
      imageUrls,
      primaryColor: draftData.colors?.[0]?.rgb || null,
      colorName: draftData.colors?.[0]?.name || null,
      discountedShipping: draftData.seller_shipping_discount_id,
      listingPrice: price,
      // costPrice: price,
      listingId: draftData.id,
      ...PoshmarkBaseProduct.parseCondition(draftData.condition),
      categoryId: draftData.category_features[0]?.id || draftData.categoryId,
      tags: draftData.style_tags || [],
      sizeName: draftData.size_obj.display_with_system_and_set || "",
      sizeId: `0,${draftData.size_obj.size_system},${draftData.size_obj.id}`,
      ...(labels.length && { categoryName: labels.join(" / ") }),
      categoryShortName: labels[labels.length - 1],
      ...(draftData.colors?.[1] && {
        secondaryColor: draftData.colors[1].rgb,
      }),
      ...(draftData.first_published_at && {
        firstListedAt: PoshmarkBaseProduct.convertDate(
          draftData.first_published_at
        ),
      }),
      ...(draftData.seller_private_info && {
        sku: draftData.seller_private_info?.sku,
        costPrice: toNumber(
          draftData.seller_private_info?.cost_price_amount?.val
        ),
        otherInfo: draftData.seller_private_info?.other_info,
      }),
    };

    return data;
  }
}
