import * as yup from "yup";

import {
  DRAFT_BUY_IT_NOW_PRICE,
  DRAFT_COST,
  DRAFT_DIMENSION,
  DRAFT_NOTES,
  DRAFT_PRICE,
  DRAFT_SKU,
  DRAFT_UPC,
  DRAFT_ZIP,
  EBAY_DRAFT_LB,
  EBAY_DRAFT_OZ,
  LISTING_URL,
} from "../../../../validation/draft";
import { nullableNumber } from "./etsy";
import {
  CALCULATED_SHIPPING_TYPE,
  FLAT_SHIPPING_TYPE,
  pricingFormat,
  pricingFormatStatuses,
  productColors,
} from "../../../../base/constants/draft";
import {
  MAX_VALIDATION_NUMBER,
  MIN_VALIDATION_NUMBER,
  PRISE_ERROR,
  TITLE_ERROR,
  DIMENSION_REQUIRED_ERROR,
  EBAY_DESCRIPTION_ERROR,
} from "../../../../base/constants/forms";
import {
  MAX_CONDITION_DESCRIPTION_LENGTH,
  MIN_CONDITION_DESCRIPTION_LENGTH,
  MIN_DESCRIPTION_LENGTH,
  MIN_TITLE_LENGTH,
} from "../../../../validation/lengthConstants";
import { MAX_TITLE_LENGTH_EBAY } from "../../const/ebay";
import { IS_ACCEPTED_RETURN_OPTIONS } from "../../components/DraftEbayFormInner/partials/ReturnOptions/constants";
import { FILES_VALIDATION } from "./base";

const MAX_DESCRIPTION_LENGTH_EBAY = 4000;

export const EBAY_TITLE = yup
  .string()
  .min(MIN_TITLE_LENGTH, TITLE_ERROR)
  .max(MAX_TITLE_LENGTH_EBAY, TITLE_ERROR)
  .trim();

export const EBAY_DESCRIPTION = yup
  .string()
  .min(MIN_DESCRIPTION_LENGTH, EBAY_DESCRIPTION_ERROR)
  .max(MAX_DESCRIPTION_LENGTH_EBAY, EBAY_DESCRIPTION_ERROR)
  .trim();

export const EBAY_CONDITION_DESCRIPTION = yup
  .string()
  .min(MIN_CONDITION_DESCRIPTION_LENGTH, EBAY_DESCRIPTION_ERROR)
  .max(MAX_CONDITION_DESCRIPTION_LENGTH, EBAY_DESCRIPTION_ERROR)
  .trim();

export const validationSchema = yup.object().shape({
  files: FILES_VALIDATION,
  title: EBAY_TITLE.required(),
  description: EBAY_DESCRIPTION.required(),
  category: yup.string().required(),
  city: yup.string().required(),
  shippingLocation: DRAFT_ZIP.required(),
  shippingCost: yup
    .number()
    .nullable()
    .min(MIN_VALIDATION_NUMBER, PRISE_ERROR)
    .max(MAX_VALIDATION_NUMBER, PRISE_ERROR)
    .test("Test", PRISE_ERROR, (_, { parent }) => {
      // When field is hidden
      if (
        (parent.shippingType === FLAT_SHIPPING_TYPE && parent.freeShipping) ||
        parent.freeShipping ||
        parent.shippingType === null ||
        parent.shippingType === CALCULATED_SHIPPING_TYPE
      )
        return true;

      return !!parent.shippingCost;
    }),
  startingPrice: yup.number().nullable().when("pricingFormat", {
    is: pricingFormat[0].id,
    then: DRAFT_PRICE.nullable().required(),
  }),
  buyItNowPrice: DRAFT_BUY_IT_NOW_PRICE.nullable().required(),
  shippingType: nullableNumber.required(),

  returnPolicyType: nullableNumber,
  returnDays: nullableNumber.when("returnPolicyType", {
    is: IS_ACCEPTED_RETURN_OPTIONS.available,
    then: nullableNumber.required(),
  }),
  returnExpenses: nullableNumber.when("returnPolicyType", {
    is: IS_ACCEPTED_RETURN_OPTIONS.available,
    then: nullableNumber.required(),
  }),
  returnMethod: nullableNumber,
  returnPolicyTypeInternational: nullableNumber,
  returnDaysInternational: nullableNumber.when(
    "returnPolicyTypeInternational",
    {
      is: IS_ACCEPTED_RETURN_OPTIONS.available,
      then: nullableNumber.required(),
    }
  ),
  returnExpensesInternational: nullableNumber.when(
    "returnPolicyTypeInternational",
    {
      is: IS_ACCEPTED_RETURN_OPTIONS.available,
      then: nullableNumber.required(),
    }
  ),
  returnMethodInternational: nullableNumber,
  shippingArea: nullableNumber.required(),
  duration: nullableNumber.required(),
  handlingTime: nullableNumber.required(),
  pricingFormat: nullableNumber.required(),
  shippingService: yup.string().required().nullable(),
  sku: DRAFT_SKU,
  upc: DRAFT_UPC,
  notes: DRAFT_NOTES,
  weightLb: EBAY_DRAFT_LB,
  weightOz: EBAY_DRAFT_OZ,
  acceptOffersOfAtLeast: DRAFT_PRICE.nullable(),
  declineOfferLowerThan: DRAFT_PRICE.nullable(),

  height: DRAFT_DIMENSION.test(
    "Test",
    DIMENSION_REQUIRED_ERROR,
    (_, { parent }) => {
      if (parent.freeShipping || parent.shippingType === FLAT_SHIPPING_TYPE)
        return true;

      return !!parent.shippingType;
    }
  ),
  width: DRAFT_DIMENSION.test(
    "Test",
    DIMENSION_REQUIRED_ERROR,
    (_, { parent }) => {
      if (parent.freeShipping || parent.shippingType === FLAT_SHIPPING_TYPE)
        return true;

      return !!parent.shippingType;
    }
  ),
  depth: DRAFT_DIMENSION.test(
    "Test",
    DIMENSION_REQUIRED_ERROR,
    (_, { parent }) => {
      if (parent.freeShipping || parent.shippingType === FLAT_SHIPPING_TYPE)
        return true;

      return !!parent.shippingType;
    }
  ),

  itemCost: DRAFT_COST,
  link: LISTING_URL,
  brandName: yup.string(),
  conditionDescription: EBAY_CONDITION_DESCRIPTION,
});

export const initialValues = {
  files: [],
  title: "",
  description: "",
  conditionDescription: "",
  sku: "",
  weightLb: "",
  weightOz: "",
  height: "",
  width: "",
  depth: "",
  doesNotApplyUpc: false,
  upc: "",
  category: "",
  shippingType: null,
  handlingTime: null,
  shippingService: "",
  freeShipping: false,
  shippingCost: null,
  shippingLocation: "",
  pricingFormat: pricingFormatStatuses.fixedPrice,
  duration: null,
  startingPrice: "",
  buyItNowPrice: "",
  allowBestOffer: false,
  acceptOffersOfAtLeast: "",
  declineOfferLowerThan: "",
  city: "",
  categoryName: "",
  categoryShortName: "",
  shippingArea: null,
  isGlobalShipping: false,
  returnPolicyType: IS_ACCEPTED_RETURN_OPTIONS.unavailable,
  returnDays: null,
  returnExpenses: null,
  returnMethod: null,
  returnPolicyTypeInternational: IS_ACCEPTED_RETURN_OPTIONS.unavailable,
  returnDaysInternational: null,
  returnExpensesInternational: null,
  returnMethodInternational: null,
  createdAt: "",
  updatedAt: "",
  link: "",
  brandName: "",
  costOfItem: null,
};

const findColorValue = (value) => {
  if (typeof value !== "number") return value;
  return productColors.find((color) => color.id === value)?.label;
};

export const fieldsToMerge = [
  { base: "files", custom: null },
  { base: null, custom: "title" },
  { base: null, custom: "description" },
  { base: "primaryColor", custom: "color", toSave: findColorValue },
  { base: null, custom: "sku" },
  { base: null, custom: "weightLb" },
  { base: null, custom: "weightOz" },
  { base: null, custom: "height" },
  { base: null, custom: "width" },
  { base: null, custom: "depth" },
  { base: null, custom: "doesNotApplyUpc" },
  { base: null, custom: "upc" },
  { base: null, custom: "brand" },
  { base: null, custom: "brandName" },
  { base: null, custom: "category" },
  { base: null, custom: "condition" },
  { base: null, custom: "conditionDescription" },
  { base: null, custom: "categoryName" },
  { base: null, custom: "categoryShortName" },
  { base: null, custom: "shippingType" },
  { base: null, custom: "handlingTime" },
  { base: null, custom: "shippingService" },
  { base: null, custom: "freeShipping" },
  { base: null, custom: "shippingCost" },
  { base: null, custom: "returnPolicyType" },
  { base: null, custom: "returnDays" },
  { base: null, custom: "returnExpenses" },
  { base: null, custom: "returnMethod" },
  { base: null, custom: "returnPolicyTypeInternational" },
  { base: null, custom: "returnDaysInternational" },
  { base: null, custom: "returnExpensesInternational" },
  { base: null, custom: "returnMethodInternational" },
  { base: null, custom: "isGlobalShipping" },
  { base: null, custom: "shippingLocation" },
  { base: null, custom: "pricingFormat" },
  { base: null, custom: "duration" },
  { base: null, custom: "startingPrice" },
  { base: null, custom: "buyItNowPrice" },
  { base: null, custom: "allowBestOffer" },
  { base: null, custom: "acceptOffersOfAtLeast" },
  { base: null, custom: "declineOfferLowerThan" },
  { base: null, custom: "city" },
  { base: null, custom: "shippingArea" },
  { base: null, custom: "productAdditionalFields" },
  { base: null, custom: "sizeName" },
  { base: null, custom: "costOfItem", toForm: (val) => val },
  { base: null, custom: "isUpdated" },
  { base: null, custom: "link", excludeSaving: true },
  { base: null, custom: "status", excludeSaving: true },
  { base: "createdAt", custom: "createdAt", excludeSaving: true },
  { base: "updatedAt", custom: "updatedAt", excludeSaving: true },
];
