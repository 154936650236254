import React, { useCallback, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import PropTypes from "prop-types";

import { DATE_FORMAT } from "../../../drafts/helpers/format";

import AnalyticCalendarContainer from "./conponents/AnalyticCalendarContainer";
import "./index.scss";

const defaultDateRange = [null, null];

const AnalyticRangeDateFilter = ({
  dateRange = defaultDateRange,
  dateRangeType,
  onChange,
  dateFormat,
  placeholder = DATE_FORMAT,
  CustomInput = () => {},
  dateFilterRef,
}) => {
  const [startDate, endDate] = dateRange;

  return (
    <div className="dateFilterContainer">
      <ReactDatePicker
        startDate={startDate}
        endDate={endDate}
        onChange={onChange}
        selectsRange
        shouldCloseOnSelect
        dateFormat={dateFormat}
        placeholderText={placeholder}
        customInput={<CustomInput />}
        calendarContainer={useCallback(
          (props) => (
            <AnalyticCalendarContainer
              dateRangeType={dateRangeType}
              onChangeDate={onChange}
              {...props}
            />
          ),
          [dateRangeType, onChange]
        )}
        ref={dateFilterRef}
      />
    </div>
  );
};

AnalyticRangeDateFilter.propTypes = {
  dateRange: PropTypes.array,
  dateRangeType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
  ]),
  onChange: PropTypes.func,
  dateFormat: PropTypes.string,
  placeholder: PropTypes.string,
  CustomInput: PropTypes.any,
  dateFilterRef: PropTypes.any,
};

export default AnalyticRangeDateFilter;
