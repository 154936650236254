import {
  CHANGE_SIDEBAR_PICKING_COUNTER,
  CHANGE_SIDEBAR_SHIPPING_COUNTER,
  CHANGE_SIDEBAR_TASKS_COUNTER,
} from "./actionTypes";

export const changeSidebarPickingCounter = (count) => ({
  type: CHANGE_SIDEBAR_PICKING_COUNTER,
  payload: count,
});

export const changeSidebarShippingCounter = (count) => ({
  type: CHANGE_SIDEBAR_SHIPPING_COUNTER,
  payload: count,
});

export const changeSidebarTasksCounter = (count) => ({
  type: CHANGE_SIDEBAR_TASKS_COUNTER,
  payload: count,
});
