import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import Bugsnag from "@bugsnag/js";

import CreationDate from "../../../../../pages/drafts/components/CreationDate";
import CustomButton from "../../../CustomButton";
import SubscriptionOption from "../SubscriptionOption";
import { CustomModal, ModalBody, ModalHeader } from "../../../CustomModal";

import StorageService from "../../../../../services/StorageService";
import SubscriptionService from "../../../../../services/SubscriptionService";
import { KEY_USER } from "../../../../constants/storage";
import { useService } from "../../../../hooks/useService";
import { subscriptionList } from "../../subscriptionList";
import { getPurchasedDates } from "../../utils/getPurchasedDates";

const SubscriptionModal = ({
  isOpen,
  handleToggleModal,
  chosenSubscription,
  handleChoseSubscribe,
  handleNextStep,
  textButton = "Next",
  text = "Please choose a subscription in order to continue. Based on your listings and/or drafts, here are suitable options:",
  isAbilityClose = false,
  isLimits = false,
}) => {
  /**
   * @type {SubscriptionService}
   */
  const subscriptionService = useService(SubscriptionService);
  const storage = useService(StorageService);

  const { activeSubscription } = storage.get(KEY_USER, {});

  const [activeSubscriptions, updateActiveSubscriptions] = useState([]);

  // Block the current subscription and everything that is less than the current one
  // In case of a limit
  const getAvailableSubscription = useCallback(
    (activeSubscriptionsElement) => {
      if (activeSubscription?.type < activeSubscriptionsElement?.type) {
        return true;
      }
    },
    [activeSubscription]
  );

  const purchasedDates = useMemo(() => {
    return getPurchasedDates(activeSubscription);
  }, [activeSubscription]);

  useEffect(() => {
    subscriptionService
      .getSubscriptions()
      .then(({ data }) => {
        updateActiveSubscriptions(data);
      })
      .catch((e) => Bugsnag.notify(e));
  }, [subscriptionService]);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={handleToggleModal}
      backdrop="static"
      keyboard={isAbilityClose}
      size="xl"
    >
      {isAbilityClose ? (
        <ModalHeader onClose={handleToggleModal}>
          Choose subscription plan to continue
        </ModalHeader>
      ) : (
        <div className="modal-header">
          <h3 className="modal-title mt-0" id="myModalLabel">
            Choose subscription plan to continue
          </h3>
        </div>
      )}
      <ModalBody>
        <p className="ui-regular mb-0">{text}</p>
        <div className="subscription-card-outer">
          <Row className="justify-content-around">
            {subscriptionList.map((item, index) => (
              <Col lg="6" xl="4" key={index}>
                <SubscriptionOption
                  isAvailable={
                    isLimits
                      ? getAvailableSubscription(activeSubscriptions[index])
                      : activeSubscriptions[index]?.isAvailable
                  }
                  isChosen={item.type === chosenSubscription?.type}
                  onChooseType={() => handleChoseSubscribe(item)}
                  {...item}
                />
              </Col>
            ))}
          </Row>
        </div>
        <Row>
          <Col className="d-flex">
            <CreationDate
              text="Purchase Date:"
              date={purchasedDates.purchaseDate}
              className="mr-60 mb-0"
            />
            <CreationDate
              text="Renewal Date:"
              date={purchasedDates.renewalDate}
              className="mr-60 mb-0"
            />
          </Col>
          <Col className="d-flex justify-content-end">
            <div>
              <CustomButton
                onClick={handleNextStep}
                disabled={!chosenSubscription}
                className="filled-primary"
              >
                {textButton}
              </CustomButton>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </CustomModal>
  );
};

SubscriptionModal.propTypes = {
  isOpen: PropTypes.bool,
  handleToggleModal: PropTypes.func,
  chosenSubscription: PropTypes.any,
  handleChoseSubscribe: PropTypes.func,
  handleNextStep: PropTypes.func,
  textButton: PropTypes.string,
  text: PropTypes.string,
  isAbilityClose: PropTypes.bool,
  isLimits: PropTypes.bool,
};

export default SubscriptionModal;
