import Bugsnag from "@bugsnag/js";

import { ACTIVE, ENDED } from "../../../base/constants/importEbay";
import { coinsToMoney } from "../../../base/helpers/currency";
import MercariExtension from "../../../services/MercariExtension";
import BaseProduct from "./BaseProduct";
import {
  PREPAID_LABEL,
  SHIP_ON_YOUR_OWN,
} from "../../drafts/components/DraftMercariFormInner/partials/ShippingTemplate";

/**

 * categoryName
 * brandName
 * sizeName
 * weightLb
 * weightOz

 * costOfItem
 * floorPrice
 * sku
 *
 * productAdditionalFields
 * firstListedAt
 * priceSold
 * marketplaceFees
 * shippingExpenses
 * buyer
 * dateSold
 * transactionFees
 */
const MILLISECONDS_PART = 1000;

class MercariProduct extends BaseProduct {
  static convertDate(date) {
    if (!date) return null;

    return new Date(date * MILLISECONDS_PART).toISOString();
  }

  constructor({ id, status, name, price, photos, updated }) {
    super();

    const ACTIVE_INVENTORY_STATUS = "on_sale";
    const isSold = status !== ACTIVE_INVENTORY_STATUS;

    this.data = {
      isSold,
      ...(isSold && {
        dateSold: MercariProduct.convertDate(updated),
      }),
      listingId: id,
      title: name,
      listingPrice: coinsToMoney(price),
      imageUrls: photos.map(({ imageUrl }) => imageUrl),
    };

    this.listingId = this.data.listingId;
  }

  get imageSrc() {
    return this.data.imageUrls[0];
  }

  get title() {
    return this.data.title;
  }

  get isSold() {
    return this.data.isSold;
  }

  get price() {
    return this.data.listingPrice;
  }

  get listingStatus() {
    return this.isSold ? ENDED : ACTIVE;
  }

  loadAdditionalFields() {
    const extension = new MercariExtension();

    return extension
      .importProductDetails([this.data.listingId])
      .then((resp) => {
        const { result } = resp;
        const itemData = result[0];

        const {
          description,
          photos,
          shippingClasses,
          customItemFieldsMetadata,
          shippingPackageDimension,
          itemSize,
          tags,
          shippingPackageWeight,
          shippingPayer,
          brand,
          seller,
          itemCategory,
          itemCondition,
          minPriceForAutoPriceDrop, //this is missing
          created,
          buyer = null,
          orderDetails = null,
        } = itemData;
        const { id: brandId } = brand || {};
        const { zipCode } = seller || {};
        const soldTimestamp =
          orderDetails.orderStatus?.transactionEvidence?.created;
        const soldDateTime = new Date(soldTimestamp);
        const switchDate = new Date("2024-03-27");

        let data = {
          description,
          brandId,
          tags: tags.tags,
          sizeId: itemSize?.id,
          categoryId: itemCategory?.id,
          shipsFrom: zipCode,
          shippingId: shippingClasses[0]?.id,
          conditionId: itemCondition?.id,
          imageUrls: photos.map(({ imageUrl }) => imageUrl),
          ...(customItemFieldsMetadata && {
            colorId: customItemFieldsMetadata?.colorId,
          }),
          ...(shippingPackageDimension && {
            width: shippingPackageDimension?.width || undefined,
            height: shippingPackageDimension?.height || undefined,
            depth: shippingPackageDimension?.length || undefined,
          }),
          ...(shippingPackageWeight && {
            weightOz: shippingPackageWeight.weight,
          }), //unit: "OUNCE"
          ...(shippingPayer && {
            shippingPayer: shippingPayer.id,
          }),
          floorPrice: minPriceForAutoPriceDrop
            ? coinsToMoney(minPriceForAutoPriceDrop)
            : undefined,
          howToShip: shippingClasses[0]?.id ? PREPAID_LABEL : SHIP_ON_YOUR_OWN,
          firstListedAt: MercariProduct.convertDate(created),
          ...(orderDetails && {
            priceSold: coinsToMoney(
              orderDetails.orderStatus?.transactionEvidence?.price
            ),
            shippingExpenses:
              coinsToMoney(
                orderDetails.orderStatus?.transactionEvidence
                  ?.sellerShippingFee || 0
              ) || null,
            // Selling fee
            marketplaceFees: coinsToMoney(
              orderDetails.item?.salesFees[0]?.calculatedFee || 0
            ),
            //by hardcoded formula
            // transactionFees: calcTransactionFeesByFormulaForMercari(coinsToMoney(orderDetails.orderStatus.transactionEvidence.price)),

            //by marketplace value Processing fee
            transactionFees:
              coinsToMoney(
                orderDetails.item?.salesFees[1]?.calculatedFee || 0
              ) || null,
            buyer: buyer?.buyerName || "",
            dateSold: orderDetails.orderStatus?.transactionEvidence?.created,
            // "itemCost" Mercari is not supported this value
          }),
        };

        if (soldDateTime.getTime() >= switchDate.getTime()) {
          data = {
            ...data,
            ...(orderDetails && {
              youMade: coinsToMoney(
                orderDetails.orderStatus?.transactionEvidence?.youMadePrice
              ),
            }),
          };
        }

        Object.assign(this.data, data);

        this.onBeforeSave();
      })
      .catch((e) => {
        Bugsnag.notify(e);
      });
  }
}

export default MercariProduct;
