import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

import CountEntries from "../../../../../../components/CountEntries";
import CustomButton from "../../../../../../../../base/components/CustomButton";
import { useProfile } from "../../../../../../../profile/hooks/useProfile";
import { SUBSCRIPTIONS_STATUSES } from "../../constants";
import { useStatelessProvider } from "../../../../../../../../base/hooks/useProvider";
import { TableContext } from "../../../../../../contexts/table";

const TableHeader = ({ onAddEmployee }) => {
  const [
    {
      activeSubscription: { type },
    },
  ] = useProfile();
  const [{ total }] = useStatelessProvider(TableContext);

  const isDisabledAddButton = {
    [SUBSCRIPTIONS_STATUSES.trial]: false,
    [SUBSCRIPTIONS_STATUSES.standard]: true,
    [SUBSCRIPTIONS_STATUSES.business]: total >= 1,
    [SUBSCRIPTIONS_STATUSES.unlimited]: false,
    [SUBSCRIPTIONS_STATUSES.expired]: true,
    [SUBSCRIPTIONS_STATUSES.beta]: false,
  };

  return (
    <div className="d-flex drafts-list-toolbar">
      <span className={styles.tableTitle}>Employee list</span>
      <div className={styles.headerCounters}>
        <CountEntries count={total}>Current employees</CountEntries>
        <CustomButton
          onClick={onAddEmployee}
          className="filled-primary"
          disabled={isDisabledAddButton[type]}
        >
          + Add Employee
        </CustomButton>
      </div>
    </div>
  );
};

TableHeader.propTypes = {
  onAddEmployee: PropTypes.func,
};

export default TableHeader;
