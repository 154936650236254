import React from "react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";

import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../base/components/CustomModal";
import FormikInput from "../../../../base/components/FormikInput";
import CustomButton from "../../../../base/components/CustomButton";
import InfoPriceItem from "../../../drafts/hooks/useProductStatus/components/SoldModal/components/InfoPriceItem";

import { validationSchema, initialValues, formFields } from "./form";
import { MIN_TITLE, MAX_TITLE } from "./constants";
import FormikDecimal from "../../../../base/components/FormikDecimal";
import DateField from "../DateField";

const defaultModalStrings = {
  title: "Create mileage",
  closeBtn: "Cancel",
  confirmBtn: "Save",
};

const CreateMileage = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  modalStrings = defaultModalStrings,
  defaultFormValues,
}) => {

  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <Formik
        initialValues={defaultFormValues || initialValues}
        validationSchema={validationSchema}
        validateOnChange={true}
        onSubmit={() => {}}
      >
        {({
          handleSubmit,
          values,
          dirty,
          setFieldValue,
          touched,
          errors,
          resetForm,
        }) => {
          const isErrors = !isEmpty(errors);
          const titleIsEmpty = values?.[formFields.title] === "";
          const mileageIsEmpty = values?.[formFields.category] === 0;

          const isDisableSave =
            isErrors || titleIsEmpty || mileageIsEmpty || isLoading || !dirty;
          return (
            <>
              <ModalHeader onClose={onClose}>{modalStrings.title}</ModalHeader>
              <ModalBody>
                <form className="form-horizontal expense-form" onSubmit={handleSubmit}>
                  <FormikInput
                    id={formFields.title}
                    name={formFields.title}
                    label="Title*"
                    containerClassName="mb-4"
                    placeholder="Add title"
                    min={MIN_TITLE}
                    max={MAX_TITLE}
                    maxLength={MAX_TITLE}
                  />
                  <FormikDecimal
                    name={formFields.mileage}
                    label="Mileage*"
                    containerClassName="mb-4"
                  />
                  <DateField
                    name="mileageMadeAt"
                    id="mileageMadeAt"
                    selected={values[formFields.mileageMadeAt]}
                    maxDate={new Date()}
                  />
                </form>
              </ModalBody>
              <ModalFooter>
                <CustomButton
                  onClick={(event) => onClose(event, dirty && touched)}
                  className="outline-primary "
                >
                  {modalStrings.closeBtn}
                </CustomButton>
                <CustomButton
                  onClick={(event) => onSubmit(values, resetForm, event)}
                  disabled={isDisableSave}
                  className="filled-primary "
                >
                  {modalStrings.confirmBtn}
                </CustomButton>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </CustomModal>
  );
};

CreateMileage.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  modalStrings: PropTypes.object,
  defaultFormValues: PropTypes.object,
};

export default CreateMileage;
