/* eslint-disable react/prop-types */
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { InputGroupAddon } from "reactstrap";
import { useField } from "formik";

import CustomInput from "../../../../base/components/Input";
import { useTranslate } from "../../../../base/hooks/useTranslate";
import ErrorMessageDefault from "../../../../base/components/ErrorMessageDefault";
import {
  DEFAULT_MAX_NUMBER,
  DEFAULT_MIN_NUMBER,
  DEFAULT_STEP,
} from "../../../../base/constants/forms";
import { useTriggerFormChanges } from "../../hooks/useDetectDirtyForm";
import DotsLoader from "../../../../base/components/DotLoader";

const FormikGroupAddon = ({
  placeholder,
  groupText,
  name,
  maxLength,
  readOnly,
  label,
  disabled,
  containerClassName,
  inputClassName,
  loading = false,
  type = "number",
  onChange = () => {},
  step = DEFAULT_STEP,
  min = DEFAULT_MIN_NUMBER,
  max = DEFAULT_MAX_NUMBER,
  description = "",
  isHideLabel = false,
  isFieldOnChange = true,
  onKeyPress = () => {},
  isHideAddon = false,
  isDiscountShippingClicked = false,
}) => {
  const [field, meta, { setValue }] = useField({ name, type, placeholder });
  const isError = meta.error && meta.touched;
  const [translate] = useTranslate();
  const { markChanges, containerRef } = useTriggerFormChanges();

  const handleOnChange = (event) => {
    if (isFieldOnChange) {
      field.onChange(event);
    }
    markChanges();
    onChange(event, setValue);
  };

  return (
    <section
      className={classnames(containerClassName, "custom-form-group")}
      ref={containerRef}
    >
      {!isHideLabel && (
        <label className={isError ? "text-danger" : ""}>
          {label} {loading && <DotsLoader />}
        </label>
      )}
      <div
        className={classnames(inputClassName, "d-flex", {
          "is-invalid-input is-invalid": isError,
        })}
      >
        <CustomInput
          {...field}
          onChange={handleOnChange}
          type={type}
          className={classnames("custom-input-addon", {
            "is-invalid-input is-invalid": isError,
          })}
          maxLength={maxLength}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeholder}
          step={step}
          min={min}
          max={max}
          isDiscountShippingClicked={isDiscountShippingClicked}
          onKeyPress={onKeyPress}
          onWheel={(e) => e.currentTarget.blur()}
        />
        {!isHideAddon && (
          <InputGroupAddon
            className="d-flex flex-column-reverse custom-input-group-addon"
            addonType="append"
          >
            {groupText}
          </InputGroupAddon>
        )}
      </div>
      {description && (
        <p
          className={classnames("font-size-10 text-gray-gomi mb-1", {
            "text-danger": isError,
          })}
        >
          {description}
        </p>
      )}
      <ErrorMessageDefault>
        {translate(meta.error, { label })}
      </ErrorMessageDefault>
    </section>
  );
};

FormikGroupAddon.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  groupText: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  isPassword: PropTypes.bool,
  onChange: PropTypes.func,
  ErrorMessage: PropTypes.any,
  step: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  isHideLabel: PropTypes.bool,
  isFieldOnChange: PropTypes.bool,
  onKeyPress: PropTypes.func,
  isHideAddon: PropTypes.bool,
};

export default FormikGroupAddon;
