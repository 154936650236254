export const MOMENT_HOUR = "hour";
export const MOMENT_DAY = "day";
export const MOMENT_MONTH = "month";
export const MOMENT_QUARTER = "quarter";
export const MOMENT_YEAR = "year";

export const BOOKKEEPING_DATE_FORMAT = "MMM D, YYYY";
export const BOOKKEEPING_INTERVAL_START_DATE_FORMAT = "MMM";
export const BOOKKEEPING_INTERVAL_CURRENT_QUARTER_START_DATE_FORMAT = "D, MMM";
export const BOOKKEEPING_INTERVAL_CURRENT_QUARTER_END_DATE_FORMAT =
  "D, MMM, YYYY";
export const BOOKKEEPING_INTERVAL_END_DATE_FORMAT = "MMM, YYYY";
export const BOOKKEEPING_INTERVAL_BY_MONTH_FORMAT = "MMM YYYY";

export const REPORT_PERIOD_OPTIONS_IDS = {
  today: "today",
  thisWeek: "thisWeek",
  thisMonth: "thisMonth",
  thisYear: "thisYear",
  thisYearToDate: "thisYearToDate",
  quarter1: "quarter-1",
  quarter2: "quarter-2",
  quarter3: "quarter-3",
  quarter4: "quarter-4",
  custom: "custom",
};

export const REPORT_PERIOD_OPTIONS = [
  { id: REPORT_PERIOD_OPTIONS_IDS.today, label: "Today" },
  { id: REPORT_PERIOD_OPTIONS_IDS.thisWeek, label: "This week" },
  { id: REPORT_PERIOD_OPTIONS_IDS.thisMonth, label: "This month" },
  { id: REPORT_PERIOD_OPTIONS_IDS.thisYear, label: "This year" },
  { id: REPORT_PERIOD_OPTIONS_IDS.thisYearToDate, label: "This Year-to Date" },
  { id: REPORT_PERIOD_OPTIONS_IDS.quarter1, label: "Quarter 1" },
  { id: REPORT_PERIOD_OPTIONS_IDS.quarter2, label: "Quarter 2" },
  { id: REPORT_PERIOD_OPTIONS_IDS.quarter3, label: "Quarter 3" },
  { id: REPORT_PERIOD_OPTIONS_IDS.quarter4, label: "Quarter 4" },
  { id: REPORT_PERIOD_OPTIONS_IDS.custom, label: "Custom" },
];

export const DISPLAY_COLUMN_STATUSES = {
  totalOnly: 1,
  months: 2,
  quarters: 3,
  years: 4,
};

export const DISPLAY_COLUMN_OPTIONS = [
  { id: DISPLAY_COLUMN_STATUSES.totalOnly, label: "Total only" },
  { id: DISPLAY_COLUMN_STATUSES.months, label: "Months" },
  { id: DISPLAY_COLUMN_STATUSES.quarters, label: "Quarters" },
  { id: DISPLAY_COLUMN_STATUSES.years, label: "Years" },
];

export const ACCOUNTING_METHODS = {
  accrual: 1,
  cash: 2,
};

export const MOMENT_QUARTERS = {
  firstQuarter: 1,
  secondQuarter: 2,
  thirdQuarter: 3,
  fourthQuarter: 4,
};

export const DEFAULT_DECIMAL_PART_LIMIT = 2;
