import React from 'react'
import { Link } from 'react-router-dom';

import { AUTH_GROUP_LINKS } from '../config';

const SignUpFooter = () => {
  return (
    <div  className='sign-up-footer text-center'>
      <p>
        Already have an account ?{" "}
        <Link
          to={AUTH_GROUP_LINKS.LINK_LOGIN}
          className="font-weight-medium"
        >
          Sign in now
        </Link>
      </p>
    </div>
  )
};

export default SignUpFooter;