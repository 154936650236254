import React, { useCallback, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useLoading } from "../../../base/hooks/useLoading";
import { useService } from "../../../base/hooks/useService";
import MileagesService from "../../../services/MileageService";
import CreateMileage from "../components/CreateMileage";

const editModalStrings = {
  title: "Edit mileage",
  closeBtn: "Cancel",
  confirmBtn: "Save",
};

export const useEditMileage = (mileages, refresh) => {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [loading, { registerPromise }] = useLoading();

  const getMileageDataById = (id, mileages) => {
    return mileages.find((mileage) => mileage.id === id);
  };

  const onOpenEditModal = useCallback(
    (id) => {
      setSelectedId(id);
      setIsOpenEditModal(true);
    },
    [setSelectedId, setIsOpenEditModal]
  );

  const onCloseEditModal = useCallback(() => {
    setIsOpenEditModal(false);
  }, [setIsOpenEditModal]);

  /**
   * @type {MileagesService}
   */
  const mileagesService = useService(MileagesService);

  const onSaveChanges = useCallback(
    (values) => {
      const formattedValues = {
        title: values.title,
        mileageQuantity: +values.mileageQuantity,
        mileageMadeAt: values.mileageMadeAt,
      };

      registerPromise(mileagesService.editMileage(selectedId, formattedValues))
        .then(() => {
          onCloseEditModal();
          refresh();
        })
        .catch((e) => Bugsnag.notify(e));
    },
    [registerPromise, mileagesService, selectedId, onCloseEditModal, refresh]
  );

  const editModal = (
    <CreateMileage
      isOpen={isOpenEditModal}
      onClose={onCloseEditModal}
      onSubmit={onSaveChanges}
      isLoading={loading}
      modalStrings={editModalStrings}
      defaultFormValues={getMileageDataById(selectedId, mileages)}
    />
  );

  return [onOpenEditModal, editModal];
};
