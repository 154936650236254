import React, { useState } from "react";
import { Card, CardBody, Container } from "reactstrap";

import FormWizard from "./components/FormWizard";
import AuthHeader from "../auth/components/Header";
import Secure from "./pages/Secure";
import AvatarStep from "./pages/AvatarStep";
import Instruction from "./pages/Instruction";
import PageMeta from "../../base/components/MetaTags";
import { useService } from "../../base/hooks/useService";
import StorageService from "../../services/StorageService";
import { KEY_USER } from "../../base/constants/storage";
import { ROLES } from "../auth/constants/roles";

import "./index.scss";

const steps = [
  {
    id: 1,
    Component: Secure,
    label: "Secure",
  },
  {
    id: 2,
    Component: AvatarStep,
    label: "Upload photo",
  },
  {
    id: 3,
    Component: Instruction,
    label: "Instructions",
  },
];

const employeeSteps = [
  {
    id: 1,
    Component: AvatarStep,
    label: "Upload photo",
  },
  {
    id: 2,
    Component: Instruction,
    label: "Instructions",
  },
];

const getSteps = (role) => (role === ROLES.employee ? employeeSteps : steps);

const CompleteProfile = () => {
  const storageSerice = useService(StorageService);
  const [role] = useState(() => storageSerice.get(KEY_USER)?.role);

  return (
    <div className="account-pages">
      <Container>
        <PageMeta title="Complete profile" />
        <div className="complete-profile-wrapper">
          <Card className="overflow-hidden">
            <AuthHeader headerText="Complete profile" />
            <CardBody className="pt-0 video-instruction-container">
              <FormWizard steps={getSteps(role)} />
            </CardBody>
          </Card>
        </div>
      </Container>
    </div>
  );
};

export default CompleteProfile;
