export const RETURN_STATUSES = {
  IN_PROGRESS: 1,
  RETURNED: 2,
};

export const DEFAULT_RETURNS_LIMIT = 10;

export const RETURN_STATUSES_VARIANTS = [
  { label: "In progress", id: 1 },
  { label: "Completed", id: 2 },
];

export const RETURN_MARKETPLACES_VARIANTS = [
  { label: "eBay", id: 1 },
  { label: "Etsy", id: 2 },
  { label: "Poshmark", id: 3 },
  { label: "Mercari", id: 4 },
  { label: "Other", id: 5 },
];

export const RETURN_DATE_FORMAT = "MM/DD/YYYY";
export const RETURN_MASK_FORMAT = "99/99/9999";

export const IN_PROGRESS_STATUS = "In progress";
export const COMPLETED_STATUS = "Completed";

export const RETURN_STATUSES_LIST = {
  [RETURN_STATUSES.IN_PROGRESS]: IN_PROGRESS_STATUS,
  [RETURN_STATUSES.RETURNED]: COMPLETED_STATUS,
};

export const FRACTION_DIGITS = 2;

export const IN_PROGRESS_TAB = 0;
export const COMPLETED_TAB = 1;
