import PropTypes from "prop-types";
import GenerateSidebarLinks from "./GenerateSidebarLinks";

/*const exampleLinks = [
  {
    label: "SIDEBAR.PROFILE",
    linkTo: LINK_TO_PROFILE_ROOT,
    children: [
      {
        label: "SIDEBAR.AVATAR",
        linkTo: LINK_TO_CHANGE_AVATAR_PAGE,
      },
      {
        label: "SIDEBAR.CHANGE_PASSWORD",
        linkTo: LINK_TO_CHANGE_PASSWORD_PAGE,
      },
    ],
  },
  {
    label: "SIDEBAR.USERS",
    linkTo: LINK_TO_USERS_ROOT,
  },
  { label: "SIDEBAR.PAYMENTS", linkTo: "/app/settings" },
];*/

const SidebarContent = ({ type, schema = [] }) => {
  return GenerateSidebarLinks(schema, type);
};

SidebarContent.propTypes = {
  type: PropTypes.string,
  schema: PropTypes.any,
};

export default SidebarContent;
