import React, { useState } from "react";
import PropTypes from "prop-types";
import AnimateHeight from "react-animate-height";

import styles from "./index.module.scss";
import { ReactComponent as ArrowDown } from "./images/arrow-down.svg";
import { ReactComponent as ArrowTop } from "./images/arrow-top.svg";
import {
  CLOSED_DROPDOWN,
  DROPDOWN_DURATION,
  OPENED_DROPDOWN,
} from "./constants";

const DropdownText = ({ title, description }) => {
  const [height, setHeight] = useState(CLOSED_DROPDOWN);

  const onToggleIsOpen = React.useCallback(
    () =>
      setHeight(height === CLOSED_DROPDOWN ? OPENED_DROPDOWN : CLOSED_DROPDOWN),
    [setHeight, height]
  );

  return (
    <>
      <button
        className={styles.titleWrapper}
        aria-expanded={height !== CLOSED_DROPDOWN}
        aria-controls="example-panel"
        onClick={onToggleIsOpen}
      >
        <span className={styles.title}>{title}</span>
        {height === OPENED_DROPDOWN ? <ArrowTop /> : <ArrowDown />}
      </button>
      <AnimateHeight
        id="example-panel"
        duration={DROPDOWN_DURATION}
        height={height}
      >
        <span className={styles.description}>{description}</span>
      </AnimateHeight>
      <div className={styles.divider} />
    </>
  );
};

DropdownText.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default DropdownText;
