import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardBody, Row, Col } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import "./index.scss";
import { useService } from "../../../../base/hooks/useService";
import AnalyticsService from "../../../../services/AnalyticsService";
import Performance from "./Performance";
import { separateThousandth } from "../../helpers/separateThousandth";
import { COUNT_DIGITS } from "../../constants";
import { ReactComponent as Edit } from "../../../taskManagement/components/Icons/Edit.svg";
import { ReactComponent as Save } from "../../assets/images/icon-16-save.svg";
import { Formik } from "formik";
import * as yup from "yup";
import { DRAFT_PRICE } from "../../../../validation/draft";
import { usePriceValidation } from "../../../../base/hooks/usePriceValidation";
import FormikGroupAddon from "../../../drafts/components/GroupAddon";
import { Progress } from "../../../../base/components/Charts";

const validationSchema = yup.object().shape({
  monthGoal: DRAFT_PRICE.nullable().required(),
});

const PerformanceMonthlyGoal = () => {
  const [performance, updatePerformance] = useState({});
  const [isEditMode, updateIsEditMode] = useState(false);

  const [onChangeWithValidation, onKeyPress] = usePriceValidation({});

  /**
   * @type {AnalyticsService}
   */
  const analytics = useService(AnalyticsService);

  const getAnalytics = useCallback(() => {
    analytics.getAnalyticsForCurrentMonth().then(({ data }) => {
      updatePerformance(data);
    });
  }, [analytics, updatePerformance]);

  useEffect(() => {
    getAnalytics();
  }, []);

  const goalReached = useMemo(() => {
    return performance?.monthGoal && performance?.currentMonthSales
      ? (
          (performance?.currentMonthSales / performance?.monthGoal) *
          100
        )?.toFixed(COUNT_DIGITS)
      : 0;
  }, [performance?.monthGoal, performance?.currentMonthSales]);

  const handleSave = useCallback(
    (goal, isValid) => {
      if (!isValid) return;
      analytics
        .setMonthGoalForCurrentMonth(goal)
        .then(() => {
          updateIsEditMode(false);
          getAnalytics();
        })
        .catch((e) =>
          Bugsnag.notify(e, (event) => {
            event.addMetadata("handleSaveError", {
              goal,
            });
          })
        );
    },
    [updateIsEditMode, getAnalytics, analytics]
  );

  return (
    <Card>
      <CardBody>
        <Row>
          <Col className={"analytics-chart-container overflow-hidden"}>
            <h3 className="font-size-19 font-weight-semibold title-color">
              Monthly goal
            </h3>
            <Row>
              <Col md={4} className="mt-4">
                {!isEditMode ? (
                  <p className="d-flex align-items-center font-size-22 fw-bold title-text-color mb-0 top-layer">
                    {separateThousandth(performance?.monthGoal) || "Add goal"}
                    <span
                      className={
                        "cursor-pointer ml-3 align-items-center top-layer"
                      }
                    >
                      <Edit onClick={() => updateIsEditMode(true)} />
                    </span>
                  </p>
                ) : (
                  <Formik
                    initialValues={{ monthGoal: performance?.monthGoal || "" }}
                    validationSchema={validationSchema}
                    onSubmit={() => {}}
                  >
                    {({ values, isValid }) => (
                      <div className="moth-goal-field d-flex ">
                        <span className="font-size-22 fw-bold title-text-color mb-0">
                          $
                        </span>
                        <FormikGroupAddon
                          label="Month goal"
                          name="monthGoal"
                          groupText="$"
                          onChange={(event, setValue) =>
                            onChangeWithValidation(event, setValue)
                          }
                          onKeyPress={onKeyPress}
                          isFieldOnChange={false}
                          placeholder="00.00"
                          isHideLabel
                          isHideAddon
                        />
                        <span
                          className={`${
                            isValid ? "cursor-pointer" : ""
                          } ml-1 mt-2 top-layer`}
                        >
                          <Save
                            onClick={() =>
                              handleSave(values.monthGoal, isValid)
                            }
                          />
                        </span>
                      </div>
                    )}
                  </Formik>
                )}
              </Col>
              <Col md={8}>
                <Progress total={100} current={goalReached}>
                  <Row>
                    <p className="mb-0 text-muted font-size-13 text-center">
                      Goal reached:
                    </p>
                  </Row>
                  <Row className="mt-1 mb-2">
                    <p className="mb-0 font-size-22 fw-bold title-text-color text-center">
                      {goalReached + "%"}
                    </p>
                  </Row>
                  <Row>
                    <p className="mb-0 text-muted font-size-13 text-center">
                      To be On Track:
                      <span className="ml-1 font-size-16 fw-bold">
                        {separateThousandth(
                          performance?.salesNeededToBeOnTrack
                        )}
                      </span>
                    </p>
                  </Row>
                </Progress>
              </Col>
            </Row>
          </Col>
          <Col className={"analytics-chart-container"}>
            <Performance performance={performance} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default PerformanceMonthlyGoal;
