import { useCallback, useMemo, useState } from "react";

import { ONLY_NUMBERS_AND_EMPTY } from "../../pages/drafts/hooks/useProductStatus/components/SoldModal/constants";
import {
  MAX_VALIDATION_NUMBER,
  MIN_VALIDATION_NUMBER,
} from "../constants/forms";
import { ONLY_NUMBERS_AND_DECIMAL_SEPARATORS } from "../constants/patterns";

const DOUBLE_NULL = "00";
const ON_KEY_PRESS_METHOD_MAP = {
  IS_VALID_EVENT_KEY: 1,
  IS_VALID_DECIMAL_PART: 2,
};
const MAX_DECIMAL_PART_LENGTH = 2;

export const usePriceValidation = ({
  maxValue = MAX_VALIDATION_NUMBER,
  mimValue = MIN_VALIDATION_NUMBER,
  maxDecimalPartLength = MAX_DECIMAL_PART_LENGTH,
  isStringFormatForReturn = false,
}) => {
  const [selectedFieldName, setSelectedFieldValue] = useState("");
  const [onKeyPressMethodStatus, setKeyPressMethodStatus] = useState(
    ON_KEY_PRESS_METHOD_MAP.IS_VALID_EVENT_KEY
  );

  const isValidPrice = (value) => {
    const stringValue = value.toString().trim();
    return ONLY_NUMBERS_AND_EMPTY.test(stringValue);
  };

  const isValidDecimalPart = useCallback(
    (value) => {
      const [, decimalPart] = value.split(/[,.]/);

      if (!decimalPart) return true;

      const isMaxLengthOfDecimalPart =
        decimalPart?.length >= maxDecimalPartLength;

      if (isMaxLengthOfDecimalPart) {
        setKeyPressMethodStatus(ON_KEY_PRESS_METHOD_MAP.IS_VALID_DECIMAL_PART);
      } else {
        setKeyPressMethodStatus(ON_KEY_PRESS_METHOD_MAP.IS_VALID_EVENT_KEY);
      }
      return true;
    },
    [maxDecimalPartLength]
  );

  const isValidEventKey = (key) => {
    return ONLY_NUMBERS_AND_DECIMAL_SEPARATORS.test(key);
  };

  const onTrimDoubleNulls = (string) => {
    const firstTwoSymbols = string.substring(0, 3);

    if (firstTwoSymbols === DOUBLE_NULL) {
      return string.slice(1, string.length);
    }

    return string;
  };

  const onDisableButtonPress = (event) => {
    return event.preventDefault();
  };

  const onChangeWithValidation = useCallback(
    (event, callback) => {
      const {
        target: { value, name },
      } = event;

      if (!value) {
        setKeyPressMethodStatus(ON_KEY_PRESS_METHOD_MAP.IS_VALID_EVENT_KEY);
      }

      setSelectedFieldValue(name);

      const isNotValidValue =
        !isValidPrice(value) ||
        !isValidDecimalPart(value) ||
        value > maxValue ||
        value < mimValue;

      if (isNotValidValue) {
        return onDisableButtonPress(event);
      }

      if (isStringFormatForReturn) {
        callback(value);
        return;
      }

      const cutValue = onTrimDoubleNulls(value.toString());

      if (!cutValue) {
        return callback("");
      }

      callback(+value || +cutValue);
    },
    [maxValue, mimValue, isStringFormatForReturn, isValidDecimalPart]
  );

  const onKeyPressMethodMap = useMemo(() => {
    const methodsMap = {
      [ON_KEY_PRESS_METHOD_MAP.IS_VALID_EVENT_KEY]: (event) => {
        if (!isValidEventKey(event.key)) {
          return onDisableButtonPress(event);
        }
        return event;
      },
      [ON_KEY_PRESS_METHOD_MAP.IS_VALID_DECIMAL_PART]: (event) => {
        const isManualSelectedValue = window.getSelection().toString();

        const isDifferentInput = event?.target?.name !== selectedFieldName;

        if (isManualSelectedValue) {
          setKeyPressMethodStatus(ON_KEY_PRESS_METHOD_MAP.IS_VALID_EVENT_KEY);
          return event;
        }

        if (isDifferentInput) {
          setKeyPressMethodStatus(ON_KEY_PRESS_METHOD_MAP.IS_VALID_EVENT_KEY);
          if (!isValidEventKey(event.key)) {
            return onDisableButtonPress(event);
          }
          return event;
        }

        return onDisableButtonPress(event);
      },
    };

    return methodsMap[onKeyPressMethodStatus];
  }, [onKeyPressMethodStatus, selectedFieldName]);

  return [onChangeWithValidation, onKeyPressMethodMap];
};
