/* eslint-disable react/prop-types */
import FormikSingleSelect from "../../../../../base/components/FormikSingleSelect";
import FormikInput from "../../../../../base/components/FormikInput";
import React, { useEffect } from "react";
import { useField } from "formik";
import { Col, Row } from "reactstrap";
import FormikGroupAddon from "../../GroupAddon";
import FormikMultiSelect from "../../../../../base/components/FormikMultiSelect";

const SIZE_LABEL = "Size";

// for etsy { "value_ids": [number], "scale_id?": number, values?: [] }
const RenderCustomField = ({
  suggestedFields,
  label,
  name,
  options = null,
  isRequired,
  shouldSetInitialOption = false,
  maxLength,
  mode = "",
  allowCreate,
  textFieldName = ".values[0]",
  isMulti,
  customComponents = {},
}) => {
  const fieldName = name;

  const validate = isRequired
    ? (value) => !value && `${label} is required`
    : undefined;
  const Custom = customComponents[label];

  const [field] = useField({ name: fieldName });

  const initialOption = {
    label: field?.value?.scale_id,
    id: field?.value?.scale_id,
    variant: undefined,
  };

  const [, , { setValue }] = useField({ name: "sizeName" });
  const [, , { setValue: setValueSizeStart }] = useField({
    name: "sizeNameStart",
  });
  const [, , { setValue: setValueSizeEnd }] = useField({ name: "sizeNameEnd" });

  const [, , { setTouched }] = useField({
    name: fieldName.concat(".scale_id"),
  });
  const nestedOption =
    options &&
    field.value &&
    options.find(({ id }) => id === field.value?.scale_id);
  const nestedOptions = nestedOption?.variants;
  const Select = isMulti ? FormikMultiSelect : FormikSingleSelect;

  // Init first part
  useEffect(() => {
    if (label === SIZE_LABEL) setValueSizeStart(nestedOption?.label || "");
  }, [label, nestedOption?.label]);

  const handleChange = (value) => {
    setValue(value?.label || "");
    if (label === SIZE_LABEL) setValueSizeEnd(value?.label);
  };

  if (Custom) {
    return (
      <Custom
        validate={validate}
        options={options}
        suggestedFields={suggestedFields}
      />
    );
  }

  if (nestedOptions) {
    const isSelect = !!nestedOptions.length;

    return (
      <Row>
        <Col md="6">
          <Select
            name={fieldName.concat(".scale_id")}
            label={label}
            placeholder={`Select ${label.toLowerCase()}`}
            options={options}
            onBlur={() => {
              setTouched(true);
            }}
            validate={validate}
            initialOption={
              field?.value && shouldSetInitialOption ? initialOption : null
            }
            allowCreate={allowCreate}
            onChange={(value) => {
              if (label === SIZE_LABEL) {
                setValueSizeStart(value?.label);
                setValueSizeEnd("");
              }
            }}
          />
        </Col>

        <Col md="6">
          {isSelect ? (
            <Select
              name={fieldName.concat(".value_ids[0]")}
              label={" "}
              validate={validate}
              initialOption={
                field?.value && shouldSetInitialOption ? initialOption : null
              }
              placeholder={`Select ${label.toLowerCase()}`}
              onBlur={() => {
                setTouched(true);
              }}
              options={nestedOptions}
              allowCreate={allowCreate}
              onChange={handleChange}
            />
          ) : (
            <FormikGroupAddon label=" " name={fieldName.concat(".values[0]")} />
          )}
        </Col>
      </Row>
    );
  }

  if (options) {
    return (
      <Select
        name={fieldName.concat(".scale_id")}
        label={label}
        validate={validate}
        onBlur={() => {
          setTouched(true);
        }}
        initialOption={
          field?.value && shouldSetInitialOption ? initialOption : null
        }
        placeholder={`Select ${label.toLowerCase()}`}
        options={options}
        allowCreate={allowCreate}
      />
    );
  }

  return (
    <FormikInput
      name={fieldName.concat(textFieldName)}
      label={label}
      validate={validate}
      onBlur={() => {
        setTouched(true);
      }}
      initialOption={
        field?.value && shouldSetInitialOption ? initialOption : null
      }
      containerClassName="mb-3"
      placeholder={"Enter " + label}
      maxLength={maxLength}
      type="text"
    />
  );
};

export default RenderCustomField;
