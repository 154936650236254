import { ArcElement, Chart, DoughnutController, Legend, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useRef } from "react";

Chart.register(
  ArcElement,
  DoughnutController,
  Legend,
  Tooltip,
  ChartDataLabels // Need
);


export const useChart = (data, { labels }) => {
  const ref = useRef(null);


  useEffect(() => {
    const ctx = ref.current.getContext("2d");

    const config = {
      type: "doughnut",
      data: data,
      options: {
        // rotation: -90,
        // circumference: 180,

        responsive: true,
        layout: {
          padding: 80
        },
        elements: {},
        plugins: {
          legend: {
            display: false
          },
          datalabels: {
            labels
          }
        }
      }
    };

    const chart = new Chart(ctx, {
      ...config

    });

    return () => {
      chart.destroy();
    };
  }, [ref, labels, data]);


  return { ref };
};
