import { useCallback, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useLoading } from "../../../base/hooks/useLoading";
import { useService } from "../../../base/hooks/useService";
import MileagesService from "../../../services/MileageService";

export const useCreateMileage = ({ refresh }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loading, { registerPromise }] = useLoading();

  const onCloseModal = useCallback(() => {
    setIsOpenModal(false);
  }, [setIsOpenModal]);

  const onOpenModal = useCallback(() => {
    setIsOpenModal(true);
  }, [setIsOpenModal]);

  /**
   * @type {MileagesService}
   */
  const mileagesService = useService(MileagesService);

  const onCreate = useCallback(
    (values, resetForm) => {
      const formattedValues = {
        title: values.title,
        mileageQuantity: +values.mileageQuantity,
        mileageMadeAt: values.mileageMadeAt,
      };

      registerPromise(mileagesService.createMileage(formattedValues))
        .then(() => {
          resetForm();
          onCloseModal();
          refresh();
        })
        .catch((e) => Bugsnag.notify(e));
    },
    [registerPromise, mileagesService, onCloseModal, refresh]
  );

  return [onCreate, { isOpenModal, onCloseModal, onOpenModal }, loading];
};
