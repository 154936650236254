import { useCallback, useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import CreditCardService from "../../../../services/CardService";
import { useLoading } from "../../../hooks/useLoading";
import { useService } from "../../../hooks/useService";

export const useCreditCardData = () => {
  const creditCardService = useService(CreditCardService);
  const [loading, { registerPromise }] = useLoading(true); // true

  const [creditCard, updateCreditCard] = useState({});

  const handleUpdateCreditCard = useCallback(() => {
    registerPromise(
      creditCardService
        .getCard()
        .then(({ data }) => updateCreditCard(data))
        .catch((e) => Bugsnag.notify(e))
    );
  }, [creditCardService, registerPromise]);

  useEffect(() => {
    handleUpdateCreditCard();
  }, [handleUpdateCreditCard]);

  return { loading, creditCard, handleUpdateCreditCard };
};
