import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import Bugsnag from "@bugsnag/js";

import SecureContent from "../components/Secure";
import CustomButton from "../../../base/components/CustomButton";

import AuthService from "../../../services/AuthService";
import StorageService from "../../../services/StorageService";
import { useLoading } from "../../../base/hooks/useLoading";
import { useService } from "../../../base/hooks/useService";
import { getValidationSchema } from "../components/Secure/form";
import {
  CODE_NAME_KEY,
  DEFAULT_START_PHONE_NUMBER,
  EMPTY_STRING,
  ERROR_SMS_CODE,
  PHONE_STORAGE_KEY,
} from "../../../base/constants/forms";
import {
  useCleanCustomErrors,
  useCustomError,
} from "../../../base/contexts/CustomErrorMessage";
import { useIsDev } from "../../../base/hooks/useIsDev";

const Secure = ({ steps, TopNavigation, onClickNext }) => {
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);

  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const isDev = useIsDev();

  const { setError } = useCustomError();
  useCleanCustomErrors();

  const [, { registerPromise }] = useLoading();

  const [isDisabledButton, updateIsDisabledButton] = useState(true);

  const handleDisabledChange = useCallback((isValid) => {
    updateIsDisabledButton(!isValid);
  }, []);

  const handleSubmit = useCallback(
    (values) => {
      registerPromise(authService.verifyPhoneNumber(values))
        .then(onClickNext)
        .catch((e) => {
          Bugsnag.notify(e);
          setError(CODE_NAME_KEY, ERROR_SMS_CODE);
          updateIsDisabledButton(true);
        });
    },
    [registerPromise, authService, onClickNext, setError]
  );

  return (
    <Formik
      initialValues={{
        phoneNumber:
          storage.get(PHONE_STORAGE_KEY) || DEFAULT_START_PHONE_NUMBER,
        code: EMPTY_STRING,
      }}
      validationSchema={getValidationSchema(isDev)}
      onSubmit={handleSubmit}
    >
      {({ errors, values, handleSubmit, setFieldValue, setFieldError }) => (
        <form className="form-horizontal p-2" onSubmit={handleSubmit}>
          <div className="step-form-wrapper">
            <div className="step-form-container">
              <div className="steps-navigation">
                <TopNavigation steps={steps} />
              </div>
              <div className="vertical-divider"></div>
              <div className="step-form-content">
                <SecureContent
                  values={values}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                  errors={errors}
                  onDisabledChange={handleDisabledChange}
                />
              </div>
            </div>
            <div className="step-worm-buttons-wrapper">
              <CustomButton
                className="filled-primary"
                onClick={handleSubmit}
                disabled={isDisabledButton}
              >
                Confirm
              </CustomButton>
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

Secure.propTypes = {
  steps: PropTypes.array,
  TopNavigation: PropTypes.func,
  onClickNext: PropTypes.func,
};

export default Secure;
