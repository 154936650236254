import io from "socket.io-client";

import { ENV } from "../base/constants/env";

class WebSocketService {
  constructor() {
    this.connectionCounter = 0;
  }

  uri = ENV.SOCKET_APP;

  close() {
    this.socket.close();
  }

  open() {
    this.socket = io(this.uri, {
      transports: ["websocket"],
      withCredentials: true,
    });
  }

  incrementCounter() {
    this.connectionCounter += 1;
  }

  decremenCounter() {
    this.connectionCounter -= 1;
  }
}

export default new WebSocketService();
