import { useCallback } from "react";
import Bugsnag from "@bugsnag/js";

import DraftsService from "../../services/DraftsService";
import { useService } from "./useService";
import { PRODUCT_TYPE } from "../constants/product";

export const useGetCounterDraft = () => {
  const draftService = useService(DraftsService);

  return useCallback(async () => {
    return draftService
      .getListDrafts({
        limit: 1,
        status: PRODUCT_TYPE.ACTIVE,
      })
      .then(({ pagination: { totalCount } }) => totalCount)
      .catch((e) => Bugsnag.notify(e));
  }, [draftService]);
};
