import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import SelectValueContainer from "./components/SelectValueContainer";
import SelectControl from "./components/SelectControl";
import SelectDropdown from "./components/Dropdown";
import CustomButton from "../../../components/CustomButton/index";
import AsyncSelect from "../AsyncSelect";
import SelectInput from "./components/SelectInput";
import SelectContainer from "./components/SelectContainer";
import Arrow from "./components/Arrow";
import SelectOption from "./components/SelectOption";
import MenuList from "./components/SelectMenu";

import { isLastOfArrayId } from "../../../helpers/isLastOfArray";
import { selectStyles } from "./selectStyles";
import "./index.scss";
import { useField } from "formik";
import { useDebounce } from "../../../hooks/useDebounce";
import CustomClear from "./components/CustomClear";

export const customComponents = {
  SelectContainer: SelectContainer,
  Input: SelectInput,
  ValueContainer: SelectValueContainer,
  DropdownIndicator: null,
  IndicatorSeparator: null,
  Control: SelectControl,
  MenuList,
  Option: SelectOption,
  IndicatorsContainer: () => null,
};

export const Popout = ({
  onGetAsyncOptions,
  placeholder = "Select a State",
  onSelect,
  className,
  onUpdateBreadcrumbs: updateBreadcrumbs,
  breadcrumbs,
  children,
  filterOption,
  value,
  onClear,
  innerRef,
}) => {
  const [searchValue, updateSearchValue] = useState("");
  const [search, updateSearch] = useState("");
  const [isOpen, updateOpen] = useState(false);
  const [parentId, updateParentId] = useState(null);

  useEffect(() => {
    updateParentId(breadcrumbs[breadcrumbs.length - 1]?.id || null);
  }, [breadcrumbs]);

  // TODO - add debounce
  const handleChangeSearch = useCallback(({ target: { value } }) => {
    updateSearchValue(value);
  }, []);

  const toggleOpen = () => updateOpen((isOpen) => !isOpen);

  const onSelectChange = (value) => {
    const { hasChildren, name, id, label } = value;
    if (search) {
      if (!value.prevPath) {
        updateBreadcrumbs((breadcrumbs) => [
          ...breadcrumbs,
          { id, name: name || label },
        ]);
      } else {
        updateBreadcrumbs(value.prevArr);
      }
      updateParentId(null);
      updateSearch("");
      updateSearchValue("");
    } else {
      if (hasChildren) {
        updateParentId(id);
        updateBreadcrumbs((breadcrumbs) => [
          ...breadcrumbs,
          { id, name: name || label },
        ]);
        return;
      }
    }

    toggleOpen();
    onSelect(value);
  };

  const ref = useRef(null);

  useImperativeHandle(innerRef, () => ({
    open: () => {
      updateOpen(true);
    },
    setQuery: (query) => {
      updateSearch(query);
      updateSearchValue(query);
    },
    scroll: () => {
      ref.current?.scrollIntoView({
        block: "center",
      });
    },
    onSelect: onSelectChange,
  }));

  const handleClickBreadcrumb = useCallback((selectedId) => {
    updateBreadcrumbs((breadcrumbs) => {
      if (isLastOfArrayId(breadcrumbs, selectedId)) return breadcrumbs;
      const currentIndex = breadcrumbs.findIndex(({ id }) => id === selectedId);
      return breadcrumbs.slice(0, currentIndex + 1);
    });
    updateParentId(selectedId);
  }, []);

  const handleResetAll = useCallback(() => {
    updateParentId(null);
    updateBreadcrumbs([]);
  }, []);

  const lookupValues = breadcrumbs
    ?.map(({ name }) => name)
    .concat(value?.label);

  const labelFullPath = lookupValues?.join(" / ");

  const [, , { setValue }] = useField({ name: "categoryName" });

  const clearValues = useCallback((e) => {
    e.stopPropagation();
    updateParentId(null);
    updateSearch("");
    updateSearchValue("");
    setValue("");
    onClear();
  }, []);

  useEffect(() => {
    if (value?.label) {
      setValue(labelFullPath || "");
    }
  }, [labelFullPath, value?.label]);

  return (
    <SelectDropdown
      ref={ref}
      isOpen={isOpen}
      onClose={toggleOpen}
      target={
        <>
          <CustomButton
            onClick={toggleOpen}
            className={classnames(
              "select-btn d-flex justify-content-between",
              { focus: isOpen },
              className
            )}
          >
            {value?.label ? (
              <p className={"filled-state"}>{labelFullPath}</p>
            ) : (
              <p className="empty-state">{placeholder}</p>
            )}
            <div className="d-flex justify-content-between ml-auto align-items-center">
              {value && (
                <div onClick={(e) => clearValues(e)}>
                  <CustomClear />
                </div>
              )}
              <div>
                <Arrow />
              </div>
            </div>
          </CustomButton>
          {children}
        </>
      }
    >
      <AsyncSelect
        menuIsOpen
        onToggleClick={toggleOpen}
        breadcrumbs={breadcrumbs}
        autoFocus={isOpen}
        controlShouldRenderValue={false}
        onRequestAPI={onGetAsyncOptions}
        onChange={onSelectChange}
        styles={selectStyles}
        value={value}
        hideSelectedOptions={false}
        parentId={parentId}
        handleClickBreadcrumb={handleClickBreadcrumb}
        handleResetAll={handleResetAll}
        handleChangeSearch={handleChangeSearch}
        components={customComponents}
        searchValue={searchValue}
        search={search}
        updateSearch={updateSearch}
        filterOption={filterOption}
      />
    </SelectDropdown>
  );
};

Popout.propTypes = {
  onGetAsyncOptions: PropTypes.func,
  placeholder: PropTypes.string,
  onSelect: PropTypes.func,
  value: PropTypes.any,
};
