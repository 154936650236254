import React from 'react';
import classNames from 'classnames';
import { Button } from 'reactstrap';

import Icon from "../../../../../../base/components/Icon";

import styles from './index.module.scss';

const Header = () => (
  <div className="d-flex align-items-center w-100 py-2">
    <Icon icon="logo" className={styles.icon} />
    <h3 className={classNames('my-0', styles.text)}>
      Don&apos;t have an account?
    </h3>
    <Button color="filled-primary" className={classNames('filled-primary-md', styles.button)}>
      Sign up
    </Button>
  </div>
);

export default Header;
