import React, { useCallback } from "react";
import {ReactComponent as GoogleLogo} from "../../../../assets/images/settings/icon-google.svg";
import "./index.scss";
import {Alert} from "reactstrap";
import {useExtensionInstall} from "../../hooks/Poshmark/useExtensionInstall";

const InstallExtensionPlaceholder = ({marketPlace = "Poshmark"}) => {
	const {onInstall} = useExtensionInstall()

	const openChromeExtension = useCallback(() => {
    onInstall();
  }, [onInstall]);

    return(
        <div className="mt-4 mb-4">
            <div className='d-flex align-items-center marketplace-body-text'>
                <GoogleLogo className='mr-2' />
                <p className='regular-black mb-0 marketplace-text-block'>
                    Please install the {" "}
                    <span
                        className='bold-green cursor-pointer'
                        onClick={() => openChromeExtension()}
                    >
                        Chrome extension
                    </span>
                    {" "} before continuing.
                </p>
            </div>
            <div className="mt-4">
                <Alert
                    color="warning"
                    role="alert"
                >
                    <div className="d-flex">
                        <div>
                            <i className="mdi mdi-alert-outline me-2 font-size-16" />
                        </div>
                        <div>
                            {`The Fearn Extension needs to be installed in order list on ${marketPlace}. Please be sure you are using the Chrome desktop browser and click the “Install”
                            button to add our Extension from the Google Chrome extension store.`}
                        </div>
                    </div>
                </Alert>
            </div>
        </div>
    )
}

export default InstallExtensionPlaceholder;
