import { useCallback } from "react";
import Bugsnag from "@bugsnag/js";

import { getFilteredFormFromServer } from "../helpers/getFilteredForm";
import { useService } from "../../../base/hooks/useService";
import DraftsService from "../../../services/DraftsService";
import { getCrosslistingChanges } from "../components/SyncFormModels";
import FormPartials from "../classes/FormPartials";
import { FORM_TYPES } from "../const/form";
import Associations from "../classes/Associations";
import { getInitialEmptyForm } from "../pages/Settings/utils/getInitialEmptyForm";

const formBasic = new FormPartials(FORM_TYPES.BASE);

export const useLoadProduct = (draftId) => {
  /**
   * @type {DraftsService}
   */
  const draftService = useService(DraftsService);

  return useCallback(
    ({ setFetchedData, updateFullForm }) => {
      return draftService
        .getDraft(draftId)
        .then(({ data }) => {
          const emptyFormItem = getInitialEmptyForm();
          updateFullForm(emptyFormItem); // clearing it before grabbing fresh data

          // TODO: Should be deleted after change shippingArea functionality
          if (!data.productEbaySpecificFields.shippingArea) {
            data.productEbaySpecificFields.shippingArea = 1;
          }

          const mappedForm = formBasic.mapModelToInitialValues(data);

          const formData = getFilteredFormFromServer(mappedForm);

          const allChanges = getCrosslistingChanges(formData);

          Associations.applyChanges(formData, allChanges);

          setFetchedData(data);

          updateFullForm(formData);
        })
        .catch((e) => Bugsnag.notify(e));
    },
    [draftId, draftService]
  );
};
