import Http from "./Http";
import moment from "moment";

class AnalyticsService extends Http {
  static $displayName = "AnalyticsService";

  getAnalyticsForCurrentMonth() {
    return this.get("/analytics/sales", {
      params: {
        currentDate: moment().format(),
      },
    });
  }

  setMonthGoalForCurrentMonth(monthGoal) {
    return this.put("/analytics", { monthGoal: monthGoal });
  }

  getTotalSales(params = null) {
    return this.get("/analytics/total-sales", { params: { ...params } });
  }

  getAverages(params = null) {
    return this.get("/analytics/averages", { params: { ...params } });
  }

  getSalesPerPlatform(params) {
    return this.get("analytics/sales-per-marketplace", {
      params: {
        startDate: params.startDate,
        endDate: params.endDate,
      },
    });
  }

  getSoldInventory(params) {
    return this.get("/analytics/sold-inventory", { params });
  }

  getAvailableInventory(params) {
    return this.get("analytics/available-inventory", { params });
  }
}

export default AnalyticsService;
