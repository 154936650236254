import React, { useCallback, useEffect, useRef, useState } from "react";
import Bugsnag from "@bugsnag/js";

import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../base/components/CustomModal";
import { useService } from "../../../base/hooks/useService";
import ProductReturnsService from "../../../services/ProductReturnsService";
import CustomButton from "../../../base/components/CustomButton";
import { Formik } from "formik";
import { noop } from "bootstrap/js/src/util";
import { validationSchema } from "../classes/forms/return";
import { Col, Row } from "reactstrap";
import FormikTextarea from "../../../base/components/FormikTextarea";
import {
  MAX_REASON_LENGTH,
  MAX_TITLE_LENGTH,
} from "../../../validation/lengthConstants";
import FormikInput from "../../../base/components/FormikInput";
import FormikSingleSelect from "../../../base/components/FormikSingleSelect";
import {
  RETURN_DATE_FORMAT,
  RETURN_MARKETPLACES_VARIANTS,
  RETURN_STATUSES,
  RETURN_STATUSES_VARIANTS,
} from "../constants/returns-constants";
import { getCutPrice } from "../../../base/helpers/getCutPrice";
import FormikGroupAddon from "../../drafts/components/GroupAddon";
import filterObjectFalsy from "../../../base/helpers/filterObjectFalsy";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { RETURN_GROUP_LINKS } from "../config";
import { formatInputPrice } from "../../drafts/helpers/formatInputPrice";
import PreventClosePage from "../../drafts/components/PreventClosePage";
import { useLocationQuery } from "../../../base/hooks/useQueryString";
import DateField from "../../expenses/components/DateField";
import ToolTip from "../../../base/components/ToolTip/ToolTip";
import {
  RETURN_SHIPPING_COST_TOOLTIP,
  SHIPPING_CHARGED_TO_BUYER_RETURN_TOOLTIP,
  SHIPPING_COST_TOOLTIP,
} from "../../../base/components/ToolTip/constant/toolTipMessages";

const initialState = {
  title: "",
  description: "",
  itemCost: undefined,
  shippingCost: undefined,
  priceSold: undefined,
  soldAt: "",
  marketplaceType: null,
  returnedAt: "",
  returnReason: "",
  receivedAt: "",
  receivedCondition: "",
  returnShippingCost: undefined,
  notes: "",
  status: RETURN_STATUSES.IN_PROGRESS,
};

const IS_OPEN_MODAL_PARAMS_NAME = "isOpenModal";

export const useCreateEditReturn = (isEdit = false, onRefresh) => {
  const history = useHistory();
  const location = useLocation();
  const productReturnsService = useService(ProductReturnsService);
  const [returnData, setReturnData] = useState(null);
  const onUpdateFunction = useRef(() => {});

  const { params, clear, merge } = useLocationQuery();

  const onShowModal = useCallback((initial = initialState, onUpdate) => {
    const returnsData = { ...initial };
    onUpdateFunction.current = onUpdate;
    // if (returnsData.soldAt) {
    //   returnsData.soldAt = transformToReturnDate(returnsData.soldAt);
    // }

    if (returnsData.returnedAt) {
      returnsData.returnedAt = transformToReturnDate(returnsData.returnedAt);
    }

    if (returnsData.receivedAt) {
      returnsData.receivedAt = transformToReturnDate(returnsData.receivedAt);
    }

    if (!returnsData.marketplaceType)
      returnsData.marketplaceType = initialState.marketplaceType;

    setReturnData(returnsData);
  }, []);

  const cancel = useCallback(() => {
    clear();
  }, [clear]);

  const createReturn = useCallback(
    (values) => {
      let updatedValues = filterObjectFalsy(values);
      delete updatedValues.status;
      delete updatedValues?.productId;

      updatedValues = transformModelToISOString(updatedValues);

      productReturnsService
        .createReturnProduct(updatedValues)
        .then(() => {
          // onUpdateFunction.current
          onRefresh();
          setReturnData(null);
        })
        .catch((e) => Bugsnag.notify(e));
    },

    [returnData, setReturnData, productReturnsService]
  );

  const editReturn = useCallback(
    (values) => {
      const returnId = values.id;
      const returnStatus = values.status;

      let updatedValues = filterObjectFalsy(values);

      updatedValues = transformModelToISOString(updatedValues);

      delete updatedValues.id;
      delete updatedValues.createdAt;
      delete updatedValues.updatedAt;
      delete updatedValues?.productId;

      const redirectAfterSuccess =
        returnStatus === RETURN_STATUSES.IN_PROGRESS
          ? RETURN_GROUP_LINKS.PROGRESS
          : RETURN_GROUP_LINKS.COMPLETED;

      productReturnsService
        .updateReturnProduct(returnId, updatedValues)
        .then(() => {
          onUpdateFunction.current(updatedValues);
          onRefresh();
          setReturnData(null);
          history.push(redirectAfterSuccess);
        })
        .catch((e) => Bugsnag.notify(e));
    },

    [returnData, setReturnData, productReturnsService]
  );

  const transformToISOString = useCallback((date) => {
    const transformDate = new Date(date);
    return transformDate.toISOString();
  }, []);

  const transformToReturnDate = useCallback((date) => {
    return moment(date).format(RETURN_DATE_FORMAT);
  }, []);

  const transformModelToISOString = useCallback((model) => {
    // if (model.soldAt) {
    //   model.soldAt = transformToISOString(model.soldAt);
    // }

    if (model.returnedAt) {
      model.returnedAt = transformToISOString(model.returnedAt);
    }

    if (model.receivedAt) {
      model.receivedAt = transformToISOString(model.receivedAt);
    }

    return model;
  }, []);

  useEffect(() => {
    if (returnData) {
      merge(IS_OPEN_MODAL_PARAMS_NAME, true);
    } else if (
      location.pathname === RETURN_GROUP_LINKS.PROGRESS ||
      location.pathname === RETURN_GROUP_LINKS.COMPLETED
    ) {
      clear();
    }
  }, [returnData]);

  useEffect(() => {
    if (!params[IS_OPEN_MODAL_PARAMS_NAME]) {
      setReturnData(null);
    }
  }, [params]);

  const viewCreateEditReturn = (
    <CustomModal isOpen={!!returnData} onClose={cancel} size="xl">
      <ModalHeader onClose={cancel}>
        {isEdit ? "Edit return" : "Create return"}
      </ModalHeader>
      {returnData && (
        <Formik
          initialValues={returnData}
          validationSchema={validationSchema}
          onSubmit={noop}
        >
          {(helpers) => {
            const { setFieldValue, values, dirty, isValid } = helpers;

            return (
              <>
                <PreventClosePage
                  isBlocked={dirty}
                  allowPath={`${RETURN_GROUP_LINKS.PROGRESS}?${IS_OPEN_MODAL_PARAMS_NAME}=true`}
                />
                <ModalBody>
                  <Row>
                    <Col>
                      <Row className="d-flex align-items-center mb-3">
                        <Col md="4">
                          <label htmlFor={"title"}>{"Title"}</label>
                        </Col>
                        <Col md="8">
                          <FormikInput
                            containerClassName={
                              "d-flex flex-wrap align-items-center"
                            }
                            id="title"
                            name="title"
                            placeholder="Enter title"
                            type="text"
                            maxLength={MAX_TITLE_LENGTH}
                            disabled={values.productId && returnData.title}
                          />
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center  mb-3">
                        <Col md="4">
                          <label htmlFor={"description"}>
                            {"Description*"}
                          </label>
                        </Col>
                        <Col md="8">
                          <FormikInput
                            containerClassName={
                              "d-flex flex-wrap align-items-center"
                            }
                            id="description"
                            name="description"
                            placeholder="Enter description"
                            type="text"
                            maxLength={MAX_TITLE_LENGTH}
                            disabled={
                              values.productId && returnData.description
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center  mb-3">
                        <Col md="4">
                          <label htmlFor={"priceSold"}>{"Price sold"}</label>
                        </Col>
                        <Col md="8">
                          <FormikGroupAddon
                            containerClassName="d-flex flex-wrap align-items-center"
                            inputClassName="w-100"
                            name="priceSold"
                            placeholder="00.00"
                            groupText="$"
                            onChange={({ target: { value } }) => {
                              setFieldValue(
                                "priceSold",
                                value ? getCutPrice(value) : null
                              );
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center  mb-3">
                        <Col md="4">
                          <label htmlFor={"itemCost"}>{"Cost of good"}</label>
                        </Col>
                        <Col md="8">
                          <FormikGroupAddon
                            containerClassName="d-flex flex-wrap align-items-center"
                            inputClassName="w-100"
                            name="itemCost"
                            placeholder="00.00"
                            groupText="$"
                            onChange={({ target: { value } }) => {
                              setFieldValue(
                                "itemCost",
                                formatInputPrice(value)
                              );
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center  mb-3">
                        <Col md="4">
                          <div className="d-flex">
                            <label htmlFor={"shippingCost"}>
                              {"Shipping cost"}
                            </label>
                            <ToolTip
                              classNames="ml-2"
                              text={SHIPPING_COST_TOOLTIP}
                            />
                          </div>
                        </Col>
                        <Col md="8">
                          <FormikGroupAddon
                            containerClassName="d-flex flex-wrap align-items-center"
                            inputClassName="w-100"
                            name="shippingCost"
                            placeholder="00.00"
                            groupText="$"
                            onChange={({ target: { value } }) => {
                              setFieldValue(
                                "shippingCost",
                                value ? getCutPrice(value) : null
                              );
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center  mb-3">
                        <Col md="4">
                          <div className="d-flex">
                            <label htmlFor={"shippingChargedToBuyer"}>
                              {"Shipping charged to buyer"}
                            </label>
                            <ToolTip
                              classNames="ml-2"
                              text={SHIPPING_CHARGED_TO_BUYER_RETURN_TOOLTIP}
                            />
                          </div>
                        </Col>
                        <Col md="8">
                          <FormikGroupAddon
                            containerClassName="d-flex flex-wrap align-items-center"
                            inputClassName="w-100"
                            name="shippingChargedToBuyer"
                            placeholder="00.00"
                            groupText="$"
                            onChange={({ target: { value } }) => {
                              setFieldValue(
                                "shippingChargedToBuyer",
                                value ? getCutPrice(value) : null
                              );
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center  mb-3">
                        <Col md="4">
                          <div className="d-flex">
                            <label htmlFor={"returnShippingCost"}>
                              {"Return shipping cost"}
                            </label>
                            <ToolTip
                              classNames="ml-2"
                              text={RETURN_SHIPPING_COST_TOOLTIP}
                            />
                          </div>
                        </Col>
                        <Col md="8">
                          <FormikGroupAddon
                            containerClassName="d-flex flex-wrap align-items-center"
                            inputClassName="w-100"
                            name="returnShippingCost"
                            placeholder="00.00"
                            groupText="$"
                            onChange={({ target: { value } }) => {
                              setFieldValue(
                                "returnShippingCost",
                                value ? getCutPrice(value) : null
                              );
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <Row className="d-flex align-items-center  mb-3">
                        <Col md="4">
                          <label htmlFor={"status"}>{"Status"}</label>
                        </Col>
                        <Col md="8">
                          <FormikSingleSelect
                            containerClassName={"mb-0"}
                            name="status"
                            placeholder="Choose status"
                            options={RETURN_STATUSES_VARIANTS}
                            initialOption={1}
                            disabled={!isEdit}
                          />
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center  mb-3">
                        <Col md="4">
                          <label htmlFor={"marketplaceType"}>
                            {"Marketplace"}
                          </label>
                        </Col>
                        <Col md="8">
                          <FormikSingleSelect
                            containerClassName={"mb-0"}
                            name="marketplaceType"
                            placeholder="Choose marketplace"
                            options={RETURN_MARKETPLACES_VARIANTS}
                          />
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center  mb-3">
                        <Col md="4">
                          <label htmlFor={"soldAt"}>{"Date sold"}</label>
                        </Col>
                        <Col md="8 d-flex align-items-center">
                          <DateField
                            name="soldAt"
                            id="soldAt"
                            selected={values?.soldAt}
                          />
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center  mb-3">
                        <Col md="4">
                          <label htmlFor={"returnedAt"}>{"Return date"}</label>
                        </Col>
                        <Col md="8">
                          <DateField
                            name="returnedAt"
                            id="returnedAt"
                            selected={values?.returnedAt}
                          />
                        </Col>
                      </Row>

                      <Row className="d-flex align-items-center mb-3">
                        <Col md="4">
                          <label htmlFor={"returnReason"}>
                            {"Return reason"}
                          </label>
                        </Col>
                        <Col md="8">
                          <FormikInput
                            containerClassName={"d-flex align-items-center"}
                            id="returnReason"
                            name="returnReason"
                            placeholder="Write your reason"
                            type="text"
                            maxLength={MAX_REASON_LENGTH}
                          />
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center  mb-3">
                        <Col md="4">
                          <label htmlFor={"receivedAt"}>
                            {"Received date"}
                          </label>
                        </Col>
                        <Col md="8">
                          <DateField
                            name="receivedAt"
                            id="receivedAt"
                            selected={values?.receivedAt}
                          />
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center mb-3">
                        <Col md="4">
                          <label htmlFor={"receivedCondition"}>
                            {"Received condition"}
                          </label>
                        </Col>
                        <Col md="8">
                          <FormikInput
                            containerClassName={"d-flex align-items-center"}
                            id="receivedCondition"
                            name="receivedCondition"
                            placeholder="Enter received condition"
                            type="text"
                            maxLength={MAX_TITLE_LENGTH}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <label htmlFor={"notes"}>{"Additional note"}</label>
                    </Col>
                    <Col md="10">
                      <FormikTextarea
                        id="notes"
                        name="notes"
                        rows="4"
                        placeholder="Enter your notes"
                        maxLength={MAX_TITLE_LENGTH}
                      />
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter>
                  <CustomButton onClick={cancel} className="outline-primary ">
                    Cancel
                  </CustomButton>

                  <CustomButton
                    onClick={() =>
                      isEdit ? editReturn(values) : createReturn(values)
                    }
                    className="filled-primary "
                    disabled={!(isValid && dirty)}
                    type={"submit"}
                  >
                    Save
                  </CustomButton>
                </ModalFooter>
              </>
            );
          }}
        </Formik>
      )}
    </CustomModal>
  );

  return {
    onShowModal,
    viewCreateEditReturn,
  };
};
