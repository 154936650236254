import Http from "./Http";

class AuthService extends Http {
  static $displayName = "AuthService";

  async login(values) {
    return await this.post("/sessions", values);
  }

  async logout() {
    return await this.delete("/sessions", {}, { skipInterceptor: true });
  }

  async register({ email, firstName, lastName, password }) {
    return await this.post("/users", { email, firstName, lastName, password });
  }

  async registerLoginWithGoogle(token) {
    return await this.post("/socials/sessions/google", { token });
  }

  async sendRestorePasswordEmail(email) {
    return await this.post("/verifications/password", { email });
  }

  async resetPassword(values) {
    return await this.put("/verifications/password", values);
  }

  async checkLinkRelevance(values) {
    return await this.put("/verifications/token/restoration-password", values);
  }

  async checkVerificationToken(values) {
    return await this.get("/verifications/password", { params: values });
  }

  async verifyUser(values) {
    return await this.put("/verifications", values);
  }

  async resendVerificationLink(values) {
    return await this.post("/verifications", values);
  }

  async sendPhoneNumber({ phoneNumber }) {
    return await this.post("/verifications/phone-number", { phoneNumber });
  }

  async verifyPhoneNumber({ code }) {
    return await this.put("/verifications/phone-number", { code });
  }
}

export default AuthService;
