import React from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { useService } from "../hooks/useService";
import SessionStorage from "../../services/SessionStorage";
import moment from "moment";

export default function PrivateRoute({ component: Component, ...rest }) {
  /**
   * @type {SessionStorage}
   */
  const storage = useService(SessionStorage);
  const { expiresAt } = storage.getSession();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!moment().isBefore(moment(expiresAt).toDate())) {
          return <Redirect to="/auth/logout" />;
        }

        return <Component {...props} />;
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
};
