import React from "react";

export default function tableStructure() {
  return [
    {
      dataField: "name",
      text: "",
      headerFormatter: () => (
        <>
          Name <i className="bx bx-sort" />
        </>
      ),
      sort: true,
      formatter: (cellContent, fullContent) => {
        return (
          <section className="d-flex flex-row align-items-center">
            {fullContent.firstName + " " + fullContent.lastName}
          </section>
        );
      },
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      text: "Date of birth",
      dataField: "dateOfBirth",
      formatter: (cellContent) => <>{cellContent}</>,
    },
  ];
}
