import { useCallback, useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../base/hooks/useService";
import SoldService from "../../../services/SoldService";

export const useSold = (limit = 10, markToUpdate = null) => {
  /**
   * @type {SoldService}
   */
  const soldService = useService(SoldService);

  return useCallback(
    ({
      orderType,
      orderBy,
      offset,
      query: q,
      startDate,
      endDate,
      marketplaceType,
    }) => {
      return soldService.getProducts({
        limit,
        orderBy,
        orderType,
        startDate,
        endDate,
        offset,
        q,
        marketplaceType,
      });
    },
    [soldService, limit, markToUpdate]
  );
};

export const useTotalProducts = (type) => {
  const load = useSold(type, 1);
  const [state, update] = useState({ total: 0, load: true });

  useEffect(() => {
    load({})
      .then(({ pagination }) => {
        update({ load: false, total: pagination.totalCount });
      })
      .catch((e) => Bugsnag.notify(e));
  }, [load]);

  return [state, update];
};
