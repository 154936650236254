export const PHONE_NUMBER_IS_USED = {
  ERROR_CODE: "PHONE_NUMBER_ALREADY_IN_USE",
  ERROR_FIELD_MESSAGE:
    "Entered phone number has already been registered in the application.",
};

export const INVALID_REGION = {
  ERROR_CODE: "INVALID_REGION",
  ERROR_FIELD_MESSAGE: "We only work with US phone numbers.",
};
