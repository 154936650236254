import React, { useCallback, useEffect, useMemo, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../../base/hooks/useService";
import AnalyticsService from "../../../../services/AnalyticsService";
import { separateThousandth } from "../../../analytics/helpers/separateThousandth";
import { useAnalyticRangeDateFilter } from "../../../analytics/hooks/useAnalyticRangeDateFilter";
import { INIT_DATE_FILTER } from "../AnalyticsToday/AnalyticsToday";

import "./index.scss";

const AnalyticsTodayPerformance = () => {
  const [performance, setPerformance] = useState({});

  const [dateFilter, { formatDate }] =
    useAnalyticRangeDateFilter(INIT_DATE_FILTER);

  /**
   * @type {AnalyticsService}
   */
  const analytics = useService(AnalyticsService);

  const fetchAnalytics = useCallback(() => {
    analytics
      .getTotalSales({
        // Take data for the day
        startDate: formatDate(dateFilter.dateRange.startDate),
        endDate: formatDate(dateFilter.dateRange.endDate),
      })
      .then(({ data }) => {
        setPerformance((prevState) => ({
          ...prevState,
          totalSales: separateThousandth(data.totalSales),
          grossProfit: separateThousandth(data.grossProfit),
          itemsListed: data.itemsListed,
          itemsSold: data.itemsSold,
        }));
      })
      .catch((e) => Bugsnag.notify(e));
  }, [analytics]);

  useEffect(() => {
    fetchAnalytics();
  }, [fetchAnalytics]);

  const items = useMemo(() => [
    { label: "Sales", value: performance?.totalSales },
    { label: "Items listed", value: performance?.itemsListed },
    { label: "Gross Profit", value: performance?.grossProfit },
    { label: "Sold Items", value: performance?.itemsSold },
  ]);

  return (
    <div className="analytics__wrapper">
      {items.map((item, i) => {
        return (
          <div className="analytics__item" key={i}>
            <div>{item.label}:</div>
            <div className="analytics__sum">{item.value || "-"}</div>
          </div>
        );
      })}
    </div>
  );
};

export default AnalyticsTodayPerformance;
