import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useField } from "formik";

import CustomInput from "./Input";
import ErrorMessageDefault from "./ErrorMessageDefault";

import { useTranslate } from "../hooks/useTranslate";
import { useCustomError } from "../contexts/CustomErrorMessage";

const FormikEmail = ({
  placeholder,
  name,
  type,
  maxLength,
  readOnly,
  label,
  containerClassName,
  onChange = () => {},
  ErrorMessage = ErrorMessageDefault,
  min,
  max,
  isHideLabel = false,
}) => {
  const [field, meta] = useField({ name, type, placeholder });
  const isError = meta.error && meta.touched;
  const [translate] = useTranslate();

  const { getError } = useCustomError();
  const errorEmail = getError(name);

  const handleOnChange = (event) => {
    field.onChange(event);
    onChange(event);
  };

  return (
    <div className={classnames(containerClassName, "custom-form-group")}>
      {!isHideLabel && (
        <label className={classnames({ "text-danger": isError || errorEmail })}>
          {label}
        </label>
      )}
      <CustomInput
        {...field}
        autoComplete="email"
        onChange={handleOnChange}
        type={type}
        className={classnames({
          "is-invalid-input is-invalid": isError || errorEmail,
        })}
        maxLength={maxLength}
        readOnly={readOnly}
        placeholder={placeholder}
      />
      <ErrorMessage name={name}>
        {translate(meta.error, { label, min, max })}
      </ErrorMessage>
    </div>
  );
};

FormikEmail.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  containerClassName: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  errorEmail: PropTypes.string,
  onChange: PropTypes.func,
  ErrorMessage: PropTypes.any,
  isHideLabel: PropTypes.bool,
};

export default FormikEmail;
