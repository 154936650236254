import React from 'react'
import { Card, CardBody, Col, Row } from 'reactstrap';
import PageMeta from '../../../../base/components/MetaTags';
import PhoneNumberForm from './PhoneNumberForm';

const PhoneNumber = () => {
  return (
    <Row className='mt-3'>
      <PageMeta title='Profile' />
      <Col lg='12' xl='7'>
        <Card>
          <CardBody>
            <h3 className='bold-black mb-4'>
              Change phone number
            </h3>
            <PhoneNumberForm />
          </CardBody>          
        </Card>      
      </Col>
    </Row>    
  )
};

export default PhoneNumber;