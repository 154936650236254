import { MARKETPLACE_TYPES } from "../../drafts/hooks/useProductStatus/components/SoldModal/constants";


class EbayProduct {
  constructor(item) {

    this.data = {
      listingId: item.listingId,
      orderId: item.orderId,
      shippingExpenses: item.shipping,
      marketplaceType: MARKETPLACE_TYPES.ebay
    }
  }
}

export default EbayProduct
