import * as yup from "yup";
import {
  DRAFT_DESCRIPTION_MERCARI,
  DRAFT_DIMENSION,
  DRAFT_PRICE,
  DRAFT_ZIP,
  LISTING_URL,
  MERCARI_DRAFT_LB,
  MERCARI_DRAFT_OZ,
} from "../../../../validation/draft";

import { MIN_TITLE_LENGTH } from "../../../../validation/lengthConstants";
import {
  CATEGORY_ERROR,
  COLOR_ERROR,
  MERCARY_DESCRIPTION_ERROR,
  PRISE_ERROR,
  TITLE_ERROR,
  DRESS_OCCASION_ERROR,
  DRESS_STYLE_ERROR,
} from "../../../../base/constants/forms";
import { BRAND_ERROR } from "../../const/poshmark";
import { MAX_TITLE_LENGTH } from "../../const/mercari";
import { descriptionValidation } from "../../helpers/descriptionValidation";
import {
  PREPAID_LABEL,
  SHIPPING_PAYER_BUYER,
} from "../../components/DraftMercariFormInner/partials/ShippingTemplate";
import { BUYER_SHIPPING_PAYER } from "../../../../base/constants/draft";

const minCountFiles = 1;
const maxCountFiles = 12;

const minCountWord = 5;

export const DRAFT_ETSY_TITLE = yup
  .string()
  .min(MIN_TITLE_LENGTH, TITLE_ERROR)
  .max(MAX_TITLE_LENGTH)
  .trim();

export const nullableNumber = yup.number().nullable(true);

export const validationSchema = yup.object().shape({
  title: DRAFT_ETSY_TITLE.required(),
  description: descriptionValidation(
    DRAFT_DESCRIPTION_MERCARI.required(),
    minCountWord
  ),
  categoryId: nullableNumber.required(),
  dressOccasion: yup
    .string()
    .nullable()
    .test("Test", "Dress occasion is needed", (_, { parent }) => {
      if (parent.categoryShortName !== "Knee-length") return true;

      return !!parent.dressOccasion;
    }),
  dressStyle: yup
    .string()
    .nullable()
    .test("Test", "Dress style is needed", (_, { parent }) => {
      if (
        parent.categoryShortName !== "Knee-length" ||
        parent.dressOccasion === null
      )
        return true;

      return !!parent.dressStyle;
    }),
  files: yup.array().min(minCountFiles).max(maxCountFiles),
  shippingId: nullableNumber.test(
    "ship",
    "Shipping is required",
    (_, { parent }) => {
      const showPackageDetails =
        PREPAID_LABEL === parent.howToShip ||
        parent.shippingPayer === SHIPPING_PAYER_BUYER;
      if (!showPackageDetails) return true;
      return !!parent.shippingId;
    }
  ),
  // floorPrice: DRAFT_PRICE.required(),
  listingPrice: DRAFT_PRICE.nullable().required(),
  brandId: yup.number().nullable(),
  conditionId: yup.number().nullable().required("Condition is required"),
  sizeId: yup.number().nullable().when("hasSizes", {
    is: true,
    then: yup.number().nullable().required(),
  }),
  shippingPayer: yup.number().nullable().required(),
  howToShip: yup
    .number()
    .nullable()
    .test(
      "Test",
      "How do you want to ship your item? is required",
      (_, { parent }) => {
        // When filed is hidden
        if (
          parent.shippingPayer === BUYER_SHIPPING_PAYER ||
          parent.shippingPayer === null
        )
          return true;

        return !!parent.howToShip;
      }
    ),

  shipsFrom: DRAFT_ZIP.required(),
  weightLb: MERCARI_DRAFT_LB,
  weightOz: MERCARI_DRAFT_OZ,
  height: DRAFT_DIMENSION,
  width: DRAFT_DIMENSION,
  depth: DRAFT_DIMENSION,
  link: LISTING_URL,
});

export const initialValues = {
  files: [],
  title: "",
  description: "",
  colorId: null,
  categoryName: "",
  categoryShortName: "",
  dressOccasion: null,
  dressStyle: null,
  brandName: "",
  sizeName: "",
  sizeId: null,
  categoryId: null,
  sku: "",
  howToShip: null,
  brandId: null,
  conditionId: null,
  listingPrice: 0,
  shippingPayer: 0,
  costOfItem: null,
  floorPrice: 0,
  weightLb: "",
  weightOz: "",
  height: "",
  width: "",
  depth: "",
  shippingId: null,
  shipsFrom: "",
  salesFee: 0,
  tags: "",
  link: "",
};

export const highlightDraftFields = {
  title: TITLE_ERROR,
  description: MERCARY_DESCRIPTION_ERROR,
  price: PRISE_ERROR,
  colorId: COLOR_ERROR,
  categoryId: CATEGORY_ERROR,
  dressOccasion: DRESS_OCCASION_ERROR,
  dressStyle: DRESS_STYLE_ERROR,
  brandId: BRAND_ERROR,
};

const convertStringToInt = (id) => {
  if (id) return Number(id);
  return id;
};

export const fieldsToMerge = [
  // base, nested custom form key
  { base: "files", custom: null }, // spec. field only for base model
  { base: null, custom: "title" },
  { base: null, custom: "description" },
  { base: null, custom: "colorId", toSave: String, toForm: convertStringToInt },
  { base: null, custom: "categoryName" },
  { base: null, custom: "categoryShortName" },
  { base: null, custom: "colorName" },
  { base: null, custom: "dressOccasion" },
  { base: null, custom: "dressStyle" },
  // { base: "primaryColor", custom: "colorId", toSave: findColorId },
  // { base: "primaryColor", custom: "colorName", toSave: findColorValue },
  { base: null, custom: "brandName" },
  { base: null, custom: "sizeName" },
  {
    base: null,
    custom: "sizeId",
    toSave: String,
    toForm: convertStringToInt,
  },
  { base: null, custom: "brandId", toSave: String, toForm: convertStringToInt },
  {
    base: null,
    custom: "categoryId",
    toSave: String,
    toForm: convertStringToInt,
  },
  { base: null, custom: "sku" },
  {
    base: null,
    custom: "tags",
  },
  {
    base: null,
    custom: "conditionId",
    toSave: String,
    toForm: convertStringToInt,
  },
  { base: null, custom: "listingPrice" },
  { base: null, custom: "shippingPayer" },
  { base: null, custom: "costOfItem", toForm: (val) => val },
  { base: null, custom: "howToShip" },
  { base: null, custom: "floorPrice" },
  {
    base: null,
    custom: "shippingId",
    toSave: String,
    toForm: convertStringToInt,
  },
  { base: null, custom: "shipsFrom" },

  { base: null, custom: "weightLb" },
  { base: null, custom: "weightOz" },
  { base: null, custom: "height" },
  { base: null, custom: "width" },
  { base: null, custom: "depth" },
  { base: null, custom: "shippingFee", excludeSaving: true },
  { base: null, custom: "salesFee", excludeSaving: true },
  { base: null, custom: "tags_1", excludeSaving: true },
  { base: null, custom: "tags_2", excludeSaving: true },
  { base: null, custom: "tags_3", excludeSaving: true },
  { base: null, custom: "suggestedShippingClassIds", excludeSaving: true },
  { base: null, custom: "link", excludeSaving: true },
  { base: null, custom: "status", excludeSaving: true },
  { base: "createdAt", custom: "createdAt", excludeSaving: true },
  { base: "updatedAt", custom: "updatedAt", excludeSaving: true },
];
