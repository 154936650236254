/* eslint-disable react/prop-types */
import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useHistory } from "react-router";

import "./index.scss";

const VerticalTabs = ({
  tabs,
  className,
  children,
  data,
  isLoading = false,
}) => {
  const history = useHistory();

  if (!tabs) return null;

  const pathname = window.location.pathname;

  return (
    <div className={classnames("wrapper", className)}>
      <div className="tabs-wrapper">
        <Nav pills className="navtab-bg ">
          {tabs.map((tab, index) => {
            if (!tab.isLink) {
              return (
                <NavItem key={`${new Date()} ${index}`}>
                  <NavLink
                    className={classnames({
                      active: pathname.includes(tab.link),
                    })}
                    onClick={() => {
                      if (isLoading) return;

                      history.push(tab.link);
                    }}
                    disabled={tab.isDisabled}
                  >
                    {tab.title}
                    {!!tab.count && ` (${tab.count})`}
                  </NavLink>
                </NavItem>
              );
            } else {
              return (
                <NavItem key={`${new Date()} ${index}`}>
                  <a
                    href={tab.link}
                    className={"nav-link target-link"}
                    rel="noreferrer"
                    target={"_blank"}
                  >
                    {tab.title}
                  </a>
                </NavItem>
              );
            }
          })}
          {!!data?.length && <div className="tabs-children">{children}</div>}
        </Nav>
      </div>
    </div>
  );
};

VerticalTabs.propTypes = {
  tabs: PropTypes.array,
  className: PropTypes.string,
  children: PropTypes.any,
};

export default VerticalTabs;
