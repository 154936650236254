import React from "react";
import InputGroupDisabled from "../../../../../InputGroupDisabled";

const PrefiledCardInputs = ({ lastFourDigits }) => {
  return (
    <>
      {lastFourDigits && (
        <>
          <InputGroupDisabled
            label="Card number"
            defaultValue={`**** **** **** ${lastFourDigits || "****"}`}
          />
          <InputGroupDisabled label="Expiry date field" defaultValue="**/**" />
          <InputGroupDisabled label="Secure code" defaultValue="***" />
        </>
      )}
    </>
  );
};

export default PrefiledCardInputs;
