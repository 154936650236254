import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { isLastOfArray } from "../../helpers/isLastOfArray";

const CustomDropdown = ({ defaultSelected, onChange, list = [] }) => {
  const [isOpen, updateIsOpen] = useState(false);
  const [selected, updateSelected] = useState(() => defaultSelected);

  useEffect(() => {
    onChange && onChange(selected);
  }, [onChange, selected]);

  const toggle = useCallback(() => {
    updateIsOpen((isOpen) => !isOpen);
  }, []);

  const handleSelect = (selectedItem) => {
    updateSelected(selectedItem);
  };

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle className="custom-button-dropdown">
        {selected}
        <i className="mdi mdi-chevron-down" />
      </DropdownToggle>
      <DropdownMenu right>
        {list.map((item, index, arr) => (
          <React.Fragment key={index}>
            <DropdownItem
              onClick={() => handleSelect(item)}
              active={item === selected}
            >
              {item}
            </DropdownItem>
            {!isLastOfArray(arr, index) && <DropdownItem divider />}
          </React.Fragment>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

CustomDropdown.propTypes = {
  defaultSelected: PropTypes.string,
  onChange: PropTypes.func,
  list: PropTypes.array,
};

export default CustomDropdown;
