import { useCallback, useState } from 'react'

export const useDropdown = (initialOpen = false) => {
  const [ isOpen, updateIsOpen ] = useState(initialOpen);

  const toggle = useCallback(() => {
    updateIsOpen(isOpen => !isOpen)
  }, []);

  return [ isOpen, toggle ];
}