import { combineReducers } from "redux";

import Layout from "./layout/reducer";
import Counters from "./Counters/reducer";
import Imports from "./import/reducer";

const rootReducer = combineReducers({
  Layout,
  Imports,
  Counters,
});

export default rootReducer;
