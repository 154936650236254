import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import Bugsnag from "@bugsnag/js";

import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../base/components/CustomModal";
import Button from "../../../../base/components/Button/index";

import { useLoading } from "../../../../base/hooks/useLoading";
import { useService } from "../../../../base/hooks/useService";
import AuthService from "../../../../services/AuthService";
import ToasterService from "../../../../services/ToastService";
import StorageService from "../../../../services/StorageService";

import { AUTH_GROUP_LINKS } from "../../config";

import { KEY_VERIFY_EMAIL } from "../../../../base/constants/storage";
import { SUCCESS_RESEND } from "../../constants/messages";

const VerifyEmail = ({ location }) => {
  const { email } = location.state;

  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);

  /**
   * @type {StorageService}
   */
  const storage = useService(StorageService);

  const toastr = useService(ToasterService);

  const [, { registerPromise }] = useLoading();
  const history = useHistory();

  const onCloseModal = useCallback(() => {
    history.push(AUTH_GROUP_LINKS.LINK_LOGIN);
  }, [history]);

  const handleResend = useCallback(() => {
    registerPromise(
      authService
        .resendVerificationLink({ email })
        .then(() => toastr.success(SUCCESS_RESEND))
        .catch((e) => Bugsnag.notify(e))
    );
  }, [registerPromise, authService, toastr, email]);

  useEffect(() => {
    return storage.onChangeDataInSiblingTab(KEY_VERIFY_EMAIL, () => {
      onCloseModal();
    });
  }, [storage, onCloseModal]);

  return (
    <CustomModal isOpen={true} onClose={onCloseModal}>
      <ModalHeader onClose={onCloseModal}>Verify email address</ModalHeader>
      <ModalBody>
        <p className="ui-regular">
          To continue please verify your email with the link that was sent to
          you.
        </p>
      </ModalBody>
      <ModalFooter>
        <Button className="filled-primary" onClick={handleResend}>
          Resend link
        </Button>
      </ModalFooter>
    </CustomModal>
  );
};

VerifyEmail.propTypes = {
  location: PropTypes.object,
};

export default VerifyEmail;
