import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import "./styles.scss";

const TaskHeader = () => (
  <Card className="task-header d-none d-sm-block">
    <CardBody className="py-3">
      <Row>
        <Col xs="12" sm="5" md="8">
          <p className="mb-0 fw-bold font-size-13">Task</p>
        </Col>
        <Col xs="2" md="1">
          <p className="mb-0 fw-bold font-size-13 text-truncate">Done</p>
        </Col>
        <Col xs="9" sm="5" md="3">
          <p className="mb-0 fw-bold font-size-13 text-center">Actions</p>
        </Col>
      </Row>
    </CardBody>
  </Card>
);

export default TaskHeader;
