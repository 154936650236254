import axios from "axios";
import Bugsnag from "@bugsnag/js";

import Http from "./Http";
import { convertJPGToJPEG } from "../base/helpers/image";

class S3Service extends Http {
  static $displayName = "S3Service";

  /**
   * Sending request on adding file to S3
   * fileType is a constants variable
   * */
  requestLoading(model) {
    return this.post("/files", model);
  }

  /**
   * Direct S3 file loading
   * */
  uploadToS3(model) {
    const { data, file } = model;
    const formData = new FormData();

    Object.keys(data.meta.formData).forEach((key) => {
      formData.append(key, data.meta.formData[key].toString());
    });

    formData.append("file", file);

    return axios
      .post(data.meta.url, formData)
      .then(() => data.data)
      .catch((e) => Bugsnag.notify(e));
  }

  /**
   * Simplified image uploading process
   * */

  // TODO use one of these methods:
  //  - uploadImageFiles
  //  - uploadImageFile
  async uploadImageFiles(files, type) {
    const promises = files.map(async (file) => {
      if (file.type === "image/jpg") {
        file = await convertJPGToJPEG(file);
      }

      const { data } = await this.requestLoading([
        { contentType: file.type, type },
      ]);

      await this.uploadToS3({ data: data[0], file });

      return data[0];
    });

    return Promise.all(promises);
  }

  async uploadImageFile(file) {
    const data = await this.requestLoading({ contentType: file.type });
    const result = await this.uploadToS3({ data, file });

    return result;
  }
}

export default S3Service;
