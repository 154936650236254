import Bugsnag from "@bugsnag/js";

import Http from "./Http";

class MarketPlaceService extends Http {
  static $displayName = "MarketPlace";

  getMarketplace() {
    return this.get("/marketplace-accounts")
      .then(({ data }) => {
        return { data };
      })
      .catch((e) => Bugsnag.notify(e));
  }

  getEtsyAccount() {
    return this.get("/etsy-accounts/auth-url");
  }

  getEbayAccount() {
    return this.get("/ebay-accounts/auth-url");
  }

  connectEtsyAccount(verifier) {
    return this.post("/etsy-accounts", { verifier });
  }

  disconnectEtsyAccount() {
    return this.delete("/etsy-accounts");
  }

  connectEbayAccount(code) {
    return this.post("/ebay-accounts", { code });
  }

  disconnectEbayAccount() {
    return this.delete("/ebay-accounts");
  }

  connectGmailToMarketplace(marketplace, isExtension, model) {
    return isExtension
      ? this.put(`/${marketplace}`, model)
      : this.patch(`/${marketplace}`, model);
  }
}

export default MarketPlaceService;
