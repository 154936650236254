export const phrases = {
  postingToEbay:
    "Posting to eBay. You may navigate away while we process your listing. We'll let you know when the process is complete.",
  process: "Please wait while we process your request",
  preparingData: "Preparing item data",
  connectingToPoshmark: "Connecting to Poshmark",
  preparingImages: "Preparing images",
  postingToPoshmark: "Posting to Poshmark",
  savingItemInformation: "Saving item information",
  postingToMercari: "Posting to Mercari",
  updatingOnPoshmark: "Updating item on Poshmark",
  updatingOnMercari: "Updating item on Mercari",
  processingImage: "Processing image",
  updateConfirmationDescription:
    "Updating will identify and log your sales, delist them from other marketplaces, as well as update your Analytics/Bookkeeping. Proceed?",
  updateConfirmationTitle: "Update",
  poshmarkNotConnectedDrafts:
    "We had trouble accessing your Poshmark account. Please be sure you are logged into poshmark.com in a different tab. Once you are logged in, click “Connect” so we can try again.",
  ebayNotConnectedDrafts:
    "We had trouble accessing your Ebay account. Please be sure you are logged into ebay.com in a different tab. Once you are logged in, click “Connect” so we can try again.",
  mercariNotConnectedDrafts:
    "We had trouble accessing your Mercari account. Please be sure you are logged into mercari.com in a different tab. Once you are logged in, click “Connect” so we can try again.",
  mercariReauthentication: "You may need to reconnect your Mercari account.",
  mercariIssueDetected:
    "We have detected issues with Mercari and we're actively working on resolving it.",
  updatingYourSales: "Updating your sales",
  fetchingActiveInventory: "Fetching active inventory",
  updateComplete: "Sales have been updated",
  fetchingSoldItems: "Fetching sold items from connected marketplaces",
  toggleMergeVisibility: "Toggle displaying merged items",
  pageWasClosed: "Page was closed!",
};
