import { useCallback, useEffect, useState } from "react";

import EbayExtension from "../../../services/EbayExtension";
import { useExtensionInstall } from "./Poshmark/useExtensionInstall";
import { useService } from "../../../base/hooks/useService";
import {
  authUrl_EBAY,
  WINDOW_CONFIG_POSHMARK,
  WINDOW_NAME_POSHMARK,
} from "../constants/connectButton";

export const useEbayIsConnect = () => {
  const [isConnect, updateIsConnect] = useState(false);
  const [isPending, updateIsPending] = useState(false);
  const { isInstalled } = useExtensionInstall();

  /**
   * @type {EbayExtension}
   */
  const ebay = useService(EbayExtension);

  const handleSignIn = useCallback(() => {
    const openedWindow = open(
      authUrl_EBAY,
      WINDOW_NAME_POSHMARK,
      WINDOW_CONFIG_POSHMARK
    );

    if (isConnect && isInstalled && openedWindow) {
      setTimeout(() => openedWindow.close(), 3000);
    }
  }, [isConnect, isInstalled]);

  useEffect(() => {
    const { unSubscribe } = ebay.onCompletedAuth((data) => {
      if (data?.err) return;

      updateIsConnect(true);
      updateIsPending(true);
    });

    return unSubscribe;
  }, [ebay]);

  useEffect(() => {
    const { unSubscribe } = ebay.onCompletedLogout(() => {
      updateIsConnect(false);
    });

    return unSubscribe;
  }, [ebay]);

  useEffect(() => {
    ebay
      .isConnected()
      .then((result) => {
        updateIsConnect(result);
      })
      .catch(() => {
        updateIsConnect(false);
      });
  }, [ebay]);

  return {
    handleSignIn,
    isConnected: isConnect?.result === true && isInstalled,
    isPending,
    updateIsPending,
    isInstalled,
  };
};
