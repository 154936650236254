import React, { useCallback, useEffect, useMemo } from "react";
import EmptyList from "../../components/EmptyList";
import Table from "../../../../base/components/Table";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { TableStructure } from "./columns";
import "./index.scss";
import PageTitle from "../../../profile/components/PageName";
import { Container } from "reactstrap";
import WhiteBox from "../../components/WhiteBox";
import { useTable } from "../../../../base/hooks/useTable";
import HeaderComponent from "../../components/TopTablePanel";
import { DRAFTS_GROUP_LINKS } from "../../config";
import { useHistory } from "react-router-dom";
import { replaceURLParams } from "../../../../base/helpers/replaceURLParams";
import { TableContext } from "../../contexts/table";
import { useCopyDraft } from "../../hooks/useCopyDraft";
import { useDeleteDraft } from "../../hooks/useDeleteDraft";
import {
  useStatefullProvider,
  useStatelessProvider,
} from "../../../../base/hooks/useProvider";
import PageMeta from "../../../../base/components/MetaTags";
import { useProduct, useTotalProducts } from "../../hooks/useProduct";
import { PRODUCT_TYPE } from "../../../../base/constants/product";
import AddDraftButton from "../../components/AddDraftButton";
import { DRAFT_PREV_PATH } from "../../constants";
import { usePrevLocation } from "../../../../base/hooks/usePrevLocation";
import emptyDraftIcon from "../../components/EmptyList/images/drafts-empty.png";

const LIMIT = 10;

const reverseOrderMap = { createdAt: true };

const Drafts = ({ load, total }) => {
  const history = useHistory();

  const { params, merge } = useLocationQuery();
  const [{ used }] = useStatelessProvider(TableContext);

  const getCollection = useProduct();

  const [loading, pagination, list, handleTableChange, { refresh }] = useTable({
    searchQueryParam: "query",
    sortTypeQueryParam: "orderType",
    sortByQueryParam: "orderBy",
    reverseOrderMap,
    getNewDataService: getCollection,
    limit: LIMIT,
  });

  const [, updateTableContext] = useStatefullProvider(TableContext);

  const { onCopy, view } = useCopyDraft(
    useCallback(() => {
      updateTableContext((data) => ({
        ...data,
        used: data.used + 1,
      }));

      refresh();
    }, [refresh, updateTableContext])
  );

  const { offset } = params;

  const { onDelete, view: deleteView } = useDeleteDraft(
    useCallback(() => {
      const noData = !(list.length - 1);

      updateTableContext((data) => ({
        ...data,
        used: data.used - 1,
      }));

      if (noData && offset) {
        merge("offset", offset - LIMIT);
        return;
      }

      refresh();
    }, [refresh, list.length, merge, offset, updateTableContext])
  );

  useEffect(() => {
    updateTableContext({
      onCopy,
      onDelete,
      used: total,
      available: 29,
    });
  }, [onCopy, updateTableContext, onDelete, total]);

  const { saveLocation } = usePrevLocation(DRAFT_PREV_PATH);

  return (
    <Container fluid>
      <PageMeta title="Drafts" />
      <PageTitle title={"DRAFTS"} />
      <div className={"drafts-page"}>
        {load ? (
          <WhiteBox>
            <p>Loading...</p>
          </WhiteBox>
        ) : !used && list.length === 0 ? (
          <EmptyList
            imgSrc={emptyDraftIcon}
            link={DRAFTS_GROUP_LINKS.ADD_DRAFT}
            buttonContent={"+ Add draft"}
            AddButton={AddDraftButton}
          >
            {`To create a new draft, click "+Add draft"`}
          </EmptyList>
        ) : (
          <div className={"drafts-table"}>
            <Table
              data={list}
              dataStructure={TableStructure}
              loading={loading}
              HeaderComponent={HeaderComponent}
              limit={LIMIT}
              searchField={"query"}
              offset={params.offset || 0}
              totalCount={pagination.totalCount}
              handleTableChange={handleTableChange}
              onRowClick={({ id }) => {
                saveLocation();
                history.push(
                  replaceURLParams(DRAFTS_GROUP_LINKS.VIEW_DRAFT, {
                    draftId: id,
                  })
                );
              }}
            />
          </div>
        )}
      </div>

      {/* Copy action */}
      {view}
      {/* Delete action */}
      {deleteView}
    </Container>
  );
};

export default () => {
  const [{ total, load }] = useTotalProducts(PRODUCT_TYPE.DRAFT);

  const providedData = useMemo(() => {
    return {
      onCopy: () => {},
      onDelete: () => {},
      used: total,
      available: 0,
    };
  }, [total]);

  return (
    <TableContext.Provider value={providedData}>
      <Drafts load={load} total={total} />
    </TableContext.Provider>
  );
};
