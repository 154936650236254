import React from "react";
import "./index.scss"

const Divider = () => {
	return (
		<div className={"draft-divider"}/>
	)
}


export default Divider