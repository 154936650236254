import React, { useCallback, useState } from "react";
import Bugsnag from "@bugsnag/js";

import CustomButton from "../../../base/components/CustomButton";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../base/components/CustomModal";
import { useLoading } from "../../../base/hooks/useLoading";
import { useService } from "../../../base/hooks/useService";
import ExpensesService from "../../../services/ExpensesService";

export const useDeleteExpense = (reload) => {
  const [selectedId, setSelectedId] = useState();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [loading, { registerPromise }] = useLoading();

  const onOpenDeleteModal = useCallback(
    (id) => {
      setSelectedId(id);
      setIsOpenDeleteModal(true);
    },
    [setIsOpenDeleteModal, setSelectedId]
  );

  const onCloseDeleteModal = useCallback(() => {
    setIsOpenDeleteModal(false);
  }, [setIsOpenDeleteModal]);

  /**
   * @type {ExpensesService}
   */
  const expensesService = useService(ExpensesService);

  const onConfirmDelete = useCallback(() => {
    registerPromise(expensesService.deleteExpense(selectedId))
      .then(() => {
        reload();
      })
      .catch((e) => Bugsnag.notify(e))
      .finally(() => {
        onCloseDeleteModal();
      });
  }, [
    registerPromise,
    expensesService,
    selectedId,
    reload,
    onCloseDeleteModal,
  ]);

  const deleteModal = (
    <CustomModal isOpen={isOpenDeleteModal} onClose={onCloseDeleteModal}>
      <ModalHeader onClose={onCloseDeleteModal}>Expense deleting</ModalHeader>
      <ModalBody>
        <p className="ui-regular">
          Are you sure you want to delete this expense? Added price will be
          deducted from Bookkeeping.
        </p>
      </ModalBody>
      <ModalFooter>
        <CustomButton onClick={onCloseDeleteModal} className="outline-primary ">
          No
        </CustomButton>
        <CustomButton
          className="filled-primary"
          onClick={onConfirmDelete}
          disabled={loading}
        >
          Yes
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );

  return [onOpenDeleteModal, deleteModal];
};
