const LINK_AUTH = "/auth";

export const AUTH_GROUP_LINKS = {
  BASE: LINK_AUTH,
  LINK_LOGIN: `${LINK_AUTH}/login`,
  LINK_LOG_OUT: `${LINK_AUTH}/logout`,
  LINK_FORGOT_PASSWORD: `${LINK_AUTH}/forgot-password`,
  LINK_RESET_PASSWORD: `/restore-password`,
  LINK_REGISTER: `${LINK_AUTH}/register`,
  LINK_VERIFICATION: `/verification`,
  LINK_BLOCKED_ACCOUNT: `${LINK_AUTH}/blocked-account`,
  LINK_CHANGE_EMAIL: "/changeEmail",
  NEW_EMPLOYEE: "/newEmployee",
  CREATE_PASSWORD: "/create-password",
  EXTERNAL: {
    TERMS_OF_USE: `/privacy`,
    AFTER_LOGIN: "/app/dashboard",
  },
  VERIFY_EMAIL: `${LINK_AUTH}/verify-email`,
};
