import yup from "./yup";
import {
  MAX_MESSAGE_LENGTH,
  MAX_TASK_DESCRIPTION_LENGTH,
  MAX_TASK_TITLE_LENGTH,
} from "./lengthConstants";
import {
  MESSAGE_MAX_LENGTH,
  MESSAGE_REQUIRED,
  TASK_ASSIGNEE_REQUIRED,
  TASK_DESCRIPTION_MAX_LENGTH,
  TASK_TITLE_MAX_LENGTH,
  TASK_TITLE_REQUIRED,
} from "../base/constants/forms";

export const TASK_MESSAGE = yup
  .string()
  .max(MAX_MESSAGE_LENGTH, MESSAGE_MAX_LENGTH)
  .trim()
  .required(MESSAGE_REQUIRED);

export const TASK_TITLE = yup
  .string()
  .max(MAX_TASK_TITLE_LENGTH, TASK_TITLE_MAX_LENGTH)
  .trim()
  .required(TASK_TITLE_REQUIRED);

export const TASK_DESCRIPTION = yup
  .string()
  .max(MAX_TASK_DESCRIPTION_LENGTH, TASK_DESCRIPTION_MAX_LENGTH)
  .trim();

export const TASK_ASSIGNEE = yup
  .number()
  .nullable()
  .required(TASK_ASSIGNEE_REQUIRED);
