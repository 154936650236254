import React, { useCallback, useState } from "react";
import { CardBody, Card } from "reactstrap";
import { Link } from "react-router-dom";
import { Formik } from "formik";

import AuthCardContainer from "../../components/AuthCardContainer";
import AuthHeader from "../../components/Header";
import CustomErrorMessage from "../../../../base/components/ErrorMessage";
import FormikEmail from "../../../../base/components/FormikEmail";

import { useService } from "../../../../base/hooks/useService";
import AuthService from "../../../../services/AuthService";
import { useLoading } from "../../../../base/hooks/useLoading";
import { initialValues, validationSchema } from "./form";
import { AUTH_GROUP_LINKS } from "../../config";
import DevideLine from "../../components/DevideLine";
import FormikPassword from "../../../../base/components/FormikPassword";
import CustomButton from "../../../../base/components/CustomButton";
import {
  MAX_PASSWORD_LENGTH,
  MIN_PASSWORD_LENGTH,
} from "../../../../validation/lengthConstants";
import SignInFooter from "../../components/SignInFooter";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../base/components/CustomModal";
import {
  getIsInvalidCredential,
  signUpPopup,
} from "../../utils/getIsInvalidCredential";
import { ERROR_INVALID_CREDENTIALS_MESSAGE } from "../../../../base/constants/forms";
import {
  useCleanCustomErrors,
  useCustomError,
} from "../../../../base/contexts/CustomErrorMessage";
import GoogleAuth from "../../components/GoogleAuth";
import { useAfterLogin } from "../../hooks/useAfterLogin";
import "../../index.scss";

const Login = () => {
  /**
   * @type {AuthService}
   */
  const authService = useService(AuthService);

  const [isLoading, { registerPromise }] = useLoading();

  const [errorModal, updateErrorModal] = useState(null);

  const { setError } = useCustomError();
  useCleanCustomErrors();

  const handleToggleModal = useCallback(() => {
    updateErrorModal((values) => ({ ...values, isOpen: false }));
  }, []);

  const { redirect } = useAfterLogin();

  const loginUser = useCallback(
    (values) => {
      registerPromise(authService.login(values))
        .then(redirect)
        .catch((baseError) => {
          const { error } = baseError;
          const isInvalidCredential = getIsInvalidCredential(error);
          if (isInvalidCredential) {
            setError("email", ERROR_INVALID_CREDENTIALS_MESSAGE);
            setError("password", ERROR_INVALID_CREDENTIALS_MESSAGE);
            return;
          }

          updateErrorModal(signUpPopup[error?.code]);
        });
    },
    [registerPromise, authService, setError, redirect]
  );

  return (
    <AuthCardContainer metaText="Login">
      <Card className="overflow-hidden">
        <AuthHeader
          headerText="Welcome Back !"
          description="Sign in to continue to Fearn"
        />
        <CardBody className="pt-0">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={loginUser}
          >
            {({ handleSubmit, values }) => (
              <form className="form-horizontal p-2" onSubmit={handleSubmit}>
                <GoogleAuth>Sign in with Google</GoogleAuth>
                <DevideLine text="Or" />
                <FormikEmail
                  id="email"
                  name="email"
                  label="Email"
                  containerClassName="mb-3"
                  placeholder="Enter your email"
                  type="email"
                  ErrorMessage={CustomErrorMessage}
                  onChange={() => {
                    setError("email", "");
                  }}
                />
                <FormikPassword
                  id="password"
                  label="Password"
                  name="password"
                  containerClassName="mb-3"
                  type="password"
                  placeholder="Enter password"
                  min={MIN_PASSWORD_LENGTH}
                  max={MAX_PASSWORD_LENGTH}
                  ErrorMessage={CustomErrorMessage}
                  onChange={() => {
                    setError("password", "");
                  }}
                />
                <CustomButton
                  type="submit"
                  className="filled-primary w-100 mt-3"
                  disabled={isLoading}
                >
                  Sign in
                </CustomButton>
                <div className="mt-4">
                  <Link
                    to={AUTH_GROUP_LINKS.LINK_FORGOT_PASSWORD}
                    className="d-flex align-items-center justify-content-center ui-regular-gray forgot-password-link"
                  >
                    <i className="mdi mdi-lock me-1 ui-icon-md" />
                    Forgot your password?
                  </Link>
                </div>
                <CustomModal
                  isOpen={errorModal?.isOpen}
                  onClose={handleToggleModal}
                >
                  <ModalHeader onClose={handleToggleModal}>
                    {errorModal?.title}
                  </ModalHeader>
                  <ModalBody>
                    <p className="ui-regular">{errorModal?.text}</p>
                  </ModalBody>
                  <ModalFooter>
                    {errorModal?.Button && (
                      <errorModal.Button
                        values={values}
                        onClick={handleToggleModal}
                      />
                    )}
                  </ModalFooter>
                </CustomModal>
              </form>
            )}
          </Formik>
        </CardBody>
      </Card>
      <SignInFooter />
    </AuthCardContainer>
  );
};

export default Login;
