import React from 'react'
import PropTypes from "prop-types";

const ErrorMessageDefault = ({ children }) => {
  return (
    <span className="invalid-feedback">
      {children}
    </span>
  )
};

ErrorMessageDefault.propTypes = {
  children: PropTypes.any
};

export default ErrorMessageDefault;
