import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";
import DotsLoader from "../../../../../base/components/DotLoader";
import joinClassNames from "../../../../../base/helpers/joinClassNames";

const Image = ({
                 path,
                 onDelete,
                 onEdit,
                 containerClassName,
                 wrapperClassName = "",

               }) => {
  const imageRef = useRef(null);

  useEffect(() => {
    if (path) imageRef.current?.classList.add(styles.loading);

    const onLoad = () => {
      imageRef.current?.classList.remove(styles.loading);
    };

    imageRef.current?.addEventListener("load", onLoad);
    return () => imageRef.current?.removeEventListener("load", onLoad);
  }, [path]);

  return (
    <div
      className={containerClassName}
    >
      <div className={`${wrapperClassName} ${styles.wrapper}`}>
        <div className={styles.overlay}/>
        <button
          type="button"
          onClick={onDelete}
          className={styles.deleteButton}
        >
          <i className="mdi mdi-close-thick font-size-20"/>
        </button>
        {onEdit && (
          <div className={styles.editButtonWrapper}>
            <button
              type="button"
              className={styles.editButton}
              onClick={onEdit}
            >
              <i className="bx bxs-pencil font-size-13 mr-2"/>
              Edit
            </button>
          </div>
        )}
        <img
          className={joinClassNames(styles.image)}
          src={path}
          ref={imageRef}
        />
        <DotsLoader className={styles.imageLoader}/>
      </div>
    </div>
  );
};

Image.propTypes = {
  path: PropTypes.string,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  containerClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

export default Image;
