import React from "react";
import { Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import AuthCardContainer from "../../components/AuthCardContainer";

import { AUTH_GROUP_LINKS } from "../../config";

const BlockedAccount = () => {
  return (
    <AuthCardContainer metaText="Blocked account">
      <Card className="overflow-hidden">
        <CardBody>
          <div className="text-center">
            Your account is blocked
            <div className="mt-4">
              <Link
                to={AUTH_GROUP_LINKS.LINK_REGISTER}
                className="fw-medium text-primary"
              >
                Sign up now
              </Link>
            </div>
          </div>
        </CardBody>
      </Card>
    </AuthCardContainer>
  );
};

export default BlockedAccount;
