/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Formik } from "formik";

import { useLoading } from "../../../../../../base/hooks/useLoading";
import FormPartials from "../../../../classes/FormPartials";
import { FORM_TYPES } from "../../../../const/form";
import { useHanleSaveDraftErrors } from "../../../../hooks/useSaveSubmit";
import { useInitialFormValues } from "../../../../helpers/mapFormValues";
import { useListingUrl } from "../../../../components/DraftEbayFormInner/partials/ListingUrl/hooks/useListingUrl";
import { Card, CardBody, Col, Row } from "reactstrap";
import { MARKETPLACES_NAMES_REQUEST } from "../../../../constants";
import DraftHeader from "../../../../components/DraftHeader";
import DraftPoshmarkFormInner from "../../../../components/DraftPoshmarkFormInner";
import CustomButton from "../../../../../../base/components/CustomButton";
import PoshmarkStatus from "../../../../components/PoshmarkStatus";
import {
  STATUS_POSHMARK_ACTIVE,
  STATUS_POSHMARK_NOT_CONNECTED,
  STATUS_POSHMARK_NOT_INSTALLED,
} from "../../../../const/poshmark";
import { ConnectButtonPoshmark } from "../../../../components/ConnectButton/ConnectButton";
import ConnectPlaceholder from "../../../../components/ConnectPlaceholder";
import { usePoshmarkIsConnect } from "../../../../hooks/Poshmark/usePoshmarkIsConnect";
import { highlightDraftFields } from "../../../../classes/forms/poshmark";
import CreationDate from "../../../../components/CreationDate";
import { useDeleteDraft } from "../../../../hooks/useDeleteDraft";
import { DRAFTS_GROUP_LINKS } from "../../../../config";
import { useService } from "../../../../../../base/hooks/useService";
import PoshmarkExtension from "../../../../../../services/PoshmarkExtension";
import ImageService from "../../../../../../services/ImageService";
import PoshmarkService from "../../../../../../services/PoshmarkService";
import {
  EDIT_INVENTORY_LINKS,
  INVENTORY_GROUP_LINKS,
} from "../../../../../inventory/config";
import { useCustomModal } from "../../../../hooks/useCustomModal";
import { convertSpecPoshmarkFieldsForSaving } from "../../../../helpers/Poshmark/convertSpecPoshmarkFieldsForSaving";
import ToasterService from "../../../../../../services/ToastService";
import {
  INVENTORY_UPDATE_SUCCESS,
  PRODUCT_LISTED_SUCCESS,
} from "../../../../../../base/constants/messages";
import { generateFormModelWithBool } from "../../../../helpers/generateFormModelWithBool";
import AutoReloadMarketplace from "../../../../components/AutoReloadMarketplace";
import { useProductStatusLabel } from "../../../../hooks/useProductStatusLabel";
import { usePathIncludes } from "../../../../hooks/usePathIncludes";
import InstallExtensionPlaceholder from "../../../../components/InstallExtensionPlaceholder";
import { useDelistAction } from "../../../../hooks/useProductStatus";
import { getCountOfActiveItems } from "../../../../helpers/getTableFieldValue";
import "../../../../index.scss";
import { EDIT_DRAFT_LINKS } from "../../../EditDraft/config";
import { PRODUCT_TYPE } from "../../../../../../base/constants/product";
import {
  getTouchedObj,
  scrollToErrors,
} from "../../../../hooks/useFormikScrollToErrors";
import { useDraftConnectionBadget } from "../../../../hooks/useDraftConnectionBadget";
import DraftUtils from "../../../../utils/DraftUtils";
import ProgressBar from "../../../../../sold/components/ProgressBar";
import Bugsnag from "@bugsnag/js";
import { useGlobalLoading } from "../../../../../../base/contexts/LoadingContext";
import { INVENTORY_STATUSES } from "../../../../../inventory/pages/EditInventory/constants";
import { phrases } from "../../../../../../store/phrases";
import { MarketplaceFormMonitor } from "../../../../components/MarketplaceFormMonitor";
import MarketPlaceService from "../../../../../../services/MarketplaceService";

const CreateDraftPoshmark = ({
  updateFullForm,
  fullFormData,
  onSave,
  onSaveAndCreateNew,
  onHandleDelete,
  fetchedData = null,
  isDelistBtnDisabled,
  toggleStatus,
  isDirty,
  isDisableCreateDraft,
  setFormLoading = () => {},
  loadLatest = null,
}) => {
  const [marketplaceData, updateMarketplaceData] = useState(null);
  const [marketplaceAccounts, setMarketplaceAccounts] = useState(null);
  const [processing, { registerPromise }] = useLoading();
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const { isConnected, isInstalled } = usePoshmarkIsConnect();
  const [loading, setLoading] = useState(false);
  const [processMessage, setProcessMessage] = useState("");
  const { setLoading: setLoadingWithMessage } = useGlobalLoading();
  const marketplaceService = useService(MarketPlaceService);
  const [, setCategoriesData] = useState([]); // used to check state of categories for rendering stuff - not used for actual categories

  /**
   * @type {PoshmarkExtension}
   */
  const poshmark = useService(PoshmarkExtension);
  /**
   * @type {PoshmarkService}
   */
  const poshmarkService = useService(PoshmarkService);

  /**
   * @type {ToasterService}
   */
  const toastr = useService(ToasterService);
  const formRef = useRef();
  const history = useHistory();

  const {
    params: { draftId },
  } = useRouteMatch();

  const formPartials = new FormPartials(FORM_TYPES.POSHMARK, true);

  const onCatchError = useHanleSaveDraftErrors();
  const { isPosted, link } = useListingUrl({ fetchedData });
  const { reloadPage } = usePathIncludes(INVENTORY_GROUP_LINKS.BASE);

  const handleSave = useCallback(
    (values, { setErrors, setTouched }) => {
      showProgressBar("Saving your changes");
      return registerPromise(
        onSave(values).catch((err) =>
          onCatchError(err, () => {
            Bugsnag.notify(err, (event) => {
              event.addMetadata("poshmarkHandleSaveError", {
                values,
              });
            });
            setTouched(highlightDraftFields, false);
            setErrors(highlightDraftFields);
          })
        )
      ).finally(hideProgressBar);
    },
    [onCatchError, onSave, registerPromise]
  );

  const initialValues = useInitialFormValues(
    fullFormData,
    formPartials.initialValues,
    formPartials.mapFields,
    formPartials.formKey
  );

  const handlePost = useCallback(
    (values, val) => {
      Bugsnag.leaveBreadcrumb(`Poshmark - ${phrases.preparingData}`, values);
      showProgressBar(phrases.preparingData);
      setLoadingWithMessage(true, phrases.preparingData);

      return registerPromise(
        onSaveAndCreateNew(values, null, "")
          .then(async (response) => {
            const {
              data: { productPoshmarkSpecificFields },
            } = response;
            const draftId = productPoshmarkSpecificFields.id;

            if (!productPoshmarkSpecificFields.listingId) {
              let draftIdPoshmark;
              let convertedData;

              Bugsnag.leaveBreadcrumb(
                `Poshmark - ${phrases.connectingToPoshmark}`
              );
              showProgressBar(phrases.connectingToPoshmark);
              setLoadingWithMessage(true, phrases.connectingToPoshmark);

              await poshmark
                .createDraft()
                .then((data) => {
                  draftIdPoshmark = data.id;
                  convertedData = convertSpecPoshmarkFieldsForSaving(
                    productPoshmarkSpecificFields,
                    val
                  );
                  const picturesBase64 = convertedData.pictures.map(
                    (picture) => {
                      return ImageService.fileToBase64(picture.file);
                    }
                  );

                  Bugsnag.leaveBreadcrumb(
                    `Poshmark - ${phrases.preparingImages}`,
                    convertedData.pictures
                  );
                  showProgressBar(phrases.preparingImages);
                  setLoadingWithMessage(true, phrases.preparingImages);

                  return Promise.all(picturesBase64);
                })
                .then((pictures) => {
                  Bugsnag.leaveBreadcrumb(
                    `Poshmark - ${phrases.postingToPoshmark}`,
                    {
                      convertedData,
                      draftIdPoshmark,
                    }
                  );
                  setLoadingWithMessage(true, phrases.postingToPoshmark);
                  showProgressBar(phrases.postingToPoshmark);

                  return poshmark.postDraft(
                    draftIdPoshmark,
                    pictures,
                    convertedData
                  );
                })
                .then((resp) => {
                  if (resp?.result?.errorType) {
                    toastr.error(
                      resp?.result?.userMessage ||
                        "Something went wrong while listing this item. Please try again."
                    );

                    return Promise.reject(new Error(resp?.result?.userMessage));
                  }

                  Bugsnag.leaveBreadcrumb(
                    `Poshmark - ${phrases.savingItemInformation}`,
                    resp
                  );
                  setLoadingWithMessage(true, phrases.savingItemInformation);
                  showProgressBar(phrases.savingItemInformation);

                  return poshmarkService.postProduct(draftId, {
                    listingId: draftIdPoshmark,
                  });
                })
                .then(() => {
                  Bugsnag.leaveBreadcrumb(
                    "Poshmark - Handling navigation",
                    values
                  );
                  toastr.success(PRODUCT_LISTED_SUCCESS);
                  toggleStatus?.(
                    MARKETPLACES_NAMES_REQUEST.poshmark,
                    INVENTORY_STATUSES.listed
                  );

                  const url = EDIT_INVENTORY_LINKS.POSHMARK.replace(
                    ":draftId",
                    draftId
                  );
                  const draftUrl = EDIT_DRAFT_LINKS.POSHMARK.replace(
                    ":draftId",
                    draftId
                  );

                  if (
                    window.location.href.includes(url) ||
                    window.location.href.includes(draftUrl) ||
                    window.location.href.includes(DRAFTS_GROUP_LINKS.ADD_DRAFT)
                  ) {
                    loadLatest?.();
                    history.replace(url);
                  }
                })
                .catch((err) => {
                  Bugsnag.notify(err, (event) => {
                    event.addMetadata("poshmarkHandlePostError", {
                      values,
                    });
                  });

                  if (fetchedData?.status === PRODUCT_TYPE.ACTIVE) {
                    history.replace(
                      EDIT_INVENTORY_LINKS.POSHMARK.replace(":draftId", draftId)
                    );
                  } else {
                    history.replace(
                      EDIT_DRAFT_LINKS.POSHMARK.replace(":draftId", draftId)
                    );
                  }

                  return Promise.reject(err);
                });
            }
          })
          .catch((err) => {
            onCatchError(err, () => Bugsnag.notify(err));
          })
      ).finally(() => {
        hideProgressBar();
        setLoadingWithMessage(false);
      });
    },

    [
      registerPromise,
      onSaveAndCreateNew,
      poshmark,
      poshmarkService,
      toastr,
      history,
      reloadPage,
      fetchedData?.status,
      onCatchError,
    ]
  );

  const handleSaveNewDraftSubmit = useCallback(
    (values, { resetForm, setErrors, setTouched }) => {
      showProgressBar("Saving your changes");
      return registerPromise(
        onSaveAndCreateNew(values)
          .then(() => {
            resetForm();
          })
          .catch((err) =>
            onCatchError(err, () => {
              Bugsnag.notify(err);
              setTouched(highlightDraftFields, false);
              setErrors(highlightDraftFields);
            })
          )
      ).finally(hideProgressBar);
    },
    [onCatchError, onSaveAndCreateNew, registerPromise]
  );

  useEffect(() => {
    if (isConnected) {
      updateMarketplaceData(STATUS_POSHMARK_ACTIVE);
    } else {
      updateMarketplaceData(
        !isInstalled
          ? STATUS_POSHMARK_NOT_INSTALLED
          : STATUS_POSHMARK_NOT_CONNECTED
      );
    }
  }, [isInstalled, isConnected, updateMarketplaceData]);

  const initialValuesTransformed = useMemo(() => {
    if (initialValues?.tags?.length) {
      if (typeof initialValues?.tags === "object") {
        initialValues.tags = initialValues.tags?.map((tag) => {
          return tag?.tagId || tag;
        });
      } else if (typeof initialValues?.tags === "string") {
        initialValues.tags = initialValues.tags
          .split(",")
          .map((tag) => tag.trim());
      }
    }

    return initialValues;
  }, [initialValues]);

  const {
    view: popupView,
    onDelete,
    deleting,
  } = useDeleteDraft(() => {
    history.push(DRAFTS_GROUP_LINKS.BASE);
  });

  const handleDelete = () => {
    onHandleDelete();
    onDelete(draftId);
  };

  const { delist } = useDelistAction({
    onDelist: onHandleDelete,
    onToggleStatus: toggleStatus,
  });

  const handleDelistAction = () => {
    const countOfActiveListing = getCountOfActiveItems(fetchedData);

    showProgressBar("Delisting");
    setLoadingWithMessage(true, "Delisting item");
    registerPromise(
      delist({
        draftId,
        marketplace: MARKETPLACES_NAMES_REQUEST.poshmark,
        listingId: fetchedData?.productPoshmarkSpecificFields?.listingId,
        fetchedData,
        formValues: formRef?.current?.values,
        countOfActiveListing,
      })
    ).finally(() => {
      hideProgressBar();
      setLoadingWithMessage(false);
    });
  };

  const { modal: modalDelist, onShowModal: onShowModalDelist } = useCustomModal(
    {
      title: "Delist item",
      message: "Do you want to delist the item from  Poshmark?",
      onAccept: handleDelistAction,
    }
  );

  const showProgressBar = (message) => {
    setLoading(true);
    setProcessMessage(message);
  };

  const hideProgressBar = () => {
    setLoading(false);
    setProcessMessage("");
  };

  const handleUpdate = useCallback(() => {
    showProgressBar(phrases.preparingData);
    setLoadingWithMessage(true, phrases.preparingData);
    Bugsnag.leaveBreadcrumb(
      `Poshmark Revise - ${phrases.preparingData}`,
      fullFormData
    );

    return registerPromise(
      onSave(fullFormData, null, "").then(
        ({ data: { productPoshmarkSpecificFields } }) => {
          const convertedData = convertSpecPoshmarkFieldsForSaving(
            productPoshmarkSpecificFields,
            formRef.current?.values
          );
          const picturesBase64 = convertedData.pictures.map((picture) => {
            return ImageService.fileToBase64(picture.file);
          });

          showProgressBar(phrases.preparingImages);
          setLoadingWithMessage(true, phrases.preparingImages);
          Bugsnag.leaveBreadcrumb(
            `Poshmark Revise - ${phrases.preparingImages}`,
            {
              images: convertedData.pictures,
            }
          );

          return Promise.all(picturesBase64).then((pictures) => {
            showProgressBar(phrases.updatingOnPoshmark);
            setLoadingWithMessage(true, phrases.updatingOnPoshmark);
            Bugsnag.leaveBreadcrumb(
              `Poshmark Revise - ${phrases.updatingOnPoshmark}`,
              convertedData
            );

            return poshmark
              .editInventory(
                productPoshmarkSpecificFields.listingId,
                pictures,
                convertedData
              )
              .then((response) => {
                Bugsnag.leaveBreadcrumb(
                  "Poshmark Revise - Handling navigation"
                );

                if (response?.result?.errorType) {
                  toastr.error(
                    response.result.userMessage ||
                      "Something went wrong while revising this item. Please try again."
                  );

                  return Promise.reject(new Error(response.result.userMessage));
                }

                toastr.success(INVENTORY_UPDATE_SUCCESS);
                history.replace(
                  EDIT_INVENTORY_LINKS.POSHMARK.replace(":draftId", draftId)
                );
                const model = generateFormModelWithBool(
                  formRef.current?.values
                );

                formRef.current?.setTouched(model, false);
                formRef.current?.setErrors(model);
              });
          });
        }
      )
    ).finally(() => {
      hideProgressBar();
      setLoadingWithMessage(false);
    });
  }, [
    registerPromise,
    onSave,
    fullFormData,
    poshmark,
    toastr,
    history,
    draftId,
  ]);

  const { modal: modalUpdate, onShowModal: onShowModalUpdate } = useCustomModal(
    {
      title: "Update item",
      message:
        "Are you sure you want to apply changes on your Poshmark Listing?",
      onAccept: handleUpdate,
    }
  );

  const [getProductStatusLabel] = useProductStatusLabel();
  const { showConnectBadget } = useDraftConnectionBadget();

  useEffect(() => {
    setFormLoading(processing || categoriesLoading);
  }, [processing, categoriesLoading]);

  useEffect(() => {
    marketplaceService.getMarketplace().then((response) => {
      setMarketplaceAccounts(response);
    });
  }, [isConnected]);

  useEffect(() => {
    document.getElementById("poshmark-form").reset();
  }, []);

  return (
    <Card className="mt-3">
      {popupView}
      {modalDelist}
      {modalUpdate}
      <CardBody>
        {loading && <ProgressBar isActive processMessage={processMessage} />}
        <Formik
          initialValues={initialValuesTransformed}
          validationSchema={formPartials.validations}
          onSubmit={(val, helpers) => handlePost(fullFormData, val, helpers)}
          innerRef={formRef}
          isInitialValid={false}
        >
          {(helpers) => {
            const {
              handleSubmit,
              setFieldValue,
              values,
              isValid,
              dirty,
              touched,
            } = helpers;

            return (
              <form
                className="form-horizontal p-2"
                onSubmit={handleSubmit}
                id="poshmark-form"
              >
                <MarketplaceFormMonitor
                  item={fullFormData}
                  formKey="productPoshmarkSpecificFields"
                  localForm={values}
                  updateFullForm={updateFullForm}
                  touchedFields={touched}
                />
                <DraftHeader
                  showSettings
                  title={"Poshmark form"}
                  status={getProductStatusLabel(
                    fetchedData?.productPoshmarkSpecificFields?.status
                  )}
                  marketplace={MARKETPLACES_NAMES_REQUEST.poshmark}
                  fetchedData={fetchedData}
                  onHandleDelete={onHandleDelete}
                  formValues={values}
                  toggleStatus={toggleStatus}
                  marketplaceData={marketplaceAccounts}
                  marketplaceAccountKey="poshmarkAccountDto"
                  isConnected={isConnected}
                >
                  <>
                    {isPosted && (
                      <>
                        <CustomButton
                          className="filled-sm w-80 mr-2"
                          onClick={() => window.open(values.link, "_blank")}
                          disabled={!!formRef?.current?.errors?.link}
                        >
                          View item
                        </CustomButton>
                        <CustomButton
                          className="filled-sm mr-2"
                          onClick={
                            isValid
                              ? onShowModalUpdate
                              : () => {
                                  helpers
                                    .validateForm()
                                    .then((errors) => {
                                      helpers
                                        .setTouched(getTouchedObj(errors))
                                        .then(scrollToErrors);
                                    })
                                    .catch((e) => Bugsnag.notify(e));
                                }
                          }
                          disabled={
                            !dirty ||
                            processing ||
                            marketplaceData === STATUS_POSHMARK_NOT_CONNECTED ||
                            marketplaceData === STATUS_POSHMARK_NOT_INSTALLED ||
                            !marketplaceAccounts?.data?.poshmarkAccountDto
                          }
                        >
                          Revise
                        </CustomButton>
                      </>
                    )}
                    <ConnectButtonPoshmark />
                  </>
                </DraftHeader>
                {marketplaceData === STATUS_POSHMARK_NOT_INSTALLED &&
                  showConnectBadget && <InstallExtensionPlaceholder />}
                {isInstalled &&
                  showConnectBadget &&
                  (!isConnected ||
                    !marketplaceAccounts?.data?.poshmarkAccountDto) && (
                    <ConnectPlaceholder />
                  )}
                <DraftPoshmarkFormInner
                  setFieldValue={setFieldValue}
                  values={values}
                  postedInfo={{ isPosted, link }}
                  formRef={formRef}
                  status={marketplaceData}
                  suggestedFields={DraftUtils.extractSuggestedFields(
                    fullFormData
                  )}
                  isLoading={setCategoriesLoading}
                  setCategoriesData={setCategoriesData}
                />
                <Row className="section-wrapper">
                  <Col className="d-flex">
                    <CreationDate
                      text="Creation date:"
                      date={values.createdAt}
                      className="mr-60"
                    />

                    {!!values.updatedAt && (
                      <CreationDate
                        className="mr-60"
                        text="Last Updated:"
                        date={values.updatedAt}
                      />
                    )}
                    <PoshmarkStatus
                      className="mr-60"
                      status={marketplaceData}
                    />
                  </Col>

                  <Col
                    ms="7"
                    className="d-flex justify-content-md-end align-items-center"
                  >
                    {!isPosted && (
                      <>
                        {draftId && (
                          <CustomButton
                            className="outline-danger w-80 mr-2"
                            onClick={handleDelete}
                            disabled={deleting}
                          >
                            Delete
                          </CustomButton>
                        )}
                        <CustomButton
                          onClick={() => handleSave(fullFormData, helpers)}
                          disabled={processing}
                          type={"button"}
                          className="filled-primary w-80 mr-2"
                        >
                          Save
                        </CustomButton>
                        <CustomButton
                          type={"button"}
                          disabled={processing || isDisableCreateDraft}
                          onClick={() =>
                            handleSaveNewDraftSubmit(fullFormData, helpers)
                          }
                          className="outline-primary mr-2 save-new-btn"
                        >
                          Save + New draft
                        </CustomButton>
                        <CustomButton
                          className="outline-primary w-80 mr-2"
                          disabled={
                            processing ||
                            marketplaceData !== STATUS_POSHMARK_ACTIVE
                          }
                          type={isValid ? "submit" : "button"}
                          onClick={
                            isValid
                              ? undefined
                              : () => {
                                  helpers
                                    .validateForm()
                                    .then((errors) => {
                                      helpers
                                        .setTouched(getTouchedObj(errors))
                                        .then(scrollToErrors);
                                    })
                                    .catch((e) => Bugsnag.notify(e));
                                }
                          }
                        >
                          Post
                        </CustomButton>
                      </>
                    )}
                    {isPosted && (
                      <>
                        <CustomButton
                          className="outline-danger w-80 mr-2"
                          onClick={onShowModalDelist}
                          disabled={
                            processing ||
                            isDelistBtnDisabled ||
                            !isConnected ||
                            !isInstalled ||
                            !marketplaceAccounts?.data?.poshmarkAccountDto
                          }
                        >
                          Delist
                        </CustomButton>
                        <CustomButton
                          className="filled-primary w-80 mr-2"
                          onClick={() => window.open(values.link, "_blank")}
                          disabled={!!formRef?.current?.errors?.link}
                        >
                          View item
                        </CustomButton>

                        <CustomButton
                          className="filled-primary w-80"
                          onClick={
                            isValid
                              ? onShowModalUpdate
                              : () => {
                                  helpers
                                    .validateForm()
                                    .then((errors) => {
                                      helpers
                                        .setTouched(getTouchedObj(errors))
                                        .then(scrollToErrors);
                                    })
                                    .catch((e) => Bugsnag.notify(e));
                                }
                          }
                          disabled={
                            processing ||
                            !isDirty ||
                            marketplaceData === STATUS_POSHMARK_NOT_CONNECTED ||
                            marketplaceData === STATUS_POSHMARK_NOT_INSTALLED ||
                            !isConnected ||
                            !isInstalled ||
                            !marketplaceAccounts?.data?.poshmarkAccountDto
                          }
                        >
                          Revise
                        </CustomButton>
                      </>
                    )}
                  </Col>
                </Row>
              </form>
            );
          }}
        </Formik>
      </CardBody>
    </Card>
  );
};

export default (props) => {
  const { isPending, updateIsPending } = usePoshmarkIsConnect();
  const reload = isPending;

  return (
    <AutoReloadMarketplace
      reload={reload}
      onReload={() => updateIsPending(false)}
    >
      <CreateDraftPoshmark {...props} key={reload} />
    </AutoReloadMarketplace>
  );
};
