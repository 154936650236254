import STATUS_CODE from "../../base/constants/statusCodes";
import { useService as startService } from "../../base/hooks/useService";
import { UNAUTORIZED_STRING } from "../../base/constants/headers";
import { sendRefreshToken } from "../refreshSession";
import axios from "axios";
import { LINK_TO_LOGOUT } from "../../base/links";
import { mapResponse } from "./mapResponse";
import ToasterService from "../ToastService";
import { csrfRequestInterceptor } from "./csrfRequestInterceptor";
import Bugsnag from "@bugsnag/js";

function redirectToLogout() {
  let location = window.location;
  location.href = location.origin + LINK_TO_LOGOUT;
}

export const RefreshSessionInterceptor = (error) => {
  let dataObject = {},
    statusCode;
  const { response } = error;
  const { UNAUTHORIZED } = STATUS_CODE;

  /**
   * @type {ToasterService}
   */
  const toastr = startService(ToasterService);

  if (response) {
    dataObject = response.data;
  }

  statusCode =
    dataObject.code || dataObject.statusCode || (response && response.status);

  const isUnauth =
    statusCode === UNAUTHORIZED || statusCode === UNAUTORIZED_STRING;

  if (isUnauth) {
    return sendRefreshToken()
      .then(() => {
        // retry request
        return axios
          .request(csrfRequestInterceptor(error.config))
          .then(mapResponse);
      })
      .catch((err) => {
        Bugsnag.notify(err);
        redirectToLogout();
        toastr.error(err.message);

        throw err;
      });
  }
  /* eslint-disable-next-line*/
  return Promise.reject(dataObject);
};
