import { CHANGE_IMPORT_COUNTER } from "./actionTypes";


const INIT_STATE = {
  current: 0,
  total: 0
};

const Counters = (state = INIT_STATE, action) => {

  switch (action.type) {
    case CHANGE_IMPORT_COUNTER:
      return {
        ...state,
        ...action.payload(state),
      };

    default:
      return state;
  }
};

export default Counters;
