import { MARKETPLACES } from "../../../../../../../base/constants/extension";
import { FORM_TYPES } from "../../../../../const/form";

export const CODE_EBAY_ACCOUNT = "ebayAccount";
export const CODE_ETSY_ACCOUNT = "etsyAccount";
export const CODE_POSHMARK_ACCOUNT = "poshmarkAccountDto";
export const CODE_MERCARI_ACCOUNT = "mercariAccountDto";

export const MARKETPLACES_ACCOUNT_CODES = {
  [MARKETPLACES.EBAY]: CODE_EBAY_ACCOUNT,
  [MARKETPLACES.ETSY]: CODE_ETSY_ACCOUNT,
  [MARKETPLACES.POSHMARK]: CODE_POSHMARK_ACCOUNT,
  [MARKETPLACES.MERKARI]: CODE_MERCARI_ACCOUNT,
};

export const FORM_TYPES_ACCOUNT_CODES = {
  [FORM_TYPES.EBAY]: CODE_EBAY_ACCOUNT,
  [FORM_TYPES.ETSY]: CODE_ETSY_ACCOUNT,
  [FORM_TYPES.POSHMARK]: CODE_POSHMARK_ACCOUNT,
  [FORM_TYPES.MERCARI]: CODE_MERCARI_ACCOUNT,
};

export const MAX_PASSWORD_LENGTH_SETUP = 100;
export const MAX_EMAIL_LENGTH_SETUP = 74;

export const PARSING_ERROR_STATUS = 3;

export const MARKETPLACE_ACCOUNTS = {
  ebay: "ebay-accounts",
  etsy: "etsy-accounts",
  poshmark: "poshmark-accounts",
  mercari: "mercari-accounts",
};
