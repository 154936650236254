export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 50;

export const MIN_FIRSTNAME_LENGTH = 2;
export const MAX_FIRSTNAME_LENGTH = 20;

export const MIN_PHONE_NUMBER_LENGTH = 12;
export const DEV_PHONE_NUMBER_LENGTH = 17;
export const ACCESS_CODE_LENGTH = 6;

export const MIN_TITLE_LENGTH = 1;
export const MAX_TITLE_LENGTH = 200;

export const MAX_REASON_LENGTH = 100;

export const MIN_CONDITION_DESCRIPTION_LENGTH = 1;
export const MAX_CONDITION_DESCRIPTION_LENGTH = 1000;

export const MIN_DESCRIPTION_LENGTH = 1;
export const MAX_DESCRIPTION_LENGTH = 4000;

export const MIN_DESCRIPTION_BASE_LENGTH = 1;
export const MAX_DESCRIPTION_BASE_LENGTH = 4000;

export const MIN_BRAND_LENGTH = 1;
export const MAX_BRAND_LENGTH = 50;

export const MIN_SKU_LENGTH = 1;
export const MAX_SKU_LENGTH = 50;

export const MIN_TAGS_LENGTH = 1;
export const MAX_TAGS_LENGTH = 800;

export const MIN_NOTES_LENGTH = 1;
export const MAX_NOTES_LENGTH = 1000;

export const MIN_CARD_NUMBER_LENGTH = 19;
export const MAX_CARD_NUMBER_LENGTH = 19;

export const MIN_EXPIRY_DATE_LENGTH = 5;

export const MIN_SECURE_CODE_LENGTH = 3;

export const MAX_MESSAGE_LENGTH = 300;
export const MAX_TASK_TITLE_LENGTH = 200;
export const MAX_TASK_DESCRIPTION_LENGTH = 500;

export const MAX_EMAIL_LENGTH = 129;
export const MAX_EMAIL_LOCAL_PART_LENGTH = 64;
export const MAX_EMAIL_DOMAIN_PART_LENGTH = 64;
