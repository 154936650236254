import React from "react";
import { Link } from "react-router-dom";

import { ROUTES_TO_LANDING } from "../constants";

const PrivacyContent = () => {
  const domain = process.env.REACT_APP_LANDING_DOMAIN || "https://dev.fearn.co";

  return (
    <p className="privacy-content">
      By clicking on Sign up you are accepting{" "}
      <Link to={{ pathname: domain + ROUTES_TO_LANDING.terms }} target="_blank">
        Terms and Conditions
      </Link>{" "}
      and{" "}
      <Link
        to={{ pathname: domain + ROUTES_TO_LANDING.privacy }}
        target="_blank"
      >
        Privacy Policy
      </Link>
    </p>
  );
};

export default PrivacyContent;
