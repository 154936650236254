import React from 'react'
import { Redirect } from 'react-router-dom'

import { KEY_USER } from '../../../../base/constants/storage';
import { useService } from '../../../../base/hooks/useService';
import StorageService from '../../../../services/StorageService';
import { APP_GROUP_LINKS } from '../../../app/config';
import { getRedirectByRole } from '../../../auth/hooks/useAfterLogin';

const CompleteRedirect = () => {
	const storage = useService(StorageService);

	const { registrationStatus, role } = storage.get(KEY_USER, {});

	const redirect = getRedirectByRole(role, registrationStatus) || APP_GROUP_LINKS.BASE;

	return <Redirect from='/' to={redirect} exact />
};

export default CompleteRedirect;
