import {useEffect, useMemo} from "react";
import {Col, Row} from "reactstrap";
import FormikSingleSelect from "../../../../../base/components/FormikSingleSelect";
import React from "react";
import {useField} from "formik";
import { useMercaryColor, useMercarySize } from "./hooks";

export const ColorAndSizes = ({colors, sizes, categoryId, categories, loading = false}) => {
	const [{value: colorId}] = useField({name: "colorId"});
	const [,,{setValue: setColorName}] = useField({name: "colorName"});
	const [{value: sizeId}] = useField({name: "sizeId"});
	const [,,{setValue: setSizeName}] = useField({name: "sizeName"});
	const [,,{setValue: setHasSizes}] = useField({name: "hasSizes"});

	const {colorLabel} = useMercaryColor(colors, colorId);
	const {sizeLabel} = useMercarySize(sizes, sizeId);

	useEffect(() => {
		setColorName(colorLabel);
	}, [colorLabel]);

	useEffect(() => {
		setSizeName(sizeLabel);
	}, [sizeLabel]);

	const category = useMemo(() => {
		if (!categoryId) return {};
		return categories.find(({id}) => categoryId === id) || {};
	}, [categories, categoryId]);

	const filteredSizes = useMemo(() => {
		if (!category.itemSizeGroupId) return [];
		return sizes.filter(({itemSizeGroupId}) => itemSizeGroupId === category.itemSizeGroupId)
	}, [sizes, category]);

	const hasSizes = !!filteredSizes.length;

	useEffect(() => {
		setHasSizes(hasSizes);
	}, [hasSizes]);

	return (
		<Row className="mb-4">
			<Col md="6">
				<FormikSingleSelect
					name='colorId'
					label="Color"
					options={colors}
					loading={loading}
					placeholder='Choose color'
				/>
			</Col>
			<Col md="6">
				{
					hasSizes &&
					<FormikSingleSelect
						name='sizeId'
						label="Size*"
						options={filteredSizes}
						placeholder='Choose size'
					/>
				}
			</Col>
		</Row>
	)
};


