import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import styles from "./index.module.scss";
import { ReactComponent as BackButton } from "./images/back-default.inline.svg";

const PageTitle = ({ title, onBackPath = "" }) => {
  const history = useHistory();

  const onBack = useCallback(
    () => history.push(onBackPath),
    [history, onBackPath]
  );

  return (
    <div className={styles.wrapper}>
      {!!onBackPath && (
        <BackButton className={styles.backButton} onClick={onBack} title="" />
      )}
      <h4 className="mb-4 font-weight-semibold font-size-16 text-uppercase">
        {title}
      </h4>
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string,
  onBackPath: PropTypes.string,
};

export default PageTitle;
