export const FORM_TYPES = {
  BASE: "base",
  EBAY: "ebay",
  ETSY: "etsy",
  POSHMARK: "poshmark",
  MERCARI: "mercari",
};

export const FORM_SPECIFIC_FIELDS_TYPES = {
  [FORM_TYPES.EBAY]: "productEbaySpecificFields",
  [FORM_TYPES.ETSY]: "productEtsySpecificFields",
  [FORM_TYPES.POSHMARK]: "productPoshmarkSpecificFields",
  [FORM_TYPES.MERCARI]: "productMercariSpecificFields",
};

export const EXTENSION_MARKETPLACES = [FORM_TYPES.POSHMARK, FORM_TYPES.MERCARI];
