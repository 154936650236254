import { INVENTORY_STATUSES } from "../../inventory/pages/EditInventory/constants";
import { PRODUCT_STATUSES } from "../constants";

export const useProductStatusLabel = () => {
  const getStatusLabel = (fetchedStatus) => {
    if (
      !fetchedStatus ||
      fetchedStatus === INVENTORY_STATUSES.delisted ||
      fetchedStatus === INVENTORY_STATUSES.notListed
    )
      return PRODUCT_STATUSES[INVENTORY_STATUSES.notListed];

    return PRODUCT_STATUSES[fetchedStatus];
  };

  return [getStatusLabel];
};
