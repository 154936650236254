import { getCategoriesTree } from "../pages/drafts/helpers/fetchBasicCategories";
import { initService } from "../base/hooks/useService";
import EbayService from "./EbayService";
import StorageService from "./StorageService";
import { KEY_EBAY_SESSION } from "../base/constants/storage";

class FormResources {
  static prefetchEbayResources() {
    const promises = [];
    /**
     * @type {EbayService}
     */
    const ebayService = initService(EbayService);
    /**
     * @type {StorageService}
     */
    const storage = initService(StorageService);

    promises.push(
      ebayService.session().then(({ data }) => {
        storage.set(KEY_EBAY_SESSION, data);
      })
    );

    return promises;
  }

  static prefetchBasicResources() {
    const promises = [getCategoriesTree()];

    return promises;
  }

  static prefetchFormResources() {
    return [...this.prefetchBasicResources(), ...this.prefetchEbayResources()];
  }

  static waitResources(resources) {
    return Promise.all(resources);
  }
}

export default FormResources;
