import React from "react";
import { Row, Col } from "reactstrap";
import ListTasks from "../../components/ListTasks/ListTasks";
import ListToDo from "../../components/ListToDo/ListToDo";


const DashboardEmployee = () => {
  return (
    <Row>
      <Col>
        <ListTasks />
      </Col>
      <Col>
        <ListToDo />
      </Col>
    </Row>
  );
};

export default DashboardEmployee;
