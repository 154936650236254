/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import Bugsnag from "@bugsnag/js";

import FormikAsyncSelect from "../../../../base/components/FormikAsyncSelect";
import FormikInput from "../../../../base/components/FormikInput";
import FormikSingleSelect from "../../../../base/components/FormikSingleSelect";
import FormikTextarea from "../../../../base/components/FormikTextarea";
import { conditions, productColors } from "../../../../base/constants/draft";
import {
  MAX_LENGTH_SKU,
  MAX_LENGTH_ZIP,
} from "../../../../base/constants/forms";
import { useService } from "../../../../base/hooks/useService";
import BrandsService from "../../../../services/BrandsService";
import { getConfigBrandInitialValue } from "../../helpers/getConfigInitialValue";
import { listToOptions, basicListToOptions } from "../../helpers/listToOptions";
import Divider from "../Divider";
import FormTitleAndDesc from "./partials/FormTitleAndDesc";
import FormPrimaryColor from "./partials/FormPrimaryColor";
import FormTags from "./partials/FormTags";
import FormCategory from "./partials/FormCategory";
import FormPackageDetails from "./partials/FormPackageDetails";
import FormPriceDetails from "./partials/FormPriceDetails";
import { useLoading } from "../../../../base/hooks/useLoading";
import {
  getCategoriesTree,
  getCategoryById,
} from "../../helpers/fetchBasicCategories";
import { useField } from "formik";
import { parseFromHtml } from "../../../../base/helpers/parseFromHtml";
import { matchStrings } from "../../../../base/helpers/matchStrings";

const Category = ({ setFieldValue, categoryId }) => {
  const [waitCategories, { registerPromise: awaitCategories }] = useLoading();
  const [categoryInitialProps, updateCategoryProps] = useState({
    onInitBreadcrumbs: () => [],
    //   initialValue: undefined,
  });
  const [loading, { registerPromise }] = useLoading();

  const category = useMemo(() => {
    return getCategoryById(categoryId);
  }, [categoryId, waitCategories]);

  const getCategoryOptions = useCallback(
    (query, parentId) => {
      return awaitCategories(getCategoriesTree(query, parentId))
        .then(basicListToOptions)
        .then((list) => {
          return list;
        })
        .catch((e) =>
          Bugsnag.notify(e, (event) => {
            event.addMetadata("getCategoryOptionsError", {
              query,
              parentId,
            });
          })
        );
    },
    [awaitCategories]
  );

  useEffect(() => {
    // prefetch
    registerPromise(
      getCategoryOptions()
        .then(() => {
          if (!categoryId) return;

          function findAllParentCategories(id, collection) {
            const category = getCategoryById(id);

            if (!category) return collection;

            return [...(category.prevArr || [])];
          }

          updateCategoryProps({
            onInitBreadcrumbs: () => findAllParentCategories(categoryId, []),
          });
        })
        .catch((e) => Bugsnag.notify(e))
    );
  }, [getCategoryOptions, registerPromise, categoryId]);

  return (
    <>
      <h3 className="bold-black mb-4">Category</h3>
      <Divider />
      <Row className="mb-4">
        <Col md="6">
          <FormCategory
            getCategoryOptions={getCategoryOptions}
            initialValue={category}
            label="Category"
            loading={waitCategories}
            setFieldValue={setFieldValue}
            key={(category?.id || "") + String(loading)}
            {...categoryInitialProps}
          />
        </Col>
      </Row>
    </>
  );
};
export const validateCustomBrandOptions = (foundOptions, brand, query) => {
  if (
    !foundOptions.find(({ name }) => matchStrings(name, brand)) &&
    brand &&
    !query
  ) {
    return [
      {
        label: brand,
        id: brand,
        name: brand,
        isCustom: true,
      },
    ];
  }

  return foundOptions;
};
const DraftBasicFormInner = ({ setFieldValue, values }) => {
  /**
   * @type {BrandsService}
   */
  const brands = useService(BrandsService);
  const [waitBrands, { registerPromise: awaitBrands }] = useLoading();

  const [, , { setValue: setDescriptionValue }] = useField({
    name: "description",
  });

  useEffect(() => {
    if (values.description) {
      setDescriptionValue(parseFromHtml(values.description));
    }
  }, []);

  const getBrandOptions = useCallback(
    (query) => {
      return awaitBrands(brands.search(query || values.brand))
        .then(listToOptions)
        .then((list) => {
          return validateCustomBrandOptions(list, values.brand, query);
        })
        .catch((e) => {
          Bugsnag.notify(e);
        });
    },
    [brands, awaitBrands, values.brand]
  );

  return (
    <>
      <h3 className="bold-black mb-4 mt-4">Item details</h3>
      <Divider />

      <FormTitleAndDesc />

      <Row className="mb-4">
        <Col md="6">
          <FormikAsyncSelect
            initialValue={getConfigBrandInitialValue(values.brand)}
            name="brand"
            label="Brand"
            loading={waitBrands}
            placeholder="Select or write brand"
            onChange={(value) => value.name}
            isCreatable
            onRequestAPI={getBrandOptions}
            getValue={(options) => {
              return options.find(({ label }) => {
                return matchStrings(label, values.brand);
              });
            }}
          />
        </Col>
        <Col md="6">
          <FormikSingleSelect
            name="itemCondition"
            label="Condition"
            placeholder="Choose condition"
            options={conditions}
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md="6">
          <FormPrimaryColor />
        </Col>

        <Col md="6">
          <FormikSingleSelect
            name="secondaryColor"
            label="Secondary color"
            options={productColors}
            placeholder="Choose secondary color"
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md="6">
          <FormikInput
            id="sku"
            name="sku"
            label="SKU"
            containerClassName="mb-3"
            placeholder="Enter SKU number"
            type="text"
            maxLength={MAX_LENGTH_SKU}
          />
        </Col>

        <Col md="6">
          <FormikInput
            id="zip"
            name="zip"
            label="Zip code"
            maxLength={MAX_LENGTH_ZIP}
            containerClassName="mb-3"
            placeholder="Enter 5-digit Zip code"
          />
        </Col>
      </Row>

      <Row className="mb-4">
        <Col md="6">
          <FormTags />
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md="6">
          <FormikTextarea
            id="notes"
            name="notes"
            label="Other info (Private)"
            rows="1"
            placeholder="Enter other info"
          />
        </Col>
      </Row>

      <Category
        setFieldValue={setFieldValue}
        category={values.category}
        categoryId={values.categoryId}
      />

      <FormPackageDetails />

      <FormPriceDetails setFieldValue={setFieldValue} />
    </>
  );
};

DraftBasicFormInner.propTypes = {
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  selectedImages: PropTypes.array,
};

export default DraftBasicFormInner;
