import Http from "./Http";

class BookkeepingService extends Http {
  static $displayName = "BookkeepingService";

  async getBookkeeping(params) {
    return await this.get("/bookkeepings", { params });
  }
}

export default BookkeepingService;
