import yup from "./yup";

import {
  ONLY_LETTERS_PATTERN,
  PASSWORD_PATTERN,
} from "../base/constants/patterns";
import {
  MIN_PASSWORD_LENGTH,
  MAX_PASSWORD_LENGTH,
  MIN_FIRSTNAME_LENGTH,
  MAX_FIRSTNAME_LENGTH,
  MAX_EMAIL_LOCAL_PART_LENGTH,
  MAX_EMAIL_DOMAIN_PART_LENGTH,
  MAX_EMAIL_LENGTH,
} from "./lengthConstants";
import {
  EMAIL_ERROR,
  ONLY_LETTERS_ERROR,
  PASSWORD_PATTERN_ERROR,
} from "../base/constants/forms";

export const USER_FIRSTNAME = yup
  .string()
  .min(MIN_FIRSTNAME_LENGTH)
  .max(MAX_FIRSTNAME_LENGTH)
  .matches(ONLY_LETTERS_PATTERN, ONLY_LETTERS_ERROR)
  .trim();

export const USER_LASTNAME = yup
  .string()
  .min(MIN_FIRSTNAME_LENGTH)
  .max(MAX_FIRSTNAME_LENGTH)
  .matches(ONLY_LETTERS_PATTERN, ONLY_LETTERS_ERROR)
  .trim();

export const USER_EMAIL = yup
  .string()
  .trim()
  .email()
  .test("email-validation", EMAIL_ERROR, (value) => {
    if (!value) return false;

    const [localPart, domainPart] = value.split("@");
    return (
      localPart?.length <= MAX_EMAIL_LOCAL_PART_LENGTH &&
      domainPart?.length <= MAX_EMAIL_DOMAIN_PART_LENGTH
    );
  })
  .max(MAX_EMAIL_LENGTH, EMAIL_ERROR);

export const USER_PASSWORD = yup
  .string()
  .min(MIN_PASSWORD_LENGTH)
  .max(MAX_PASSWORD_LENGTH)
  .matches(PASSWORD_PATTERN, PASSWORD_PATTERN_ERROR)
  .trim();

export const LOGIN_PASSWORD = yup.string().trim();

export const CONFIRM_PASSWORD = yup.string().trim();
