import { useCallback } from "react";
import Bugsnag from "@bugsnag/js";

import { useUpdateProfile } from "../../../../pages/profile/hooks/useProfile";
import SubscriptionService from "../../../../services/SubscriptionService";
import { useLoading } from "../../../hooks/useLoading";
import { useModal } from "../../../hooks/useModal";
import { useService } from "../../../hooks/useService";

export const useCancelSubscription = () => {
  const { open, close, isOpen } = useModal();
  const [loading, { registerPromise }] = useLoading();
  const updateProfile = useUpdateProfile();

  const subscriptionService = useService(SubscriptionService);

  const handleCancelSubscription = useCallback(() => {
    registerPromise(subscriptionService.cancelSubscribe())
      .then(close)
      .then(updateProfile)
      .catch((e) => Bugsnag.notify(e));
  }, [subscriptionService, close, registerPromise, updateProfile]);

  return {
    open,
    close,
    isOpen,
    loading,
    handleCancelSubscription,
  };
};
