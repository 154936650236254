import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Button } from 'reactstrap';
import { Formik, Form } from 'formik';
import classNames from 'classnames';

import EmployeesService from '../../../../services/EmployeesService';
import FormikPassword from '../../../../base/components/FormikPassword';
import AuthHeader from '../../components/Header';
import { useLocationQuery } from '../../../../base/hooks/useQueryString';
import { useService } from '../../../../base/hooks/useService';
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from '../../../../validation/lengthConstants';
import { validationSchema, initialValues } from '../ResetPassword/form';
import { AUTH_GROUP_LINKS } from '../../config';

import styles from './index.module.scss';

const CreatePassword = () => {
  const { push } = useHistory();
  const { params } = useLocationQuery();
  const employeesService = useService(EmployeesService);

  const handleSubmit = useCallback(async ({ password }) => {
    await employeesService.createPassword({ password, token: params.token });

    push(AUTH_GROUP_LINKS.LINK_LOGIN);
  }, [employeesService, params.token, push]);

  return (
    <div className="d-flex flex-column">
      <Card className={classNames("mx-auto overflow-hidden w-100", styles.card)}>
        <AuthHeader
          headerText="Set password"
          description='&nbsp;'
        />
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ dirty, isValid }) => (
            <Form className="px-4 pb-4 mb-2">
              <FormikPassword
                type="password"
                id="password"
                label="Password"
                name="password"
                containerClassName="mb-3"
                placeholder="Enter password"
                min={MIN_PASSWORD_LENGTH}
                max={MAX_PASSWORD_LENGTH}
              />

              <FormikPassword
                type="password"
                id="repeatPassword"
                label="Repeat Password"
                name="repeatPassword"
                containerClassName="mb-3"
                placeholder="Enter repeat password"
                min={MIN_PASSWORD_LENGTH}
                max={MAX_PASSWORD_LENGTH}
              />

              <Button
                className="filled-primary w-100 mt-4"
                color="filled-primary"
                disabled={!dirty || !isValid}
              >
                Save
              </Button>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default CreatePassword;
