import React, {
  useState,
  useRef,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import { Card } from "reactstrap";
import { Formik } from "formik";
import { useHistory } from "react-router";
import Bugsnag from "@bugsnag/js";

import styles from "./index.module.scss";

import FormikInput from "../../../../base/components/FormikInput";
import Avatar from "../../../../base/components/Avatar";
import ImageUploader from "../../../../base/components/ImageUploader";
import CustomButton from "../../../../base/components/CustomButton";

import { useService } from "../../../../base/hooks/useService";
import { useLoading } from "../../../../base/hooks/useLoading";
import ProfileService from "../../../../services/ProfileService";

import { PROFILE_GROUP_LINKS } from "../../config";
import { validationSchema } from "./form";

import { useProfile } from "../../hooks/useProfile";
import S3Service from "../../../../services/S3Service";
import ImageService from "../../../../services/ImageService";
import { fileTypes } from "../../../../base/constants/fileTyles";
import PageMeta from "../../../../base/components/MetaTags";
import {
  MAX_FIRSTNAME_LENGTH,
  MIN_FIRSTNAME_LENGTH,
} from "../../../../validation/lengthConstants";

const EditProfile = () => {
  const [isLoading, { registerPromise }] = useLoading();
  const [{ firstName, lastName, image }, setProfileData] = useProfile();
  const profileData = useMemo(
    () => ({ firstName, lastName }),
    [firstName, lastName]
  );
  const [croppedImage, setCroppedImage] = useState(image?.link);
  const [croppedFile, setCroppedFile] = useState(null);

  const history = useHistory();

  const actions = useRef({
    open: () => {},
    close: () => {},
  });

  const onOpenModal = useCallback(() => actions.current.open(), [actions]);

  const onCropImage = useCallback((image) => {
    setCroppedFile(image);
    const imageBlobUrl = URL.createObjectURL(image);
    setCroppedImage(imageBlobUrl);
    actions.current.close();
  }, []);

  const onCancelEdit = useCallback(() => {
    history.push(PROFILE_GROUP_LINKS.BASE);
  }, []);

  /**
   * @type {ProfileService}
   */
  const profileService = useService(ProfileService);
  /**
   * @type {S3Service}
   */
  const s3 = useService(S3Service);
  /**
   * @type {ImageService}
   */
  const imagesService = useService(ImageService);

  const handleEdit = (values) => {
    croppedFile
      ? registerPromise(
          s3
            .uploadImageFiles([croppedFile], fileTypes.userAvatar)
            .then(([{ file }]) => {
              imagesService.resizeLoadedImages([file.id]);
              return file.id;
            })
            .then((fileId) => {
              return profileService.editProfile({
                ...values,
                imageId: fileId || image?.id,
              });
            })
        )
          .then(({ data }) => setProfileData(data))
          .catch((e) => Bugsnag.notify(e))
      : registerPromise(
          profileService.editProfile({
            ...values,
            imageId: image?.id,
          })
        )
          .then(({ data }) => setProfileData(data))
          .catch((e) => Bugsnag.notify(e));
  };

  const onDeleteAvatar = useCallback(() => {
    registerPromise(profileService.editProfile({ lastName, firstName }))
      .then(({ data }) => {
        setProfileData(data);
      })
      .catch((e) => Bugsnag.notify(e));
  }, [setProfileData]);

  useEffect(() => {
    setCroppedImage(image?.link);
  }, [image?.link]);

  return (
    <div className={styles.wrapper}>
      <PageMeta title="Profile" />
      <Card className="p-2">
        <Formik
          initialValues={profileData}
          validationSchema={validationSchema}
          onSubmit={handleEdit}
        >
          {({ errors, handleSubmit }) => (
            <form className="form-horizontal p-2" onSubmit={handleSubmit}>
              <div className={styles.editAvatarWrapper}>
                <Avatar
                  image={croppedImage || image?.link}
                  wrapperClassName={styles.avatarWrapper}
                />
                <div className={styles.changeAvatarButtonsWrapper}>
                  <CustomButton className="filled-sm" onClick={onOpenModal}>
                    Change photo
                  </CustomButton>
                  <CustomButton
                    className="outline-primary-sm"
                    onClick={onDeleteAvatar}
                    disabled={
                      !image ||
                      !croppedImage ||
                      Object.keys(errors).length ||
                      isLoading
                    }
                  >
                    Delete
                  </CustomButton>
                </div>
              </div>
              <ImageUploader
                onCropImage={onCropImage}
                onMount={({ onOpen, onClose }) => {
                  actions.current.open = onOpen;
                  actions.current.close = onClose;
                }}
              />
              <FormikInput
                id="firstName"
                label="First name"
                name="firstName"
                containerClassName={styles.inputWrapper}
                type="text"
                placeholder="Enter first name"
                min={MIN_FIRSTNAME_LENGTH}
                max={MAX_FIRSTNAME_LENGTH}
              />
              <FormikInput
                id="lastName"
                label="Last name"
                name="lastName"
                containerClassName={styles.inputWrapper}
                type="text"
                placeholder="Enter last name"
                min={MIN_FIRSTNAME_LENGTH}
                max={MAX_FIRSTNAME_LENGTH}
              />
              <div className={styles.formButtonWrapper}>
                <CustomButton
                  className="outline-primary"
                  type="button"
                  onClick={onCancelEdit}
                >
                  Cancel
                </CustomButton>
                <CustomButton
                  className="filled-primary "
                  type="submit"
                  disabled={Object.keys(errors).length || isLoading}
                >
                  Save
                </CustomButton>
              </div>
            </form>
          )}
        </Formik>
      </Card>
    </div>
  );
};

export default EditProfile;
