import React, { useCallback, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import PageMeta from "../../../../base/components/MetaTags";

import Table from "../../../../base/components/Table";
import { PRODUCT_TYPE } from "../../../../base/constants/product";
import { replaceURLParams } from "../../../../base/helpers/replaceURLParams";
import {
  useStatefullProvider,
  useStatelessProvider,
} from "../../../../base/hooks/useProvider";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useTable } from "../../../../base/hooks/useTable";
import { DRAFTS_GROUP_LINKS } from "../../../drafts/config";
import { TableContext } from "../../../drafts/contexts/table";
import { useActivateSold } from "../../../drafts/hooks/useActivateSold";
import { useDeleteDraft } from "../../../drafts/hooks/useDeleteDraft";
import { useTotalProducts } from "../../../drafts/hooks/useProduct";
import { useReturnProduct } from "../../../drafts/hooks/useReturnProduct";
import { useSold } from "../../../drafts/hooks/useSold";
import { SUBSCRIPTIONS_STATUSES } from "../../../drafts/pages/Settings/page/EmployeeManagement/constants";
import {
  SOLD_INVENTORY_LABEL,
  MAX_INVENTORY_COUNT,
} from "../../../inventory/constants";
import PageTitle from "../../../profile/components/PageName";
import { useProfile } from "../../../profile/hooks/useProfile";
import { SOLD_GROUP_LINKS } from "../../config";

import TableHeader from "./components/TableHeader";
import styles from "./index.module.scss";
import { TableStructure } from "./table";
import { useMarkToReload } from "../../../../base/hooks/useMarkToReload";
import { SOLD_ITEMS_MARK_TO_RELOAD } from "../../../../base/constants/markToReload";
import { SOLD_PREV_PATH } from "../../../drafts/constants";
import { usePrevLocation } from "../../../../base/hooks/usePrevLocation";
import { useCopySold } from "../../hooks/useCopySold";
import emptySoldIcon from "../../images/sold-empty.png";
import EmptyList from "../../../drafts/components/EmptyList";
import UpdateButton from "./components/UpdateButton";
import WhiteBox from "../../../drafts/components/WhiteBox";

const LIMIT = 10;

const reverseOrderMap = { statusAt: true };

const popupConfig = {
  title: "Delete item",
  message:
    "Are you sure you want to delete the Sold item? All information will be deleted from Analytics and Bookkeeping.",
  btnAccept: "Yes",
  btnClose: "No",
};

const reactivePopupConfig = {
  title: "Activate Sold Listing",
  message:
    "Do you want to activate this item? it will move to Draft and may affect Analytics and/or Bookkeeping.",
  btnAccept: "Activate",
  btnClose: "Cancel",
};

const SoldList = ({ load, total }) => {
  const [
    {
      activeSubscription: { type },
    },
  ] = useProfile();

  const [{ total: totalDrafts }] = useTotalProducts(PRODUCT_TYPE.DRAFT);

  const isDisabled = useMemo(() => {
    return {
      [SUBSCRIPTIONS_STATUSES.trial]: true,
      [SUBSCRIPTIONS_STATUSES.standard]:
        totalDrafts < MAX_INVENTORY_COUNT.standard,
      [SUBSCRIPTIONS_STATUSES.business]:
        totalDrafts < MAX_INVENTORY_COUNT.business,
      [SUBSCRIPTIONS_STATUSES.unlimited]: true,
      [SUBSCRIPTIONS_STATUSES.expired]: true,
      [SUBSCRIPTIONS_STATUSES.beta]: true,
    };
  }, [totalDrafts]);

  const history = useHistory();
  const { params, merge } = useLocationQuery();
  const [{ active: totalSoldInventory }] = useStatelessProvider(TableContext);

  const { marker } = useMarkToReload(SOLD_ITEMS_MARK_TO_RELOAD);

  const getCollection = useSold(LIMIT, marker);

  const [loading, pagination, list, handleTableChange, { refresh }] = useTable({
    ...params,
    searchQueryParam: "query",
    sortTypeQueryParam: "orderType",
    sortByQueryParam: "orderBy",
    reverseOrderMap,
    getNewDataService: getCollection,
    limit: LIMIT,
  });

  const [, updateTableContext] = useStatefullProvider(TableContext);

  const { offset } = params;

  const [copySoldModal, onCopySold] = useCopySold(
    useCallback(
      (id) => {
        history.push(DRAFTS_GROUP_LINKS.VIEW_DRAFT.replace(":draftId", id));
      },
      [refresh, list.length, merge, offset, updateTableContext, history]
    )
  );

  const { view: deleteModal, onDelete } = useDeleteDraft(
    useCallback(() => {
      const noData = !(list.length - 1);

      updateTableContext((data) => ({
        ...data,
        active: data.active - 1,
      }));

      if (noData && offset) {
        merge("offset", offset - LIMIT);
        return;
      }

      refresh();
    }, [refresh, list.length, merge, offset, updateTableContext]),
    popupConfig
  );

  const { view: reactivateModal, onActivate: onReactivate } = useActivateSold(
    useCallback(
      (id) => {
        const noData = !(list.length - 1);

        updateTableContext((data) => ({
          ...data,
          active: data.active - 1,
        }));

        if (noData && offset) {
          merge("offset", offset - LIMIT);
          return;
        }

        refresh();

        history.push(DRAFTS_GROUP_LINKS.VIEW_DRAFT.replace(":draftId", id));
      },
      [refresh, list.length, merge, offset, updateTableContext, history]
    ),
    reactivePopupConfig
  );

  const { view: returnProductModal, onReturnProduct } = useReturnProduct(
    useCallback(() => {
      const noData = !(list.length - 1);

      updateTableContext((data) => ({
        ...data,
        active: data.active - 1,
      }));

      if (noData && offset) {
        merge("offset", offset - LIMIT);
        return;
      }

      refresh();
    }, [refresh, list.length, merge, offset, updateTableContext])
  );

  useEffect(() => {
    updateTableContext({
      onCopySold,
      onDelete,
      onReactivate,
      onReturnProduct,
      active: total,
      disableActivate: !isDisabled[type],
    });
  }, [
    onCopySold,
    updateTableContext,
    onDelete,
    onReactivate,
    onReturnProduct,
    total,
    isDisabled,
    type,
  ]);

  const textNotFoundData = useMemo(() => {
    return !list?.length && params?.endDate?.length
      ? "No sold items for chosen period"
      : 'To add sales, either click "Update" or import sales from a selected marketplace.';
  }, [params?.endDate, list]);

  const { saveLocation } = usePrevLocation(SOLD_PREV_PATH);

  return (
    <>
      <PageMeta title="Sold inventory" />
      <PageTitle
        title={`SOLD INVENTORY ${
          pagination?.totalCount ? `(${pagination.totalCount})` : ""
        }`}
      />
      {load ? (
        <WhiteBox>
          <p>Loading...</p>
        </WhiteBox>
      ) : !totalSoldInventory && list.length === 0 ? (
        <EmptyList
          imgSrc={emptySoldIcon}
          UpdateButton={UpdateButton}
          importButton={true}
          updateButtonClassName={"ml-3"}
          inventoryStatusLabel={SOLD_INVENTORY_LABEL}
        >
          {textNotFoundData}
        </EmptyList>
      ) : (
        <div className={styles.tableWrapper + " drafts-page"}>
          <Table
            data={list}
            dataStructure={TableStructure}
            loading={loading}
            HeaderComponent={TableHeader}
            limit={LIMIT}
            searchField={"query"}
            offset={params.offset || 0}
            totalCount={pagination?.totalCount}
            handleTableChange={handleTableChange}
            onRowClick={({ id }) => {
              saveLocation();
              history.push(
                replaceURLParams(SOLD_GROUP_LINKS.DETAILS, {
                  draftId: id,
                })
              );
            }}
          />
        </div>
      )}
      {copySoldModal}
      {reactivateModal}
      {deleteModal}
      {returnProductModal}
    </>
  );
};

export default () => {
  const [{ total, load }] = useTotalProducts(PRODUCT_TYPE.SOLD);

  const providedData = useMemo(() => {
    return {
      onCopySold: () => {},
      onDelete: () => {},
      onReactivate: () => {},
      onReturnProduct: () => {},
      active: total,
      disableActivate: undefined,
    };
  }, [total]);

  return (
    <TableContext.Provider value={providedData}>
      <SoldList load={load} total={total} />
    </TableContext.Provider>
  );
};
