export const MAX_ETSY_TITLE_LENGTH = 140;
export const MAX_ETSY_MATERIALS_ELEMENTS = 13;

export const ETSY_RENEWAL_OPTION_AUTOMATIC = 1;
export const ETSY_RENEWAL_OPTION_MANUAL = 2;

export const ETSY_WHO_MADE_IT_OPTIONS = [
  { "id": 1, "label": "I did" },
  { "id": 2, "label": "A member of my shop" },
  { "id": 3, "label": "Another company or person" }
];

export const ETSY_BRAND_NAME = {
  1: "Handmade",
  3: "Vintage"
};

export const ETSY_WHAT_IS_IT_OPTIONS = [
  { "id": 1, "label": "Finished product" },
  { "id": 2, "label": "Supply or tool to make things" }
];

export const ETSY_WHEN_WAS_IT_MADE_OPTIONS = [
  { "id": 1, "label": "Made To Order" },
  {
    "id": 2,
    "label": "After 2020"
  },
  { "id": 3, "label": "After 2010" },
  { "id": 4, "label": "After 2001" },
  { "id": 5, "label": "Before 2002" },
  {
    "id": 6,
    "label": "TwoThousand"
  },
  { "id": 7, "label": "After 1990" },
  { "id": 8, "label": "After 1980" },
  { "id": 9, "label": "After 1970" },
  {
    "id": 10,
    "label": "After 1960"
  },
  { "id": 11, "label": "After 1950" },
  { "id": 12, "label": "After 1940" },
  { "id": 13, "label": "After 1930" },
  {
    "id": 14,
    "label": "After 1920"
  },
  { "id": 15, "label": "After 1910" },
  { "id": 16, "label": "After 1900" },
  { "id": 17, "label": "After 1800" },
  {
    "id": 18,
    "label": "After 1700"
  },
  { "id": 19, "label": "Before 1700" }
];


export const ETSY_COLORS = {
  BEIGE: 1,
  BLUE: 2,
  BLACK: 3,
  BRONZE: 4,
  BROWN: 5,
  CLEAR: 6,
  COPPER: 7,
  GOLD: 8,
  GREY: 9,
  GREEN: 10,
  ORANGE: 11,
  PINK: 12,
  PURPLE: 13,
  RAINBOW: 14,
  RED: 15,
  ROSE_GOLD: 16,
  SILVER: 17,
  WHITE: 18,
  YELLOW: 19

};

export const ETSY_PRIMARY_COLORS = [
  { "id": ETSY_COLORS.BEIGE, "label": "Beige" },
  { "id": ETSY_COLORS.BLUE, "label": "Blue" },
  { "id": ETSY_COLORS.BLACK, "label": "Black" },
  { "id": ETSY_COLORS.BRONZE, "label": "Bronze" },
  { "id": ETSY_COLORS.BROWN, "label": "Brown" },
  { "id": ETSY_COLORS.CLEAR, "label": "Clear" },
  { "id": ETSY_COLORS.COPPER, "label": "Copper" },
  { "id": ETSY_COLORS.GOLD, "label": "Gold" },
  { "id": ETSY_COLORS.GREY, "label": "Grey" },
  { "id": ETSY_COLORS.GREEN, "label": "Green" },
  { "id": ETSY_COLORS.ORANGE, "label": "Orange" },
  { "id": ETSY_COLORS.PINK, "label": "Pink" },
  { "id": ETSY_COLORS.PURPLE, "label": "Purple" },
  { "id": ETSY_COLORS.RAINBOW, "label": "Rainbow" },
  { "id": ETSY_COLORS.RED, "label": "Red" },
  { "id": ETSY_COLORS.ROSE_GOLD, "label": "RoseGold" },
  { "id": ETSY_COLORS.SILVER, "label": "Silver" },
  { "id": ETSY_COLORS.WHITE, "label": "White" },
  { "id": ETSY_COLORS.YELLOW, "label": "Yellow" }
];
