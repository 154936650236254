import React from 'react'
import PropTypes from "prop-types";
import classNames from 'classnames';

const CustomAlert = ({ className, children }) => {
  return (
    <div 
      className={classNames('default-secondary-alert', className)}
    >
      <p className='ui-regular-black mb-0'>
        {children}
      </p>
    </div>
  )
};

CustomAlert.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
};

export default CustomAlert;