import React from "react";

import InfoContainer from "../components/InfoContainer";
import InfoTitle from "../components/InfoTitle";
import TextList from "../components/TextList";

import { termPolicyData } from "../data/termPolicyMok";

const TermPolicy = () => {
  return (
    <InfoContainer metaText="Terms and Conditions">
      <InfoTitle>Terms and Conditions</InfoTitle>
      {termPolicyData.map(({ title, texts }) => (
        <TextList key={title} title={title} texts={texts} />
      ))}
    </InfoContainer>
  );
};

export default TermPolicy;
