import React, { useEffect, useRef, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle, Tooltip } from "reactstrap";
import classNames from "classnames";

import {
  AddIcon,
  CheckIcon,
  DropdownItemContainer,
  FilterContainer,
  LinkIcon,
} from "./index.styles";
import Icon from "../../../../base/components/Icon.jsx";
import { useDropdownFilter } from "../../../drafts/contexts/inventory-dropdown";
import { phrases } from "../../../../store/phrases";
import { useTooltip } from "../../../../base/hooks/useTooltip";

export const InventoryFilter = () => {
  const [open, setOpen] = useState<boolean>(false);
  const {
    selectedValues,
    handleClick,
    isMergeDisplayed,
    options,
    setIsMergeDisplayed,
  } = useDropdownFilter();
  const tooltipRef = useRef(null);
  const tooltipProps = useTooltip(tooltipRef);

  return (
    <FilterContainer>
      <span>Selection</span>
      <Dropdown isOpen={open} toggle={() => setOpen(!open)} menuRole="menu">
        <DropdownToggle className="filter-dropdown-wrapper" tag="button">
          {selectedValues.join(", ")}
          <Icon icon={"bottomArrow"} className="filter-dropdown-icon" />
        </DropdownToggle>
        <DropdownMenu persist className="filter-dropdown-menu__menu to-right">
          {options.map((option) => (
            <DropdownItemContainer
              className="filter-dropdown-item-wrapper"
              onClick={() => handleClick(option)}
              key={option}
            >
              <div
                className={classNames(
                  selectedValues.indexOf(option) !== -1 && "fw-bold"
                )}
              >
                {option}
              </div>

              {selectedValues.indexOf(option) !== -1 ? (
                <CheckIcon icon="checkIcon" />
              ) : (
                <AddIcon>+</AddIcon>
              )}
            </DropdownItemContainer>
          ))}
        </DropdownMenu>
      </Dropdown>
      {selectedValues.length > 0 && !selectedValues.includes("All") && (
        <>
          <div ref={tooltipRef}>
            <LinkIcon
              icon={"linkIcon"}
              disabled={!isMergeDisplayed}
              onClick={() => setIsMergeDisplayed(!isMergeDisplayed)}
              ref={tooltipRef}
            />
          </div>
          <Tooltip placement="top" {...tooltipProps}>
            {phrases.toggleMergeVisibility}
          </Tooltip>
        </>
      )}
    </FilterContainer>
  );
};
