import React from "react";
import PropTypes from "prop-types";

import CustomButton from "../../../../base/components/CustomButton";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../base/components/CustomModal";

const ConfirmCloseModal = ({ isOpen, onClose, onConfirm }) => {
  return (
    <CustomModal isOpen={isOpen}>
      <ModalHeader onClose={onClose} isEmpty></ModalHeader>
      <ModalBody>Do you want to exit without saving changes?</ModalBody>
      <ModalFooter>
        <CustomButton onClick={onClose} className="outline-primary ">
          No
        </CustomButton>
        <CustomButton onClick={onConfirm} className="filled-primary ">
          Yes
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );
};

ConfirmCloseModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
};

export default ConfirmCloseModal;
