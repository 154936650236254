import Http from "./Http";

class ShippingService extends Http {
  static $displayName = "ShippingService";
  baseUrl = '/products/shipping-tasks';

  getList(params) {
    return this.get(this.baseUrl, { params });
  }

  changeShippingStatusById(id, model) {
    return this.patch(`${this.baseUrl}/${id}`, model);
  }
}

export default ShippingService;
