import React, { useMemo } from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

import BookkeepingTable from "./components/BookkeepingTable";

import { useBookkeepingTable } from "./hooks/useBookkeepingTable";
import { ACCOUNTING_METHODS } from "../../constants";

const BookkeepingTables = ({ bookkeepingData, settings }) => {
  const isViewTotalsOnly = useMemo(
    () => !bookkeepingData?.bookkeepingsByIntervals?.length,
    [bookkeepingData]
  );

  const [mappedData] = useBookkeepingTable({
    bookkeepingData,
    isViewTotalsOnly,
    isCash: settings.accountingMethod === ACCOUNTING_METHODS.cash,
  });

  return (
    <div className={styles.tablesWrapper}>
      {mappedData?.map((table, index) => (
        <BookkeepingTable
          table={table}
          isViewTotalsOnly={isViewTotalsOnly}
          key={index}
        />
      ))}
    </div>
  );
};

BookkeepingTables.propTypes = {
  bookkeepingData: PropTypes.object,
  settings: PropTypes.object,
};

export default BookkeepingTables;
