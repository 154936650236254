import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import VerticalPageWrapper from "../../base/components/VerticalPageWrapper";

import BookkeepingList from "./pages/BookkeepingList";

import { BOOKKEEPING_GROUP_LINKS } from "./config";

const BookkeepingPage = () => {
  return (
    <VerticalPageWrapper
      title="Bookkeeping"
      subTitle="Income statement"
      titleMeta="Bookkeeping"
      hasButton={false}
    >
      <Switch>
        <Route path={BOOKKEEPING_GROUP_LINKS.BASE} exact>
          <BookkeepingList />
        </Route>
        <Redirect to={BOOKKEEPING_GROUP_LINKS.BASE} />
      </Switch>
    </VerticalPageWrapper>
  );
};

export default BookkeepingPage;
