/* eslint-disable react/prop-types */
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { useField } from "formik";

import CustomInput from "./Input";
import { useCustomError } from "../contexts/CustomErrorMessage";
import ErrorMessageDefault from "./ErrorMessageDefault";
import FieldDescription from "./FieldDescription";
import { useTranslate } from "../hooks/useTranslate";
import { useTriggerFormChanges } from "../../pages/drafts/hooks/useDetectDirtyForm";

const FormikInput = ({
  placeholder,
  name,
  type,
  maxLength,
  maxLengthForCounter,
  readOnly,
  label,
  bottomText = "",
  min = 0,
  disabled,
  containerClassName,
  onChange = () => {},
  ErrorMessage = ErrorMessageDefault,
  step,
  mask,
  isShowLengthDescription = false,
  maxLengthDescription,
  isHideLabel = false,
  pipeValue = (data) => data,
  description = "",
  autoFocus = false,
}) => {
  const [field, meta] = useField({ name, type, placeholder });
  const isError = meta.error && meta.touched;
  const [translate] = useTranslate();

  const { getError } = useCustomError();
  const errorMessage = getError(name);

  const isWithError = isError || errorMessage;

  const { markChanges, containerRef } = useTriggerFormChanges();

  const handleOnChange = (event) => {
    field.onChange(event);
    onChange(event);
    markChanges();
  };

  return (
    <section
      className={classnames(containerClassName, "custom-form-group")}
      ref={containerRef}
    >
      {label && !isHideLabel && (
        <label className={isWithError ? "text-danger" : ""}>{label}</label>
      )}
      <CustomInput
        {...field}
        value={pipeValue(field.value)}
        onChange={handleOnChange}
        type={type}
        className={classnames({
          "is-invalid-input is-invalid": isWithError,
        })}
        maxLength={maxLength}
        readOnly={readOnly}
        disabled={disabled}
        placeholder={placeholder}
        step={step}
        mask={mask}
        autoFocus={autoFocus}
      />
      <div className="d-flex align-items-center">
        {bottomText && (
          <span className="font-size-10 text-gray-gomi mb-1 mr-3">
            {bottomText}
          </span>
        )}
        {isShowLengthDescription && (
          <FieldDescription
            isWithError={isWithError}
            valueLength={field?.value?.length}
            description={description}
            maxLength={maxLengthDescription || maxLengthForCounter}
          />
        )}
      </div>

      <ErrorMessage name={name}>
        {translate(meta.error, {
          label,
          min,
          max: maxLengthForCounter ? maxLengthForCounter : maxLength,
        })}
      </ErrorMessage>
    </section>
  );
};

FormikInput.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  bottomText: PropTypes.string,
  containerClassName: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  ErrorMessage: PropTypes.any,
  disabled: PropTypes.bool,
  step: PropTypes.string,
  isShowLengthDescription: PropTypes.bool,
  maxLengthDescription: PropTypes.number,
  isHideLabel: PropTypes.bool,
  description: PropTypes.string,
  mask: PropTypes.string,
};

export default FormikInput;
