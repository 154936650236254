/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import { useService } from "../../../../../base/hooks/useService";
import EtsyService from "../../../../../services/EtsyService";
import { Col } from "reactstrap";
import FormikSingleSelect from "../../../../../base/components/FormikSingleSelect";
import DraftCaption from "../../DraftCaptionText";
import React from "react";
import { useLoading } from "../../../../../base/hooks/useLoading";
import { HOW_CREATE_SHIPPING_TEMPLATE } from "../../../const/links";

const ShippingTemplate = ({ marketplaceData }) => {
  const [templates, updateTemplates] = useState([]);
  const [loading, { registerPromise }] = useLoading();
  const noTemplates = !loading && !templates.length;

  /**
   *
   * @type {EtsyService}
   */
  const etsy = useService(EtsyService);

  useEffect(() => {
    registerPromise(
      etsy.getTemplates().then(({ data }) => {
        updateTemplates(
          data.map(({ title: label, shippingTemplateId: id }) => {
            return {
              label,
              id,
            };
          })
        );
      })
    );
  }, [etsy, registerPromise, marketplaceData]);

  return (
    <>
      <Col md="6">
        <FormikSingleSelect
          name="shippingTemplateId"
          label="Shipping template"
          placeholder="Shipping template"
          options={templates}
          customError={
            noTemplates
              ? "Your connected account does not have any flat-rate shipping profiles"
              : ""
          }
          isHideLabel
        />
      </Col>
      <DraftCaption>
        Please create at least one flat-rate shipping profile on Etsy.com and
        reconnect your account with Fearn. Calculated shipping are currently not
        supported with Etsy’s api.{" "}
        <a
          className={"green"}
          href={HOW_CREATE_SHIPPING_TEMPLATE}
          target={"_blank"}
          rel={"noreferrer"}
        >
          How to create a shipping template.
        </a>
      </DraftCaption>
    </>
  );
};

export default ShippingTemplate;
