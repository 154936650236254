import React from "react";
import styles from "../index.module.scss"
import { separateThousandthFloored } from "../../../helpers/separateThousandth";

export const FormatPrice = (price) => {
  return <div className={styles.tableText}>
    {separateThousandthFloored(price)}
  </div>;
};

export const FormatAvgDaysTillSold = (price) => {
  return <div className={styles.tableText}>{Math.round(price)}</div>;
};

export const FormatTitle = (name, data, idx) => {
  return (
    <div className={styles.inventoryTitleContainer}>
      <div className={styles.inventoryTitleIndex}>{idx+1}</div>
      <div className={styles.inventoryTitleName}>{data.name}</div>
    </div>
  );
};
