import React from "react";
import { Container, Row, Col } from "reactstrap";

import error from "../../../assets/images/error-img.png";
import { ENV } from "../../constants/env";
import { useService } from "../../hooks/useService";
import StorageService from "../../../services/StorageService";
import { KEY_USER } from "../../constants/storage";
import { DASHBOARD_GROUP_LINKS } from "../../../pages/dashboard/config";
import PageMeta from "../../components/MetaTags";

const Page404 = () => {
  const storage = useService(StorageService);

  const userStorage = storage.get(KEY_USER);

  const getLink = userStorage 
    ? DASHBOARD_GROUP_LINKS.BASE  
    : ENV.LANDING_APP_DOMAIN;

  return (
    <div className="account-pages">
      <PageMeta title='404' />
      <Container>
        <Row>
          <Col lg="12">
            <div className="text-center mb-5">
              <h1 className="display-2 font-weight-medium">
                4<i className="bx bx-buoy bx-spin text-primary display-3" />4
              </h1>
              <h4>Oops…</h4>
              <p className='regular-black'>Seems we can not find the page you’re looking for</p>
              <div className="mt-5 text-center">
              <a 
                href={getLink} 
                className='btn btn-primary waves-effect waves-light'
              >
                Go home
              </a>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8" xl="6">
            <div>
              <img src={error} alt="" className="img-fluid" />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Page404;
