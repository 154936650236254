import React, { useState, useCallback, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Bugsnag from "@bugsnag/js";

import VerticalPageWrapper from "../../base/components/VerticalPageWrapper";
import { useLoading } from "../../base/hooks/useLoading";
import { useService } from "../../base/hooks/useService";
import ExpensesService from "../../services/ExpensesService";
import MileagesService from "../../services/MileageService";

import ExpensesList from "./components/ExpensesList";
import MileagesList from "./components/MileagesList";
import { EXPENSES_GROUP_LINKS } from "./config";
import {
  EXPENSES_TAB,
  EXPENSES_TABS,
  MILEAGES_TAB,
} from "./constants/expensesTabs";

const Expenses = () => {
  const [tabs, updateTabs] = useState(EXPENSES_TABS);
  const [_, { registerPromise }] = useLoading();

  const getCountForTab = useCallback((totalCounterObj) => {
    if (totalCounterObj) {
      const objectKey = Object.keys(totalCounterObj)[0];

      updateTabs((prevState) => {
        prevState[objectKey].count = totalCounterObj[objectKey];

        return [...prevState];
      });
    }
  }, []);

  /**
   * @type {ExpensesService}
   */
  const expensesService = useService(ExpensesService);

  const getExpenseCount = useCallback(() => {
    registerPromise(expensesService.getListExpenses())
      .then(({ pagination }) => {
        getCountForTab({ [EXPENSES_TAB]: pagination?.totalCount });
      })
      .catch((e) => Bugsnag.notify(e));
  }, [registerPromise, expensesService, getCountForTab]);

  /**
   * @type {ExpensesService}
   */
  const mileagesService = useService(MileagesService);

  const getMileageCount = useCallback(() => {
    registerPromise(mileagesService.getMileageList())
      .then(({ pagination }) => {
        getCountForTab({ [MILEAGES_TAB]: pagination?.totalCount });
      })
      .catch((e) => Bugsnag.notify(e));
  }, [registerPromise, mileagesService, getCountForTab]);

  useEffect(() => {
    getExpenseCount();
    getMileageCount();
  }, []);

  return (
    <VerticalPageWrapper
      title="Expenses"
      titleMeta="Expenses"
      tabs={tabs}
      hasButton={false}
    >
      <Switch>
        <Route path={EXPENSES_GROUP_LINKS.EXPENSES} exact>
          <ExpensesList getCount={getCountForTab} />
        </Route>
        <Route path={EXPENSES_GROUP_LINKS.MILEAGES} exact>
          <MileagesList getCount={getCountForTab} />
        </Route>
        <Redirect to={EXPENSES_GROUP_LINKS.EXPENSES} />
      </Switch>
    </VerticalPageWrapper>
  );
};

export default Expenses;
