import * as yup from "yup";
import {
  MIN_DESCRIPTION_LENGTH,
  MIN_TITLE_LENGTH,
} from "../../../../validation/lengthConstants";
import {
  COST_ERROR,
  MAX_VALIDATION_NUMBER,
  MIN_PRICE,
  MIN_ORIGINAL_PRICE,
  MIN_VALIDATION_NUMBER,
  PRISE_ERROR,
  TITLE_ERROR,
  POSHMARK_DESCRIPTION_ERROR,
} from "../../../../base/constants/forms";
import {
  BRAND_ERROR,
  CATEGORY_ERROR,
  MAX_DESCRIPTION_POSHMARK_LENGTH,
  MAX_OTHER_INFO_LENGTH,
  MAX_TITLE_POSHMARK_LENGTH,
  NEW_WITH_TAGS_ERROR,
  OTHER_ERROR,
  SIZE_ERROR,
} from "../../const/poshmark";
import { DRAFT_SKU, LISTING_URL } from "../../../../validation/draft";
import {} from "../../../../base/constants/draft";

const maxCountTags = 3;
const minCountFiles = 1;

export const DRAFT_POSHMARK_TITLE = yup
  .string()
  .min(MIN_TITLE_LENGTH, TITLE_ERROR)
  .max(MAX_TITLE_POSHMARK_LENGTH)
  .trim();

export const DRAFT_POSHMARK_DESCRIPTION = yup
  .string()
  .min(MIN_DESCRIPTION_LENGTH, POSHMARK_DESCRIPTION_ERROR)
  .max(MAX_DESCRIPTION_POSHMARK_LENGTH, POSHMARK_DESCRIPTION_ERROR)
  .trim();

export const DRAFT_POSHMARK_PRICE = yup
  .number()
  .nullable(true)
  .min(MIN_PRICE, PRISE_ERROR)
  .max(MAX_VALIDATION_NUMBER, PRISE_ERROR);

export const DRAFT_POSHMARK_ORIGINAL_PRICE = yup
  .number()
  .nullable(true)
  .min(MIN_ORIGINAL_PRICE, PRISE_ERROR)
  .max(MAX_VALIDATION_NUMBER, PRISE_ERROR);

export const DRAFT_POSHMARK_OTHER = yup
  .string()
  .min(MIN_TITLE_LENGTH, OTHER_ERROR)
  .max(MAX_OTHER_INFO_LENGTH)
  .trim();

export const DRAFT_POSHMARK_COST = yup
  .number()
  .nullable(true)
  .min(MIN_VALIDATION_NUMBER, COST_ERROR)
  .max(MAX_VALIDATION_NUMBER, COST_ERROR);

const DRAFT_POSHMARK_CATEGORIES = yup.string();
const DRAFT_POSHMARK_SUB_CATEGORIES = yup.string();
// const DRAFT_POSHMARK_ADD_SUB_CATEGORIES = yup.string();
const DRAFT_POSHMARK_COLORS = yup.string().nullable(true);
const DRAFT_POSHMARK_SHIPPING_DISCOUNT = yup.string();

export const validationSchema = yup.object().shape({
  title: DRAFT_POSHMARK_TITLE.required(),
  description: DRAFT_POSHMARK_DESCRIPTION.required(),
  department: DRAFT_POSHMARK_CATEGORIES.required(),
  category: DRAFT_POSHMARK_SUB_CATEGORIES.when("department", {
    is: (department) => !!department,
    then: DRAFT_POSHMARK_SUB_CATEGORIES.required(),
  }),
  // category_features: DRAFT_POSHMARK_ADD_SUB_CATEGORIES.when("category", {
  //   is: (category) => !!category,
  //   then: DRAFT_POSHMARK_SUB_CATEGORIES.required(),
  // }),
  sizeId: yup.string().when("department", {
    is: (department) => !!department,
    then: yup.string().required(),
  }),
  isTagsAttached: yup.bool().nullable(true),
  isAddToBoutique: yup.bool().nullable(true),
  brandId: yup.string().required(),
  brandName: yup.string(),
  primaryColor: DRAFT_POSHMARK_COLORS,
  secondaryColor: DRAFT_POSHMARK_COLORS,
  tags: yup.array().max(maxCountTags),
  originalPrice: DRAFT_POSHMARK_ORIGINAL_PRICE.required(),
  listingPrice: DRAFT_POSHMARK_PRICE.required(),
  discountedShipping: DRAFT_POSHMARK_SHIPPING_DISCOUNT,
  earning: yup.string(),
  sku: DRAFT_SKU,
  // costPrice: DRAFT_POSHMARK_COST,
  otherInfo: DRAFT_POSHMARK_OTHER,
  files: yup.array().min(minCountFiles),
  link: LISTING_URL,
});

export const highlightDraftFields = {
  title: TITLE_ERROR,
  description: POSHMARK_DESCRIPTION_ERROR,
  department: CATEGORY_ERROR,
  sizeId: SIZE_ERROR,
  isTagsAttached: NEW_WITH_TAGS_ERROR,
  brandId: BRAND_ERROR,
  listingPrice: PRISE_ERROR,
  originalPrice: PRISE_ERROR,
};

export const initialValues = {
  files: [],
  title: "",
  description: "",
  categoryId: "",
  department: "",
  category: "",
  category_features: "",
  categoryName: "",
  categoryShortName: "",
  sizeId: "",
  sizeName: "",
  isTagsAttached: null,
  isAddToBoutique: null,
  brandId: "",
  brandName: "",
  primaryColor: "",
  colorName: "",
  secondaryColor: "",
  tags: "",
  originalPrice: "",
  listingPrice: "",
  discountedShipping: "",
  earning: "",
  sku: "",
  costPrice: null,
  otherInfo: "",
  link: "",
  availability: 2,
};

export const fieldsToMerge = [
  // base, nested custom form key
  { base: "files", custom: "pictures", excludeSaving: true }, // spec. field only for base model
  { base: null, custom: "title" },
  { base: null, custom: "description" },
  { base: null, custom: "categoryId" },
  { base: null, custom: "department", excludeSaving: true },
  { base: null, custom: "category", excludeSaving: true },
  { base: null, custom: "category_features" },
  { base: null, custom: "categoryName" },
  { base: null, custom: "categoryShortName" },
  { base: null, custom: "sizeId" },
  { base: null, custom: "sizeName" },
  {
    base: null,
    custom: "isTagsAttached",
    toForm: (value) => {
      if (typeof value === "boolean") return value;

      return null;
    },
  },
  {
    base: null,
    custom: "isAddToBoutique",
    toForm: (value) => {
      if (typeof value === "boolean") return value;

      return null;
    },
  },
  { base: null, custom: "brandId" },
  { base: null, custom: "brandName" },
  { base: null, custom: "primaryColor" },
  { base: null, custom: "secondaryColor" },
  { base: null, custom: "colorName" },
  { base: null, custom: "tags" },
  { base: null, custom: "originalPrice", toForm: (val) => val },
  { base: null, custom: "listingPrice" },
  { base: null, custom: "discountedShipping" },
  { base: null, custom: "earning", excludeSaving: true },
  { base: null, custom: "sku" },
  { base: null, custom: "costPrice", toForm: (val) => val },
  { base: null, custom: "otherInfo" },
  { base: null, custom: "availability" },
  { base: null, custom: "link", excludeSaving: true },
  { base: null, custom: "listingId", excludeSaving: true },
  { base: null, custom: "status", excludeSaving: true },
  { base: "createdAt", custom: "createdAt", excludeSaving: true },
  { base: "updatedAt", custom: "updatedAt", excludeSaving: true },
];

export const stringToArray = (string) => {
  if (Array.isArray(string)) return;
  return string.split(",");
};

const defaultTransform = (data) => {
  return data;
};

export const transformTags = (listTags) => {
  if (!listTags || !Array.isArray(listTags)) return [];

  return listTags?.map((tag) => {
    return tag?.tagId || tag;
  });
};

const transformCondition = (condition) => {
  return condition ? "nwt" : "not_nwt";
};

const sellerShippingDiscountTransform = (discount) => {
  return { id: discount || null };
};

const priceTransform = (price) => {
  return {
    currency_code: "USD",
    currency_symbol: "$",
    val: price?.toString() || null,
  };
};

export const fieldsToMergeServer = [
  { from: "style_tags", to: "tags", transform: defaultTransform },
  { from: "condition", to: "isTagsAttached", transform: transformCondition },
  { from: "brand", to: "brandId", transform: defaultTransform },
  { from: "price_amount", to: "listingPrice", transform: priceTransform },
  {
    from: "original_price_amount",
    to: "originalPrice",
    transform: priceTransform,
  },
  {
    from: "seller_shipping_discount",
    to: "discountedShipping",
    transform: sellerShippingDiscountTransform,
  },
  { from: "cost_price_amount", to: "costPrice", transform: priceTransform },
  { from: "size", to: "sizeId", transform: defaultTransform },
  { from: "other_info", to: "otherInfo", transform: defaultTransform },
  { from: "title", to: "title", transform: defaultTransform },
  { from: "description", to: "description", transform: defaultTransform },
  { from: "sku", to: "sku", transform: defaultTransform },
];
