/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import StripeCreditCard from "./CreditCard";
import { CustomModal, ModalBody } from "../../../CustomModal";

import { ENV } from "../../../../constants/env";
import { CREDIT_STYLES } from "./CreditCard/style";

const stripePromise = loadStripe(ENV.STRIPE_APP);

const CreditCard = ({
  isOpen,
  handleIsOpen,
  onClose,
  onChangeStep,
  chosenSubscription,
  activeSubscription,
  title = "Set up your credit or debit card",
  isChangingSubscription,
  onChangeSubscriptionPlan,
  pricesDifference,
  isPostSubscription,
}) => {
  return (
    <CustomModal isOpen={isOpen} unmountOnClose={false}>
      <div className="modal-header">
        <h3 className="modal-title mt-0">{title}</h3>
      </div>
      <ModalBody>
        <Elements stripe={stripePromise} options={CREDIT_STYLES.STRIPE_OPTIONS}>
          <StripeCreditCard
            handleIsOpen={handleIsOpen}
            onChangeStep={onChangeStep}
            chosenSubscription={chosenSubscription}
            activeSubscription={activeSubscription}
            isChangingSubscription={isChangingSubscription}
            onChangeSubscriptionPlan={onChangeSubscriptionPlan}
            pricesDifference={pricesDifference}
            isPostSubscription={isPostSubscription}
            onClose={onClose}
          />
        </Elements>
      </ModalBody>
    </CustomModal>
  );
};

CreditCard.propTypes = {
  isOpen: PropTypes.bool,
  onChangeStep: PropTypes.func,
  chosenSubscription: PropTypes.any,
};

export default CreditCard;
