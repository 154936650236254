import React from "react";
import classnames from "classnames";
import { ReactComponent as DeleteIcon } from "../../../../../base/components/Select/DropdownSelect/grey.svg";

export const SearchInput = ({
                              value: search,
                              onChange,
                              placeholder,
                              autoFocus,
                              autoComplete,
                              className,
                              isDeleteButton = false,
                              isDisabled = false,
                            }) => {
  return (
    <>
      <div
        className={classnames(
          "search-box d-inline-block table-search",
          className
        )}
      >
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <input
              autoComplete={autoComplete}
              autoFocus={autoFocus}
              id="search-bar-0"
              type="text"
              aria-labelledby="search-bar-0-label"
              className="form-control "
              placeholder={''}
              value={search}
              onChange={onChange}
              disabled={isDisabled}
            />
            {!search && (
              <div className={"search-placeholder"}>{placeholder}</div>
            )}
          </label>
          <i className="bx bx-search-alt search-icon" />
          {isDeleteButton && search && (
            <DeleteIcon
              className="cursor-pointer delete-icon"
              onClick={() =>
                onChange({
                  target: { value: "" },
                  currentTarget: { value: "" },
                })
              }
            />
          )}
        </div>
      </div>
    </>
  );
};