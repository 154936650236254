import React from "react";
import PropTypes from "prop-types";

import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import { useHighlight } from "../../../../base/hooks/useHighlight";

const WithHighlight = ({ text, exact = false, className = "cell-bold" }) => {
  const {
    params: { query },
  } = useLocationQuery();
  const { decorateText } = useHighlight(query, exact);

  return <p {...decorateText(text)} className={className} />;
};

WithHighlight.propTypes = {
  text: PropTypes.string,
  exact: PropTypes.bool,
  className: PropTypes.string,
};

export default WithHighlight;
