import React, { useState } from "react";
import { useCallback } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import HeaderFormatter from "../../../drafts/components/Table/HeaderFormatter";
import {
  CREATED_AT_FIELD,
  ORDER_BY,
  ORDER_TYPE,
  ORDER_TYPE_ASC,
  ORDER_TYPE_DESC,
} from "./constants";

const MileagesHeader = () => {
  const [orderType, setOrderType] = useState(ORDER_TYPE_DESC);
  const { merge } = useLocationQuery();

  const onSortByDate = useCallback(() => {
    merge(ORDER_BY, CREATED_AT_FIELD);
    setOrderType(
      orderType === ORDER_TYPE_ASC ? ORDER_TYPE_DESC : ORDER_TYPE_ASC
    );
    merge(ORDER_TYPE, orderType);
  }, [merge, setOrderType, orderType]);

  return (
    <Card className="task-header d-none d-sm-block mb-1">
      <CardBody className="py-3">
        <Row>
          <Col xs="12" sm="5" md="5">
            <p className="mb-0 fw-bold font-size-13">Title</p>
          </Col>
          <Col xs="2" md="3">
            <p className="mb-0 fw-bold font-size-13 text-truncate">
              <HeaderFormatter
                text="Date"
                dataField={CREATED_AT_FIELD}
                sortBy={CREATED_AT_FIELD}
                onClick={onSortByDate}
              />
            </p>
          </Col>
          <Col xs="2" md="2">
            <p className="mb-0 fw-bold font-size-13 text-nowrap">Mileage</p>
          </Col>
          <Col xs="9" sm="5" md="2">
            <p className="mb-0 fw-bold font-size-13 text-end">Actions</p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default MileagesHeader;
