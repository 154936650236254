import { object } from "yup";

import {
  TASK_TITLE,
  TASK_DESCRIPTION,
  TASK_ASSIGNEE,
} from "../../../validation/taskManagement";

export const taskSchema = object().shape({
  assigneeId: TASK_ASSIGNEE,
  title: TASK_TITLE,
  description: TASK_DESCRIPTION,
});
