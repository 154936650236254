import TableSearch from "../Search";
import CountEntries from "../CountEntries";
import AddDraftButton from "../AddDraftButton";
import React, { useMemo } from "react";
import "./index.scss";
import { TableContext } from "../../contexts/table";
import { useStatelessProvider } from "../../../../base/hooks/useProvider";
import { useService } from "../../../../base/hooks/useService";
import StorageService from "../../../../services/StorageService";
import { KEY_USER } from "../../../../base/constants/storage";
import { isDisabledDraft } from "../../helpers/isDisabledDraft";
import { AVAILABLE_DRAFT } from "../../constants";
import { DRAFTS_GROUP_LINKS } from "../../config";

const HeaderComponent = ({ toolkitProps }) => {
  const [{ used }] = useStatelessProvider(TableContext);
  const storage = useService(StorageService);

  const { activeSubscription } = storage.get(KEY_USER, {});

  const isDisabled = useMemo(() => {
    if (!activeSubscription) return false; // CHECK
    const { type } = activeSubscription;
    return isDisabledDraft(type, used, 'draft');
  }, [used, activeSubscription]);

  const type = activeSubscription?.type || null;

  return (
    <div className={"d-flex drafts-list-toolbar"}>
      <TableSearch
        {...toolkitProps.searchProps}
        placeholder="Search by SKU or Title"
      />

      <div className={"draft-header-counters"}>
        <CountEntries 
          count={used}
          color='green'
        >
          Used
        </CountEntries>
        {!!AVAILABLE_DRAFT[type] && (
          <CountEntries 
            className='mr-3'
            count={AVAILABLE_DRAFT[type] - used}
            color='brown'
          >
            Available
          </CountEntries>
        )}        
        <AddDraftButton className={"ml-4"} disabled={isDisabled} link={DRAFTS_GROUP_LINKS.ADD_DRAFT} buttonContent="+ Add Draft"/>
      </div>
    </div>
  );
};

export default HeaderComponent;
