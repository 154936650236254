import React, { useCallback, useState } from "react";
import Bugsnag from "@bugsnag/js";

import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../base/components/CustomModal";
import CustomButton from "../../../base/components/CustomButton";
import { useService } from "../../../base/hooks/useService";
import { useLoading } from "../../../base/hooks/useLoading";
import SoldService from "../../../services/SoldService";

const defaultConfig = {
  title: "Return item",
  message:
    "Do you want to create a return ticket for this chosen item? It will move to Returns and may affect Analytics and/or Bookkeeping.",
  btnAccept: "Yes",
  btnClose: "No",
};

export const useReturnProduct = (
  afterReturn,
  { title, message, btnAccept, btnClose } = defaultConfig
) => {
  const [soldToReturn, setSoldToReturn] = useState(null);
  const [loading, { registerPromise }] = useLoading();

  /**
   * @type {SoldService}
   */
  const soldService = useService(SoldService);

  const onReturnProduct = useCallback((productId) => {
    setSoldToReturn(productId);
  }, []);

  const close = useCallback(() => {
    setSoldToReturn(null);
  }, []);

  const returnSoldProduct = useCallback(() => {
    registerPromise(
      soldService
        .returnSold(soldToReturn)
        .then(() => {
          afterReturn();
        })
        .catch((e) => Bugsnag.notify(e))
    );

    close();
  }, [soldToReturn, close, soldService, registerPromise, afterReturn]);

  const view = (
    <CustomModal isOpen={!!soldToReturn} onClose={close}>
      <ModalHeader onClose={close}>{title}</ModalHeader>
      <ModalBody>
        <p className="ui-regular">{message}</p>
      </ModalBody>
      <ModalFooter>
        <CustomButton onClick={close} className="outline-primary ">
          {btnClose}
        </CustomButton>
        <CustomButton onClick={returnSoldProduct} className="filled-primary ">
          {btnAccept}
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );

  return {
    view,
    loading,
    onReturnProduct,
  };
};
