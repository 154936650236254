import { useMemo } from "react";
import { EVENTS } from "../constants/socketEvents";
import { useSocketIo } from "./useSocketIo";
import { useHttpInterceptors } from "./useHttpInterceptor";
import { useService } from "./useService";
import ImportProductService from "../../services/ImportProductService";
import { ACTIVE_INVENTORY_LIMIT } from "../../pages/inventory/constants/importInventory";

export const useLimitationEvent = (handleEvent) => {

  /**
   @type {ImportProductService}
   */
  const importProductService = useService(ImportProductService);

  const interceptors = useMemo(() => [
    data => data,
    (err) => {

      if (err?.error?.code === ACTIVE_INVENTORY_LIMIT) handleEvent();

      throw err;
    }
  ], [handleEvent]);

  useHttpInterceptors(importProductService, interceptors);

  const subscriptionSocketEvents = useMemo(() => ({
    events: [
      {
        event: EVENTS.PRODUCTS_LIMITATION_EVENT,
        handleEvent
      }
    ]
  }), [handleEvent]);

  useSocketIo(subscriptionSocketEvents);
};
