import React from 'react'
import PropTypes from "prop-types";
import classnames from 'classnames';

import { badgesColor } from './colors';

const CustomBadge = ({color = 'green', className, children}) => {
  return (
    <span className={classnames(
      'custom-badges',
      badgesColor[color] || badgesColor['green'],
      className
    )}>
      {children}
    </span>
  )
};

CustomBadge.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
}

export default CustomBadge;