import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, Row, Col } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import AnalyticsService from "../../../../services/AnalyticsService";
import { Progress } from "../../../../base/components/Charts";
import { useService } from "../../../../base/hooks/useService";
import {
  separateThousandth,
  separateThousandthFloored,
} from "../../../analytics/helpers/separateThousandth";
import ProgressPerformance from "../ProgressPerformance/ProgressPerformance";
import { ANALYTICS_GROUP_LINKS } from "../../../analytics/config";
import "./AnalyticsMonth.scss";

const COUNT_ROUNDING = 0;

const AnalyticsMonth = () => {
  const history = useHistory();
  const [performance, updatePerformance] = useState({});
  const [shouldHideOverflow, setShouldHideOverflow] = useState(false);

  const goalReached = useMemo(() => {
    return performance?.monthGoal && performance?.currentMonthSales
      ? (
          (performance?.currentMonthSales / performance?.monthGoal) *
          100
        )?.toFixed(COUNT_ROUNDING)
      : 0;
  }, [performance?.monthGoal, performance?.currentMonthSales]);

  /**
   * @type {AnalyticsService}
   */
  const analytics = useService(AnalyticsService);

  const getAnalytics = useCallback(() => {
    analytics
      .getAnalyticsForCurrentMonth()
      .then(({ data }) => {
        updatePerformance(data);
      })
      .catch((e) => {
        // this isn't a bug; this is us saying that no goals were found
        // just logging it in the console for now
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }, [analytics, updatePerformance]);

  useEffect(() => {
    setShouldHideOverflow(true);
    getAnalytics();
  }, [getAnalytics]);

  return (
    <Card class={shouldHideOverflow ? "card chart-overflow-hidden" : "card"}>
      <CardBody
        className="py-3 external-event"
        onClick={() => history.push(ANALYTICS_GROUP_LINKS.MONTHLY_OVERVIEW)}
      >
        <Row className="row chart-overflow-hidden">
          <Col>
            <h3 className="font-size-19 font-weight-semibold title-color">
              This Month So Far
            </h3>
            <ProgressPerformance performance={performance} />
          </Col>
          <Col className={"chart-dashboard-column"}>
            <div className="position-absolute top-0 left-0">
              <div className="font-size-19 fw-bold title-color mb-3">
                Monthly goal
              </div>
              <div className="font-size-22 fw-bold title-text-color mb-0">
                {separateThousandthFloored(performance?.monthGoal)}
              </div>
            </div>
            <Progress total={100} current={goalReached} dashboard={true}>
              <Row>
                <p className="mb-0 text-muted font-size-13 text-center">
                  Goal reached:
                </p>
              </Row>
              <Row className="mt-1 mb-3">
                <p className="mb-0 font-size-22 fw-bold title-text-color text-center">
                  {goalReached + "%"}
                </p>
              </Row>
              <Row>
                <p className="mb-0 text-muted font-size-13 text-center">
                  To be On Track:
                  <span className="ml-1 font-size-16 fw-bold">
                    {separateThousandth(performance?.salesNeededToBeOnTrack)}
                  </span>
                </p>
              </Row>
            </Progress>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default AnalyticsMonth;
