import React from "react";
import PropTypes from "prop-types";

import CustomButton from "../../../../base/components/CustomButton";

const ShowMoreFieldsButton = ({ className, onClick, children }) => {
  return (
    <CustomButton className={className} onClick={onClick}>
      {children}
    </CustomButton>
  );
};

ShowMoreFieldsButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.any,
};

export default ShowMoreFieldsButton;
