import React from "react";
import { BannerContainer, BannerText } from "./index.styles";
import { useError } from "../../contexts/ErrorContext";

export const GlobalBanner = () => {
  const { error } = useError();

  if (!error || error === "") return null;

  return (
    <BannerContainer>
      <BannerText>
        <strong>Note:</strong> {error}
      </BannerText>
    </BannerContainer>
  );
};
