import StorageService from "../../../services/StorageService";
import { useService } from "../../../base/hooks/useService";
import { KEY_USER } from "../../../base/constants/storage";

const useGetIdProfile = () => {
  /**
   * type {StorageService}
   */
  const storage = useService(StorageService);

  const { id } = storage.get(KEY_USER, {});

  return id;
};

export default useGetIdProfile;
