import React from "react";
import { Col, Row } from "reactstrap";
import FormikTextarea from "../../../../../../base/components/FormikTextarea";

const FormPoshmarkTextArea = ({
  label,
  description,
  name,
  placeholder,
  maxLength,
  maxLengthDescription,
  isShowLengthDescription,
  rows,
  isHideLabel,
}) => {
  return (
    <Row className="section-wrapper">
      <Col md={4}>
        <h3 className="bold-black mb-1">{label}</h3>
        <p className="text-gray-gomi">{description}</p>
      </Col>
      <Col md={8}>
        <FormikTextarea
          id={name}
          name={name}
          placeholder={placeholder}
          type="text"
          maxLength={maxLength}
          maxLengthDescription={maxLengthDescription}
          isShowLengthDescription={isShowLengthDescription}
          rows={rows}
          className={"mb-0"}
          label={label}
          isHideLabel={isHideLabel}
        />
      </Col>
    </Row>
  );
};

export default FormPoshmarkTextArea;
