import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useField } from "formik";
import moment from "moment"
import "./index.scss"
import styles
  from "../../../drafts/hooks/useProductStatus/components/SoldModal/components/InfoPriceItem/index.module.scss";

const DateField = ({
                     name,
                     id,
                     selected,
                     containerClassName="date-filter",
                     onChange,
                     minDate,
                     maxDate
                   }) => {
  const [{value}, meta, {setValue, setTouched}] = useField({name});
  const isError = meta.error && meta.touched;

  const formattedTime = selected ? moment(selected).toDate() : null

  return (
    <div className={containerClassName}>
    <div className={`dateFilterContainer mr-0`}>
      <DatePicker
        name={name}
        id={id}
        selected={formattedTime}
        onChange={(date) => {
          setTouched(true);
          setValue(date)}}
        minDate={minDate}
        maxDate={maxDate}
        autoComplete='off'
        placeholderText={"MM/DD/YYYY"}
      />
      {isError && (
        <span className={styles.priceError}>{meta.error}</span>
      )}
    </div>
    </div>
  );
};

export default DateField;