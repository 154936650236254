import React from "react";
import { Card, CardBody } from "reactstrap";
import moment from "moment";
import { BOOKKEEPING_DATE_FORMAT } from "../../../bookkeeping/constants";
import { DEFAULT_AVATAR_IMAGE } from "../../../profile/constants";
import { useProfile } from "../../../profile/hooks/useProfile";

import "./index.scss";


const DashboardHeader = () => {
  const [profileInfo] = useProfile();
  const {firstName, lastName, image} = profileInfo;

  return (
    <Card className="task-header">
      <CardBody className="py-3 ">
        <div className="dashboard-header">
          <div className="dashboard-header__info">
            <img
              className="avatar-image rounded-circle img-thumbnail"
              src={image?.link || DEFAULT_AVATAR_IMAGE}
              alt={firstName}
            />
            <h4 className="dashboard-title">Welcome back {firstName} {lastName}!</h4>
          </div>
          <div className="dashboard-header__date">{moment().format(BOOKKEEPING_DATE_FORMAT)}</div>
        </div>
      </CardBody>
    </Card>
  );
};

export default DashboardHeader;
