const LINK_TO_PROFILE_ROOT = `/app/profile`;
const LINK_TO_CHANGE_PASSWORD_PAGE = `${LINK_TO_PROFILE_ROOT}/change-password`;
const LINK_TO_CHANGE_AVATAR_PAGE = `${LINK_TO_PROFILE_ROOT}/avatar`;
const LINK_TO_VIEW_PROFILE_PAGE = `${LINK_TO_PROFILE_ROOT}/info`;
const LINK_TO_VIEW_EDIT_PROFILE_PAGE = `${LINK_TO_PROFILE_ROOT}/edit`;
const LINK_TO_VIEW_EMAIL_PAGE = `${LINK_TO_PROFILE_ROOT}/change-email`;
const LINK_TO_VIEW_PHONE_NUMBER_PAGE = `${LINK_TO_PROFILE_ROOT}/phone-number`;

export const PROFILE_GROUP_LINKS = {
  BASE: LINK_TO_PROFILE_ROOT,
  LINK_TO_CHANGE_PASSWORD_PAGE,
  LINK_TO_CHANGE_AVATAR_PAGE,
  LINK_TO_VIEW_PROFILE_PAGE,
  LINK_TO_VIEW_EDIT_PROFILE_PAGE,
  LINK_TO_VIEW_PHONE_NUMBER_PAGE,
  LINK_TO_VIEW_EMAIL_PAGE
};
