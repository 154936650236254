import { useEffect, useState } from "react";
import Bugsnag from "@bugsnag/js";

import SubscriptionService from "../../../../../services/SubscriptionService";
import { useService } from "../../../../../base/hooks/useService";

export const useGetAvailableSubscription = () => {
  const subscriptionService = useService(SubscriptionService);

  const [availableSubscriptions, updateAvailableSubscriptions] = useState([]);

  useEffect(() => {
    subscriptionService
      .getSubscriptions()
      .then(({ data }) => {
        updateAvailableSubscriptions(data);
      })
      .catch((e) => Bugsnag.notify(e));
  }, [subscriptionService]);

  return availableSubscriptions;
};
