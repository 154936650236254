import { useMemo } from "react";
import { useInfiniteScroll } from "../../../base/components/InfiniteScrollWrapper/hooks/useInfiniteScroll";
import { useLocationQuery } from "../../../base/hooks/useQueryString";
import { useService } from "../../../base/hooks/useService";
import ExpensesService from "../../../services/ExpensesService";

export const useGetExpenseList = () => {
  const {
    params: { query: q, categoryId, startDate, endDate, orderBy, orderType },
  } = useLocationQuery();

  const addedParams = useMemo(() => {
    if (endDate) {
      return { q, categoryId, startDate, endDate, orderBy, orderType };
    }
    return { q, categoryId, orderBy, orderType };
  }, [q, categoryId, startDate, endDate, orderBy, orderType]);

  /**
   * @type {ExpensesService}
   */
  const expensesService = useService(ExpensesService);

  const { data, handleFetch, hasMore, handleRefresh, totalCount, isLoading } =
    useInfiniteScroll({
      addedParams,
      fetchData: (params) => expensesService.getListExpenses(params),
    });

  return { data, handleFetch, hasMore, handleRefresh, totalCount, isLoading };
};
