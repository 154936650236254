import React from "react";

import "./styles.scss";

const Placeholder = ({
  text,
  imgSrc,
  onCreateButtonClick,
  CreateButton = null,
  buttonText = "Create return",
}) => {
  return (
    <div className="inventory-placeholder">
      <img src={imgSrc} />
      <p className="inventory-placeholder__text">{text}</p>
      {CreateButton && (
        <CreateButton buttonText={buttonText} onClick={onCreateButtonClick} />
      )}
    </div>
  );
};

export default Placeholder;
