import React, { useCallback, useState } from "react";

import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../base/components/CustomModal";
import CustomButton from "../../../base/components/CustomButton";

export const useCustomModal = ({
  title,
  message,
  onAccept,
  acceptTitle = "Yes",
  declineTitle = "No",
  isHideNoButton = false,
  additional,
}) => {
  const [isShow, updateIsShow] = useState(false);

  const close = useCallback(() => {
    updateIsShow(false);
  }, []);
  const handleAccept = useCallback(() => {
    onAccept();
    close();
  }, [onAccept]);
  const onShowModal = () => {
    updateIsShow(true);
  };
  const modal = (
    <CustomModal isOpen={!!isShow} onClose={close}>
      <ModalHeader onClose={close}>{title}</ModalHeader>
      <ModalBody>
        <div className="ui-regular mb-0">{message}</div>
        {additional && <div>{additional}</div>}
      </ModalBody>
      <ModalFooter>
        {!isHideNoButton && (
          <CustomButton onClick={close} className="outline-primary ">
            {declineTitle}
          </CustomButton>
        )}

        <CustomButton onClick={handleAccept} className="filled-primary ">
          {acceptTitle}
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );
  return {
    modal,
    onShowModal,
  };
};
