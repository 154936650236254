import React from "react";
import { ModalHeader } from "reactstrap";
import {ReactComponent as Close} from "../../../../assets/images/ic_close_grey.svg"
import style from "./index.module.scss"

import {
  CustomModal,
  ModalBody,
  ModalFooter,
} from "../../../../base/components/CustomModal";
import CustomButton from "../../../../base/components/CustomButton";

const ModalConfirm = ({ isOpen, onClose, onClick, title, text }) => {
  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <ModalHeader onClose={onClose}>{title}</ModalHeader>
      <Close onClick={onClose} className={style.closeButton}/>
      <ModalBody>
        <p className="regular-black mb-0">{text}</p>
      </ModalBody>
      <ModalFooter>
        <CustomButton className="outline-primary" onClick={onClose}>
          No
        </CustomButton>
        <CustomButton onClick={onClick} className="filled-primary">
          Yes
        </CustomButton>
      </ModalFooter>
    </CustomModal>
  );
};

export default ModalConfirm;
