import React, { useCallback, useState } from "react";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";

import CustomButton from "../../../../../../base/components/CustomButton";
import {
  CustomModal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "../../../../../../base/components/CustomModal";

const ButtonsContainer = ({
  data,
  handleSignIn,
  handleDisconnect,
  modalText,
  isExtension,
}) => {
  const [isOpenModal, updateIsOpenModal] = useState(false);

  const handleToggleModal = useCallback(() => {
    updateIsOpenModal((isOpen) => !isOpen);
  }, []);

  const handleToggleDisconnect = useCallback(() => {
    handleToggleModal();
    handleDisconnect && handleDisconnect();
  }, [handleToggleModal, handleDisconnect]);

  if (data) {
    return (
      <>
        <Row>
          <Col>
            <CustomButton
              onClick={handleSignIn}
              className="filled-primary w-100 mb-2 mt-2"
            >
              Reconnect
            </CustomButton>
          </Col>
          <Col>
            <CustomButton
              onClick={handleToggleModal}
              className="outline-danger w-100 mb-2 mt-2"
            >
              Disconnect
            </CustomButton>
          </Col>
        </Row>
        <CustomModal isOpen={isOpenModal} onClose={handleToggleModal}>
          <ModalHeader onClose={handleToggleModal}>Disconnect</ModalHeader>
          <ModalBody>
            <p className="ui-regular">{modalText}</p>
          </ModalBody>
          <ModalFooter>
            <CustomButton
              className="outline-primary"
              onClick={handleToggleModal}
            >
              No
            </CustomButton>
            <CustomButton
              className="filled-primary"
              onClick={handleToggleDisconnect}
            >
              Yes
            </CustomButton>
          </ModalFooter>
        </CustomModal>
      </>
    );
  }

  return (
    <Row>
      <Col>
        <CustomButton
          disabled={isExtension}
          onClick={handleSignIn}
          className="filled-primary w-100 mb-2 mt-2"
        >
          Connect
        </CustomButton>
      </Col>
    </Row>
  );
};

ButtonsContainer.propTypes = {
  data: PropTypes.any,
  handleSignIn: PropTypes.func,
  handleConnect: PropTypes.func,
  handleDisconnect: PropTypes.func,
  modalText: PropTypes.string,
};

export default ButtonsContainer;
