import React from "react";
import InventoryTabItem from "../components/InventoryTabItem";
import {INVENTORY_STATUSES} from "../constants";

export const usePostedInventory = ({inventoryData}) => {
	const INVENTORY_TABS = [
		{title: "Basic", type: "/base", objectName: " "},
		{title: "eBay", type: "/ebay", objectName: "productEbaySpecificFields"},
		{title: "Etsy", type: "/etsy", objectName: "productEtsySpecificFields"},
		{
			title: "Poshmark",
			type: "/poshmark",
			objectName: "productPoshmarkSpecificFields",
		},
		{
			title: "Mercari",
			type: "/mercari",
			objectName: "productMercariSpecificFields",
		},
	];

	const getEditInventoryTabs = (url) => {
		return INVENTORY_TABS.map((tab) => {
			const status = tab?.objectName
				? inventoryData[tab.objectName]?.status
				: false;

			return {
				title: (
					<InventoryTabItem
						posted={status === INVENTORY_STATUSES.listed}
						title={tab?.title}
					/>
				),
				link: url + tab?.type,
			};
		});
	};

	return {
		getEditInventoryTabs,
	};
};
