import React from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

const InfoItem = ({ title, value = "-" }) => {
  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>{title}</span>
      <span className={styles.value}>{value}</span>
    </div>
  );
};

InfoItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

export default InfoItem;
