import React, { useEffect, useMemo } from "react";
import { Container } from "reactstrap";
import Bugsnag from "@bugsnag/js";

import PageMeta from "../../../../base/components/MetaTags";
import PageTitle from "../../../profile/components/PageName";
import ProfileService from "../../../../services/ProfileService";
import { useService } from "../../../../base/hooks/useService";
import { useProfile } from "../../../profile/hooks/useProfile";
import { ROLES } from "../../../auth/constants/roles";
import DashboardHeader from "../../components/DashboardHeader";
import DashboardEmployee from "../DasboardEmployee";
import DashboardStoreOwner from "../DashboardStoreOwner";

import "./index.scss";

const DashboardPanel = () => {
  /**
   * @type {ProfileService}
   */
  const profileService = useService(ProfileService);
  const [profileInfo, update] = useProfile();

  useEffect(() => {
    profileService
      .getProfileInfo()
      .then(({ data }) => update(data))
      .catch((e) => Bugsnag.notify(e));
  }, [profileService]);

  const dashboardMap = useMemo(
    () => ({
      [ROLES.owner]: <DashboardStoreOwner />,
      [ROLES.employee]: <DashboardEmployee />,
    }),
    []
  );

  return (
    <Container fluid>
      <PageMeta title="Dashboard" />
      <PageTitle title={"Dashboard"} />
      <DashboardHeader />
      {dashboardMap[profileInfo.role]}
    </Container>
  );
};

export default DashboardPanel;
