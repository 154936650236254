import _ from "lodash";
import { useMemo } from "react";

export const prepareFormModelForSaving = (
  fullModel,
  customModel,
  fieldsToCompare,
  formName
) => {
  const largeModel = {
    ...fullModel,
    [formName]: {},
  };

  fieldsToCompare.forEach(({ base, custom }) => {
    if (custom === null && base) {
      largeModel[base] = customModel[base];
      return;
    }

    if (base === null && custom) {
      if (largeModel[formName][custom] && largeModel[formName][custom] !== null)
        return;

      if (
        customModel[custom] === null &&
        fullModel[formName] &&
        fullModel[formName][custom]
      ) {
        largeModel[formName][custom] = fullModel[formName][custom];
        return;
      }

      largeModel[formName][custom] = customModel[custom];
      return;
    }

    const commonValue = largeModel[base];
    const customValue = customModel[custom];

    if (_.isEqual(commonValue, customValue)) {
      return;
    }

    if (formName) {
      largeModel[formName][custom] = customValue;
    } else {
      largeModel[custom] = customValue;
    }
  });

  largeModel[formName].dirty = false;

  if (customModel?.dirty) {
    largeModel[formName].dirty = customModel.dirty;
  }

  return largeModel;
};

export const getInitialFormValues = (
  largeModel,
  initialValues,
  fieldsToMerge,
  key
) => {
  const model = {};

  const form = key ? largeModel[key] || {} : largeModel;

  fieldsToMerge.forEach(({ base, custom, toForm, allowZero }) => {
    if (base === null && custom) {
      model[custom] = toForm
        ? toForm(form[custom])
        : form[custom] || initialValues[custom];
      return;
    }

    if (allowZero && form[base] === 0) {
      model[base] = form[base];
      return;
    }

    if (custom === null && base) {
      model[base] = form[base] || initialValues[base];
      return;
    }

    model[custom] = form[custom] || largeModel[base];

    if (model[custom] === undefined) {
      model[custom] = initialValues[custom];
    }
  });

  const finalForm = Object.assign({}, initialValues, model);
  finalForm.files = largeModel.files;

  return finalForm;
};

export const getProductModel = (largeModel, fieldsToMerge, key) => {
  const model = {};
  const form = key ? largeModel[key] || {} : largeModel;
  const property = key ? "custom" : "base";
  const isNestedForm = !!key;

  fieldsToMerge.forEach((data) => {
    const propertyName = data[property];
    const propertyNameCustom = data["custom"];
    const basePropertyName = data.base;
    const toSave = data.toSave || ((data) => data);

    if (!propertyName || data.excludeSaving) return;

    let value = form[propertyName];

    if (isNestedForm && !!basePropertyName && !value) {
      value = largeModel[basePropertyName];
    }

    if (!value && value !== 0 && value !== false) return;

    const isValueFromBasic =
      largeModel[basePropertyName] === form[propertyNameCustom];

    model[propertyName] = toSave(value, isValueFromBasic);
  });

  return model;
};

export const useInitialFormValues = (
  largeModel,
  initialValues,
  fieldsToMerge,
  key
) => {
  return useMemo(
    () => getInitialFormValues(largeModel, initialValues, fieldsToMerge, key),
    [largeModel, initialValues, fieldsToMerge, key]
  );
};
