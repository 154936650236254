import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";

import CustomButton from "../../../../base/components/CustomButton";
import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import InfiniteScrollWrapper from "../../../../base/components/InfiniteScrollWrapper";
import { useGetMileageList } from "../../hooks/useGetMileageList";

import MileagesHeader from "../MileagesHeader";
import ExpenseSearch from "../ExpenseSearch";
import MileageCard from "../MileageCard";

import CreateMileage from "../CreateMileage";
import { useCreateMileage } from "../../hooks/useCreateMileage";
import { useDeleteMileage } from "../../hooks/useDeleteMileage";

import ExpensesPlaceholder from "../ExpensesPlaceholder";
import Placeholder from "../../../../base/components/Table/placeholder";
import { useEditMileage } from "../../hooks/useEditMileage";
import { MILEAGES_TAB } from "../../constants/expensesTabs";
import WhiteBox from "../../../drafts/components/WhiteBox";

const placeholderStrings = {
  messageFirstPart: "No mileages for now. To create a new",
  messageSecondPart: "mileage, click \"+ Add mileage\"",
  btnTitle: "+ Add mileage",
};

const MileagesList = ({
                        getCount = () => {
                        },
                      }) => {
  const {
    params: { query: q, orderBy, orderType },
  } = useLocationQuery();

  const [
    mileages,
    { handleRefresh, handleFetch, isLoading, hasMore, totalCount },
  ] = useGetMileageList();

  const [
    onCreateMileage,
    { isOpenModal, onOpenModal, onCloseModal },
    createProcessing,
  ] = useCreateMileage({ refresh: handleRefresh });

  const [onEdit, editModal] = useEditMileage(mileages, handleRefresh);

  const [onOpenDeleteModal, deleteModal] = useDeleteMileage({
    refresh: handleRefresh,
  });

  useEffect(() => {
    handleRefresh();
  }, [q, orderBy, orderType]);

  useEffect(() => {
    getCount({ [MILEAGES_TAB]: totalCount });
  }, [totalCount]);

  return (
    <>
      <Card>
        <CardBody>
          {isLoading ? (
            <WhiteBox>
              <p>Loading...</p>
            </WhiteBox>
          ) : !mileages?.length && !isLoading && !q ? (
            <ExpensesPlaceholder
              onClick={onOpenModal}
              strings={placeholderStrings}
              isMileages
            />
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <ExpenseSearch placeholder="Search by Title"/>
                <CustomButton
                  className="filled-primary create-expense-btn"
                  onClick={onOpenModal}
                >
                  + Create mileage
                </CustomButton>
              </div>
              <MileagesHeader/>
              <InfiniteScrollWrapper hasMore={hasMore} fetchData={handleFetch}>
                {!mileages?.length && q ? (
                  <Placeholder text={"No found expenses"}/>
                ) : (
                  mileages &&
                  mileages.map((mileage) => {
                    return (
                      <MileageCard
                        key={mileage?.id}
                        onEdit={onEdit}
                        onDelete={onOpenDeleteModal}
                        {...mileage}
                      />
                    );
                  })
                )}
              </InfiniteScrollWrapper>
            </>
          )}
        </CardBody>
      </Card>
      <CreateMileage
        isOpen={isOpenModal}
        onClose={onCloseModal}
        onSubmit={onCreateMileage}
        isLoading={createProcessing}
      />
      {editModal}
      {deleteModal}
    </>
  );
};

MileagesList.propTypes = {
  getCount: PropTypes.func,
};

export default MileagesList;
