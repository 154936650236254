import React, { forwardRef } from "react";
import PropTypes from "prop-types";

import Icon from "../../../../base/components/Icon";

import styles from "./index.module.scss";

const AnalyticRangeDateInput = forwardRef(({ value, onClick }, ref) => {
  return (
    <button className={styles.button + " flex-wrap"} onClick={onClick} ref={ref}>
      <span className={styles.dateRange}>{value}</span>
      <Icon icon="iconSidebarTaskManagement" />
    </button>
  );
});

AnalyticRangeDateInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
};

export default AnalyticRangeDateInput;
