import React from "react";
import ExpenseDateFilter from "../../../../../expenses/components/ExpenseDateFilter";
import { useExpenseDateFilter } from "../../../../../expenses/hooks/useExpenseDateFilter";
import SoldInventoryDropdown from "../../../../components/InventoryDropdown";
import styles from "../../index.module.scss";

const TableHeader = ({ headerProps }) => {
  const [dateRange, onChangeDateRange] = useExpenseDateFilter();

  return (
    <div className={"d-flex drafts-list-toolbar justify-content-between"}>
      <div className={styles.headerContainer}>
        <h3 className={styles.headerTitle}>Top 10</h3>
        <SoldInventoryDropdown
          list={headerProps.groupByList}
          defaultSelected={headerProps.groupByList[0]}
          onChange={headerProps.onSelectGroup}
          classes={styles.headerBrandButton}
        />
      </div>
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <span className="date-filter-label">Filter by date:</span>
          <ExpenseDateFilter
            dateRange={dateRange}
            onChange={onChangeDateRange}
            containerClass={"dateFilterContainer"}
            placeholder={"MM/DD/YYYY"}
          />
          <SoldInventoryDropdown
            list={headerProps.orderByList}
            defaultSelected={headerProps.orderByList[0]}
            onChange={headerProps.onSelectOrder}
            classes={styles.headerCountButton}
          />
        </div>
      </div>
    </div>
  );
};

export default TableHeader;
