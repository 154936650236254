/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { Fragment } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import Bugsnag from "@bugsnag/js";

import CustomButton from "../../../../base/components/CustomButton";
import ImportInventoryItem from "./ImportInventoryItem";
import { activeImportList, BACKEND_KEYS, soldImportList } from "./ImportList";

import MarketPlaceService from "../../../../services/MarketplaceService";
import { isLastOfArray } from "../../../../base/helpers/isLastOfArray";
import { useDropdown } from "../../../../base/hooks/useDropdown";
import { useService } from "../../../../base/hooks/useService";
import PoshmarkExtension from "../../../../services/PoshmarkExtension";
import MercariExtension from "../../../../services/MercariExtension";
import { ACTIVE_INVENTORY_LABEL } from "../../constants";
import { useEbayIsConnect } from "../../../drafts/hooks/useEbayIsConnect";
import { useEtsyIsConnect } from "../../../drafts/hooks/useEtsyIsConnect";
import {
  MARKETPLACE_EBAY_TYPE,
  MARKETPLACE_ETSY_TYPE,
  MARKETPLACE_MERCARI_TYPE,
  MARKETPLACE_POSHMARK_TYPE,
} from "../../constants/importInventory";
import { usePoshmarkIsConnect } from "../../../drafts/hooks/Poshmark/usePoshmarkIsConnect";
import { useMercariIsConnect } from "../../../drafts/hooks/useMercariIsConnect";

const ImportInventory = ({ status }) => {
  /**
   * @type {PoshmarkExtension}
   */
  const poshmarkExtension = useService(PoshmarkExtension);
  /**
   * @type {MercariExtension}
   */
  const mercariExtension = useService(MercariExtension);

  const marketplaceService = useService(MarketPlaceService);
  const [marketplaceData, updateMarketplaceData] = useState({
    mercariAccount: null,
  });
  const [isOpen, toggle] = useDropdown();

  useEffect(() => {
    marketplaceService
      .getMarketplace()
      .then((response) => {
        const { data } = response;

        return updateMarketplaceData(data);
      })
      .then(() => {
        poshmarkExtension
          .isConnected()
          .then((response) => {
            if (!response.result) return;

            const {
              result: { value },
            } = response;

            updateMarketplaceData((prevState) => ({
              ...prevState,
              poshmarkAccount: value,
            }));
          })
          .catch((e) => {
            Bugsnag.notify(e);
          });

        mercariExtension.isConnected().then((response) => {
          const {
            result: { value },
          } = response;

          updateMarketplaceData((prevState) => ({
            ...prevState,
            mercariAccount: value,
          }));
        });
      })
      .catch((e) => Bugsnag.notify(e));
  }, [marketplaceService, poshmarkExtension, mercariExtension]);

  const importList =
    status === ACTIVE_INVENTORY_LABEL ? activeImportList : soldImportList;

  const { isConnected: isConnectedEbay } = useEbayIsConnect();
  const { isConnected: isConnectedEtsy } = useEtsyIsConnect();
  const { isConnected: isConnectedPoshmark } = usePoshmarkIsConnect();
  const { isConnected: isConnectedMercari } = useMercariIsConnect();

  const isConnectedAPIMarketplaces = useMemo(
    () => ({
      [BACKEND_KEYS[MARKETPLACE_EBAY_TYPE]]: isConnectedEbay,
      [BACKEND_KEYS[MARKETPLACE_ETSY_TYPE]]: isConnectedEtsy,
      [BACKEND_KEYS[MARKETPLACE_POSHMARK_TYPE]]: isConnectedPoshmark,
      [BACKEND_KEYS[MARKETPLACE_MERCARI_TYPE]]: isConnectedMercari,
    }),
    [isConnectedEbay, isConnectedEtsy, isConnectedPoshmark, isConnectedMercari]
  );

  return (
    <Dropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle style={{ display: "none" }}>Dropdown</DropdownToggle>
      <CustomButton
        className="outline-primary ml-0 inventory-import-button"
        onClick={toggle}
      >
        Import
        <i className="mdi mdi-chevron-down green-color ml-1" />
      </CustomButton>
      <DropdownMenu persist>
        {importList.map((item, index, arr) => {
          return (
            <Fragment key={index}>
              <ImportInventoryItem
                data={
                  item.backendKey &&
                  marketplaceData?.[item.backendKey] &&
                  isConnectedAPIMarketplaces[item.backendKey]
                }
                status={status}
                {...item}
              />
              {!isLastOfArray(arr, index) && <DropdownItem divider />}
            </Fragment>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ImportInventory;
