import { useCallback, useEffect, useMemo, useState } from "react";

export const useCheckedAll = (items, key) => {
  const [map, updateMap] = useState({});

  const reset = useCallback(() => {
    updateMap({})
  }, []);

  const total = useMemo(() => {
    return Object.keys(map).length
  }, [map]);

  const isCheckedAll = useMemo(() => {
    return total === items.length;
  }, [total, items]);

  const checkOne = useCallback(({[key]: id}) => {
    updateMap(({[id]: oldValue, ...state}) => {

      if (oldValue)return state;

      return {
        ...state,
        [id]: true
      }
    });
  }, [key]);

  const checkAll = useCallback(() => {
    if(isCheckedAll)return updateMap({});

    let newState = {};

    items.forEach(({[key]: id}) => {
      newState[id] = true
    });

    updateMap(newState)
  }, [items, isCheckedAll, key]);


  const getIsChecked = useCallback(({[key]: listingId}) => {
    return !!map[listingId]
  }, [map, key]);


  // re-validate
  useEffect(() => {
    updateMap(state => {
      const newState = {};

      items.forEach(({[key]: id}) => {
          if (state[id]) {
            newState[id] = true
          }
      });

      return newState;
    });
  }, [items, key]);

  return {isCheckedAll, checkAll, checkOne, reset, getIsChecked, total};
};
