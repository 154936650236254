import React, { useEffect, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card } from "reactstrap";
import qs from "qs";
import Bugsnag from "@bugsnag/js";

import { useLocationQuery } from "../../../../base/hooks/useQueryString";
import Header from "./components/Header";
import { useService } from "../../../../base/hooks/useService";
import EmployeesService from "../../../../services/EmployeesService";
import AuthCardContainer from "../../components/AuthCardContainer";
import {
  BAD_REQUEST_ERROR,
  VALIDATION_ERROR,
} from "../../../../base/constants/statusCodes";
import { ReactComponent as ExpiredLogo } from "../../../../assets/images/ic-experied-link.svg";

import styles from "./index.module.scss";

const NewEmployee = () => {
  const [error, setError] = useState({});
  const { push } = useHistory();
  const { params } = useLocationQuery();
  const employeesService = useService(EmployeesService);

  const verifyEmployee = useCallback(
    async (token) => {
      try {
        await employeesService.verifyEmployee(token);

        push(`/create-password?${qs.stringify(params)}`);
      } catch (baseError) {
        const { error } = baseError;
        Bugsnag.notify(baseError);
        setError(error);
      }
    },
    [employeesService, params, push]
  );

  useEffect(() => {
    verifyEmployee(params.token);
  }, [params, verifyEmployee]);

  return (
    (error?.code === BAD_REQUEST_ERROR || error?.code === VALIDATION_ERROR) && (
      <div className="container">
        <Header />
        <AuthCardContainer metaText="Verification">
          <Card className={styles.card}>
            <ExpiredLogo className="mx-auto" />
            <h3 className="mt-4 text-center">Sorry!</h3>
            <p className="mx-auto text-center">
              Link was expired. Contact your Store Owner.
            </p>
          </Card>
        </AuthCardContainer>
      </div>
    )
  );
};

export default NewEmployee;
