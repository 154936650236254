import {
  MARKETPLACES,
  MARKETPLACES_NAMES_REQUEST_BY_ID,
} from "../../drafts/constants";
import {
  PoshmarkActiveProduct,
  PoshmarkSoldProduct,
} from "../classes/PoshmarkProduct";
import MercariProduct from "../classes/MercariProduct";
import QueuePromises from "../../../base/classes/QueuePromises";
import {
  findElement,
  findElementLabel,
  useGetMercariCategories,
} from "../../drafts/components/DraftMercariFormInner/partials/hooks";

import { transformProducts } from "../helpers/transformProducts";
import { useStatelessProvider } from "../../../base/hooks/useProvider";
import { ImportContext } from "../../context/import";
import { SOLD_INVENTORY_LABEL } from "../constants";

const useUploadCSV = (marketplace) => {
  const [
    {
      isLoading,
      importing,
      disable,
      startPreparing,
      endPreparing,
      productStatus,
    },
  ] = useStatelessProvider(ImportContext);

  const onImportToCSVFile = (products) => {
    const queue = new QueuePromises();

    startPreparing();

    products.forEach((product) => {
      const loadData = () =>
        product?.loadAdditionalFields() || Promise.resolve();
      queue.add(loadData);
    });

    disable();

    return queue.finally(() => {
      const formattedProducts = products.map((product) => product.data).flat();

      const transformedProducts = transformProducts(
        formattedProducts,
        marketplace
      );

      endPreparing();

      importing(
        transformedProducts,
        MARKETPLACES_NAMES_REQUEST_BY_ID[marketplace],
        productStatus
      );
    });
  };

  return [isLoading, onImportToCSVFile];
};

export const useImportPoshmarkCSV = () => {
  const [isLoading, onImportToCSVFile] = useUploadCSV(MARKETPLACES.poshmark);

  const formatProducts = (products, productType) => {
    if (!products) return [];

    const Mapper =
      productType === SOLD_INVENTORY_LABEL
        ? PoshmarkSoldProduct
        : PoshmarkActiveProduct;

    return products
      .filter(Mapper.checkIsValid)
      .map((product) => new Mapper(product));
  };

  return [{ formatProducts, onImportToCSVFile }, isLoading];
};

export const useImportMercariCSV = () => {
  const [isLoading, onImportToCSVFile] = useUploadCSV(MARKETPLACES.mercari);
  const { brands, colors, sizes, categories } = useGetMercariCategories();

  const formatProducts = (products) => {
    if (!products) return [];
    return products.map((product) => new MercariProduct(product));
  };

  const onImportToCSVMercaryFile = (products) => {
    products.forEach((product) => {
      product.addBeforeSaveHook(function () {
        const data = this.data;
        const { colorId, sizeId, brandId, categoryId } = data;

        if (brandId) {
          data.brandName = findElementLabel(brands, brandId);
        }
        if (sizeId) {
          data.sizeName = findElementLabel(sizes, sizeId);
        }
        if (colorId) {
          data.colorName = findElementLabel(colors, colorId);
        }

        if (!categoryId) return;

        const labels = [];

        const findAllLabels = (id) => {
          const nestedCategory = findElement(categories, id);
          if (!nestedCategory) return;
          labels.unshift(nestedCategory.label);
          return findAllLabels(nestedCategory.parentId);
        };

        findAllLabels(categoryId);

        if (labels.length) {
          data.categoryName = labels.join(" / ");
          data.categoryShortName = labels[labels.length - 1];
        }
      });
    });

    return onImportToCSVFile(products);
  };

  return [
    { formatProducts, onImportToCSVFile: onImportToCSVMercaryFile },
    isLoading,
  ];
};
