import React, { forwardRef } from "react";
import PropTypes from "prop-types";

const IconButton = forwardRef(
  ({ className, onClick, isDisabled, children }, ref) => {
    return (
      <button
        className={"icon-button ".concat(className)}
        onClick={onClick}
        disabled={isDisabled}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);

IconButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  children: PropTypes.any,
};

export default IconButton;
