import {
  MARKETPLACE_EBAY_TYPE,
  MARKETPLACE_ETSY_TYPE,
  MARKETPLACE_MERCARI_TYPE,
  MARKETPLACE_POSHMARK_TYPE,
} from "../../../../pages/inventory/constants/importInventory";

export const marketplacesList = [
  {
    key: "productEbaySpecificFields",
    marketplaceType: MARKETPLACE_EBAY_TYPE,
    label: "Ebay",
    [MARKETPLACE_EBAY_TYPE]: "productEbaySpecificFieldsId",
    id: "productEbaySpecificFieldsId",
  },
  {
    key: "productPoshmarkSpecificFields",
    marketplaceType: MARKETPLACE_POSHMARK_TYPE,
    label: "Poshmark",
    [MARKETPLACE_POSHMARK_TYPE]: "productPoshmarkSpecificFieldsId",
    id: "productPoshmarkSpecificFieldsId",
  },
  {
    key: "productEtsySpecificFields",
    marketplaceType: MARKETPLACE_ETSY_TYPE,
    [MARKETPLACE_ETSY_TYPE]: "productEtsySpecificFieldsId",
    label: "Etsy",
    id: "productEtsySpecificFieldsId",
  },
  {
    key: "productMercariSpecificFields",
    marketplaceType: MARKETPLACE_MERCARI_TYPE,
    [MARKETPLACE_MERCARI_TYPE]: "productMercariSpecificFieldsId",
    label: "Mercari",
    id: "productMercariSpecificFieldsId",
  },
];

export const marketMap = {};

marketplacesList.forEach((market) => {
  marketMap[market.marketplaceType] = market;
});

export const SUCCESS_MERGE_MESSAGE = "Products have been successfully merged!";
export const SORTING_TYPE = "asc";
// export const SORTING_TYPE = "desc";
export const MERGE_OFFSET = 100;
export const ID_LABEL = "id";
