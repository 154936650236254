import React from "react";
import PageMeta from "../../../base/components/MetaTags";
import { Container } from "reactstrap";
import PropTypes from "prop-types";

const InfoContainer = ({ children, metaText }) => {
  return (
    <div className="info-pages">
      <PageMeta title={metaText} />
      <Container>{children}</Container>
    </div>
  );
};

InfoContainer.propTypes = {
  children: PropTypes.any,
  metaText: PropTypes.string,
};

export default InfoContainer;
