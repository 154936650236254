/* eslint-disable react/prop-types */
import React, { useRef, useMemo } from "react";
import Bugsnag from "@bugsnag/js";

import classNames from "classnames";
import PropTypes from "prop-types";
import { Tooltip, Row, Col } from "reactstrap";
import { noop } from "lodash";

import Checkbox from "../../../../base/components/Checkbox";
import { MarketplacesMap } from "../../../drafts/constants";
import { useTooltip } from "../../../../base/hooks/useTooltip";
import { useChangeShippingCost } from "../../helpers/useChangeShippingCost";
import Icon from "../../../../base/components/Icon";
import styles from "./index.module.scss";
import PriceInput from "./components/PriceInput";
import ShippingTabletColumnRow from "./components/MobileColumnRow";
import { COLORS_NAMES } from "../../../../base/constants/draft";
import { useService } from "../../../../base/hooks/useService";
import SoldService from "../../../../services/SoldService";
import { toNumber, toStringWithDecimalPart } from "../../../sold/helpers";

const emptyValue = "-";
const commonClasses = classNames(
  styles.shippingListCell,
  "d-flex align-items-center fw-bold"
);

const ShippingCard = ({
  id,
  title,
  sku,
  color,
  buyer,
  marketplaceType,
  shippingCost,
  onCheckboxClick = noop,
  onRefresh = noop,
  order,
}) => {
  const tooltipTogglerRef = useRef(null);
  const tabletTooltipTogglerRef = useRef(null);
  const mobileTooltipTogglerRef = useRef(null);

  const [
    isEditShippingCost,
    onOpenEditShippingCostMode,
    onChangeShippingCost,
    newShippingCost,
    onChangeValue,
  ] = useChangeShippingCost(shippingCost, onRefresh);
  const soldService = useService(SoldService);

  const handleChangeShippingCost = (event) => {
    const data = {
      priceSold: order.priceSold,
      itemCost: order.itemCost,
      marketplaceFees: order.marketplaceFees,
      shippingExpenses: toNumber(newShippingCost),
      transactionFees: order.transactionFees,
      marketplaceType: order.marketplaceType,
      paymentMethod: order.paymentMethod,
      buyer: order.buyer,
      shippingChargedToBuyer: order.shippingChargedToBuyer,
      additionalSellingFees: order.additionalSellingFees,
    };

    onChangeShippingCost(id, event)
      .then(() => {
        return soldService.updateSold(order.id, data);
      })
      .catch((e) =>
        Bugsnag.notify(e, (event) => {
          event.addMetadata("handleChangeShippingCostError", {
            data,
          });
        })
      );
  };

  const formattedShippingCost = useMemo(
    () =>
      shippingCost ? `$${toStringWithDecimalPart(shippingCost)}` : emptyValue,
    [shippingCost]
  );

  return (
    <div className={styles.shippingListItem}>
      <div className={classNames("row", styles.desktopContainer)}>
        <div id={styles.rowItem} className={classNames(commonClasses, "col-3")}>
          <span className={styles.rowItemTitle}>Title</span>
          {title}
        </div>
        <div id={styles.rowItem} className={classNames(commonClasses, "col-1")}>
          <span className={styles.rowItemTitle}>SKU</span>
          {sku || emptyValue}
        </div>
        <div
          id={styles.rowItem}
          className={classNames(commonClasses, styles.shippingListCell, "col")}
        >
          <span className={styles.rowItemTitle}>Color</span>
          {COLORS_NAMES[color] || color || emptyValue}
        </div>
        <div id={styles.rowItem} className={classNames(commonClasses, "col-2")}>
          <span className={styles.rowItemTitle}>Buyer</span>
          {buyer || emptyValue}
        </div>
        <div id={styles.rowItem} className={classNames(commonClasses, "col-2")}>
          <span className={styles.rowItemTitle}>Marketplace</span>
          <div className={styles.chip}>
            {MarketplacesMap?.[marketplaceType]}
          </div>
        </div>
        <div id={styles.rowItem} className={classNames(commonClasses, "col")}>
          <span className={styles.rowItemTitle}>Shipping cost</span>
          {isEditShippingCost ? (
            <div className="d-flex align-items-center">
              <PriceInput value={newShippingCost} onChange={onChangeValue} />
              <Icon
                className={styles.editAndSaveBtn}
                icon="saveIcon"
                onClick={(event) => handleChangeShippingCost(event)}
              />
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <span>{formattedShippingCost}</span>
              <Icon
                className={styles.editAndSaveBtn}
                icon="editPen"
                onClick={onOpenEditShippingCostMode}
              />
            </div>
          )}
        </div>
        <div className={classNames(commonClasses, "col")}>
          <Checkbox
            className="ml-3"
            ref={tooltipTogglerRef}
            onClick={(event) => onCheckboxClick(event, id, shippingCost)}
          />
          <Tooltip placement="top" {...useTooltip(tooltipTogglerRef)}>
            Mark as shipped
          </Tooltip>
        </div>
      </div>
      <Row className={styles.tabletContainer}>
        <Col md="3" sm="3" xs="3" className={styles.tabletColumnRow}>
          <span className={styles.rowItemTitle}>Title</span>
          <span className="fw-bold">{title}</span>
        </Col>
        <Col md="3" sm="3" xs="3" className={styles.tabletColumnRowsWrapper}>
          <ShippingTabletColumnRow
            defaultData={{ label: "SKU", value: sku }}
            emptyValue={emptyValue}
          />
          <ShippingTabletColumnRow
            defaultData={{ label: "Color", value: color }}
            emptyValue={emptyValue}
          />
        </Col>
        <Col md="3" sm="3" xs="3" className={styles.tabletColumnRowsWrapper}>
          <ShippingTabletColumnRow
            defaultData={{ label: "Buyer", value: buyer }}
            emptyValue={emptyValue}
          />
          <div className={styles.tabletColumnRow}>
            <span className={styles.rowItemTitle}>Marketplace</span>
            <div className={styles.tabletChip}>
              {MarketplacesMap?.[marketplaceType]}
            </div>
          </div>
        </Col>
        <Col md="2" sm="2" xs="2">
          <div className={styles.tabletColumnRow}>
            <span className={styles.rowItemTitle}>Shipping cost</span>
            {isEditShippingCost ? (
              <div className="d-flex align-items-center">
                <PriceInput value={newShippingCost} onChange={onChangeValue} />
                <Icon
                  className={styles.editAndSaveBtn}
                  icon="saveIcon"
                  onClick={(event) => onChangeShippingCost(id, event)}
                />
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <span className="fw-bold">{formattedShippingCost}</span>
                <Icon
                  className={styles.editAndSaveBtn}
                  icon="editPen"
                  onClick={onOpenEditShippingCostMode}
                />
              </div>
            )}
          </div>
        </Col>
        <Col md="1" sm="1" xs="1" className="d-flex align-items-center">
          <Checkbox
            className="ml-3"
            ref={tabletTooltipTogglerRef}
            onClick={(event) => onCheckboxClick(event, id, shippingCost)}
          />
          <Tooltip placement="top" {...useTooltip(tabletTooltipTogglerRef)}>
            Mark as shipped
          </Tooltip>
        </Col>
      </Row>
      <div className={styles.mobileContainer}>
        <div className={styles.mobileColumnRow}>
          <span className={styles.mobileTitle}>{title}</span>
        </div>
        <ShippingTabletColumnRow
          containerClassName={styles.mobileColumnRow}
          defaultData={{ label: "SKU", value: sku }}
          emptyValue={emptyValue}
        />
        <ShippingTabletColumnRow
          containerClassName={styles.mobileColumnRow}
          defaultData={{ label: "Color", value: color }}
          emptyValue={emptyValue}
        />

        <ShippingTabletColumnRow
          containerClassName={styles.mobileColumnRow}
          defaultData={{ label: "Buyer", value: buyer }}
          emptyValue={emptyValue}
        />
        <div className={styles.mobileColumnRow}>
          <span className={styles.rowItemTitle}>Marketplace</span>
          <div className={styles.mobileChip}>
            {MarketplacesMap?.[marketplaceType]}
          </div>
        </div>

        <div>
          <div className={styles.mobileColumnRow}>
            <span className={styles.rowItemTitle}>Shipping cost</span>
            {isEditShippingCost ? (
              <div className="d-flex align-items-center">
                <PriceInput value={newShippingCost} onChange={onChangeValue} />
                <Icon
                  className={styles.editAndSaveBtn}
                  icon="saveIcon"
                  onClick={(event) => onChangeShippingCost(id, event)}
                />
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <span className="fw-bold">{formattedShippingCost}</span>
                <Icon
                  className={styles.editAndSaveBtn}
                  icon="editPen"
                  onClick={onOpenEditShippingCostMode}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.mobileCheckbox}>
          <Checkbox
            ref={mobileTooltipTogglerRef}
            onClick={(event) => onCheckboxClick(event, id, shippingCost)}
          />
          <Tooltip placement="top" {...useTooltip(mobileTooltipTogglerRef)}>
            Mark as shipped
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

ShippingCard.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  sku: PropTypes.string,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  buyer: PropTypes.string,
  marketplaceType: PropTypes.oneOf([1, 2, 3, 4, 5]),
  shippingCost: PropTypes.number,
  onCheckboxClick: PropTypes.func,
  onRefresh: PropTypes.func,
};

export default ShippingCard;
