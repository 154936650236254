import React, { useCallback } from "react";
import PropTypes from "prop-types";

import styles from "./index.module.scss";

const BrandOption = ({ label, onClick }) => {
  const onClickHandler = useCallback(() => onClick(label), [label, onClick]);

  return (
    <span className={styles.brandOption} onClick={onClickHandler}>
      {label}
    </span>
  );
};

BrandOption.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default BrandOption;
