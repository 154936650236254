import React, { useCallback, useEffect, useRef, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Bugsnag from "@bugsnag/js";

import VerticalPageWrapper from "../../base/components/VerticalPageWrapper";
import ReturnsProgressList from "./pages/ReturnsProgressList";
import ReturnsCompletedList from "./pages/ReturnCompletedList";

import { RETURN_GROUP_LINKS } from "./config";
import { RETURNS_TABS } from "./returnsTabs";
import { useCreateEditReturn } from "./hooks/useCreateEditReturn";
import { noop } from "lodash/util";
import {
  COMPLETED_TAB,
  IN_PROGRESS_TAB,
  RETURN_STATUSES,
} from "./constants/returns-constants";
import { useService } from "../../base/hooks/useService";
import ProductReturnsService from "../../services/ProductReturnsService";
import { useGetReturnsList } from "./hooks/useGetReturnsList";

const Returns = () => {
  const productReturnsService = useService(ProductReturnsService);

  const [tabs, updateTabs] = useState(RETURNS_TABS);

  const { data } = useGetReturnsList(RETURN_STATUSES.RETURNED);

  const getCountForTab = useCallback((totalCounterObj) => {
    if (totalCounterObj) {
      const objectKey = Object.keys(totalCounterObj)[0];

      updateTabs((prevState) => {
        prevState[objectKey].count = totalCounterObj[objectKey];

        return [...prevState];
      });
    }
  }, []);

  const getCounts = () => {
    productReturnsService
      .getListReturnProducts({
        limit: 1,
        offset: 0,
        status: RETURN_STATUSES.IN_PROGRESS,
      })
      .then((data) => {
        getCountForTab({ [IN_PROGRESS_TAB]: data.pagination.totalCount });
      })
      .catch((e) => Bugsnag.notify(e));

    productReturnsService
      .getListReturnProducts({
        limit: 1,
        offset: 0,
        status: RETURN_STATUSES.RETURNED,
      })
      .then((data) => {
        getCountForTab({ [COMPLETED_TAB]: data.pagination.totalCount });
      })
      .catch((e) => Bugsnag.notify(e));
  };

  useEffect(() => {
    getCounts();
  }, []);

  const listActions = useRef({
    refresh: noop,
  });

  const handleCreateReturn = useCallback(() => {
    onShowModal();
  }, []);

  const onMountList = useCallback(
    (actions) => {
      listActions.current = actions;
    },
    [listActions]
  );

  const { viewCreateEditReturn, onShowModal } = useCreateEditReturn(false, () =>
    listActions.current.refresh()
  );
  return (
    <VerticalPageWrapper
      title="Returns"
      titleMeta="Returns"
      buttonText="Create return"
      tabs={tabs}
      onClick={handleCreateReturn}
      data={data}
    >
      {viewCreateEditReturn}
      <Switch>
        <Route path={RETURN_GROUP_LINKS.PROGRESS}>
          <ReturnsProgressList
            getCount={getCountForTab}
            onMountList={onMountList}
            updateTabs={updateTabs}
            onCreateButtonClick={handleCreateReturn}
          />
        </Route>
        <Route path={RETURN_GROUP_LINKS.COMPLETED}>
          <ReturnsCompletedList
            getCount={getCountForTab}
            onMountList={onMountList}
            updateTabs={updateTabs}
            onCreateButtonClick={handleCreateReturn}
          />
        </Route>
        <Redirect
          from={RETURN_GROUP_LINKS.BASE}
          to={RETURN_GROUP_LINKS.PROGRESS}
          exact
        />
        <Redirect to={RETURN_GROUP_LINKS.PROGRESS} />
      </Switch>
    </VerticalPageWrapper>
  );
};

export default Returns;
