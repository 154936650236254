import React from "react";
import PropTypes from "prop-types";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";

import InputWrapper from "../InputWrapper";
import CustomButton from "../CustomButton";

import { useDisabledSubmit } from "../../hooks/useDisabledSubmit";
import {
  CARD_EXPIRY,
  CARD_NUMBER,
  REQUIRED_CARD_FIELDS,
  VALIDATION_FIELDS,
} from "../Subscription/constant/cardFields";
import { CREDIT_STYLES } from "../Subscription/components/CreditCard/CreditCard/style";

const StripeForm = ({ onSubmit, onCancel, isLoading }) => {
  const { isDisabled, onChange, errors } = useDisabledSubmit(
    REQUIRED_CARD_FIELDS,
    VALIDATION_FIELDS
  );

  return (
    <form onSubmit={onSubmit}>
      <div className="mb-5">
        <InputWrapper label="Card number" error={errors[CARD_NUMBER]}>
          <CardNumberElement
            onChange={onChange}
            options={CREDIT_STYLES.ELEMENT_OPTIONS}
          />
        </InputWrapper>
        <InputWrapper label="Expiry date" error={errors[CARD_EXPIRY]}>
          <CardExpiryElement
            onChange={onChange}
            options={CREDIT_STYLES.ELEMENT_OPTIONS_DATE}
          />
        </InputWrapper>
        <InputWrapper label="Secure code">
          <CardCvcElement
            onChange={onChange}
            options={CREDIT_STYLES.ELEMENT_OPTIONS_CVC}
          />
        </InputWrapper>
      </div>
      <div className="d-flex justify-content-end">
        <CustomButton className="outline-primary mr-2 w-130" onClick={onCancel}>
          Cancel
        </CustomButton>
        <CustomButton
          type="submit"
          className="filled-primary"
          disabled={isDisabled || isLoading}
        >
          Save
        </CustomButton>
      </div>
    </form>
  );
};

StripeForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default StripeForm;
