export const INVENTORY_TABS = [
  { type: "base", objectName: " " },
  { type: "ebay", objectName: "productEbaySpecificFields" },
  { type: "etsy", objectName: "productEtsySpecificFields" },
  {
    type: "poshmark",
    objectName: "productPoshmarkSpecificFields",
  },
  {
    type: "mercari",
    objectName: "productMercariSpecificFields",
  },
];

export const EBAY_NAME = "ebay"
export const STARTINTG_PRICE_NAME = "startingPrice"

export const MIN_LISTING_URL_VALUE = 1;
export const MAX_LISTING_URL_VALUE = 300;

export const MIN_LISTING_URL_ERROR = "Link must be from 1 to 300 characters";

export const LISTING_URL_PATTERN = /^(https:\/\/+)[\S]+$/;

export const LISTING_URL_ERROR =
  "The link should start from https:// and must not contain spaces";
