import React from 'react'
import PropTypes from 'prop-types';
import classnames from 'classnames'
import { isLastOfArray } from '../../../../../helpers/isLastOfArray';

const Breadcrumbs = ({ selectProps }) => {
  const { breadcrumbs, handleClickBreadcrumb, handleResetAll } = selectProps;

  return (
    <div className='select-breadcrumb'>
      <span onClick={handleResetAll}>All / </span>
      {breadcrumbs.map(({ id, name }, index, array) => {
        const isLast = isLastOfArray(array, index);
        return (
          <span 
            key={id}
            onClick={() => handleClickBreadcrumb(id)}
            className={classnames(
              { 'regular-black': !isLast },
              { 'bold-black': isLast }
            )}
          >
            {name}
            {!isLast && ' / '}
          </span>        
        )
      }
      )}
    </div>
  )
};

Breadcrumbs.propTypes = {
  selectProps: PropTypes.object
}

export default Breadcrumbs;