import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import PageMeta from "../../base/components/MetaTags";
import VerticalTabs from "../../base/components/VerticalTabs";
import PageTitle from "../profile/components/PageName";

import { HELP_GROUP_LINKS, HELP_TABS } from "./config";
import ContactUs from "./pages/ContactUs";
import FaqPage from "./pages/Faq";
import Instruction from "./pages/Instruction";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";

const HelpPage = () => {
  return (
    <div className="page-content">
      <Container fluid>
        <PageMeta title="Help" />
        <PageTitle title="HELP" />
        <VerticalTabs tabs={HELP_TABS} />
        <Switch>
          <Route
            path={HELP_GROUP_LINKS.INSTRUCTION}
            component={Instruction}
            exact
          />
          <Route
            path={HELP_GROUP_LINKS.CONTACT_US}
            component={ContactUs}
            exact
          />
          <Route
            path={HELP_GROUP_LINKS.PRIVACY}
            component={PrivacyPolicy}
            exact
          />
          <Route
            path={HELP_GROUP_LINKS.TERMS}
            component={TermsAndConditions}
            exact
          />
          <Route path={HELP_GROUP_LINKS.FAQ} component={FaqPage} exact />
          <Redirect to={HELP_GROUP_LINKS.INSTRUCTION} />
        </Switch>
      </Container>
    </div>
  );
};

export default HelpPage;
