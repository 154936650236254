import React from "react";
import { CardBody, Row } from "reactstrap";
import PropTypes from "prop-types";

import { ReactComponent as EmptyLogo } from "../ExpensesPlaceholder/assets/group-3.svg";

const EmptyDateFilterPlaceholder = ({ text }) => {
  return (
    <CardBody className="returned-placeholder d-flex flex-column">
      <Row>
        <EmptyLogo />
      </Row>
      <Row className="text-center mt-4">
        <h3 className="bold-black font-size-19 text-muted">{text}</h3>
      </Row>
    </CardBody>
  );
};

EmptyDateFilterPlaceholder.propTypes = {
  text: PropTypes.string,
};

export default EmptyDateFilterPlaceholder;
