/* eslint-disable react/prop-types */
import React from "react";
import styles from "./Condition.module.scss";
import joinClassNames from "../../../../../base/helpers/joinClassNames";
import Divider from "../../Divider";
import { useField } from "formik";
import { useTriggerFormChanges } from "../../../hooks/useDetectDirtyForm";

const Block = ({ children, title, onClick, isActive }) => {
  return (
    <div
      className={joinClassNames(styles.block, isActive && styles.active)}
      onClick={onClick}
    >
      <p className={styles.title}>{title}</p>
      <div className={styles.desc}>{children}</div>
    </div>
  );
};

const collection = [
  {
    title: "New",
    children: "New with tags (NWT). Unopened packaging. Unused.",
  },
  {
    title: "Like new",
    children: "New without tags (NWOT). No signs of wear. Unused.",
  },
  {
    title: "Good",
    children: "Gently used. One / few minor flaws. Functional.",
  },
  {
    title: "Fair ",
    children: "Used, functional, multiple flaws / defects.",
  },
  {
    title: "Poor",
    children: "Major flaws, may be damaged, for parts.",
  },
];

const Condition = () => {
  const [{ value }, { error, touched }, { setValue }] = useField({
    name: "conditionId",
  });
  const { markChanges, containerRef } = useTriggerFormChanges();
  const isError = error && touched;

  return (
    <div className={"mb-4"}>
      <h3 className="bold-black mb-4">Condition</h3>
      <Divider />
      <div className={joinClassNames(styles.row, "mb-1")} ref={containerRef}>
        {collection.map(({ title, children }, index) => (
          <Block
            title={title}
            key={title}
            onClick={() => {
              setValue(index + 1);
              markChanges();
            }}
            isActive={index + 1 === value}
          >
            {children}
          </Block>
        ))}
      </div>
      {isError && (
        <span className={styles.invalidConditionMessage}>{error}</span>
      )}
    </div>
  );
};

export default Condition;
