import { useCallback, useState, useEffect, useRef } from "react";
import Bugsnag from "@bugsnag/js";
import { useHistory } from "react-router-dom";

import { useLocationQuery, stringifyParams } from "./useQueryString";
import { useLoading } from "./useLoading";
import { INVENTORY_TABLE_EVENT_TYPE_SORT } from "../../pages/drafts/constants";
import { getOrderType } from "../../pages/drafts/helpers/getOrderType";

/**
 *
 * @param {string} searchQueryParam
 * @param {string} sortTypeQueryParam
 * @param {string} sortByQueryParam
 * @param {Function} getNewDataService
 * @param {Object} reverseOrderMap
 * @param {number} limit
 *
 * @param {Object} sortTransformerMap
 * @param {Object} sortTypeTransformMap
 *
 * @returns {[boolean, {totalCount: number, nextOffset: number}, data: Array, handleTableChange: Function, actions: Object]}
 */

export const useTable = ({
  searchQueryParam = "query",
  sortTypeQueryParam = "sortType",
  sortByQueryParam = "sortBy",
  getNewDataService = () => {},
  limit = 10,
  sortTransformerMap = null, //for [.net] backend
  sortTypeTransformMap = null, //for custom sort types asc -> desc -> default -> etc.
}) => {
  const history = useHistory();
  const { params } = useLocationQuery();
  const [loading, { disable, enable }] = useLoading();

  const [pagination, updatePagination] = useState({
    totalCount: 0,
    nextOffset: limit,
  });

  const [data, updateData] = useState([]);
  const refSearch = useRef(params[searchQueryParam] || "");

  const handleTableChange = useCallback(
    (type, { page, searchText = "", sortField, sortOrder }) => {
      const newParams = {
        ...params,
        offset: page ? limit * (page - 1) : params.offset,
        [searchQueryParam]: searchText ? searchText.trim() : undefined,
        [sortByQueryParam]:
          type === INVENTORY_TABLE_EVENT_TYPE_SORT ? sortField : params.orderBy,
        [sortTypeQueryParam]: getOrderType(type, sortOrder, params),
      };

      // When changed search - clear offset
      if (refSearch.current !== searchText) {
        newParams.offset = 0;

        if (/^\s*$/.test(searchText)) {
          delete newParams.query;
        }
      }

      refSearch.current = searchText;

      if (sortTransformerMap) {
        newParams[sortByQueryParam] = sortTransformerMap[sortField];
      }

      if (sortTypeTransformMap) {
        newParams[sortTypeQueryParam] = sortTypeTransformMap[sortOrder];
      }

      history.push({
        search: stringifyParams(newParams),
      });
    },

    [history, limit, params]
  );

  const downloadNewData = useCallback(async () => {
    try {
      enable();

      const { data, pagination } = await getNewDataService({
        ...params,
        limit,
      });

      updateData([...data]);
      updatePagination(pagination);
    } catch (e) {
      Bugsnag.notify(e);
    } finally {
      disable(false);
    }
  }, [params, limit]);

  useEffect(() => {
    downloadNewData();
  }, [downloadNewData, params]);

  const incrementTotal = useCallback((count) => {
    updatePagination((state) => {
      return {
        ...state,
        totalCount: state.totalCount + count,
      };
    });
  }, []);

  const actions = {
    refresh: downloadNewData,
    incrementTotal,
  };

  return [loading, pagination, data, handleTableChange, actions];
};
