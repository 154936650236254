import { useCallback, useEffect, useRef, useState } from "react"
import { DEFAULT_INITIAL_SECONDS, DEFAULT_INTERVAL, ZERO_SECONDS } from "../constants/shared";

export const useTimer = ({ initialSeconds = DEFAULT_INITIAL_SECONDS }) => {
  const intervalRef = useRef(null);
  const [ timer, updateTimer ] = useState({ 
    seconds: initialSeconds, 
    isActive: false 
  });  

  const onCancel = useCallback(() => {
    clearInterval(intervalRef.current);
    updateTimer({
      seconds: ZERO_SECONDS, 
      isActive: false
    }); 
  }, []);

  const onStart = useCallback(() => {
    updateTimer({
      seconds: initialSeconds, 
      isActive: true
    })
  }, [initialSeconds]);

  useEffect(() => {
    if (!timer.isActive) return

    intervalRef.current = setInterval(() => {
      if (timer.seconds > ZERO_SECONDS) {
        updateTimer(timer => ({ 
          ...timer, 
          seconds: timer.seconds - 1 
        }));
        return;
      }
      onCancel();    
    }, DEFAULT_INTERVAL);

    return () => clearInterval(intervalRef.current)
  }, [onCancel, onStart, timer]);


  return { 
    seconds: String(timer.seconds), 
    isActive: timer.isActive,
    onStart,
    onCancel
  };
}