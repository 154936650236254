import React from "react";
import PropTypes from "prop-types";

export const createStatefullProvider = (initialState) => {
  const State = React.createContext(initialState);
  const DispatchContext = React.createContext();

  const Provider = ({ children, value = initialState }) => {
    const [state, update] = React.useState(value);

    return (
      <DispatchContext.Provider value={update}>
        <State.Provider value={state}>{children}</State.Provider>
      </DispatchContext.Provider>
    );
  };

  Provider.propTypes = {
    children: PropTypes.any,
    value: PropTypes.any,
  };

  return {
    State,
    DispatchContext,
    Provider,
  };
};

export const createProvider = (initialState) => {
  const State = React.createContext(initialState);
  const DispatchContext = React.createContext();

  const Provider = ({ children, value = initialState, update }) => {
    return (
      <DispatchContext.Provider value={update}>
        <State.Provider value={value}>{children}</State.Provider>
      </DispatchContext.Provider>
    );
  };

  Provider.propTypes = {
    children: PropTypes.any,
    value: PropTypes.any,
  };

  return {
    State,
    DispatchContext,
    Provider,
  };
};
