import React from "react";

const DropdownIcon = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="24px" viewBox="0 0 30 24"
             version="1.1">
            <g id="App" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="2_07_30-Create-a-draft-from-basic-Category-selection"
                   transform="translate(-805.000000, -1430.000000)" fill="#74818D">
                    <g id="Group-6" transform="translate(294.000000, 1335.000000)">
                        <g id="Input/Action-Copy-9" transform="translate(0.000000, 60.000000)">
                            <g id="ic_chevron-down_black" transform="translate(511.000000, 35.000000)">
                                <path
                                    d="M8.93999994,10 L12,13.0599989 L15.0600004,10 L16,10.9466664 L12,14.9466648 L8,10.9466664 L8.93999994,10 L8.93999994,10 Z"
                                    id="Shape"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default DropdownIcon;